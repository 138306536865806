import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingType, THEME } from '../../../../enums';
import { ApplicationReceivedIcon } from '../../../../icons';
import { sendInterviewCalendarInviteStateActions } from '../../../../redux/dashboard/metrics/send_interview_calendar_invite/send_interview_calendar_redux';
import { useAppDispatch, useAppSelector, postSnackbarMessage, getInterviewCalendarInviteThunk, selectArchiveInterviewState, archiveInterviewCalendarInviteThunk } from '../../../../redux/store';
import './archive_interview.scss';
import { CustomButton, FormInput } from '../../../../utils';
import DocSavedText from '../../../../components/doc_saved_text/doc_saved_text';
import { ActionDialogHolder,ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper from '../../../../components/dialog_wrapper/dialog_wrapper';
import { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
interface Props {
    setShowArchive: any,
    data: any,
    closePopup: any,
    profileUserName:String | undefined,
    selectedInterviewNames : String,
    clearSelectedSets?:()=>void

}

const SendInterviewCalendarArchivePopup: React.FunctionComponent<Props> = (props) => {
    const {
        setShowArchive,
        data,
        closePopup,
        profileUserName,
        selectedInterviewNames,
        clearSelectedSets

    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const sendInterviewCalendarInviteArchiveState = useAppSelector((state) => selectArchiveInterviewState(state))
    const [message, setMessage] = useState(null);
    const [displayForm,setDisplayForm] = useState(false)
    const actionRef = useRef<ActionDialogHolderType>(null);
    /*useEffect(() => {
        if (sendInterviewCalendarInviteArchiveState.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(sendInterviewCalendarInviteArchiveState.response || null));
            dispatch(getInterviewCalendarInviteThunk({ this_week: true, get_count: false, completed: false }));
            dispatch(sendInterviewCalendarInviteStateActions.clearArchiveResponse());
            setShowArchive(false);
            closePopup();
        }

    }, [sendInterviewCalendarInviteArchiveState.loading])*/
    const handleSuccess = ()=>{
        dispatch(postSnackbarMessage(sendInterviewCalendarInviteArchiveState.response || null));
        dispatch(getInterviewCalendarInviteThunk({ this_week: true, get_count: false, completed: false }));
            
    }

    const onClosePopup = (action?: () => void) => {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
      };

    const toggle = ()=>{
        if (sendInterviewCalendarInviteArchiveState.loading == LoadingType.succeeded) {
            handleSuccess()
            if(clearSelectedSets) {
              clearSelectedSets()
            }
        }
        if(getError()) handleClearError()
        dispatch(sendInterviewCalendarInviteStateActions.clearArchiveResponse());
        setDisplayForm(false)
        setShowArchive(false);
        closePopup();
    }

    const getError = () => {
        return sendInterviewCalendarInviteArchiveState.error;
    }
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={true}
            wrapperWidth={"30%"}
            onClose={toggle}
            onDissmiss={toggle}>
            <DialogWrapper onClose={()=>onClosePopup(toggle)}>
              <div className="uf-container contact-form archive-appl-sent">
                <div className="uf-header">
                  <FormDialogTilteHeader
                    title={t("interview_application")}
                    titleIcon={
                      <ApplicationReceivedIcon width={"100%"} height={"100%"} />
                    }
                  />
                </div>
                {sendInterviewCalendarInviteArchiveState.loading === LoadingType.succeeded ? (
                  <div className="uf-content archive-appl-sent-content">
                    <DocSavedText>
                      <span>
                        <div>
                          <span className="aknw-name">
                          {t('hey_name',{name : props.profileUserName}) as String}
                          </span>
                        </div>
                        {
                          <span className="info-txt">
                            {selectedInterviewNames.length>1 ? 
                              t('you_have_moved_the_selected_interview_items_to_archive_directory')
                              :t('you_have_moved_items_to_archive_directory',{name : props.selectedInterviewNames[0]})
                          }
                            
                          </span>
                        }
                      </span>
                    </DocSavedText>
                  </div>
                ) : (displayForm? (
                            <div className="interview-dialog-content">
                                <p className="ac-header-text"> {t("archive_reason")}</p>
                                <div className='ac-eng-field-row'>
                                    <div className='ac-sc-desc-fields'>
                                        <FormInput
                                            id={'message'}
                                            onChange={(field: any, value: any) => {
                                                setMessage(value);
                                            }}
                                            multiline={true}
                                            required={false}
                                            placeholder={""}
                                            type={"text"}
                                            value={message}
                                        />
                                    </div>
                                </div>
                            </div>
                ): <div className="uf-content archive-appl-sent-content">
                    {
                      <span style={{marginTop:"0.3em",marginBottom:"0.3em"}}>
                        {t("are_you_sure_you_want_to_archive", {
                          name : props.selectedInterviewNames
                        }) as String}
                      </span>
                    }
                  </div>
                  
                )}
                <div className="uf-actions">
                  {sendInterviewCalendarInviteArchiveState.loading === LoadingType.succeeded ? (
                    <div className="btn-save">
                      <CustomButton
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("ok")}
                        enable
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={()=>onClosePopup(toggle)}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="btn-cancel">
                        <CustomButton
                          loading={false}
                          textStyle={{ textTransform: "capitalize" }}
                          name={!displayForm?t("no"):t('cancel')}
                          enable
                          backgroundColor={THEME.defaultHighLightColor}
                          onClick={()=>onClosePopup(toggle)}
                        />
                      </div>
                      <div className="btn-save">
                        <CustomButton
                          loading={sendInterviewCalendarInviteArchiveState.loading===LoadingType.pending}
                          textStyle={{ textTransform: "capitalize" }}
                          name={!displayForm?t("yes"):t('save')}
                          enable
                          backgroundColor={THEME.defaultHighLightColor}
                          onClick={!displayForm?()=>setDisplayForm(true):handleOnSubmit}
                        />
                      </div>
                    </>
                    
                  )}
                </div>
              </div>
            </DialogWrapper>
          </ActionDialogHolder>
        
    );

    function handleOnSubmit() {
        const payload: any = {
            ids: data,
            reason: message,
        }
        dispatch(archiveInterviewCalendarInviteThunk(payload));
    }

    function handleClearError() {
        dispatch(sendInterviewCalendarInviteStateActions.clearSaveResponse());
    }
}

export default SendInterviewCalendarArchivePopup;
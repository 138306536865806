import { useTranslation } from "react-i18next";
import SortableTable, { TableData } from "../../../../../../components/sortable_table/sortable_table";
import { currencyConversion, CustomButton, CustomCheckBox, getTextEmAllIcon, SpinnerScreen } from "../../../../../../utils";
import { useAppDispatch } from "../../../../../../redux/store";
import { useEffect, useState } from "react";
import { IMultipleMove, IPipelineStatus, ITPState, TalentPipeline } from "../../../../../../interfaces/talent_pipeline";
import { getDateString, getPrefferedContact } from "../../../../../../variables";
import { RouteComponentProps } from "react-router-dom";
import { AppRoutes } from "../../../../../../routes";
import CustomFormSelect, { CustomMultiFormSelect } from "../../../../../../components/form_selector/form_select";
import SearchBar from "../../../../../../components/search_bar";
import { DeleteIcon, MarkTalentIcon, PaperPlaneIcon } from "../../../../../../icons";
import { Portal } from "react-portal";
import { ConfirmationDialog } from "../../../../popups/confirm_popup/confirm_popup";
import { LoadingType, TalentPipelineDirectory, THEME } from "../../../../../../enums";
import { TalentStatus } from "../../../../../../interfaces";
import { pipelineSliceActions } from "../../../../../../redux/job_order/details/talent_pipeline/talent_pipeline_details/talent_pipeline_reducer";
import "../talent_pipeline.scss"
interface Props {
    loading: boolean;
    talentPipelineList: TalentPipeline[] | null;
    history: RouteComponentProps["history"],
    handleMoveToTrash: (doc: IMultipleMove[]) => void,
    handleMoveToQueue: (doc: IMultipleMove[]) => void,
    jobId: string,
    talentTrashState: ITPState,
    talentQueState: ITPState,
    talentPipelineStatusChangeState: ITPState,
    pipelineStatusList: IPipelineStatus[],
    talentStatusList: TalentStatus[],
    currentLoginUserName: string,
    directory: string,
    canSendText: boolean | undefined,
    index: number,
    handleTalentPipelineStatusChange: (talent_id, status_id) => void,
    onSuccessClose: () => void,
    handleBroadCastTalent: (selectedTalents: string[], forceSend?: boolean) => void,
    showErrorMsg: string,
    showMsg: boolean
}

const TalentMatchesSection: React.FunctionComponent<Props> = (props) => {
    const { loading, talentPipelineList, history,
        handleMoveToQueue, handleMoveToTrash,
        jobId, talentTrashState,
        talentQueState, pipelineStatusList, talentStatusList, currentLoginUserName,
        directory, canSendText, index, handleTalentPipelineStatusChange, onSuccessClose,
        handleBroadCastTalent, showErrorMsg, showMsg } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();


    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [search, setSearch] = useState('');
    const [talentStatus, setTalentStatus] = useState<string[]>([]);
    const [pipelineStatus, setPipelineStatus] = useState<string[]>([]);
    const [selectedItems, setSelectedItems] = useState<
        Map<string, { talent_id: string; talent_name: string, job_id: string }>
    >(new Map());

    const [showConfirm, setShowConfirm] = useState<{ visible: boolean, type: string | null, talent_id: string | null, talent_name: string | null }>({ visible: false, type: null, talent_id: null, talent_name: null })


    const [formState, setFormState] = useState<{ talent_id: string, pipeline_status: string, pipeline_status_id: string }[]>([]);
    const [isHovering, setIsHovering] = useState<{ visible: boolean, id: string } | null>(null)

    useEffect(() => {
        if (talentPipelineList) {
            setFormState(talentPipelineList.map(value => ({ talent_id: value.talent_id, pipeline_status: value.pipeline_status, pipeline_status_id: value.pipeline_status_id })))
        }
    }, [talentPipelineList]);

    const SelectAllCheckBox = () => {
        return (
            <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.size === talentPipelineList?.length}
                onClick={handleMarkAllItem}
            />
        );
    };
    const selectedTalentsList = Array.from(selectedItems.values()).map(({ talent_id, job_id }) => ({ talent_id, job_id }));
    const handleMarkAllItem = () => {
        const newSet = new Map<string, { talent_id: string; talent_name: string, job_id: string }>();
        if (selectedItems.size === talentPipelineList?.length) {
            setSelectedItems(newSet);
        } else {
            talentPipelineList?.forEach((doc) =>
                newSet.set(doc.talent_id, { talent_id: doc.talent_id, talent_name: `${doc.talent_first_name ?? ""} ${doc.talent_middle_name ?? ""} ${doc.talent_last_name ?? ""}`, job_id: jobId })
            );
            setSelectedItems(newSet);
        }
    };
    const handleMarkItem = (value) => {
        const newSet = new Map(selectedItems);
        if (selectedItems.has(value.talent_id)) {
            newSet.delete(value.talent_id);
        } else {
            newSet.set(value.talent_id, { talent_id: value.talent_id, talent_name: `${value.talent_first_name ?? ""} ${value.talent_middle_name ?? ""} ${value.talent_last_name ?? ""}`, job_id: jobId });
        }
        setSelectedItems(newSet);
    };


    const headerList = [
        { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
        // { title: "access_talent", code: "access_talent" },
        { title: "talent_match", code: "talent_first_name" },
        { title: "talent_status", code: "status" },
        { title: "pipeline_status", code: "pipeline_status" },
        { title: "skills_and_certifications", code: "matched_skills" },
        { title: "pay_rate", code: "pay_rate" },
        { title: "contact", code: "preferred_contact_method" },
        { title: "match_score", code: "match_score" },
        { title: "date_last_engagement", code: "last_engagement_date" },
        { title: directory === TalentPipelineDirectory.Queued ? "move_to_interview" : "move_to_que", code: "" },
        { title: "not_a_fit", code: "" }
    ];
    const tableheaderList = directory === TalentPipelineDirectory.Trashed ? headerList.slice(1, -2) : headerList

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    const getFlexNumber = (value: number) => {
        if (value === 0 && directory !== TalentPipelineDirectory.Trashed) return 0.1;
        if (value === 1 && directory !== TalentPipelineDirectory.Trashed) return 0.8;
        if (value === 2 && directory !== TalentPipelineDirectory.Trashed) return 0.5;
        if (value === 4 && directory !== TalentPipelineDirectory.Trashed) return 1;
        if (value === 5 && directory !== TalentPipelineDirectory.Trashed) return 0.5;
        if (value === 6 && directory !== TalentPipelineDirectory.Trashed) return 0.3;
        if (value === 7 && directory !== TalentPipelineDirectory.Trashed) return 0.5;
        if (value === 8 && directory !== TalentPipelineDirectory.Trashed) return 0.6;
        if (value === 9 && directory !== TalentPipelineDirectory.Trashed) return 0.6;
        if (value === 10 && directory !== TalentPipelineDirectory.Trashed) return 0.4;
        return 1;
    }

    const getFilteredList = (): TalentPipeline[] | null => {
        if (!talentPipelineList) {
            return null; // Explicitly return null if the list is not available
        }

        let list: TalentPipeline[] = [...talentPipelineList];

        // Sorting
        if (sortedField != null) {
            list = list.sort((a, b) => {
                const valueA =
                    a[sortedField] != null
                        ? typeof a[sortedField] === "string"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] === "string"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";

                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        // Filter by talent status
        if (talentStatus.length !== 0) {
            list = list.filter((talent) => talentStatus.includes(talent.status_id));
        }
        // Filter by Pipeline status
        if (pipelineStatus.length !== 0) {
            list = list.filter((talent) => pipelineStatus.includes(talent.pipeline_status_id));
        }
        // Search Filter
        if (search.trim()) {
            list = list.filter((doc) => {
                const str = search.trim().toLowerCase();
                return Object.values(doc).some((val) =>
                    val && val.toString().trim().toLowerCase().includes(str)
                );
            });
        }

        return list;
    };

    function handleUserSelect(value: TalentPipeline) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
            state: {
                id: value.talent_id,
                name: `${value.talent_first_name ?? ""} ${value.talent_middle_name ?? ""} ${value.talent_last_name ?? ""}`,
            },
        });
        return;
    }

    const handleInterviewRedirect = (talent_id: string, talent_name: string) => {
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${jobId}/interviews`,
            state: {
                id: jobId,
                talent_id: talent_id,
                name: talent_name,
                interview: true
            },
        });
        return;
    }

    const handleTalentStatus = (value) => {
        setTalentStatus(value)
    }
    const handlePipelineStatus = (value) => {
        setPipelineStatus(value)
    }

    const handleClose = () => {
        showConfirm.type === "move" ? dispatch(pipelineSliceActions.clearMoveQueState()) : dispatch(pipelineSliceActions.clearMoveTrashState());
        setShowConfirm({ visible: false, type: "", talent_id: null, talent_name: null });
        setSelectedItems(new Map());
    }

    const handleClearError = () => {
        showConfirm.type === "move" ? dispatch(pipelineSliceActions.clearMoveQueError()) : dispatch(pipelineSliceActions.clearMoveTrashError())
    }
    const handleFormState = (val, talent_id) => {
        const updatedFormState = formState.map((item) => {
            if (item.talent_id === talent_id) {
                return {
                    ...item,
                    pipeline_status_id: val
                };
            }
            return item;
        });
        setFormState(updatedFormState);
        handleTalentPipelineStatusChange(talent_id, val)

    }

    const handleSubmit = () => {
        const payload = (selectedItems.size === 0 && showConfirm.talent_id) ? [{ job_id: jobId, talent_id: showConfirm.talent_id ?? "" }] : selectedTalentsList
        if (showConfirm.type === "move") {
            handleMoveToQueue(payload)
        }
        else {
            handleMoveToTrash(payload)
        }
    }
    const handleOpenConfirm = (type: string, talentId?: string, talentName?: string | null) => {
        if (selectedItems.size === 1 && selectedTalentsList.length !== 0) {
            const firstItem = selectedItems.values().next().value;
            if (firstItem) {
                const { talent_id, talent_name } = firstItem;
                setShowConfirm({ visible: true, type: type, talent_id: talent_id, talent_name: talent_name })
            }
        } else if (selectedItems.size > 1) {
            setShowConfirm({ visible: true, type: type, talent_id: null, talent_name: null })

        } else {
            setShowConfirm({ visible: true, type: type, talent_id: talentId ?? "", talent_name: talentName ?? "" })
        }

    }

    function handleMouseOver(id) {
        setIsHovering({ id: id, visible: true });
    }
    function handleMouseOut() {
        setIsHovering(null);
    }

    return (
        <div className="talent-matches-container">
            <div className="talent-pipeline-header">
                <div className="header-left-section">
                    <div className="right-alignment">
                        <CustomMultiFormSelect
                            label={t("talent_status")}
                            name={"company_status"}
                            list={talentStatusList.map((doc) => ({
                                label: doc.talent_status,
                                value: doc.id,
                            }))}
                            onChange={handleTalentStatus}
                            required={false}
                            placeholder={t("all")}
                            value={talentStatus}
                            customStyle={{ width: "-webkit-fill-available" }}
                        />

                    </div>
                    {directory === TalentPipelineDirectory.Match &&

                        <div className=" right-alignment multiple-btns">
                            <CustomButton
                                key={"que" + index}
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("move_selected_to_que")}
                                enable={selectedItems.size !== 0}
                                onClick={() => handleOpenConfirm("move")}
                                customClassName={'mul-que'}
                                backgroundColor={THEME.defaultHighLightColor}
                            />
                            <CustomButton
                                key={"trash" + index}
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("move_selected_to_trash")}
                                enable={selectedItems.size !== 0}
                                onClick={() => handleOpenConfirm("trash")}
                                customClassName={"mul-trash"}
                                backgroundColor={THEME.defaultHighLightColor}
                            /></div>
                    }

                    {directory === TalentPipelineDirectory.Queued &&
                        <div className="right-alignment">
                            <CustomMultiFormSelect
                                label={t("pipeline_status")}
                                name={"company_status"}
                                list={pipelineStatusList.map((doc) => ({
                                    label: doc.value,
                                    value: doc.id,
                                }))}
                                onChange={handlePipelineStatus}
                                required={false}
                                placeholder={t("all")}
                                value={pipelineStatus}
                                customStyle={{ width: "13vw" }}
                            />
                        </div>
                    }

                </div>
                <div className="header-right-section">
                    <div className="total-count">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number"><b>{getFilteredList()?.length}</b></span>
                        </span>
                    </div>
                    <div className="right-alignment">
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                        />
                    </div>
                    {directory !== TalentPipelineDirectory.Trashed &&
                        <div>
                            <CustomButton
                                key={`broadcast-${directory}-${index}-${Math.random()}`}
                                leftIcon={getTextEmAllIcon()}
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("broadcast_to_talent")}
                                enable={canSendText ? true : false}
                                onClick={() => handleBroadCastTalent(Array.from(selectedItems.values()).map(ids => ids.talent_id))}
                                customClassName={`${directory} textemall`}
                            />
                            {showMsg && <span className="select_talent_msg">{showErrorMsg}</span>}
                        </div>
                    }
                </div>
            </div>
            <div className="talent-matches-table">
                <SortableTable
                    headerList={tableheaderList}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {loading ?
                        <SpinnerScreen />
                        :
                        <>
                            {getFilteredList() && getFilteredList()?.length! > 0 ? (
                                <>
                                    {getFilteredList()?.map((doc, docIndex) => {
                                        const talentName = `${doc.talent_first_name ?? ""} ${doc.talent_middle_name ?? ""} ${doc.talent_last_name ?? ""}`;
                                        return (
                                            <tr key={directory + doc.talent_id + docIndex}>

                                               {directory !== TalentPipelineDirectory.Trashed && <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                    <CustomCheckBox
                                                        name={""}
                                                        title={""}
                                                        checked={selectedItems!.has(doc.talent_id)}
                                                        onClick={() => handleMarkItem(doc)}
                                                    />
                                                </TableData>}
                                                {/* <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                                    {getTableAccessIcon(() => handleUserSelect(doc))}
                                                </TableData> */}
                                                <TableData customStyle={{ flex: getFlexNumber(1) }} isButton={true} onClick={() => handleUserSelect(doc)}>
                                                    <span>{`${doc.talent_first_name ?? ""} ${doc.talent_middle_name ?? ""} ${doc.talent_last_name ?? ""}`}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                                    <span>{doc.status ?? ""}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                    {directory === TalentPipelineDirectory.Queued ?
                                                        <CustomFormSelect
                                                            name={'pipeline_status'}
                                                            value={formState.find((pipeline) => pipeline.talent_id === doc.talent_id)?.pipeline_status_id}
                                                            placeholder={t('select')}
                                                            list={pipelineStatusList.map((doc) => ({
                                                                label: doc.value,
                                                                value: doc.id,
                                                            }))}
                                                            label={t("")}
                                                            onChange={(val) => (handleFormState(val, doc.talent_id))}
                                                            required={false}
                                                            isClearable={false}
                                                        />
                                                        :
                                                        <span>{doc.pipeline_status ?? ""}</span>
                                                    }

                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                                    <div
                                                        className="col-hover-container"
                                                        style={{ cursor: "pointer" }}
                                                        onMouseOut={() => handleMouseOut()}
                                                        onMouseOver={() => handleMouseOver(doc.talent_id)}
                                                    >
                                                        <span>
                                                            {doc.matched_skills && doc.matched_skills.length > 5
                                                                ? <>
                                                                    {doc.matched_skills.slice(0, 5).map((item, matchIndex, arr) => (
                                                                        <span key={matchIndex}>
                                                                            {item} {matchIndex !== arr.length - 1 ? ", " : ""}
                                                                        </span>
                                                                    ))}
                                                                    <span>...</span>
                                                                </>
                                                                : doc.matched_skills.map((item, matchIndex, arr) => (
                                                                    <span key={matchIndex}>
                                                                        {item} {matchIndex !== arr.length - 1 ? ", " : ""}
                                                                    </span>
                                                                ))
                                                            }
                                                        </span>
                                                        {isHovering && (doc.talent_id === isHovering.id) && (
                                                            <div className="parent-position">
                                                                <div className="child-position">
                                                                    <div className="action-list-title">
                                                                        {doc.matched_skills.map((item, matchIndex, arr) => (
                                                                            <span key={matchIndex}>
                                                                                {item} {matchIndex !== arr.length - 1 ? ", " : ""}&nbsp;&nbsp;
                                                                            </span>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                                    <span>{doc.pay_rate ? currencyConversion(doc.pay_rate) : "$0.00"}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                                    <div className="tx-center">
                                                        {getPrefferedContact(doc.preferred_contact_method)}
                                                    </div>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                    <span>{doc.match_score ? (doc.match_score + "%") : 0}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                                    <span>{getDateString(doc.last_engagement_date) ?? ""}</span>
                                                </TableData>
                                                {directory !== TalentPipelineDirectory.Trashed &&
                                                    <>
                                                        <TableData customStyle={{ flex: getFlexNumber(9) }}>

                                                            <div className="icons-row">
                                                                {directory === TalentPipelineDirectory.Match
                                                                    ?
                                                                    <span className="chaticon" onClick={() => handleOpenConfirm("move", doc.talent_id, talentName)}>{<MarkTalentIcon height={"1.5vw"} width={"1.5vw"} />}</span>
                                                                    :
                                                                    <span className="chaticon" onClick={() => handleInterviewRedirect(doc.talent_id, talentName)}>{<PaperPlaneIcon height={"1.5vw"} width={"1.5vw"} />}</span>
                                                                }

                                                            </div>

                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(10) }}>
                                                            <div className="icons-row">
                                                                <span className="delicon" onClick={() => handleOpenConfirm("trash", doc.talent_id, talentName)}>{<DeleteIcon height={"1vw"} width={"1vw"} />}</span>
                                                            </div>
                                                        </TableData>
                                                    </>
                                                }
                                            </tr>
                                        )
                                    })}
                                </>
                            )
                                :
                                <>
                                    <tr className="talent-pipeline-nqd">
                                        <TableData customStyle={{ flex: getFlexNumber(20) }} align="center" >
                                            <div className="nqd_div">
                                                {directory === TalentPipelineDirectory.Match ? t("no_matched_talents") :
                                                    (directory === TalentPipelineDirectory.Trashed ? t("no_trashed_talents") : t("no_que_talents"))}
                                            </div>
                                        </TableData>
                                    </tr>
                                </>
                            }

                        </>
                    }
                </SortableTable>
            </div>
            {
                showConfirm.visible && (
                    <Portal>
                        <ConfirmationDialog
                            title={t(showConfirm.type === "move" ? "move_to_que" : "move_to_trash")}
                            onClose={handleClose}
                            type={showConfirm.type ?? ""}
                            message={t(showConfirm.type === "move" ? "are_u_sure_to_move_talent" : "are_u_sure_to_trash_talent", { name: selectedItems.size > 1 ? "the selected talents" : showConfirm.talent_name })}
                            state={showConfirm.type === "move" ? talentQueState : talentTrashState}
                            onClearError={handleClearError}
                            onConfirm={handleSubmit}
                            talent_name={showConfirm.talent_name ?? ""}
                            currentLoginUserName={currentLoginUserName}
                            onSuccessClose={() => { handleClose(); onSuccessClose() }}
                        />
                    </Portal>
                )
            }

        </div >

    )
}

export default TalentMatchesSection;
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ImportDataFileAPI } from "../../../apis/import_data_file_api";
import { S3API } from "../../../apis/s3_api";
import { catchRequestError } from "../../../utils";
import { AppError, ITalentResumeUpload } from "../../../interfaces";


export const getTalentResumeUploadUrls = createAsyncThunk<
    ITalentResumeUpload[],
    { file_names: string[], files: File[] | null, allow_duplicates: boolean },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    "@talent/universal_directory/upload_talent/get_upload_urls/post",
    async (doc, thunkAPI) => {
        try {
            const res = await ImportDataFileAPI.getTalentResumesUploadUrl(doc.file_names, doc.allow_duplicates);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateTalentImportThunk = createAsyncThunk<
    string,
    string[],
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    "@talent/universal_directory/upload_talent/import_talents/patch",
    async (doc, thunkAPI) => {
        try {
            const res = await ImportDataFileAPI.updateImportTalents(doc);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const uploadTalentResumeFileThunk = createAsyncThunk<
    any,
    { uploadFiles: { url: string, file: File | null }[] },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>(
    "@talent/universal_directory/upload_talent/import_talents/put",
    async (payload, thunkAPI) => {
        try {
            const uploadResults = await Promise.all(
                payload.uploadFiles.map(async ({ url, file }) => {
                    if (!file) { 
                        return { status: "failed", error: "File is null" };
                    }
                    try {
                        const response = await S3API.uploadWithPreSignedURL(url, file);
                        // console.log("S3 Response:", response); // 

                        if (!response || !response.status) {
                            // console.error("S3API returned undefined for:", file.name);
                            return { status: "failed", error: "No response from S3API" };
                        }

                        return { status: response.status };
                    } catch (error: any) {
                        // console.error("Upload Error:", error);
                        return { status: "failed", error: error.message  || "Unknown Error"};
                    }
                })
            );

            // console.log("Upload Results:", uploadResults);

            const allSuccessful = uploadResults.every((res) => res.status === 200);
            if (!allSuccessful) {
                throw new Error("Some uploads failed.");
            }

            return uploadResults;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


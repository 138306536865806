import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./job_talent_pipeline_status_thunk";
import { IPipelineStatus } from "../../../../interfaces/talent_pipeline";

export interface PipelineStatusState {
    error?: AppError | null,
    loading: LoadingType,
    response: IPipelineStatus[] | null,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}


const initialState: PipelineStatusState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
}


export const pipelineStatusSlice = createSlice({
    name: 'pipelineStatus',
    initialState: initialState,
    reducers: {
        clearPipelineStatusError(state) {
            return { ...state, error: null };
        },
        ClearState(state) {
            return { ...state, loading: LoadingType.idle, error: null };
        },
        clearPipelineStatusCreateState(state) {
            return { ...state, create: { ...initialState.create } };
        },
        clearPipelineStatusUpdateState(state) {
            return { ...state, update: { ...initialState.update } };
        },
        clearPipelineStatusDeleteState(state) {
            return { ...state, remove: { ...initialState.remove } };
        },
        clearPipelineStatusCreateStateError(state) {
            return { ...state, create: { ...initialState.create, error: null } };
        },
        clearPipelineStatusUpdateStateError(state) {
            return { ...state, update: { ...initialState.update, error: null } };
        },
        clearPipelineStatusDeleteStateError(state) {
            return { ...state, remove: { ...initialState.remove, error: null } };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getPipelineStatusThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getPipelineStatusThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getPipelineStatusThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload }))

            .addCase(thunk.createPipelineStatusThunk.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.createPipelineStatusThunk.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.createPipelineStatusThunk.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.deletePipelineStatusThunk.pending, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.deletePipelineStatusThunk.rejected, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.deletePipelineStatusThunk.fulfilled, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updatePipelineStatusThunk.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.updatePipelineStatusThunk.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.updatePipelineStatusThunk.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const pipelineStatusActions = pipelineStatusSlice.actions;

export const selectState = (state: PipelineStatusState) => state;
export const selectLoading = (state: PipelineStatusState) => state.loading;
export const selectError = (state: PipelineStatusState) => state.error;



import { useTranslation } from 'react-i18next';
import ClientCompanyInterviewsList, { InterviewFeedbackCard } from '../../../../../components/client_company_interviews_list/client_company_interviews_list';
import { TableErrorHandler } from '../../../../../components/table_empty/table_empty';
import { LoadingType, THEME } from '../../../../../enums';
import { ChatIcon, CloseIcon } from '../../../../../icons';
import { ITalentClientCompanyInterview } from '../../../../../interfaces';
import { SingleTalentInterviewsToClientCompaniesState } from '../../../../../redux/talent/details/interviews/interviews_to_client_companies/interviews_to_client_companies_reducer';
import { SpinnerScreen } from '../../../../../utils';
import { getDateString } from '../../../../../variables';
import InterviewDetailsHeader from '../components/interview_details_header/interview_details_header';
import './interview_send_outs_to_client_companies.scss';

interface Props {
    onInterviewClick: (value: ITalentClientCompanyInterview) => void
    onAddNew: () => void,
    onRefresh: () => void,
    state: SingleTalentInterviewsToClientCompaniesState,
    selectedInterview: ITalentClientCompanyInterview | null,
    onEditInterview: (value: ITalentClientCompanyInterview) => void,
    onCloseInterview: () => void,
    handleCompanySelect?: () => void,
    handleDeptSelect?: () => void,
    handleContactSelect?: () => void,
}

const InterviewSendOutsToClientCompanies: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();

    if (props.state.loading === LoadingType.pending) {
        return (
            <div className="tint-isocc-container">
                <SpinnerScreen />
            </div>
        )
    }

    if (props.state.error != null) {
        <div className="tint-isocc-container">
            <TableErrorHandler error={props.state.error} onRefresh={props.onRefresh} />
        </div>
    }

    return (
        <div className="tint-isocc-container">
            <div className="tint-isocc-left-panel">
                <ClientCompanyInterviewsList
                    interviews={props.state.data ? Object.keys(props.state.data).map((key) => props.state.data[key]) : []}
                    onClick={props.onInterviewClick}
                    onAddNew={props.onAddNew}
                    selectedInterview={props.selectedInterview}
                />
                <div className="tint-left-panel-shape-pyramid"></div>
            </div>
            <div className="tint-isocc-right-panel">
                {props.selectedInterview && <div className="tint-isocc-details-holder">
                    <div className="tint-isocc-icon">
                        <ChatIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
                    </div>
                    <div className="tint-isocc-content">
                        <div className="tint-isocc-title">
                            <span>{t('client_company_interview')}</span>
                            <button className="icon-btn close-icon" onClick={props.onCloseInterview}>
                                <CloseIcon width={"100%"} height={"100%"} />
                            </button>
                        </div>
                        <div className="tint-isocc-info-header">
                            <InterviewDetailsHeader  
                                list={[
                                    {title: t('company'), value: props.selectedInterview.company_name, isButton: true, handleCompanyClick: props.handleCompanySelect},
                                    {title: t('department'), value: props.selectedInterview.department_name, isButton: true, handleDeptClick: props.handleDeptSelect},
                                    {title: t('interview_with'), value: props.selectedInterview.interview_with, isButton: true, handleContactClick:props.handleContactSelect},
                                    {title: t('interview_date'), value: getDateString(props.selectedInterview?.interview_date!)},
                                    {title: t('interview_status'), value: props.selectedInterview.interview_status},
                                ]}
                                onEditInterview={() => props.onEditInterview(props.selectedInterview!)}
                            />
                        </div>
                        <div className="tint-isocc-info-content">
                            <InterviewFeedbackCard 
                                talent_feedback={props.selectedInterview.talent_interview_feedback}
                                client_company_contact_feedback={props.selectedInterview.contact_interview_feedback}
                                recruiter_notes={props.selectedInterview.recruiter_notes}
                            />
                        </div>
                    </div>
                </div>}

            </div>

        </div>
    )
}


export default InterviewSendOutsToClientCompanies;
import { AddCircleOutline } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { LoadingType, THEME } from "../../../../enums";
import {
  CalenderIcon,
  EditIcon,
  FacebookIcon,
  FacebookWhiteIcon,
  IndeedIcon,
  IndeedLogoIcon,
  InstagramIcon,
  LinkedInIcon,
  LinkedInWhiteIcon,
  MetricsIcon,
  TwitterIcon,
  TwitterWhiteIcon,
  TwitterXIcon,
  TwitterXWhiteIcon,
  YoutubeIcon,
  YoutubeWhiteIcon,
  ZipRecruiterChairIcon,
  ZipRecruiterIcon,
} from "../../../../icons";
import { MarketingCampaign } from "../../../../interfaces/marketing_campaign";
import {
  selectMarketingCampaignList,
  selectMarketingCampaignListState,
} from "../../../../redux/sales_marketing/marketing_activity/marketing_campaign_selector";
import { getMarketingCampaigns } from "../../../../redux/sales_marketing/marketing_activity/marketing_campaign_thunk";
import { manageMarketingCampaignActions } from "../../../../redux/sales_marketing/plan_marketing_campaign/manage_marketing_campaign_reducer";
import {
  getRoleUsersList,
  postSnackbarMessage,
  selectProfileState,
  selectRoleUsersList,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  getPlusBtnIcon,
  currencyConversion,
  getUserNameFromProfileDropdown,
  convertNumberToMoney,
} from "../../../../utils";
import {
  convertDateToTimeStamp,
  dateToTimestamp,
  extractDateFromTimestamp,
  extractTimeFromTimestamp,
  getDateString,
} from "../../../../variables";
import PlanMarketingCampaign from "../plan_marketing_campaign/plan_marketing_campaign";
import "../sales_call_activity/sales_call_activity.scss";
import "./marketing_activity.scss";
import SearchBar from "../../../../components/search_bar";
import { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-datepicker/dist/react-datepicker.css";
interface Props extends RouteComponentProps<any> {}

const initialState = {
  id: "",
  sequence_number: 0,
  marketing_campaign_name: "",
  marketing_campaign_goal: "",
  target_audience: "",
  budget: 0,
  gross_profit_potential: 0,
  start_date_time: 0,
  start_date: 0,
  start_time: "12:00 AM",
  end_date_time: 0,
  end_date: 0,
  end_time: "12:00 AM",
  social_media_platforms_to_publish: [],
  agency_id: "",
  branch_id: "",
  is_deleted: false,
}

const MarketingActivity: React.FunctionComponent<Props> = (props) => {
  const [tab, setTab] = useState<string>("youtube");
  const tabs = [
    "youtube",
    "linked_in",
    "facebook",
    // "X",
    // "indeed",
    // "instagram",
    // "zip_recruiter"
  ];
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const profileState = useAppSelector((state) => selectProfileState(state));
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState("");

  const [showCompletePopup, setShowCompletePopup] = useState<boolean>(false);
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [selectedCall, setSelectedCall] = useState<MarketingCampaign>(
    new MarketingCampaign()
  );
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  const tableHeader = [
    { title: "Campaign Name", code: "marketing_campaign_name" },
    { title: "Start Date", code: "start_date_time" },
    { title: "End Date", code: "end_date_time" },
    { title: "Target Audience", code: "target_audience" },
    { title: "Campaign Budget", code: "budget" },
    {
      title: "Gross Profit Potential for Campaign",
      code: "gross_profit_potential",
    },
    ...(["youtube", "linked_in", "facebook"].includes(tab)
      ? [{ title: "metrics", code: "metrics" }]
      : []),
    { title: "", code: "" },
  ];
  const usersList = useAppSelector((state) => selectRoleUsersList(state));

  const [marketActivityFilter, setMarketActivityFilter]: any = useState("");

  const campaigns: MarketingCampaign[] = useAppSelector((state) =>
    selectMarketingCampaignList(state)
  );

  const { loading, error } = useAppSelector((state) =>
    selectMarketingCampaignListState(state)
  );

  const loadingState = loading === LoadingType.pending;

  useEffect(() => {
    getCampaigns();
    dispatch(getRoleUsersList());
  }, []);

  useEffect(() => {
    if (error != null && campaigns.length !== 0) {
      dispatch(postSnackbarMessage(error.message));
    }
  }, [error]);

  const getCampaigns = () => dispatch(getMarketingCampaigns());

  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      // setSortedField(null);
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  const today = new Date();
  today.setHours(23, 59, 59, 999);

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const [marketDateRange, setMarketDateRange] = useState([
    thirtyDaysAgo,
    today,
  ]);
  const [serviceStartDate, serviceEndDate] = marketDateRange;

  useEffect(() => {
    if (!marketFormState.available_from && !marketFormState.available_to) {
      setMarketFormState({
        available_from: convertDateToTimeStamp(thirtyDaysAgo),
        available_to: convertDateToTimeStamp(today),
      });
    }
  }, []);

  const [marketFormState, setMarketFormState] = useState<{
    available_from: number;
    available_to: number;
  }>({
    available_from: 0,
    available_to: 0,
  });

  useEffect(() => {
    if (
      marketFormState.available_from !== 0 &&
      marketFormState.available_to !== 0
    ) {
      setDatePickerOpen(false);
    }
    if (
      marketFormState.available_from === 0 &&
      marketFormState.available_to === 0
    ) {
      setDatePickerOpen(true);
    }
  }, [marketFormState.available_from, marketFormState.available_to]);

  const getFilteredList = () => {
    let list = [...campaigns];
    let sortedList: MarketingCampaign[] | undefined;
    list = list.filter((item) => {
      const tabName = t(tab);
      if (
        tabName === "X" &&
        item.social_media_platforms_to_publish?.includes("Twitter")
      ) {
        return true;
      }
      if (
        item.social_media_platforms_to_publish?.findIndex(
          (i) => i.toLowerCase() === tabName.toLowerCase()
        ) !== -1
      ) {
        return item;
      }
    });

    if (sortedField != null) {
      list = [...(sortedList ?? list)].sort((a, b) => {
        const valueA =
          a[sortedField] != null
            ? typeof a[sortedField] != typeof 1
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] != typeof 1
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    if (
      marketActivityFilter?.length !== 0 &&
      marketFormState.available_from &&
      marketFormState.available_to
    ) {
      sortedList = (sortedList ?? list).filter((doc) => {
        const isScheduleValid =
          doc.start_date_time !== null &&
          doc.end_date_time !== null &&
          ((doc.start_date_time >= marketFormState.available_from &&
            doc.end_date_time <= marketFormState.available_to) ||
            (doc.start_date_time >= marketFormState.available_from &&
              doc.start_date_time <= marketFormState.available_to) ||
            (marketFormState.available_from >= doc.start_date_time &&
              marketFormState.available_to <= doc.end_date_time) ||
            (doc.end_date_time >= marketFormState.available_from &&
              doc.end_date_time <= marketFormState.available_to));
        return (
          isScheduleValid &&
          (doc.modified_by !== null
            ? marketActivityFilter.includes(doc?.modified_by!)
            : marketActivityFilter.includes(doc?.created_by!))
        );
      });
    }
    if (marketActivityFilter?.length !== 0) {
      sortedList = (sortedList ?? list).filter((doc) => {
        const userIncluded =
          doc.modified_by !== null
            ? marketActivityFilter.includes(doc?.modified_by!)
            : marketActivityFilter.includes(doc?.created_by!);
        return userIncluded;
      });
    }
    if (marketFormState.available_from && marketFormState.available_to) {
      sortedList = (sortedList ?? list).filter((doc) => {
        const isScheduleValid =
          doc.start_date_time !== null &&
          doc.end_date_time !== null &&
          ((doc.start_date_time >= marketFormState.available_from &&
            doc.end_date_time <= marketFormState.available_to) ||
            (doc.start_date_time >= marketFormState.available_from &&
              doc.start_date_time <= marketFormState.available_to) ||
            (marketFormState.available_from >= doc.start_date_time &&
              marketFormState.available_to <= doc.end_date_time) ||
            (doc.end_date_time >= marketFormState.available_from &&
              doc.end_date_time <= marketFormState.available_to));

        return isScheduleValid;
      });
    }

    // return list;
    return (sortedList ?? list)?.filter((doc) => {
      const str = search.trim().toLowerCase();
      const marketingCampaignName = doc.marketing_campaign_name
        ? doc.marketing_campaign_name.trim().toLowerCase().includes(str)
        : false;
      const targetAudience = doc.target_audience
        ? doc.target_audience.trim().toLowerCase().includes(str)
        : false;
      const campaignBudget = doc.budget
        ? convertNumberToMoney(doc.budget)
            .toString()
            .trim()
            .toLowerCase()
            .includes(str)
        : false;
      const grossProfitPotential = doc.gross_profit_potential
        ? convertNumberToMoney(doc.gross_profit_potential)
            .toString()
            .trim()
            .toLowerCase()
            .includes(str)
        : false;
      return (
        marketingCampaignName ||
        targetAudience ||
        campaignBudget ||
        grossProfitPotential
      );
    });
  };

  const showSpinner = () => loading === LoadingType.pending;

  const getTabIcon = (i) => {
    switch (i) {
      case "facebook":
        return tab === i ? (
          <FacebookWhiteIcon
            height={"20px"}
            width={"20px"}
            style={{ marginRight: "15px", marginTop: "0.2em" }}
          />
        ) : (
          <FacebookIcon
            height={"20px"}
            width={"20px"}
            style={{ marginRight: "15px" }}
          />
        );
      case "linked_in":
        return tab === i ? (
          <LinkedInWhiteIcon
            height={"20px"}
            width={"20px"}
            style={{ marginRight: "15px", marginTop: "0.2em" }}
          />
        ) : (
          <LinkedInIcon
            height={"20px"}
            width={"20px"}
            style={{ marginRight: "15px" }}
          />
        );
      case "X":
        return tab === i ? (
          <TwitterXWhiteIcon
            height={"20px"}
            width={"20px"}
            style={{ marginRight: "15px", marginTop: "0.2em" }}
          />
        ) : (
          <TwitterXIcon
            height={"20px"}
            width={"20px"}
            style={{ marginRight: "15px" }}
          />
        );
      case "indeed":
        return (
          <IndeedLogoIcon
            width={"16px"}
            height={"16px"}
            // viewBox={"0 0 80 400"}
            style={{ marginRight: "15px", marginTop: "0.2em" }}
          />
        );
      case "instagram":
        return (
          <InstagramIcon
            width={"20px"}
            height={"20px"}
            style={{ marginRight: "15px", marginTop: "0.2em" }}
          />
        );
      case "zip_recruiter":
        return (
          <ZipRecruiterChairIcon
            width={"16px"}
            height={"16px"}
            //viewBox={"0 0 20 98"}
            style={{ marginRight: "15px", marginTop: "0.4em" }}
          />
        );
      case "youtube":
        return tab === i ? (
          <YoutubeWhiteIcon
            width={"16px"}
            height={"16px"}
            style={{ marginRight: "15px", marginTop: "0.4em" }}
          />
        ) : (
          <YoutubeIcon
            width={"16px"}
            height={"16px"}
            style={{ marginRight: "15px", marginTop: "0.4em" }}
          />
        );
    }
  };

  const getTabColor = (i) => {
    switch (i) {
      case "facebook":
        return tab === i ? "#314C93" : "#EBF1F8";
      case "linked_in":
        return tab === i ? "#314C93" : "#EBF1F8";
      case "X":
        return tab === i ? "#0C95E5" : "#EBF1F8";
      case "indeed":
        return tab === i ? "#003A9B" : "#EBF1F8";
      case "instagram":
        return tab === i ? "#003A9B" : "#EBF1F8";
      default:
        return tab === i ? "#314C93" : "#EBF1F8";
    }
  };

  const getTabTextColor = (i) => {
    switch (i) {
      case "facebook":
        return tab !== i ? "#314C93" : "#EBF1F8";
      case "linked_in":
        return tab !== i ? "#006CAB" : "#EBF1F8";
      case "X":
        return tab !== i ? "#0C95E5" : "#EBF1F8";
      case "indeed":
        return tab !== i ? "#003A9B" : "#EBF1F8";
      default:
        return tab !== i ? "#314C93" : "#EBF1F8";
    }
  };

  const handleEdit = (val: MarketingCampaign) => {
    const startDateSting = extractDateFromTimestamp(val.start_date_time);
    const startDateTimeStamp = dateToTimestamp(startDateSting);
    const startTime = extractTimeFromTimestamp(val.start_date_time);
    const endDateSting = extractDateFromTimestamp(val.end_date_time);
    const endDateTimeStamp = dateToTimestamp(endDateSting);
    const endTime = extractTimeFromTimestamp(val.end_date_time);
    const updatedSalesCall = {
      ...val,
      start_date: startDateTimeStamp,
      start_time: startTime,
      end_date: endDateTimeStamp,
      end_time: endTime,
    };
    setSelectedCall(updatedSalesCall);
    // setSelectedCall(val);
    setShowCompletePopup(true);
    setShowEditPopup(true);
  };
  // const [marketDateRange, setMarketDateRange] = useState([null, null]);
  // const [serviceStartDate, serviceEndDate] = marketDateRange;

  function handleChange(value) {
    setMarketFormState({
      ...marketFormState,
      available_from: value[0] !== null ? convertDateToTimeStamp(value[0]) : 0,
      available_to:
        value[1] !== null
          ? convertDateToTimeStamp(
              new Date(value[1].getTime() + 1 * 24 * 60 * 60 * 1000)
            )
          : 0,
    });
    setMarketDateRange(value);
  }

  function getFlexNumber(value: number) {
    if (value === 6) return 0.5;
    if (value === 7) return 0.5;
    return 1;
  }

  const handleMetricsClick = () => {
    if (tab === "facebook") {
      return window.open("https://www.facebook.com/business/", "_blank");
    } else if (tab === "linked_in") {
      return window.open("https://www.linkedin.com/campaignmanager", "_blank");
    } else if (tab === "youtube") {
      return window.open("https://studio.youtube.com", "_blank");
    }
  };

  const getTable = () => {
    if (showSpinner()) return <TableLoading />;

    if (error != null && campaigns.length === 0) {
      return <TableErrorHandler error={error} onRefresh={getCampaigns} />;
    }

    if (campaigns.length === 0 || getFilteredList().length === 0) {
      return <TableEmpty title={"No campaigns found"} onClick={getCampaigns} />;
    }

    if (getFilteredList().length !== 0) {
      return (
        <SortableTable
          headerList={tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}>
          {getFilteredList().map((doc) => {
            return (
              <tr key={doc.id}>
                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                  <span>{doc.marketing_campaign_name}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(1) }}>
                  <span>{getDateString(Math.round(doc.start_date_time))}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                  <span>{getDateString(doc.end_date_time)}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                  {doc.target_audience}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                  {currencyConversion(doc.budget ?? 0)}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                  <span>
                    {currencyConversion(doc.gross_profit_potential ?? 0)}
                  </span>
                </TableData>
                {["youtube", "linked_in", "facebook"].includes(tab) && (
                  <TableData customStyle={{ flex: getFlexNumber(6) }}>
                    <span>
                      <button
                        className="icon-btn tdp-edit-icon"
                        onClick={handleMetricsClick}>
                        <img
                          src={MetricsIcon}
                          width={"100%"}
                          height={"100%"}
                          alt="close_btn"
                        />
                      </button>
                    </span>
                  </TableData>
                )}
                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                  <span>
                    <button
                      className="icon-btn tdp-edit-icon"
                      onClick={() => handleEdit(doc)}>
                      <EditIcon
                        width={"100%"}
                        height={"100%"}
                        style={{ color: "#fff" }}
                      />
                    </button>
                  </span>
                </TableData>
              </tr>
            );
          })}
        </SortableTable>
      );
    }
  };

  return (
    <div className="marketing-activity">
      <div className="flex-row main-header">
        {tabs.map((i) => (
          <div
            key={i}
            className={`flex-grow flex-row main-header-item ${
              tab === i ? "selected" : ""
            } ${i === "youtube" ? "youtube-icon" : ""}`}
            style={{
              backgroundColor: getTabColor(i),
              color: getTabTextColor(i),
            }}
            onClick={() => setTab(i)}>
            {getTabIcon(i)}
            <div className="tab-header">{t(i)}</div>
          </div>
        ))}
      </div>
      <div className="tabs flex-row"></div>
      <div className="table-container">
        <div className="actions">
          <div className="table-header">
            <CustomMultiFormSelect
              customStyle={{ width: "initial", marginLeft: "0em" }}
              // label={t("account_manager")}
              // name={"account_manager"}
              label={t("select_user")}
              name={"select_user"}
              value={marketActivityFilter}
              placeholder={"All"}
              list={usersList.map((doc) => ({
                label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                value: doc.id,
              }))}
              onChange={(value) => {
                setMarketActivityFilter(value);
              }}
              disabled={loadingState}
            />
            <div className="dateRange">
              <label>{t("date_range")}</label>
              <div className="date-input-icon">
                <div className="calendar-icon-div">
                  <CalenderIcon
                    width={"100%"}
                    height={"100%"}
                    className="calendar-icon"
                    onClick={() => setDatePickerOpen(true)}
                  />
                </div>
                <DatePicker
                  selectsRange={true}
                  startDate={serviceStartDate}
                  endDate={serviceEndDate}
                  onChange={handleChange}
                  isClearable={loadingState ? false : true}
                  placeholderText={"Start Date -  End Date"}
                  dateFormat={"MM.dd.yyyy"}
                  onClickOutside={() => setDatePickerOpen(false)}
                  open={isDatePickerOpen}
                  onFocus={() => setDatePickerOpen(true)}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                  disabled={loadingState}
                />
              </div>
            </div>
            <div className="total-count-action">
              <span>
                {`${t("total_count")}: `}
                <span className="total-count-number">
                  {getFilteredList()?.length}
                </span>
              </span>
            </div>
          </div>
          <div className="add-campaign">
            <div className="add-campaign-search">
              <SearchBar
                value={search}
                onChange={(value) => setSearch(value)}
                onSearch={()=>{}}
              />
            </div>
            <div>
              <CustomButton
                leftIcon={getPlusBtnIcon()}
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("plan_a_marketing_campaign")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => {
                  setSelectedCall(initialState);
                  setShowCompletePopup(true);
                }}
              />
            </div>
          </div>
        </div>
        {getTable()}
        {showCompletePopup && profileState?.profile && (
          <Portal>
            <PlanMarketingCampaign
              showModal={showCompletePopup}
              setEditPopup={showEditPopup}
              onClose={() => {
                setShowCompletePopup(false);
                dispatch(
                  manageMarketingCampaignActions.clearCreateMarketingCampaignState()
                );
                dispatch(
                  manageMarketingCampaignActions.clearUpdateMarketingCampaignState()
                );
              }}
              marketingCampaignInfo={selectedCall}
              successTitle={t("plan_marketing_campaign")}
              currentLoginUserName={getUserNameFromProfileDropdown(
                profileState?.profile
              )}
              onSuccessClose={handleAddMarketingSuccess}
              onGotoAgencyDashboard={handleGotoAgencyDashboard}
              onGotoCreated={handleOnGotoSalesCall}
              onCreateNew={handleCreateNew}
            />
          </Portal>
        )}
      </div>
    </div>
  );
  function handleAddMarketingSuccess() {
    setShowCompletePopup(false);
    getCampaigns();
    dispatch(
      manageMarketingCampaignActions.clearCreateMarketingCampaignState()
    );
    dispatch(
      manageMarketingCampaignActions.clearUpdateMarketingCampaignState()
    );
    setShowEditPopup(false);
  }

  function handleGotoAgencyDashboard() {
    handleAddMarketingSuccess();
    props.history.push(AppRoutes.dashboardPage);
  }
  function handleCreateNew() {
    dispatch(
      manageMarketingCampaignActions.clearCreateMarketingCampaignState()
    );
  }
  function handleOnGotoSalesCall(id: string, name: string) {
    handleAddMarketingSuccess();
  }
};

export default MarketingActivity;

import { useEffect, useState } from 'react';
import EditIcon from '../../../../assets/icons/edit_button.svg';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg';
import { LoadingType, THEME } from "../../../enums";
import { CustomButton, getPlusBtnIcon } from "../../../utils";
import { useTranslation } from "react-i18next";
import TabOptionsHeader from '../../../components/tab_options_header/tab_options_header';
import SortableTable, { TableData } from '../../../components/sortable_table/sortable_table';
import {
    createEngagementActivityType,
    createEngagementMethod,
    deleteEngagementActivityMethod,
    deleteEngagementActivityType,
    getEngagementActivityMethods,
    getEngagementActivityType,
    selectEngagementActivityMethodList,
    selectEngagementActivityMethodListState,
    selectEngagementActivityTypeList,
    selectEngagementActivityTypeListState,
    updateEngagementActivityMethod,
    updateEngagementActivityType,
    createBusinessSector, getBusinessSectors, updateBusinessSector, deleteBusinessSector,
    selectSalesBusinessSectorState, selectBusinessSectorList,
    selectStatusCodeState, selectStatusCodeList,
    createServiceProfile, getServiceProfile, updateServiceProfile, deleteServiceProfile,
    selectServiceProfileListState, selectServiceProfileList,
    createCheckDelivery, updateCheckDelivery, getCheckDelivery, deleteCheckDelivery,
    selectCheckDeliveryState, selectCheckDeliveryList,
    createOnboardingStep, updateOnboardingStep, getOnboardingSteps, deleteOnboardingStep,
    selectOnboardingStepState, selectOnboardingStepList,
    selectServiceCallState, selectServiceCallList,
    createCompanyStatus, updateCompanyStatus, getCompanyStatus, deleteCompanyStatus,
    selectCompanyStatusState, selectCompanyStatusList,
    createContactStatus, updateContactStatus, getContactStatus, deleteContactStatus,
    selectContactStatusState, selectContactStatusList,
    createTalentStatus, updateTalentStatus, getTalentStatus, deleteTalentStatus,
    selectTalentStatusState, selectTalentStatusList,
    selectJobStatusState, selectJobStatusList,
    createContactMethod, updateContactMethod, getContactMethods, deleteContactMethod,
    selectContactMethodState, selectContactMethodList,
    createPayCycle, updatePayCycle, getPayCycles, deletePayCycle,
    selectPayCycleState, selectPayCycleList,
    selectPayPeriodState, selectPayPeriodList,
    getPipelineStatusThunk,
    createPipelineStatusThunk,
    updatePipelineStatusThunk,
    deletePipelineStatusThunk,
    selectPipelineStatusState,
} from "../../../redux/admin_center";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../redux/store";
import './system_wide_page.scss';
import { Portal } from "react-portal";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../components/table_empty/table_empty";
import CustomFormSelect from '../../../components/form_selector/form_select';
import { DeleteConfirmationDialog } from '../../components/delete_confirmation_dialog';
import { engagementActivityTypeListActions } from '../../../redux/admin_center/system_configuration/engagement_activity_types/engagement_activity_type_list_reducer';
import { engagementActivityMethodListActions } from '../../../redux/admin_center/system_configuration/engagement_activity_methods/engagement_activity_method_list_reducer';
import { businessSectorActions } from '../../../redux/admin_center/system_configuration/business_sector/business_sector_reducer';
import { serviceProfileListActions } from '../../../redux/admin_center/system_configuration/service_profile/service_profile_list_reducer';
import { checkDeliveryActions } from '../../../redux/admin_center/system_configuration/check_delivery/check_delivery_reducer';
import { onboardingStepActions } from '../../../redux/admin_center/system_configuration/onboarding_step/onboarding_step_reducer';
import { companyStatusActions } from '../../../redux/admin_center/system_configuration/company_status/company_status_reducer';
import { contactStatusActions } from '../../../redux/admin_center/system_configuration/contact_status/contact_status_reducer';
import { talentStatusActions } from '../../../redux/admin_center/system_configuration/talent_status/talent_status_reducer';
import { contactMethodActions } from '../../../redux/admin_center/system_configuration/contact_method/contact_method_reducer';
import { payCycleActions } from '../../../redux/admin_center/system_configuration/pay_cycle/pay_cycle_reducer';
import SystemWideAddPopup from './popups/system_wide_add_popup/system_wide_add_popup';
import { pipelineStatusActions } from '../../../redux/admin_center/system_configuration/job_talent_pipeline_status/job_talent_pipeline_status_reducer';

const tableHeader = [
    { title: 'value', code: 'actions' },
    { title: 'actions', code: 'actions' },
];

const tableHeaderStatusCode = [
    { title: 'title', code: 'actions' },
    { title: 'description', code: 'actions' },
    { title: 'actions', code: 'actions' },
];

const categories = {
    1: {
        label: "engagement_activity_types", value: ["engagement_activity_type"], buttonLabel: "add_engagement_activity_type",
        inputLabel: "engagement_activity_type", deleteLabel: "delete_engagement_activity_type",
        deleteMessage: "delete_engagement_activity_type_message", updateLabel: "update_engagement_activity_type",
        methods: {
            create: createEngagementActivityType, update: updateEngagementActivityType, remove: deleteEngagementActivityType,
            get: getEngagementActivityType
        },
        headers: tableHeader,
        clearCreateState: engagementActivityTypeListActions.clearEngagementActivityTypeCreateState,
        clearUpdateState: engagementActivityTypeListActions.clearEngagementActivityTypeUpdateState,
        clearDeleteState: engagementActivityTypeListActions.clearEngagementActivityTypeDeleteState,
        clearDeleteStateError: engagementActivityTypeListActions.clearEngagementActivityTypeDeleteStateError,
    },
    2: {
        label: "engagement_activity_methods", value: ["engagement_activity_method"], buttonLabel: "add_engagement_activity_method",
        inputLabel: "engagement_activity_method", deleteLabel: "delete_engagement_activity_method",
        deleteMessage: "delete_engagement_activity_method_message", updateLabel: "update_engagement_activity_method",
        methods: {
            create: createEngagementMethod, update: updateEngagementActivityMethod, remove: deleteEngagementActivityMethod,
            get: getEngagementActivityMethods
        },
        headers: tableHeader,
        clearCreateState: engagementActivityMethodListActions.clearEngagementActivityMethodCreateState,
        clearUpdateState: engagementActivityMethodListActions.clearEngagementActivityMethodUpdateState,
        clearDeleteState: engagementActivityMethodListActions.clearEngagementActivityMethodDeleteState,
        clearDeleteStateError: engagementActivityMethodListActions.clearEngagementActivityMethodDeleteStateError,
    },
    3: {
        label: "business_sectors", value: ["business_sector"], buttonLabel: "add_business_sector",
        inputLabel: "business_sector", deleteLabel: "delete_business_sector",
        deleteMessage: "delete_business_sector_message", updateLabel: "update_business_sector",
        methods: {
            create: createBusinessSector, update: updateBusinessSector, remove: deleteBusinessSector,
            get: getBusinessSectors
        },
        headers: tableHeader,
        clearCreateState: businessSectorActions.clearBusinessSectorCreateState,
        clearUpdateState: businessSectorActions.clearBusinessSectorUpdateState,
        clearDeleteState: businessSectorActions.clearBusinessSectorDeleteState,
        clearDeleteStateError: businessSectorActions.clearBusinessSectorDeleteStateError,
    },
    5: {
        label: "service_profiles", value: ["service_profile"], buttonLabel: "add_service_profile",
        inputLabel: "service_profile", deleteLabel: "delete_service_profile",
        deleteMessage: "delete_service_profile_message", updateLabel: "update_service_profile",
        methods: {
            create: createServiceProfile, update: updateServiceProfile, remove: deleteServiceProfile,
            get: getServiceProfile
        },
        headers: tableHeader,
        clearCreateState: serviceProfileListActions.clearServiceProfileCreateState,
        clearUpdateState: serviceProfileListActions.clearServiceProfileUpdateState,
        clearDeleteState: serviceProfileListActions.clearServiceProfileDeleteState,
        clearDeleteStateError: serviceProfileListActions.clearServiceProfileDeleteStateError,
    },
    6: {
        label: "check_deliveries", value: ["check_delivery"], buttonLabel: "add_check_delivery",
        inputLabel: "check_delivery", deleteLabel: "delete_check_delivery",
        deleteMessage: "delete_check_delivery_message", updateLabel: "update_check_delivery",
        methods: {
            create: createCheckDelivery, update: updateCheckDelivery, remove: deleteCheckDelivery,
            get: getCheckDelivery
        },
        headers: tableHeader,
        clearCreateState: checkDeliveryActions.clearCheckDeliveryCreateState,
        clearUpdateState: checkDeliveryActions.clearCheckDeliveryUpdateState,
        clearDeleteState: checkDeliveryActions.clearCheckDeliveryDeleteState,
        clearDeleteStateError: checkDeliveryActions.clearCheckDeliveryDeleteStateError,
    },
    7: {
        label: "onboarding_steps", value: ["onboarding"], buttonLabel: "add_onboarding_step",
        inputLabel: "onboarding_step", deleteLabel: "delete_check_delivery",
        deleteMessage: "delete_onboarding_step_message", updateLabel: "update_onboarding_step",
        methods: {
            create: createOnboardingStep, update: updateOnboardingStep, remove: deleteOnboardingStep,
            get: getOnboardingSteps
        },
        headers: tableHeader,
        clearCreateState: onboardingStepActions.clearOnboardingStepCreateState,
        clearUpdateState: onboardingStepActions.clearOnboardingStepUpdateState,
        clearDeleteState: onboardingStepActions.clearOnboardingStepDeleteState,
        clearDeleteStateError: onboardingStepActions.clearOnboardingStepDeleteStateError,
    },
    9: {
        label: "company_status", value: ["company_status", "description"], buttonLabel: "add_company_status",
        inputLabel: "title", deleteLabel: "delete_company_status",
        deleteMessage: "delete_company_status_message", updateLabel: "update_company_status",
        inputLabel2: "description",
        methods: {
            create: createCompanyStatus, update: updateCompanyStatus, remove: deleteCompanyStatus,
            get: getCompanyStatus
        },
        headers: tableHeaderStatusCode,
        clearCreateState: companyStatusActions.clearCompanyStatusCreateState,
        clearUpdateState: companyStatusActions.clearCompanyStatusUpdateState,
        clearDeleteState: companyStatusActions.clearCompanyStatusDeleteState,
        clearDeleteStateError: companyStatusActions.clearCompanyStatusDeleteStateError,
    },
    10: {
        label: "contact_status", value: ["contact_status", "description"], buttonLabel: "add_contact_status",
        inputLabel: "title", deleteLabel: "delete_contact_status",
        deleteMessage: "delete_contact_status_message", updateLabel: "update_contact_status",
        inputLabel2: "description",
        methods: {
            create: createContactStatus, update: updateContactStatus, remove: deleteContactStatus,
            get: getContactStatus
        },
        headers: tableHeaderStatusCode,
        clearCreateState: contactStatusActions.clearContactStatusCreateState,
        clearUpdateState: contactStatusActions.clearContactStatusUpdateState,
        clearDeleteState: contactStatusActions.clearContactStatusDeleteState,
        clearDeleteStateError: contactStatusActions.clearContactStatusDeleteStateError,
    },
    11: {
        label: "talent_status", value: ["talent_status", "description"], buttonLabel: "add_talent_status",
        inputLabel: "title", deleteLabel: "delete_talent_status",
        deleteMessage: "delete_talent_status_message", updateLabel: "update_talent_status",
        inputLabel2: "description",
        methods: {
            create: createTalentStatus, update: updateTalentStatus, remove: deleteTalentStatus,
            get: getTalentStatus
        },
        headers: tableHeaderStatusCode,
        clearCreateState: talentStatusActions.clearTalentStatusCreateState,
        clearUpdateState: talentStatusActions.clearTalentStatusUpdateState,
        clearDeleteState: talentStatusActions.clearTalentStatusDeleteState,
        clearDeleteStateError: talentStatusActions.clearTalentStatusDeleteStateError,
    },
    12: {
        label: "pipeline_status", value: ["value", "description"], buttonLabel: "add_pipeline_status",
        inputLabel: "title", deleteLabel: "delete_pipeline_status",
        deleteMessage: "delete_pipeline_status_message", updateLabel: "update_pipeline_status",
        inputLabel2: "description",
        methods: {
            create: createPipelineStatusThunk, update: updatePipelineStatusThunk, remove: deletePipelineStatusThunk,
            get: getPipelineStatusThunk
        },
        headers: tableHeaderStatusCode,
        clearCreateState: pipelineStatusActions.clearPipelineStatusCreateState,
        clearUpdateState: pipelineStatusActions.clearPipelineStatusUpdateState,
        clearDeleteState: pipelineStatusActions.clearPipelineStatusDeleteState,
        clearDeleteStateError: pipelineStatusActions.clearPipelineStatusDeleteStateError,
    },
    13: {
        label: "contact_methods", value: ["title", "description"], buttonLabel: "add_contact_method",
        inputLabel: "title", deleteLabel: "delete_contact_method",
        deleteMessage: "delete_contact_method_message", updateLabel: "update_contact_method",
        inputLabel2: "description",
        methods: {
            create: createContactMethod, update: updateContactMethod, remove: deleteContactMethod,
            get: getContactMethods
        },
        headers: tableHeaderStatusCode,
        clearCreateState: contactMethodActions.clearContactMethodCreateState,
        clearUpdateState: contactMethodActions.clearContactMethodUpdateState,
        clearDeleteState: contactMethodActions.clearContactMethodDeleteState,
        clearDeleteStateError: talentStatusActions.clearTalentStatusDeleteStateError,
    },
    14: {
        label: "pay_cycles", value: ["pay_cycle", "description"], buttonLabel: "add_pay_cycle",
        inputLabel: "title", deleteLabel: "delete_pay_cycle",
        deleteMessage: "delete_pay_cycle_message", updateLabel: "update_pay_cycle",
        inputLabel2: "description",
        methods: {
            create: createPayCycle, update: updatePayCycle, remove: deletePayCycle,
            get: getPayCycles
        },
        headers: tableHeaderStatusCode,
        clearCreateState: payCycleActions.clearPayCycleCreateState,
        clearUpdateState: payCycleActions.clearPayCycleUpdateState,
        clearDeleteState: payCycleActions.clearPayCycleDeleteState,
        clearDeleteStateError: payCycleActions.clearPayCycleDeleteStateError,
    },
}

const SystemWidePage = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<{visible: boolean, title: string} | null>(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [methodValue, setMethodValue] = useState({
        'id': '', 'value': '', 'description': '',
        'sequence_number': 0, 'agency_id': '', 'is_deleted': false,
        'created_by': '',
        'created_date': 0,
        'modified_by': '',
        'modified_date': 0
    });
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [dropdownChanged, setDropDownChanged] = useState(true);
    const exceptionList = [9, 10, 11, 12, 13, 14, 15];

    const engagementActivityMethodState = useAppSelector((state) => selectEngagementActivityMethodListState(state));
    const engagementActivityMethodList = useAppSelector((state) => selectEngagementActivityMethodList(state));
    const engagementActivityTypeState = useAppSelector((state) => selectEngagementActivityTypeListState(state));
    const engagementActivityTypeList = useAppSelector((state) => selectEngagementActivityTypeList(state));
    const businessSectorState = useAppSelector((state) => selectSalesBusinessSectorState(state));
    const businessSectorList = useAppSelector((state) => selectBusinessSectorList(state));
    const statusCodeState = useAppSelector((state) => selectStatusCodeState(state));
    const statusCodeList = useAppSelector((state) => selectStatusCodeList(state));
    const serviceProfileState = useAppSelector((state) => selectServiceProfileListState(state));
    const serviceProfileList = useAppSelector((state) => selectServiceProfileList(state));
    const checkDeliveryState = useAppSelector((state) => selectCheckDeliveryState(state));
    const checkDeliveryList = useAppSelector((state) => selectCheckDeliveryList(state));
    const onboardingStepState = useAppSelector((state) => selectOnboardingStepState(state));
    const onboardingStepList = useAppSelector((state) => selectOnboardingStepList(state));
    const serviceCallState = useAppSelector((state) => selectServiceCallState(state));
    const serviceCallList = useAppSelector((state) => selectServiceCallList(state));
    const companyStatusState = useAppSelector((state) => selectCompanyStatusState(state));
    const companyStatusList = useAppSelector((state) => selectCompanyStatusList(state));
    const contactStatusState = useAppSelector((state) => selectContactStatusState(state));
    const contactStatusList = useAppSelector((state) => selectContactStatusList(state));
    const talentStatusState = useAppSelector((state) => selectTalentStatusState(state));
    const talentStatusList = useAppSelector((state) => selectTalentStatusList(state));
    const jobStatusState = useAppSelector((state) => selectJobStatusState(state));
    const jobStatusList = useAppSelector((state) => selectJobStatusList(state));
    const contactMethodState = useAppSelector((state) => selectContactMethodState(state));
    const contactMethodList = useAppSelector((state) => selectContactMethodList(state));
    const payCycleState = useAppSelector((state) => selectPayCycleState(state));
    const payCycleList = useAppSelector((state) => selectPayCycleList(state));
    const payPeriodState = useAppSelector((state) => selectPayPeriodState(state));
    const payPeriodList = useAppSelector((state) => selectPayPeriodList(state));
    const pipelineStatusState = useAppSelector((state) => selectPipelineStatusState(state));

    const reduxMapping = {
        1: { state: engagementActivityTypeState, list: engagementActivityTypeList },
        2: { state: engagementActivityMethodState, list: engagementActivityMethodList },
        3: { state: businessSectorState, list: businessSectorList },
        4: { state: statusCodeState, list: statusCodeList },
        5: { state: serviceProfileState, list: serviceProfileList },
        6: { state: checkDeliveryState, list: checkDeliveryList },
        7: { state: onboardingStepState, list: onboardingStepList },
        8: { state: serviceCallState, list: serviceCallList },
        9: { state: companyStatusState, list: companyStatusList },
        10: { state: contactStatusState, list: contactStatusList },
        11: { state: talentStatusState, list: talentStatusList },
        12: { state: pipelineStatusState, list: pipelineStatusState?.response },
        13: { state: contactMethodState, list: contactMethodList },
        14: { state: payCycleState, list: payCycleList },
        15: { state: payPeriodState, list: payPeriodList },
        16: { state: jobStatusState, list: jobStatusList },
    }

    useEffect(() => {
        if (getMapping()?.state?.create?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.create?.response));
            dispatch(getCategory()?.clearCreateState())
        }
        else if (getMapping()?.state?.update?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.update?.response));
            dispatch(getCategory()?.clearUpdateState())
        }
        else if (getMapping()?.state?.remove?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.remove?.response));
            dispatch(getCategory()?.clearDeleteState())
        }
    }, [
        getMapping()?.state?.create?.loading, 
        getMapping()?.state?.update?.loading, 
        getMapping()?.state?.remove?.loading
    ]);

    function getCategory(id = null) {
        if (id)
            return categories[id];
        return categories[selectedCategory];
    }

    function getMapping(id = null) {
        if (id)
            return reduxMapping[id];
        return reduxMapping[selectedCategory];
    }

    function handleCreateCloseDialog() {
        setShowCreateDialog(false);
    }

    function handleCloseDeleteDialog() {
        setShowDeleteDialog(null);
        setDeleteItemId(null);
    }

    function handleShowDeleteDialog(id, title) {
        setShowDeleteDialog({visible: true, title: title});
        setDeleteItemId(id);
    }

    function handleDeleteApprove() {
        if (deleteItemId) {
            dispatch(getCategory().methods.remove(deleteItemId));
        }
        handleCloseDeleteDialog();
    }

    function handleShowEditDialog(method) {
        setShowEditDialog(true);
        if (exceptionList.includes(selectedCategory)) {
            setMethodValue({
                ...methodValue, 'id': method.id, 'value': method[getCategory().value[0]],
                'description': method[getCategory().value[1]], 'sequence_number': method['sequence_number'],
                'agency_id': method['agency_id'], 'is_deleted': method['is_deleted'], 'created_by': method['created_by'],
                'created_date': method['created_date'], 'modified_by': method['modified_by'], 'modified_date': method['modified_date']
            });
        } 
        else
            setMethodValue({ ...methodValue, 'id': method.id, 'value': method[getCategory().value] });
    }

    function handleCloseEditDialog() {
        setShowEditDialog(false);
    }

    function handleDropdownChange(value) {
        if (!value)
            value = '1';
        setDropDownChanged(true);
        setSelectedCategory(Number(value));
        dispatch(getCategory(value).methods.get());
    }

    function retryFetchingData() {
        setDropDownChanged(true);
        dispatch(getCategory().methods.get());
    }

    useEffect(() => {
        if (getMapping().state.loading == LoadingType.succeeded) {
            setDropDownChanged(false);
        }
    }, [getMapping().state.loading])

    useEffect(() => {
        if (getMapping().state.remove.loading == LoadingType.succeeded)
            dispatch(getCategory().methods.get());
    }, [getMapping().state.remove.loading])

    useEffect(() => {
        dispatch(getCategory().methods.get());
        return () => {

        }
    }, [])

    return (
        <div className="ac-sc-container">
            <div className="ac-sc-header">
                <div className="ac-sytm-cns-header">
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="ac-sc-dropdown">
                <div className="ac-sc-dropdown-button">
                    <CustomFormSelect
                        name={t("talent_dropdown")}
                        list={Object.keys(categories).map(id => ({ value: id, label: categories[id].label }))}
                        onChange={(val) => handleDropdownChange(val)}
                        value={selectedCategory}
                        placeholder={t('engagement_activity_types')}
                        required={false}
                        label={t('please_select_system_wide_category')}
                        customStyle={{ marginTop: "0 !important", maxWidth: "20vw" }}
                    />
                    <div>
                        {![8].includes(selectedCategory) ?
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                enable={true}
                                leftIcon={getPlusBtnIcon()}
                                name={t(categories[selectedCategory].buttonLabel)}
                                backgroundColor={THEME.buttonColor1}
                                onClick={() => (setShowCreateDialog(true))}
                                customStyle={{ width: "max-content" }}
                            /> : <></>}
                    </div>
                </div>
            </div>
            <div className="ac-sc-table">
                {getMapping().state.loading == LoadingType.pending && dropdownChanged ? <TableLoading /> :
                    <TableBody
                        state={getMapping().state}
                        onRetry={retryFetchingData}
                        data={getMapping().list}
                        category={getCategory()}
                        handleShowEdit={handleShowEditDialog}
                        handleShowDelete={handleShowDeleteDialog}
                    />
                }
            </div>
            {showCreateDialog && <Portal>
                <SystemWideAddPopup
                    visible={showCreateDialog}
                    onClose={handleCreateCloseDialog}
                    onDismiss={handleCreateCloseDialog}
                    category={getCategory()}
                    category_id={selectedCategory}
                    reduxMapping={reduxMapping} 
                    exceptionList={exceptionList} 
                    errorState={reduxMapping[selectedCategory]?.create?.error}
                />
            </Portal>}
            {showDeleteDialog && (
                <Portal>
                    <DeleteConfirmationDialog
                        message={t(getCategory()?.deleteMessage, { value: showDeleteDialog?.title })}
                        onCancel={() => setShowDeleteDialog(null)}
                        onConfirm={handleDeleteApprove}
                        deleting={getMapping()?.state?.remove === LoadingType.pending}
                        deleteType={""}
                        customDeleteHeaderTitle={t(getCategory()?.deleteLabel)}
                        state={getMapping()?.state}
                        onClearError={getCategory()?.clearDeleteStateError()}
                    />
                </Portal>
            )}
            {showEditDialog && <Portal>
                <SystemWideAddPopup
                    visible={showEditDialog}
                    onClose={handleCloseEditDialog}
                    onDismiss={handleCloseEditDialog}
                    category={getCategory()}
                    methodValue={methodValue}
                    category_id={selectedCategory}
                    reduxMapping={reduxMapping}
                    exceptionList={exceptionList}
                    errorState={reduxMapping[selectedCategory]?.update?.error}
                />
            </Portal>}
        </div>

    )
}

const TableBody = ({ state, onRetry, data, category, handleShowEdit, handleShowDelete }) => {
    const ignoreActions = ["Need To Review", "Client Interview Scheduled", "Not A Fit"]
    function getActions(doc) {
        if (category.label === 'service_calls')
            return <td style={{ "display": "none" }}></td>;
        else if (category.label === 'job_status')
            return <td></td>
        else
            return (
                <TableData>
                    {ignoreActions.includes(doc.value) ? <></> :
                        <><img src={EditIcon} alt='edit_button' style={{ marginRight: '20px' }}
                            onClick={() => handleShowEdit(doc)} />
                            <img src={DeleteIcon} alt='delete_button'
                                onClick={() => handleShowDelete(doc.id, doc[category.value[0]])}
                            />
                        </>
                    }
                </TableData>
            )
    }

    if (state.loading == LoadingType.failed) {
        return (<TableErrorHandler error={state.error} onRefresh={onRetry} />)
    }
    else if (data.length == 0) {
        return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    }
    else {
        return (
            <SortableTable headerList={category.headers}>
                {data.map((doc, index) => {
                    return (
                        <tr key={doc.id}>
                            {category.value.map((value, index) => {
                                return (
                                    <TableData key={doc.id + index} customStyle={{ wordWrap: "break-word" }}>
                                        <span>{doc[value]}</span>
                                    </TableData>
                                )
                            })}
                            {getActions(doc)}
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }
}

export default SystemWidePage;
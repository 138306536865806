import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import { useAppDispatch } from "../../../../redux/store";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { CircleDownloadIcon } from "../../../../icons";
import { CustomButton, SpinnerScreen } from "../../../../utils";
import { lowBtnStyle, outlineBtnStyle } from "../../../../variables";
import { LoadingType, THEME } from "../../../../enums";
import "./import_people_popup.scss";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { AppError } from "../../../../interfaces";
import ApiError from "../../../../components/api_error";
import { IApolloImportResponse } from "../../../../interfaces/apollo_search_import";
interface Props {
    visible: boolean;
    title: string;
    successTitle: string;
    currentLoginUserName: string | undefined;
    selectedNames: (string | undefined)[];
    data?: { company_id: string, department_ids?: string[] };
    onClose: () => void;
    onDissmiss?: () => void;
    onGotoCreated: (id: string, name: string | undefined) => void;
    importResponse: IApolloImportResponse | undefined;
    importLoading: LoadingType;
    source: string;
    handleOnImport: (isDirectDial: boolean, forceImport: boolean) => void;
    error: AppError | null | undefined;
    onClearError: () => void;
    selectedCount: number;
}
const ImportPeoplePopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onGotoCreated,
        successTitle,
        currentLoginUserName,
        selectedNames,
        importResponse,
        importLoading,
        source,
        handleOnImport,
        error,
        onClearError,
        selectedCount
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);

    const fieldName = source === "companies" ? t("company") : (source === "talent" ? t("talent") : t("contact"));
    const [dialStatus, setDialStatus] = useState<boolean>(false);
    const [companyDialStatus, setCompanyDialStatus] = useState<boolean>(true);

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"40%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="import-f-container">
                    <div className="import-f-header">
                        <FormDialogTilteHeader
                            title={successTitle}
                            titleIcon={<CircleDownloadIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="import-f-content">
                        <div className="saved-doc-holder">
                            {importLoading !== LoadingType.succeeded &&
                                (
                                    <div>
                                        {source !== "companies" && (
                                            <>
                                                <div className="deducted-count">
                                                    <span >{t('credits_to_deduct')}:&nbsp;<b>{dialStatus ? (3 * selectedCount) : (2 * selectedCount)}</b></span>
                                                </div>
                                                <FormControl component="fieldset">
                                                    {/* <FormLabel component="legend" className="imp-radio-label">{t('credit_note')}</FormLabel> */}
                                                    <RadioGroup aria-required aria-label="direct_not_dial" name="direct_not_dial" value={dialStatus}
                                                        onChange={(event) => setDialStatus(event.target.value === "true")} className="radio-group-div">
                                                        <FormControlLabel value={false} control={<Radio />} label={t("not_direct_dial", { name: source })} />
                                                        <FormControlLabel value={true} control={<Radio />} label={t("direct_dial", { name: source })} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </>
                                        )}
                                        {source === "companies" && (

                                            <div className="confirm-msg">
                                                <div className="deducted-count">
                                                    <span >{t('credits_to_deduct')}:&nbsp;<b>{(1 * selectedCount)}</b></span>
                                                </div>
                                                {/* <span>{t("sure_to_import_company")}</span>
                                                <div>
                                                    {selectedNames.map(name => {
                                                        return (
                                                            <><li className="li-name">{name}</li></>
                                                        )
                                                    })}
                                                </div> */}
                                                <FormControl component="fieldset">
                                                    {/* <FormLabel component="legend" className="imp-radio-label">{t('credit_note')}</FormLabel> */}
                                                    <RadioGroup aria-required aria-label="company_import_Credit" name="company_import_Credit" value={companyDialStatus}
                                                        onChange={(event) => setCompanyDialStatus(event.target.value === "true")} className="radio-group-div">
                                                        {/* <FormControlLabel value={false} control={<Radio />} label={t("not_direct_dial", { name: source })} /> */}
                                                        <FormControlLabel value={true} control={<Radio />} label={t("import_company_credit", { name: source })} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        )}

                                    {error && (
                                        <div className={"error-section"}>
                                            <ApiError
                                                message={error.code === 409 ? error.message.msg : error.message}
                                                onClose={onClearError}
                                            />
                                        </div>
                                    )}
                                    </div>
                                )
                            }
                            {importLoading === LoadingType.pending &&
                                <SpinnerScreen />
                            }
                            {importLoading === LoadingType.succeeded &&
                                <DocSavedText>
                                    <span className="aknw-name">
                                        {t("well_done_on_importing_new", {
                                            field: fieldName,
                                            name: currentLoginUserName,
                                        })}
                                    </span>
                                    <span className="info-txt">{`${t("a_new_imported", {
                                        name: fieldName,
                                    })}:`}</span>
                                    {importResponse && importResponse.import_status.dd.length > 0 &&
                                        (
                                            <>
                                                {source !== "companies" && <span className="resp-msg">{t("imported_with_direct_dial")}</span>}
                                                {importResponse?.import_status.dd.map(item => {
                                                    return (
                                                        <><li className="gotoName" onClick={() => handleGotoCreated(item.id, item.name)}>{item.name} - {item.company_name}</li></>
                                                    )
                                                })}</>)
                                    }
                                    {importResponse && importResponse.import_status.no_dd.length > 0 &&
                                        (
                                            <>
                                                <span className="resp-msg">{t("imported_without_direct_dial")}</span>
                                                {importResponse?.import_status.no_dd.map(item => {
                                                    return (
                                                        <><li className="gotoName" onClick={() => handleGotoCreated(item.id, item.name)}>{item.name}  - {item.company_name}</li></>
                                                    )
                                                })}</>)
                                    }
                                </DocSavedText>}
                        </div>
                    </div>
                    <div >
                        {importLoading !== LoadingType.succeeded &&
                            <div className="import-f-actions">
                                <div className="btn-cancel" style={{ marginRight: "1em" }}>
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={t("cancel")}
                                        enable={true}
                                        backgroundColor={THEME.toggleDisableColor}
                                        onClick={onClose}
                                    />
                                </div>
                                <div className="btn-save">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={
                                            error !== null && error?.code === 409 ?
                                                t("force_import")
                                                : t("import")}
                                        enable={true}
                                        backgroundColor={error !== null && error?.code === 409
                                            ? THEME.secondaryColor6
                                            : THEME.defaultHighLightColor}
                                        onClick={() => handleOnImport(dialStatus, error !== null && error?.code === 409
                                            ? true
                                            : false)}
                                    />
                                </div>
                            </div>
                        }

                        {importLoading === LoadingType.succeeded &&
                            (
                                <div className="import-f-actions">
                                    <div className="btn-cancel" style={{ marginRight: "1em" }}>
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("cancel")}
                                            enable={true}
                                            backgroundColor={THEME.toggleDisableColor}
                                            onClick={onClose}
                                        />
                                    </div>
                                    <div className="btn-new">
                                        <CustomButton
                                            loading={false}
                                            textStyle={{
                                                textTransform: "capitalize",
                                                ...outlineBtnStyle.text,
                                            }}
                                            name={t("continue_search", { name: fieldName })}
                                            enable={true}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={onClose}
                                            customStyle={outlineBtnStyle.btn}
                                        />
                                    </div>
                                    {/* <div className="btn-save">
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("go_to_this_newly_imported", { name: fieldName })}
                                            enable={true}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={handleGotoCreated}
                                        />
                                    </div> */}
                                </div>
                            )}

                    </div>
                </div>
            </DialogWrapper>

        </ActionDialogHolder>
    );

    function handleGotoCreated(id: string, name: string) {
        if (importLoading === LoadingType.succeeded && importResponse !== undefined && id !== "" && id !== null) {
            closePopup(() => onGotoCreated(id, name));
        }
    }
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
};

export default ImportPeoplePopup;
import { useTranslation } from "react-i18next";
import SocialMediaProfile from "../../components/social_media_profile/social_media_profile";
import {
  CallIcon,
  CompaniesIcon,
  EmailIcon,
  LocationIcon,
} from "../../icons";
import {
  addressParser,
  CustomButton,
  getUserNameFromProfileDropdown,
} from "../../utils";
import "./contact_details_section.scss";
import { LoadingType, ProfilePictureType, THEME } from "../../enums";
import UploadProfile from "../../components/image_crop/image_crop_popup";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
  getPreSignedURLForDownload,
  selectSingleProfilePictureStateById,
} from "../../redux/store";
import { Portal } from "react-portal";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../routes";
import { IContact } from "../../interfaces";
import { FieldNav } from "../../components/field_nav/field_nav";
import UserAvatar from "../../components/user_avatar/user_avatar";
import { ProfileState } from "../../redux/profile/profile_reducer";
import SendEmailPopup from "../components/send_email/send_email_popup";
import { sendEmailActions } from "../../redux/send_email/send_email_reducer";

interface Props {
  contactInfo: IContact | null;
  profile: ProfileState;
  // onShowRefreshPopup: Dispatch<SetStateAction<boolean>>;
  // availCredits: number | undefined,
  // onAddCredit: Dispatch<SetStateAction<boolean>>;
}

const ContactHeader = (props) => {
  const { name, uri, rating, level } = props;
  const dispatch = useAppDispatch();
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [sentRequest, setSentRequest] = useState(false);
  const [imageURL, setImageURL] = useState("");

  const profilePictureState = useAppSelector((state) =>
    selectSingleProfilePictureStateById(
      ProfilePictureType.contact + "-" + props.objectId
    )(state)
  );

  useEffect(() => {
    setSentRequest(true);
    dispatch(
      getPreSignedURLForDownload({
        object_id: props.objectId,
        object_type: ProfilePictureType.contact,
      })
    );
  }, []);

  function updateProfile() {
    dispatch(
      getPreSignedURLForDownload({
        object_id: props.objectId,
        object_type: ProfilePictureType.contact,
      })
    );
    setSentRequest(true);
  }

  useEffect(() => {
    if (
      sentRequest &&
      profilePictureState.download.loading === LoadingType.succeeded
    ) {
      setSentRequest(false);
      setImageURL(profilePictureState.download.response ?? "");
    }
  }, [profilePictureState.download.loading]);

  return (
    <div className="cont-ds-user-info">
      <UserAvatar
        onClick={() => setShowImageUpload(true)}
        url={imageURL}
        rating={{ value: rating, level: level }}
      />
      <div className="cont-ds-user-name">
        <span>{name}</span>
      </div>
      {showImageUpload && (
        <Portal>
          <UploadProfile
            onClose={() => setShowImageUpload(false)}
            onDismiss={() => setShowImageUpload(false)}
            visible={showImageUpload}
            onApprove={updateProfile}
            objectId={props.objectId}
            objectType={ProfilePictureType.contact}
          />
        </Portal>
      )}
    </div>
  );
};

const ContactDetailsSection: React.FunctionComponent<Props> = (props) => {
  const {
    contactInfo,
    profile,
    //  onShowRefreshPopup, availCredits, onAddCredit
  } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showSendEmail, setShowSendEmail] = useState(false);
  const scheduleSalesCall = () => {
    history.push({
      pathname: AppRoutes.salesAndMarketingSalesCallActivity,
      state: {
        companyId: contactInfo?.company_id,
        departmentId: contactInfo?.department_ids
          ? contactInfo?.department_ids[0]
          : null,
        contactId: contactInfo?.id,
        planCall: true,
        planned_by_name: getUserNameFromProfileDropdown(profile?.profile!),
        source: "contacts-details",
      },
    });
  };

  function handleCompanySelect(company_id, company_name) {
    history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${company_id}`,
      state: {
        id: company_id,
        name: company_name,
      },
    });
    return;
  }

  function handleDepartmentSelect(value: IContact, index) {
    history.push({
      pathname:
        value.department_ids[index] === undefined
          ? `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/`
          : `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_ids[index]}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.department_ids[index],
        departmentName: value.department_names[index],
      },
    });
    return;
  }

  function handleStatusClick() {
    history.push({
      pathname: `${AppRoutes.contactsUniversalDirectory}/${contactInfo?.id}`,
      state: {
        id: contactInfo?.id,
        status: contactInfo?.status_id,
      },
    });
    return;
  }

  const handleClearError = () => {
    dispatch(sendEmailActions.clearSendEmailStateError());
  };

  return (
    <div className="cont-ds-container">
      <ContactHeader
        name={`${contactInfo?.first_name ?? ""} ${
          contactInfo?.last_name ?? ""
        } `}
        objectId={contactInfo?.id}
        rating={contactInfo?.engagement_rating}
        level={contactInfo?.engagement_level}
      />
      <div className="cont-ds-branch-selector">
        <div className="cont-ds-branch-left">
          <div className="cont-ds-status">
            <span>
              {`${t("STATUS")}`}
            </span>
            <span className="cont-ds-status-txt"
                onClick={
                  contactInfo?.status !== "" && contactInfo?.status !== null
                    ? handleStatusClick
                    : () => { }
            }>
              {contactInfo?.status ?? ""}
            </span>
          </div>
        </div>
        <div className="cont-ds-branch-right">
          <div className="cont-ds-status">
            <span>
              {`${t("contact_id")}`}
              </span>
              <span
                className="cont-ds-id-txt">
                {contactInfo?.id_number ? contactInfo.id_number : ""}
              </span>
          </div>
        </div>

        {/* {contactInfo && contactInfo.imported_from_apollo &&
          (<div>
            <CustomButton
              leftIcon={(<div className="err-h-add-icon">
                <ReloadIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
              </div>)}
              loading={false}
              textStyle={{ textTransform: 'capitalize' }}
              name={t('refresh_contact')}
              enable={(availCredits! > 2)}
              backgroundColor={THEME.buttonColor15}
              onClick={() => onShowRefreshPopup(true)}
              customClassName={'refresh_contact'}
            />
            {availCredits! <= 2 && (
              <div className='no-creds-div'>
                <div className='no-creds'>{t('no_credits_available')}</div>
                <div>
                  <div onClick={() => onAddCredit(true)} className='add-creds-div'>
                    {t("add_credits")}
                  </div>

                </div>
              </div>
            )}
          </div>)} */}
      </div>
      <div className="cont-ds-info-container">
        <div className="cont-ds-info-icon">
          <CompaniesIcon width={"100%"} height={"100%"} />
        </div>
        <div className="cont-ds-info-content">
          <div className="cont-ds-info-holder">
            <div className="cont-ds-into-title">
              <span>{t("company_name").toUpperCase()}</span>
            </div>
            {contactInfo?.company_id !== null &&
            contactInfo?.company_id !== "" ? (
              <FieldNav 
              customStyle={{marginBottom: "0.5em"}}
                isButton={true}
                onClick={() =>
                  handleCompanySelect(
                    contactInfo?.company_id,
                    contactInfo?.company_name
                  )
                }>
                <span>{contactInfo?.company_name}</span>
              </FieldNav>
            ) : (
              <span className="na-span">NA</span>
            )}

            <div className="cont-ds-into-title">
              <span>{t("department_name").toUpperCase()}</span>
            </div>
            {contactInfo?.company_id !== null &&
            contactInfo?.department_names &&
            contactInfo?.department_names.length !== 0 ? (
              <>
                {contactInfo?.department_names.map((d, index, arr) => (
                  <FieldNav
                    isButton={true}
                    customStyle={{marginBottom: "0.5em"}}
                    onClick={() => handleDepartmentSelect(contactInfo, index)}>
                    <span>
                      {d} {index !== arr.length - 1 ? ", " : ""}
                    </span>
                  </FieldNav>
                ))}
              </>
            ) : (
              <>
                {contactInfo?.company_id !== null ? (
                  <span className="cont-ds-info-value">{t("corporate")}</span>
                ) : (
                  <span className="na-span">NA</span>
                )}
              </>
            )}

            <div className="cont-ds-into-title">
              <span>{t("job_title").toUpperCase()}</span>
            </div>
            <div className="cont-ds-info-value">
              {contactInfo?.job_title !== null &&
              contactInfo?.job_title !== "" ? (
                contactInfo?.job_title
              ) : (
                <span className="na-span">NA</span>
              )}
            </div>
            <div className="cont-ds-into-title">
              <span>{t("contact_role").toUpperCase()}</span>
            </div>
            <div className="cont-ds-info-value">
              {contactInfo?.role !== null &&
              contactInfo?.role !== "" ? (
                contactInfo?.role
              ) : (
                <span className="na-span">NA</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="cont-ds-info-container">
        <div className="cont-ds-info-icon">
          <LocationIcon width={"100%"} height={"100%"} />
        </div>
        <div className="cont-ds-info-content">
          <div className="cont-ds-info-holder">
            <div className="cont-ds-into-title">
              {/* <span>{t("address").toUpperCase()}</span> */}
            </div>
            <div className="cont-ds-info-value">
              {addressParser({
                street_address: contactInfo
                  ? contactInfo["street_address"]
                  : null,
                street_address2: contactInfo
                  ? contactInfo["street_address2"]
                  : null,
                city: contactInfo ? contactInfo["city"] : null,
                state: contactInfo ? contactInfo["state"] : null,
                zip_code: contactInfo ? contactInfo["zip_code"] : null,
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        className="cont-ds-info-container"
        style={{ border: "none", paddingTop: "0" }}>
        <div className="cont-ds-info-icon">
          <CallIcon
            width={"100%"}
            height={"100%"}
            style={{ color: THEME.primaryColor }}
          />
        </div>
        <div className="cont-ds-info-content">
          <div className="cont-ds-info-holder">
            <div className="cont-ds-into-title">
              {/* <span>{t("contact").toUpperCase()}</span> */}
            </div>
            <div className="cont-ds-info-value">
              {contactInfo?.mobile_no && (
                <div>
                  <span style={{ fontWeight: "normal" }}>{t("mobile")}</span>
                  <span style={{ marginLeft: "5px" }}>
                    {contactInfo?.mobile_no}
                  </span>
                </div>
              )}
              {contactInfo?.office_no && (
                <div>
                  <span style={{ fontWeight: "normal" }}>{t("office_no")}</span>
                  <span style={{ marginLeft: "5px" }}>
                    {contactInfo?.office_no}
                  </span>
                </div>
              )}
              {(contactInfo?.mobile_no == null ||
                contactInfo?.mobile_no == "") &&
                (contactInfo?.office_no == null ||
                  contactInfo?.office_no == "") && (
                  <span className="na-span">NA</span>
                )}
            </div>
          </div>
        </div>
      </div>

      <div className="cont-ds-info-container" style={{ marginBottom: "0.5em" }}>
        <div className="cont-ds-info-icon">
          <EmailIcon
            width={"100%"}
            height={"100%"}
            style={{ color: THEME.primaryColor }}
          />
        </div>
        <div className="cont-ds-info-content">
          <div className="cont-ds-info-holder">
            <div className="cont-ds-into-title">
              <span>{t("work_email").toUpperCase()}</span>
            </div>
            <div className="cont-ds-info-value">
              {contactInfo?.email !== null && contactInfo?.email !== "" ? (
                <div
                  onClick={() => setShowSendEmail(true)}
                  className="info-value">
                  <span>{contactInfo?.email}</span>
                </div>
              ) : (
                <span className="na-span">NA</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="cont-ds-info-container"
        style={{ border: "none", paddingTop: "0", marginBottom: "0.5em" }}>
        <div className="cont-ds-info-icon">
          <EmailIcon
            width={"100%"}
            height={"100%"}
            style={{ color: THEME.primaryColor }}
          />
        </div>
        <div className="cont-ds-info-content">
          <div className="cont-ds-info-holder">
            <div className="cont-ds-into-title">
              <span>{t("personal_email").toUpperCase()}</span>
            </div>
            <div className="cont-ds-info-value">
              <div className="info-value">
                {contactInfo?.email_2 !== null &&
                contactInfo?.email_2 !== "" ? (
                  <span onClick={() => setShowSendEmail(true)}>
                    {contactInfo?.email_2}
                  </span>
                ) : (
                  <span className="na-span">NA</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSendEmail && (
        <Portal>
          <SendEmailPopup
            visible={showSendEmail}
            data={{
              obj_id: contactInfo?.id!,
              email: contactInfo?.email!,
              email_2: contactInfo?.email_2!,
            }}
            objectType={ProfilePictureType.contact}
            onClose={() => setShowSendEmail(false)}
            onClearError={handleClearError}
          />
        </Portal>
      )}

      <SocialMediaProfile
        title={t("social_media").toUpperCase()}
        profile={contactInfo?.social_media_links!}
      />
      <CustomButton
        loading={false}
        textStyle={{ textTransform: "capitalize" }}
        name={t("schedule_a_sales_call")}
        enable
        backgroundColor={"#1198AB"}
        onClick={scheduleSalesCall}
        leftIcon={
          <CallIcon width={"17px"} height={"17px"} style={{ color: "white" }} />
        }
      />
    </div>
  );
};

export default ContactDetailsSection;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";
import { TalentAPI } from "../../../../../apis";
import { IMultipleMove, ITalentPipelineQuery } from "../../../../../interfaces/talent_pipeline";
import { JobOrderPipeline } from "../../../../../interfaces/job_order_pipeline";

export const getJobOrderPipelineDataThunk = createAsyncThunk<
    JobOrderPipeline[],
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/job-order-pipeline/get',
    async (jobOrderId, thunkAPI) => {
        try {
            const res = await TalentAPI.getJobOrderPipelineData(jobOrderId);
            return res.data as JobOrderPipeline[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

// export const updateJobOrderPipelineStatus = createAsyncThunk<
//     string,
//     ITalentPipelineQuery,
//     {
//         rejectValue: AppError | null,
//         rejectedMeta: AppError | null,
//     }
// >(
//     '@jobs/details/job-order-pipeline/update-pipeline-status/patch',
//     async (doc, thunkAPI) => {
//         try {
//             const res = await TalentAPI.changeJobOrderPipelineStatus(doc.job_id, doc.talent_id, doc.status_id ?? "");
//             return res.data as JobOrderPipeline[];
//         } catch (error) {
//             return catchRequestError(error, thunkAPI);
//         }
//     }
// );

// export const multipleJobOrdersQueueThunk = createAsyncThunk<
//     string,
//     IMultipleMove[],
//     {
//         rejectValue: AppError | null,
//         rejectedMeta: AppError | null,
//     }
// >(
//     '@jobs/details/job-order-pipeline/move-multiple-job-orders-queue/patch',
//     async (doc, thunkAPI) => {
//         try {
//             const res = await TalentAPI.multipleJobOrdersQueue(doc);
//             return res.data;
//         } catch (error) {
//             return catchRequestError(error, thunkAPI);
//         }
//     }
// );

// export const multipleJobOrdersTrashThunk = createAsyncThunk<
//     string,
//     IMultipleMove[],
//     {
//         rejectValue: AppError | null,
//         rejectedMeta: AppError | null,
//     }
// >(
//     '@jobs/details/job-order-pipeline/move-multiple-job-orders-trash/patch',
//     async (doc, thunkAPI) => {
//         try {
//             const res = await TalentAPI.multipleJobOrdersTrash(doc);
//             return res.data;
//         } catch (error) {
//             return catchRequestError(error, thunkAPI);
//         }
//     }
// );
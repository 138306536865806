import { t } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import SectionCard from "../../../../../components/section_card/section_card";
import { LoadingType, ROLE, THEME } from "../../../../../enums";
import { ExclaimOrangeIcon, TickGreenIcon } from "../../../../../icons";
import {
  AppError,
  IOnboardingDocsReviewStatus,
  ITalent,
} from "../../../../../interfaces";
import {
  getRoleUsersList,
  getTalentODRSThunk,
  selectProfileState,
  selectRoleUsersList,
  selectSingleTalentODRSStateById,
  updateTalentODRSThunk,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store";
import {
  CustomButton,
  SpinnerScreen,
  getPlusBtnIcon,
  paperPlaneBtnIcon,
} from "../../../../../utils";
import DirectDepositPopup from "../../../docs/direct_deposit/direct_deposit";
import EmployeeHandbookPopup from "../../../docs/employee_handbook/employee_handbook";
import I9Popup from "../../../docs/i9/i9";
import NoticeOfHealthcareExchangePopup from "../../../docs/notice_of_healthcare_exchange/notice_of_healthcare_exchange";
import W4Popup from "../../../docs/w4/w4";
import "./onboarding_docs.scss";
import SendOnBoardingLinkPopup from "../../../../dashboard/popups/send_onboarding_link/send_onboarding_link";
import { sendOnBoardingLinksStateActions } from "../../../../../redux/dashboard/metrics/send_onboarding_link/send_onboarding_link_reducer";
import WOTCPopup from "../../../docs/wotc/wotc";

interface ODRSOption {
  title: string;
  isReviewd: boolean;
  type: string;
  visible: boolean;
  enable: boolean;
}

interface Props {
  talentId: string;
  talentData: ITalent | null;
}

const OnboardingDocs: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const {
    talentId,
    talentData,
  } = props;
  const state = useAppSelector((state) =>
    selectSingleTalentODRSStateById(talentId)(state)
  );

  const [showSendOnBoardingLink, setShowSendOnBoardingLink] =
    useState<boolean>(false);
  const recruitersList = useAppSelector((state) => selectRoleUsersList(state));
  useEffect(() => {
    dispatch(getRoleUsersList());
    return () => { };
  }, []);

  const [options, setOptions] = useState<{ [key: string]: ODRSOption }>({
    direct_deposit_document: {
      type: "direct_deposit_document",
      title: t("direct_deposit"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
    handbook_document: {
      type: "handbook_document",
      title: t("employee_handbook"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
    notice_of_healthcare_exchange_document: {
      type: "notice_of_healthcare_exchange_document",
      title: t("notice_of_healthcare_exchange"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
    wotc_document: { type: "wotc_document", title: t('wotc'), isReviewd: false, visible: false, enable: true },
    w4_document: {
      type: "w4_document",
      title: t("w4"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
    i9_document: {
      type: "i9_document",
      title: t("i9"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
    // essential_staffcare_document: { type: "essential_staffcare_document", title: t('essential_staff_care'), isReviewd: false, visible: false, enable: true },


  });

  useEffect(() => {
    if (state.data) {
      const {
        direct_deposit_document,
        handbook_document,
        // essential_staffcare_document,
        wotc_document,
        notice_of_healthcare_exchange_document,
        w4_document,
        i9_document,
      } = state.data;
      setOptions({
        ...options,
        direct_deposit_document: {
          ...options.direct_deposit_document,
          isReviewd: direct_deposit_document.recruiter_completed_review,
        },
        handbook_document: {
          ...options.handbook_document,
          isReviewd: handbook_document.recruiter_completed_review,
        },
        notice_of_healthcare_exchange_document: {
          ...options.notice_of_healthcare_exchange_document,
          isReviewd:
            notice_of_healthcare_exchange_document.recruiter_completed_review,
        },
        w4_document: {
          ...options.w4_document,
          isReviewd: w4_document.recruiter_completed_review,
        },
        i9_document: {
          ...options.i9_document,
          isReviewd: i9_document.recruiter_completed_review,
        },
        // essential_staffcare_document: { ...options.essential_staffcare_document, isReviewd: essential_staffcare_document.recruiter_completed_review },
        wotc_document: { ...options.wotc_document, isReviewd: wotc_document.recruiter_completed_review },


      });
    }
    if (state && state.update.loading === LoadingType.succeeded) {
      dispatch(getTalentODRSThunk({ talent_id: talentId }));
    }
  }, [state, state.data, state.update.loading]);

  function isTalentReviewCompleted(type: string) {
    if (state.data === null) return;
    const {
      handbook_document,
      terms_and_conditions,
      notice_of_healthcare_exchange_document,

      direct_deposit_document,
      w4_document,
      i9_document,
      // essential_staffcare_document,
      wotc_document,
    } = state.data;

    switch (type) {
      case "handbook_document":
        return handbook_document.recruiter_completed_review;
      case "notice_of_healthcare_exchange_document":
        return notice_of_healthcare_exchange_document.recruiter_completed_review;
      // case "essential_staffcare_document": return essential_staffcare_document.recruiter_completed_review;
      case "wotc_document": return wotc_document.recruiter_completed_review;

      case "terms_and_conditions":
        return terms_and_conditions.talent_read_tc;
      case "direct_deposit_document":
        return direct_deposit_document.recruiter_completed_review;
      case "w4_document":
        return w4_document.recruiter_completed_review;
      case "i9_document":
        return i9_document.recruiter_completed_review;
      default:
        return false;
    }
  }

  function handleCancelClose(type: string) {
    setOptions({ ...options, [type]: { ...options[type], visible: false } });
  }

  function handleOpenOption(type: string) {
    setOptions({ ...options, [type]: { ...options[type], visible: true } });
  }
  const dispatch = useAppDispatch();
  const today = Math.floor(Date.now() / 1000);
  const profileState = useAppSelector((state) => selectProfileState(state));
  const currentLoginUserId = profileState.profile?.id ?? "";
  const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""
    }`;
  const viewOnboardingData = profileState.profile?.view_onboarding_data
  const handleFinishReview = (type: string, value?: any) => {
    if (state && state?.data !== null) {
      dispatch(
        updateTalentODRSThunk({
          ...state?.data,
          [type]: {
            ...state?.data[type],
            recruiter_completed_review: true,
            reviewed_on_timestamp: today,
            reviewed_by: currentLoginUserId,
          },
          talent_id: props.talentId,
        })
      );
      setOptions({
        ...options,
        [type]: { ...options[type], visible: false, isReviewd: true },
      });
      // dispatch(talentODRSSliceActions.clearState());
    }
  };

  return (
    <SectionCard
      backgroundColor="#EBF1F8"
      activeColor="#0B3E78"
      title={t("on_boarding_docs")}
      buttonTitle={t("send_onboarding_link")}
      onButtonTitleClick={() => setShowSendOnBoardingLink(true)}
      state={state}
    >
      <div className="obd-docs-container">
        {state && state.loading === LoadingType.pending && (
          <SpinnerScreen></SpinnerScreen>
        )}
        {state && state.loading === LoadingType.failed && (
          <div className="no-data-message">
            <span>{"No onboarding record found for this talent"}</span>
          </div>
        )}
        <div className="main-docs-row1">
          {state && viewOnboardingData === true &&
            state.loading === LoadingType.succeeded &&
            state.error === null && (
              <div className="obd-status-options">
                <div className="option-column">
                  {Object.keys(options)
                    .slice(0, 3)
                    .map((key, index) => {
                      return (
                        <DocumentStatusCard
                          isReviewed={options[key].isReviewd}
                          name={t(options[key].title)}
                          onClick={() => handleOpenOption(options[key].type)}
                        />
                      );
                    })}
                </div>
                <div className="option-column">
                  {Object.keys(options)
                    .slice(3, 6)
                    .map((key, index) => {
                      if (key === "wotc_document" && state.data?.wotc_document?.talent_completed_review === false) {
                        return null;
                      }
                      return (
                        <DocumentStatusCard
                          isReviewed={options[key].isReviewd}
                          name={t(options[key].title)}
                          onClick={() => handleOpenOption(options[key].type)}
                        />
                      );
                    })}
                </div>
              </div>
            )}
        </div>
        {/* <div className="main-docs-row2">
          <div>
            {state && viewOnboardingData === true &&
              state.loading === LoadingType.succeeded &&
              state.error === null && (
                <div className="obd-status-options">
                  <div className="option-column">
                    {Object.keys(options)
                      .slice(5, 7)
                      .map((key, index) => {
                        if (key === "wotc_document" && state.data?.wotc_document?.talent_completed_review === false) {
                          return null;
                        }
                        return (
                          <DocumentStatusCard
                            isReviewed={options[key].isReviewd}
                            name={t(options[key].title)}
                            onClick={() => handleOpenOption(options[key].type)}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
          </div>
        </div> */}
      </div>
      {options.handbook_document.visible && state.data && (
        <Portal>
          <EmployeeHandbookPopup
            visible={options.handbook_document.visible}
            type={options.handbook_document.type}
            talentId={talentId}
            state={state.data}
            loading={state.loading}
            termsAndConditions={""}
            title={options.handbook_document.title}
            onClose={() => handleCancelClose(options.handbook_document.type)}
            successTitle={""}
            onSuccessClose={handleFinishReview}
            talentData={talentData}
            today={today}
            currentLoginUserName={currentLoginUserName}
            recruitersList={recruitersList}
            isReviewd={options.handbook_document.isReviewd}
          />
        </Portal>
      )}
      {options.notice_of_healthcare_exchange_document.visible && state.data && (
        <Portal>
          <NoticeOfHealthcareExchangePopup
            visible={options.notice_of_healthcare_exchange_document.visible}
            type={options.notice_of_healthcare_exchange_document.type}
            state={state.data}
            talentId={talentId}
            title={options.notice_of_healthcare_exchange_document.title}
            onClose={() =>
              handleCancelClose(
                options.notice_of_healthcare_exchange_document.type
              )
            }
            successTitle={""}
            onSuccessClose={handleFinishReview}
            talentData={talentData}
            today={today}
            currentLoginUserName={currentLoginUserName}
            recruitersList={recruitersList}
          />
        </Portal>
      )}
      {/* {options.essential_staffcare_document.visible && state.data && <Portal>
                <EssentialStaffcarePopup
                    visible={options.essential_staffcare_document.visible}
                    type={options.essential_staffcare_document.type}
                    
                    state={state.data}
                    title={options.essential_staffcare_document.title}
                    onClose={() => handleCancelClose(options.essential_staffcare_document.type)}
                    successTitle={''}
                    onSuccessClose={handleFinishReview}
                />
            </Portal>}*/}
      {options.wotc_document.visible && state.data && <Portal>
        <WOTCPopup
          visible={options.wotc_document.visible}
          type={options.wotc_document.type}
          // state={state.data}
          status={state.data.wotc_document.status}
          cti_confirmation_number={state.data.wotc_document.cti_confirmation_number}
          title={options.wotc_document.title}
          onClose={() => handleCancelClose(options.wotc_document.type)}
          successTitle={''}
          onSuccessClose={handleFinishReview}
        />
      </Portal>}
      {options.direct_deposit_document.visible && state.data && (
        <Portal>
          <DirectDepositPopup
            visible={options.direct_deposit_document.visible}
            type={options.direct_deposit_document.type}
            state={state.data}
            talentId={talentId}
            title={options.direct_deposit_document.title}
            onClose={() =>
              handleCancelClose(options.direct_deposit_document.type)
            }
            successTitle={""}
            onSuccessClose={handleFinishReview}
            talentData={talentData}
            today={today}
            currentLoginUserName={currentLoginUserName}
            recruitersList={recruitersList}
          />
        </Portal>
      )}
      {options.w4_document.visible && state.data && (
        <Portal>
          <W4Popup
            visible={options.w4_document.visible}
            type={options.w4_document.type}
            state={state.data}
            talentId={talentId}
            title={options.w4_document.title}
            onClose={() => handleCancelClose(options.w4_document.type)}
            successTitle={""}
            onSuccessClose={handleFinishReview}
            talentData={talentData}
            today={today}
            currentLoginUserName={currentLoginUserName}
            recruitersList={recruitersList}
          />
        </Portal>
      )}
      {options.i9_document.visible && state.data && (
        <Portal>
          <I9Popup
            visible={options.i9_document.visible}
            type={options.i9_document.type}
            state={state.data}
            talentId={talentId}
            title={options.i9_document.title}
            onClose={() => handleCancelClose(options.i9_document.type)}
            successTitle={""}
            onSuccessClose={handleFinishReview}
            talentData={talentData}
            today={today}
            currentLoginUserName={currentLoginUserName}
            recruitersList={recruitersList}
          />
        </Portal>
      )}

      {showSendOnBoardingLink && (
        <Portal>
          <SendOnBoardingLinkPopup
            visible={showSendOnBoardingLink}
            onClose={() => setShowSendOnBoardingLink(false)}
            onDissmiss={() => setShowSendOnBoardingLink(false)}
            onSuccessClose={handleOnSendOnBoardingSuccess}
            talentId={talentId}
            setDisable={true}
          />
        </Portal>
      )}
    </SectionCard>
  );

  function DocumentStatusCard(props: DSCProps) {
    const { isReviewed, name, onClick } = props;
    return (
      <button className="doc-card-container" onClick={onClick}>
        <div className="stat-icon">
          {isReviewed ? (
            <TickGreenIcon width={"100%"} height={"100%"} />
          ) : (
            <ExclaimOrangeIcon width={"100%"} height={"100%"} />
          )}
        </div>
        <div className="doc-name">
          {name !== t('wotc') && <span>{name}</span>}
          {name === t('wotc') && (<><span>{name} </span><span style={{
            color: state.data?.wotc_document.cti_confirmation_number ? THEME.statusActiveColor : "#FF7600", marginLeft:'0.5rem'
          }}>{state.data?.wotc_document.status ? " - " + state.data?.wotc_document.status : ""}</span></>)}
        </div>

        {name !== t('essential_staff_care') && name !== t('wotc') && <div
          className="stat-banner"
          style={{
            backgroundColor: isReviewed ? THEME.statusActiveColor : "#FF7600",
          }}
        >
          <span>{isReviewed ? t("reviewed") : t("not_reviewed")}</span>
        </div>}
        {name === t('essential_staff_care') && <div
          className="stat-banner"
          style={{
            backgroundColor: THEME.statusActiveColor,
          }}
        >
          <span>{t("completed")}</span>
        </div>}

      </button>
    );
  }
  function handleOnSendOnBoardingSuccess() {
    setShowSendOnBoardingLink(false);
    dispatch(sendOnBoardingLinksStateActions.clearSendOnBoardingLinkState());
  }
};

interface DSCProps {
  isReviewed: boolean;
  name: string;
  onClick: () => void;
}

export default OnboardingDocs;

import { CircularProgress } from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import "./sortable_table.scss";

export interface IHeaderListProps {
  title: string;
  code: string,
  children?: React.ReactNode,
  sort?: boolean,
  colSpan?: number;
  flex?: number;
}

interface Props {
  headerList: Array<IHeaderListProps>;
  topHeaderList?: Array<IHeaderListProps>;

  headerChildren?: React.ReactNode;
  children: React.ReactNode;
  sortedField?: string | null;
  onSortChange?: (value: string) => void;
  isLoading?: boolean;
  flexNumber?: (index: number) => number | React.CSSProperties;
  topHeaderFlexNumber?: (index: number) => number | React.CSSProperties;
  headerStyles?: React.CSSProperties;
  tableStyles?: React.CSSProperties;
  tableHeaderStyles?: React.CSSProperties;
  tableBodyStyles?: React.CSSProperties;
  isAsc?: string;
  customBorderStyle?: string[];
  customBGColor?: string[];
  customTextAlign?: string[];
  customTopBorderStyle?: string[];
  customTopBGColor?: string[];
  customTopTextAlign?: string[];
}

const SortableTable = (props: Props) => {
  const {
    headerList,
    topHeaderList,
    topHeaderFlexNumber,
    headerChildren,
    children,
    onSortChange,
    flexNumber,
    sortedField,
    isLoading,
    tableStyles,
    tableHeaderStyles,
    tableBodyStyles,
    headerStyles,
    customBorderStyle,
    customBGColor,
    customTextAlign,
    customTopBorderStyle,
    customTopBGColor,
    customTopTextAlign,
    isAsc,
  } = props;
  const { t } = useTranslation();

  const getFlex = (index: number, flex?: number): React.CSSProperties => {
    if (flex) return { flex: flex } as React.CSSProperties;
    if (!flexNumber) return {};
    if (typeof flexNumber(index) === 'number') return { flex: flexNumber(index) } as React.CSSProperties;
    return flexNumber(index) as React.CSSProperties;
  }
  const getTopHeaderFlex = (index: number): React.CSSProperties => {

    if (!topHeaderFlexNumber) return {};
    if (typeof topHeaderFlexNumber(index) === 'number') return { flex: topHeaderFlexNumber(index) } as React.CSSProperties;
    return topHeaderFlexNumber(index) as React.CSSProperties;
  }

  const SortArrow = ({ sort = true, ...rest }: IHeaderListProps) => {
    if (sortedField && sort) {
      return (
        <div className={`table-header-sort-icon`}>
          {isAsc === "asc" ? (
            <ArrowDownward fontSize={"inherit"} color={"inherit"} />
          ) : (
            <ArrowUpward fontSize={"inherit"} color={"inherit"} />
          )}
        </div>
      );
    }
    return null;
  }

  return (
    <table className="sortable-table" style={tableStyles}>
      {/* <caption>Our products</caption> */}
      <thead style={tableHeaderStyles}>

        {topHeaderList &&
          (<tr>
            {topHeaderList.map((doc, index) => {
              const { title, code, sort = true, children, colSpan } = doc;
              if (colSpan) {
                return (
                  <th
                    key={index}
                    className="tb-header-cc"
                    onClick={() => {
                      if (onSortChange && sort) onSortChange(code);
                    }}
                    style={{
                      ...getTopHeaderFlex(index),
                      ...headerStyles,
                      borderLeft: customTopBorderStyle && customTopBorderStyle.includes(title) ? "1px solid #c3cfd6" : "none",
                      backgroundColor: customTopBGColor && customTopBGColor.includes(title) ? "#DFE7EF" : "none",
                      borderBottom: "1px solid rgba(195, 196, 198, 0.3)",
                    }}
                    title={t(title)}
                    colSpan={doc.colSpan}
                  >
                    <span style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: customTopTextAlign && customTopTextAlign.includes(title) ? "100%" : "auto",
                      textAlign: customTopTextAlign && customTopTextAlign.includes(title) ? "center" : "start"
                    }}>
                      {children ?? t(title)}
                    </span>
                    <SortArrow {...doc} />
                  </th>
                );
              }
            })}
          </tr>)}

        <tr>
          {headerChildren ??
            headerList.map((doc, index) => {
              const { title, code, sort = true, children } = doc;
              return (
                <th
                  key={index}
                  className="tb-header-cc"
                  onClick={() => {
                    if (onSortChange && sort) onSortChange(code);
                  }}
                  style={{
                    ...getFlex(index, doc.flex),
                    ...headerStyles,
                    borderLeft: customBorderStyle && customBorderStyle.includes(title) ? "1px solid #c3cfd6" : "none",
                    backgroundColor: customBGColor && customBGColor.includes(title) ? "#DFE7EF" : "none",
                  }}
                  title={t(title)}
                >
                  <span style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: customTextAlign && customTextAlign.includes(title) ? "100%" : "auto",
                    textAlign: customTextAlign && customTextAlign.includes(title) ? "center" : "start"
                  }}>
                    {children ?? t(title)}
                  </span>
                  <SortArrow {...doc} />
                </th>
              );
            })}
        </tr>
      </thead>
      <tbody style={tableBodyStyles}>
        {isLoading ? (
          <CircularProgress style={{ marginLeft: "50%" }} />
        ) : (
          children
        )}
      </tbody>
    </table>
  );
};

interface TableDataProps {
  isButton?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  customStyle?: React.CSSProperties;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  align?: "center" | "left" | "right" | "justify" | "char" | undefined;
  colSpan?: number,
}

export const TableData: React.FunctionComponent<TableDataProps> = (props) => {
  if (props.isButton) {
    return (
      <td
        style={props.customStyle}
        className="table-data-container"
        align={props.align}
      >
        {props.prefix}
        <a
          className="table-data-btn"
          onClick={props.onClick ? props.onClick : () => { }}
        >
          {props.children}
        </a>
        {props.suffix}
      </td>
    );
  }
  return (
    <td
      style={props.customStyle}
      className="table-data-container"
      align={props.align}
      colSpan={props.colSpan}
    >
      {props.children}
    </td>
  );
};

export default SortableTable;

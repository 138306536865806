import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import MultiOptionSection from "../../../../components/multi_option_section/multi_option_section";
import {
  LoadingType,
  TALENT_SKILLS_TYPE,
  THEME,
} from "../../../../enums";
import {
  getRoleUsersList,
  getTalentODRSThunk,
  selectProfileState,
  selectRoleUsersList,
  selectSingleTalentODRSStateById,
  updateTalentODRSThunk,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import {
  SingleTalentState,
  talentDetailsSliceActions,
} from "../../../../redux/talent/details/dashboard/talent_details/talent_details_reducer";
import { manageTalentActions } from "../../../../redux/talent/universal_directory/manage_talent/manage_talent_reducer";
import { CreateOption } from "../../../talent/popups/create_option/create_option";
import "./e_doc_and_certifications.scss";
import { AppRoutes } from "../../../../routes";
import { SpinnerScreen } from "../../../../utils";
import { ExclaimOrangeIcon, TickGreenIcon } from "../../../../icons";
import { talentODRSSliceActions } from "../../../../redux/talent/details/onboarding_and_docs/application_status/application_status_reducer";
import EmployeeHandbookPopup from "../../../talent/docs/employee_handbook/employee_handbook";
import NoticeOfHealthcareExchangePopup from "../../../talent/docs/notice_of_healthcare_exchange/notice_of_healthcare_exchange";
import DirectDepositPopup from "../../../talent/docs/direct_deposit/direct_deposit";
import W4Popup from "../../../talent/docs/w4/w4";
import I9Popup from "../../../talent/docs/i9/i9";
import WOTCPopup from "../../../talent/docs/wotc/wotc";
import { RemoveOptionPopup } from "../../../components/remove_option/remove_option";
import EmployeeW2Page from "../employee_w2/employee_w2_page";

interface Props extends RouteComponentProps<any> { }

interface Props {
  talentState: SingleTalentState;
}
interface ODRSOption {
  title: string;
  isReviewd: boolean;
  type: string;
  visible: boolean;
  enable: boolean;
}



export const EDocAndCertifications: React.FunctionComponent<Props> = (
  props
) => {
  const { match, talentState, history } = props;
  const { params } = match;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const talentId = params.id;
  const eDocsState = useAppSelector((state) =>
    selectSingleTalentODRSStateById(talentId)(state)
  );


  const recruitersList = useAppSelector((state) => selectRoleUsersList(state));


  useEffect(() => {
    dispatch(getRoleUsersList());
    dispatch(getTalentODRSThunk({ talent_id: talentId }));
    return () => { };
  }, []);


  const [addOption, setAddOption] = useState<{
    visible: boolean;
    type: string;
  } | null>(null);
  const [removeOption, setRemoveOption] = useState<{
    visible: boolean;
    type: string;
    value: { id: string; value: string };
  } | null>(null);



  /// Add option
  function handleAddOption(type: string) {
    setAddOption({ visible: true, type: type });
  }

  /// Remove Option
  function handleRemoveOption(
    type: string,
    value: { id: string; value: string }
  ) {
    setRemoveOption({ visible: true, type: type, value: value });
  }

  /// close add option dialog
  function handleCloseAddDialog() {
    setAddOption(null);
  }

  /// Add on success add option
  function handleOnSuccessAddDialog() {
    if (addOption) {
      dispatch(
        talentDetailsSliceActions.clearTalentDetailsAddOptionError({
          id: params.departmentId ?? talentId,
          type: addOption?.type,
        })
      );
      dispatch(manageTalentActions.clearUpdateTalentState());
      setAddOption(null);
    }
  }

  /// close add option dialog
  function handleCloseRemoveDialog() {
    setRemoveOption(null);
  }

  /// Add on success add option
  function handleOnSuccessRemoveDialog() {
    if (removeOption) {
      dispatch(
        talentDetailsSliceActions.clearTalentDetailsRemoveOptionError({
          id: params.departmentId ?? talentId,
          type: removeOption?.type,
        })
      );
      setRemoveOption(null);
    }
  }
  const [options, setOptions] = useState<{ [key: string]: ODRSOption }>({
    direct_deposit_document: {
      type: "direct_deposit_document",
      title: t("direct_deposit"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
    handbook_document: {
      type: "handbook_document",
      title: t("employee_handbook"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
    // essential_staffcare_document: { type: "essential_staffcare_document", title: 'essential_staff_care_docs', isReviewd: false, visible: false, enable: true },
    wotc_document: { type: 'wotc_document', visible: false, title: t('wotc'), enable: true, isReviewd: false, },
    notice_of_healthcare_exchange_document: {
      type: "notice_of_healthcare_exchange_document",
      title: t("notice_of_healthcare_exchange"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
    w4_document: {
      type: "w4_document",
      title: t("w4"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
    i9_document: {
      type: "i9_document",
      title: t("i9"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
  });

  useEffect(() => {
    if (eDocsState.data) {
      const {
        direct_deposit_document,
        handbook_document,
        // essential_staffcare_document,
        wotc_document,
        notice_of_healthcare_exchange_document,
        w4_document,
        i9_document,
      } = eDocsState.data;
      setOptions({
        ...options,
        direct_deposit_document: {
          ...options.direct_deposit_document,
          isReviewd: direct_deposit_document.recruiter_completed_review,
        },
        handbook_document: {
          ...options.handbook_document,
          isReviewd: handbook_document.recruiter_completed_review,
        },
        // essential_staffcare_document: { ...options.essential_staffcare_document, isReviewd: essential_staffcare_document.recruiter_completed_review },
        wotc_document: {
          ...options.wotc_document,
          isReviewd: handbook_document.recruiter_completed_review,
        },
        notice_of_healthcare_exchange_document: {
          ...options.notice_of_healthcare_exchange_document,
          isReviewd:
            notice_of_healthcare_exchange_document.recruiter_completed_review,
        },
        w4_document: {
          ...options.w4_document,
          isReviewd: w4_document.recruiter_completed_review,
        },
        i9_document: {
          ...options.i9_document,
          isReviewd: i9_document.recruiter_completed_review,
        },
      });
    }
    // if (eDocsState && eDocsState.update.loading === LoadingType.succeeded) {
    //   dispatch(getTalentODRSThunk({ talent_id: talentId }));
    // }
  }, [eDocsState, eDocsState.data, eDocsState.update.loading]);
  function DocumentStatusCard(props: DSCProps) {
    const { isReviewed, name, onClick } = props;
    return (
      <button className="doc-card-container" onClick={onClick}>
        <div className="stat-icon">
          {isReviewed ? (
            <TickGreenIcon width={"100%"} height={"100%"} />
          ) : (
            <ExclaimOrangeIcon width={"100%"} height={"100%"} />
          )}
        </div>
        <div className="doc-name">
          <span>{name}</span>
        </div>
        <div
          className="stat-banner"
          style={{
            backgroundColor: isReviewed ? THEME.statusActiveColor : "#FF7600",
          }}
        >
          <span>{isReviewed ? t("reviewed") : t("not_reviewed")}</span>
        </div>
      </button>
    );
  }
  function handleOpenOption(type: string) {
    setOptions({ ...options, [type]: { ...options[type], visible: true } });
  }
  function handleCancelClose(type: string) {
    setOptions({ ...options, [type]: { ...options[type], visible: false } });
  }

  const today = Math.floor(Date.now() / 1000);
  const profileState = useAppSelector((state) => selectProfileState(state));
  const currentLoginUserId = profileState.profile?.id ?? "";
  const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""
    }`;
  const handleFinishReview = (type: string, value?: any) => {
    if (eDocsState && eDocsState?.data !== null) {
      setOptions({
        ...options,
        [type]: { ...options[type], visible: false, isReviewd: true },
      });

      dispatch(
        updateTalentODRSThunk({
          ...eDocsState?.data,
          [type]: {
            ...eDocsState?.data[type],
            recruiter_completed_review: true,
            reviewed_on_timestamp: today,
            reviewed_by: currentLoginUserId,
          },
          talent_id: talentId,
        })
      );

      dispatch(talentODRSSliceActions.clearState());
    }
  };


  function handleRedirectOnboardingDoc(talentId: string, talentName: string) {
    history.push({
      pathname: `${AppRoutes.talentDetailsPage}/${talentId}/onboarding-profile-and-docs`,
      state: {
        id: talentId,
        name: talentName,
      },
    });
  }
  const viewOnboardingData = profileState.profile?.view_onboarding_data

  const handleClearRemoveOptionError = () => {
    dispatch(talentDetailsSliceActions.clearTalentDetailsRemoveOptionError({ id: params.id, type: removeOption?.type! }));
  };

  return (
    <div className="edc-db-container">
      <div className="edc-db-tbc">
        <div className="title">{t("e-documents").toUpperCase()}</div>
      </div>
      {/* <div className="onboarding_btn">
        <div>
          <CustomButton
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("onboarding_docs")}
            enable={true}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={() =>
              handleRedirectOnboardingDoc(
                talentId,
                talentState.data?.first_name + " " + talentState.data?.last_name
              )
            }
          />
        </div>
      </div> */}
      <div>
        {eDocsState && eDocsState.loading === LoadingType.pending && (
          <SpinnerScreen></SpinnerScreen>
        )}
        {eDocsState && viewOnboardingData === true &&
          eDocsState.loading === LoadingType.succeeded &&
          eDocsState.error === null && (
            <div className="obd-status-options">
              <div className="option-column">
                {Object.keys(options)
                  .slice(0, 3)
                  .map((key, index) => {
                    if (key === "wotc_document" && eDocsState?.data?.wotc_document?.talent_completed_review === false) {
                      return null;
                    }
                    return (
                      <DocumentStatusCard
                        isReviewed={options[key].isReviewd}
                        name={t(options[key].title)}
                        onClick={() => handleOpenOption(options[key].type)}
                      />
                    );
                  })}
              </div>
              <div className="option-column">
                {Object.keys(options)
                  .slice(3, 5)
                  .map((key, index) => {
                    if (key === "i9_document" && options[key].isReviewd) {
                      return null;
                    }
                    return (
                      <DocumentStatusCard
                        isReviewed={options[key].isReviewd}
                        name={t(options[key].title)}
                        onClick={() => handleOpenOption(options[key].type)}
                      />
                    );
                  })}
              </div>
            </div>

          )}
      </div>
      <div>
        <MultiOptionSection
          headerTitle={t("credentials").toUpperCase()}
          actionName={t("add_credentials")}
          onClick={() => handleAddOption(TALENT_SKILLS_TYPE.credentials)}
          list={talentState.credentials.data ?? []}
          onRemove={(value) =>
            handleRemoveOption(TALENT_SKILLS_TYPE.credentials, value)
          }
        />
      </div>
      <div>
        <EmployeeW2Page talentId={talentId} currentLoginUserName={currentLoginUserName} />
      </div>
      {
        addOption && (
          <Portal>
            <CreateOption
              talent_id={talentId}
              visible={addOption.visible}
              type={addOption.type}
              onClose={handleCloseAddDialog}
              onDissmiss={handleCloseAddDialog}
              onSuccessClose={handleOnSuccessAddDialog}
            />
          </Portal>
        )
      }
      {
        removeOption && <Portal>
          <RemoveOptionPopup
            id={params.id}
            state={talentState}
            data={removeOption.value}
            type={removeOption.type}
            sourceType={"Talent"}
            visible={removeOption.visible}
            onClose={handleCloseRemoveDialog}
            onDissmiss={handleCloseRemoveDialog}
            onSuccessClose={handleOnSuccessRemoveDialog}
            onClearError={handleClearRemoveOptionError}
          />
        </Portal>
      }
      {
        options.handbook_document.visible && eDocsState.data && (
          <Portal>
            <EmployeeHandbookPopup
              visible={options.handbook_document.visible}
              type={options.handbook_document.type}
              talentId={talentId}
              state={eDocsState.data}
              loading={eDocsState.loading}
              termsAndConditions={""}
              title={options.handbook_document.title}
              onClose={() => handleCancelClose(options.handbook_document.type)}
              successTitle={""}
              onSuccessClose={handleFinishReview}
              talentData={talentState.data}
              today={today}
              currentLoginUserName={currentLoginUserName}
              recruitersList={recruitersList}
              isReviewd={options.handbook_document.isReviewd}
            />
          </Portal>
        )
      }
      {
        options.notice_of_healthcare_exchange_document.visible &&
        eDocsState.data && (
          <Portal>
            <NoticeOfHealthcareExchangePopup
              visible={options.notice_of_healthcare_exchange_document.visible}
              type={options.notice_of_healthcare_exchange_document.type}
              state={eDocsState.data}
              talentId={talentId}
              title={options.notice_of_healthcare_exchange_document.title}
              onClose={() =>
                handleCancelClose(
                  options.notice_of_healthcare_exchange_document.type
                )
              }
              successTitle={""}
              onSuccessClose={handleFinishReview}
              talentData={talentState.data}
              today={today}
              currentLoginUserName={currentLoginUserName}
              recruitersList={recruitersList}
            />
          </Portal>
        )
      }
      {
        options.direct_deposit_document.visible && (
          <Portal>
            <DirectDepositPopup
              visible={options.direct_deposit_document.visible}
              type={options.direct_deposit_document.type}
              state={eDocsState.data}
              talentId={talentId}
              title={options.direct_deposit_document.title}
              onClose={() =>
                handleCancelClose(options.direct_deposit_document.type)
              }
              successTitle={""}
              onSuccessClose={handleFinishReview}
              talentData={talentState.data}
              today={today}
              currentLoginUserName={currentLoginUserName}
              recruitersList={recruitersList}
            />
          </Portal>
        )
      }
      {
        options.w4_document.visible && eDocsState.data && (
          <Portal>
            <W4Popup
              visible={options.w4_document.visible}
              type={options.w4_document.type}
              state={eDocsState.data}
              talentId={talentId}
              title={options.w4_document.title}
              onClose={() => handleCancelClose(options.w4_document.type)}
              successTitle={""}
              onSuccessClose={handleFinishReview}
              talentData={talentState.data}
              today={today}
              currentLoginUserName={currentLoginUserName}
              recruitersList={recruitersList}
            />
          </Portal>
        )
      }
      {
        options.i9_document.visible && eDocsState.data && (
          <Portal>
            <I9Popup
              visible={options.i9_document.visible}
              type={options.i9_document.type}
              state={eDocsState.data}
              talentId={talentId}
              title={options.i9_document.title}
              onClose={() => handleCancelClose(options.i9_document.type)}
              successTitle={""}
              onSuccessClose={handleFinishReview}
              talentData={talentState.data}
              today={today}
              currentLoginUserName={currentLoginUserName}
              recruitersList={recruitersList}
            />
          </Portal>
        )
      }
      {
        options.wotc_document.visible && eDocsState.data && <Portal>
          <WOTCPopup
            visible={options.wotc_document.visible}
            type={options.wotc_document.type}
            status={eDocsState.data?.wotc_document.status}
            cti_confirmation_number={eDocsState.data?.wotc_document.cti_confirmation_number}
            title={options.wotc_document.title}
            onClose={() => handleCancelClose(options.wotc_document.type)}
            successTitle={''}
            onSuccessClose={() => { }}
          />
        </Portal>
      }
    </div >
  );
};
interface DSCProps {
  isReviewed: boolean;
  name: string;
  onClick: () => void;
}

export enum TalentPipelineDirectory {
    Match = "Matches",
    Trashed = "Not a Fit",
    Queued = "Queued",
}

export enum TalentPipelineStatus {
    Need_To_Review = "Need To Review",
    Not_A_Fit = "Not A Fit",
    Fit = "Fit Match",
    Talent_Submitted_To_Client = "Talent Submitted To Client",
    Client_Interview_Scheduled = 'Interview Scheduled With Client',
    Internal_Interview_Scheduled = 'Internal Interview Scheduled',
}
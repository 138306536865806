import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { ReportCenterAPI } from "../../../apis/report_center_api";
import { IEmplyeesDropdownList } from "../../../interfaces/report_center_payroll_tax";

export const getEmployeeDropdownList = createAsyncThunk<
    Array<IEmplyeesDropdownList>,
    { start_date: number, end_date: number, is_check_date?: boolean },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@selectors/payroll-and-tax/employees/dropdown/get',
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getEmployeeDropdown(payload.start_date, payload.end_date, payload.is_check_date);
            if (typeof res.data === 'string') return [];
            return res.data as Array<IEmplyeesDropdownList>;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, CompanyCategory } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getCompanyCategories = createAsyncThunk<
    Array<CompanyCategory>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/companies/company_category/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getCompanyCategories();
            return res.data as CompanyCategory[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createCompanyCategory = createAsyncThunk<
    string,
    {'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/companies/company_category/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createCompanyCategory(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteCompanyCategory = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/companies/company_category/delete',
    async (contact_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteCompanyCategory(contact_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateCompanyCategory = createAsyncThunk<
    string,
    {'id': string, 'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/companies/company_category/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateCompanyCategory(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getRoleUsersList, getSalesCallSummaryReportDownloadUrl, getSalesCallSummaryReportFilteredList, selectRoleUsersList, selectSalesCallSummaryReportDownloadUrlState, selectSalesCallSummaryReportListState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { convertDateToTimeStamp, getDateString } from "../../../../../variables";
import { LoadingType, THEME } from "../../../../../enums";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { CalenderIcon, CompletedCallIcon, CompletedCallWhiteIcon, DownloadIcon, ScheduledCallIcon, ScheduledCallWhiteIcon, TickGreenIcon, TickGreyIcon } from "../../../../../icons";
import { CustomMultiFormSelect } from "../../../../../components/form_selector/form_select";
import DatePicker from "react-datepicker";
import { CustomButton, CustomCheckBox, getContactIcon } from "../../../../../utils";
import { SalesCall } from "../../../../../interfaces/sales_call";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../../components/table_empty/table_empty";
import { AppRoutes } from "../../../../../routes";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import './sales_call_summary_report.scss';
import { clearSalesCallSummaryReportListError, clearSCSRDownloadState } from "../../../../../redux/report_center/sales_and_marketing/sales_call_summary_report/sales_call_summary_report_reducer";
import SearchBar from "../../../../../components/search_bar";
import ApiError from "../../../../../components/api_error";

interface Props extends RouteComponentProps<any> { }
const SalesCallSummaryReport: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { location, history } = props;

    const tableHeader = [
        { title: "company_status", code: "company_status" },
        { title: "company", code: "company_name" },
        { title: "department", code: "department_name" },
        { title: "contact", code: "contact_first_name" },
        { title: "dm_reached", code: "dm_reached" },
        { title: "call_method", code: "contact_method" },
        { title: "call_goal", code: "call_goal" },
        { title: "date_of_scheduled_sales_call", code: "schedule_date_time" },
        { title: "call_results", code: "call_results" },
    ];

    const completedCallsTableheader = [
        { title: "company_status", code: "company_status" },
        { title: "company", code: "company_name" },
        { title: "department", code: "department_name" },
        { title: "contact", code: "contact_first_name" },
        { title: "dm_reached", code: "dm_reached" },
        { title: "call_method", code: "contact_method" },
        { title: "call_goal", code: "call_goal" },
        { title: "date_of_completed_sales_call", code: "call_date_time" },
        { title: "call_results", code: "call_result" },
    ];
    const hoveringStateColumn = {
        callGoal: "call_goal",
        callResult: "call_result",
      };

    const usersList = useAppSelector((state) => selectRoleUsersList(state));
    const { loading, error, response } = useAppSelector((state) => selectSalesCallSummaryReportListState(state));
    const salesCSRDownloadUrlState = useAppSelector((state) => selectSalesCallSummaryReportDownloadUrlState(state));

    const [showCompleted, setShowCompleted] = useState<boolean>(false);
    const [managerFilter, setManagerFilter] = useState<string[]>([]);
    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const [salesFormState, setSalesFormState] = useState<{ available_from: number, available_to: number }>({ available_from: convertDateToTimeStamp(thirtyDaysAgo, true), available_to: convertDateToTimeStamp(new Date(), true) })
    const [salesDateRange, setSalesDateRange] = useState([thirtyDaysAgo, new Date()]);
    const [salesStartDate, salesEndDate] = salesDateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [salesCallsSummaryList, setSalesCallsSummaryList] = useState<SalesCall[] | null>(null);
    const [dmReached, setDMReached] = useState<boolean>(false);
    const [isHovering, setIsHovering] = useState<{
        visible: boolean;
        id: string;
        column: string;
      } | null>(null); 

    useEffect(() => {
        dispatch(getRoleUsersList());
    }, []);

    useEffect(() => {
        if (loading === LoadingType.succeeded && response) {
            setSalesCallsSummaryList(response);
        }
        return () => {
            setSalesCallsSummaryList(null) 
        }
    }, [loading, response]);

    useEffect(() => {
        if ((salesFormState.available_from !== 0 && salesFormState.available_to !== 0) || ((salesFormState.available_from === 0 && salesFormState.available_to === 0))) {
            getSalesCallSummarysList();
        }
    }, [showCompleted, salesFormState.available_from, salesFormState.available_to, dmReached, managerFilter]);

    useEffect(() => {
        if ((salesStartDate === null && salesEndDate === null) || (salesFormState.available_from === 0 && salesFormState.available_to === 0)) {
            setSalesDateRange([thirtyDaysAgo, new Date()]);
            setSalesFormState({ available_from: convertDateToTimeStamp(thirtyDaysAgo, true), available_to: convertDateToTimeStamp(new Date(), true) })
        }
    }, [showCompleted]);


    useEffect(() => {
        if ((salesFormState.available_from !== 0 && salesFormState.available_to !== 0)) {
            setDatePickerOpen(false)
        }
        if ((salesFormState.available_from === 0 && salesFormState.available_to === 0)) {
            openDatePicker();
        }
    }, [salesFormState.available_from, salesFormState.available_to]);

    useEffect(() => {
        if (salesCSRDownloadUrlState.loading === LoadingType.succeeded && salesCSRDownloadUrlState.response?.url &&
            salesCSRDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', salesCSRDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(clearSCSRDownloadState());
        }
    }, [salesCSRDownloadUrlState.loading]);

    const openDatePicker = () => {
        setDatePickerOpen(true);
    };
    const getSalesCallSummarysList = () => {
        dispatch(getSalesCallSummaryReportFilteredList({ my_upcoming_calls: !showCompleted, start_date: salesFormState.available_from, end_date: salesFormState.available_to, show_only_dm_reached: dmReached, users: managerFilter }))
    }

    function handleChange(value) {
        setSalesFormState({
            ...salesFormState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setSalesDateRange(value);

    }

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function handleDownload() {
        if (managerFilter?.length !== 0) {
            dispatch(getSalesCallSummaryReportDownloadUrl({ upcoming: !showCompleted, start_date: salesFormState.available_from, end_date: salesFormState.available_to, download: true, users: managerFilter }));
        }
        else {
            dispatch(getSalesCallSummaryReportDownloadUrl({ upcoming: !showCompleted, start_date: salesFormState.available_from, end_date: salesFormState.available_to, download: true }));
        }
    }

    function handleCompanySelect(value: SalesCall) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            },
        });
        return;
    }
    function handleDepartmentSelect(value: SalesCall) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
                departmentId: value.department_id,
                departmentName: value.department_name,
            },
        });
        return;
    }

    function handleContactSelect(value: SalesCall) {
        props.history.push({
            pathname: `${AppRoutes.contactsDetailsPage}/${value.contact_id}`,
            state: {
                id: value.contact_id,
                name: `${value.contact_first_name ?? ""} ${value.contact_last_name ?? ""
                    }`,
            },
        });
        return;
    }
    function getFlexNumber(value: number) {
        if (value === 0) return 1.2;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 2;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 2;
        if (value === 11) return 1;
        return 1;
    }

    function handleMouseOver(id, column) {
        setIsHovering({ id: id, visible: true, column: column });
      }
      function handleMouseOut() {
        setIsHovering(null);
      }

    const showSpinner = () => loading === LoadingType.pending;

    const getFilteredList = () => {
        let list: SalesCall[] = [...salesCallsSummaryList || []];
        let sortedList: SalesCall[] | undefined;
        if (sortedField !== null) {
            sortedList = [...(sortedList ?? list)].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return (sortedList ?? list)?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }
    const getTable = () => {
        if (showSpinner()) return <TableLoading />;

        if (error != null && salesCallsSummaryList?.length === 0) {
            return <TableErrorHandler error={error} onRefresh={getSalesCallSummarysList} />;
        }

        if (salesCallsSummaryList?.length === 0 || getFilteredList()?.length === 0) {
            return <TableEmpty title={"No calls found"} onClick={getSalesCallSummarysList} />;
        }

        if (getFilteredList()?.length !== 0) {
            return (
                <div className="scsr-table">
                    <SortableTable
                        headerList={showCompleted ? completedCallsTableheader : tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {getFilteredList()?.map((doc) => {
                            return (
                                <tr key={doc.id}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                        <span>{doc.company_status}</span>
                                    </TableData>
                                    <TableData
                                        customStyle={{ flex: getFlexNumber(1) }}
                                        isButton={true}
                                        onClick={() => handleCompanySelect(doc)}
                                    >
                                        <span>{doc.company_name}</span>
                                    </TableData>
                                    <TableData
                                        customStyle={{ flex: getFlexNumber(2) }}
                                        isButton={true}
                                        onClick={() => handleDepartmentSelect(doc)}
                                    >
                                        <span>{doc.department_name ?? t("corporate")}</span>
                                    </TableData>
                                    <TableData
                                        customStyle={{ flex: getFlexNumber(3) }}
                                        isButton={true}
                                        onClick={() => handleContactSelect(doc)}
                                    >
                                        <span>
                                            {`${doc.contact_first_name ?? ""} ${doc.contact_last_name ?? ""}`}
                                        </span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                        {doc.dm_reached ? <TickGreenIcon /> : <TickGreyIcon />}
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                        {getContactIcon(doc.contact_method)}
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                    <div
                      style={{ cursor: "pointer" }}
                      onMouseOut={() => handleMouseOut()}
                      onMouseOver={() =>
                        handleMouseOver(doc.id, hoveringStateColumn.callGoal)
                      }
                      className="col-hover-container"
                    >
                      <span>
                        {doc != null && doc?.call_goal?.length > 100
                          ? doc?.call_goal?.substring(0, 100) + "..."
                          : doc?.call_goal}
                      </span>
                      {isHovering &&
                        doc.id === isHovering.id &&
                        isHovering.column === hoveringStateColumn.callGoal && (
                          <div className="parent-position">
                            <div className="child-position">
                              <span style={{ cursor: "pointer" }}>
                                {doc?.call_goal}
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                                    </TableData>
                                    {!showCompleted ? (
                                        <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                            <span>{getDateString(doc.schedule_date_time)}</span>
                                        </TableData>
                                    ) : (
                                        <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                            <span>{getDateString(doc.date_of_completed)}</span>
                                        </TableData>
                                    )}
                                    <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                    <div
                      style={{ cursor: "pointer" }}
                      onMouseOut={() => handleMouseOut()}
                      onMouseOver={() =>
                        handleMouseOver(doc.id, hoveringStateColumn.callResult)
                      }
                      className="col-hover-container"
                    >
                      <span>
                        {doc != null && doc?.call_result?.length > 100
                          ? doc?.call_result?.substring(0, 100) + "..."
                          : doc?.call_result}
                      </span>
                      {isHovering &&
                        doc.id === isHovering.id &&
                        isHovering.column ===
                          hoveringStateColumn.callResult && (
                          <div className="parent-position">
                            <div className="child-position">
                              <span style={{ cursor: "pointer" }}>
                                {doc?.call_result}
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                                    </TableData>
                                </tr>
                            )
                        })}
                    </SortableTable>
                </div>
            );
        }
    };

    const handleClearError = () => {
        dispatch(clearSalesCallSummaryReportListError())
    }

    function handleOnChange(value) {
        setDMReached(value);
    }

    return (
        <div className="sales-call-summary-report">
            <div className="sales-call-summary-report-header">
                <div className="main-table-header">
                    <div>
                        <CustomMultiFormSelect
                            label={t("select_user")}
                            name={"select_user"}
                            value={managerFilter}
                            placeholder={"All"}
                            list={usersList?.map((doc) => ({
                                label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                                value: doc.id,
                            }))}
                            onChange={(value) => {
                                setManagerFilter(value);
                            }}
                            disabled={loading === LoadingType.pending}
                        />
                    </div>
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={openDatePicker} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={salesStartDate}
                                endDate={salesEndDate}
                                onChange={handleChange}
                                isClearable={loading === LoadingType.pending ? false: true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dropdownMode="select"
                                disabled={loading === LoadingType.pending}
                            />
                        </div>
                    </div>
                    {error && <div className="error-holder">
                        <ApiError message={error?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>
            <div className="flex-row main-header">
                <div
                    className={`flex-grow main-header-item ${!showCompleted ? "selected" : ""
                        }`}
                    onClick={() => {
                        setShowCompleted(false)
                        setDMReached(false)
                    }}
                >
                    {!showCompleted ? (
                        <ScheduledCallWhiteIcon
                            height={"20px"}
                            width={"20px"}
                            style={{ marginRight: "15px" }}
                        />
                    ) : (
                        <ScheduledCallIcon
                            height={"20px"}
                            width={"20px"}
                            style={{ marginRight: "15px" }}
                        />
                    )}
                    Scheduled Sales Calls
                </div>
                <div
                    className={`flex-grow main-header-item ${showCompleted ? "selected" : ""
                        }`}
                    onClick={() => {
                        setShowCompleted(true)
                        setDMReached(false)
                    }}
                >
                    {showCompleted ? (
                        <CompletedCallWhiteIcon
                            height={"20px"}
                            width={"20px"}
                            style={{ marginRight: "15px" }}
                        />
                    ) : (
                        <CompletedCallIcon
                            height={"20px"}
                            width={"20px"}
                            style={{ marginRight: "15px" }}
                        />
                    )}
                    Completed Sales Calls
                </div>
            </div>

            <div className="table-container">
                <div className="header-container">
                    <div className="table-header">
                        <div className="table-header-title">
                            <span>{t("sales_call_summary_report")}</span>
                        </div>
                        <div className="total-count-action">
                            <span>
                                {`${t("total_count")}: `}
                                <span className="total-count-number">
                                    {getFilteredList()?.length}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="rc-scsr-btns">
                        {showCompleted && <div className="cSCall-toggle">
                            <CustomCheckBox
                                name={"show_only_dm_reached"}
                                title={t("show_only_dm_reached")}
                                checked={dmReached}
                                onClick={()=>handleOnChange(!dmReached)}
                            />
                        </div>}
                        {showCompleted &&
                            <div className="total-count-action">
                                <span>Total <strong>DM</strong> Reached:</span> <span className="total-count-number">{salesCallsSummaryList?.filter((doc) => doc.dm_reached === true).length}</span>
                            </div>}
                        <div className="rc-scsr-btn">
                            <CustomButton
                                leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                                loading={false}
                                name={t("download")}
                                enable={getFilteredList()?.length !== 0 && (loading === LoadingType.pending ? false : true)}
                                backgroundColor={THEME.buttonColor16}
                                onClick={() => handleDownload()}
                            />
                        </div>
                        <div>
                            <SearchBar
                                value={search}
                                onChange={setSearch}
                                onSearch={() => { }}
                            />
                        </div>
                        {/* <div>
                            <CustomButton
                                leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                                loading={false}
                                name={t("print")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => { }}
                            />
                        </div> */}
                    </div>
                </div>
                {getTable()}
            </div>
        </div>
    );


}

export default SalesCallSummaryReport;
import { useTranslation } from 'react-i18next';
import EmployeeAvatar from '../employee_avatar/employee_avatar';
import { IInBoundLeads } from '../../interfaces/inbound_leads';
import { CallIcon, EmailIcon, GlobeIcon, LocationIcon,GroupIcon } from '../../icons';
import { addressParser, convertToPhoneFormat, getArrayStrings } from '../../utils';
import SocialMediaProfile from '../social_media_profile/social_media_profile';
import { ProfileSocialMedia } from '../../interfaces';
import { useState } from 'react';
import { Portal } from 'react-portal';
import SendEmailPopup from '../../main/components/send_email/send_email_popup';
import { useAppDispatch } from '../../redux/store';
import { sendEmailActions } from '../../redux/send_email/send_email_reducer';
import { ProfilePictureType } from '../../enums';
import './inbound_leads_details_section.scss'

interface Props {
    inboundLeadDetails: IInBoundLeads | null,
}

const LeadDashboardDetailsSection: React.FunctionComponent<Props> = (props) => {
    const { inboundLeadDetails } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showSendEmail, setShowSendEmail] = useState(false);

    const inboundLeadProfileSection = [
        {
            sectionTitle: 'main_contact',
            sectionIcon: GroupIcon,
            direction: 'column',
            sectionContent: [
                { title: 'main_contact', valueField: "first_name", valueField2: "last_name" },
                { title: 'contact_number', valueField: "contact_number" },
                { title: 'role', valueField: "role" },
                
            ]
        },
        {
            sectionTitle: 'address',
            sectionIcon: LocationIcon,
            direction: 'column',
            sectionContent: [
                {
                    title: 'address', valueField: addressParser({
                        'street_address': inboundLeadDetails?.street_address,
                        'city': inboundLeadDetails?.city,
                        'state': inboundLeadDetails?.state,
                        'zip_code': inboundLeadDetails?.zip_code,
                    })
                },
            ]
        },
        {
            sectionTitle: 'email',
            sectionIcon: EmailIcon,
            direction: 'column',
            sectionContent: [
                { title: 'email', valueField: 'email' },
            ]
        },
        {
            sectionTitle: 'website',
            sectionIcon: GlobeIcon,
            direction: 'column',
            sectionContent: [
                { title: 'website', valueField: 'website' },
            ]
        },
    ];

    const handleClearError = () => {
        dispatch(sendEmailActions.clearSendEmailStateError())
    }

    return (
        <div className="tds-container lead-container">
            <EmployeePortalInfo
                name={inboundLeadDetails?.company_name}
                uri={""}
                objectId={inboundLeadDetails?.id}
                rating={""}
                level={""}
            />
            <div className="tds-ds-branch-selector">
                <div className="tds-ds-status" >
                    <span>
                        {`${t("status")}:`}
                    </span>
                    <span className="status-txt">
                        {inboundLeadDetails?.status ?? ""}
                    </span>
                </div>
        
            </div>
            {inboundLeadProfileSection.map((doc, index) => {
                return (
                    <div key={index} className="tds-info-container" style={{ marginTop: "1rem",marginBottom: index === inboundLeadProfileSection.length - 1 ? "1rem" : "0", }}>
                        <div className="tds-info-icon">
                            <doc.sectionIcon width={"100%"} height={"100%"} />
                        </div>
                        <div
                            className="tds-info-content"
                            style={{
                                ...(doc.direction === 'row') && { flexDirection: 'row', justifyContent: 'space-between' },
                            }}
                        >
                            {doc.sectionContent.map((data, index) => {
                                const arrayValue = getValues(data.valueField);
                                const arrayValue2 = getValues(data.valueField2);
                                return (
                                    <div
                                        key={index}
                                        className="tds-info-holder"
                                        style={{
                                            ...(doc.direction === 'row') && { flex: 1, width: "50%" },
                                            ...(index === 0) && { marginRight: '1em' }
                                        }
                                        
                                        }>
                                        <div className="tds-into-title">
                                            {data.title === "contact_number" ? null : <span>{t(data.title).toUpperCase()}</span>}
                                        </div>
                                        <div className="tds-info-value">
                                            {
                                                doc.sectionTitle === 'email' ?
                                                (<div className="email-info-value">
                                                    <span onClick={(inboundLeadDetails?.email !== "" && inboundLeadDetails?.email !== null) ? () => setShowSendEmail(true) : () => { }}>{(inboundLeadDetails?.email !== null && inboundLeadDetails?.email !== "") ? arrayValue : <span className="na-span">NA</span>}</span>
                                                </div>)
                                                : <span style={{fontWeight:"normal",fontSize:"0.821vw"}}>{data.title==="contact_number"? "Mobile":""} {data.title === "contact_number" ? <span style={{marginLeft:"0.3em"}}>{convertToPhoneFormat(inboundLeadDetails?.contact_number)}</span> : data.title==="website" ? <a href={getHttpsString(arrayValue.props.children.toString())} target='_blank' rel = "noreferrer">{arrayValue}</a>:arrayValue}</span>
                                            }
                                            <span style={{ marginLeft: "0.5em" }}>{arrayValue2}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
            <SocialMediaProfile
                title={t('social_media').toUpperCase()}
                profile={inboundLeadDetails?.social_media_links as ProfileSocialMedia}
            />
            {showSendEmail && (
                <Portal>
                    <SendEmailPopup
                        visible={showSendEmail}
                        data={{ obj_id: inboundLeadDetails?.id!, email: inboundLeadDetails?.email!}}
                        objectType={ProfilePictureType.lead}
                        onClose={() => setShowSendEmail(false)}
                        onClearError={handleClearError}
                    />
                </Portal>
            )}
        </div>
    );

    function getValues(obj: any) {
        if (inboundLeadDetails == null) return (<span>{''}</span>);
        if (Array.isArray(obj)) {
            return getArrayStrings(obj, inboundLeadDetails);
        }
        const value = (obj !== '' && inboundLeadDetails![obj] !== null) ? inboundLeadDetails![obj] : '';
        if (typeof obj === "object") {
            return obj;
        }
        if (obj === "email") {
            return (
                (inboundLeadDetails.email !== null && inboundLeadDetails.email !== "") ? <span>{inboundLeadDetails.email}</span> : <span className="na-span">NA</span>
            );
        }
        return (
            <span>{value}</span>
        );
    }
    function getHttpsString(url: string) {
        if (url.includes('https://') || url.includes('http://')) {
            return url;
        }
        return `https://${url}`;
    }

}

const EmployeePortalInfo = (props) => {
    const { name } = props;

    return (
        <div className="tds-user-info">
            <EmployeeAvatar
                onClick={() => { }}
                url={""}
            />
            <div className="tds-user-name">
                <span>{name}</span>
            </div>
        </div>
    );
}

export default LeadDashboardDetailsSection;
import axios from "axios";
import {
  ToggleRoleData,
  UsersQuery,
  ICreateUser,
  IUpdateUser,
  IHandbookUploadUrlsQuery,
  AdminCenterEmailConfiguration,
} from "../interfaces";
import { IAgencyUploadUrlsQuery } from "../interfaces/agency_management";
import { AgencyToggleStatus, ToggleStatus } from "../interfaces/toggle_status";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";
import { RiskMgmtInsuranceCompany } from "../interfaces/risk_mgmt_insurance_company";
import { IAdminRCVacationConfig } from "../interfaces/report_center";

export const AdminCenterAPI = {
  /// [USER MANAGEMENT]

  getUsersList(credentials?: UsersQuery) {
    if (credentials == null) return interceptor.get(`${UrlBuilder.getUsers}`);
    return interceptor.get(
      `${UrlBuilder.getUsers}?branch_id=${credentials?.branch_id}&role=${credentials?.role}`
    );
  },

  getUserById(userId: string) {
    return interceptor.get(`${UrlBuilder.getProfile}?user_id=${userId}`);
  },

  createUser(credentials: ICreateUser) {
    return interceptor.post(UrlBuilder.createUser, credentials);
  },

  updateProfile(credentials: IUpdateUser) {
    return interceptor.patch(
      `${UrlBuilder.updateProfile}?user_id=${credentials.id}`,
      credentials.data
    );
  },

  toggleUserRole(credentials: ToggleRoleData) {
    return interceptor.post(
      `${UrlBuilder.toggleRole}?role=${credentials.role}&user_id=${credentials.user_id}&add=${credentials.add}`
    );
  },

  getUsersDropdownList(credentials?: UsersQuery) {
    if (credentials == null)
      return interceptor.get(`${UrlBuilder.usersDropdown}`);
    return interceptor.get(
      `${UrlBuilder.usersDropdown}?branch_id=${credentials?.branch_id}&role=${credentials?.role}`
    );
  },

  /// [System Configuration -> Engagement Activity Methods]

  getEngagementActivityMethod() {
    return interceptor.get(`${UrlBuilder.engagementActivityMethod}`);
  },

  createEngagementActivityMethod(data) {
    return interceptor.post(`${UrlBuilder.engagementActivityMethod}`, data);
  },

  deleteEngagementActivityMethod(method_id) {
    return interceptor.delete(
      `${UrlBuilder.engagementActivityMethod}?engagement_activity_method_id=${method_id}`
    );
  },

  updateEngagementActivityMethod(data) {
    return interceptor.patch(`${UrlBuilder.engagementActivityMethod}`, data);
  },
  /// [System Configuration -> Success Profile Questions]
  getSuccessProfileQuestions() {
    return interceptor.get(`${UrlBuilder.successProfileQuestions}`);
  },

  createSuccessProfileQuestions(data) {
    return interceptor.post(`${UrlBuilder.successProfileQuestions}`, data);
  },

  deleteSuccessProfileQuestions(method_id) {
    return interceptor.delete(
      `${UrlBuilder.successProfileQuestions}?success_profile_question_id=${method_id}`
    );
  },

  updateSuccessProfileQuestions(data) {
    return interceptor.patch(`${UrlBuilder.successProfileQuestions}`, data);
  },

  /// [System Configuration Engagement Activity Types]

  getEngagementActivityTypes() {
    return interceptor.get(`${UrlBuilder.engagementActivityType}`);
  },

  createEngagementActivityTypes(data) {
    return interceptor.post(`${UrlBuilder.engagementActivityType}`, data);
  },

  deleteEngagementActivityTypes(method_id) {
    return interceptor.delete(
      `${UrlBuilder.engagementActivityType}?engagement_activity_type_id=${method_id}`
    );
  },

  updateEngagementActivityTypes(data) {
    return interceptor.patch(`${UrlBuilder.engagementActivityType}`, data);
  },

  /// [System Configuration ---> Skill list]

  getBusinessSector() {
    return interceptor.get(UrlBuilder.businessSector);
  },
  getSkillsList() {
    return interceptor.get(UrlBuilder.skillSet);
  },
  createSkillSet(data) {
    return interceptor.post(`${UrlBuilder.skillSet}`, data);
  },
  updateSkillSet(data) {
    return interceptor.patch(`${UrlBuilder.skillSet}`, data);
  },
  deleteSkillSet(id) {
    return interceptor.delete(`${UrlBuilder.skillSet}?skill_set_id=${id}`);
  },

  getCertifications() {
    return interceptor.get(UrlBuilder.certifications);
  },
  createCertifications(data) {
    return interceptor.post(`${UrlBuilder.certifications}`, data);
  },
  updateCertifications(data) {
    return interceptor.patch(`${UrlBuilder.certifications}`, data);
  },
  deleteCertifications(id) {
    return interceptor.delete(
      `${UrlBuilder.certifications}?certification_id=${id}`
    );
  },
  getCredentials() {
    return interceptor.get(UrlBuilder.credentials);
  },
  createCredentials(data) {
    return interceptor.post(`${UrlBuilder.credentials}`, data);
  },
  updateCredentials(data) {
    return interceptor.patch(`${UrlBuilder.credentials}`, data);
  },
  deleteCredentials(id) {
    return interceptor.delete(`${UrlBuilder.credentials}?credential_id=${id}`);
  },
  getEducationRequirements() {
    return interceptor.get(UrlBuilder.educationRequirements);
  },
  createEducationRequirements(data) {
    return interceptor.post(`${UrlBuilder.educationRequirements}`, data);
  },
  updateEducationRequirements(data) {
    return interceptor.patch(`${UrlBuilder.educationRequirements}`, data);
  },
  deleteEducationRequirements(id) {
    return interceptor.delete(
      `${UrlBuilder.educationRequirements}?education_requirement_id=${id}`
    );
  },
  getEquipments() {
    return interceptor.get(UrlBuilder.equipments);
  },
  createEquipments(data) {
    return interceptor.post(`${UrlBuilder.equipments}`, data);
  },
  updateEquipments(data) {
    return interceptor.patch(`${UrlBuilder.equipments}`, data);
  },
  deleteEquipments(id) {
    return interceptor.delete(
      `${UrlBuilder.equipments}?requirement_equipment_id=${id}`
    );
  },

  /// [System Configuration -> Sales Call Journey Methods]

  getSalesCallJourneyMethods() {
    return interceptor.get(`${UrlBuilder.salesCallJourneyMethod}`);
  },

  createSalesCallJourneyMethod(data) {
    return interceptor.post(`${UrlBuilder.salesCallJourneyMethod}`, data);
  },

  deleteSalesCallJourneyMethods(method_id) {
    return interceptor.delete(
      `${UrlBuilder.salesCallJourneyMethod}?sales_call_journey_method_id=${method_id}`
    );
  },

  updateSalesCallJourneyMethods(data) {
    return interceptor.patch(`${UrlBuilder.salesCallJourneyMethod}`, data);
  },
  /// [System Configuration Service Profile]

  getServiceProfile() {
    return interceptor.get(`${UrlBuilder.serviceProfile}`);
  },

  createServiceProfile(data) {
    return interceptor.post(`${UrlBuilder.serviceProfile}`, data);
  },

  deleteServiceProfile(method_id) {
    return interceptor.delete(
      `${UrlBuilder.serviceProfile}?service_profile_id=${method_id}`
    );
  },

  updateServiceProfile(data) {
    return interceptor.patch(`${UrlBuilder.serviceProfile}`, data);
  },

  /// [System Configuration -> Marketing Campaign Journey Steps]

  getMarketingCampaignSteps() {
    return interceptor.get(`${UrlBuilder.marketingCampaignStep}`);
  },

  createMarketingCampaignStep(data) {
    return interceptor.post(`${UrlBuilder.marketingCampaignStep}`, data);
  },

  deleteMarketingCampaignStep(step_id) {
    return interceptor.delete(
      `${UrlBuilder.marketingCampaignStep}?marketing_campaign_step_id=${step_id}`
    );
  },

  updateMarketingCampaignStep(data) {
    return interceptor.patch(`${UrlBuilder.marketingCampaignStep}`, data);
  },

  /// [System Configuration -> Business Sector]

  createBusinessSector(data) {
    return interceptor.post(`${UrlBuilder.businessSector}`, data);
  },

  deleteBusinessSector(sector_id) {
    return interceptor.delete(
      `${UrlBuilder.businessSector}?business_sector_id=${sector_id}`
    );
  },

  updateBusinessSector(data) {
    return interceptor.patch(`${UrlBuilder.businessSector}`, data);
  },

  /// [System Configuration -> Status Code]

  getStatusCodes() {
    return interceptor.get(`${UrlBuilder.statusCode}`);
  },

  createStatusCode(data) {
    return interceptor.post(`${UrlBuilder.statusCode}`, data);
  },

  deleteStatusCode(code_id) {
    return interceptor.delete(
      `${UrlBuilder.statusCode}?status_code_id=${code_id}`
    );
  },

  updateStatusCode(data) {
    return interceptor.patch(`${UrlBuilder.statusCode}`, data);
  },

  /// [System Configuration -> Contact Role]

  getContactRoles() {
    return interceptor.get(`${UrlBuilder.contactRole}`);
  },

  createContactRole(data) {
    return interceptor.post(`${UrlBuilder.contactRole}`, data);
  },

  deleteContactRole(contact_id) {
    return interceptor.delete(
      `${UrlBuilder.contactRole}?contact_role_id=${contact_id}`
    );
  },

  updateContactRole(data) {
    return interceptor.patch(`${UrlBuilder.contactRole}`, data);
  },

  /// [System Configuration -> Company Categories]

  getCompanyCategories() {
    return interceptor.get(`${UrlBuilder.companyCategories}`);
  },

  createCompanyCategory(data) {
    return interceptor.post(`${UrlBuilder.companyCategories}`, data);
  },

  deleteCompanyCategory(category_id) {
    return interceptor.delete(
      `${UrlBuilder.companyCategories}?category_id=${category_id}`
    );
  },

  updateCompanyCategory(data) {
    return interceptor.patch(`${UrlBuilder.companyCategories}`, data);
  },
  /// [System Configuration -> Check Delivery]

  getCheckDelivery() {
    return interceptor.get(`${UrlBuilder.checkDelivery}`);
  },

  createCheckDelivery(data) {
    return interceptor.post(`${UrlBuilder.checkDelivery}`, data);
  },

  deleteCheckDelivery(delivery_id) {
    return interceptor.delete(
      `${UrlBuilder.checkDelivery}?check_delivery_id=${delivery_id}`
    );
  },

  updateCheckDelivery(data) {
    return interceptor.patch(`${UrlBuilder.checkDelivery}`, data);
  },

  /// [System Configuration -> Onboarding Step]

  getOnboardingSteps() {
    return interceptor.get(`${UrlBuilder.onboardingStep}`);
  },

  createOnboardingStep(data) {
    return interceptor.post(`${UrlBuilder.onboardingStep}`, data);
  },

  deleteOnboardingStep(step_id) {
    return interceptor.delete(
      `${UrlBuilder.onboardingStep}?onboarding_id=${step_id}`
    );
  },

  updateOnboardingStep(data) {
    return interceptor.patch(`${UrlBuilder.onboardingStep}`, data);
  },

  /// [System Configuration -> Service Call]

  getServiceCalls() {
    return interceptor.get(`${UrlBuilder.serviceCall}`);
  },

  createServiceCall(data) {
    return interceptor.post(`${UrlBuilder.serviceCall}`, data);
  },

  deleteServiceCall(call_id) {
    return interceptor.delete(
      `${UrlBuilder.serviceCall}?service_call_id=${call_id}`
    );
  },

  updateServiceCall(data) {
    return interceptor.patch(`${UrlBuilder.serviceCall}`, data);
  },

  /// [System Configuration -> Traffic Light Engagement Metric]

  getTrafficLightEngagementMetrics() {
    return interceptor.get(`${UrlBuilder.trafficLightEngagementMetric}`);
  },

  createTrafficLightEngagementMetric(data) {
    return interceptor.post(`${UrlBuilder.trafficLightEngagementMetric}`, data);
  },

  deleteTrafficLightEngagementMetric(metric_id) {
    return interceptor.delete(
      `${UrlBuilder.trafficLightEngagementMetric}?traffic_light_engagement_metric_id=${metric_id}`
    );
  },

  updateTrafficLightEngagementMetric(data) {
    return interceptor.patch(
      `${UrlBuilder.trafficLightEngagementMetric}`,
      data
    );
  },

  /// [System Configuration -> Success Profile Question Steps]

  getSuccessProfileQuestionSteps() {
    return interceptor.get(`${UrlBuilder.successProfileQuestionSteps}`);
  },

  createSuccessProfileQuestionStep(data) {
    return interceptor.post(`${UrlBuilder.successProfileQuestionSteps}`, data);
  },

  deleteSuccessProfileQuestionStep(step_id) {
    return interceptor.delete(
      `${UrlBuilder.successProfileQuestionSteps}?success_profile_question_step_id=${step_id}`
    );
  },

  updateSuccessProfileQuestionStep(data) {
    return interceptor.patch(`${UrlBuilder.successProfileQuestionSteps}`, data);
  },

  /// [System Configuration -> Company Status]

  getCompanyStatus() {
    return interceptor.get(`${UrlBuilder.companyStatus}`);
  },

  createCompanyStatus(data) {
    return interceptor.post(`${UrlBuilder.companyStatus}`, data);
  },

  deleteCompanyStatus(status_id) {
    return interceptor.delete(
      `${UrlBuilder.companyStatus}?company_status_id=${status_id}`
    );
  },

  updateCompanyStatus(data) {
    return interceptor.patch(`${UrlBuilder.companyStatus}`, data);
  },

  /// [System Configuration -> Contact Status]

  getContactStatus() {
    return interceptor.get(`${UrlBuilder.contactStatus}`);
  },

  createContactStatus(data) {
    return interceptor.post(`${UrlBuilder.contactStatus}`, data);
  },

  deleteContactStatus(status_id) {
    return interceptor.delete(
      `${UrlBuilder.contactStatus}?contact_status_id=${status_id}`
    );
  },

  updateContactStatus(data) {
    return interceptor.patch(`${UrlBuilder.contactStatus}`, data);
  },

  /// [System Configuration -> Talent Status]

  getTalentStatus() {
    return interceptor.get(`${UrlBuilder.talentStatus}`);
  },

  createTalentStatus(data) {
    return interceptor.post(`${UrlBuilder.talentStatus}`, data);
  },

  deleteTalentStatus(status_id) {
    return interceptor.delete(
      `${UrlBuilder.talentStatus}?talent_status_id=${status_id}`
    );
  },

  updateTalentStatus(data) {
    return interceptor.patch(`${UrlBuilder.talentStatus}`, data);
  },

  /// [System Configuration -> Job Status]

  getJobStatus() {
    return interceptor.get(`${UrlBuilder.jobStatus}`);
  },

  createJobStatus(data) {
    return interceptor.post(`${UrlBuilder.jobStatus}`, data);
  },

  deleteJobStatus(status_id) {
    return interceptor.delete(
      `${UrlBuilder.jobStatus}?job_status_id=${status_id}`
    );
  },

  updateJobStatus(data) {
    return interceptor.patch(`${UrlBuilder.jobStatus}`, data);
  },

  /// [System Configuration -> Contact Method]

  getContactMethod() {
    return interceptor.get(`${UrlBuilder.contactMethod}`);
  },

  createContactMethod(data) {
    return interceptor.post(`${UrlBuilder.contactMethod}`, data);
  },

  deleteContactMethod(method_id) {
    return interceptor.delete(
      `${UrlBuilder.contactMethod}?contact_method_id=${method_id}`
    );
  },

  updateContactMethod(data) {
    return interceptor.patch(`${UrlBuilder.contactMethod}`, data);
  },

  /// [System Configuration -> End Assignment Reasons]

  getEndAssignmentReasons() {
    return interceptor.get(`${UrlBuilder.endAssignmentReason}`);
  },

  createEndAssignmentReason(data) {
    return interceptor.post(`${UrlBuilder.endAssignmentReason}`, data);
  },

  deleteEndAssignmentReason(reason_id) {
    return interceptor.delete(
      `${UrlBuilder.endAssignmentReason}?end_assignment_reason_id=${reason_id}`
    );
  },

  updateEndAssignmentReason(data) {
    return interceptor.patch(`${UrlBuilder.endAssignmentReason}`, data);
  },

  /// [System Configuration -> Job Order Placement]

  getJobOrderPlacements() {
    return interceptor.get(`${UrlBuilder.jobOrderPlacement}`);
  },

  createJobOrderPlacement(data) {
    return interceptor.post(`${UrlBuilder.jobOrderPlacement}`, data);
  },

  deleteJobOrderPlacement(placement_id) {
    return interceptor.delete(
      `${UrlBuilder.jobOrderPlacement}?job_order_placement_id=${placement_id}`
    );
  },

  updateJobOrderPlacement(data) {
    return interceptor.patch(`${UrlBuilder.jobOrderPlacement}`, data);
  },

  /// [System Configuration -> Talent Interview Status]

  getTalentInterviewStatus() {
    return interceptor.get(`${UrlBuilder.talentInterviewStatus}`);
  },

  createTalentInterviewStatus(data) {
    return interceptor.post(`${UrlBuilder.talentInterviewStatus}`, data);
  },

  deleteTalentInterviewStatus(status_id) {
    return interceptor.delete(
      `${UrlBuilder.talentInterviewStatus}?talent_interview_status_id=${status_id}`
    );
  },

  updateTalentInterviewStatus(data) {
    return interceptor.patch(`${UrlBuilder.talentInterviewStatus}`, data);
  },

  /// [System Configuration -> Talent Interview Questions]

  getTalentInterviewQuestions() {
    return interceptor.get(`${UrlBuilder.talentInterviewQuestions}`);
  },

  createTalentInterviewQuestion(data) {
    return interceptor.post(`${UrlBuilder.talentInterviewQuestions}`, data);
  },

  deleteTalentInterviewQuestion(question_id) {
    return interceptor.delete(
      `${UrlBuilder.talentInterviewQuestions}?talent_interview_question_id=${question_id}`
    );
  },

  updateTalentInterviewQuestion(data) {
    return interceptor.patch(`${UrlBuilder.talentInterviewQuestions}`, data);
  },

  /// [System Configuration -> Talent Other Docs]

  getOtherDocs() {
    return interceptor.get(`${UrlBuilder.OtherDocs}`);
  },

  createOtherDocs(data) {
    return interceptor.post(`${UrlBuilder.OtherDocs}`, data);
  },

  deleteOtherDocs(other_doc_id) {
    return interceptor.delete(
      `${UrlBuilder.OtherDocs}?other_doc_id=${other_doc_id}`
    );
  },

  updateOtherDocs(data) {
    return interceptor.patch(`${UrlBuilder.OtherDocs}`, data);
  },

  /// [System Configuration -> Security Policy -> Password Expiration]

  getAgencyDetails() {
    return interceptor.get(`${UrlBuilder.agencyDetails}`);
  },

  updateAgencyDetails(data) {
    return interceptor.patch(`${UrlBuilder.updateAgencyDetails}`, data);
  },

  updatePasswordExpirationDays(data) {
    return interceptor.patch(`${UrlBuilder.passwordExpirationUpdate}`, data);
  },

  /// [System Configuration -> Job Order Source]

  getJobOrderSources() {
    return interceptor.get(`${UrlBuilder.jobOrderSource}`);
  },

  createJobOrderSource(data) {
    return interceptor.post(`${UrlBuilder.jobOrderSource}`, data);
  },

  deleteJobOrderSource(source_id) {
    return interceptor.delete(
      `${UrlBuilder.jobOrderSource}?job_order_source_id=${source_id}`
    );
  },

  updateJobOrderSource(data) {
    return interceptor.patch(`${UrlBuilder.jobOrderSource}`, data);
  },

  /// [System Configuration -> Work Comp Code]

  getWorkCompCodes() {
    return interceptor.get(`${UrlBuilder.workCompCode}`);
  },

  createWorkCompCode(data) {
    return interceptor.post(`${UrlBuilder.workCompCode}`, data);
  },

  deleteWorkCompCode(code_id) {
    return interceptor.delete(
      `${UrlBuilder.workCompCode}?comp_code_id=${code_id}`
    );
  },

  updateWorkCompCode(data) {
    return interceptor.patch(`${UrlBuilder.workCompCode}`, data);
  },

  /// [System Configuration -> Pay Cycle]

  getPayCycles() {
    return interceptor.get(`${UrlBuilder.payCycle}`);
  },

  createPayCycle(data) {
    return interceptor.post(`${UrlBuilder.payCycle}`, data);
  },

  deletePayCycle(id) {
    return interceptor.delete(`${UrlBuilder.payCycle}?pay_cycle_id=${id}`);
  },

  updatePayCycle(data) {
    return interceptor.patch(`${UrlBuilder.payCycle}`, data);
  },

  /// [System Configuration -> Pay Period]

  getPayPeriods() {
    return interceptor.get(`${UrlBuilder.payPeriod}`);
  },

  createPayPeriod(data) {
    return interceptor.post(`${UrlBuilder.payPeriod}`, data);
  },

  deletePayPeriod(id) {
    return interceptor.delete(`${UrlBuilder.payPeriod}?pay_period_id=${id}`);
  },

  updatePayPeriod(data) {
    return interceptor.patch(`${UrlBuilder.payPeriod}`, data);
  },

  /// [Agency Setup -> Branches]

  getBranches() {
    return interceptor.get(`${UrlBuilder.getAgencyBranches}`);
  },

  createBranch(data) {
    return interceptor.post(`${UrlBuilder.createAgencyBranch}`, data);
  },

  updateBranch(data) {
    return interceptor.patch(`${UrlBuilder.updateAgencyBranch}`, data);
  },

  /// [System Configuration -> Assignment Type]

  getAssignmentTypes() {
    return interceptor.get(`${UrlBuilder.assignmentType}`);
  },

  createAssignmentType(data) {
    return interceptor.post(`${UrlBuilder.assignmentType}`, data);
  },

  deleteAssignmentType(id) {
    return interceptor.delete(
      `${UrlBuilder.assignmentType}?assignment_type_id=${id}`
    );
  },

  updateAssignmentType(data) {
    return interceptor.patch(`${UrlBuilder.assignmentType}`, data);
  },
  /// [System Configuration -> OnBoarding Type]
  getAgencyOnboarding() {
    return interceptor.get(`${UrlBuilder.adminCenterAgencyOnBoarding}`);
  },

  updateAgencyOnboarding(data) {
    return interceptor.patch(`${UrlBuilder.adminCenterAgencyOnBoarding}`, data);
  },

  getPreAgencyOnboardingHandbookUploadURL(query: IHandbookUploadUrlsQuery) {
    return interceptor.get(`${UrlBuilder.adminCenterAgencyOnBoardingHandbookUploadURL}?file_name=${query.file_name}`);
  },

  /// [Role Management --> ]

  /// [System Configuration -> Text Em All]
  getTextEmAll() {
    return interceptor.get(`${UrlBuilder.textEmAll}`);
  },
  updateTextEmAll(data) {
    return interceptor.patch(`${UrlBuilder.textEmAll}`, data);
  },
  postUserCredSSO(credentials) {
    return interceptor.post(UrlBuilder.textEmAllSSOSetup, credentials);
  },

  /// [Team Management -> Team Services]
  getTMTeamsDetails() {
    return interceptor.get(UrlBuilder.teamServices);
  },
  getTMTeamMembersDetailsById(id: string) {
    return interceptor.get(`${UrlBuilder.teamServices}?team_id=${id}`);
  },
  createTMTeams(data) {
    return interceptor.post(`${UrlBuilder.teamServices}`, data);
  },
  updateTMTeams(data) {
    return interceptor.patch(`${UrlBuilder.teamServices}`, data);
  },
  deleteTMTeams(id: string) {
    return interceptor.delete(`${UrlBuilder.deleteTeamServices}?team_id=${id}`);
  },

  /// [PayRoll Tax Configuration]
  getPayRollTaxConfigList() {
    return interceptor.get(`${UrlBuilder.payRollTaxConfig}`);
  },

  createPayRollTaxConfigList(data) {
    return interceptor.post(`${UrlBuilder.payRollTaxConfig}`, data);
  },
  updatePayRollTaxConfigList(data) {
    return interceptor.patch(`${UrlBuilder.payRollTaxConfig}`, data);
  },
  deletePayRollTaxConfigList(id: string) {
    return interceptor.delete(`${UrlBuilder.payRollTaxConfig}?payroll_tax_configuration_id=${id}`);
  },


  /// [Sales Tax Configuration]
  getSalesTaxConfigList() {
    return interceptor.get(`${UrlBuilder.salesTaxConfig}`);
  },
  createSalesTaxConfigList(data) {
    return interceptor.post(`${UrlBuilder.salesTaxConfig}`, data);
  },
  updateSalesTaxConfigList(data) {
    return interceptor.patch(`${UrlBuilder.salesTaxConfig}`, data);
  },
  deleteSalesTaxConfigList(id: string) {
    return interceptor.delete(`${UrlBuilder.salesTaxConfig}?sales_tax_configuration_id=${id}`);
  },


  /// [Work Comp Configuration]
  getWorkCompConfigList() {
    return interceptor.get(`${UrlBuilder.workCompConfig}`);
  },
  getWorkCompConfigDropdown() {
    return interceptor.get(`${UrlBuilder.workCompConfigDropdown}`);
  },
  createWorkCompConfigList(data) {
    return interceptor.post(`${UrlBuilder.workCompConfig}`, data);
  },
  updateWorkCompConfigList(data) {
    return interceptor.patch(`${UrlBuilder.workCompConfig}`, data);
  },
  deleteWorkCompConfigList(id: string) {
    return interceptor.delete(`${UrlBuilder.workCompConfig}?work_comp_id=${id}`);
  },


  /// [User Management Toggle Status]
  UMtoggleStatus(data: ToggleStatus) {
    return interceptor.post(
      `${UrlBuilder.toggleStatus}?user_id=${data.user_id}&enable=${data.status}`
    );
  },

  /// [Agency Management Toggle Status]
  agencyToggleStatus(data: AgencyToggleStatus) {
    return interceptor.patch(
      `${UrlBuilder.agencyToggleStatus}?domain=${data.domain}&enable=${data.enable}`
    );
  },

  /// [Agency Management]
  getAgencies() {
    return interceptor.get(UrlBuilder.getAgencies);
  },
  getAgencyById(agencyId: string) {
    return interceptor.get(`${UrlBuilder.getAgencyById}?agency_id=${agencyId}`);
  },
  createAgency(data) {
    return interceptor.post(`${UrlBuilder.createAgency}`, data);
  },
  updateAgency(data) {
    return interceptor.patch(`${UrlBuilder.updateAgency}`, data);
  },
  getAgencyURLForUpload(query: IAgencyUploadUrlsQuery) {
    return interceptor.get(`${UrlBuilder.getAgencyLogo}?agency_id=${query.agency_id}&logo_file_name=${query.logo_file_name}`)
  },
  uploadAgencyLogo(url: string, data) {
    return axios.put(url, data);
  },

  /// [Admin Center Info Text]
  getInfoTextByCategory(category: string) {
    return interceptor.get(`${UrlBuilder.infoText}?category=${category}`);
  },
  updateInfoTextByCategory(data) {
    return interceptor.patch(`${UrlBuilder.infoText}`, data);
  },

  /// [Back Office]

  /// [Back Office -> Additiona Tax]

  getAdditionalTaxDetails() {
    return interceptor.get(UrlBuilder.additionalTax);
  },
  createAdditionalTaxDetails(data) {
    return interceptor.post(`${UrlBuilder.additionalTax}`, data);
  },
  updateAdditionalTaxDetails(data) {
    return interceptor.patch(`${UrlBuilder.additionalTax}`, data);
  },
  deleteAdditionalTaxDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.additionalTax}?additional_tax_id=${id}`);
  },

  getBankAccountDetails() {
    return interceptor.get(UrlBuilder.bankAccount);
  },
  createBankAccountDetails(data) {
    return interceptor.post(`${UrlBuilder.bankAccount}`, data);
  },
  updateBankAccountDetails(data) {
    return interceptor.patch(`${UrlBuilder.bankAccount}`, data);
  },
  deleteBankAccountDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.bankAccount}?bank_account_id=${id}`);
  },

  getBillingPeriodDetails() {
    return interceptor.get(UrlBuilder.billingPeriod);
  },
  createBillingPeriodDetails(data) {
    return interceptor.post(`${UrlBuilder.billingPeriod}`, data);
  },
  updateBillingPeriodDetails(data) {
    return interceptor.patch(`${UrlBuilder.billingPeriod}`, data);
  },
  deleteBillingPeriodDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.billingPeriod}?billing_period_id=${id}`);
  },

  getBillingRateDetails() {
    return interceptor.get(UrlBuilder.billingRate);
  },
  createBillingRateDetails(data) {
    return interceptor.post(`${UrlBuilder.billingRate}`, data);
  },
  updateBillingRateDetails(data) {
    return interceptor.patch(`${UrlBuilder.billingRate}`, data);
  },
  deleteBillingRateDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.billingRate}?billing_rate_id=${id}`);
  },

  getDeafaultPaymentOptionDetails() {
    return interceptor.get(UrlBuilder.defaultPaymentOption);
  },
  createDeafaultPaymentOptionDetails(data) {
    return interceptor.post(`${UrlBuilder.defaultPaymentOption}`, data);
  },
  updateDeafaultPaymentOptionDetails(data) {
    return interceptor.patch(`${UrlBuilder.defaultPaymentOption}`, data);
  },
  deleteDeafaultPaymentOptionDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.defaultPaymentOption}?default_payment_option_id=${id}`);
  },

  getEmployeeDeductionDetails() {
    return interceptor.get(UrlBuilder.employeeDeduction);
  },
  createEmployeeDeductionDetails(data) {
    return interceptor.post(`${UrlBuilder.employeeDeduction}`, data);
  },
  updateEmployeeDeductionDetails(data) {
    return interceptor.patch(`${UrlBuilder.employeeDeduction}`, data);
  },
  deleteEmployeeDeductionDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.employeeDeduction}?employee_deduction_id=${id}`);
  },

  getDeductionInstitutionDetails() {
    return interceptor.get(UrlBuilder.deductionInstitution);
  },
  createDeductionInstitutionDetails(data) {
    return interceptor.post(`${UrlBuilder.deductionInstitution}`, data);
  },
  updateDeductionInstitutionDetails(data) {
    return interceptor.patch(`${UrlBuilder.deductionInstitution}`, data);
  },
  deleteDeductionInstitutionDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.deductionInstitution}?deduction_institution_id=${id}`);
  },

  getDeductionTypeDetails() {
    return interceptor.get(UrlBuilder.deductionType);
  },
  createDeductionTypeDetails(data) {
    return interceptor.post(`${UrlBuilder.deductionType}`, data);
  },
  updateDeductionTypeDetails(data) {
    return interceptor.patch(`${UrlBuilder.deductionType}`, data);
  },
  deleteDeductionTypeDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.deductionType}?deduction_type_id=${id}`);
  },

  getGroupByDetails() {
    return interceptor.get(UrlBuilder.groupBy);
  },
  createGroupByDetails(data) {
    return interceptor.post(`${UrlBuilder.groupBy}`, data);
  },
  updateGroupByDetails(data) {
    return interceptor.patch(`${UrlBuilder.groupBy}`, data);
  },
  deleteGroupByDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.groupBy}?group_by_id=${id}`);
  },

  getMappingGroupDetails() {
    return interceptor.get(UrlBuilder.mappingGroup);
  },
  createMappingGroupDetails(data) {
    return interceptor.post(`${UrlBuilder.mappingGroup}`, data);
  },
  updateMappingGroupDetails(data) {
    return interceptor.patch(`${UrlBuilder.mappingGroup}`, data);
  },
  deleteMappingGroupDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.mappingGroup}?mapping_group_id=${id}`);
  },

  getPayTypeDetails() {
    return interceptor.get(UrlBuilder.payType);
  },
  createPayTypeDetails(data) {
    return interceptor.post(`${UrlBuilder.payType}`, data);
  },
  updatePayTypeDetails(data) {
    return interceptor.patch(`${UrlBuilder.payType}`, data);
  },
  deletePayTypeDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.payType}?pay_type_id=${id}`);
  },

  getMarkUpDetails() {
    return interceptor.get(UrlBuilder.markUp);
  },
  createMarkUpDetails(data) {
    return interceptor.post(`${UrlBuilder.markUp}`, data);
  },
  updateMarkUpDetails(data) {
    return interceptor.patch(`${UrlBuilder.markUp}`, data);
  },
  deleteMarkUpDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.markUp}?mark_up_id=${id}`);
  },

  getPaymentMethodDetails() {
    return interceptor.get(UrlBuilder.paymentMethod);
  },
  createPaymentMethodDetails(data) {
    return interceptor.post(`${UrlBuilder.paymentMethod}`, data);
  },
  updatePaymentMethodDetails(data) {
    return interceptor.patch(`${UrlBuilder.paymentMethod}`, data);
  },
  deletePaymentMethodDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.paymentMethod}?payment_method_id=${id}`);
  },

  getPaymentTermsDetails() {
    return interceptor.get(UrlBuilder.paymentTerms);
  },
  createPaymentTermsDetails(data) {
    return interceptor.post(`${UrlBuilder.paymentTerms}`, data);
  },
  updatePaymentTermsDetails(data) {
    return interceptor.patch(`${UrlBuilder.paymentTerms}`, data);
  },
  deletePaymentTermsDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.paymentTerms}?payment_terms_id=${id}`);
  },

  getProcessingMethodDetails() {
    return interceptor.get(UrlBuilder.processingMethod);
  },
  createProcessingMethodDetails(data) {
    return interceptor.post(`${UrlBuilder.processingMethod}`, data);
  },
  updateProcessingMethodDetails(data) {
    return interceptor.patch(`${UrlBuilder.processingMethod}`, data);
  },
  deleteProcessingMethodDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.processingMethod}?processing_method_id=${id}`);
  },

  getRunTypeDetails() {
    return interceptor.get(UrlBuilder.runType);
  },
  createRunTypeDetails(data) {
    return interceptor.post(`${UrlBuilder.runType}`, data);
  },
  updateRunTypeDetails(data) {
    return interceptor.patch(`${UrlBuilder.runType}`, data);
  },
  deleteRunTypeDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.runType}?run_type_id=${id}`);
  },

  getTransactionTypeDetails() {
    return interceptor.get(UrlBuilder.transactionType);
  },
  createTransactionTypeDetails(data) {
    return interceptor.post(`${UrlBuilder.transactionType}`, data);
  },
  updateTransactionTypeDetails(data) {
    return interceptor.patch(`${UrlBuilder.transactionType}`, data);
  },
  deleteTransactionTypeDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.transactionType}?transaction_type_id=${id}`);
  },

  ///Employee Contribution Dropdown

  getEmployeeContributionDetails() {
    return interceptor.get(UrlBuilder.employeeContribution);
  },
  createEmployeeContributionDetails(data) {
    return interceptor.post(`${UrlBuilder.employeeContribution}`, data);
  },
  updateEmployeeContributionDetails(data) {
    return interceptor.patch(`${UrlBuilder.employeeContribution}`, data);
  },
  deleteEmployeeContributionDetails(id: string) {
    return interceptor.delete(`${UrlBuilder.employeeContribution}?employee_contribution_id=${id}`);
  },

  ///System Configuration - Insurance Company

  getInsuranceCompanyData() {
    return interceptor.get(UrlBuilder.riskManagementInsuranceCompany);
  },

  createInsuranceCompany(doc: RiskMgmtInsuranceCompany) {
    return interceptor.post(UrlBuilder.riskManagementInsuranceCompany, doc);
  },

  updateInsuranceCompany(doc: RiskMgmtInsuranceCompany) {
    return interceptor.patch(UrlBuilder.riskManagementInsuranceCompany, doc);
  },
  deleteInsuranceCompany(insurance_company_id: string) {
    return interceptor.delete(`${UrlBuilder.riskManagementInsuranceCompany}?insurance_company_id=${insurance_company_id}`);
  },

  // Report Center 

  getVacationConfig() {
    return interceptor.get(UrlBuilder.vacationConfig);
  },
  patchVacationConfig(doc: IAdminRCVacationConfig) {
    return interceptor.patch(UrlBuilder.vacationConfig, doc);
  },

  ///Check And Invoice Starting Numbers

  getCheckAndInvoiceStartingNumbersDetails() {
    return interceptor.get(UrlBuilder.backofficeConfiguration);
  },
  updateCheckAndInvoiceStartingNumbersDetails(data) {
    return interceptor.patch(`${UrlBuilder.backofficeConfiguration}`, data);
  },

  /// [System Configuration -> Email Configuration]
  getEmailConfigList() {
    return interceptor.get(UrlBuilder.emailConfiguration);
  },
  createEmailConfigList(doc: { email: string, password: string }) {
    return interceptor.post(UrlBuilder.emailConfiguration, doc);
  },
  updateEmailConfigList(data: AdminCenterEmailConfiguration) {
    return interceptor.patch(`${UrlBuilder.emailConfiguration}`, data);
  },

    /// [System Configuration -> Talent Source]
    getTalentSource() {
      return interceptor.get(`${UrlBuilder.talentSource}`);
    },
  
    createTalentSource(data) {
      return interceptor.post(`${UrlBuilder.talentSource}`, data);
    },
  
    updateTalentSource(data) {
      return interceptor.patch(`${UrlBuilder.talentSource}`, data);
    },
    
    deleteTalentSource(source_id) {
      return interceptor.delete(`${UrlBuilder.talentSource}?source_id=${source_id}`);
    },
  
};


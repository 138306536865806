import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import SearchBar from '../../../../../components/search_bar';
import SortableTable, { TableData } from '../../../../../components/sortable_table/sortable_table';
import TableEmpty, { TableErrorHandler, TableFilterEmpty, TableLoading } from '../../../../../components/table_empty/table_empty';
import { LoadingType, ROLE, THEME } from '../../../../../enums';
import { useAppDispatch, useAppSelector, selectDashboardEngagementListState, selectDashboardEngagementCandidates, postSnackbarMessage, getDashboardEngagementCandidateDetails, selectProfileState, selectTextEmAllState, getTEADraftBroadCast, getRoleUsersList, selectRecruiterRoleUsersList } from '../../../../../redux/store';
import EmailIcon from "../../../../../../assets/icons/email.svg";
import CallIcon from "../../../../../../assets/icons/call.svg";
import './universal_directory.scss';
import { getDaysAgoWithDateAMPM } from '../../../../../variables'
import { getCompanyStatusText, getContactTypeIcon, getTableAccessIcon, getTextEmAllIcon } from '../../../../../utils/helpers';
import { CallBlueIcon, EmailBlueIcon, LockOpenIcon, TalentActiveStatusIcon } from '../../../../../icons';
import { AppRoutes } from '../../../../../routes';
import { ITalent, ITalentEngagement } from '../../../../../interfaces';
import { talentRequestSSNActions } from '../../../../../redux/talent/details/dashboard/request_ssn/request_ssn_reducer';
import SSNHolder from '../../../../../components/ssn_holder/ssn_holder';
import { Portal } from 'react-portal';
import ViewSSNPopup from '../../../../talent/popups/view_ssn/view_ssn';
import { textEmAllSliceActions } from '../../../../../redux/textEmAll/text_Em_All_reducer';
import { TEADraftBroadCast } from '../../../../../interfaces/text_em_all';
import { CustomButton, CustomCheckBox } from '../../../../../utils';
import BroadcastToTalentErrorPopup from '../../../../../components/broadcast_talent/broadcast_talent_error';
import BroadcastToTalentPopup from '../../../../../components/broadcast_talent/broadcast_talent';
import { getTalentStatus, selectTalentStatusList } from '../../../../../redux/admin_center';
import CustomFormSelect, { CustomMultiFormSelect } from '../../../../../components/form_selector/form_select';
interface Props extends RouteComponentProps<any> { }

const TalentEngagementUniversalDirectoryPage: React.FunctionComponent<Props> = (props) => {

    const dispatch = useAppDispatch();
    const dashboardEngagementsState = useAppSelector((state) => selectDashboardEngagementListState(state));
    const candidatesList = useAppSelector((state) => selectDashboardEngagementCandidates(state));
    const profileState = useAppSelector((state) => selectProfileState(state));
    const canSendText =
        profileState?.profile?.texting &&
        profileState?.profile?.text_em_all_token !== null;

    const textEmAllState = useAppSelector((state) => selectTextEmAllState(state));
    const [broadcastTalent, setBroadcastTalent] = useState<boolean>(false);
    const [broadcastTalentError, setBroadcastTalentError] =
        useState<boolean>(false);
    useEffect(() => {
        dispatch(textEmAllSliceActions.clearState());
        return () => { };
    }, []);
    useEffect(() => {
        if (
            textEmAllState &&
            textEmAllState?.error !== null &&
            textEmAllState?.loading === LoadingType.failed
        ) {
            setBroadcastTalentError(true);
        }
        if (
            textEmAllState &&
            textEmAllState?.error === null &&
            textEmAllState?.loading === LoadingType.succeeded
        ) {
            setBroadcastTalent(true);
        }
        if (
            textEmAllState &&
            textEmAllState?.error === null &&
            textEmAllState?.loading === LoadingType.idle
        ) {
            setBroadcastTalent(false);
            setBroadcastTalentError(false);

        }
    }, [textEmAllState, textEmAllState.error, textEmAllState.loading]);

    const [selectedItems, setSelectedItems] = useState<
        Map<string, { id: string; name: string }>
    >(new Map());

    useEffect(() => {
        dispatch(getRoleUsersList());
        dispatch(getTalentStatus());
        return () => { };
    }, []);

    const talentStatusList = useAppSelector((state) => selectTalentStatusList(state));
    const recruitersList = useAppSelector((state) =>
        selectRecruiterRoleUsersList(state)
    );
    const [recruiters, setRecruiter] = useState<string[]>([]);
    const [status, setStatus] = useState<string | null>(null);
    // useEffect(()=>{
    //     if (
    //           recruitersList &&
    //           recruitersList.length > 0 
    //         ) {
    //         //   const isUserRecruiter = profileState?.profile?.roles?.some((role) => role.role === ROLE.recruiter);
    //           const isUserInRecruitersList = recruitersList.some((recruiter) => recruiter.id === profileState?.profile?.id);
          
    //           if (isUserInRecruitersList) {
    //             const selectedRecruiters = [profileState?.profile?.id ?? ""]; // Automatically add only once
    //             setRecruiter(selectedRecruiters);
    //           }
    //         }
    // },[recruitersList])

    const handleMarkItem = (value: ITalentEngagement) => {
        const newSet = new Map(selectedItems);
        if (selectedItems.has(value.talent_id)) {
            newSet.delete(value.talent_id);
        } else {
            newSet.set(value.talent_id, {
                id: value.talent_id,
                name: value.talent_name,
            });
        }
        setSelectedItems(newSet);
    };
    const handleMarkAllItem = () => {
        const newSet = new Map<string, { id: string; name: string }>();
        if (selectedItems.size === getFilteredList().length) {
            setSelectedItems(newSet);
        } else {
            getFilteredList().forEach((doc) =>
                newSet.set(doc.talent_id, {
                    id: doc.talent_id,
                    name: doc.talent_name,
                })
            );
            setSelectedItems(newSet);
        }
    };
    const [formState] = useState<TEADraftBroadCast>({
        modified_by: "",
        created_by: "",
        modified_date: 0,
        talent_ids: [],
    });
    const [showMsg, setShowMsg] = useState<boolean>(false);
    const [showErrorMsg, setShowErrorMsg] = useState<string>("");

    const handleBroadCastTalent = (forceSend?: boolean) => {
        if (selectedItems.size !== 0) {
            const talentids_list = Array.from(selectedItems.keys());
            dispatch(
                getTEADraftBroadCast({ forceSend: forceSend, data: { ...formState, talent_ids: talentids_list } })
            );
            // setBroadcastTalent(true);
            setShowMsg(false);
        } else {
            setShowMsg(true);
            setShowErrorMsg(t("please_select_talent_to_broadcast"));
            setTimeout(() => setShowErrorMsg(""), 5000);
        }
    };

    const [showSSNPopup, setShowSSNPopup] = useState<{ talentId: string, visible: boolean } | null>(null);
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const { history, match, location } = props;
    const { params } = match;
    const { t } = useTranslation();

    useEffect(() => {
        if (params && params.type !== "")
            getcandidatesList();
        return () => {
        }
    }, [params.type])

    useEffect(() => {
        if (dashboardEngagementsState.error != null && candidatesList.length != 0) {
            dispatch(postSnackbarMessage(dashboardEngagementsState.error.message));
        }
        return () => {
        }
    }, [dashboardEngagementsState.error])

    function getcandidatesList() {
        dispatch(getDashboardEngagementCandidateDetails(params.type));
    }

    function getFilteredList(): ITalentEngagement[] {
        const list = candidatesList;
        let sortedList: ITalentEngagement[] | undefined;
        let resultList: ITalentEngagement[] | undefined;

        if (recruiters.length !== 0) {
            resultList = (resultList ?? candidatesList).filter((doc) => {
                const filter =
                    doc.recruiters && doc.recruiters.length !== 0
                        ? doc.recruiters.some((s) => recruiters.indexOf(s) >= 0)
                        : false;
                return filter;
            });
        }

        if (status != null && status != "") {
            resultList = (resultList ?? candidatesList).filter((doc) => {
                const statusFilter =
                    doc.status_id && status
                        ? doc.status_id === status
                        : false;
                return statusFilter;
            });
        }

        if (sortedField != null) {
            sortedList = [...list].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] != undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }


        return (sortedList ?? resultList ?? list).filter(doc => {
            const str = search.trim().toLowerCase();
            const talentnameFilter = doc.talent_name ? doc.talent_name.trim().toLowerCase().includes(str) : false;
            const jobtitleFilter = doc.job_title ? doc.job_title.trim().toLowerCase().includes(str) : false;
            const statusFilter = doc.status ? doc.status.trim().toLowerCase().includes(str) : false;
            const cityFilter = doc.city ? doc.city.trim().toLowerCase().includes(str) : false;
            const stateFilter = doc.state ? doc.state.trim().toLowerCase().includes(str) : false;
            const zipcodeFilter = doc.zip_code ? doc.zip_code.trim().toLowerCase().includes(str) : false;
            const addressFilter = doc.address ? doc.address.trim().toLowerCase().includes(str) : false;
            const onAssignmentFilter = doc.on_assignment ? doc.on_assignment.trim().toLowerCase().includes(str) : false;
            const ssnFilter = doc.last_4_digits_of_ssn ? doc.last_4_digits_of_ssn.trim().toLowerCase().includes(str) : false;
            return talentnameFilter || jobtitleFilter || cityFilter || stateFilter || zipcodeFilter || statusFilter || addressFilter || onAssignmentFilter || ssnFilter;
        });
    }


    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            // setSortedField(null);
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }

    function getRatings(value: any) {
        return (
            <div className={params.type === 'LOW' ? 'cmp-eng-rating-low' : (params.type === 'MEDIUM' ? 'cmp-eng-rating-medium' : 'cmp-eng-rating-high')}>
                <span className='cmp-eng-rating-text'>
                    {value}
                </span>
            </div>
        )
    }
    return (
        <div className="cps-ud-container">
            <div className="cps-ud-table-header">
                <div className="tlnt-ud-table-header-options">
                    <div className="recruiter-selector">
                        <CustomMultiFormSelect
                            label={t("recruiter")}
                            name={"recruiter"}
                            list={recruitersList.map((doc) => ({
                                label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                                value: doc.id,
                            }))}
                            onChange={setRecruiter}
                            required={false}
                            placeholder={t("select")}
                            value={recruiters}
                            customStyle={{ width: "-webkit-fill-available" }}
                        />
                    </div>
                    <div className="company-status-selector">
                        <CustomFormSelect
                            customStyle={{ width: "-webkit-fill-available" }}
                            name={"talent_status"}
                            value={status}
                            required={false}
                            label={t("status")}
                            placeholder={t("all")}
                            list={talentStatusList.map((doc) => ({
                                label: doc.talent_status,
                                value: doc.id,
                            }))}
                            onChange={setStatus}
                        />
                    </div>
                    <div className="cps-ud-table-search-bar">
                        <SearchBar value={search} onChange={(value) => setSearch(value)} onSearch={() => { }} />
                    </div>
                    <div className="total-count">
                        <span>{`${t('total_count')}: `} <span className="total-count-number">{getFilteredList().length}</span> </span>
                    </div>
                </div>
                <div className="tlnt-ud-brdcast-btn">
                    <CustomButton
                        leftIcon={getTextEmAllIcon()}
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("broadcast_to_talent")}
                        enable={canSendText ? true : false}
                        onClick={handleBroadCastTalent}
                    />
                    {showMsg && (
                        <span className="select_talent_msg">{showErrorMsg}</span>
                    )}
                </div>
            </div>

            <div className="cps-ud-table">
                {getCandidatesTableList()}
            </div>
            {showSSNPopup && (
                <Portal>
                    <ViewSSNPopup
                        visible={showSSNPopup.visible}
                        title={t('social_security_number')}
                        talentId={showSSNPopup.talentId}
                        onClose={() => setShowSSNPopup(null)}
                        onDissmiss={() => setShowSSNPopup(null)}
                        onSuccessClose={handleOnSuccessSSNClose}
                    />
                </Portal>
            )}
            {canSendText &&
                broadcastTalentError &&
                textEmAllState &&
                textEmAllState?.error !== null && (
                    <Portal>
                        <BroadcastToTalentErrorPopup
                            error={textEmAllState?.error}
                            onClose={() => {
                                setBroadcastTalentError(false);
                                dispatch(textEmAllSliceActions.clearState());
                            }}
                            visible={broadcastTalentError}
                            title={t("something_went_wrong")}
                            loading={textEmAllState?.loading === LoadingType.pending}
                            onForceSend={handleBroadCastTalent}
                        />
                    </Portal>
                )}
            {canSendText &&
                broadcastTalent && (
                    <Portal>
                        <BroadcastToTalentPopup
                            visible={broadcastTalent}
                            title={""}
                            successTitle={""}
                            responseURL={textEmAllState?.response}
                            loading={textEmAllState?.loading === LoadingType.pending}
                            onClose={() => {
                                setBroadcastTalent(false);
                                dispatch(textEmAllSliceActions.clearState());
                            }}
                            onSuccessClose={() => { }}
                        />
                    </Portal>
                )}
        </div>
    );

    function getDateTime(timestamp) {
        let date = new Date(timestamp * 1000);
        let dateString = date.toLocaleDateString();
        let timeString = date.toLocaleTimeString();
        let dateList = dateString.split('/');
        return `${dateList[1]}/${dateList[0]}/${dateList[2]}${" " + timeString}`
    }
    function getSSNInfo(value: string, id: string) {
        return (
            // <div className="ssn-holder">
            //     <div className="ssn-number">
            //         <span>{value}</span>
            //     </div>
            //     <button className="loc-open-icon">
            //         <LockOpenIcon width={"100%"} height={"100%"} style={{ color: '#00A4DC' }} />
            //     </button>
            // </div>
            <SSNHolder
                value={value}
                iconColor={THEME.tableRowTextColor}
                onClick={() => handleOnSSNView(id)}
                showFormat={false}
            />
        )
    }
    function handleOnSSNView(id: string) {
        dispatch(talentRequestSSNActions.initialiseRequestSSN(id));
        if (profileState.profile && profileState.profile.show_ssn) {
            setShowSSNPopup({ visible: true, talentId: id });
        } else {
            dispatch(postSnackbarMessage(t('ssn_access_is_disabled')));
        }

    }
    function handleOnSuccessSSNClose() {
        setShowSSNPopup(null);
        // dispatch(talentAvailableDatesActions.clearSingleTalentAvailableDatesManageState(params.id));
    }

    // function toTitles(s) {
    //     return s.replace(/\w\S*/g, function (t) { return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase(); });
    // }
    function talentStatus(status: string) {
        if (status === "ACTIVE") return (<span style={{ color: THEME.buttonColor17 }}>{t('active')}</span>);
        else if (status === "INACTIVE") return (<span style={{ color: "#b1b1b1" }}>{t('in_active')}</span>);
        else return (<span>{''}</span>);
    }
    function getPrefferedContact(value: string) {
        return (
            <div className="pref-contact-icon">
                {getContactTypeIcon(value, "#00A4DC")}
            </div>
        );

    }

    function getTalentStatusIcon(doc: ITalentEngagement) {
        return (
            <div className="status-icon">
                <TalentActiveStatusIcon
                    width={"100%"}
                    height={"100%"}
                    style={{
                        color:
                            doc.on_assignment != null
                                ? THEME.buttonColor13
                                : THEME.statusDisabledColor,
                    }}
                />
            </div>
        );
    }
    /// table list
    function getCandidatesTableList() {
        if (dashboardEngagementsState.loading === LoadingType.pending) {
            return (<TableLoading />);
        }
        if (dashboardEngagementsState.error != null && candidatesList && candidatesList.length === 0) {
            return (
                <TableErrorHandler error={dashboardEngagementsState.error} onRefresh={getcandidatesList} />
            );
        }
        if (candidatesList.length === 0) {
            return (
                <TableEmpty title={t('no_data_found')} onClick={() => getcandidatesList()} />
            );
        }
        if (getFilteredList().length === 0) {
            return (
                <TableFilterEmpty title={t('no_data_found')} />
            );
        }
        const SelectAllCheckBox = () => {
            return (
                <CustomCheckBox
                    name={""}
                    title={""}
                    checked={selectedItems.size === getFilteredList().length}
                    onClick={handleMarkAllItem}
                />
            );
        };
        const tableHeader = [
            // { title: 'access_talent', code: 'access_talent' },
            { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
            { title: 'engagement_rating', code: 'engagement_rating' },
            { title: 'name', code: 'talent_name' },
            { title: 'job_title', code: 'job_title' },
            { title: 'on_assignment', code: 'on_assignment' },
            { title: 'status', code: 'status' },
            { title: 'address', code: 'address' },
            { title: 'city', code: 'city' },
            { title: 'state', code: 'state' },
            { title: 'zip_code', code: 'zip_code' },
            { title: 'ssn_hash', code: 'last_4_digits_of_ssn' },
            { title: 'last_contact', code: 'last_contact' },
            { title: 'contact', code: 'preferred_contact' },
        ];
        return (
            <SortableTable
                headerList={tableHeader}
                sortedField={sortedField}
                onSortChange={handleSortFieldChange}
                flexNumber={getFlexNumber}
                isAsc={sortDirection}
            >
                {getFilteredList().map((doc, index) => {
                    return (
                        <tr key={doc.talent_id}>
                            {/* <TableData customStyle={{ flex: getFlexNumber(0) }}>{getTableAccessIcon(() => handleTalentSelect(doc))}</TableData> */}
                            <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                <CustomCheckBox
                                    name={""}
                                    title={""}
                                    checked={selectedItems.has(doc.talent_id)}
                                    onClick={() => handleMarkItem(doc)}
                                />
                            </TableData>
                            <TableData customStyle={{ flex: getFlexNumber(1) }}>{getRatings(doc.engagement_rating)}</TableData>
                            <TableData prefix={getTalentStatusIcon(doc)} customStyle={{ flex: getFlexNumber(2) }} isButton={true} onClick={() => handleTalentSelect(doc)}><span>{doc.talent_name}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{doc.job_title}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{doc.on_assignment ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(5) }}><span>{doc.status ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(6) }}><span>{doc.address ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(7) }}><span>{doc.city ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(8) }}><span>{doc.state ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(9) }}><span>{doc.zip_code ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(10) }}><span>{doc.last_4_digits_of_ssn ? getSSNInfo(doc.last_4_digits_of_ssn, doc.talent_id) : ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(11) }}><span>{doc.last_contact ? getDaysAgoWithDateAMPM(t, doc.last_contact) : ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(12) }}>{getPrefferedContact(doc.preferred_contact)}</TableData>
                            {/* <td>
                                {doc.preferred_contact ?
                                    (doc.preferred_contact === 'EMAIL' ?
                                    <EmailBlueIcon width={"100%"} height={"100%"} />
                                        :<CallBlueIcon width={"100%"} height={"100%"} />)
                                    : ''}
                            </td> */}
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 0.3;
        if (value === 1) return 1;
        if (value === 2) return 1.5;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1.4;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 1;
        if (value === 11) return 2;
        if (value === 12) return 1;
        // if (value === 12) return 1;
        return 1;
    }

    function handleTalentSelect(value: ITalentEngagement) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
            state: {
                id: value.talent_id,
                name: value.talent_name,
            }
        })
        return;
    }
}

// interface TableHeaderOptionsProps {
//     totalCount: number,
//     search: string,
//     onSearchChange: (value: string) => void,
// }

// const TableHeaderOptions = (props: TableHeaderOptionsProps) => {
//     const { t } = useTranslation();
//     return (

//     );
// }


export default TalentEngagementUniversalDirectoryPage;
import { useEffect, useRef, useState } from "react";
import { ICompanyCertification, ICreateTalentCertifications, ITalentCertifications } from "../../../../interfaces";
import { getCertificationsList, selectCertificationsList, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { useTranslation } from "react-i18next";
import { ReadDocIcon } from "../../../../icons";
import { CustomButton } from "../../../../utils";
import { LoadingType, THEME } from "../../../../enums";
import "./add_certification.scss";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { DateInput } from "../../../../components/date_input/date_input";
import DragAndDrop from "../../../../components/drag_and_drop/drag_and_drop";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { getOtherDocs, selectOtherDocsList } from "../../../../redux/admin_center";
import { OtherDocs } from "../../../../interfaces/other_docs";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";


interface Props {
    visible: boolean;
    title: string;
    successTitle: string;
    certification?: ITalentCertifications;
    onClose: () => void;
    onDissmiss?: () => void;
    onSuccessClose: () => void;
    onSubmit: (value: ICreateTalentCertifications) => void;
    talent_id: string;
    skill_ids: any;
    onFileChange: (value: File) => void;
    loading: LoadingType;
    currentLoginUserName: string;
    handleDelete: (doc: any) => void;
}

const AddCertificationPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onSuccessClose,
        certification,
        title,
        talent_id,
        onSubmit,
        skill_ids,
        loading,
        onFileChange,
        handleDelete
    } = props;
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(getOtherDocs());
        dispatch(getCertificationsList());
        return () => { }
    }, []);
    
    const certificationsList = useAppSelector((state) => selectCertificationsList(state));

    const otherDocsList = useAppSelector((state) => selectOtherDocsList(state));
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [certifyDocsList, setCertifyDocsList] = useState<ICompanyCertification[] | OtherDocs[] | null>(certificationsList);

    const getCerticationsData = (value: ITalentCertifications): ICreateTalentCertifications => {
        return {
            talent_id: value.talent_id ?? "",
            file_name: value.file_name ?? "",
            skill_id: value.skill_id ?? null,
            skill_name: value.skill_name ?? "",
            skill_type: value.skill_type ?? "",
            date_issued: value.date_issued ?? 0,
            expiry_date: value.expiry_date ?? 0,
            file_path: value.file_path ?? ""
        }
    }

    const initialForm: ICreateTalentCertifications = {
        talent_id: talent_id,
        file_name: "",
        skill_id: null,
        skill_name: "",
        skill_type: "certifications",
        date_issued: 0,
        expiry_date: 0,
        file_path: "",
    }
    const [formState, setFormState] = useState<ICreateTalentCertifications>(
        certification ? getCerticationsData(certification) : initialForm
    );
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>();
    useEffect(() => {
        if (formState.skill_type === "certifications") {
            setCertifyDocsList(certificationsList);
        }
        if (formState.skill_type === "other_docs") {
            setCertifyDocsList(otherDocsList);
        }
    }, [certificationsList, formState.skill_type, otherDocsList]);


    function handleFieldChange(fieldId: string, value: any | React.ChangeEvent<HTMLInputElement>) {
        if (fieldId === "skill_type") {
            setFormState({ ...formState, skill_type: value, skill_id: null });

        }
        else {
            setFormState({ ...formState, [fieldId]: value });

        }
    }
    function handleSubmit() {
        setCheckValidationOnSubmit(true)
        if (_isFormValid()) {
            if (selectedFile) {
                formState.file_name = (selectedFile as File).name;
            }
            onSubmit(formState)
            setCheckValidationOnSubmit(false)
        }
    }
    useEffect(() => {
        if (selectedFile) {
            onFileChange(selectedFile);
        }
    }, [selectedFile]);


    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"40%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="certi-f-container">
                    <div className="certi-f-header">
                        <FormDialogTilteHeader
                            title={title}
                            titleIcon={<ReadDocIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="certi-f-content">

                        {loading === LoadingType.succeeded ? (
                            <div className="dshb-soa-f-mark-success-holder">
                                <DocSavedText>
                                    <span className="aknw-name">
                                        {t("well_done", { name: props.currentLoginUserName })}
                                    </span>
                                    <span className="info-txt">{`${t(
                                        "document_uploaded_saved"
                                    )}`}</span>

                                </DocSavedText>
                            </div>
                        )
                            :
                            (
                                <div>
                                    <div>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend" className="radio-label">Skill Type *</FormLabel>
                                            <RadioGroup aria-required row aria-label="skill_type" name="skill_type1" value={formState.skill_type}

                                                onChange={(val) => { handleFieldChange("skill_type", (val.target as HTMLInputElement).value); }}>
                                                <FormControlLabel value="certifications" control={<Radio />} label={t("certifications")} disabled={certification !== undefined ? true : false} />
                                                <FormControlLabel value="other_docs" control={<Radio />} label={t("other_documents")} disabled={certification !== undefined ? true : false} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <CustomFormSelect
                                            name={'skill_id'}
                                            list={certifyDocsList!.map((doc, index) => ({ label: formState.skill_type === "certifications" ? doc.certification : doc.other_docs, value: doc.id, isDisabled: skill_ids.includes(doc.id) }))}
                                            onChange={(value) => handleFieldChange('skill_id', value)}
                                            required={true}
                                            placeholder={t('select_option')}
                                            value={formState.skill_id}
                                            customStyle={{ width: '-webkit-fill-available' }}
                                            label={formState.skill_type === "certifications" ? t("certification_name") : t("document_type")}
                                            disabled={certification !== undefined ? true : false}
                                            checkValidationOnSubmit={checkValidationOnSubmit}
                                            validations={certifnFormValidators}
                                        />
                                        {/* {getError() != null && <div className={"error-section"}>
                                <ApiError message={getError()?.message} onClose={handleClearError} />
                            </div>} */}
                                    </div>
                                    <div className="cf-content-form-input">
                                        <div className="form-item">
                                            <DateInput
                                                id={"date_issued"}
                                                onChange={handleFieldChange}
                                                placeholder={""}
                                                value={formState.date_issued}
                                                label={t("date_issued")}
                                                disableFuture={true}
                                            />
                                        </div>
                                        <div className="form-item">
                                            <DateInput
                                                id={"expiry_date"}
                                                onChange={handleFieldChange}
                                                placeholder={""}
                                                value={formState.expiry_date}
                                                label={t("expiration_date")}
                                                disablePast={true}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            key={"certification"}
                                            className={"oa-cnt-inf-f-input-holder"}
                                            style={{ width: '-webkit-fill-available' }}
                                        >
                                            <DragAndDrop
                                                label={t('attach_doc')}
                                                selectedFile={selectedFile}
                                                accept={".pdf,image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                                                onChange={setSelectedFile}
                                                onRemove={() => setSelectedFile(null)}
                                                formValidators={validateFileType}
                                                placeholder={t('upload_here')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                    <div className="certi-f-actions">
                        {props.loading === LoadingType.succeeded ?
                            (
                                <div className="dshb-soa-f-actions">
                                    <div className="btn-save">
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("ok")}
                                            enable={true}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={onSuccessClose}
                                        />
                                    </div>
                                </div>
                            )
                            :
                            (
                                <>
                                    {/* <div> */}
                                    {certification && certification.file_path !== null && (
                                        <div style={{ marginRight: "auto" }}>
                                            <CustomButton
                                                loading={false}
                                                textStyle={{ textTransform: "capitalize" }}
                                                name={t("delete_field", { name: t("certification") })}
                                                enable={true}
                                                backgroundColor={THEME.statusInActiveColor}
                                                onClick={() => handleDelete(certification.id)}
                                            />
                                        </div>
                                    )
                                    }
                                    {/* </div> */}

                                    <div className="btn-cancel">
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("cancel")}
                                            enable={true}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={onClose}
                                        />
                                    </div>
                                    <div className="btn-save">
                                        <CustomButton
                                            loading={loading === LoadingType.pending}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={certification ? t("update") : t("save")}
                                            // enable={_isFormValid()}
                                            enable={true}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={handleSubmit}
                                        />
                                    </div>
                                </>
                            )
                        }


                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder >
    )

    function _isFormValid() {
        const {
            skill_type,
            skill_id,
            file_name
        } = formState;
        if (file_name !== "") {
            return true
        }
        if (
            skill_type === "" ||
            skill_id === "" || selectedFile === null
        ) {
            return false;
        }
        return true;
    }
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
    function validateFileType() {
        if (selectedFile === null && checkValidationOnSubmit) {
            return t("validators.required");
        }
        if (selectedFile && selectedFile.type.startsWith('image/')) {
            return t('image_file_is_not_accepted');
        }
        return null;
    }

    function certifnFormValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "skill_id": {
                if ((value.text === "" || value.text === null || value.text === undefined)) {
                    return t("validators.required");
                }
                return null
            }

            default: {
                return null;
            }
        }
    }

}

export default AddCertificationPopup;
import SectionCard from "../../../../../components/section_card/section_card";
import './certifications_and_others.scss';
import { CustomButton, getPlusBtnIcon } from "../../../../../utils";
import { LoadingType, THEME } from "../../../../../enums";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { addNewTalentCertificationsThunk, deleteTalentCertificationsThunk, getTalentCertificationsThunk, postSnackbarMessage, selectProfileState, selectSingleTalentCertificationStateById, updateTalentCertificationsThunk, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { ICreateTalentCertifications, ITalentCertifications } from "../../../../../interfaces";
import { PDFIcon, ExclaimOrangeIcon, TickGreenIcon, DownloadIcon } from "../../../../../icons";
import { Portal } from "react-portal";
import AddCertificationPopup from "../../../popups/add_certification/add_certification";
import { talentCertificationsActions } from "../../../../../redux/talent/details/onboarding_and_docs/certifications_other_docs/certifications_other_docs_reducer";
import { getDateString } from "../../../../../variables";
import { FILE_ICONS } from "../../../../../file_icons";
import { DeleteConfirmationDialog } from "../../../../components/delete_confirmation_dialog";

interface Props {
    talent_id: string;
}

const CertificationsAndOthers: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { talent_id } = props;
    const talentCertificationsState = useAppSelector((state) => selectSingleTalentCertificationStateById(talent_id)(state));
    const addCertificationsState = talentCertificationsState.add;
    const updateCertificationsState = talentCertificationsState.update;
    const removeCertificationsState = talentCertificationsState.delete;
    const [talentCertData, setTalentCertData] = useState<ITalentCertifications[] | null>()
    const [addCertification, setAddCertification] = useState<boolean>(false);
    const [fileChange, setFileChange] = useState<File | null>(null);
    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""
        }`;
    useEffect(() => {
        dispatch(getTalentCertificationsThunk({ talent_id: talent_id }));
        return () => { };
    }, []);

    useEffect(() => {
        if ((removeCertificationsState && removeCertificationsState.loading === LoadingType.succeeded && removeCertificationsState.response)) {
            dispatch(postSnackbarMessage(removeCertificationsState.response));
            dispatch(getTalentCertificationsThunk({ talent_id: talent_id }));
            dispatch(talentCertificationsActions.clearTalentRemoveCertificationError(talent_id));
            setAddCertification(false);
            setOptions([])

        }
        return () => { }
    }, [dispatch, removeCertificationsState, removeCertificationsState?.loading, removeCertificationsState?.response, talent_id])


    const [options, setOptions] = useState<{
        title: string;
        isFileUploaded: boolean;
        type: string;
        visible: boolean;
        enable: boolean;
        id: string;
        uploaded: number | undefined;
        filePath: string | null;
        file_name: string;
    }[]>([]);
    useEffect(() => {
        if (talentCertificationsState.loading === LoadingType.succeeded && talentCertificationsState.data) {
            setTalentCertData(talentCertificationsState.data);
        }
    }, [talentCertificationsState.loading, talentCertificationsState.data]);

    useEffect(() => {
        if (talentCertData !== undefined && talentCertData !== null) {
            talentCertData.map((doc) => {
                const updatedOptions = talentCertData.map((doc) => ({
                    title: doc.skill_name,
                    isFileUploaded: doc.file_path !== null ? true : false,
                    type: doc.skill_type,
                    visible: true,
                    enable: doc.file_path === null ? true : false,
                    id: doc.id,
                    uploaded: doc.uploaded_date,
                    filePath: doc.download_url,
                    file_name: doc.file_name,
                }));
                setOptions(updatedOptions);
            });
        }
    }, [talentCertData]);

    const [addStateLoading, setAddStateLoading] = useState(LoadingType.idle)
    useEffect(() => {
        if (talentCertificationsState.add) {
            setAddStateLoading(talentCertificationsState.add.loading);
        }
        return () => { }
    }, [dispatch, fileChange, talentCertificationsState.add, talent_id]);
    useEffect(() => {
        if (talentCertificationsState.update) {
            setAddStateLoading(talentCertificationsState.update.loading);
        }
        return () => { }
    }, [dispatch, fileChange, talentCertificationsState.update, talent_id]);

    const [showDeleteAction, setShowDeleteAction] = useState<{
        doc: any;
        visible: boolean;
    } | null>(null);
    const [certDataById, setCertDataById] = useState<ITalentCertifications | undefined>();

    function handleAddCertificationSuccess() {
        // dispatch(talentCertificationsActions.clearTalentCertificationError(talent_id));

        if (talentCertificationsState.add) {
            dispatch(talentCertificationsActions.clearTalentAddCertificationError(talent_id));
        }
        if (talentCertificationsState.update) {
            dispatch(talentCertificationsActions.clearTalentUpdateCertificationError(talent_id));
        }
        dispatch(talentCertificationsActions.clearTalentUpdateCertificationError(talent_id));
        setAddStateLoading(LoadingType.idle);
        setAddCertification(false);
        setCertDataById(undefined);
    }

    /// handle submit
    function handleSubmit(value: ICreateTalentCertifications) {
        if (certDataById && certDataById.file_path !== null) {
            dispatch(updateTalentCertificationsThunk({ talent_id: talent_id, data: { ...certDataById, ...value }, file: fileChange }));
        }
        else {
            dispatch(addNewTalentCertificationsThunk({ talent_id: talent_id, data: value, file: fileChange }));
        }
    }
    const updateMissingFileData = (id: string) => {
        const updatedData = talentCertData?.find((cert) => cert.id === id);
        setCertDataById(updatedData)
        setAddCertification(true);
    }
    function handleDownload(filePath) {
        let aTag = document.createElement('a')
        aTag.setAttribute('href', filePath);
        aTag.setAttribute('target', '_blank');
        aTag.click();
    }

    function handleDeleteRecord(doc: any) {
        setShowDeleteAction({ doc: doc, visible: true });
    }
    /// close delete  dialog
    function handleCloseDeleteDialog() {
        setShowDeleteAction(null);
    }
    ///Delete record
    function handleOnSuccessDeleteRecordDialog(id: string) {
        dispatch(deleteTalentCertificationsThunk({ talent_id: talent_id, skill_doc_id: id }))
        handleCloseDeleteDialog();
    }
    function handleClearError() {
        dispatch(talentCertificationsActions.clearTalentRemoveCertificationError(talent_id));

    }
    return (
        <SectionCard
            backgroundColor="#EBF1F8"
            activeColor="#0B3E78"
            title={t('certifications_and_other_docs')}
            buttonTitle={t("add_certification_other_doc")}
            onButtonTitleClick={() => setAddCertification(true)}
            isBtnVisible= {true}
        >
            <div className="certi-docs-container">
                <div className="certi-pill-container">
                    {options.map((option, index) => (
                        <div key={index} className="pill-card">
                            <DocPillCard
                                isFileUploaded={option.isFileUploaded}
                                name={t(option.title)}
                                uploaded={option.uploaded}
                                onClick={() => updateMissingFileData(option.id)}
                                filePath={option.filePath}
                                file_name={option.file_name}
                            />
                        </div>
                    ))}

                </div>
                {addCertification && (
                    <Portal>
                        <AddCertificationPopup
                            visible={addCertification}
                            title={certDataById ? t("update_certification") : t("add_new_certification")}
                            successTitle={t("new_talent")}
                            onClose={() => { setAddCertification(false); setCertDataById(undefined); setAddStateLoading(LoadingType.idle); }}
                            onSuccessClose={handleAddCertificationSuccess}
                            onSubmit={handleSubmit}
                            talent_id={talent_id}
                            skill_ids={talentCertData?.map(item => item.skill_id)}
                            onFileChange={setFileChange}
                            loading={addStateLoading}
                            certification={certDataById}
                            currentLoginUserName={currentLoginUserName}
                            handleDelete={handleDeleteRecord}
                        />
                    </Portal>
                )}
                 {showDeleteAction && showDeleteAction.visible && (
                    <Portal>
                        <DeleteConfirmationDialog
                            message={t("are_you_sure_you_want_to_delete_record", { name: certDataById?.skill_name })}
                            onCancel={() => setShowDeleteAction(null)}
                            onConfirm={() => handleOnSuccessDeleteRecordDialog(showDeleteAction?.doc)}
                            deleting={removeCertificationsState?.loading === LoadingType.pending}
                            deleteType={t("certification")}
                            state={removeCertificationsState}
                            onClearError={handleClearError}
                        />
                    </Portal>
                )}
            </div>
        </SectionCard>
    );

    function DocPillCard(props: DSCProps) {
        const { isFileUploaded, name, onClick, uploaded, filePath, file_name } = props;
        const [isHovering, setIsHovering] = useState<boolean>(false);
        const nameList = file_name !== null ? file_name.split('.') : [];
        const type = file_name !== null ? nameList[nameList?.length - 1] : 'unknown';
        return (
            <div onMouseOut={() => { setIsHovering(false) }}
                onMouseOver={() => { setIsHovering(true) }}
                className="doc-pill-card">
                <div>
                    <button className="doc-pill-card-container" onClick={onClick}>
                        <div className="stat-icon">
                            {isFileUploaded ? (
                                <TickGreenIcon width={"100%"} height={"100%"} />
                            ) : (
                                <ExclaimOrangeIcon width={"100%"} height={"100%"} />
                            )}
                        </div>
                        <div className="doc-name" >
                            <span style={{
                                color: isFileUploaded ? THEME.statusActiveColor : "#FF7600",
                            }}>{name}</span>
                        </div>
                        <div
                            className="stat-banner"
                            style={{
                                backgroundColor: isFileUploaded ? THEME.statusActiveColor : "#FF7600",
                            }}
                        >
                            <span>{isFileUploaded ? t("uploaded") : t("missing")}</span>
                        </div>
                    </button>
                </div>
                {isHovering && (
                    <div className="parent-position">
                        <div className="child-position">
                            <div className="doc-icon">
                                {/* <PDFIcon width={"100%"} height={"100%"} /> */}
                                <img src={FILE_ICONS[type.toLowerCase()]} width={"100%"} height={"100%"} style={{ objectFit: 'cover' }} alt="respective file type icon" />

                            </div>
                            <div>
                                <div className="action-list-title" >{file_name}</div>
                                <div className="uploaded">
                                    {isFileUploaded ? (<span>{t('uploaded')} {(getDateString(uploaded))}</span>) : (<span>{t("file_is_missing")}</span>)}
                                </div>
                                {filePath !== null && (
                                    <div className="doc-icon action-btn">
                                        <div>
                                            <CustomButton
                                                customClassName={"doc-down-btn"}
                                                enable={true}
                                                leftIcon={<div><DownloadIcon /></div>}
                                                name={''}
                                                onClick={() => (handleDownload(filePath))}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

}




interface DSCProps {
    isFileUploaded: boolean;
    name: string;
    onClick: () => void;
    uploaded: number | undefined;
    filePath: string | null;
    file_name: string;
}

export default CertificationsAndOthers;

import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder';
import ApiError from '../../../../components/api_error';
import DialogWrapper, { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import { LoadingType, THEME } from '../../../../enums';
import { CalenderIcon } from '../../../../icons';
import { AppError } from '../../../../interfaces';
import {
    useAppDispatch,
    useAppSelector,
    selectSingleTalentAvailableDatesStateById,
    postSnackbarMessage,
    addTalentAvailableDatesThunk,
    getTalentAvailableDatesThunk
} from '../../../../redux/store';
import { CustomButton, SpinnerScreen } from '../../../../utils';
import { t } from 'i18next';
import './add_availability.scss';
import { getDateString } from '../../../../variables';
import DocSavedText from '../../../../components/doc_saved_text/doc_saved_text';
import { talentAvailableDatesActions } from '../../../../redux/talent/details/dashboard/talent_availability/talent_availability_reducer';


interface Props {
    visible: boolean,
    title: string,
    talentId: string,
    name: string,
    currentLoginUserName: string,
    date: number,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}
const AvailabiltyPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onSuccessClose,
        talentId,
        date,
        title,
        name,
        currentLoginUserName,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const availabilityState = useAppSelector(state => selectSingleTalentAvailableDatesStateById(props.talentId)(state));
    const createState = availabilityState.add;
    const actionRef = useRef<ActionDialogHolderType>(null);

    useEffect(() => {
        getTalentAvailabilityDates();
        return () => { }
    }, [])

    // useEffect(() => {
    //     if (createState.loading == LoadingType.succeeded) {
    //         dispatch(postSnackbarMessage(createState.response ?? null));
    //         // closePopup(onSuccessClose);
    //     }
    //     return () => { }
    // }, [createState.loading])

    function getTalentAvailabilityDates() {
        dispatch(getTalentAvailableDatesThunk(props.talentId));
    }

    function getLoading() {
        return availabilityState.loading === LoadingType.pending;
    }

    function handleWrapperClose(action?: () => void) {
        if (createState.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(createState.response ?? null));
            closePopup(onSuccessClose);
        } else {
            if (action) action();
        }
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            //wrapperWidth={getLoading() ? "30%" : "fit-content"}
            wrapperWidth={"35%"}
            onClose={() => handleWrapperClose(onClose)}
            onDissmiss={() => handleWrapperClose(onDissmiss)}
            customStyle={{ maxWidth: '40%' }}
        >
            <DialogWrapper
                onClose={() => {
                    if (createState.loading == LoadingType.succeeded) {
                        dispatch(postSnackbarMessage(createState.response ?? null));
                        closePopup(onSuccessClose);
                    } else {
                        closePopup(onClose);
                        handleClearError();
                    }

                }}
            >
                {getLoading() ? <div className="loading-spinner" ><SpinnerScreen /></div> : <AvailabilityForm
                    title={title}
                    date={date}
                    name={name}
                    currentLoginUserName={currentLoginUserName}
                    loading={createState.loading}
                    error={null}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    onClearError={handleClearError}
                    onSuccessClose={() => closePopup(onSuccessClose)}
                    
                />}
            </DialogWrapper>
        </ActionDialogHolder>
    );

    ///Handle clear error
    function handleClearError() {
        dispatch(talentAvailableDatesActions.clearSingleTalentAvailableDatesStateError(props.talentId));
    }

    function handleSubmit(value: number) {
        const payload = { talent_id: talentId, available_dates: [...availabilityState.data, value] };
        dispatch(addTalentAvailableDatesThunk(payload));
    }

    function handleCancel() {
        handleClearError();
        closePopup(onClose);
    }

    


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }



}

interface AvailabilityFormProps {
    title: string,
    date: number,
    name: string,
    currentLoginUserName: string,
    loading: LoadingType,
    error?: AppError | null,
    onSubmit: (value: number) => void,
    onSuccessClose: () => void,
    onCancel: () => void,
    onClearError: () => void,
}

const AvailabilityForm: React.FunctionComponent<AvailabilityFormProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="tlnt-avblt-add-f-container">
            <div className="tlnt-avblt-add-f-header">
                <FormDialogTilteHeader
                    title={props.title}
                    titleIcon={<CalenderIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                />
            </div>
            <div className="tlnt-avblt-add-f-content">
                {getUI()}
                {props.error && <div className={"error-section"}>
                    <ApiError message={props.error.message} onClose={props.onClearError} />
                </div>}
            </div>
            {getActions()}
        </div>
    );

    function getActions() {
        if (props.loading === LoadingType.succeeded) {
            return (
                <div className="tlnt-avblt-add-f-actions">
                    <div className="btn-save">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('ok')}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => props.onSuccessClose()}
                        />
                    </div>
                </div>
            )
        }
        return (
            <div className="tlnt-avblt-add-f-actions">
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('cancel')}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={props.onCancel}
                    />
                </div>

                <div className="btn-save">
                    <CustomButton
                        loading={props.loading === LoadingType.pending}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('yes')}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => props.onSubmit(props.date)}
                    />
                </div>
            </div>
        )
    }


    function getUI() {
        const strDate = getDateString(props.date, "ddmmmyyyy");
        if (props.loading === LoadingType.succeeded) {
            return (
                <div className="question-holder" style={{minHeight:"11.458333333333334vw"}}>
                    <DocSavedText>
                        <span>{t('great', { name: props.currentLoginUserName })}</span>
                        <span>{t('has_been_marked_available_on', { name: props.name, date: strDate })}</span>
                    </DocSavedText>
                </div>
            )
        }

        return (
            <div className="question-holder">
                <div className="question-txt">
                    <span>{t('do_you_want_to_mark') + " " } <span style={{fontWeight:"bold"}}>{props.name}</span>{` ${t('available_on_date',{date : strDate})}`}</span>
                </div>
            </div>
        )
    }
}

export default AvailabiltyPopup;
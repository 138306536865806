import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../../interfaces";
import { MyProfile } from "../../../../../interfaces/my_profile";
import { catchRequestError } from "../../../../../utils";
import { JobOrderAPI } from "../../../../../apis";
import { IMultipleMove, ITalentPipelineQuery, TalentPipeline } from "../../../../../interfaces/talent_pipeline";

export const getTalentPipelineDataThunk = createAsyncThunk<
    TalentPipeline[],
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/talent-pipeline/get',
    async (jobOrderId, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getTalentPipelineData(jobOrderId);
            return res.data as TalentPipeline[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateTalentMatchRatingThunk = createAsyncThunk<
    string,
    ITalentPipelineQuery,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/talent-pipeline/update-match-rating/patch',
    async (doc, thunkAPI) => {
        try {
            const res = await JobOrderAPI.updateTalentMatchRating(doc.job_id,doc.talent_id,doc.match_rating ?? "");
            return res.data as TalentPipeline[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updatePipelineStatus = createAsyncThunk<
    string,
    ITalentPipelineQuery,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/talent-pipeline/update-pipeline-status/patch',
    async (doc, thunkAPI) => {
        try {
            const res = await JobOrderAPI.changeTalentPipelineStatus(doc.job_id,doc.talent_id,doc.status_id ?? "");
            return res.data as TalentPipeline[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const multipleTalentsQueueThunk = createAsyncThunk< 
    string,
    IMultipleMove[],
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/talent-pipeline/move-multiple-talents-queue/patch',
    async (doc, thunkAPI) => {
        try {
            const res = await JobOrderAPI.multipleTalentsQueue(doc);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const multipleTalentsTrashThunk = createAsyncThunk<
    string,
    IMultipleMove[],
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/talent-pipeline/move-multiple-talents-trash/patch',
    async (doc, thunkAPI) => {
        try {
            const res = await JobOrderAPI.multipleTalentsTrash(doc);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const moveTalentToQueueThunk = createAsyncThunk<
    string,
    ITalentPipelineQuery,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/talent-pipeline/move-talent-queue/patch',
    async (doc, thunkAPI) => {
        try {
            const res = await JobOrderAPI.moveTalentToQueue(doc.job_id,doc.talent_id);
            return res.data as TalentPipeline[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const moveTalentTrash = createAsyncThunk<
    string,
    ITalentPipelineQuery,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/talent-pipeline/move-talent-trash/patch',
    async (doc, thunkAPI) => {
        try {
            const res = await JobOrderAPI.trashPipelineTalent(doc.job_id,doc.talent_id);
            return res.data as TalentPipeline[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
import { THEME } from '../../enums';
import { SingleTalentODRSState } from '../../redux/talent/details/onboarding_and_docs/application_status/application_status_reducer';
import { CustomButton, getPlusBtnIcon, paperPlaneBtnIcon } from '../../utils';
import './section_card.scss';

interface Props {
    children: React.ReactNode,
    title: string,
    buttonTitle?: string,
    backgroundColor: string,
    activeColor: string,
    icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }>
    onButtonTitleClick?: () => void;
    state?: SingleTalentODRSState;
    buttonBackgroundColor?: string;
    employeePortalAccess?: boolean;
    isBtnVisible?: boolean;

};


const SectionCard = (props: Props) => {
    return (
        <div className="scrd-container">
            <div className="scrd-header" style={{ backgroundColor: props.backgroundColor }}>
                <div className='header-left'>
                    {props.icon && <div className="scrd-icon">
                        <props.icon width={"100%"} height={"100%"} style={{ color: props.activeColor }} />
                    </div>}
                    <div className="scrd-path-name">
                        <span className={'scrd-path-txt'} style={{ color: props.activeColor }}>{props.title}</span>
                    </div>
                </div>
                {props.buttonTitle && props.state?.error && (
                    <div className="send-link">
                        <CustomButton
                            leftIcon={paperPlaneBtnIcon()}
                            name={props.buttonTitle}
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={props.onButtonTitleClick || (() => { })}
                        />
                    </div>
                )}
                {props.buttonTitle && props.isBtnVisible &&
                    (
                        <div className="create-login">
                            <CustomButton
                                leftIcon={getPlusBtnIcon()}
                                name={props.buttonTitle}
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={props.onButtonTitleClick || (() => { })}
                            />
                        </div>
                    )
                }
            </div>
            <div className="scrd-content">
                {props.children}
            </div>
        </div>
    );
}

export default SectionCard;
import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, CompanyCategory } from "../../../../interfaces";
import * as thunk from "./company_categories_thunk";

export interface CompanyCategoryState extends EntityState<CompanyCategory> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const CompanyCategoryAdapter: EntityAdapter<CompanyCategory> = createEntityAdapter<CompanyCategory>({
    selectId: (doc) => doc.id
})

const initialState: CompanyCategoryState = CompanyCategoryAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const CompanyCategorySlice = createSlice({
    name: 'companyCategory',
    initialState: initialState,
    reducers: {
        clearCompanyCategoryListError(state){
            return {...state, error: null};
        },
        clearCompanyCategoryListState(state){
            return CompanyCategoryAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearCompanyCategoryCreateState(state) {
            return { ...state, create : {...initialState.create }};
        },
        clearCompanyCategoryUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearCompanyCategoryDeleteState(state) {
            return { ...state, remove : {...initialState.remove} };
        },
        clearCompanyCategoryCreateStateError(state) {
            return { ...state, create : { ...initialState.create , error: null }};
        },
        clearCompanyCategoryUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearCompanyCategoryDeleteStateError(state) {
            return { ...state, remove : { ...initialState.remove , error: null} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getCompanyCategories.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getCompanyCategories.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getCompanyCategories.fulfilled, (state, action) => CompanyCategoryAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createCompanyCategory.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createCompanyCategory.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createCompanyCategory.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.deleteCompanyCategory.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteCompanyCategory.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteCompanyCategory.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateCompanyCategory.pending, (state, action) => ({...state, update: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateCompanyCategory.rejected, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateCompanyCategory.fulfilled, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const companyCategoryActions = CompanyCategorySlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = CompanyCategoryAdapter.getSelectors();
export const selectState = (state: CompanyCategoryState) => state;
export const selectLoading = (state: CompanyCategoryState) => state.loading;
export const selectError = (state: CompanyCategoryState) => state.error;

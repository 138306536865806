import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import TabOptionsHeader from '../../../../../components/tab_options_header/tab_options_header';
import { CalenderIcon, DownloadIcon } from '../../../../../icons';
import { IRCWCcode } from '../../../../../interfaces/report_center';
import { getRCWCcodeThunk, getReportDownloadURL, getReportStartEndDate, selectRCWCState, selectReportsDownloadState, selectReportStartEndDateState, useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { convertDateToTimeStamp, dateType, getDateFromTimeStamp } from '../../../../../variables';
import { LoadingType, THEME } from '../../../../../enums';
import WorkCompRecordTable from '../../../components/wc_code_table/wc_code_table_page';
import { currencyConversion, CustomButton, SpinnerScreen } from '../../../../../utils';
import UrlBuilder from '../../../../../apis/url_builder';
import { reportDownloadSliceActions } from '../../../../../redux/report_center/download_report/download_report_reducer';
import './rc_work_comp_code.scss';
import CustomFormSelect from '../../../../../components/form_selector/form_select';
import ApiError from '../../../../../components/api_error';
import { rcWorkCompSliceActions } from '../../../../../redux/report_center/payroll_tax/rc_work_comp_code/rc_wc_code_reducer';
import TableEmpty from '../../../../../components/table_empty/table_empty';


interface Props extends RouteComponentProps<any> { }
const RCWCcode: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const wccodesState = useAppSelector((state) => selectRCWCState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));
    const reportStartEndDate = useAppSelector((state) => selectReportStartEndDateState(state));

    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>();
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [startDate, endDate] = dateRange;
    const [wcCodeData, setWCCodeData] = useState<IRCWCcode | null>(null);
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [isCheckDate, setIsCheckDate] = useState<boolean>(false);
    const loadingState = wccodesState.loading === LoadingType.pending;

    useEffect(() => {
        dispatch(getReportStartEndDate({}));
    }, []);

    useEffect(() => {
        if (reportStartEndDate.loading === LoadingType.succeeded && reportStartEndDate.response.length !== 0) {
            const startDate = reportStartEndDate.response[0];
            const EndDate = reportStartEndDate.response[1];
            setFormState({ available_from: startDate, available_to: EndDate });
            setDateRange([getDateFromTimeStamp(startDate), getDateFromTimeStamp(EndDate)]);
        }
    }, [reportStartEndDate.loading, reportStartEndDate.response]);

    useEffect(() => {
        if ((formState?.available_from !== 0 && formState?.available_to !== 0)
            || ((formState?.available_from !== 0 && formState?.available_to !== 0) || (formState?.available_from === 0 && formState?.available_to === 0) && isCheckDate)) {
            getWCCodesData();
            setDatePickerOpen(false);
        }
        else if ((formState?.available_from === 0 && formState?.available_to === 0)) {
            getWCCodesData();
            setDatePickerOpen(true);
        }
    }, [formState?.available_from, formState?.available_to, isCheckDate]);

    useEffect(() => {
        if (wccodesState?.error && wccodesState.error?.message) {
            setWCCodeData({} as IRCWCcode);
        }
    }, [wccodesState?.error?.message]);
    
    useEffect(() => {
        if (wccodesState.loading === LoadingType.succeeded && wccodesState.response) {
            setWCCodeData(wccodesState.response);
        }
    }, [wccodesState.loading, wccodesState.response]);

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    const getWCCodesData = () => {
        if (formState) {
            dispatch(getRCWCcodeThunk({ start_date: formState.available_from, end_date: formState.available_to, is_check_date: isCheckDate! }));
        }
    }

    function handleChange(value) {
        setFormState({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setDateRange(value);
        // dispatch(payrollCashSliceActions.clearPRCState());
    }

    function handleDownload() {
        if (formState) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.rcWcCode,
                    start_date: formState.available_from,
                    end_date: formState.available_to,
                    is_check_date: isCheckDate ? isCheckDate : false,
                    download: true
                }));
        }
    }

    const handleClearError = () => {
        dispatch(rcWorkCompSliceActions.clearRCWCErrorState())
    }

    // const [totals, setTotals] = useState<{
    //     index: number, totalDoc: {
    //         regularTimeWages: number,
    //         overTimeWages: number,
    //         doubleTimeWages: number,
    //         regularTimePremium: number,
    //         overTimePremium: number,
    //         totalPremium: number
    //     }
    // }[]>();
    // const updateTotals = (newTotals) => {
    //     setTotals((prevTotals) => {
    //         const updatedTotals = {
    //             regularTimeWages: prevTotals.regularTimeWages + newTotals.regularTimeWages,
    //             overTimeWages: prevTotals.overTimeWages + newTotals.overTimeWages,
    //             doubleTimeWages: prevTotals.doubleTimeWages + newTotals.doubleTimeWages,
    //             regularTimePremium: prevTotals.regularTimePremium + newTotals.regularTimePremium,
    //             overTimePremium: prevTotals.overTimePremium + newTotals.overTimePremium,
    //             totalPremium: prevTotals.totalPremium + newTotals.totalPremium
    //         };

    //         return updatedTotals;
    //     });
    // };

    return (
        <div className="rc-wc-container">
            <div className="header-container">
                <div className="table-header">
                    <span>{t("workcomp_class_code")}</span>
                </div>
                <div>
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="rc-wc-header">
                <div className="main-header">
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={() => setDatePickerOpen(true)} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleChange}
                                isClearable={loadingState ? false : true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dropdownMode="select"
                                disabled={loadingState}
                            />
                        </div>
                    </div>
                    <div className="fs-btn">
                        <CustomFormSelect
                            name={t("date_type")}
                            list={dateType.map((doc) => ({
                                label: doc.label,
                                value: doc.value,
                            }))}
                            onChange={setIsCheckDate}
                            value={isCheckDate}
                            placeholder={t('select')}
                            required={false}
                            label={t('date_type')}
                            disabled={loadingState}
                        />
                    </div>
                    {wccodesState?.error && <div className="error-holder">
                        <ApiError message={wccodesState?.error?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="fs-btn">
                    <CustomButton
                        leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                        loading={false}
                        name={t("download")}
                        enable={wccodesState?.response?.records.length !== 0 && (loadingState ? false : true)}
                        backgroundColor={THEME.buttonColor16}
                        onClick={() => handleDownload()}
                    />
                </div>
            </div>
            <div className='rc-wc-content'>
                {wccodesState.loading === LoadingType.pending ?
                    <SpinnerScreen />
                    :
                    <>
                        {wcCodeData?.records?.map((record, index) => (
                            <WorkCompRecordTable
                                record={record}
                            // updateTotals={updateTotals}
                            // recIndex={index}
                            />
                        ))}
                        <div>
                            {wcCodeData && wcCodeData?.records?.length > 0 ?
                                <table className='grand-total-table'>
                                    <tbody>
                                        <tr className="total-row">
                                            <td style={{ flex: getFlexNumber(0), wordWrap: "break-word" }}><span>{t("grand_total")}</span></td>
                                            <td style={{ flex: getFlexNumber(1), wordWrap: "break-word" }}><span></span></td>
                                            <td style={{ flex: getFlexNumber(2), wordWrap: "break-word" }}><span>{wcCodeData.grand_total_regular_time_wages ? currencyConversion(wcCodeData.grand_total_regular_time_wages) : "$0.00"}</span></td>
                                            <td style={{ flex: getFlexNumber(3), wordWrap: "break-word" }}><span>{wcCodeData.grand_total_over_time_wages ? currencyConversion(wcCodeData.grand_total_over_time_wages) : "$0.00"}</span></td>
                                            <td style={{ flex: getFlexNumber(4), wordWrap: "break-word" }}><span>{wcCodeData.grand_total_double_time_wages ? currencyConversion(wcCodeData.grand_total_double_time_wages) : "$0.00"}</span></td>
                                            <td style={{ flex: getFlexNumber(5), wordWrap: "break-word" }}><span></span></td>
                                            <td style={{ flex: getFlexNumber(6), wordWrap: "break-word" }}><span>{wcCodeData.grand_total_regular_time_premium ? currencyConversion(wcCodeData.grand_total_regular_time_premium) : "$0.00"}</span></td>
                                            <td style={{ flex: getFlexNumber(7), wordWrap: "break-word" }}><span>{wcCodeData.grand_total_over_time_premium ? currencyConversion(wcCodeData.grand_total_over_time_premium) : "$0.00"}</span></td>
                                            <td style={{ flex: getFlexNumber(8), wordWrap: "break-word" }}><span>{wcCodeData.grand_total_premium ? currencyConversion(wcCodeData.grand_total_premium) : "$0.00"}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                :
                                <div className='na_div'>
                                    <TableEmpty title={"No Data found"} onClick={getWCCodesData} />
                                </div>

                            }
                        </div>
                    </>
                }
            </div>
        </div>
    );
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 5;

        return 1;
    }

}

export default RCWCcode;
import { combineReducers } from "@reduxjs/toolkit";
import { assignmentReducer } from "./assignments";
import { jobOrderContactsReducer } from "./contacts";
import { jobTalentReducer } from "./talent";
import { JobDocumentReducer } from "./document";
import { jobDashboardReducers } from "./dashboard";
import { jobServiceProfileReducer } from "./service_profile";
import { jobSuccessProfileReducer } from "./success_profile";
import { grossProfitCalculatorSlice } from "./gross_profit_calculator/gross_profit_calculator_reducer";
import { talentPipelineReducer } from "./talent_pipeline";

export const jobsDetailReducer = combineReducers({
    'dashboard': jobDashboardReducers,
    'serviceProfile': jobServiceProfileReducer,
    'successProfile': jobSuccessProfileReducer,
    "assignments": assignmentReducer,
    "contacts": jobOrderContactsReducer,
    "talent": jobTalentReducer,
    "document": JobDocumentReducer,
    "talentPipeline": talentPipelineReducer,
    [grossProfitCalculatorSlice.name]: grossProfitCalculatorSlice.reducer,
})

export * from './dashboard';
export * from './assignments';
export * from './contacts';
export * from './talent';
export * from './document';
export * from './service_profile';
export * from './success_profile';
export * from './talent_pipeline';

export * from "./gross_profit_calculator/gross_profit_calculator_thunk";
export * from "./gross_profit_calculator/gross_profit_calculator_selector";
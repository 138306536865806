import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../components/api_error";
import { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import { LoadingType, ROLE, TALENT_SKILLS_TYPE, THEME } from "../../../../enums";
import {
    addTalentSkills,
    selectCertificationsList,
    selectCertificationsListState,
    selectTalentDetailsByIdState,
    selectCredentialsList,
    selectCredentialsListState,
    selectEducationList,
    selectEducationListState,
    selectRequiredEquipmentList,
    selectRequiredEquipmentListState,
    selectSkillSetList,
    selectSkillSetListState,
    selectAccountManagerRoleUsersList,
    selectRecruiterRoleUsersList,
    selectTalentDetailsState,
    selectManageTalentUpdateState,
    addTalentAccountManagers,
    addTalentRecruiters
} from "../../../../redux/store";
import { selectRoleUsersListState } from "../../../../redux/selectors";
import { useAppDispatch, useAppSelector, postSnackbarMessage, updateTalent } from "../../../../redux/store";
import { CustomButton, getStatusFromICreateState, getUserNameFromProfileDropdown, SpinnerScreen } from "../../../../utils";
import './create_option.scss';
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { CompaniesIcon, TalentIcon } from "../../../../icons";
import { talentDetailsSliceActions } from "../../../../redux/talent/details/dashboard/talent_details/talent_details_reducer";
import { IAddSkill, IAddTalentStaff, ITalent } from "../../../../interfaces";
import { cloneDeep } from 'lodash';
import { manageTalentActions } from "../../../../redux/talent/universal_directory/manage_talent/manage_talent_reducer";

interface CreateOptionProps {
    visible: boolean,
    talent_id: string,
    type: string,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
    talentState?: ITalent | null
}

export const CreateOption = (props: CreateOptionProps) => {
    const {
        visible,
        talent_id,
        type,
        onClose,
        onDissmiss,
        onSuccessClose,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const skillSetListState = useAppSelector((state) => selectSkillSetListState(state));
    const credentialsListState = useAppSelector((state) => selectCredentialsListState(state));
    const certificationsListState = useAppSelector((state) => selectCertificationsListState(state));
    const educationListState = useAppSelector((state) => selectEducationListState(state));
    const requiredEquipmentListState = useAppSelector((state) => selectRequiredEquipmentListState(state));
    const roleUsersListState = useAppSelector((state) => selectRoleUsersListState(state));
    const talentUpdateState = useAppSelector((state) => selectManageTalentUpdateState(state));

    const skillSetList = useAppSelector((state) => selectSkillSetList(state));
    const credentialsList = useAppSelector((state) => selectCredentialsList(state));
    const certificationsList = useAppSelector((state) => selectCertificationsList(state));
    const educationList = useAppSelector((state) => selectEducationList(state));
    const requiredEquipmentList = useAppSelector((state) => selectRequiredEquipmentList(state));
    const accountManagersList = useAppSelector((state) => selectAccountManagerRoleUsersList(state));
    const recruitersList = useAppSelector((state) => selectRecruiterRoleUsersList(state));

    /// Create optin states
    const talentState = useAppSelector((state) => selectTalentDetailsByIdState(talent_id)(state));
    const skillSetAddState = talentState.professional_skills.add;
    const credentialsAddState = talentState.credentials.add;
    const certificationsAddState = talentState.certifications.add;
    const educationAddState = talentState.education.add;
    const requiredEquipmentAddState = talentState.safety_equipment.add;
    const accountManagerAddState = talentState.account_managers.add;
    const recruitersAddState = talentState.recruiters.add;

    const [selected, setSelected] = useState<string[]>([]);
    const actionRef = useRef<ActionDialogHolderType>(null);

    useEffect(() => {
        if (skillSetAddState.loading === LoadingType.succeeded ||
            credentialsAddState.loading === LoadingType.succeeded ||
            certificationsAddState.loading === LoadingType.succeeded ||
            educationAddState.loading === LoadingType.succeeded ||
            requiredEquipmentAddState.loading === LoadingType.succeeded ||
            talentUpdateState.loading === LoadingType.succeeded ||
            accountManagerAddState.loading === LoadingType.succeeded ||
            recruitersAddState.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(skillSetAddState.response ||
                credentialsAddState.response ||
                certificationsAddState.response ||
                educationAddState.response ||
                requiredEquipmentAddState.response ||
                accountManagerAddState.response ||
                recruitersAddState.response ||
                getStatusFromICreateState(talentUpdateState.response)));
            closePopup(onSuccessClose);
        }
        return () => { }
    }, [
        skillSetAddState.loading,
        credentialsAddState.loading,
        certificationsAddState.loading,
        educationAddState.loading,
        requiredEquipmentAddState.loading,
        talentUpdateState.loading,
        accountManagerAddState.loading,
        recruitersAddState.loading,
    ])

    function isFetchLoading() {
        return skillSetListState.loading === LoadingType.pending ||
            credentialsListState.loading === LoadingType.pending ||
            certificationsListState.loading === LoadingType.pending ||
            educationListState.loading === LoadingType.pending ||
            requiredEquipmentListState.loading === LoadingType.pending ||
            roleUsersListState.loading === LoadingType.pending ||
            talentState.loading === LoadingType.pending;
    }

    function isAddLoading() {
        return skillSetAddState.loading === LoadingType.pending ||
            credentialsAddState.loading === LoadingType.pending ||
            certificationsAddState.loading === LoadingType.pending ||
            educationAddState.loading === LoadingType.pending ||
            requiredEquipmentAddState.loading === LoadingType.pending ||
            talentUpdateState.loading === LoadingType.pending ||
            accountManagerAddState.loading === LoadingType.pending ||
            recruitersAddState.loading === LoadingType.pending;
    }

    function getError() {
        return skillSetAddState.error ||
            credentialsAddState.error ||
            certificationsAddState.error ||
            educationAddState.error ||
            requiredEquipmentAddState.error ||
            talentUpdateState.error ||
            accountManagerAddState.error ||
            recruitersAddState.error;
    }

    function getListOptions(): Array<{ label: string, value: string, isDisabled: boolean }> {
        switch (type) {
            case TALENT_SKILLS_TYPE.professional_skills: return (skillSetList ?? []).map((doc, index) => ({ label: doc.skill_set, value: doc.id, isDisabled: (talentState?.professional_skills?.data ?? []).map(doc => doc.id).includes(doc.id) || false }));
            case TALENT_SKILLS_TYPE.credentials: return (credentialsList ?? []).map((doc, index) => ({ label: doc.credential, value: doc.id, isDisabled: (talentState?.credentials?.data ?? []).map(doc => doc.id).includes(doc.id) || false }));
            case TALENT_SKILLS_TYPE.certifications: return (certificationsList ?? []).map((doc, index) => ({ label: doc.certification, value: doc.id, isDisabled: (talentState?.certifications?.data ?? []).map(doc => doc.id).includes(doc.id) || false }));
            case TALENT_SKILLS_TYPE.education: return (educationList ?? []).map((doc, index) => ({ label: doc.education_requirement, value: doc.id, isDisabled: (talentState?.education?.data ?? []).map(doc => doc.id).includes(doc.id) || false }));
            case TALENT_SKILLS_TYPE.safety_equipment: return (requiredEquipmentList ?? []).map((doc, index) => ({ label: doc.required_equipment, value: doc.id, isDisabled: (talentState?.safety_equipment?.data ?? []).map(doc => doc.id).includes(doc.id) || false }));
            case TALENT_SKILLS_TYPE.account_managers: return (accountManagersList ?? []).map((doc, index) => ({ label: getUserNameFromProfileDropdown(doc), value: doc.id, isDisabled: (talentState?.account_managers?.data ?? []).map(doc => doc.id).includes(doc.id) || false }));
            case TALENT_SKILLS_TYPE.recruiters: return (recruitersList ?? []).map((doc, index) => ({ label: getUserNameFromProfileDropdown(doc), value: doc.id, isDisabled: (talentState?.recruiters?.data ?? []).map(doc => doc.id).includes(doc.id) || false }));
            default: return [];
        }
    }

    function getOptionForm() {
        return (
            <div className="tlnt-op-f-container">
                <div className="tlnt-op-f-header">
                    <FormDialogTilteHeader
                        title={`${t('add_option', { value: t(type) })}`}
                        titleIcon={<TalentIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                    />
                </div>
                <div className="tlnt-op-f-content">
                    <CustomMultiFormSelect
                        name={type}
                        list={getListOptions()}
                        onChange={(value) => setSelected(value)}
                        required={true}
                        placeholder={t('select_option', { value: type })}
                        value={selected}
                        customStyle={{ width: '-webkit-fill-available' }}
                        isOptionSelected={(option)=> 
                            (talentState?.professional_skills?.data!.map(doc => doc.id).includes(option.value) || 
                            talentState?.credentials?.data!.map(doc => doc.id).includes(option.value)||
                            talentState?.certifications?.data!.map(doc => doc.id).includes(option.value) ||
                            talentState?.education?.data!.map(doc => doc.id).includes(option.value) ||
                            talentState?.safety_equipment?.data!.map(doc => doc.id).includes(option.value) ||
                            (talentState?.account_managers?.data!.map(doc => doc.id).includes(option.value) && type === "account_managers") || 
                            (talentState?.recruiters?.data!.map(doc => doc.id).includes(option.value)) && type === "recruiters") ||
                            selected.some(selectedOption => selectedOption === option.value)
                        }
                    />
                    {getError() != null && <div className={"error-section"}>
                        <ApiError message={getError()?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="tlnt-op-f-actions">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('cancel')}
                            enable={true}
                            backgroundColor={THEME.secondaryColor4}
                            onClick={() => {
                                if (getError()) handleClearError();
                                closePopup(onClose);
                            }}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            loading={isAddLoading()}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('add')}
                            enable={selected.length != 0}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleAddOption}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={430}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                {isFetchLoading() ? <div className="loading-spinner"><SpinnerScreen /></div> : getOptionForm()}
            </DialogWrapper>

        </ActionDialogHolder>

    );

    // function 

    function handleClearError() {
        dispatch(talentDetailsSliceActions.clearTalentDetailsAddOptionError({ id: talent_id, type: type }));
        dispatch(manageTalentActions.clearManageTalentStateError());
    }

    function handleAddOption() {
        switch (type) {
            case TALENT_SKILLS_TYPE.account_managers: {

                const payload: IAddTalentStaff = {
                    talent_id: talent_id,
                    user_id_list: [...selected, ...(talentState?.data?.account_managers ?? []),],
                    role: ROLE.accountManager,
                }
                dispatch(addTalentAccountManagers(payload));

                break;
            }
            case TALENT_SKILLS_TYPE.recruiters: {
                const payload: IAddTalentStaff = {
                    talent_id: talent_id,
                    user_id_list: [...selected, ...(talentState?.data?.recruiters ?? []),],
                    role: ROLE.recruiter,
                }
                dispatch(addTalentRecruiters(payload));

                break;
            }

            case TALENT_SKILLS_TYPE.professional_skills: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addTalentSkills({ talentId: talent_id, value: payload }));
                break;
            }
            case TALENT_SKILLS_TYPE.credentials: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addTalentSkills({ talentId: talent_id, value: payload }));
                break;
            }
            case TALENT_SKILLS_TYPE.certifications: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addTalentSkills({ talentId: talent_id, value: payload }));
                break;
            }
            case TALENT_SKILLS_TYPE.education: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addTalentSkills({ talentId: talent_id, value: payload }));
                break;
            }
            case TALENT_SKILLS_TYPE.safety_equipment: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addTalentSkills({ talentId: talent_id, value: payload }));
                break;
            }
            default: { break; }
        }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}
import { FacebookIcon, InstagramIcon, LinkedInIcon, SocialMediaIcon, YoutubeIcon,TwitterXIcon } from '../../icons';
import { ProfileSocialMedia } from '../../interfaces';
import './social_media_profile.scss';

interface Props {
    title: string,
    profile: ProfileSocialMedia,
    showXurl?: boolean,
}

const SocialMediaProfile: React.FunctionComponent<Props> = (props) => {
    const { profile, title, showXurl } = props;
    const isProfileNull = !profile;
    return (
        <div className="social-media-info-container">
            <div className="social-media-info-icon">
                <SocialMediaIcon width={"100%"} height={"100%"} />
            </div>
            <div className="social-media-info-content">
                <div className="social-media-info-holder">
                    <div className="social-media-into-title">
                        <span>{title}</span>
                    </div>
                    <div className="social-media-info-value">
                        {/* Check if profile is null, display "NA" if true */}
                        {isProfileNull ? (
                            <span className="na-span">NA</span>
                        ) : (
                            <>
                                {profile.linkedin_url && <a href={getHttpsString(profile.linkedin_url)} target="_blank" className="social-media-info-icon" rel="noreferrer">
                                    <LinkedInIcon width={"100%"} height={"100%"} />
                                </a>}
                                {profile.youtube_url && <a href={getHttpsString(profile.youtube_url)} target="_blank" className="social-media-info-icon" rel="noreferrer">
                                    <YoutubeIcon width={"100%"} height={"100%"} />
                                </a>}
                                {profile.facebook_url && <a href={getHttpsString(profile.facebook_url)} target="_blank" className="social-media-info-icon" rel="noreferrer">
                                    <FacebookIcon width={"100%"} height={"100%"} />
                                </a>}
                                {profile.instagram_url && <a href={getHttpsString(profile.instagram_url)} target="_blank" className="social-media-info-icon" rel="noreferrer">
                                    <InstagramIcon width={"100%"} height={"100%"} />
                                </a>}
                                {showXurl && profile.twitter_url && <a href={getHttpsString(profile.twitter_url)} target="_blank" className="social-media-info-icon" rel="noreferrer">
                                    <TwitterXIcon width={"100%"} height={"100%"} />
                                </a>}
                                {(
                                    !profile.linkedin_url &&
                                    !profile.instagram_url &&
                                    !profile.facebook_url &&
                                    !profile.youtube_url &&
                                    !profile.twitter_url
                                ) && <span className="na-span">NA</span>}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )


    function getHttpsString(url: string) {
        if (url.includes('https://') || url.includes('http://')) {
            return url;
        }
        return `https://${url}`;
    }
}

export default SocialMediaProfile;
import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./import_page_thunk";
import { AppError, PayrollBatch } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";

export interface ImportFileState {
    error?: AppError | null;
    loading: LoadingType;
    response: { id: string; upload_url: string } | null;
    upload: {
        error?: AppError | null;
        loading: LoadingType;
        response: any | null;
    };
    postFile: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    }
}

const initialState: ImportFileState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    upload: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    postFile: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
};

export const importFileSlice = createSlice({
    name: "importFileSlice",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearErrorState(state) {
            return { ...initialState, error: null };
        },
        clearPostErrorState(state) {
            return {
                ...initialState,
                postFile: { ...initialState.postFile, error: null }
            }
        },
        clearUploadFilesState(state) {
            return { ...state, upload: { ...initialState.upload } };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.postImportFileURLThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.postImportFileURLThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.postImportFileURLThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                response: action.payload,
            }))
            .addCase(thunk.patchImportFileThunk.pending, (state, action) => ({ ...state, postFile: { ...state.postFile, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.patchImportFileThunk.rejected, (state, action) => ({ ...state, postFile: { ...state.postFile, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.patchImportFileThunk.fulfilled, (state, action) => ({ ...state, postFile: { ...state.postFile, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.uploadImportFileThunk.pending, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.uploadImportFileThunk.rejected, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.uploadImportFileThunk.fulfilled, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.succeeded, error: null, response: action.payload } }))


            .addDefaultCase((state) => ({ ...state }));
    },
});
export const ImportPageSliceActions = importFileSlice.actions;
export const selectState = (state: ImportFileState) => state;

import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError } from "../../../../../interfaces";
import * as thunk from "./job_order_pipeline_thunk";
import { JobOrderPipeline } from "../../../../../interfaces/job_order_pipeline";

export interface JobOrderPipelineState {
    error?: AppError | null,
    loading: LoadingType,
    response: JobOrderPipeline[] | null,
    // pipelineStatus: {
    //     error?: AppError | null,
    //     loading: LoadingType,
    //     response: string | null,
    // },
    // moveQue: {
    //     error?: AppError | null,
    //     loading: LoadingType,
    //     response: string | null,
    // },
    // moveTrash: {
    //     error?: AppError | null,
    //     loading: LoadingType,
    //     response: string | null,
    // }
}

const initialState: JobOrderPipelineState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    // pipelineStatus: {
    //     error: null,
    //     loading: LoadingType.idle,
    //     response: null,
    // },
    // moveQue: {
    //     error: null,
    //     loading: LoadingType.idle,
    //     response: null,
    // },
    // moveTrash: {
    //     error: null,
    //     loading: LoadingType.idle,
    //     response: null,
    // }
}


export const jobOrderPipelineSlice = createSlice({
    name: 'jobOrderPipelineDetails',
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...state, ...initialState };
        },
        // clearMoveQueState(state) {
        //     return { ...state, moveQue: { ...initialState.moveQue } };
        // },
        // clearMoveTrashState(state) {
        //     return { ...state, moveTrash: { ...initialState.moveTrash } };
        // },
        // clearMoveQueError(state) {
        //     return { ...state, moveQue: { ...state.moveQue, error: null } };
        // },
        // clearMoveTrashError(state) {
        //     return { ...state, moveTrash: { ...state.moveTrash, error: null } };
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getJobOrderPipelineDataThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getJobOrderPipelineDataThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.error, }))
            .addCase(thunk.getJobOrderPipelineDataThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            // .addCase(thunk.updateJobOrderPipelineStatus.pending, (state, action) => ({ ...state, pipelineStatus: { ...state.pipelineStatus, loading: LoadingType.pending, error: null, } }))
            // .addCase(thunk.updateJobOrderPipelineStatus.rejected, (state, action) => ({ ...state, pipelineStatus: { ...state.pipelineStatus, loading: LoadingType.failed, error: action.payload, response: null, } }))
            // .addCase(thunk.updateJobOrderPipelineStatus.fulfilled, (state, action) => ({ ...state, pipelineStatus: { ...state.pipelineStatus, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            // .addCase(thunk.multipleJobOrdersQueueThunk.pending, (state, action) => ({ ...state, moveQue: { ...state.moveQue, loading: LoadingType.pending, error: null, } }))
            // .addCase(thunk.multipleJobOrdersQueueThunk.rejected, (state, action) => ({ ...state, moveQue: { ...state.moveQue, loading: LoadingType.failed, error: action.payload, response: null, } }))
            // .addCase(thunk.multipleJobOrdersQueueThunk.fulfilled, (state, action) => ({ ...state, moveQue: { ...state.moveQue, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            // .addCase(thunk.multipleJobOrdersTrashThunk.pending, (state, action) => ({ ...state, moveTrash: { ...state.moveTrash, loading: LoadingType.pending, error: null, } }))
            // .addCase(thunk.multipleJobOrdersTrashThunk.rejected, (state, action) => ({ ...state, moveTrash: { ...state.moveTrash, loading: LoadingType.failed, error: action.payload, response: null, } }))
            // .addCase(thunk.multipleJobOrdersTrashThunk.fulfilled, (state, action) => ({ ...state, moveTrash: { ...state.moveTrash, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const jobOrderPipelineSliceActions = jobOrderPipelineSlice.actions;
export const selectState = (state: JobOrderPipelineState) => state;



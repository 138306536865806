import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { LoadingType, THEME } from "../../../../enums";
import { ICompetitor, ICompetitorsByPage, IProfileDropdown } from "../../../../interfaces";
import {
  geCompetitersByPage,
  getRoleUsersList,
  postSnackbarMessage,
  selectCompetitorsListState,
  selectRoleUsersList,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  getPlusBtnIcon,
  getTableAccessIcon,
  getWebsiteInfo,
} from "../../../../utils";
import { AddCompetitor } from "./add_competitor";
import "./competitor_sheet.scss";
import SearchBar from "../../../../components/search_bar";
import { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import Pagination from "../../../components/pagination/pagination";

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
  { title: "access_competitor", code: "access_competitor" },
  { title: "competitor", code: "competitor_name" },
  { title: "address", code: "street_address" },
  { title: "status", code: "status" },
  { title: "city", code: "city" },
  { title: "state", code: "state" },
  { title: "zip_code", code: "zip_code" },
  { title: "website", code: "website" },
  { title: "domain_expertise", code: "domain_expertise" },
  { title: "competitor_customers", code: "competitor_customers" },
];

const CompetitorSheet: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const [sortedField, setSortedField] = useState<string | null>("id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState("");

  const dispatch = useAppDispatch();
  const [modal, setModal] = useState(false);
  const usersList = useAppSelector((state) => selectRoleUsersList(state));
  const { competitorsByPage } = useAppSelector((state) =>
    selectCompetitorsListState(state)
  );

  const [usersListData, setUsersListData] = useState<IProfileDropdown[]>([]);

  const [selectedUser, setSelectedUser]: any = useState("");
  const [showInActive, setShowInActive] = useState(false);
  const [competitorList, setcompetitorList] = useState<ICompetitorsByPage | null>(null);
  const [isHovering, setIsHovering] = useState<{ visible: boolean, id: string } | null>(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(100)

  useEffect(() => {
    if (usersList.length !== 0) {
      setUsersListData(usersList)
    }
  }, [usersList])

  useEffect(() => {
    if (competitorsByPage?.loading === LoadingType.succeeded && competitorsByPage?.response) {
      setcompetitorList(competitorsByPage?.response)
      return () => { };
    }
  }, [competitorsByPage?.loading, competitorsByPage?.response]);

  useEffect(() => {
    // Timer ID to keep track of the setTimeout
    const timeoutId = setTimeout(() => {
        if (search?.length === 0 || search?.length >= 2) {
          getCompetitors({ pageNum: 1 });
        }
    }, 500);  // 500ms debounce delay, adjust as needed

    // Cleanup function to clear the timeout if the search value changes before the timeout is reached
    return () => clearTimeout(timeoutId);

}, [search]);  // Trigger the effect whenever the search value changes

  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    const val = e.target.checked;
    setShowInActive(val);
    getCompetitors({ pageNum: 1,include_inactive: val});
  }

  const getCompetitors = (doc?: { pageNum?: number, pageSize?: number, users_ids?: Array<string>, include_inactive?: boolean }) => {
    dispatch(geCompetitersByPage({ page: doc?.pageNum ?? currentPage, size: doc?.pageSize ?? size, users: doc?.users_ids ?? selectedUser, include_inactive: doc?.include_inactive ?? showInActive, search_str: search }));
    setCurrentPage(doc?.pageNum ?? currentPage)
  }

  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  useEffect(() => {
    dispatch(getRoleUsersList());

  }, []);

  useEffect(() => {
    if (competitorsByPage?.error != null && competitorList?.items?.length != 0) {
      dispatch(postSnackbarMessage(competitorsByPage?.error.message));
    }
  }, [competitorsByPage?.error]);

  const getFilteredList = () => {
    let list = [...competitorList?.items! ?? []];
    let sortedList: ICompetitor[] | undefined;
    if (sortedField != null) {
      sortedList = [...(sortedList ?? list)].sort((a, b) => {
        const valueA =
          a[sortedField] != null
            ? typeof a[sortedField] != typeof 1 &&
              typeof a[sortedField] != typeof []
              ? a[sortedField].trim().toLowerCase()
              : typeof a[sortedField] === typeof []
                ? []
                : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] != typeof 1 &&
              typeof b[sortedField] != typeof []
              ? b[sortedField].trim().toLowerCase()
              : typeof b[sortedField] === typeof []
                ? []
                : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    return sortedList ?? list;
  };

  const handleCompetitorSelect = (value): void => {
    props.history.push({
      pathname: `${AppRoutes.salesAndMarketingCompetitorSheet}/${value.id}/dashboard`,
      state: {
        id: value.id,
        name: value.competitor_name,
      },
    });
  };

  function handleMouseOver(id) {
    setIsHovering({ id: id, visible: true });
  }
  function handleMouseOut() {
    setIsHovering(null);
  }

  const showSpinner = () => competitorsByPage?.loading === LoadingType.pending;

  const getTable = () => {
    if (showSpinner()) return <TableLoading />;

    if (competitorsByPage?.error != null && competitorList?.items?.length === 0) {
      return <TableErrorHandler error={competitorsByPage?.error} onRefresh={getCompetitors} />;
    }

    if (competitorList?.items?.length === 0 || getFilteredList()?.length === 0) {
      return <TableEmpty title={"No calls found"} onClick={getCompetitors} />;
    }

    if (getFilteredList()?.length !== 0) {
      return (
        <SortableTable
          headerList={tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {getFilteredList()?.map((doc) => {
            return (
              <tr key={doc.id}>
                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                  {getTableAccessIcon(() => handleCompetitorSelect(doc))}
                </TableData>
                <TableData
                  customStyle={{ flex: getFlexNumber(1) }}
                  isButton={true}
                  onClick={() => handleCompetitorSelect(doc)}
                >
                  <span>{doc.competitor_name}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                  <span>{doc.street_address}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                  <span>{doc.status}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                  <span>{doc.city}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                  <span>{doc.state}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                  {doc.zip_code}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                  <div style={{ cursor: "pointer" }} onMouseOut={() => handleMouseOut()}
                    onMouseOver={() => handleMouseOver(doc.id)}>
                      <span style={{ width: "1vw", height: "1vw" }}>
                        {getWebsiteInfo(doc.website, isHovering, doc.id)}
                      </span>
                  </div>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(8) }}>
                  <span>{doc.domain_expertise.join(",")}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(9) }}>
                  <span>{doc.competitor_customers.join(",")}</span>
                </TableData>
              </tr>
            );
          })}
        </SortableTable>
      );
    }
  };

  function getFlexNumber(value: number) {
    if (value === 0) return 1;
    if (value === 1) return 2;
    if (value === 2) return 2;
    if (value === 3) return 1;
    if (value === 4) return 1.5;
    if (value === 5) return 1;
    if (value === 6) return 1;
    if (value === 7) return 1;
    if (value === 8) return 3;
    if (value === 9) return 3;
    return 1;
  }

  const changeModelHandler = () => {
    setModal(!modal);
  };

  const handlePageChange = (pageNum: number, size: number) => {
    getCompetitors({ pageNum: pageNum, pageSize: size })
    setCurrentPage(pageNum);
    setSize(size ? size : competitorList?.size!)
  };

  const handleUsers = (value) => {
    setSelectedUser(value)
    getCompetitors({ pageNum: 1, users_ids: value });
  }

  return (
    <div className="my-call-deck">
      <div className="filters-container">
        <div className="actions">
          <div className="table-header">
            <div>
              <CustomMultiFormSelect
                customStyle={{ width: "initial", marginLeft: "0em" }}
                label={t("select_user")}
                name={"select_user"}
                value={selectedUser}
                placeholder={"All"}
                list={usersListData.map((doc) => ({
                  label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                  value: doc.id,
                }))}
                onChange={handleUsers}
              />
            </div>
            <div>
              <div className="active-checkbox">
                <input
                  type="checkbox"
                  checked={showInActive}
                  onChange={handleOnChange}
                />
                <span>{t("include_inactive")}</span>
              </div>
            </div>
            <div className="total-count-action">
              <span>
                {`${t("total_count")}: `}
                <span className="total-count-number">
                  {competitorList?.total!}
                </span>
              </span>
            </div>
          </div>
          <div className="cs-search-btn">
            <div className="cs-search">
              <SearchBar
                value={search}
                onChange={(value) => setSearch(value)}
                onSearch={()=>{}}
              />
            </div>
            <div style={{ width: "auto" }}>
              <CustomButton
                leftIcon={getPlusBtnIcon()}
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("Add Competitor")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => setModal(true)}
              />
            </div>
          </div>
        </div>


      </div>
      <div className="cs-table">{getTable()}</div>
      <div className="as-table-footer">
        <Pagination
          totalPages={competitorList?.pages!}
          onPageChange={handlePageChange}
          curPageNum={currentPage}
          totalCount={competitorList?.total!}
          size={competitorList?.size!}
          loadingState={competitorsByPage?.loading}
        />
      </div>
      {modal && (
        <Portal>
          <AddCompetitor
            showModal={modal}
            callbackHandler={changeModelHandler}
            callAPI={getCompetitors}
          />
        </Portal>
      )}
    </div>
  );
};

export default CompetitorSheet;

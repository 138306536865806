import { createAsyncThunk } from "@reduxjs/toolkit";
import { JobOrderAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { IPipelineStatus } from "../../../../interfaces/talent_pipeline";
import { catchRequestError } from "../../../../utils";

export const getPipelineStatusThunk = createAsyncThunk<
    IPipelineStatus[],
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/system_configuration/job_talent_pipleine_status/get',
    async (_, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getPipelineStatus();
            return res.data as IPipelineStatus[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createPipelineStatusThunk = createAsyncThunk<
    string,
    {'value': string, 'description': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/job_talent_pipleine_status/post',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.createPipelineStatus(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deletePipelineStatusThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/job_talent_pipleine_status/delete',
    async (method_id, thunkAPI) => {
        try {
            const res = await JobOrderAPI.deletePipelineStatus(method_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updatePipelineStatusThunk = createAsyncThunk<
    string,
    IPipelineStatus,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/job_talent_pipleine_status/update',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.updatePipelineStatus(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import { RouteComponentProps } from 'react-router-dom';
import StateChangeHandler from '../../../../../components/state_change_handler/state_change_handler';
import CurveTabNavigation from '../../../../../components/curve_tab_navigation/curve_tab_navigation';
import { COMPANY_TYPE, LoadingType } from '../../../../../enums';
import { EditIcon, EngagementIcon, MagnifyGlassIcon, RatingIcon, SkillsSummaryIcon, StaffIcon } from '../../../../../icons';
import { IContact, ISkillOption } from '../../../../../interfaces';
import {
  apolloAddCreditsThunk,
  apolloGetCreditsThunk,
  getContactById,
  postSnackbarMessage,
  refreshApolloContact,
  selectApolloCreditsState,
  selectApolloRefreshState,
  selectContactDetailsByIdState,
} from '../../../../../redux/store';

import { selectProfileState, useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { AppRoutes } from '../../../../../routes';
import CreateContactPopup from '../../../popups/create_contact/create_contact';
import { CreateContactOptionPopup } from '../../../popups/create_option/create_option';
import './dashboard.scss';
import ContactDetailsSection from '../../../contact_details_section';
import ContactSkillSummary from './summary/skills_summary';
import EngagementDetails from '../../../../components/engagementDetails';
import ContactsStaffDetails from './staffing/contacts_staffing';
import RatingsTab from '../../../../components/ratingsDetails';
import { contactDetailsSliceActions } from '../../../../../redux/contacts/details/dashboard/contact_details/contact_details_reducer';
import { manageContactActions } from '../../../../../redux/contacts/universal_directory/manage_contact/manage_contact_reducer';
import { apolloRefreshActions } from '../../../../../redux/apollo_search_import/apollo_refresh/apollo_refresh_reducer';
import { IApolloCredits } from '../../../../../interfaces/apollo_search_import';
import ApolloRefreshPopup from '../../../../../components/apollo_refresh_popup/apollo_refresh_popup';
import { apolloCreditsActions } from '../../../../../redux/apollo_search_import/apollo_credits/apollo_credits_reducer';
import AddCreditsPopup from '../../../../apollo_search_import/popups/add_credits_popup/add_credits_popup';
import { RemoveOptionPopup } from '../../../../components/remove_option/remove_option';

interface Props extends RouteComponentProps<any> { }
const ContactDashboard: React.FunctionComponent<Props> = (props) => {
  const { history, location, match } = props;
  const { params } = match;
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => selectProfileState(state));
  const contactState = useAppSelector((state) => selectContactDetailsByIdState(params.id)(state));
  const contactRefreshState = useAppSelector((state) => selectApolloRefreshState(state));
  const apolloCreditsState = useAppSelector((state) => selectApolloCreditsState(state));
  // const departmentState = useAppSelector((state) => selectContactDetailsDepartmentState(state));
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [editContact, setEditContact] = useState<{ visible: boolean, data: IContact } | null>(null);
  const [addOption, setAddOption] = useState<{ visible: boolean, type: string } | null>(null);
  const [removeOption, setRemoveOption] = useState<{ visible: boolean, type: string, value: { id: string, value: string } } | null>(null);

  useEffect(() => {
    getContactDetails();
    // dispatch(apolloGetCreditsThunk());
    return () => { }
  }, [params.departmentId])

  useEffect(() => {
    if (contactState.loading === LoadingType.succeeded) {
      updateNavigationNames();
    }
    return () => { }
  }, [contactState.loading])


  function updateNavigationNames() {
    if (params) {
      if (params.id != null && contactState.data) {
        const contact = (contactState.data as IContact);
        history.replace({
          pathname: location.pathname,
          state: {
            ...(location.state as any),
            id: params.id,
            name: contact.name ?? `${contact.first_name ?? ''} ${contact.last_name ?? ''}`,
          }
        });
      }

    }

  }

  function getContactDetails() {
    if (params && params.id != null) {
      dispatch(getContactById({ contact_id: params.id }));
    }
  }
//   const [availCredits, setAvailCredits] = useState<IApolloCredits | null>();

//   useEffect(() => {
//     if (apolloCreditsState && apolloCreditsState.loading === LoadingType.succeeded && apolloCreditsState.response) {
//       setAvailCredits(apolloCreditsState.response)
//     }
//   }, [apolloCreditsState, apolloCreditsState.loading])
//   const [showRefreshPopup, setShowRefreshPopup] = useState<boolean>(false);

//   useEffect(() => {
//     if (contactRefreshState && contactRefreshState.refreshContact.loading === LoadingType.succeeded) {
//       setShowRefreshPopup(false);
//       dispatch(postSnackbarMessage(contactRefreshState.refreshContact.response.msg ?? null));
//       handleClearError();
//       getContactDetails();
//     }
//   }, [contactRefreshState, contactRefreshState.loading]);
//   useEffect(() => {
//     if (apolloCreditsState && apolloCreditsState.addCredits && apolloCreditsState.addCredits.loading === LoadingType.succeeded && apolloCreditsState.addCredits.response) {
//         // setSnackBarMessage(apolloCreditsState.addCredits.response);
//         dispatch(postSnackbarMessage(apolloCreditsState.addCredits.response ?? null));
//         setAddCredit(false);
//         dispatch(apolloGetCreditsThunk());
//         dispatch(apolloCreditsActions.clearApolloAddCreditsState());
//     }
// }, [apolloCreditsState.addCredits.loading]);
//   function handleOnRefreshContact(need_direct_dial: boolean) {
//     dispatch(refreshApolloContact({ contact_id: params.id, need_direct_dial: need_direct_dial }));
//   }
//   function handleClearError() {
//     dispatch(apolloRefreshActions.clearApolloRefreshContactState());
//   };
//   const [addCredit, setAddCredit] = useState<boolean>(false);
//   const handleAddCredits = (count: Number | undefined) => {
//       dispatch(apolloAddCreditsThunk({ count: count }));
//   }
  const handleClearRemoveOptionError = () => {
    dispatch(contactDetailsSliceActions.clearContactDetailsRemoveOptionError({ id: params.id, type: removeOption?.type! }));
  };
  return (
    <StateChangeHandler
      loading={contactState.loading}
      error={contactState.error}
      onRefresh={getContactDetails}
    >
      <div className="cnt-dsh-container">
        <div className="cnt-dsh-left-panel">
          <ContactDetailsSection
            profile={profile}
            contactInfo={contactState.data}
            // onShowRefreshPopup={setShowRefreshPopup}
            // availCredits={availCredits?.credits_available}
            // onAddCredit={setAddCredit}
          />
        </div>
        <div className="cnt-dsh-right-panel">
          <CurveTabNavigation
            tabs={getTabList()}
            selected={tabIndex}
            onChange={(value) => setTabIndex(value)}
            actions={getEditAction()}
          />
        </div>
      </div>
      {editContact && <Portal>
        <CreateContactPopup
          visible={editContact.visible}
          contact={editContact.data}
          title={t('edit_contact')}
          successTitle={t('edit_contact')}
          onClose={() => setEditContact(null)}
          onSuccessClose={handleUpdateContactSuccess}
          onGotoAgencyDashboard={() => { }}
          onGotoCreated={() => { }}
        />
      </Portal>}


      {addOption && <Portal>
        <CreateContactOptionPopup
          data={{ contact_id: params.id, contact: contactState.data ?? undefined }}
          visible={addOption.visible}
          type={addOption.type}
          onClose={handleCloseAddDialog}
          onDissmiss={handleCloseAddDialog}
          onSuccessClose={handleOnSuccessAddDialog}
        />
      </Portal>}
      {removeOption && <Portal>
        <RemoveOptionPopup
          id={params.id}
          state={contactState}
          data={removeOption.value}
          type={removeOption.type}
          sourceType={"Contact"}
          visible={removeOption.visible}
          onClose={handleCloseRemoveDialog}
          onDissmiss={handleCloseRemoveDialog}
          onSuccessClose={handleOnSuccessRemoveDialog}
          onClearError={handleClearRemoveOptionError}
        />
      </Portal>}
      {/* {showRefreshPopup && (
        <Portal>
          <ApolloRefreshPopup
            visible={showRefreshPopup}
            title={t('refresh_contact')}
            paramId={params.id}
            onClose={() => { setShowRefreshPopup(false); handleClearError() }}
            onDissmiss={() => { setShowRefreshPopup(false); handleClearError() }}
            onSuccessClose={handleOnRefreshContact}
            importedDirectDial={contactState?.data?.direct_dial}
            name={`${contactState.data?.first_name ?? ''} ${contactState.data?.last_name ?? ''}`}
            refreshState={contactRefreshState.refreshContact}
            onClearError={handleClearError}

          />
        </Portal>
      )}
      {addCredit && (
        <Portal>
          <AddCreditsPopup
            visible={addCredit}
            onClose={() => setAddCredit(false)}
            loading={apolloCreditsState.addCredits.loading}
            handleAddCredits={handleAddCredits}
            error={apolloCreditsState.addCredits.error?.message}
          />
        </Portal>
      )
      } */}
    </StateChangeHandler>


  );


  function getTabList() {
    return [
      {
        title: t("skill_sets").toUpperCase(),
        icon: SkillsSummaryIcon,
        content: <ContactSkillSummary
          contactId={params.id}
          onAddOption={handleAddOption}
          onRemoveOption={handleRemoveOption}
        />,
      },
      {
        title: t("engagement_details").toUpperCase(),
        icon: EngagementIcon,
        content: <EngagementDetails id={params.id} source={"CONTACT"}
          onSuccessCreate={getContactDetails}
        />
      },
      {
        title: t('staffing_representatives', { value: profile.profile?.agency_name }).toUpperCase(),
        icon: StaffIcon,
        content: <ContactsStaffDetails contactId={params.id} onAddOption={handleAddOption} onRemoveOption={handleRemoveOption} />
      },
      {
        title: t('ratings').toUpperCase(),
        icon: RatingIcon,
        content: <RatingsTab id={params.id} source={"contact"} />
      },

    ];
  }

  /// close add option dialog
  function handleCloseAddDialog() {
    setAddOption(null);
  }

  /// Add option
  function handleAddOption(type: string) {
    setAddOption({ visible: true, type: type });
  }

  /// Add on success add option
  function handleOnSuccessAddDialog() {
    getContactDetails();
    if (addOption != null) {
      dispatch(contactDetailsSliceActions.clearContactDetailsAddOptionError({ id: params.id, type: addOption?.type }));
      setAddOption(null);
      getContactDetails();
    }

  }

  /// Remove Option
  function handleRemoveOption(type: string, value: { id: string, value: string }) {

    setRemoveOption({ visible: true, type: type, value: value });
  }

  /// close add option dialog
  function handleCloseRemoveDialog() {
    setRemoveOption(null);
  }

  /// Add on success remove option
  function handleOnSuccessRemoveDialog() {
    getContactDetails();
    if (removeOption != null) {
      dispatch(contactDetailsSliceActions.clearContactDetailsRemoveOptionError({ id: params.id, type: removeOption?.type }));
      setRemoveOption(null);
      getContactDetails();
    }

  }

  /// Edit icon actions
  function getEditAction() {
    return (
      <button className="icon-btn cnt-dsh-edit-icon" onClick={handleEdit}>
        <EditIcon width={"100%"} height={"100%"} style={{ color: '#fff' }} />
      </button>
    );
  }



  function handleEdit() {
    setEditContact({ visible: true, data: (contactState.data as IContact) });
  }

  ///Add user action
  function handleUpdateContactSuccess() {
    setEditContact(null);
    getContactDetails();
    dispatch(manageContactActions.clearManageContactState(["photo", "resume"]));
  }

}


export default ContactDashboard;
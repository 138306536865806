import { Accordion, AccordionDetails, AccordionSummary, Portal } from "@material-ui/core";
import { ExpandMoreIcon, TalentIcon } from "../../../../../icons";
import { useTranslation } from "react-i18next";
import { getTalentPipelineDataThunk, getTEADraftBroadCast, multipleTalentsQueueThunk, multipleTalentsTrashThunk, selectProfileState, selectTalentPipelineState, selectTextEmAllState, updatePipelineStatus, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import TalentMatchesSection from "./talent_matches_section/talent_matches_section";
import { LoadingType, TalentPipelineDirectory, THEME } from "../../../../../enums";
import { IMultipleMove, IPipelineStatus, TalentPipeline } from "../../../../../interfaces/talent_pipeline";
import './talent_pipeline.scss';
import { getPipelineStatusThunk, getTalentStatus, selectPipelineStatusState, selectTalentStatusList } from "../../../../../redux/admin_center";
import { pipelineSliceActions } from "../../../../../redux/job_order/details/talent_pipeline/talent_pipeline_details/talent_pipeline_reducer";
import BroadcastToTalentPopup from "../../../../../components/broadcast_talent/broadcast_talent";
import BroadcastToTalentErrorPopup from "../../../../../components/broadcast_talent/broadcast_talent_error";
import { TEADraftBroadCast } from "../../../../../interfaces/text_em_all";
import { textEmAllSliceActions } from "../../../../../redux/textEmAll/text_Em_All_reducer";

interface Props extends RouteComponentProps<any> { }

const TalentPipelinePage: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { history, match } = props;
    const { params } = match
    const dispatch = useAppDispatch();
    const [expanded, setExpanded] = useState<boolean | string>('false');
    const talentPipelineState = useAppSelector((state) =>
        selectTalentPipelineState(state)
    );

    const textEmAllState = useAppSelector((state) => selectTextEmAllState(state));

    const talentTrashState = talentPipelineState.moveTrash;
    const talentQueState = talentPipelineState.moveQue;
    const talentPipelineStatusChangeState = talentPipelineState.pipelineStatus;


    const pipelineStatusState = useAppSelector((state) => selectPipelineStatusState(state));
    const talentStatusList = useAppSelector((state) => selectTalentStatusList(state));

    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""}`;
    const canSendText = profileState?.profile?.texting && profileState?.profile?.text_em_all_token !== null;

    const [matchesTalentList, setMatchesTalentList] = useState<TalentPipeline[] | null>(null);
    const [queTalentList, setQueTalentList] = useState<TalentPipeline[] | null>(null);
    const [trashedTalentList, setTrashedTalentList] = useState<TalentPipeline[] | null>(null);
    const [piplineStatus, setPipelineStatus] = useState<IPipelineStatus[] | null>(null);

    const accordions = [
        { id: "panel1", title: "qualified_talent",subTitle: "qualified_talent_subtitle", directory: TalentPipelineDirectory.Queued },
        { id: "panel2", title: "smart_matches",subTitle: "smart_matches_subtitle", directory: TalentPipelineDirectory.Match },
        { id: "panel3", title: "not_a_fit",subTitle: "not_a_fit_subtitle", directory: TalentPipelineDirectory.Trashed }];
    useEffect(() => {
        dispatch(pipelineSliceActions.clearState());
        dispatch(getTalentPipelineDataThunk(params.id));
        dispatch(getPipelineStatusThunk());
        dispatch(getTalentStatus())
        handleExpand('panel2');
    }, [])
    useEffect(() => {
        if (talentPipelineState.loading === LoadingType.succeeded && talentPipelineState.response) {
            setMatchesTalentList(talentPipelineState.response.filter((talent) => talent.directory === TalentPipelineDirectory.Match));
            setQueTalentList(talentPipelineState.response.filter((talent) => talent.directory === TalentPipelineDirectory.Queued));
            setTrashedTalentList(talentPipelineState.response.filter((talent) => talent.directory === TalentPipelineDirectory.Trashed));
        }
        if (talentPipelineState.loading === LoadingType.failed) {
            setMatchesTalentList(null);
            setQueTalentList(null);
            setTrashedTalentList(null);
        }
    }, [talentPipelineState.loading, talentPipelineState.response]);

    useEffect(() => {
        if (pipelineStatusState.loading === LoadingType.succeeded && pipelineStatusState.response) {
            setPipelineStatus(pipelineStatusState.response);
        }
    }, [pipelineStatusState.loading, pipelineStatusState.response]);

    useEffect(() => {
        if (talentPipelineStatusChangeState.loading === LoadingType.succeeded) {
            dispatch(getTalentPipelineDataThunk(params.id));
        }
    }, [talentPipelineStatusChangeState.loading]);

    const handleExpand = (panel: string) => {
        setExpanded((expanded === panel ? false : panel));
    };

    const handleMoveToTrash = (doc: IMultipleMove[]) => {
        dispatch(multipleTalentsTrashThunk(doc))
    }
    const handleMoveToQueue = (doc: IMultipleMove[]) => {
        dispatch(multipleTalentsQueueThunk(doc))
    }
    const handleTalentPipelineStatusChange = (talent_id, status_id) => {
        dispatch(updatePipelineStatus({ job_id: params.id, talent_id: talent_id, status_id: status_id }));
    }
    const handleSuccessClose = () => {
        dispatch(getTalentPipelineDataThunk(params.id));
    }

    //Text Em All Related Code
    const [broadcastTalent, setBroadcastTalent] = useState<boolean>(false);
    const [broadCastTalentList, setBroadcastTalentList] = useState<string[]>([]);

    const [broadcastTalentError, setBroadcastTalentError] =
        useState<boolean>(false);
    useEffect(() => {
        dispatch(textEmAllSliceActions.clearState());
        return () => { };
    }, []);
    useEffect(() => {
        if (
            textEmAllState &&
            textEmAllState?.error !== null &&
            textEmAllState?.loading === LoadingType.failed
        ) {
            setBroadcastTalentError(true);
        }
        if (
            textEmAllState &&
            textEmAllState?.error === null &&
            textEmAllState?.loading === LoadingType.succeeded
        ) {
            setBroadcastTalent(true);
        }
        if (
            textEmAllState &&
            textEmAllState?.error === null &&
            textEmAllState?.loading === LoadingType.idle
        ) {
            setBroadcastTalent(false);
            setBroadcastTalentList([]);
            setBroadcastTalentError(false);

        }
    }, [textEmAllState, textEmAllState.error]);
    const [teaFormState] = useState<TEADraftBroadCast>({
        modified_by: "",
        created_by: "",
        modified_date: 0,
        talent_ids: [],
    });
    const [showMsg, setShowMsg] = useState<boolean>(false);
    const [showErrorMsg, setShowErrorMsg] = useState<string>("");
    const handleBroadCastTalent = (selectedTalents: string[], forceSend?: boolean) => {
        if (selectedTalents.length !== 0) {
            setBroadcastTalentList(selectedTalents);
            dispatch(
                getTEADraftBroadCast({ forceSend: forceSend, data: { ...teaFormState, talent_ids: selectedTalents } })
            );
            setShowMsg(false);
        } else {
            setShowMsg(true);
            setShowErrorMsg(t("please_select_talent_to_broadcast"));
            setTimeout(() => setShowErrorMsg(""), 5000);
        }
    };
    return (
        <div className="jobs-talent-pipeline-container">
            {accordions.map((accordion, index) => {
                return (
                    <Accordion className="accordion-container"
                        expanded={expanded === accordion.id} onChange={() => handleExpand(accordion.id)} key={accordion.id + index}
                        style={{
                            marginTop: 0, // Adjust spacing
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="accordion-title"
                            aria-controls="talent-matches"

                        >
                            <div className="title-holder">
                                <div className="ac-title-icon">
                                    <TalentIcon
                                        width={"1.5vw"}
                                        height={"1.5vw"}
                                        style={{ color: THEME.defaultHighLightColor }}
                                    />
                                </div>
                                <div className="title-name">
                                    <span>{t(accordion.title)}</span>
                                    <span className="accordion-subtitle">{t(accordion.subTitle ?? "")}</span>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className="accordion-details">
                            <TalentMatchesSection
                                directory={accordion.directory}
                                index={index}
                                loading={talentPipelineState.loading === LoadingType.pending}
                                talentPipelineList={accordion.directory === TalentPipelineDirectory.Queued ? queTalentList : accordion.directory === TalentPipelineDirectory.Match ? matchesTalentList : trashedTalentList}
                                history={history}
                                handleMoveToTrash={(doc: IMultipleMove[]) => handleMoveToTrash(doc)}
                                handleMoveToQueue={(doc: IMultipleMove[]) => handleMoveToQueue(doc)}
                                jobId={params.id}
                                talentQueState={talentQueState}
                                talentTrashState={talentTrashState}
                                talentPipelineStatusChangeState={talentPipelineStatusChangeState}
                                pipelineStatusList={piplineStatus ?? []}
                                talentStatusList={talentStatusList ?? []}
                                currentLoginUserName={currentLoginUserName}
                                canSendText={canSendText}
                                handleTalentPipelineStatusChange={(talent_id, status_id) => handleTalentPipelineStatusChange(talent_id, status_id)}
                                onSuccessClose={handleSuccessClose}
                                handleBroadCastTalent={handleBroadCastTalent}
                                showMsg={showMsg}
                                showErrorMsg={showErrorMsg}
                            />
                        </AccordionDetails>
                    </Accordion>)
            })}
            {canSendText &&
                broadcastTalentError &&
                textEmAllState &&
                textEmAllState?.error !== null && (
                    <Portal>
                        <BroadcastToTalentErrorPopup
                            error={textEmAllState?.error}
                            onClose={() => {
                                setBroadcastTalentError(false);
                                dispatch(textEmAllSliceActions.clearState());
                            }}
                            visible={broadcastTalentError}
                            title={t("something_went_wrong")}
                            loading={textEmAllState?.loading === LoadingType.pending}
                            onForceSend={(forceSend) => handleBroadCastTalent(broadCastTalentList,forceSend)}
                        />
                    </Portal>
                )}
            {canSendText && broadcastTalent && (
                <Portal>
                    <BroadcastToTalentPopup
                        visible={broadcastTalent}
                        title={""}
                        successTitle={""}
                        onClose={() => {
                            setBroadcastTalent(false);
                            setBroadcastTalentList([]);
                            dispatch(textEmAllSliceActions.clearState());

                        }}
                        onSuccessClose={() => { }}
                        responseURL={textEmAllState?.response}
                        loading={textEmAllState?.loading === LoadingType.pending}
                    />
                </Portal>
            )}
        </div>
    )
}

export default TalentPipelinePage;
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import { RouteComponentProps } from 'react-router-dom';
import StateChangeHandler from '../../../../components/state_change_handler/state_change_handler';
import CurveTabNavigation from '../../../../components/curve_tab_navigation/curve_tab_navigation';
import { LoadingType, THEME } from '../../../../enums';
import { EditIcon, EngagementIcon, SkillsSummaryIcon, CalenderIcon, StaffIcon, RatingIcon } from '../../../../icons';
import { ITalent, IDepartment, ITalentJobMatch, ITalentCompanyMatch } from '../../../../interfaces';
import {
    getTalentById,
    selectTalentDetailsByIdState,
    getTalentJobMatches,
    getTalentCompanyMatches,
    postSnackbarMessage,
    selectSingleResumeFileStateById,
    getResumeFileUrlForDownload,
    refreshApolloTalent,
    selectTalentDetailsState,
    selectApolloRefreshState,
    selectApolloCreditsState,
    apolloGetCreditsThunk,
    apolloAddCreditsThunk,
    getTerminateTalent,
    selectTerminateTalentState,
    selectManageAssignmentState
} from '../../../../redux/store';
import { useAppDispatch, useAppSelector, selectProfileState } from '../../../../redux/store';
import { AppRoutes } from '../../../../routes';
import { getRatingBackgrounColor, getTalentMatchQuery, handleDownload } from '../../../../utils';
import CreateTalentPopup from '../../popups/create_talent/create_talent';
// import CreateDepartmentPopup from '../../popup/talent_department/talent_department';
import { CreateOption } from '../../popups/create_option/create_option';
import AvailabilityTab from './availability/availability';
import './talent_dashboard_page.scss';
import CompanySummery from './skills_summery/skills_summery';
import { talentDetailsSliceActions } from '../../../../redux/talent/details/dashboard/talent_details/talent_details_reducer';
import TalentDetailsSection from '../../../../components/talent_details_section/talent_details_section';
import { manageTalentActions } from '../../../../redux/talent/universal_directory/manage_talent/manage_talent_reducer';
import { getTrafficLightEngagementMetrics, selectTrafficLightEngagementMetricList } from '../../../../redux/admin_center';
import SearchBar from '../../../../components/search_bar';
import SortableTable, { TableData } from '../../../../components/sortable_table/sortable_table';
import TableEmpty, { TableLoading, TableErrorHandler, TableFilterEmpty } from '../../../../components/table_empty/table_empty';
import CreateAssignmentPopup from '../../../jobs/popups/create_assignment/create_assignemt_popup';
import TalentStaffDetails from './staffing/staffing';
import EngagementDetails from '../../../components/engagementDetails';
import { NotificationCircle } from '../../../../components/notification_circle/notification_circle';
import AvailabiltyPopup from '../../popups/add_availability/add_availability';
import { talentAvailableDatesActions } from '../../../../redux/talent/details/dashboard/talent_availability/talent_availability_reducer';
import RatingsTab from '../../../components/ratingsDetails';
import ViewSSNPopup from '../../popups/view_ssn/view_ssn';
import { talentRequestSSNActions } from '../../../../redux/talent/details/dashboard/request_ssn/request_ssn_reducer';
import { resumeFileSliceActions } from '../../../../redux/files/resume_files/resume_file_reducer';
import { convertDateToTimeStamp } from '../../../../variables';
import TextEmAllTab from './text_em_all/text_em_all';
import { textEmAllSliceActions } from '../../../../redux/textEmAll/text_Em_All_reducer';
import ApolloRefreshPopup from '../../../../components/apollo_refresh_popup/apollo_refresh_popup';
import { apolloRefreshActions } from '../../../../redux/apollo_search_import/apollo_refresh/apollo_refresh_reducer';
import { IApolloCredits } from '../../../../interfaces/apollo_search_import';
import { apolloCreditsActions } from '../../../../redux/apollo_search_import/apollo_credits/apollo_credits_reducer';
import AddCreditsPopup from '../../../apollo_search_import/popups/add_credits_popup/add_credits_popup';
import TerminateTalentPopup from '../../popups/terminate_talent/terminate_talent';
import { clearTalentTerminationState, clearTalentTerminationStateError } from '../../../../redux/talent/details/terminate_talent/terminate_talent_reducer';
import { RemoveOptionPopup } from '../../../components/remove_option/remove_option';

const jobMatchesTableHeader = [
    // { title: 'access_job', code: 'access_job' },
    { title: 'job_title', code: 'job_title' },
    { title: 'company_name', code: 'company_name' },
    { title: 'skills_and_certifications', code: 'skill_and_certifications' },
    { title: 'engagement_rating', code: 'engagement_rating' },
    { title: 'requested', code: 'requested' },
    { title: 'placed', code: 'placed' },
    { title: 'over_or_under', code: 'over_under_placed' },
]

const companyMatchesTableHeader = [
    // { title: 'access_company', code: 'access_company' },
    { title: 'company_name', code: 'company_name' },
    { title: 'skills_and_certifications', code: 'skill_and_certifications' },
    { title: 'engagement_rating', code: 'engagement_rating' },
]


interface Props extends RouteComponentProps<any> { }
const TalentDashboardPage: React.FunctionComponent<Props> = (props) => {
    const { history, location, match } = props;
    const { params } = match;
    const dispatch = useAppDispatch();
    const profileState = useAppSelector((state) => selectProfileState(state));
    const talentState = useAppSelector((state) => selectTalentDetailsByIdState(params.id)(state));
    const talentRefreshState = useAppSelector((state) => selectApolloRefreshState(state));
    const apolloCreditsState = useAppSelector((state) => selectApolloCreditsState(state));
    const terminateTalentState = useAppSelector((state) => selectTerminateTalentState(state));

    // const departmentState = useAppSelector((state) => selectCompanyDetailsDepartmentState(state));
    const assignmentManageState = useAppSelector((state) => selectManageAssignmentState(state));

    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [branchId, setBranchId] = useState<string | null>(null);
    const trafficeMetrics = useAppSelector((state) => selectTrafficLightEngagementMetricList(state));
    const [editTalent, setEditTalent] = useState<{ visible: boolean, data: ITalent } | null>(null);
    const [editDepartment, setEditDepartment] = useState<{ visible: boolean, data?: IDepartment } | null>(null);
    const [addOption, setAddOption] = useState<{ visible: boolean, type: string } | null>(null);
    const [removeOption, setRemoveOption] = useState<{ visible: boolean, type: string, value: { id: string, value: string } } | null>(null);
    const [showAssignmentPopUp, setShowAssignmentPopUp] = useState(false);
    const [terminateTalentPopUp, setTerminateTalentPopUp] = useState(false);


    /// [Talent] Availability
    const [showAvailabilityPopup, setShowAvailabilityPopup] = useState<{ selectedDate: number, name: string, talentId: string, loginUserName: string, visible: boolean } | null>(null);

    /// [Talent] Mark Availability Current Day From Details Section
    const [showMarkAvailabilityPopup, setShowMarkAvailabilityPopup] = useState<{ selectedDate: number, name: string, talentId: string, loginUserName: string, visible: boolean } | null>(null);

    /// [Talent] SSN

    const [showSSNPopup, setShowSSNPopup] = useState<{ talentId: string, visible: boolean } | null>(null);
    const resumeDownloadState = useAppSelector((state) => selectSingleResumeFileStateById(params.id)(state))
    useEffect(() => {
        if (resumeDownloadState.loading === LoadingType.succeeded && resumeDownloadState.response) handleDownload(resumeDownloadState.response);
        return () => { }
    }, [resumeDownloadState.loading])
    useEffect(() => {
        if (assignmentManageState?.create?.loading === LoadingType.succeeded || assignmentManageState?.update?.loading === LoadingType.succeeded) {
            dispatch(getTalentById(params.id));
        }
    }, [assignmentManageState?.create?.loading, assignmentManageState?.update?.loading])
    function handleIndexChange(value: number) {
        setTabIndex(value);
        if (value === 5) {
            dispatch(textEmAllSliceActions.clearState());
        }
        dispatch(textEmAllSliceActions.clearState());

    }
    useEffect(() => {
        const state = location.state as any;

        if (state && state["redirectFrom"]) {
            setTabIndex(5)
        }
    }, [location]);
    /// JOB Matches
    const [jobSearch, setJobSearch] = useState<string>('');
    const [jobSortedField, setJobSortedField] = useState<string | null>(null);
    const profile = useAppSelector((state) => selectProfileState(state));
    function handleJobSortFieldChange(value: string) {
        if (jobSortedField && jobSortedField === value) {
            setJobSortedField(null);
        } else {
            setJobSortedField(value);
        }
    }
    function getJobFilteredList(): ITalentJobMatch[] {
        let sortedList: ITalentJobMatch[] | undefined;
        if (jobSortedField != null) {
            sortedList = [...(talentState.jobMatches.data ?? [])].sort((a, b) => {
                if (a[jobSortedField] < b[jobSortedField]) {
                    return -1;
                }
                if (a[jobSortedField] > b[jobSortedField]) {
                    return 1;
                }
                return 0;
            });
        }
        return (sortedList ?? (talentState.jobMatches.data ?? [])).filter(doc => {
            const str = jobSearch.trim().toLowerCase();
            const jobTitleFilter = doc.job_title ? doc.job_title.trim().toLowerCase().includes(str) : false;
            const comapnyNameFilter = doc.company_name ? doc.company_name.trim().toLowerCase().includes(str) : false;
            const skillsFilter = doc.matched_skills ? doc.matched_skills.includes(str) : false;
            const distanceFilter = doc.requested ? doc.requested.toString().trim().toLowerCase().includes(str) : false;
            return jobTitleFilter || comapnyNameFilter || skillsFilter || distanceFilter;
        });
    }
    function getJobMatchedSkillsText(value: string[]) {
        let skills = "";
        value.map((doc, index) => {
            skills = skills + (skills.length > 0 ? ", " : "") + doc;
        });
        return (
            <span title={skills}>{skills.length > 17 ? skills.substring(0, 16) + "..." : skills}</span>
        )
    }
    function getJobMatches() {
        if (params && params.id != null && talentState.data != null) dispatch(getTalentJobMatches({ talentId: params.id, query: getTalentMatchQuery(talentState.data) }));
    }
    /// Company Matches
    const [companySearch, setCompanySearch] = useState<string>('');
    const [companySortedField, setCompanySortedField] = useState<string | null>(null);
    function handleCompanySortFieldChange(value: string) {
        if (companySortedField && companySortedField === value) {
            setCompanySortedField(null);
        } else {
            setCompanySortedField(value);
        }
    }
    function getCompanyFilteredList(): ITalentCompanyMatch[] {
        let sortedList: ITalentCompanyMatch[] | undefined;
        if (companySortedField != null) {
            sortedList = [...(talentState.companyMatches.data ?? [])].sort((a, b) => {
                if (a[companySortedField] < b[companySortedField]) {
                    return -1;
                }
                if (a[companySortedField] > b[companySortedField]) {
                    return 1;
                }
                return 0;
            });
        }
        return (sortedList ?? (talentState.companyMatches.data ?? [])).filter(doc => {
            const str = companySearch.trim().toLowerCase();
            const comapnyNameFilter = doc.company_name ? doc.company_name.trim().toLowerCase().includes(str) : false;
            const skillsFilter = doc.matched_skills ? doc.matched_skills.includes(str) : false;
            // const distanceFilter = doc.requested ? doc.requested.toString().trim().toLowerCase().includes(str) : false;
            return comapnyNameFilter || skillsFilter;
        });
    }

    function getCompanyMatches() {
        if (params && params.id != null && talentState.data != null) dispatch(getTalentCompanyMatches({ talentId: params.id, query: getTalentMatchQuery(talentState.data) }));
    }

    useEffect(() => {
        /// Get Talent Details
        getTalentDetails();
        // dispatch(apolloGetCreditsThunk());

        return () => {
            dispatch(resumeFileSliceActions.clearResumeFileState(params.id))
        }
    }, [])



    function getTalentDetails() {
        if (params && params.id != null) {
            setBranchId(null);
            dispatch(getTalentById(params.id));
            dispatch(getTrafficLightEngagementMetrics());
            dispatch(talentRequestSSNActions.initialiseRequestSSN(params.id));
        }

    }

    // const [availCredits, setAvailCredits] = useState<IApolloCredits | null>();

    // useEffect(() => {
    //     if (apolloCreditsState && apolloCreditsState.loading === LoadingType.succeeded && apolloCreditsState.response) {
    //         setAvailCredits(apolloCreditsState.response)
    //     }
    // }, [apolloCreditsState, apolloCreditsState.loading])
    function handleBranchChange(value: string) {
        if (value !== '') {
            history.push({
                pathname: `${location.pathname}/${value}`,
                state: {
                    ...(location.state as any),
                    departmentId: value,
                    departmentName: value,
                }
            });
        } else {
            history.push({
                pathname: `${AppRoutes.companiesDetailsPage}/${params.id}/dashboard`,
                state: {
                    ...(location.state as any),
                    departmentId: null,
                    departmentName: null,
                }
            });
        }

    }

    function handleOnCompanySelect(value: ITalentJobMatch | ITalentCompanyMatch) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            },
        });
        return;
    }
    function handleOnJobSelect(value: ITalentJobMatch) {
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${value.id}`,
            state: {
                id: value.id,
                name: value.job_title,
            },
        });
        return;
    }

    /// [Navigation update when details edited]
    useEffect(() => {
        if (talentState.loading === LoadingType.succeeded) {
            updateNavigationNames();
        }
        return () => { }
    }, [talentState.loading])


    function updateNavigationNames() {
        if (params) {
            if (params.id != null && talentState.data != null) {
                const talent = talentState.data;
                history.replace({
                    pathname: location.pathname,
                    state: {
                        ...(location.state as any),
                        name: `${talent.first_name ?? ""} ${talent.middle_name ?? ""} ${talent.last_name ?? ""}`,
                    }
                });
            }

        }

    }
    function hanleOnResume(path: string) {
        dispatch(getResumeFileUrlForDownload({ id: params.id, file_path: path }));
    };

    const handleTerminateTalent = (selectedDate: number) => {
        if (params.id && selectedDate) {
            dispatch(getTerminateTalent({ talent_id: params.id, termination_date: selectedDate }))
        }
    }

    const handleClearError = () => {
        dispatch(clearTalentTerminationStateError())
    }
    const handleCloseSuccess = () => {
        setTerminateTalentPopUp(false)
        dispatch(getTalentById(params.id));
        dispatch(clearTalentTerminationState())
        dispatch(clearTalentTerminationStateError())
    }
    // const [showRefreshPopup, setShowRefreshPopup] = useState<boolean>(false);

    // useEffect(() => {
    //     if (talentRefreshState && talentRefreshState.refreshTalent.loading === LoadingType.succeeded) {
    //         setTimeout(() => (setShowRefreshPopup(false)), 1000);
    //         dispatch(postSnackbarMessage(talentRefreshState.refreshTalent.response.msg ?? null));
    //         handleClearError();
    //         getTalentDetails();
    //     }
    // }, [talentRefreshState, talentRefreshState.loading])
    // function handleOnRefreshTalent(need_direct_dial: boolean) {
    //     dispatch(refreshApolloTalent({ talent_id: params.id, need_direct_dial: need_direct_dial }));
    // }
    // function handleClearError() {
    //     dispatch(apolloRefreshActions.clearApolloRefreshTalentState());
    // }

    const handleClearRemoveOptionError = () => {
        dispatch(talentDetailsSliceActions.clearTalentDetailsRemoveOptionError({id: params.id, type: removeOption?.type!}))
    }

    return (
        <StateChangeHandler
            loading={talentState.loading}
            error={talentState.error}
            onRefresh={getTalentDetails}
        >
            <div className="tdp-container">
                <div className="tdp-left-panel">
                    <TalentDetailsSection
                        talent={talentState.data}
                        onActionClick={handleActionPress}
                        onBranchChange={handleBranchChange}
                        onSSNClick={handleOnSSNView}
                        onResumeClick={hanleOnResume}
                        // onShowRefreshPopup={setShowRefreshPopup}
                        // availCredits={availCredits?.credits_available}
                    />
                </div>
                <div className="tdp-right-panel">
                    <CurveTabNavigation
                        tabs={getTabList()}
                        selected={tabIndex}
                        onChange={handleIndexChange}
                        actions={getEditAction()}
                        customStyle={{ height: 'fit-content' }}
                    />
                    {/* <MatchHolder
                        title={t("job_matches").toUpperCase()}
                        searchPlaceHolder={t("search_for_more_job_matches")}
                        search={jobSearch}
                        onSearchChange={setJobSearch}
                        onSearch={() => { }}
                    ><></>
                    </MatchHolder> */}
                    {/* <div className="tdp-job-match">
                        {getJobMatchesTable()}
                    </div> */}
                    <MatchHolder
                        title={t("company_matches").toUpperCase()}
                        searchPlaceHolder={t("search_for_more_company_matches")}
                        search={companySearch}
                        onSearchChange={setCompanySearch}
                        onSearch={() => { }}
                    ><></>
                    </MatchHolder>
                    <div className="tdp-comp-match">
                        {getCompanyMatchesTable()}
                    </div>
                </div>
            </div>
            {editTalent && (
                <Portal>
                    <CreateTalentPopup
                        visible={editTalent.visible}
                        talent={editTalent.data}
                        title={t('edit_talent')}
                        successTitle={t('edit_talent')}
                        onClose={() => setEditTalent(null)}
                        onSuccessClose={handleUpdateTalentSuccess}
                        onGotoAgencyDashboard={() => { }}
                        onGotoCreated={() => { }}
                        showSSN={profileState?.profile?.show_ssn}
                    />
                </Portal>
            )}

            {addOption && (
                <Portal>
                    <CreateOption
                        talent_id={params.id}
                        visible={addOption.visible}
                        type={addOption.type}
                        onClose={handleCloseAddDialog}
                        onDissmiss={handleCloseAddDialog}
                        onSuccessClose={handleOnSuccessAddDialog}
                        talentState={talentState?.data!}
                    />
                </Portal>
            )}
            {removeOption && <Portal>
                <RemoveOptionPopup
                    id={params.id}
                    state={talentState}
                    data={removeOption.value}
                    type={removeOption.type}
                    sourceType={"Talent"}
                    visible={removeOption.visible}
                    onClose={handleCloseRemoveDialog}
                    onDissmiss={handleCloseRemoveDialog}
                    onSuccessClose={handleOnSuccessRemoveDialog}
                    onClearError={handleClearRemoveOptionError}
                />
            </Portal>}
            {showAssignmentPopUp && (
                <CreateAssignmentPopup
                    callbackHandler={() => setShowAssignmentPopUp(false)}
                    assignmentInfo={Object.create({})}
                    selectedJobTalent={talentState.data}
                    isFromJobs={false}
                    showModal
                />
            )}

            {showAvailabilityPopup && (
                <Portal>
                    <AvailabiltyPopup
                        visible={showAvailabilityPopup.visible}
                        title={t('availability')}
                        talentId={params.id}
                        name={showAvailabilityPopup.name}
                        currentLoginUserName={showAvailabilityPopup.loginUserName}
                        date={showAvailabilityPopup.selectedDate}
                        onClose={() => setShowAvailabilityPopup(null)}
                        onDissmiss={() => setShowAvailabilityPopup(null)}
                        onSuccessClose={handleOnSuccessAvailablityClose}
                    />
                </Portal>
            )}

            {showMarkAvailabilityPopup && (
                <Portal>
                    <AvailabiltyPopup
                        visible={showMarkAvailabilityPopup.visible}
                        title={t('availability')}
                        talentId={params.id}
                        name={showMarkAvailabilityPopup.name}
                        currentLoginUserName={showMarkAvailabilityPopup.loginUserName}
                        date={showMarkAvailabilityPopup?.selectedDate}
                        onClose={() => setShowMarkAvailabilityPopup(null)}
                        onDissmiss={() => setShowMarkAvailabilityPopup(null)}
                        onSuccessClose={handleOnSuccessAvailablityClose}
                    />
                </Portal>
            )}

            {showSSNPopup && (
                <Portal>
                    <ViewSSNPopup
                        visible={showSSNPopup.visible}
                        title={t('social_security_number')}
                        talentId={params.id}
                        onClose={() => setShowSSNPopup(null)}
                        onDissmiss={() => setShowSSNPopup(null)}
                        onSuccessClose={handleOnSuccessSSNClose}
                    />
                </Portal>
            )}
            {terminateTalentPopUp && (
                <Portal>
                    <TerminateTalentPopup 
                        visible={terminateTalentPopUp} 
                        headerTitle={'terminate_talent'}
                        state={terminateTalentState}
                        talentName={`${talentState.data?.first_name ?? ""} ${talentState.data?.middle_name ?? ""} ${talentState.data?.last_name ?? ""}`}
                        onClose={handleCloseSuccess}
                        onApprove={handleTerminateTalent}
                        onClearError={handleClearError}
                    />
                </Portal>
            )}
            {/* {showRefreshPopup && (
                <Portal>
                    <ApolloRefreshPopup
                        visible={showRefreshPopup}
                        title={t('refresh_talent')}
                        paramId={params.id}
                        onClose={() => { setShowRefreshPopup(false); handleClearError() }}
                        onDissmiss={() => { setShowRefreshPopup(false); handleClearError() }}
                        onSuccessClose={handleOnRefreshTalent}
                        importedDirectDial={talentState?.data?.direct_dial}
                        name={`${talentState.data?.first_name ?? ''} ${talentState.data?.middle_name ?? ''} ${talentState.data?.last_name ?? ''}`}
                        refreshState={talentRefreshState.refreshTalent}
                        onClearError={handleClearError}

                    />
                </Portal>
            )}
            {addCredit && (
                <Portal>
                    <AddCreditsPopup
                        visible={addCredit}
                        onClose={() => setAddCredit(false)}
                        loading={apolloCreditsState.addCredits.loading}
                        handleAddCredits={handleAddCredits}
                        error={apolloCreditsState.addCredits.error?.message}
                    />
                </Portal>
            )
            } */}
        </StateChangeHandler>
    );

    function getTabList() {
        return [
            {
                title: t("skill_sets").toUpperCase(),
                icon: SkillsSummaryIcon,
                content: (
                    <CompanySummery
                        onAddOption={handleAddOption}
                        onRemoveOption={handleRemoveOption}
                        customStyle={{
                            overflow: "auto",
                            height: "20.3125vw",
                        }}
                    />
                ),
            },
            {
                title: t("engagement_details").toUpperCase(),
                icon: EngagementIcon,
                content: (
                    <EngagementDetails
                        id={params.id}
                        source={"TALENT"}
                        onSuccessCreate={getTalentDetails}
                        customStyle={{
                            overflow: "auto",
                            height: "20.3125vw",
                        }}
                    />
                ),
            },
            {
                title: t("availability").toUpperCase(),
                icon: CalenderIcon,
                content: (
                    <AvailabilityTab
                        talent={talentState.data!}
                        onAddAvailability={handleAddAvailability}
                        onMarkedAlert={handleTalentAvailableDatesMarkedAlert}
                    />
                ),
            },
            {
                title: t("staffing_representatives", {
                    value: profile.profile?.agency_name,
                }).toUpperCase(),
                icon: StaffIcon,
                content: (
                    <TalentStaffDetails
                        onAddOption={handleAddOption}
                        onRemoveOption={handleRemoveOption}
                        id={params.id}
                        customStyle={{
                            overflow: "auto",
                            height: "20.3125vw",
                        }}
                    />
                ),
            },
            {
                title: t("ratings").toUpperCase(),
                icon: RatingIcon,
                content: (
                    <RatingsTab
                        id={params.id}
                        source={"talent"}
                        onSuccessCallback={getTalentDetails}
                        customStyle={{
                            overflow: "auto",
                            height: "20.3125vw",
                        }}
                    />
                ),
            },
            {
                title: t("text_em_all").toUpperCase(),
                icon: RatingIcon,
                content: (
                    <TextEmAllTab
                        talent_id={params.id}
                        talent_phone={talentState?.data?.phone}
                    />
                ),
            },
        ];
    }

    /// JOB Matches table
    function getJobMatchesTable() {
        const state = talentState.jobMatches;
        if (state.loading === LoadingType.pending) {
            return (<TableLoading />);
        }
        if (state.error != null && state.data?.length === 0) {
            return (
                <TableErrorHandler error={state.error} onRefresh={getJobMatches} />
            );
        }
        if (state.data != null && state.data.length === 0) {
            return (
                <TableEmpty title={t('no_job_matches_found')} onClick={getJobMatches} />
            );
        }
        if (getJobFilteredList().length === 0) {
            return (
                <TableFilterEmpty title={t('no_job_matches_found')} />
            );
        }

        return (
            <SortableTable
                headerList={jobMatchesTableHeader}
                sortedField={jobSortedField}
                onSortChange={handleJobSortFieldChange}
                flexNumber={getJobMatchesFlexNumber}
            >
                {getJobFilteredList().map((doc, index) => {
                    return (
                        <tr key={index} onClick={() => { }}>
                            {/* <TableData customStyle={{ flex: getJobMatchesFlexNumber(0) }}>{getTableAccessIcon(() => { })}</TableData> */}
                            <TableData customStyle={{ flex: getJobMatchesFlexNumber(0) }} isButton={doc.job_title ? true : false} onClick={() => handleOnJobSelect(doc)}><span>{doc.job_title}</span></TableData>
                            <TableData customStyle={{ flex: getJobMatchesFlexNumber(1) }} isButton={doc.company_name ? true : false} onClick={() => handleOnCompanySelect(doc)}><span>{doc.company_name}</span></TableData>
                            <TableData customStyle={{ flex: getJobMatchesFlexNumber(2) }}>{getJobMatchedSkillsText(doc.matched_skills)}</TableData>
                            <TableData customStyle={{ flex: getJobMatchesFlexNumber(3) }}>{getEngagementRating(doc.engagement_rating, "COMPANY")}</TableData>
                            <TableData customStyle={{ flex: getJobMatchesFlexNumber(4), color: THEME.statusInActiveColor }}><span>{doc.requested ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getJobMatchesFlexNumber(5), color: THEME.buttonColor17 }}><span>{doc.placed ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getJobMatchesFlexNumber(6), color: THEME.statusInActiveColor }}><span>{doc.over_under_placed ?? ''}</span></TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }

    function getJobMatchesFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1.2;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        //    if (value === 7) return 1;
        return 1;
    }

    function getEngagementRating(rating: number, source: string) {
        const ratingtype: any = trafficeMetrics.filter((h: any) => rating >= h.range_from && rating <= (h.range_to === null ? rating : h.range_to) && h.category === source)
        return (
            <div style={{ textAlign: "center" }}>
                <NotificationCircle
                    value={rating}
                    onClick={() => { }}
                    customStyle={{
                        backgroundColor: getRatingBackgrounColor(ratingtype.length > 0 ? ratingtype[0].type : "LOW"),
                        width: '1.756vw',
                        height: '1.756vw',
                    }}
                    textStyle={{ color: THEME.white, fontSize: '0.878vw', }}
                />
            </div>
        )
    }

    /// [Company] Matches
    function getCompanyMatchesTable() {
        const state = talentState.companyMatches;
        if (state.loading === LoadingType.pending) {
            return (<TableLoading />);
        }
        if (state.error != null && state.data?.length === 0) {
            return (
                <TableErrorHandler error={state.error} onRefresh={getCompanyMatches} />
            );
        }
        if (state.data != null && state.data.length === 0) {
            return (
                <TableEmpty title={t('no_company_matches_found')} onClick={getCompanyMatches} />
            );
        }
        if (getCompanyFilteredList().length === 0) {
            return (
                <TableFilterEmpty title={t('no_company_matches_found')} />
            );
        }

        return (
            <SortableTable
                headerList={companyMatchesTableHeader}
                sortedField={companySortedField}
                onSortChange={handleCompanySortFieldChange}
                flexNumber={getFlexNumber}
            >
                {getCompanyFilteredList().map((doc, index) => {
                    return (
                        <tr key={index} onClick={() => { }}>
                            {/* <TableData customStyle={{ flex: getFlexNumber(0) }}>{getTableAccessIcon(() => { })}</TableData> */}
                            <TableData customStyle={{ flex: getFlexNumber(0) }} isButton={doc.company_name ? true : false} onClick={() => handleOnCompanySelect(doc)}><span>{doc.company_name}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(1) }}>{getJobMatchedSkillsText(doc.matched_skills)}</TableData>
                            <TableData customStyle={{ flex: getFlexNumber(2) }}>{getEngagementRating(doc.engagement_rating, "COMPANY")}</TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1.2;
        if (value === 2) return 1;
        //  if (value === 3) return 1;
        return 1;
    }


    /// close add option dialog
    function handleCloseAddDialog() {
        setAddOption(null);
    }

    /// Add option
    function handleAddOption(type: string) {
        setAddOption({ visible: true, type: type });
    }

    /// Add on success add option
    function handleOnSuccessAddDialog() {
        if (addOption) {
            dispatch(talentDetailsSliceActions.clearTalentDetailsAddOptionError({ id: params.departmentId ?? params.id, type: addOption?.type }));
            dispatch(manageTalentActions.clearUpdateTalentState());
            setAddOption(null);
            getTalentDetails();
        }
    }

    /// Remove Option
    function handleRemoveOption(type: string, value: { id: string, value: string }) {
        setRemoveOption({ visible: true, type: type, value: value });
    }

    /// close add option dialog
    function handleCloseRemoveDialog() {
        setRemoveOption(null);
    }

    /// Add on success add option
    function handleOnSuccessRemoveDialog() {
        if (removeOption) {
            dispatch(talentDetailsSliceActions.clearTalentDetailsRemoveOptionError({ id: params.departmentId ?? params.id, type: removeOption?.type }));
            setRemoveOption(null);
            getTalentDetails();
        }
    }

    /// Edit icon actions
    function getEditAction() {
        return (
            <button className="icon-btn tdp-edit-icon" onClick={handleEdit}>
                <EditIcon width={"100%"} height={"100%"} style={{ color: '#fff' }} />
            </button>
        );
    }


    function handleEdit() {
        // if (talentState.data != null && talentState.data.type === COMPANY_TYPE.company) {
        setEditTalent({ visible: true, data: talentState.data! });
        // }
        // if (talentState.data != null && talentState.data.type === COMPANY_TYPE.department) {
        //     setEditDepartment({ visible: true, data: talentState.data });
        // }
    }

    ///Add user action
    function handleUpdateTalentSuccess() {
        setEditTalent(null);
        getTalentDetails();
        dispatch(manageTalentActions.clearManageTalentState());
    }


    function handleActionPress(value: string) {

        if (value === "assign_to_job_order") {
            setShowAssignmentPopUp(true);
        }
        if (value === "active_assignments") {
            history.push({
                pathname: `${AppRoutes.talentDetailsPage}/${params.id}/assignments`,
                state: {
                    ...(location.state as any),
                }
            })
        }
        if (value === "mark_talent_available") {
            // if (talentState.data?.dates_available.includes(convertDateToTimeStamp(new Date()))) {
            //     dispatch(postSnackbarMessage(t('talent_already_marked_as_availbale')));
            // }
            // else {
            setShowMarkAvailabilityPopup({
                visible: true,
                talentId: params.id,
                selectedDate: convertDateToTimeStamp(new Date()),
                name: `${talentState.data?.first_name ?? ''} ${talentState.data?.middle_name ?? ''} ${talentState.data?.last_name ?? ''}`,
                loginUserName: `${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`
            });
            // }
        }
        if (value === "send_message_to_talent") {
            setTabIndex(5);
        }
        if (value === "terminate_talent") {
            setTerminateTalentPopUp(true);
        }
    }


    /// [Availability popup]

    function handleAddAvailability(value: number) {
        setShowAvailabilityPopup({
            visible: true,
            talentId: params.id,
            selectedDate: value,
            name: `${talentState.data?.first_name ?? ''} ${talentState.data?.middle_name ?? ''} ${talentState.data?.last_name ?? ''}`,
            loginUserName: `${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`
        });
    }

    function handleOnSuccessAvailablityClose() {
        setShowAvailabilityPopup(null);
        setShowMarkAvailabilityPopup(null);
        dispatch(talentAvailableDatesActions.clearSingleTalentAvailableDatesManageState(params.id));
        getTalentDetails();
    }

    function handleTalentAvailableDatesMarkedAlert() {
        dispatch(postSnackbarMessage(t('talent_already_marked_as_availbale')));
    }


    /// [SSN View]
    function handleOnSSNView() {
        if (profileState.profile && profileState.profile.show_ssn) {
            setShowSSNPopup({ visible: true, talentId: params.id });
        } else {
            dispatch(postSnackbarMessage(t('ssn_access_is_disabled')));
        }

    }

    function handleOnSuccessSSNClose() {
        setShowSSNPopup(null);
        // dispatch(talentAvailableDatesActions.clearSingleTalentAvailableDatesManageState(params.id));
    }
}

interface MatchProps {
    title: string,
    children: React.ReactNode,
    search: string,
    searchPlaceHolder?: string,
    onSearchChange: (value: string) => void,
    onSearch: () => void,
}

const MatchHolder: React.FunctionComponent<MatchProps> = (props) => {
    return (
        <div className="tdp-match-container">
            <div className="tdp-header">
                <div className="tdp-header-title">
                    <span>{props.title}</span>
                </div>
                <div className="tdp-header-search">
                    <SearchBar
                        placeholder={props.searchPlaceHolder}
                        value={props.search}
                        onChange={props.onSearchChange}
                        onSearch={props.onSearch}
                    />
                </div>
            </div>
            <div className="tdp-content">
                {props.children}
            </div>
        </div>
    );
}


export default TalentDashboardPage;
import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./thirty_day_calls_report_thunk";
import { IReportCenterService } from "../../../../interfaces/report_center_service";

export interface ThirtyDayCallsReportsListState extends EntityState<IReportCenterService> {
    error?: AppError | null,
    loading: LoadingType,  
}

const thirtyDayCallsReportsListAdapter: EntityAdapter<IReportCenterService> = createEntityAdapter<IReportCenterService>({
    selectId: (doc) => doc.id,
})

const initialState: ThirtyDayCallsReportsListState = thirtyDayCallsReportsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const thirtyDayCallsReportsListSlice = createSlice({
    name: 'thirtyDayCallsReportsList',
    initialState: initialState,
    reducers: {
        clearThirtyDayCallsReportsListError(state) {
            return { ...state, error: null };
        },
        clearThirtyDayCallsReportsListState(state) {
            return thirtyDayCallsReportsListAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getThirtyDayCallsReportsList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getThirtyDayCallsReportsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getThirtyDayCallsReportsList.fulfilled, (state, action) => thirtyDayCallsReportsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearThirtyDayCallsReportsListError, clearThirtyDayCallsReportsListState } = thirtyDayCallsReportsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = thirtyDayCallsReportsListAdapter.getSelectors();
export const selectState = (state: ThirtyDayCallsReportsListState) => state;



import { useEffect, useState } from 'react';
import EditIcon from '../../../../assets/icons/edit_button.svg';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg';
import { LoadingType, THEME } from "../../../enums";
import { CustomButton, getPlusBtnIcon } from "../../../utils";
import { useTranslation } from "react-i18next";
import TabOptionsHeader from '../../../components/tab_options_header/tab_options_header';
import SortableTable, { TableData } from '../../../components/sortable_table/sortable_table';
import {
    createCompanyCategory, getCompanyCategories, updateCompanyCategory, deleteCompanyCategory,
    selectCompanyCategoryList,
    selectCompanyCategoryState
} from "../../../redux/admin_center";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../redux/store";
import './system_wide_page.scss';
import { Portal } from "react-portal";
import '../../../custom.scss';
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../components/table_empty/table_empty";
import CustomFormSelect from '../../../components/form_selector/form_select';
import { DeleteConfirmationDialog } from '../../components/delete_confirmation_dialog';
import ContactsAddPopup from './popups/contacts_add_popup/contacts_add_popup';
import { companyCategoryActions } from '../../../redux/admin_center/system_configuration/company_categories/company_categories_reducer';

const tableHeader = [
    { title: 'value', code: 'actions' },
    { title: 'actions', code: 'actions' },
];

const categories = {
    1: {
        label: "company_categories", value: ["value"], buttonLabel: "add_company_category",
        inputLabel: "company_category", deleteLabel: "delete_company_category",
        deleteMessage: "delete_company_category_message", updateLabel: "update_company_category",
        methods: {
            create: createCompanyCategory, update: updateCompanyCategory, remove: deleteCompanyCategory,
            get: getCompanyCategories
        },
        headers: tableHeader,
        deleteAlertMessage: "Company Category Deleted Successfully!",
        clearCreateState: companyCategoryActions.clearCompanyCategoryCreateState,
        clearUpdateState: companyCategoryActions.clearCompanyCategoryUpdateState,
        clearDeleteState: companyCategoryActions.clearCompanyCategoryDeleteState,
        clearDeleteStateError: companyCategoryActions.clearCompanyCategoryDeleteStateError,
    }
}

const CompaniesPage = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<{ visible: boolean, title: string } | null>(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [methodValue, setMethodValue] = useState({ 'id': '', 'value': '' });
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [dropdownChanged, setDropDownChanged] = useState(true);

    const copanyCategoryState = useAppSelector((state) => selectCompanyCategoryState(state));
    const companyCategoriesList = useAppSelector((state) => selectCompanyCategoryList(state));

    const reduxMapping = { 1: { state: copanyCategoryState, list: companyCategoriesList } }

    useEffect(() => {
        if (getMapping()?.state?.create?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.create?.response));
            dispatch(getCategory()?.clearCreateState())
        }
        else if (getMapping()?.state?.update?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.update?.response));
            dispatch(getCategory()?.clearUpdateState())
        }
        else if (getMapping()?.state?.remove?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.remove?.response));
            dispatch(getCategory()?.clearDeleteState())
        }
    }, [
        getMapping()?.state?.create?.loading,
        getMapping()?.state?.update?.loading,
        getMapping()?.state?.remove?.loading
    ]);

    function getCategory(id = null) {
        if (id)
            return categories[id];
        return categories[selectedCategory];
    }

    function getMapping(id = null) {
        if (id)
            return reduxMapping[id];
        return reduxMapping[selectedCategory];
    }


    function handleCreateCloseDialog() {
        setShowCreateDialog(false);
    }

    function handleCloseDeleteDialog() {
        setShowDeleteDialog(null);
        setDeleteItemId(null);
    }

    function handleShowDeleteDialog(id, title) {
        setShowDeleteDialog({ visible: true, title: title });
        setDeleteItemId(id);
    }

    function handleDeleteApprove() {
        if (deleteItemId) {
            dispatch(getCategory().methods.remove(deleteItemId));
        }
        handleCloseDeleteDialog();
    }

    function handleShowEditDialog(method) {
        setShowEditDialog(true);
        setMethodValue({ ...methodValue, 'id': method.id, 'value': method[getCategory().value] });
    }

    function handleCloseEditDialog() {
        setShowEditDialog(false);
    }

    function handleDropdownChange(value) {
        if (!value)
            value = '1';
        setDropDownChanged(true);
        setSelectedCategory(Number(value));
        dispatch(getCategory(value).methods.get());
    }

    function retryFetchingData() {
        setDropDownChanged(true);
        dispatch(getCategory().methods.get());
    }

    useEffect(() => {
        if (getMapping().state.loading == LoadingType.succeeded) {
            setDropDownChanged(false);
        }
    }, [getMapping().state.loading])

    useEffect(() => {
        if (getMapping().state.remove.loading == LoadingType.succeeded)
            dispatch(getCategory().methods.get());
    }, [getMapping().state.remove.loading])

    useEffect(() => {
        dispatch(getCategory().methods.get());
        return () => {

        }
    }, [])

    return (
        <div className="ac-sc-container">
            <div className="ac-sc-header">
                <div className="ac-sytm-cns-header">
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="ac-sc-dropdown">
                <div className="ac-sc-dropdown-button">
                    <CustomFormSelect
                        name={t("companies_dropdown")}
                        list={Object.keys(categories).map(id => ({ value: id, label: categories[id].label }))}
                        onChange={(val) => handleDropdownChange(val)}
                        value={selectedCategory}
                        placeholder={t('company_categories')}
                        required={false}
                        label={t('please_select_category')}
                        customStyle={{ marginTop: "0 !important", maxWidth: "20vw" }}
                    />
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            enable={true}
                            leftIcon={getPlusBtnIcon()}
                            name={t(categories[selectedCategory].buttonLabel)}
                            backgroundColor={THEME.buttonColor1}
                            onClick={() => (setShowCreateDialog(true))}
                            customStyle={{ width: "max-content" }}
                        />
                    </div>
                </div>
            </div>
            <div className="ac-sc-table">
                {getMapping().state.loading == LoadingType.pending && dropdownChanged ? <TableLoading /> :
                    <TableBody
                        state={getMapping().state}
                        onRetry={retryFetchingData}
                        data={getMapping().list}
                        category={getCategory()}
                        handleShowEdit={handleShowEditDialog}
                        handleShowDelete={handleShowDeleteDialog}
                    />
                }
            </div>
            {showCreateDialog && <Portal>
                <ContactsAddPopup
                    visible={showCreateDialog}
                    onClose={handleCreateCloseDialog}
                    onDismiss={handleCreateCloseDialog}
                    category={getCategory()}
                    category_id={selectedCategory}
                    reduxMapping={reduxMapping}
                    errorState={reduxMapping[selectedCategory]?.update?.error}
                />
            </Portal>}
            {showDeleteDialog && (
                <Portal>
                    <DeleteConfirmationDialog
                        message={t(getCategory()?.deleteMessage, { value: showDeleteDialog?.title })}
                        onCancel={() => setShowDeleteDialog(null)}
                        onConfirm={handleDeleteApprove}
                        deleting={getMapping()?.state?.remove === LoadingType.pending}
                        deleteType={""}
                        customDeleteHeaderTitle={t(getCategory()?.deleteLabel)}
                        state={getMapping()?.state}
                        onClearError={getCategory()?.clearDeleteStateError()}
                    />
                </Portal>
            )}
            {showEditDialog && <Portal>
                <ContactsAddPopup
                    visible={showEditDialog}
                    onClose={handleCloseEditDialog}
                    onDismiss={handleCloseEditDialog}
                    methodValue={methodValue}
                    category={getCategory()}
                    category_id={selectedCategory}
                    reduxMapping={reduxMapping}
                    errorState={reduxMapping[selectedCategory]?.update?.error}
                />
            </Portal>}
        </div>

    )
}

const TableBody = ({ state, onRetry, data, category, handleShowEdit, handleShowDelete }) => {
    if (state.loading == LoadingType.failed) {
        return (<TableErrorHandler error={state.error} onRefresh={onRetry} />)
    }
    else if (data.length == 0) {
        return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    }
    else {
        return (
            <SortableTable headerList={category.headers}>
                {data.map((doc, index) => {
                    return (
                        <tr key={doc.id}>
                            {category.value.map((value, index) => {
                                return (
                                    <TableData key={doc.id + index} customStyle={{ wordWrap: "break-word" }}>
                                        <span>{doc[value]}</span>
                                    </TableData>
                                )
                            })}
                            <TableData>
                                <img src={EditIcon} alt='edit_button' style={{ marginRight: '20px' }}
                                    onClick={() => handleShowEdit(doc)} />
                                <img src={DeleteIcon} alt='delete_button'
                                    onClick={() => handleShowDelete(doc.id, doc.value)} />
                            </TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }
}

export default CompaniesPage;

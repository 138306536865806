import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../../components/dialog_wrapper/dialog_wrapper";
import SearchBar from "../../../../../components/search_bar";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import TableEmpty, { TableLoading, TableErrorHandler, TableFilterEmpty } from "../../../../../components/table_empty/table_empty";
import { LoadingType, THEME } from "../../../../../enums";
import { PaperPlaneIcon, MarkTalentIcon } from "../../../../../icons";
import { useAppDispatch, useAppSelector, postSnackbarMessage, selectInBoundLeadsListState, getInBoundLeadsList, selectProfileState, saveInBoundLeadImport, saveInBoundLeadArchive } from "../../../../../redux/store";
import { AppRoutes } from "../../../../../routes";
import { CustomCheckBox, getTableAccessIcon, CustomButton, convertToPhoneFormat } from "../../../../../utils";
import { getDateString } from "../../../../../variables";
import SendInterviewCalendarInvitePopup from "../../../popups/send_interview_calendar_invite/send_interview_calendar_invite";
import { IInBoundLeads } from "../../../../../interfaces/inbound_leads";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import ApiError from "../../../../../components/api_error";
import { inBoundLeadsStateActions } from "../../../../../redux/dashboard/metrics/inbound_leads/inbound_leads_directory/inbound_leads_directory_reducer";
import "./inbound_leads_directory.scss";
import DocSavedText from "../../../../../components/doc_saved_text/doc_saved_text";

interface Props extends RouteComponentProps<any> {
    isArchiveDirectory: boolean;
}

const InboundLeadsDirectory: React.FunctionComponent<
    Props
> = (props) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");
    const [showImportModal, setShowImportModal] = useState<boolean>(false);
    const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [showInvite, setShowInvite] = useState(false);
    const [selectedRow, setSelectedRow] = useState<IInBoundLeads | null>(null);
    const [importType, setImportType] = useState<string>("");
    const [isHovering, setIsHovering] = useState<{ visible: boolean, id: string } | null>(null)
    const [inboundLeadsList, setInboundLeadsList] = useState<IInBoundLeads[] | null>(null);

    const inBoundLeadsListState = useAppSelector((state) =>
        selectInBoundLeadsListState(state)
    );
    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`;
    const importLoadingSuccessState = inBoundLeadsListState?.import?.loading === LoadingType.succeeded;
    const archiveLoadingSuccessState = inBoundLeadsListState?.archive?.loading === LoadingType.succeeded;

    const tableHeader = [
        ...(props.isArchiveDirectory ? [] : [{ title: "", code: "" }]),
        { title: "access_lead_sheets", code: "" },
        { title: "company_name", code: "company_name" },
        { title: "contact_name", code: "first_name" },
        { title: "business_goals", code: "business_goals" },
        { title: "status", code: "status" },
        { title: "email", code: "email" },
        { title: "phone_number", code: "contact_number" },
        { title: "date_received", code: "received_date" },
        ...(props.isArchiveDirectory ? [] : [{ title: "send_demo_invite", code: "" }]),
    ];

    useEffect(() => {
        getIBLeads()
        setImportType("")
        setSelectedItems(new Set())
    }, [props.isArchiveDirectory]);

    useEffect(() => {
        if (inBoundLeadsListState && inBoundLeadsListState?.loading === LoadingType.succeeded && inBoundLeadsListState?.response) {
            setInboundLeadsList(inBoundLeadsListState?.response);
        }
    }, [inBoundLeadsListState?.loading, inBoundLeadsListState?.response]);

    useEffect(() => {
        if (inBoundLeadsListState?.error != null && inboundLeadsList?.length != 0) {
            dispatch(postSnackbarMessage(inBoundLeadsListState?.error?.message));
        }
    }, [inBoundLeadsListState?.error]);

    const getIBLeads = () => {
        if (props.isArchiveDirectory) return dispatch(getInBoundLeadsList(true));
        return dispatch(getInBoundLeadsList(false));
    }

    const getFilteredList = () => {
        let list: IInBoundLeads[] = Array.isArray(inboundLeadsList) ? [...inboundLeadsList] : [];
        if (sortedField != null) {
            list.sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] != undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list?.filter((doc) => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(
                (val) => val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    };

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    const handleAccessLeadSelect = (value: IInBoundLeads): void => {
        props.history.push({
            pathname: `${AppRoutes.dashboardLeadsPage}/details/${value.id}`,
            state: {
                id: value.id,
                name:
                    value.first_name +
                    " " +
                    value.last_name,
            },
        });
    };

    function handleMouseOver(id) {
        setIsHovering({ id: id, visible: true });
    }
    function handleMouseOut() {
        setIsHovering(null);
    }

    const handleSuccessClose = () => {
        setShowImportModal(false)
        setShowArchiveModal(false)
        setSelectedItems(new Set());
        getIBLeads()
        dispatch(inBoundLeadsStateActions.clearInBoundLeadsImportState())
        dispatch(inBoundLeadsStateActions.clearInBoundLeadsArchiveState())
    }

    const handleGoToAgencydashboard = () => {
        props.history.push(AppRoutes.dashboardPage);
        dispatch(inBoundLeadsStateActions.clearInBoundLeadsImportState())
    }

    const getTable = () => {
        if (inBoundLeadsListState?.loading === LoadingType.pending) return <TableLoading />;
        if (inBoundLeadsListState?.error != null && inboundLeadsList?.length === 0) {
            return <TableErrorHandler error={inBoundLeadsListState?.error} onRefresh={getIBLeads} />;
        }
        if (inboundLeadsList?.length === 0) {
            return (
                <TableEmpty title={t("No Leads found")} onClick={getIBLeads} />
            );
        }
        if (getFilteredList()?.length === 0) {
            return <TableFilterEmpty title={t("No Leads found")} />;
        }
        if (getFilteredList()?.length !== 0) {
            return (
                <SortableTable
                    headerList={tableHeader}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {getFilteredList()?.map((doc) => {
                        // const business_goals = doc.goal1 !== "" ? doc.goal1 : (doc.goal2 !== "" ? doc.goal2 : doc.goal3);
                        return (
                            <tr key={doc.id}>
                                {!props.isArchiveDirectory && <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                    <CustomCheckBox
                                        name={""}
                                        title={""}
                                        checked={selectedItems.has(doc.id)}
                                        onClick={() => {
                                            const newSet = new Set(selectedItems);
                                            if (selectedItems.has(doc.id)) {
                                                newSet.delete(doc.id);
                                            } else {
                                                newSet.add(doc.id);
                                            }
                                            setSelectedItems(newSet);
                                            setSelectedRow(doc);
                                        }}
                                    />
                                </TableData>}
                                <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                    {getTableAccessIcon(() => handleAccessLeadSelect(doc))}
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                    <span>{doc.company_name ?? ""}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                    <span>
                                        {(doc?.first_name +
                                            " " +
                                            doc?.last_name)}
                                    </span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(4) }}>

                                    <div style={{ cursor: "pointer" }} onMouseOut={() => handleMouseOut()}
                                        onMouseOver={() => handleMouseOver(doc.id)}>
                                        <ul className="business-ul">
                                            <li> {(doc.goal1 && doc.goal1.length > 50) ? doc.goal1.slice(0, 40) + '...' : doc.goal1}</li>
                                            <li> {(doc.goal2 && doc.goal2.length > 50) ? doc.goal2.slice(0, 40) + '...' : doc.goal2}</li>
                                            <li> {(doc.goal3 && doc.goal3.length > 50) ? doc.goal3.slice(0, 40) + '...' : doc.goal3}</li>
                                        </ul>
                                    </div>
                                    {isHovering && (doc.id === isHovering.id) && (
                                        <div className="parent-position">
                                            <div className="child-position">
                                                <div className="action-list-title">
                                                    <span style={{ cursor: "pointer" }}>{doc.goal1}</span>
                                                    <span style={{ cursor: "pointer" }}>{doc.goal2}</span>
                                                    <span style={{ cursor: "pointer" }}>{doc.goal3}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                    <span>
                                        {doc.status ?? ""}
                                    </span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                    <span>
                                        {doc.email ?? ""}
                                    </span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                    <span>{convertToPhoneFormat(doc.contact_number) ?? "NA"}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                    <span>{getDateString(doc.received_date)}</span>
                                </TableData>
                                {!props.isArchiveDirectory && <TableData customStyle={{ flex: getFlexNumber(9),justifyContent:"center" }}>
                                    <PaperPlaneIcon
                                        height={20}
                                        width={20}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setSelectedRow(doc);
                                            setShowInvite(true);
                                        }}

                                    />
                                </TableData>}
                            </tr>
                        );
                    })}
                </SortableTable>
            );
        }
    };

    const getImportUI = () => {
        if (importLoadingSuccessState) {
            return (
                <div className="import-leads-success-message">
                    <DocSavedText>
                        <span>{t('well_done', { name: currentLoginUserName })}</span>
                        <span>{importType === "Company" ? t('inboun_leads_import_companies_success_message') : (importType === "Contact" ? t("inboun_leads_import_contacts_success_message") : t("inboun_leads_import_both_success_message"))}</span>
                    </DocSavedText>
                </div>
            )
        }
        return (
            <>
                <FormControl component="fieldset">
                    <RadioGroup aria-required aria-label="import" name="import" value={importType}
                        onChange={(event) => setImportType(event.target.value)} className="radio-group-div">
                        <FormControlLabel value="Company" control={<Radio />} label={t("Companies")} />
                        <FormControlLabel value="Contact" control={<Radio />} label={t("Contacts")} />
                        <FormControlLabel value="Both" control={<Radio />} label={t("Both")} />
                    </RadioGroup>
                </FormControl>
                {
                    inBoundLeadsListState?.import?.error && <div className="error-holder">
                        <ApiError message={inBoundLeadsListState?.import.error?.message} onClose={handleClearError} />
                    </div>
                }
            </>
        )
    }

    const getActions = () => {
        if (importLoadingSuccessState) {
            return (
                <>
                    <div className="btn-save">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('back_to_my_agency_dashboard')}
                            enable={true}
                            backgroundColor={THEME.optionDisableColor}
                            customStyle={{ marginRight: "1em" }}
                            onClick={handleGoToAgencydashboard}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('inbound_leads_directory')}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleSuccessClose}
                        />
                    </div>
                </>
            )
        }
        return (
            <>
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("cancel")}
                        enable
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => closePopup(toggle)}
                    />
                </div>
                <div className="btn-save">
                    <CustomButton
                        loading={inBoundLeadsListState.import.loading === LoadingType.pending}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("import")}
                        enable={importType !== ""}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={handleImport}
                    />
                </div>
            </>
        )
    }

    function getFlexNumber(value: number) {
        // if( value === 0 ) return 
        if (!props.isArchiveDirectory && value === 0) return 0.1;
        if (!props.isArchiveDirectory && value === 1) return 0.4;
        if (!props.isArchiveDirectory && value === 5) return 0.6;
        if (!props.isArchiveDirectory && value === 9) return 0.5;
        if (!props.isArchiveDirectory && value === 7) return 0.6;
        if (!props.isArchiveDirectory && value === 8) return 0.5;
        return 1;
    }

    const toggle = () => {
        setShowArchiveModal(false);
        setShowImportModal(false);
        setShowInvite(false);
        setImportType("")
        handleClearError()
    };

    const closePopup = (action?: () => void) => {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    };

    const handleImport = () => {
        dispatch(saveInBoundLeadImport({ lead_ids: Array.from(selectedItems), import_type: importType }))
    };

    const handleArchive = () => {
        dispatch(saveInBoundLeadArchive(Array.from(selectedItems)))
    };

    const handleClearError = () => {
        dispatch(inBoundLeadsStateActions.clearInBoundLeadsImportState())
        dispatch(inBoundLeadsStateActions.clearInBoundLeadsArchiveState())
    }

    return (
        <div className="cps-ud-container contacts-ud applications-received-ud db-inbound-leads">
            <div className="w-100">
                <div
                    className="p-3 inbound-leads-header"
                >
                    <div className="total-count">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                    <div className="apps-ud-actions">
                        <div className="apps-ud-search">
                            <SearchBar
                                value={search}
                                onChange={(value) => setSearch(value)}
                                onSearch={() => { }}
                            />
                        </div>
                        {!props.isArchiveDirectory && <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("import")}
                            enable={selectedItems.size > 0}
                            backgroundColor={"#00BE4B"}
                            onClick={() => setShowImportModal(true)}
                            customStyle={{ marginRight: "10px" }}
                        />}
                        {!props.isArchiveDirectory && <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("archive")}
                            enable={selectedItems.size > 0}
                            backgroundColor={"#B5BAC5"}
                            onClick={() => setShowArchiveModal(true)}
                        />}
                    </div>
                </div>
            </div>
            <div className="cps-ud-table">{getTable()}</div>
            {showImportModal && (
                <Portal>
                    <ActionDialogHolder
                        ref={actionRef}
                        visible={showImportModal}
                        wrapperWidth={"36%"}
                        onClose={toggle}
                        onDissmiss={toggle}
                    >
                        <DialogWrapper onClose={() => closePopup(toggle)}>
                            <div className="uf-container contact-form import-leads">
                                <div className="uf-header">
                                    <FormDialogTilteHeader
                                        title={importLoadingSuccessState ? t("Import") : t("would_you_like_to_import", {
                                            name: currentLoginUserName,
                                        })}
                                        titleIcon={
                                            <MarkTalentIcon width={"100%"} height={"100%"} />
                                        }
                                    />
                                </div>
                                <div className="uf-content import-leads-content">
                                    {getImportUI()}
                                </div>
                                <div className="uf-actions">
                                    {getActions()}
                                </div>
                            </div>
                        </DialogWrapper>
                    </ActionDialogHolder>
                </Portal>
            )}
            {showArchiveModal && (
                <Portal>
                    <ActionDialogHolder
                        ref={actionRef}
                        visible={showArchiveModal}
                        wrapperWidth={"33%"}
                        onClose={toggle}
                        onDissmiss={toggle}
                    >
                        <DialogWrapper onClose={() => closePopup(toggle)}>
                            <div className="uf-container contact-form archive-leads">
                                <div className="uf-header">
                                    <FormDialogTilteHeader
                                        title={t("inbound_leads")}
                                        titleIcon={
                                            <MarkTalentIcon width={"100%"} height={"100%"} />
                                        }
                                    />
                                </div>
                                {archiveLoadingSuccessState ?
                                    <div className="uf-content archive-leads-content">
                                        <DocSavedText>
                                            <span>
                                                <div><span className="aknw-name">{t('hey_name', { name: currentLoginUserName })}</span></div>
                                                <span className="info-txt">{selectedItems.size===1 ? 
                                                t('you_have_moved_items_to_archive_directory', { name: (selectedRow?.first_name + " " + selectedRow?.last_name) }):t('you_have_moved_the_selected_items_to_archive_directory')}</span>
                                            </span>
                                        </DocSavedText>
                                    </div>
                                    :
                                    <div className="uf-content archive-leads-content">
                                        <span style={{marginTop:"0.3em",marginBottom:"0.3em"}}>{ selectedItems.size===1 ? 
                                        t('are_you_sure_you_want_to_archive', { name: (selectedRow?.first_name + " " + selectedRow?.last_name) }):t('are_you_sure_you_want_to_archive_the_selected_inbound_leads')}</span>
                                    </div>
                                }
                                <div className="uf-actions">
                                    {archiveLoadingSuccessState ?
                                        <div className="btn-save">
                                            <CustomButton
                                                loading={false}
                                                textStyle={{ textTransform: "capitalize" }}
                                                name={t("ok")}
                                                enable
                                                backgroundColor={THEME.defaultHighLightColor}
                                                onClick={handleSuccessClose}
                                            />
                                        </div>
                                        :
                                        <>
                                            <div className="btn-cancel">
                                                <CustomButton
                                                    loading={false}
                                                    textStyle={{ textTransform: "capitalize" }}
                                                    name={t("No")}
                                                    enable
                                                    backgroundColor={THEME.defaultHighLightColor}
                                                    onClick={() => closePopup(toggle)}
                                                />
                                            </div>
                                            <div className="btn-save">
                                                <CustomButton
                                                    loading={false}
                                                    textStyle={{ textTransform: "capitalize" }}
                                                    name={t("Yes")}
                                                    enable
                                                    backgroundColor={THEME.defaultHighLightColor}
                                                    onClick={handleArchive}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </DialogWrapper>
                    </ActionDialogHolder>
                </Portal>
            )}
            {showInvite && selectedRow && (
                <Portal>
                    <ActionDialogHolder
                        ref={actionRef}
                        visible={showInvite}
                        wrapperWidth={"30%"}
                        onClose={toggle}
                        onDissmiss={toggle}
                    >
                        <DialogWrapper onClose={() => closePopup(toggle)}>
                            <SendInterviewCalendarInvitePopup
                                state={inBoundLeadsListState?.sendInvite}
                                setShowInvite={setShowInvite}
                                closePopup={closePopup}
                                inBoundLeadsSendInviteData={selectedRow}
                                isInBoundLeads={true}
                            />
                        </DialogWrapper>
                    </ActionDialogHolder>
                </Portal>
            )}
        </div>
    );
};

export default InboundLeadsDirectory;

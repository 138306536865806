import React, { useRef } from 'react'
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder'
import DialogWrapper, { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import { ApplicationReceivedIcon } from '../../../../icons';
import { t } from "i18next";
import { CustomButton } from '../../../../utils';
import { LoadingType, THEME } from '../../../../enums';
import DocSavedText from '../../../../components/doc_saved_text/doc_saved_text';

interface ArchiveRecruiting {
    currentLoginUserName: string,
    onboardingCandidate: string | undefined
    visible: boolean,
    loading : LoadingType,
    title : string,
    onToggle :()=>void,
    handleArchiveList : ()=>void
}

const ArchiveRecruiting: React.FunctionComponent<ArchiveRecruiting> = (props) => {
    const actionRef = useRef<ActionDialogHolderType>(null);
    
    const closePopup = (action?: () => void) => {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    };

    return (
        <div>
            <ActionDialogHolder
            ref={actionRef}
            visible={props.visible}
            wrapperWidth={"30%"}
            onClose={props.onToggle}
            onDissmiss={props.onToggle}>
            <DialogWrapper onClose={()=>closePopup(props.onToggle)}>
              <div className="uf-container contact-form archive-appl-sent">
                <div className="uf-header">
                  <FormDialogTilteHeader
                    title={props.title}
                    titleIcon={
                      <ApplicationReceivedIcon width={"100%"} height={"100%"} />
                    }
                  />
                </div>
                {props.loading === LoadingType.succeeded ? (
                  <div className="uf-content archive-appl-sent-content">
                    <DocSavedText>
                      <span>
                        <div>
                          <span className="aknw-name">
                          {t('hey_name',{name : props.currentLoginUserName}) as String}
                          </span>
                        </div>
                        {
                          <span className="info-txt">
                            {t('you_have_moved') + " " + props.onboardingCandidate + " " + t('to_the_archived_directory')}
                          </span>
                        }
                      </span>
                    </DocSavedText>
                  </div>
                ) : (
                  <div className="uf-content archive-appl-sent-content">
                    {
                      <span style={{marginTop:"0.3em",marginBottom:"0.3em"}}>
                        {t("are_you_sure_you_want_to_archive", {
                          name : props.onboardingCandidate
                        }) as String}
                      </span>
                    }
                  </div>
                )}
                <div className="uf-actions">
                  {props.loading === LoadingType.succeeded ? (
                    <div className="btn-save">
                      <CustomButton
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("ok")}
                        enable
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={props.onToggle}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="btn-cancel">
                        <CustomButton
                          loading={false}
                          textStyle={{ textTransform: "capitalize" }}
                          name={t("no")}
                          enable
                          backgroundColor={THEME.defaultHighLightColor}
                          onClick={()=>closePopup(props.onToggle)}
                        />
                      </div>
                      <div className="btn-save">
                        <CustomButton
                          loading={props.loading===LoadingType.pending}
                          textStyle={{ textTransform: "capitalize" }}
                          name={t("yes")}
                          enable
                          backgroundColor={THEME.defaultHighLightColor}
                          onClick={props.handleArchiveList}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </DialogWrapper>
          </ActionDialogHolder>
        </div>
    )
}

export default ArchiveRecruiting
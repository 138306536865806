import './comp_claim.scss';
import { IconButton, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ApiError from "../../../components/api_error";
import { FormDialogTilteHeader } from "../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../components/doc_saved_text/doc_saved_text";
import DragAndDrop from "../../../components/drag_and_drop/drag_and_drop";
import RenderInput from "../../../components/render_input/render_input";
import { LoadingType, THEME } from "../../../enums";
import { ClaimStatusIcon, CompaniesIcon, LockIcon } from "../../../icons";
import {
    AppError,
    FormInputOption,
    IAssignmentList,
    ICompaniesList,
    ITalentsList,
    UpdateICreateUnEmploymentClaim,
    UpdateICreateWorkCompClaim,

} from "../../../interfaces";
import {
    convertNumberToMoney,
    convertToSSNFormat,
    CustomButton,
    formatToMoney,
} from "../../../utils";
import {
    lowBtnStyle,
    outlineBtnStyle,
} from "../../../variables";

import {
    useAppDispatch, useAppSelector,
    getAssignmentDropdown, selectassignmentDropdownListEntities,
    selectCompaniesDropdownList, selectAssignmentDropdownList, selectTalentsDropdownList, selectTalentsDropdownListEntities, getTalentDropdown,
} from "../../../redux/store";
import { ICreateUnEmploymentClaim, IUnEmploymentClaimData } from "../../../interfaces";
import { assignmentDropdownListActions } from '../../../redux/selectors/assignment_list/assignment_list_reducer';

interface Props {
    claim?: IUnEmploymentClaimData;
    title: string;
    successTitle?: string;
    loading: LoadingType;
    currentLoginUserName?: string;
    error: AppError | null | undefined;
    onClose: () => void;
    onSubmit: (doc: { forceAdd: boolean; value: ICreateUnEmploymentClaim }) => void;
    onClearError: () => void;
    onGotoAgencyDashboard: () => void;
    onCreateNew: () => void;
    onGotoCreated: (id: string, name: string) => void;
    companiesList: ICompaniesList[],
    talentList: ITalentsList[],
    assignmentsList: IAssignmentList[],
    selectedDocument: Array<File> | null,
    onDocumentChange: (value: Array<File>) => void,
    onRemoveDocument: (index: number) => void,
    stateTalentData?: { talent_id: string; talent_ssn: string; employee_name: string };
}
const claimStatus = [
    {
        title: "OPEN",
        value: "OPEN"
    },
    {
        title: "CLOSED",
        value: "CLOSED"
    },];
const getUnemployementClaimFormData = (value?: IUnEmploymentClaimData): ICreateUnEmploymentClaim => {
    return {
        id: null,
        sequence_number: null,
        employee_name: "",
        claim_status: "OPEN",
        potential_charges: 0,
        cost_to_date: 0,
        claim_number: null,
        claim_date: null,
        date_closed: null,
        branch_id: null,
        branch_name: null,
        next_steps: null,
        social_security_number: null,
        assignment_id: null,
        assignment: null,
        on_assignment: false,
        company_id: null,
        company: null,
        notes: null,
        claim_docs: [],
        agency_id: null,
        is_deleted: false,
        talent_id: null,
        ...value != undefined && value,
    };
};


const initialForm: ICreateUnEmploymentClaim = {
    id: null,
    sequence_number: null,
    employee_name: "",
    claim_status: "OPEN",
    potential_charges: 0,
    cost_to_date: 0,
    claim_number: null,
    claim_date: null,
    date_closed: null,
    branch_id: null,
    branch_name: null,
    next_steps: null,
    social_security_number: null,
    assignment_id: null,
    assignment: null,
    on_assignment: false,
    company_id: null,
    company: null,
    notes: null,
    claim_docs: [],
    agency_id: null,
    is_deleted: false,
    talent_id: null,
}

const getInitialState = (value?: ICreateUnEmploymentClaim | null) => {
    if (value) {
        return {
            ...value,
            potential_charges: convertNumberToMoney(value.potential_charges, 'number') as string,
            cost_to_date: convertNumberToMoney(value.cost_to_date, 'number') as string,
        }
    }
    return new UpdateICreateUnEmploymentClaim()
}


const UnemployementClaimForm: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const initialForm: ICreateUnEmploymentClaim = {
        id: null,
        sequence_number: null,
        employee_name: props.stateTalentData?.employee_name ?? "",
        claim_status: "OPEN",
        potential_charges: 0,
        cost_to_date: 0,
        claim_number: null,
        claim_date: null,
        date_closed: null,
        branch_id: null,
        branch_name: null,
        next_steps: null,
        social_security_number: props.stateTalentData &&  props.stateTalentData?.talent_ssn !== "" ? props.stateTalentData?.talent_ssn : null,
        assignment_id: null,
        assignment: null,
        on_assignment: false,
        company_id: null,
        company: null,
        notes: null,
        claim_docs: [],
        agency_id: null,
        is_deleted: false,
        talent_id: props.stateTalentData?.talent_id !== "" ? props.stateTalentData?.talent_id! : null,
    }
    const [formState, setFormState] = useState<UpdateICreateUnEmploymentClaim>(
        getInitialState(props.claim ? getUnemployementClaimFormData(props.claim) : initialForm)
    );
    const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
    const dispatch = useAppDispatch();
    const talentList = useAppSelector((state) => selectTalentsDropdownList(state));
    const talentEntities = useAppSelector((state) => selectTalentsDropdownListEntities(state));
    const companiesDropdown = useAppSelector((state) => selectCompaniesDropdownList(state));
    const assignmentsList = useAppSelector((state) => selectAssignmentDropdownList(state));
    const assignmentsListEntities = useAppSelector((state) => selectassignmentDropdownListEntities(state));
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getTalentDropdown({}));
        if (formState.talent_id && formState.talent_id != '') {
            dispatch(getAssignmentDropdown({ talent_id: formState.talent_id }));
        }
        else {
            dispatch(assignmentDropdownListActions.clearAssignmentDropdownListState());
            setFormState({
                ...formState,
                assignment_id: null,
            });
        }
    }, [formState.talent_id]);

    useEffect(() => {
        if (formState.talent_id) {
            const talent = talentEntities[formState.talent_id];
            setFormState({
                ...formState,
                social_security_number: (talent && talent?.last_4_digits_of_ssn) ? convertToSSNFormat(talent?.last_4_digits_of_ssn) : null,
                employee_name: (talent && talent.name) ? talent.name : "",
                // assignment_id: null,
            });
        }
        if (formState.talent_id === null || formState.talent_id === "") {
            setFormState({ ...formState, social_security_number: null, assignment_id: null, employee_name: "" });
        }
        return () => { }
    }, [formState.talent_id,])

    useEffect(() => {

        if (!props.claim && formState.assignment_id) {
            setFormState({ ...formState, company_id: (assignmentsListEntities[formState.assignment_id] && assignmentsListEntities[formState.assignment_id]?.company_id) ? assignmentsListEntities[formState.assignment_id]?.company_id! : null });
        }
        if (!props.claim && formState.assignment_id === null || formState.assignment_id === "") {
            setFormState({ ...formState, company_id: null });
        }
        return () => { }
    }, [formState.assignment_id, formState.company_id, props.claim])



    function handleFieldChange(fieldId: string, value: any, parent?: string) {
        setFormState({ ...formState, [fieldId]: value });

    }

    const handleSave = () => {
        setCheckValidationOnSubmit(true);
        const requiredFieldArray = ['employee_name', 'assignment_id']
        requiredFieldArray.map((field) => {
            handleFieldChange(field, formState[field]);
        })
        if (_isFormValid()) {
            props.onSubmit({
                forceAdd:
                    props.error != null && props.error?.code === 409
                        ? true
                        : false,
                value: {
                    ...formState,
                    potential_charges: convertNumberToMoney(formState.potential_charges, 'number') as number,
                    cost_to_date: convertNumberToMoney(formState.cost_to_date, 'number') as number,
                }
            })
            setCheckValidationOnSubmit(false);
        }

    }

    function enableSave(): boolean {
        return formState.talent_id != null && formState.company_id != null && formState.assignment_id != null;
    }
    function getLockIcon() {
        return (
            <div className="wrk-lock-icon">
                <LockIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
            </div>
        )
    }

    function updateForm(value: ICreateUnEmploymentClaim) {
        setFormState({
            ...value,
            potential_charges: convertNumberToMoney(value.potential_charges, 'string') as string,
            cost_to_date: convertNumberToMoney(value.cost_to_date, 'string') as string,
        });
    }


    const formBasicInformation: (FormInputOption | FormInputOption[])[] = [
        /// 1st row
        [
            {
                field: "talent_id",
                type: "selector",
                label: "Talent",
                secure: false,
                required: true,
                placeholder: "select",
                keyboardtype: "",
                selectorList: talentList.map((doc) => ({
                    label: doc.name,
                    value: doc.id,
                })),
                multi: false,
            },
            {
                field: "social_security_number",
                type: "text",
                label: "social_security_number",
                secure: false,
                required: false,
                placeholder: "",
                keyboardtype: "",
                maxLength: 11,
                suffix: getLockIcon(),
                disabled: true,
                inputValue: convertToSSNFormat(formState.social_security_number),

            },
            {
                field: "assignment_id",
                type: "selector",
                label: "assignment",
                secure: false,
                required: true,
                placeholder: "Select",
                keyboardtype: "",
                selectorList: assignmentsList.map((doc) => ({
                    label: doc.job_title,
                    value: doc.id,
                })),
                multi: false,
            },
            {
                field: "company_id",
                type: "selector",
                label: "company",
                secure: false,
                required: false,
                placeholder: "",
                keyboardtype: "",
                selectorList: companiesDropdown.map((doc) => ({
                    label: doc.name,
                    value: doc.id,
                })),
                multi: false,
                disabled: true,
                inputValue: formState.company_id,
            },
        ],

        /// 2nd row
        [
            {
                field: "claim_date",
                type: "date",
                label: "claim_date",
                secure: false,
                required: false,
                placeholder: "MM/DD/YYYY",
                keyboardtype: "",
                setHoursToZero: true,
            },
            {
                field: "date_closed",
                type: "date",
                label: "date_closed",
                secure: false,
                required: false,
                placeholder: "MM/DD/YYYY",
                keyboardtype: "",
                setHoursToZero: true,

            },
        ],
    ];

    const formProfessionalInformation: (FormInputOption | FormInputOption[])[] = [
        [

            {
                field: "claim_number",
                type: "text",
                label: "claim_number",
                secure: false,
                required: false,
                placeholder: "",
                keyboardtype: "",

            },
            {
                field: "claim_status",
                type: "selector",
                label: "claim_status",
                secure: false,
                required: false,
                placeholder: "select",
                keyboardtype: "",
                selectorList: claimStatus.map(doc => ({ label: doc.title, value: doc.value })),
                multi: false,
                isClearable: false,
            },
            {
                field: "potential_charges",
                type: "text",
                label: "potential_charges",
                secure: false,
                required: false,
                placeholder: "",
                keyboardtype: "",
                min: "0.01",
                step: "0.01",
                prefix: <span>$</span>,
                onBlur: formatToMoney,
                onKeyDown: formatToMoney,
                onKeyUp: formatToMoney,

            },
            {
                field: "cost_to_date",
                type: "text",
                label: "cost_to_date",
                secure: false,
                required: false,
                placeholder: "",
                keyboardtype: "",
                min: "0.01",
                step: "0.01",
                prefix: <span>$</span>,
                onBlur: formatToMoney,
                onKeyDown: formatToMoney,
                onKeyUp: formatToMoney,
            },
        ],
    ];
    const formAdditionalInformation: (FormInputOption | FormInputOption[])[] = [
        [

            {
                field: "notes",
                type: "text",
                label: "notes",
                secure: false,
                required: false,
                placeholder: "",
                keyboardtype: "",
                multiline: true,
                numberOfLines: 10,
                containerStyle: {
                    flex: 1,
                }
            },
            {
                field: "next_steps",
                type: "text",
                label: "next_steps",
                secure: false,
                required: false,
                placeholder: "",
                keyboardtype: "",
                multiline: true,
                numberOfLines: 10,
                containerStyle: {
                    flex: 1,
                }
            },

        ],
    ];

    if (props.loading === LoadingType.succeeded && props.claim === undefined) {
        return (
            <div className="wrk-comp-f-container">
                <div className="wrk-comp-f-header">
                    <FormDialogTilteHeader
                        title={props.title}
                        titleIcon={<ClaimStatusIcon width={"100%"} height={"100%"} />}
                    />
                </div>
                <div className="wrk-comp-f-content">
                    <div className="saved-doc-holder">
                        <DocSavedText>
                            <span className="aknw-name">
                                {t("good_job", {
                                    field: t("new_unemployment_claim"),
                                    name: `${props.currentLoginUserName}!`,
                                })}
                            </span>
                            <span className="info-txt">{`${t("you_added_a_new_unemployment_claim_for")}:`}</span>
                            <span className="name">{`${formState.employee_name}.`}</span>
                        </DocSavedText>
                    </div>
                </div>
                <div className="wrk-comp-f-actions">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize", ...lowBtnStyle.text }}
                            name={t("go_to_my_agency_dashboard")}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={props.onGotoAgencyDashboard}
                            customStyle={lowBtnStyle.btn}
                        />
                    </div>
                    <div className="btn-new">
                        <CustomButton
                            loading={false}
                            textStyle={{
                                textTransform: "capitalize",
                                ...outlineBtnStyle.text,
                            }}
                            name={t("create_new", { name: t("unemployment_claim") })}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => {
                                updateForm(initialForm);
                                props.onCreateNew();
                            }}
                            customStyle={outlineBtnStyle.btn}
                        />
                    </div>

                    <div className="btn-save">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("go_to_this_newly_created", { name: t("unemployment_claim") })}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => formState.talent_id ? props.onGotoCreated(formState.talent_id, formState.employee_name) : {}}
                        />
                    </div>
                </div>
            </div>
        );
    }

    const snackbarAction = () => {
        return (
            <>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => setSnackBarMessage(null)}
                >
                    <Close fontSize="small" />
                </IconButton>
            </>
        );
    };

    return (
        <div className="wrk-comp-f-container">
            <div className="wrk-comp-f-header">
                <FormDialogTilteHeader
                    title={props.title}
                    titleIcon={<ClaimStatusIcon width={"100%"} height={"100%"} />}
                />
            </div>
            <div className="wrk-comp-f-content">
               
                {getSectionOne()}
                <div className="wrk-comp-f-section-divider">
                    {getSectionInputs(formProfessionalInformation)}
                </div>
                <div className="wrk-comp-f-section-divider">
                    {getSectionInputs(formAdditionalInformation)}

                    <div className="oa-cnt-inf-form-row">
                        <div
                            key={"doc"}
                            className={"oa-cnt-inf-f-input-holder"}
                            style={{ width: '-webkit-fill-available' }}
                        >
                            <DragAndDrop
                                label={t('claim_docs')}
                                selectedFiles={props.selectedDocument}
                                accept={".pdf,image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                                onMultiChange={props.onDocumentChange}
                                onMultiRemove={props.onRemoveDocument}
                                formValidators={validateDocumentType}
                                multiple={true}
                            />
                        </div>
                    </div>
                </div>
                {props.error && (
                    <div className={"error-section"}>
                        <ApiError
                            message={props.error.message}
                            onClose={props.onClearError}
                        />
                    </div>
                )}

            </div>
            <div className="wrk-comp-f-actions">
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("cancel")}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={props.onClose}
                    />
                </div>

                <div className="btn-save">
                    <CustomButton
                        loading={props.loading === LoadingType.pending}
                        textStyle={{ textTransform: "capitalize" }}
                        name={
                            props.error != null && props.error?.code === 409
                                ? t("force_add")
                                : props.claim
                                    ? t("update_field", { name: t("unemployment_claim") })
                                    : t("save")
                        }
                        // enable={enableSave()}
                        enable={true}
                        backgroundColor={
                            props.error != null && props.error?.code === 409
                                ? THEME.secondaryColor6
                                : THEME.defaultHighLightColor
                        }
                        onClick={handleSave}
                    />
                </div>
            </div>
            <Snackbar
                open={snackBarMessage !== null}
                autoHideDuration={5000}
                onClose={() => setSnackBarMessage(null)}
                message={snackBarMessage}
                action={snackbarAction()}
            />
        </div>
    );

    /// Section one
    function getSectionOne() {
        return (
            <div className="wrk-comp-f-section-divider">
                {getSectionInputs(formBasicInformation)}
            </div>
        );
    }

    function getSectionInputs(list: (FormInputOption | FormInputOption[])[]) {
        return list.map((doc, index) => {
            if (Array.isArray(doc)) {
                return (
                    <div key={index + "row"} className="wrk-comp-f-row ">
                        {doc.map((subDoc, subIndex) => {
                            return (
                                <RenderInput
                                    key={index + subIndex}
                                    doc={subDoc}
                                    index={subIndex}
                                    formState={formState}
                                    inputValue={subDoc.inputValue}
                                    formValidators={contactFormValidators}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                    handleFieldChange={handleFieldChange}
                                    className={"wrk-comp-f-input-holder"}
                                />
                            );
                        })}
                    </div>
                );
            }

            return (
                <RenderInput
                    key={index}
                    doc={doc}
                    index={index}
                    formState={formState}
                    // inputValue={doc.inputValue}
                    inputValue={(doc?.inputValue?.cost_to_date! || doc?.inputValue?.potential_charges) ? convertNumberToMoney(doc.inputValue.cost_to_date || doc?.inputValue?.potential_charges, "string") as string : ""}
                    handleFieldChange={handleFieldChange}
                    className={"wrk-comp-f-input-holder"}
                />
            );
        });

    }
    function validateDocumentType() {
        // if (props.selectedDocument && props.selectedDocument.type.startsWith('image/')) {
        //     return t('image_file_is_not_accepted');
        // }
        return null;
    }

    function _isFormValid() {
        const { employee_name, assignment_id } = formState;
        if (employee_name === "" || employee_name === null) return false;
        if (assignment_id === "" || assignment_id === null) return false;
        return true;
    }

    function contactFormValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "talent_id": {
                if (value.text === "" || value.text === null || value.text === undefined) return t("validators.required");
                return null;
            }
            case "assignment_id": {
                if (value.text === "" || value.text === null || value.text === undefined) return t("validators.required");
                return null;
            }
            default: {
                return null;
            }
        }
    }
};

export default UnemployementClaimForm;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableFilterEmpty,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { LoadingType, TALENT_STATUS, THEME } from "../../../../enums";
import { TalentActiveStatusIcon } from "../../../../icons";
import { ITalent, ITalentsByPage } from "../../../../interfaces";
import {
  selectTalentListState,
  getRoleUsersList,
  selectProfileState,
  getTEADraftBroadCast,
  selectTextEmAllState,
  getTalentsByPage,
} from "../../../../redux/store";
import {
  postSnackbarMessage,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  AccessPicture,
  CustomButton,
  CustomCheckBox,
  getContactTypeIcon,
  getTableAccessIcon,
  getTextEmAllIcon,
} from "../../../../utils";
import { getDaysAgoWithDateAMPM } from "../../../../variables";
import SSNHolder from "../../../../components/ssn_holder/ssn_holder";
import ViewSSNPopup from "../../../../main/talent/popups/view_ssn/view_ssn";
import { talentRequestSSNActions } from "../../../../redux/talent/details/dashboard/request_ssn/request_ssn_reducer";
import { TEADraftBroadCast } from "../../../../interfaces/text_em_all";
import BroadcastToTalentPopup from "../../../../components/broadcast_talent/broadcast_talent";
import { textEmAllSliceActions } from "../../../../redux/textEmAll/text_Em_All_reducer";
import "./universal_directory.scss";
import SearchBar from "../../../../components/search_bar";
import BroadcastToTalentErrorPopup from "../../../../components/broadcast_talent/broadcast_talent_error";
import Pagination from "../../../components/pagination/pagination";

interface Props extends RouteComponentProps<any> {
  talentId: string;
}

const UniversalDirectoryPage: React.FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const profileState = useAppSelector((state) => selectProfileState(state));
  const canSendText =
    profileState?.profile?.texting &&
    profileState?.profile?.text_em_all_token !== null;
  const textEmAllState = useAppSelector((state) => selectTextEmAllState(state));

  const [showSSNPopup, setShowSSNPopup] = useState<{
    talentId: string;
    visible: boolean;
  } | null>(null);
  const employeesListState = useAppSelector((state) =>
    selectTalentListState(state)
  );
  const [employeesList, setEmployeesList] = useState<ITalentsByPage | null>(null);

  const [search, setSearch] = useState("");
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(100)

  useEffect(() => {
    dispatch(getRoleUsersList());
    return () => { };
  }, []);

  useEffect(() => {
    // Timer ID to keep track of the setTimeout
    const timeoutId = setTimeout(() => {
      if (search?.length === 0 || search?.length >= 2) {
        getTalents(1);
      }
    }, 500);  // 500ms debounce delay, adjust as needed

    // Cleanup function to clear the timeout if the search value changes before the timeout is reached
    return () => clearTimeout(timeoutId);

  }, [search]);  // Trigger the effect whenever the search value changes

  function getTalents(pageNum?: number, pageSize?: number) {
    dispatch(getTalentsByPage({ page: pageNum ?? currentPage, size: pageSize ?? size, employee: true, search_str: search }));
    setCurrentPage(pageNum ?? currentPage)
  }

  useEffect(() => {
    if (employeesListState?.talentsByPage?.loading === LoadingType.succeeded && employeesListState?.talentsByPage?.response) {
      setEmployeesList(employeesListState?.talentsByPage?.response)
      return () => { };
    }
  }, [employeesListState?.talentsByPage?.loading, employeesListState?.talentsByPage?.response]);

  function getFilteredList(): ITalent[] {
    let sortedList: ITalent[] | undefined;
    let resultList: ITalent[] | undefined;

    if (sortedField != null) {
      sortedList = [...(resultList ?? employeesList?.items!)].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] != undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    return sortedList ?? resultList ?? resultList ?? employeesList?.items!;

  }

  function handleSortFieldChange(value: string) {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  }

  function handleUserSelect(value: ITalent) {
    props.history.push({
      pathname: `${AppRoutes.employeePortalDetailsPage}/${value.id}`,
      state: {
        id: value.id,
        name: `${value.first_name ?? ""} ${value.middle_name ?? ""} ${value.last_name ?? ""
          }`,
      },
    });
    return;
  }
  function handleEmployeeUserSelect(value: ITalent) {
    props.history.push({
      pathname: `${AppRoutes.employeePortalDetailsPage}/${value.id}`,
      state: {
        id: value.id,
        name: `${value.first_name ?? ""} ${value.middle_name ?? ""} ${value.last_name ?? ""
          }`,
      },
    });
    return;
  }

  function handleOnAssignmentSelect(value: ITalent) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.on_assignment_company_id}`,
      state: {
        id: value.on_assignment_company_id,
        name: `${value.on_assignment_company ?? ""}`,
      },
    });
    return;
  }
  const [broadcastTalent, setBroadcastTalent] = useState<boolean>(false);
  const [broadcastTalentError, setBroadcastTalentError] =
    useState<boolean>(false);
  useEffect(() => {
    dispatch(textEmAllSliceActions.clearState());
    return () => { };
  }, []);
  useEffect(() => {
    if (
      textEmAllState &&
      textEmAllState?.error !== null &&
      textEmAllState?.loading === LoadingType.failed
    ) {
      setBroadcastTalentError(true);
    }
    if (
      textEmAllState &&
      textEmAllState?.error === null &&
      textEmAllState?.loading === LoadingType.succeeded
    ) {
      setBroadcastTalent(true);
    }
    if (
      textEmAllState &&
      textEmAllState?.error === null &&
      textEmAllState?.loading === LoadingType.idle
    ) {
      setBroadcastTalent(false);
      setBroadcastTalentError(false);
    }
  }, [textEmAllState, textEmAllState.error]);
  const [formState] = useState<TEADraftBroadCast>({
    modified_by: "",
    created_by: "",
    modified_date: 0,
    talent_ids: [],
  });
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const [showErrorMsg, setShowErrorMsg] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<
    Map<string, { id: string; name: string }>
  >(new Map());

  const handleMarkItem = (value: ITalent) => {
    const newSet = new Map(selectedItems);
    if (selectedItems.has(value.id)) {
      newSet.delete(value.id);
    } else {
      newSet.set(value.id, {
        id: value.id,
        name: value.first_name ?? "" + " " + value.last_name ?? "",
      });
    }
    setSelectedItems(newSet);
  };
  const handleMarkAllItem = () => {
    const newSet = new Map<string, { id: string; name: string }>();
    if (selectedItems.size === getFilteredList()?.length) {
      setSelectedItems(newSet);
    } else {
      getFilteredList()?.forEach((doc) =>
        newSet.set(doc.id, {
          id: doc.id,
          name: doc.first_name + " " + doc.last_name,
        })
      );
      setSelectedItems(newSet);
    }
  };
  const handleBroadCastTalent = (forceSend?: boolean) => {
    if (selectedItems.size !== 0) {
      const talentids_list = Array.from(selectedItems.keys());
      dispatch(
        getTEADraftBroadCast({
          forceSend: forceSend,
          data: { ...formState, talent_ids: talentids_list },
        })
      );
      setShowMsg(false);
    } else {
      setShowMsg(true);
      setShowErrorMsg(t("please_select_emp_to_broadcast"));
      setTimeout(() => setShowErrorMsg(""), 5000);
    }
  };

  const handlePageChange = (pageNum: number, size: number) => {
    getTalents(pageNum, size)
    setSize(size ? size : employeesList?.size!)
  };

  return (
    <div className="tlnt-ud-container emp-ud-container">
      <div className="emp-ud-header-container">
        <div className="emp-ud-header">
          <SearchBar
            value={search}
            onChange={(value) => setSearch(value)}
            onSearch={() => { }}
          />
          <span className="total-count">
            {`${t("total_count")}: `}
            <span className="total-count-number">
              {employeesList?.total!}
            </span>
          </span>
        </div>
        <div className="emp-ud-header">
          <div className={"broadcast_btn_div"}>
            <CustomButton
              leftIcon={getTextEmAllIcon()}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("broadcast_to_employee")}
              enable={canSendText ? true : false}
              onClick={handleBroadCastTalent}
            />

            {showMsg && (
              <span className="select_talent_msg">{showErrorMsg}</span>
            )}
          </div>
        </div>
      </div>
      <div className="tlnt-ud-table">
        {employeesListState.loading === LoadingType.pending ? (
          <TableLoading />
        ) : (
          <TableBody
            employeesListState={employeesListState?.talentsByPage}
            getFilteredList={getFilteredList}
            sortedField={sortedField}
            handleSortFieldChange={handleSortFieldChange}
            handleOnAssignmentSelect={handleOnAssignmentSelect}
            sortDirection={sortDirection}
            handleMarkItem={handleMarkItem}
            handleMarkAllItem={handleMarkAllItem}
            selectedItems={selectedItems}
            getTalents={getTalents}
            talentStatus={talentStatus}
            getSSNInfo={getSSNInfo}
            getDaysAgoWithDateAMPM={getDaysAgoWithDateAMPM}
            getPrefferedContact={getPrefferedContact}
            employeesList={employeesList?.items}
            handleUserSelect={handleUserSelect}
            handleEmployeeUserSelect={handleEmployeeUserSelect}
            getTalentStatusIcon={getTalentStatusIcon}
          />
        )}
      </div>
      <div className="as-table-footer">
        <Pagination
          totalPages={employeesList?.pages!}
          onPageChange={handlePageChange}
          curPageNum={currentPage}
          totalCount={employeesList?.total!}
          size={employeesList?.size!}
          loadingState={employeesListState?.talentsByPage?.loading}
        />
      </div>
      {showSSNPopup && (
        <Portal>
          <ViewSSNPopup
            visible={showSSNPopup.visible}
            title={t("social_security_number")}
            talentId={showSSNPopup.talentId}
            onClose={() => setShowSSNPopup(null)}
            onDissmiss={() => setShowSSNPopup(null)}
            onSuccessClose={handleOnSuccessSSNClose}
          />
        </Portal>
      )}
      {canSendText &&
        broadcastTalentError &&
        textEmAllState &&
        textEmAllState?.error !== null && (
          <Portal>
            <BroadcastToTalentErrorPopup
              error={textEmAllState?.error}
              onClose={() => {
                setBroadcastTalentError(false);
                dispatch(textEmAllSliceActions.clearState());
              }}
              visible={broadcastTalentError}
              title={t("something_went_wrong")}
              loading={textEmAllState?.loading === LoadingType.pending}
              onForceSend={handleBroadCastTalent}
            />
          </Portal>
        )}
      {canSendText && broadcastTalent && (
        <Portal>
          <BroadcastToTalentPopup
            visible={broadcastTalent}
            title={""}
            successTitle={""}
            onClose={() => {
              setBroadcastTalent(false);
              dispatch(textEmAllSliceActions.clearState());
            }}
            onSuccessClose={() => { }}
            responseURL={textEmAllState?.response}
            loading={textEmAllState?.loading === LoadingType.pending}
          />
        </Portal>
      )}
    </div>
  );

  function getSSNInfo(value: string, id: string) {
    return (
      <SSNHolder
        value={value}
        iconColor={THEME.tableRowTextColor}
        onClick={() => handleOnSSNView(id)}
        showFormat={false}
      />
    );
  }
  function handleOnSSNView(id: string) {
    dispatch(talentRequestSSNActions.initialiseRequestSSN(id));
    if (profileState.profile && profileState.profile.show_ssn) {
      setShowSSNPopup({ visible: true, talentId: id });
    } else {
      dispatch(postSnackbarMessage(t("ssn_access_is_disabled")));
    }
  }

  function handleOnSuccessSSNClose() {
    setShowSSNPopup(null);
  }

  /// Talent table list

  function getTalentStatusIcon(doc: ITalent) {
    return (
      <div className="status-icon">
        <TalentActiveStatusIcon
          width={"100%"}
          height={"100%"}
          style={{
            color:
              doc.on_assignment_company != null
                ? THEME.buttonColor13
                : THEME.statusDisabledColor,
          }}
        />
      </div>
    );
  }

  function talentStatus(status: string) {
    if (status === TALENT_STATUS.active)
      return <span style={{ color: THEME.buttonColor17 }}>{t("active")}</span>;
    else if (status === TALENT_STATUS.inactive)
      return <span style={{ color: "#b1b1b1" }}>{t("in_active")}</span>;
    else return <span>{""}</span>;
  }

  function getPrefferedContact(value: string) {
    return (
      <div className="pref-contact-icon">
        {getContactTypeIcon(value, "#00A4DC")}
      </div>
    );
  }
};
const TableBody = ({
  employeesListState,
  getFilteredList,
  sortDirection,
  handleMarkAllItem,
  handleMarkItem,
  selectedItems,
  employeesList,
  handleSortFieldChange,
  getTalents,
  handleOnAssignmentSelect,
  talentStatus,
  sortedField,
  getSSNInfo,
  getDaysAgoWithDateAMPM,
  getPrefferedContact,
  handleUserSelect,
  handleEmployeeUserSelect,
  getTalentStatusIcon,
}) => {
  const { t } = useTranslation();
  const SelectAllCheckBox = () => {
    return (
      <CustomCheckBox
        name={""}
        title={""}
        checked={selectedItems.size === getFilteredList()?.length}
        onClick={handleMarkAllItem}
      />
    );
  };
  const tableHeader = [
    { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },

    { title: "access", code: "access_employee_portal" },
    { title: "name", code: "first_name" },
    { title: "on_assignment", code: "on_assignment_company" },
    { title: "status", code: "status" },
    { title: "address", code: "street_address" },
    { title: "city", code: "city" },
    { title: "zip_code", code: "zip_code" },
    { title: "state", code: "state" },
    { title: "ssn_hash", code: "last_4_digits_of_ssn" },
    { title: "last_contact", code: "last_engagement_date" },
    { title: "contact", code: "preferred_contact_method" },
    { title: "recruiter", code: "recruiter_name" },
  ];
  if (employeesListState?.loading === LoadingType.pending) {
    return <TableLoading />;
  }
  if (employeesListState?.error != null && employeesList?.length === 0) {
    return (
      <TableErrorHandler
        error={employeesListState?.error}
        onRefresh={getTalents}
      />
    );
  }
  if (employeesList?.length === 0) {
    return (
      <TableEmpty
        title={t("no_employees_found")}
        onClick={() => getTalents()}
      />
    );
  }
  if (getFilteredList()?.length === 0) {
    return <TableFilterEmpty title={t("no_employees_found")} />;
  }
  return (
    <SortableTable
      headerList={tableHeader}
      sortedField={sortedField}
      onSortChange={handleSortFieldChange}
      flexNumber={getFlexNumber}
      isAsc={sortDirection}
    >
      {getFilteredList()?.map((doc, index) => {
        return (
          <tr key={doc.id}>
            <TableData customStyle={{ flex: getFlexNumber(0) }}>
              <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.has(doc.id)}
                onClick={() => handleMarkItem(doc)}
              />
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(1) }}>
              <AccessPicture picture={doc.photo_url ?? ""} onClick={() => handleUserSelect(doc)} altText={doc.first_name + "Employee Picture"} />
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(2) }}
              isButton={true}
              onClick={() => handleEmployeeUserSelect(doc)}
              prefix={getTalentStatusIcon(doc)}
            >
              <span>{`${doc.first_name ?? ""} ${doc.middle_name ?? ""} ${doc.last_name ?? ""
                }`}</span>
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(3) }}
              isButton={doc.on_assignment_company ? true : false}
              onClick={() => handleOnAssignmentSelect(doc)}
            >
              <span>{doc.on_assignment_company ?? ""}</span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(4) }}>
              <span>{doc.status ?? ""}</span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(5) }}>
              <span>{doc.street_address}</span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(6) }}>
              <span>{doc.city ?? ""}</span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(7) }}>
              <span>{doc.zip_code ?? ""}</span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(8) }}>
              <span>{doc.state ?? ""}</span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(9) }}>
              {doc.last_4_digits_of_ssn
                ? getSSNInfo(doc.last_4_digits_of_ssn, doc.id)
                : ""}
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(10) }}>
              <span>
                {doc.last_engagement_date
                  ? getDaysAgoWithDateAMPM(t, doc.last_engagement_date)
                  : ""}
              </span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(11) }}>
              {getPrefferedContact(doc.preferred_contact_method)}
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(12) }}>
              <span>{doc.recruiter_name}</span>
            </TableData>
          </tr>
        );
      })}
    </SortableTable>
  );
};

function getFlexNumber(value: number) {
  if (value === 0) return 0.3;
  if (value === 1) return 0.7;
  if (value === 2) return 3;
  if (value === 3) return 2;
  if (value === 4) return 1.6;
  if (value === 5) return 2.5;
  if (value === 6) return 1.3;
  if (value === 7) return 1.5;
  if (value === 10) return 2;
  if (value === 11) return 1;
  return 1;
}

export default UniversalDirectoryPage;

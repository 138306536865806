import { t } from "i18next";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../components/api_error";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import MultiOptionSection from "../../../../components/multi_option_section/multi_option_section";
import { LoadingType, THEME } from "../../../../enums";
import { MagnifyGlassIcon } from "../../../../icons";
import { AppError, ISkillOption } from "../../../../interfaces";
import { selectOnlineApplicationReceivedDetailsState } from "../../../../redux/dashboard/metrics/online_applications_received_details/online_applications_received_selector";
import {
  getCertificationsList,
  getCredentialsList,
  getEducationList,
  getSkillSetList,
  selectCertificationsList,
  selectCertificationsListState,
  selectCredentialsList,
  selectCredentialsListState,
  selectSkillSetList,
  selectSkillSetListState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { CustomButton, SpinnerScreen } from "../../../../utils";

interface AddPopupProps {
  clearError: Function;
  error: AppError | null | undefined;
  list: Array<{ label: string; value: string }>;
  onAdd: Function;
  onCancel: Function;
  onChange: Function;
  saving: boolean;
  selectedOptions: string[];
  title: string;
}

const AddPopup: FC<AddPopupProps> = (props: AddPopupProps) => {
  const {
    clearError,
    error,
    list,
    onAdd,
    onCancel,
    onChange,
    saving,
    selectedOptions,
    title,
  } = props;
  const actionRef = useRef<ActionDialogHolderType>(null);
  return (
    <ActionDialogHolder
      ref={actionRef}
      visible
      wrapperWidth={430}
      onClose={() => onCancel()}
      onDissmiss={() => onCancel()}
    >
      <DialogWrapper onClose={() => onCancel()}>
        <div className="cmpny-op-f-container">
          <div className="cmpny-op-f-header">
            <FormDialogTilteHeader
              title={title}
              titleIcon={<MagnifyGlassIcon width={"100%"} height={"100%"} />}
            />
          </div>
          <div className="cmpny-op-f-content">
            <CustomMultiFormSelect
              name={title}
              list={list}
              onChange={(value) => onChange(value)}
              required={true}
              placeholder={t("select")}
              value={selectedOptions}
              customStyle={{ width: "-webkit-fill-available" }}
            />
            {error && (
              <div className={"error-section"}>
                <ApiError message={error?.message} onClose={() => clearError} />
              </div>
            )}
          </div>
          <div className="cmpny-op-f-actions" style={{marginTop:"1em"}}>
            <div className="btn-cancel">
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("cancel")}
                enable={true}
                backgroundColor={THEME.secondaryColor4}
                onClick={() => onCancel()}
              />
            </div>
            <div className="btn-save">
              <CustomButton
                loading={saving}
                textStyle={{ textTransform: "capitalize" }}
                name={t("add")}
                enable={selectedOptions.length !== 0}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => onAdd()}
              />
            </div>
          </div>
        </div>
      </DialogWrapper>
    </ActionDialogHolder>
  );
};

interface RemovePopupProps {
  clearError: Function;
  error: AppError | null | undefined;
  onRemove: Function;
  onCancel: Function;
  saving: boolean;
  title: string;
}

const RemovePopup: FC<RemovePopupProps> = (props: RemovePopupProps) => {
  const { clearError, error, onCancel, onRemove, saving, title } = props;
  const actionRef = useRef<ActionDialogHolderType>(null);

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible
      wrapperWidth={430}
      onClose={() => onCancel()}
      onDissmiss={() => onCancel()}
    >
      <div className="del-role-container">
        <div className="del-role-header">
          <div className="title-txt">
            <span>{`${t("attention")}!!`}</span>
          </div>
        </div>
        <div className="del-role-content">
          <div className="message-txt">
            <span>{title}</span>
          </div>
          {error && (
            <div className={"error-section"}>
              <ApiError message={error.message} onClose={() => clearError()} />
            </div>
          )}
        </div>
        <div className="del-role-action-holder">
          <div className="del-role-action">
            <div className="btn-no">
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("cancel")}
                enable={true}
                backgroundColor={THEME.secondaryColor4}
                onClick={() => onCancel()}
              />
            </div>
            <div className="btn-yes">
              <CustomButton
                loading={saving}
                textStyle={{ textTransform: "capitalize" }}
                name={t("delete")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => onRemove()}
              />
            </div>
          </div>
        </div>
      </div>
    </ActionDialogHolder>
  );
};

const OnlineApplicationReceivedSkillSummary = ({
  updateApplication,
  appInfo,
  disable,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [addOption, setAddOption] = useState<{
    visible: boolean;
    optionType: string;
  } | null>(null);
  const [removeOption, setRemoveOption] = useState<{
    visible: boolean;
    optionType: string;
    value: ISkillOption;
  } | null>(null);
  const skillSetListState = useAppSelector((state) =>
    selectSkillSetListState(state)
  );
  const credentialsListState = useAppSelector((state) =>
    selectCredentialsListState(state)
  );
  const certificationsListState = useAppSelector((state) =>
    selectCertificationsListState(state)
  );
  const skillSetList = useAppSelector((state) => selectSkillSetList(state));
  const credentialsList = useAppSelector((state) =>
    selectCredentialsList(state)
  );
  const certificationsList = useAppSelector((state) =>
    selectCertificationsList(state)
  );
  const { loading } = useAppSelector((state) =>
    selectOnlineApplicationReceivedDetailsState(state)
  );
  useEffect(() => {
    dispatch(getSkillSetList());
    dispatch(getCertificationsList());
    dispatch(getCredentialsList());
    dispatch(getEducationList());
  }, []);

  const getList = (optionType) => {
    switch (optionType) {
      case "skills":
        return skillSetList.map((doc, index) => ({
          label: doc.skill_set,
          value: doc.id,
        }));
      case "credentials":
        return credentialsList.map((doc, index) => ({
          label: doc.credential,
          value: doc.id,
        }));
      case "certifications":
        return certificationsList.map((doc, index) => ({
          label: doc.certification,
          value: doc.id,
        }));
      default:
        return [];
    }
  };

  const getSeletedOptions = (optionType) => {
    const list = getList(optionType).map(({ label, value }) => ({
      id: value,
      value: label,
    }));
    switch (optionType) {
      case "skills":
        return appInfo?.professional_skills
          ? list.filter(
              (doc) =>
                appInfo.professional_skills.findIndex((i) => doc.id === i) > -1
            )
          : [];
      case "credentials":
        return appInfo?.credentials
          ? list.filter(
              (doc) => appInfo.credentials.findIndex((i) => doc.id === i) > -1
            )
          : [];
      case "certifications":
        return appInfo?.certifications
          ? list.filter(
              (doc) =>
                appInfo.certifications.findIndex((i) => doc.id === i) > -1
            )
          : [];
      default:
        return [];
    }
  };

  const handleAddOption = (option: string) => {
    setAddOption({ visible: true, optionType: option });
    const items = getSeletedOptions(option).map((i) => i.id);
    setSelectedOptions([...items]);
  };

  const handleRemoveOption = (option: string, value: ISkillOption) => {
    setRemoveOption({ visible: true, optionType: option, value: value });
  };

  const cancelAdd = () => {
    setAddOption({ visible: false, optionType: "" });
    setSelectedOptions([]);
  };

  const cancelRemove = () => {
    setRemoveOption({
      visible: false,
      optionType: "",
      value: { id: "", value: "" },
    });
  };

  const handleAdd = () => {
    switch (addOption?.optionType) {
      case "skills": {
        updateApplication({
          ...appInfo,
          professional_skills: [...selectedOptions],
        });
        cancelAdd();
        break;
      }
      case "credentials": {
        updateApplication({ ...appInfo, credentials: [...selectedOptions] });
        cancelAdd();
        break;
      }
      case "certifications": {
        updateApplication({ ...appInfo, certifications: [...selectedOptions] });
        cancelAdd();
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleRemove = () => {
    switch (removeOption?.optionType) {
      case "skills": {
        updateApplication({
          ...appInfo,
          professional_skills: [
            ...appInfo.professional_skills.filter(
              (i) => i !== removeOption.value.id
            ),
          ],
        });
        cancelRemove();
        break;
      }
      case "credentials": {
        updateApplication({
          ...appInfo,
          credentials: [
            ...appInfo.credentials.filter((i) => i !== removeOption.value.id),
          ],
        });
        cancelRemove();
        break;
      }
      case "certifications": {
        updateApplication({
          ...appInfo,
          certifications: [
            ...appInfo.certifications.filter(
              (i) => i !== removeOption.value.id
            ),
          ],
        });
        cancelRemove();
        break;
      }
      default: {
        break;
      }
    }
  };

  const clearError = () => {};

  if (
    skillSetListState.loading === LoadingType.pending ||
    credentialsListState.loading === LoadingType.pending ||
    certificationsListState.loading === LoadingType.pending ||
    loading === LoadingType.pending
  ) {
    return <SpinnerScreen />;
  }

  return (
    <div className={`cd-cs-covv-container ${disable ? "disabled" : ""}`}>
      <MultiOptionSection
        headerTitle={t("professional_skills").toUpperCase()}
        actionName={t("add_skills")}
        onClick={() => handleAddOption("skills")}
        list={getSeletedOptions("skills") ?? []}
        onRemove={(value) => handleRemoveOption("skills", value)}
      />
      <MultiOptionSection
        headerTitle={t("credentials").toUpperCase()}
        actionName={t("add_credentials")}
        onClick={() => handleAddOption("credentials")}
        list={getSeletedOptions("credentials") ?? []}
        onRemove={(value) => handleRemoveOption("credentials", value)}
      />
      <MultiOptionSection
        headerTitle={t("certifications").toUpperCase()}
        actionName={t("add_certifications")}
        onClick={() => handleAddOption("certifications")}
        list={getSeletedOptions("certifications") ?? []}
        onRemove={(value) => handleRemoveOption("certifications", value)}
      />
      {addOption && addOption.visible && (
        <Portal>
          <AddPopup
            clearError={clearError}
            error={null}
            list={getList(addOption.optionType)}
            onAdd={handleAdd}
            onCancel={cancelAdd}
            onChange={(value) => setSelectedOptions(value)}
            saving={false}
            selectedOptions={selectedOptions}
            title={`${t("add_option", { value: addOption.optionType })}`}
          />
        </Portal>
      )}
      {removeOption && removeOption.visible && (
        <Portal>
          <RemovePopup
            clearError={clearError}
            error={null}
            onCancel={cancelRemove}
            onRemove={handleRemove}
            saving={false}
            title={t("are_you_sure_you_want_to_delete", {
              value: `${removeOption.value.value} ${removeOption.optionType}`,
            })}
          />
        </Portal>
      )}
    </div>
  );
};

export default OnlineApplicationReceivedSkillSummary;


import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";
import { IRCNewHireReport } from "../../../../interfaces/report_center_payroll_tax";
import * as thunk from "./new_hire_report_thunk";
export interface NewHireReportListState extends EntityState<IRCNewHireReport> {
    error?: AppError | null,
    loading: LoadingType,
}

const newHireReportListAdapter: EntityAdapter<IRCNewHireReport> = createEntityAdapter<IRCNewHireReport>({
    selectId: (doc) => doc.assignment_id,
})

const initialState: NewHireReportListState = newHireReportListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const newHireReportListSlice = createSlice({
    name: 'newHireReportList',
    initialState: initialState,
    reducers: {
        clearNewHireReportListError(state) {
            return { ...state, error: null };
        },
        clearNewHireReportListState(state) {
            return newHireReportListAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getNewHireReportList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getNewHireReportList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getNewHireReportList.fulfilled, (state, action) => newHireReportListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearNewHireReportListError, clearNewHireReportListState } = newHireReportListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = newHireReportListAdapter.getSelectors();
export const selectState = (state: NewHireReportListState) => state;



import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import { ITalentResumeUpload } from "../../../interfaces";
import * as thunk from "./talents_upload_thunk";



export interface TalentResUploadState {
    getUploadUrls: {
        error?: AppError | null,
        loading: LoadingType,
        response: ITalentResumeUpload[] | null,
    },
    importTalent: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null,
    },
    upload: {
        error?: AppError | null;
        loading: LoadingType;
        response: any | null;
    }

}

const initialState: TalentResUploadState = {
    getUploadUrls: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    importTalent: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    upload: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
}


export const talentResUploadSlice = createSlice({
    name: 'talentResUpload',
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...state, ...initialState };
        },
        clearTalentGetUploadState(state) {
            return { ...state, getUploadUrls: { ...initialState.getUploadUrls } };
        },
        clearTalentGetUploadStateError(state) {
            return { ...state, getUploadUrls: { ...state.getUploadUrls, error: null } };
        },
        clearUploadFilesState(state) {
            return { ...state, upload: { ...initialState.upload } };
        },
        clearUploadFilesStateError(state) {
            return { ...state, upload: { ...state.upload, error: null } };
        },
        clearImportTalentState(state) {
            return { ...state, importTalent: { ...initialState.importTalent } };
        },
        clearImportTalentStateError(state) {
            return { ...state, importTalent: { ...state.importTalent, error: null } };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTalentResumeUploadUrls.pending, (state, action) => ({ ...state, getUploadUrls: { ...state.getUploadUrls, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getTalentResumeUploadUrls.rejected, (state, action) => ({ ...state, getUploadUrls: { ...state.getUploadUrls, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.getTalentResumeUploadUrls.fulfilled, (state, action) => ({ ...state, getUploadUrls: { ...state.getUploadUrls, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateTalentImportThunk.pending, (state, action) => ({ ...state, importTalent: { ...state.importTalent, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateTalentImportThunk.rejected, (state, action) => ({ ...state, importTalent: { ...state.importTalent, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateTalentImportThunk.fulfilled, (state, action) => ({ ...state, importTalent: { ...state.importTalent, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.uploadTalentResumeFileThunk.pending, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.uploadTalentResumeFileThunk.rejected, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.uploadTalentResumeFileThunk.fulfilled, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});
export const talentResumeUploadSliceActions = talentResUploadSlice.actions;
export const selectState = (state: TalentResUploadState) => state;

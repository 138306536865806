import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolder, ActionDialogHolderType } from '../../components/action_dialog_holder/action_dialog_holder';
import { LoadingType, THEME } from '../../enums';
import { CalenderIcon } from '../../icons';
import { AppError, IEngagement } from '../../interfaces';
import { postSnackbarMessage, saveEngagement, selectProfileState, updateEngagement, useAppDispatch, useAppSelector } from '../../redux/store';
import { CustomButton, FormInput } from '../../utils';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { isValid } from 'date-fns';
import './engagementsPopup.scss';
import { selectEngagementSavestate } from '../../redux/engagement/engagement_selector';
import CloseIcon from '../../../assets/icons/close-btn2.svg';
import { convertDateToTimeStamp, dateTimeToTimestamp, dateToTimestamp, extractDateFromTimestamp, extractTimeFromTimestamp, getDateFromTimeStamp, getDateString, timestampToStringDate } from '../../variables';
import TimePickerComponent from '../../components/time_picker/time_picker';
import { Timer } from '@material-ui/icons';
import { DateInput } from '../../components/date_input/date_input';
import DialogWrapper, { FormDialogTilteHeader } from '../../components/dialog_wrapper/dialog_wrapper';
import DragAndDrop from '../../components/drag_and_drop/drag_and_drop';
interface IAddEngagement {
    showPopup: boolean;
    source: string;
    object_id: string;
    onClose: () => void;
    onDissmiss?: () => void;
    onSuccessClose: () => void;
    engagementData: IEngagement | null;
    engagementState: {
        error?: AppError | null;
        loading: LoadingType;
        response: string;
    };
}

const AddEnagement = ({ showPopup, source, onClose, onDissmiss, object_id, engagementData, onSuccessClose, engagementState }: IAddEngagement) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const profileState = useAppSelector((state) => selectProfileState(state));
    const [userDocument, setUserDocument] = useState<Array<File> | null>(null);
    const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""
        }`;

    function getCurrentTime() {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }
    const initialState: IEngagement = {
        id: engagementData && engagementData.id ? engagementData.id : null,
        created_by_name: engagementData && engagementData.id ? engagementData.created_by_name : currentLoginUserName,
        object_id: engagementData && engagementData.object_id ? engagementData.object_id : object_id,
        object_type: engagementData && engagementData.object_type ? engagementData.object_type : null,
        event_title: engagementData && engagementData.event_title ? engagementData.event_title : "",
        description: engagementData && engagementData.description ? engagementData.description : "",
        date_time: null,
        date: engagementData && engagementData.date ? engagementData.date : convertDateToTimeStamp(new Date()),
        time: engagementData && engagementData.time ? engagementData.time : getCurrentTime(),
        is_deleted: false,
        file_names : engagementData?.file_names ?? [],
        file_paths:engagementData?.file_paths?? [],
        assignment_id : engagementData && engagementData.assignment_id? engagementData.assignment_id : null,
        company_id : engagementData && engagementData.company_id? engagementData.company_id: null,
        contact_ids : engagementData && engagementData.contact_ids? engagementData.contact_ids : [],
        create_date : engagementData && engagementData.create_date? engagementData.create_date : null,
        data_migration_id : engagementData && engagementData.data_migration_id? engagementData.data_migration_id : null,
        job_id : engagementData && engagementData.job_id? engagementData.job_id : null,
        job_ids : engagementData && engagementData.job_ids? engagementData.job_ids:[],
        migrated: engagementData && engagementData.migrated? engagementData.migrated : false,
        migrated_id : engagementData && engagementData.migrated_id? engagementData.migrated_id : null,
        sales_call_id : engagementData && engagementData.sales_call_id? engagementData.sales_call_id : null,
        services_call_id : engagementData && engagementData.services_call_id? engagementData.services_call_id : null,
        talent_id : engagementData && engagementData.talent_id? engagementData.talent_id : null,
        modified_by : engagementData && engagementData.modified_by? engagementData.modified_by : null,
        modified_date : engagementData && engagementData.modified_date? engagementData.modified_date : 0 

    }
    const [eventData, setEventData] = useState(initialState);
    // const [eventDate, setEventDate]: any = useState(engagementData && engagementData.date_time ? getDateFromTimeStamp(engagementData.date_time) : new Date());
    const [eventDateValid, setEventDateValid] = useState(true);
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>();
    /*useEffect(() => {
        if (engagementState.loading === LoadingType.succeeded) {
            if(userDocument && userDocument.length>0) {
                saveDocument(engagementState.response?.object_id);
            }
            closePopup(onSuccessClose());
        }

        return () => { }

    }, [engagementState.loading])*/

    const closePopup = (action: any) => {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
    const getError = () => {
        return engagementState.error;
    }

    const isAddLoading = () => {
        return engagementState.loading === LoadingType.pending;
    }

    // function getDateTime(timestamp) {
    //     let date = new Date(timestamp * 1000);
    //     let dateString = date.toLocaleDateString()
    //     let dateList = dateString.split('/');
    //     return `${dateList[0]}/${dateList[1]}/${dateList[2]}`
    // }

    const handleClearTime = () => {
        setEventData({ ...eventData, time: "" })
    }

    const handleClearError = () => {
        switch (source) {
            default: {
                break;
            }
        }
    }
    const handleAddOption = () => {
        // const dateTime = toTimestamp(eventDate)
        setCheckValidationOnSubmit(true);
        if (!isFormValid()) {
            return;
        }
        const dateTimestampValue = timestampToStringDate(eventData.date)
        const timeStringValue = eventData.time
        const dateAndTimeTimeStampValue = dateTimeToTimestamp(dateTimestampValue, timeStringValue)! / 1000
        const payload: IEngagement = {
            ...eventData,
            // date_time: dateTime,
            date_time: dateAndTimeTimeStampValue,
            object_type: source,
            file_names : getSelectedNames()
            //file_names : userDocument?.map((file:any)=>file.name)?? []
        }
        dispatch(eventData.id && eventData.id !== null ? updateEngagement({data: payload, files: userDocument }) : saveEngagement({data: payload, files: userDocument }));          
        setCheckValidationOnSubmit(false);
    }

    const handleFilesChange = (value:Array<File>)=>{
        
        if(userDocument && userDocument.length>0) {
            setUserDocument([...userDocument,...value])
        }
        else{
            setUserDocument(value)
        }
    }
    function handleFileRemove(index: number) {
        if (userDocument && userDocument?.length > 1) {
            const newArr = [...userDocument]
            newArr.splice(index, 1);
            setUserDocument(newArr);
        } else {
            setUserDocument(null);
        }

    }

    const getSelectedNames = ()=>{
        let newSelectedList : string[] = userDocument?.map((file:any)=>file.name)?? [];
        if(eventData && eventData.id!=null && engagementData && engagementData.file_names.length>0) {
            newSelectedList = [...newSelectedList,...engagementData.file_names]
        }
        return newSelectedList
    }
    
    const isFormValid = () =>
        !!eventData.event_title &&
        !!eventData.time &&
        !!eventData.date

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={showPopup}
            wrapperWidth={"30%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={onClose}>
            <div className="cmpny-op-f-container">
                <div className="uf-header">
                        <FormDialogTilteHeader
                            title={engagementData && engagementData.id ? t("edit_event") : t("add_event")}
                            titleIcon={
                          <CalenderIcon width={"100%"} height={"100%"} />
                            }
                            subTitle='Please add the name of the event and select a date and time'
                        />
                </div>
                <div className="cmpny-op-f-content">
                    <div className='ac-eng-field-row'>
                        <div className='ac-eng-fields' style={{width:"100%"}}>
                            <FormInput
                                id={'event_title'}
                                onChange={(field: any, value: any) => {
                                    if (value !== null) {
                                        setEventData((prevState: IEngagement) => ({
                                            ...prevState,
                                            event_title: value,
                                        }));
                                    }
                                }}
                                required={true}
                                placeholder={""}
                                type={"text"}
                                value={eventData.event_title}
                                label={t('event_title')}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                                validations={engagementFormValidators}
                            />
                        </div>
                    </div>
                    <div className='ac-eng-field-row'>
                        <div className='ac-sc-desc-fields' >
                            <FormInput
                                id={'description'}
                                onChange={(field: any, value: any) => {
                                    if (value !== null) {
                                        setEventData((prevState: IEngagement) => ({
                                            ...prevState,
                                            description: value,
                                        }));
                                    }
                                }}
                                multiline={true}
                                required={false}
                                placeholder={""}
                                type={"text"}
                                value={eventData.description}
                                label={"Description"}
                            />
                        </div>

                    </div>
                    <div className='ac-eng-field-row'>
                        <div className='ac-eng-fields'>
                            <p className='ac-Field-text'>Date*</p>
                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    disableToolbar={false}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    value={eventData.date}
                                    className={'datePickerStyle'}
                                    disableFuture={false}
                                    size="small"
                                    style={{ marginTop: 0, width: "100%" }}
                                    // onChange={(date: any, value: any) => {
                                    //     if (date && date.toString() !== 'Invalid Date') {
                                    //         setEventDate(value);
                                    //         setEventDateValid(isValid(date));
                                    //     }
                                    // }}
                                    onChange={(field: any, value: any) => {
                                        if (value !== null) {
                                            setEventData((prevState: IEngagement) => ({
                                                ...prevState,
                                                date: value,
                                            }));
                                        }
                                        setEventDateValid(isValid(value));
                                    }}

                                    KeyboardButtonProps={{
                                        "aria-label": "from date",
                                        "aria-required": "true"
                                    }}
                                    onError={(error) => {
                                        if (error !== "") {
                                            setEventDateValid(false)
                                        }
                                    }}
                                    error={!eventDateValid}
                                    helperText={eventDateValid ? ("") : ("Please select valid Date")}
                                    aria-required="true"
                                />
                            </MuiPickersUtilsProvider> */}
                            <DateInput
                                id={"date"}
                                onChange={(field: any, value: any) => {
                                    setEventData((prevState: IEngagement) => ({
                                        ...prevState,
                                        date: value,
                                    }));
                                }}
                                placeholder={""}
                                value={eventData.date}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                                validations={engagementFormValidators}
                                required
                            />

                        </div>
                        <div className='ac-eng-fields'>
                            <p className='ac-Field-text-time'>Time*</p>
                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    margin="normal"
                                    id="date_time"
                                    value={eventDate}
                                    className='datePickerStyle'
                                    mask="--:-- M"
                                    size="small"
                                    style={{ marginTop: 0, width: "100%" }}
                                    onChange={(date) => {
                                        setEventDate(date)
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'start time',
                                    }}
                                />
                            </MuiPickersUtilsProvider> */}
                            <TimePickerComponent
                                id={"time"}
                                onChange={(field: any, value: any) => {
                                    setEventData((prevState: IEngagement) => ({
                                        ...prevState,
                                        time: value,
                                    }));
                                }}
                                placeholder={''}
                                value={eventData.time}
                                onClearTime={handleClearTime}
                                icon={<Timer />}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                                validations={engagementFormValidators}
                            />
                        </div>

                        <div className='ac-eng-input-form-field'>
                            <FormInput
                                id={'created_by_name'}
                                onChange={(field: any, value: any) => {
                                    if (value !== null) {
                                        setEventData((prevState: IEngagement) => ({
                                            ...prevState,
                                            created_by_name: value,
                                        }));
                                    }
                                }}
                                placeholder={""}
                                type={"text"}
                                value={eventData.id ? eventData.created_by_name : currentLoginUserName}
                                label={t('this_event_made_by')}
                                disabled
                            />
                        </div>

                    </div>
                    <div className="ac-eng-field-row">
                        <div
                            key={"doc"}
                            style={{ width: '-webkit-fill-available' }}
                        >
                            <DragAndDrop
                                label={t('attach_docx')}
                                selectedFiles={userDocument}
                                accept={".pdf,image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                                onMultiChange={handleFilesChange}
                                onMultiRemove={handleFileRemove}
                                //formValidators={()=>null}
                                multiple={true}
                            />
                        </div>
                    </div>
                 
                </div>
                
                
                <div className={"dialog-footer"}>
                    <div className="cmpny-op-f-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.secondaryColor4}
                                onClick={() => {
                                    if (getError()) handleClearError();
                                    closePopup(onClose);
                                }}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                loading={isAddLoading()}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={engagementData && engagementData.id ? t('update') : t('save')}
                                enable={true}
                                // enable={
                                //     !!eventData.event_title &&
                                //     !!eventData.time &&
                                //     !!eventData.date
                                // }
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={handleAddOption}
                            />
                        </div>
                    </div>
                </div>
            </div>
            </DialogWrapper>
        </ActionDialogHolder>

    );

    function engagementFormValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "event_title":
            case "time":
            case "date": {
                if (!value.text) return t("validators.required");
                return null;
            }

            default:
                return null;
        }
    }
}

export default AddEnagement;
import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolderType, ActionDialogHolder } from '../../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper from '../../../../components/dialog_wrapper/dialog_wrapper';
import { LoadingType, ProfilePictureType, ROLE } from '../../../../enums';
import { ITalent, ICreateTalent, ICreateResponse } from '../../../../interfaces';
import {
    selectCertificationsList,
    selectCertificationsListState,
    selectCredentialsList,
    selectCredentialsListState,
    selectEducationList,
    selectEducationListState,
    selectRecruitersList,
    selectRecruitersListState,
    selectRequiredEquipmentList,
    selectRequiredEquipmentListState,
    selectSkillSetList,
    selectSkillSetListState,
    selectRoleUsersListState,
    getRoleUsersList,
    selectAccountManagerRoleUsersList,
    useAppDispatch, useAppSelector, postSnackbarMessage,
    selectManageTalentCreateState,
    selectManageTalentUpdateState,
    selectCompetitorsListState,
    selectCompetitorsList,
    updateTalent,
    createTalent,
    getCredentialsList,
    getEducationList,
    getRequiredEquipmentList,
    getSkillSetList,
    getCertificationsList,
    getCompetitorsList,
    getRecruiters,
    selectManageTalentGetUploadURLState,
    selectAccountManagersListState,
    selectBusinessSectorListState,
    getAccountManagers,
    getBusinessSectors,
    selectManageTalentUploadFilesState,
    selectAccountManagersList,
    selectBusinessSectorList,
    selectProfileState,
    getTalentFileUploadUrlsThunk,
    uploadTalentFilesThunk,
    getTalentList,

} from "../../../../redux/store";
import { manageTalentActions } from '../../../../redux/talent/universal_directory/manage_talent/manage_talent_reducer';
import { getStatusFromICreateState, SpinnerScreen } from '../../../../utils';
import TalentForm from '../../forms/talent_form/talent_form';

import './create_talent.scss';
import { getTalentSource, getTalentStatus, getWorkCompConfigList, selectTalentSourceList, selectTalentSourceState, selectTalentStatusList, selectTalentStatusState, selectWorkCompConfigList, selectWorkCompConfigListState } from '../../../../redux/admin_center';

interface Props {
    visible: boolean,
    title: string,
    successTitle: string,
    talent?: ITalent,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
    onGotoAgencyDashboard: () => void,
    onGotoCreated: (id: string, name: string) => void,
    isTalent?: boolean,
    showSSN?: boolean | undefined
}

const CreateTalentPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onSuccessClose,
        showSSN
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const profileState = useAppSelector((state) => selectProfileState(state));
    const createState = useAppSelector((state) => selectManageTalentCreateState(state));
    const updateState = useAppSelector((state) => selectManageTalentUpdateState(state));
    const uploadURLState = useAppSelector((state) => selectManageTalentGetUploadURLState(state));
    const uploadFileState = useAppSelector((state) => selectManageTalentUploadFilesState(state));
    const accountManagersListState = useAppSelector((state) => selectAccountManagersListState(state));
    const recruitersListState = useAppSelector((state) => selectRecruitersListState(state));
    const businessSectorListState = useAppSelector((state) => selectBusinessSectorListState(state));
    const competitorsListState = useAppSelector((state) => selectCompetitorsListState(state));

    const skillSetListState = useAppSelector((state) => selectSkillSetListState(state));
    const credentialsListState = useAppSelector((state) => selectCredentialsListState(state));
    const certificationsListState = useAppSelector((state) => selectCertificationsListState(state));
    const educationListState = useAppSelector((state) => selectEducationListState(state));
    const requiredEquipmentListState = useAppSelector((state) => selectRequiredEquipmentListState(state));

    /// list
    const accountManagersList = useAppSelector((state) => selectAccountManagersList(state));
    const recruitersList = useAppSelector((state) => selectRecruitersList(state));
    const businessSectorList = useAppSelector((state) => selectBusinessSectorList(state));
    const competitorsList = useAppSelector((state) => selectCompetitorsList(state));
    const actionRef = useRef<ActionDialogHolderType>(null);

    /// skills
    const skillSetList = useAppSelector((state) => selectSkillSetList(state));
    const credentialsList = useAppSelector((state) => selectCredentialsList(state));
    const certificationsList = useAppSelector((state) => selectCertificationsList(state));
    const educationList = useAppSelector((state) => selectEducationList(state));
    const requiredEquipmentList = useAppSelector((state) => selectRequiredEquipmentList(state));

    ///Files
    const [contactLogo, setContactLogo] = useState<File | null>(null);
    const [contactResume, setContactResume] = useState<File | null>(null);

    ///Talent Status
    const talentStatusState = useAppSelector((state) => selectTalentStatusState(state));
    const talentStatusList = useAppSelector((state) => selectTalentStatusList(state));

    ///work comp code
    const workCompCodeState = useAppSelector((state) => selectWorkCompConfigListState(state));
    const workCompCodeList = useAppSelector((state) => selectWorkCompConfigList(state));

    ///talent source
    const talentSourceState = useAppSelector((state) => selectTalentSourceState(state));
    const talentSourceList = useAppSelector((state) => selectTalentSourceList(state));

    useEffect(() => {
        getData();
        dispatch(getSkillSetList());
        dispatch(getCertificationsList());
        dispatch(getCredentialsList());
        dispatch(getEducationList());
        dispatch(getRequiredEquipmentList());
        dispatch(manageTalentActions.initializeManageTalentState())
        dispatch(getTalentStatus());
        dispatch(getWorkCompConfigList());
        dispatch(getTalentSource());
    }, [])

    useEffect(() => {
        if (createState.loading == LoadingType.succeeded) {
            if (createState.response) handleNextStep(createState.response);
        }
        if (updateState.loading == LoadingType.succeeded) {
            if (updateState.response) handleNextStep(updateState.response);
        }
        return () => { }
    }, [createState.loading, updateState.loading])


    /// [handle get file upload urls]
    useEffect(() => {
        if (uploadURLState.loading === LoadingType.succeeded && uploadURLState.response) {
            dispatch(uploadTalentFilesThunk({
                data: uploadURLState.response,
                photo: contactLogo,
                resume: contactResume,
            }))
        }
        return () => {

        }
    }, [uploadURLState.loading])

    /// [handle upload files]
    useEffect(() => {
        if (uploadFileState.loading === LoadingType.succeeded) {
            if (props.talent && props.talent.id) closePopup(onSuccessClose);
        }
        return () => {

        }
    }, [uploadFileState.loading])


    function handleNextStep(response: ICreateResponse) {
        if (contactLogo != null || contactResume != null) {
            dispatch(getTalentFileUploadUrlsThunk({
                object_id: response.object_id,
                profile_pic_file_name: contactLogo ? contactLogo?.name : undefined,
                resume_file_name: contactResume ? contactResume?.name : undefined
            }));
        } else if (props.talent && props.talent.id) {
            dispatch(postSnackbarMessage(response.status ?? null));
            closePopup(onSuccessClose);
        } else {
            /// 
        }
        /// dispatch(postSnackbarMessage(response.status ?? null));
        /// closePopup(onSuccessClose);
    }

    function handleUploadFileFinish() {
        closePopup(onSuccessClose);
    }

    const handleClosePopup = () => {
        if (createState?.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(createState.response?.status ?? null));
            return closePopup(onSuccessClose)
        }
        return closePopup(onClose)
    }

    function getData() {
        dispatch(getAccountManagers({ role: ROLE.accountManager, branch_id: '' }));
        dispatch(getRecruiters({ role: ROLE.recruiter, branch_id: '' }));
        dispatch(getBusinessSectors());
        dispatch(getCompetitorsList());
    }

    function isInitializing() {
        return accountManagersListState.loading === LoadingType.pending ||
            recruitersListState.loading === LoadingType.pending ||
            businessSectorListState.loading === LoadingType.pending ||
            competitorsListState.loading === LoadingType.pending ||
            skillSetListState.loading === LoadingType.pending ||
            credentialsListState.loading === LoadingType.pending ||
            certificationsListState.loading === LoadingType.pending ||
            educationListState.loading === LoadingType.pending ||
            requiredEquipmentListState.loading === LoadingType.pending ||
            talentStatusState.loading === LoadingType.pending || 
            workCompCodeState.loading === LoadingType.pending ||
            talentSourceState.loading === LoadingType.pending;
    }

    function isLoading() {
        return createState.loading ||
            updateState.loading ||
            uploadURLState.loading ||
            uploadFileState.loading;
    }

    function isError() {
        return createState.error ||
            updateState.error ||
            uploadURLState.error ||
            uploadFileState.error;
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={handleClosePopup}>
                {isInitializing() ? <div className="loading-spinner"><SpinnerScreen /></div> : <TalentForm
                    title={props.title}
                    successTitle={props.successTitle}
                    currentLoginUserName={`${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`}
                    talent={props.talent}
                    accountManagers={accountManagersList}
                    recruiters={recruitersList}
                    businessSectors={businessSectorList}
                    competitors={competitorsList}
                    loading={isLoading()}
                    error={isError()}
                    onClearError={handleClearError}
                    onClose={() => closePopup(onClose)}
                    onSubmit={handleSubmit}
                    skillSetList={skillSetList}
                    credentialsList={credentialsList}
                    certificationsList={certificationsList}
                    educationList={educationList}
                    requiredEquipmentList={requiredEquipmentList}
                    /// Photo
                    selectedPhoto={contactLogo}
                    onPhotoChange={setContactLogo}
                    onRemovePhoto={() => setContactLogo(null)}
                    /// Resume
                    selectedResume={contactResume}
                    onResumeChange={setContactResume}
                    onRemoveResume={() => setContactResume(null)}

                    onGotoAgencyDashboard={handleGotoAgencyDashboard}
                    onCreateNew={handleCreateNew}
                    onGotoCreated={handleGotoCreated}
                    isTalent={profileState.profile?.is_talent}
                    showSSN={showSSN}
                    talentStatusList={talentStatusList}
                    workCompCodeList={workCompCodeList}
                    talentSourceList={talentSourceList}
                />}
            </DialogWrapper>
        </ActionDialogHolder>

    );

    ///Handle clear error
    function handleClearError() {
        dispatch(manageTalentActions.clearManageTalentStateError());
    }

    /// handle submit
    function handleSubmit(doc: { forceAdd: boolean, value: ICreateTalent }) {
        if (props.talent && props.talent.id) {
            dispatch(updateTalent({ ...props.talent, ...doc.value, }));
        } else {
            dispatch(createTalent(doc));
        }
    }

    function handleGotoAgencyDashboard() {
        closePopup(props.onGotoAgencyDashboard);
    }


    function handleCreateNew() {
        setContactLogo(null);
        setContactResume(null);
        dispatch(getTalentList());
        dispatch(manageTalentActions.clearManageTalentState());
    }


    function handleGotoCreated(name: string) {
        if (props.talent && props.talent.id) {
            closePopup(onSuccessClose);
        } else if (createState.response && createState.response.object_id) {
            closePopup(() => props.onGotoCreated(createState?.response?.object_id!, name));
        }
    }


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default CreateTalentPopup;
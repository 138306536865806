import { useTranslation } from "react-i18next";
import { getCompaniesDropdown, getDepartmentsDropdown, getRoleUsersList, selectAccountManagerRoleUsersList, selectCompaniesDropdownList, selectDepartmentsDropdownList, selectRecruiterRoleUsersList, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useEffect, useState } from "react";
import { departmentsDropdownListActions } from "../../../../redux/selectors/departments_list/departments_list_reducer";
import { IReportCenterDownloadUrlParams, IReportCenterDownloadUrlRequestBody, IReportCenterUploadUrlResponse } from "../../../../interfaces/report_center";
import { LoadingType, THEME } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import { convertDateToTimeStamp, getDateString } from "../../../../variables";
import { IReportCenterService } from "../../../../interfaces/report_center_service";
import { AppRoutes } from "../../../../routes";
import { RouteComponentProps } from "react-router-dom";
import DatePicker from "react-datepicker";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../components/table_empty/table_empty";
import SortableTable, { IHeaderListProps, TableData } from "../../../../components/sortable_table/sortable_table";
import { CalenderIcon, DownloadIcon, TickGreenIcon, TickGreyIcon } from "../../../../icons";
import TabOptionsHeader from "../../../../components/tab_options_header/tab_options_header";
import CustomFormSelect, { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import { CustomButton } from "../../../../utils";
import './service_section_page.scss';
import SearchBar from "../../../../components/search_bar";
import { reportDownloadSliceActions } from "../../../../redux/report_center/download_report/download_report_reducer";
import ApiError from "../../../../components/api_error";


interface Props {
    tableHeader: Array<IHeaderListProps>;
    reportListState: any;
    loading: boolean;
    reportList: IReportCenterService[];
    history: RouteComponentProps["history"];
    companiesList: {
        label: string;
        value: string;
    }[];
    downloadUrlState: {
        error?: AppError | null;
        loading: LoadingType;
        response: IReportCenterUploadUrlResponse | null;
    };
    tableHeaderTitle: string;
    getReportList: ({ start_date, end_date }: { start_date: number, end_date: number }) => void;
    handleDownload: ({ params, requestBody }: { params: IReportCenterDownloadUrlParams, requestBody?: IReportCenterDownloadUrlRequestBody }) => void;
    onClearError: () => void;

}
const ServiceReportSection: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { downloadUrlState, reportListState, loading, reportList, tableHeader, tableHeaderTitle, companiesList, getReportList, handleDownload, onClearError } = props

    const departmentsList = useAppSelector((state) => selectDepartmentsDropdownList(state));
    const accountManagersList = useAppSelector((state) => selectAccountManagerRoleUsersList(state));
    const recruitersList = useAppSelector((state) => selectRecruiterRoleUsersList(state));

    const [company, setCompany] = useState<string>("");
    const [department, setDepartment] = useState<string>("");
    const [accountManagers, setAccountManagers] = useState<string[]>([]);
    const [recruiters, setRecruiters] = useState<string[]>([]);
    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>({ available_from: convertDateToTimeStamp(thirtyDaysAgo, true), available_to: convertDateToTimeStamp(new Date(), true) })
    const [dateRange, setDateRange] = useState([thirtyDaysAgo, new Date()]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);

    useEffect(() => {
        dispatch(getRoleUsersList());
    }, []);

    useEffect(() => {
        if (company) {
            dispatch(getDepartmentsDropdown(company));
        }
        else {
            dispatch(
                departmentsDropdownListActions.clearDepartmentsDropdownListState()
            );
            setDepartment("");
        }
    }, [company]);

    useEffect(() => {
        if ((formState.available_from !== 0 && formState.available_to !== 0) || ((formState.available_from === 0 && formState.available_to === 0))) {
            getReportList({ start_date: formState.available_from, end_date: formState.available_to });
        }
    }, [formState.available_from, formState.available_to]);

    useEffect(() => {
        if ((formState.available_from !== 0 && formState.available_to !== 0)) {
            setDatePickerOpen(false)
        }
        if ((formState.available_from === 0 && formState.available_to === 0)) {
            openDatePicker();
        }
    }, [formState.available_from, formState.available_to])

    useEffect(() => {
        if (downloadUrlState.loading === LoadingType.succeeded && downloadUrlState.response?.url &&
            downloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', downloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [downloadUrlState.loading]);

    function handleChange(value) {
        setFormState({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setDateRange(value);

    }

    const openDatePicker = () => {
        setDatePickerOpen(true);
    };

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };
    function handleCompanySelect(value: IReportCenterService) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            },
        });
        return;
    }
    function handleDepartmentSelect(value: IReportCenterService) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
                departmentId: value.department_id,
                departmentName: value.department_name,
            },
        });
        return;
    }
    function handleJobTitleSelect(value: IReportCenterService) {
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${value.job_order_id}`,
            state: {
                id: value.job_order_id,
                name: value.job_title,
            },
        });
        return;
    }

    function handleTalentSelect(value: IReportCenterService) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
            state: {
                id: value.talent_id,
                name: value.talent_name,
            },
        });
        return;
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        return 1;
    }

    const showSpinner = () => reportListState?.loading === LoadingType.pending;

    const getFilteredList = () => {
        let list: IReportCenterService[] = [...reportList || []];
        let sortedList: IReportCenterService[] | undefined;
        
        sortedList = list?.filter(doc => {
            // Check for criteria
            const hasCompany = company && company !== "";
            const hasDepartment = department && department !== "";
            const hasAccountManagers = accountManagers.length > 0;
            const hasRecruiters = recruiters.length > 0;

            // Check company and department filters
            const companyMatch = !hasCompany || doc.company_id === company;
            const departmentMatch = !hasDepartment || doc.department_id === department;

            // Check account managers filter
            const accountManagersMatch = !hasAccountManagers || (
                doc.account_managers &&
                doc.account_managers.length > 0 &&
                doc.account_managers.some(manager => accountManagers.includes(manager))
            );

            // Check recruiters filter
            const recruitersMatch = !hasRecruiters || (
                doc.recruiters &&
                doc.recruiters.length > 0 &&
                doc.recruiters.some(recruiter => recruiters.includes(recruiter))
            );

            // Return true if all required conditions are met
            return companyMatch && departmentMatch && accountManagersMatch && recruitersMatch;
        });

        if (sortedField !== null) {
            sortedList = [...(sortedList ?? list)].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return (sortedList ?? list)?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }

    const getTable = () => {
        if (showSpinner()) return <TableLoading />;

        if (reportListState?.error != null && reportList?.length === 0) {
            return <TableErrorHandler error={reportListState?.error} onRefresh={() => getReportList({ start_date: formState.available_from, end_date: formState.available_to })} />;
        }

        if (reportList?.length === 0 || getFilteredList()?.length === 0) {
            return <TableEmpty title={"No reports found"} onClick={() => getReportList({ start_date: formState.available_from, end_date: formState.available_to })} />;
        }

        if (getFilteredList()?.length !== 0) {
            return (
                <div className="ssp-table">
                    <SortableTable
                        headerList={tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {getFilteredList()?.map((doc) => {
                            return (
                                <tr key={doc.id}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}
                                        isButton={true}
                                        onClick={() => handleCompanySelect(doc)}
                                    >
                                        <span>{doc.company_name}</span>
                                    </TableData>
                                    <TableData
                                        customStyle={{ flex: getFlexNumber(1) }}
                                        isButton={true}
                                        onClick={() => handleDepartmentSelect(doc)}
                                    >
                                        <span>{doc.department_name !== "" && doc.department_name !== null ? doc.department_name : t("corporate")}</span>
                                    </TableData>
                                    <TableData
                                        customStyle={{ flex: getFlexNumber(2) }}
                                        isButton={true}
                                        onClick={() => handleTalentSelect(doc)}
                                    >
                                        <span>{doc.talent_name}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                        <span>{doc.manager}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(4) }}
                                        isButton={true}
                                        onClick={() => handleJobTitleSelect(doc)}
                                    >
                                        <span>{doc.job_title}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                        <span>{getDateString(doc.date_of_scheduled_call)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                        <span>{doc.dm_reached ? <TickGreenIcon /> : <TickGreyIcon />}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                        <span>{doc.call_result}</span>
                                    </TableData>
                                </tr>
                            )
                        })}
                    </SortableTable>
                </div>
            );
        }
    };

    return (
        <div className="reports-container">
            <div className="reports-header">
                <div className="main-header">
                    <div className='cmp-dropdown'>
                        <div className="dateRange">
                            <label>{t("date_range")}</label>
                            <div className="date-input-icon">
                                <div className="calendar-icon-div">
                                    <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={openDatePicker} />
                                </div>
                                <DatePicker
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={handleChange}
                                    isClearable={loading ? false: true}
                                    placeholderText={'Start Date -  End Date'}
                                    dateFormat={'MM.dd.yyyy'}
                                    onClickOutside={() => setDatePickerOpen(false)}
                                    open={isDatePickerOpen}
                                    onFocus={() => setDatePickerOpen(true)}
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    dropdownMode="select"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='cmp-dropdown'>
                        <CustomFormSelect
                            label={t("customer_name")}
                            name={"company_id"}
                            value={company}
                            placeholder={"All"}
                            list={companiesList?.map((doc) => ({
                                label: doc.label,
                                value: doc.value,
                            }))}
                            onChange={(value) => {
                                setCompany(value);
                            }}
                            disabled={loading}
                        />
                    </div>
                    <div className='cmp-dropdown'>
                        <CustomFormSelect
                            label={t("department")}
                            name={"department_id"}
                            value={department}
                            placeholder={"All"}
                            list={departmentsList?.map((doc) => ({
                                label: doc.name,
                                value: doc.id,
                            }))}
                            onChange={(value) => {
                                setDepartment(value);
                            }}
                            disabled={company && company !== "" ? false : true || loading}
                        />
                    </div>
                    <div className='cmp-dropdown'>
                        <CustomMultiFormSelect
                            label={t("account_manager")}
                            name={"account_manager"}
                            value={accountManagers}
                            placeholder={"All"}
                            list={accountManagersList?.map((doc) => ({
                                label: `${doc.first_name ?? ''} ${doc.last_name ?? ''}`,
                                value: doc.id,
                            }))}
                            onChange={(value) => {
                                setAccountManagers(value);
                            }}
                            disabled={loading}
                        />
                    </div>
                    <div className='cmp-dropdown'>
                        <CustomMultiFormSelect
                            label={t("recruiter")}
                            name={"recruiter"}
                            value={recruiters}
                            placeholder={"All"}
                            list={recruitersList.map((doc) => ({
                                label: `${doc.first_name ?? ''} ${doc.last_name ?? ''}`,
                                value: doc.id
                            }))}
                            onChange={(value) => {
                                setRecruiters(value);
                            }}
                            disabled={loading}
                        />
                    </div>
                    {reportListState && reportListState?.error && <div className="error-holder">
                        <ApiError message={reportListState?.error?.message} onClose={onClearError} />
                    </div>}
                </div>
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>

            <div className="table-container">
                <div className="header-container">
                    <div className="table-header">
                        <div className="table-header-title">
                            <span>{t(tableHeaderTitle)}</span>
                        </div>
                    </div>
                    <div className="report-btns">
                        <div className="total-count-action">
                            <span>
                                {`${t("total_count")}: `}
                                <span className="total-count-number">
                                    {getFilteredList()?.length}
                                </span>
                            </span>
                        </div>
                        <div className="download-btn">
                            <CustomButton
                                leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                                loading={false}
                                name={t("download")}
                                enable={getFilteredList()?.length !== 0 && (loading ? false : true)}
                                backgroundColor={THEME.buttonColor16}
                                onClick={() => handleDownload(formState && {
                                    params: { company_id: company, department_id: department, download: true, start_date: formState.available_from, end_date: formState.available_to },
                                    requestBody: { recruiter_ids: recruiters, account_manager_ids: accountManagers }
                                })}
                            />
                        </div>
                        <div>
                            <SearchBar
                                value={search}
                                onChange={setSearch}
                                onSearch={() => { }}
                            />
                        </div>
                    </div>
                </div>
                {getTable()}
            </div>
        </div>
    );


}

export default ServiceReportSection;
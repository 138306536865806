import React, { ChangeEvent, useEffect, useState } from "react";

import "./contacts_assignment_page.scss";
import SearchBar from "../../../../../components/search_bar";
import {
  CustomButton,
  CustomCheckBox,
  getTableAccessIcon,
  getTextEmAllIcon,
} from "../../../../../utils";
import { LoadingType, TALENT_STATUS, THEME } from "../../../../../enums";
import { AddCircleOutline } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import SortableTable, {
  TableData,
} from "../../../../../components/sortable_table/sortable_table";
import {
  getTEADraftBroadCast,
  selectProfileState,
  selectTextEmAllState,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store";
import {
  getContactAssignmentsListByPageById,
  selectContactAssignmentsState,
} from "../../../../../redux/contacts/details/assignments";
import TableEmpty, {
  TableErrorHandler,
  TableLoading,
} from "../../../../../components/table_empty/table_empty";
import { AppRoutes } from "../../../../../routes";
import { Rating } from "@material-ui/lab";
import { currencyConversion } from "../../../../../utils";
import { Assignment, IAssignmentsByPage } from "../../../../../interfaces";
import { TEADraftBroadCast } from "../../../../../interfaces/text_em_all";
import { Portal } from "react-portal";
import BroadcastToTalentPopup from "../../../../../components/broadcast_talent/broadcast_talent";
import { textEmAllSliceActions } from "../../../../../redux/textEmAll/text_Em_All_reducer";
import BroadcastToTalentErrorPopup from "../../../../../components/broadcast_talent/broadcast_talent_error";
import Pagination from "../../../../components/pagination/pagination";
import { CustomMultiFormSelect } from "../../../../../components/form_selector/form_select";
import { statusOptions } from "../../../../../variables";


const ContactsAssignmentPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id, departmentId } = props.match.params;
  const profileState = useAppSelector((state) => selectProfileState(state));
  const textEmAllState = useAppSelector((state) => selectTextEmAllState(state));

  const canSendText =
    profileState?.profile?.texting &&
    profileState?.profile?.text_em_all_token !== null;
  const [totalCount, setTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [sortedField, setSortedField] = useState<null | string>(null);

  const [sortDirection, setSortDirection] = useState("asc");

  const [sentRequest, setSentRequest] = useState(false);

  const assignmentState = useAppSelector((state) => selectContactAssignmentsState(state));
  const [broadcastTalent, setBroadcastTalent] = useState<boolean>(false);
  const [broadcastTalentError, setBroadcastTalentError] =
    useState<boolean>(false);
  const [contactAssignmentsList, setContactAssignmentsList] = useState<IAssignmentsByPage | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(100);
  const [status, setStatus] = useState<string[]>(["ACTIVE"]);

  useEffect(() => {
    dispatch(textEmAllSliceActions.clearState());
    return () => { };
  }, []);

  useEffect(() => {
    // Timer ID to keep track of the setTimeout
    const timeoutId = setTimeout(() => {
      if (searchValue?.length === 0 || searchValue?.length >= 2) {
        callAPI({ pageNum: 1 });
      }
    }, 500);  // 500ms debounce delay, adjust as needed

    // Cleanup function to clear the timeout if the search value changes before the timeout is reached
    return () => clearTimeout(timeoutId);

  }, [searchValue]);  // Trigger the effect whenever the search value changes

  useEffect(() => {
    if (assignmentState && assignmentState.loading === LoadingType.succeeded && assignmentState.response) {
      setContactAssignmentsList(assignmentState?.response);
      setTotalCount(assignmentState.response.total)
    }
  }, [assignmentState.loading, assignmentState.response]);

  useEffect(() => {
    if (
      textEmAllState &&
      textEmAllState?.error !== null &&
      textEmAllState?.loading === LoadingType.failed
    ) {
      setBroadcastTalentError(true);
    }
    if (
      textEmAllState &&
      textEmAllState?.error === null &&
      textEmAllState?.loading === LoadingType.succeeded
    ) {
      setBroadcastTalent(true);
    }
    if (
      textEmAllState &&
      textEmAllState?.error === null &&
      textEmAllState?.loading === LoadingType.idle
    ) {
      setBroadcastTalent(false);
      setBroadcastTalentError(false);

    }
  }, [textEmAllState, textEmAllState.error]);
  const [formState] = useState<TEADraftBroadCast>({
    modified_by: "",
    created_by: "",
    modified_date: 0,
    talent_ids: [],
  });
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const [showErrorMsg, setShowErrorMsg] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<
    Map<string, { id: string; name: string }>
  >(new Map());

  const handleMarkItem = (value: Assignment) => {
    const newSet = new Map(selectedItems);
    if (selectedItems.has(value.id)) {
      newSet.delete(value.id);
    } else {
      newSet.set(value.id, {
        id: value.talent_id,
        name: value.talent_name,
      });
    }
    setSelectedItems(newSet);
  };
  const handleMarkAllItem = () => {
    const newSet = new Map<string, { id: string; name: string }>();
    if (selectedItems.size === getFilteredList().length) {
      setSelectedItems(newSet);
    } else {
      getFilteredList().forEach((doc) =>
        newSet.set(doc.id, {
          id: doc.talent_id,
          name: doc.first_name + " " + doc.last_name,
        })
      );
      setSelectedItems(newSet);
    }
  };

  function handleSearchChange(value) {
    setSearchValue(value);
  }

  function handleSortChange(value) {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  }

  function getFilteredList() {
    let list;
    if (!contactAssignmentsList?.items) return [];
    if (sortedField != null) {
      list = [...contactAssignmentsList?.items].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] != undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }
    return list ?? contactAssignmentsList?.items!;
  }

  function callAPI(doc?: { pageNum?: number, pageSize?: number, status_ids?: Array<string> }) {
    let data: any = {
      contact_id: id,
      page: doc?.pageNum ?? currentPage,
      size: doc?.pageSize ?? size,
      search_str: searchValue,
      filter_ids: {statuses :doc?.status_ids ?? status}
    };
    dispatch(getContactAssignmentsListByPageById(data));
    setCurrentPage(doc?.pageNum ?? currentPage);
  }

  function handleRetry() {
    callAPI();
  }

  function handleAccessAssignment(assignment) {
    props.history.push(
      `${AppRoutes.assignmentsDetailsPage}/${assignment.id}/dashboard`
    );
  }

  useEffect(() => {
    if (
      assignmentState.loading === LoadingType.succeeded &&
      sentRequest &&
      contactAssignmentsList?.items
    ) {
      setSentRequest(false);
    }
  }, [assignmentState.loading]);



  const handleBroadCastTalent = (forceSend?: boolean) => {
    if (selectedItems.size !== 0) {
      const talentids_list = Array.from(selectedItems.values());
      dispatch(
        getTEADraftBroadCast({ forceSend: forceSend, data: { ...formState, talent_ids: talentids_list.map(ids => ids.id) } })
      );
      setShowMsg(false);
    } else {
      setShowMsg(true);
      setShowErrorMsg(t("please_select_talent_to_broadcast"));
      setTimeout(() => setShowErrorMsg(""), 5000);
    }
  };

  const handlePageChange = (pageNum: number, size: number) => {
    callAPI({ pageNum: pageNum, pageSize: size })
    setSize(size ? size : contactAssignmentsList?.size!)
  };

  const handleStatus = (value) => {
    setStatus(value)
    callAPI({ pageNum: 1, status_ids: value });
  }

  return (
    <div className={"con-asmnt-main-container"}>
      <div className={"con-asmnt-header"}>
        <div className={"con-asmnt-header-left"}>
          <div className="search-bar">
            <SearchBar
              value={searchValue}
              onChange={handleSearchChange}
              onSearch={()=>{}}
            />
          </div>
          <div>
            <CustomMultiFormSelect
              label={t("status")}
              name={"company_status"}
              list={[...statusOptions]}
              onChange={handleStatus}
              required={false}
              placeholder={t("all")}
              value={status}
            />
          </div>
          <div className={"total-count"}>
            <span>
              {`${t("total_count")}: `}
              <span className="total-count-number">{totalCount}</span>
            </span>
          </div>
        </div>
        <div>
          <CustomButton
            leftIcon={getTextEmAllIcon()}
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("broadcast_to_talent")}
            enable={canSendText ? true : false}
            onClick={handleBroadCastTalent}
          />
          {showMsg && <span className="select_talent_msg">{showErrorMsg}</span>}
        </div>
      </div>
      <div className="cap-table">
        {assignmentState.loading === LoadingType.pending && sentRequest ? (
          <TableLoading />
        ) : (
          <TableBody
            state={assignmentState}
            getFilteredList={getFilteredList}
            sortedField={sortedField}
            handleSortChange={handleSortChange}
            onRetry={handleRetry}
            data={contactAssignmentsList?.items}
            accessJob={handleAccessAssignment}
            handleUserSelect={handleUserSelect}
            handleCompanySelect={handleCompanySelect}
            handleDepartmentSelect={handleDepartmentSelect}
            handleJobSelect={handleJobSelect}
            handleTalentSelect={handleTalentSelect}
            sortDirection={sortDirection}
            handleMarkItem={handleMarkItem}
            handleMarkAllItem={handleMarkAllItem}
            selectedItems={selectedItems}
          />
        )}
      </div>
      <div className="cap-table-footer">
        <Pagination
          totalPages={contactAssignmentsList?.pages!}
          onPageChange={handlePageChange}
          curPageNum={currentPage}
          totalCount={totalCount}
          size={contactAssignmentsList?.size!}
          loadingState={assignmentState?.loading}
        />
      </div>
      {canSendText &&
        broadcastTalentError &&
        textEmAllState &&
        textEmAllState?.error !== null && (
          <Portal>
            <BroadcastToTalentErrorPopup
              error={textEmAllState?.error}
              onClose={() => {
                setBroadcastTalentError(false);
                dispatch(textEmAllSliceActions.clearState());
              }}
              visible={broadcastTalentError}
              title={t("something_went_wrong")}
              loading={textEmAllState?.loading === LoadingType.pending}
              onForceSend={handleBroadCastTalent}
            />
          </Portal>
        )}
      {canSendText && broadcastTalent && (
        <Portal>
          <BroadcastToTalentPopup
            visible={broadcastTalent}
            title={""}
            successTitle={""}
            onClose={() => {
              setBroadcastTalent(false);
              dispatch(textEmAllSliceActions.clearState());
            }}
            onSuccessClose={() => { }}
            responseURL={textEmAllState?.response}
            loading={textEmAllState?.loading === LoadingType.pending}
          />
        </Portal>
      )}
    </div>
  );
  function handleUserSelect(value: Assignment) {
    props.history.push({
      pathname: `${AppRoutes.assignmentsDetailsPage}/${value.id}`,
      state: {
        id: value.id,
        name: `${value.talent_name}-${value.job_title}`,
      },
    });
    return;
  }
  function handleCompanySelect(value: Assignment) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
      },
    });
    return;
  }

  function handleDepartmentSelect(value: Assignment) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.department_id,
        departmentName: value.department_name,
      },
    });
    return;
  }

  function handleJobSelect(value: Assignment) {
    props.history.push({
      pathname: `${AppRoutes.jobDetailsPage}/${value.job_order_id}`,
      state: {
        id: value.job_order_id,
        name: value.job_title,
      },
    });
    return;
  }

  function handleTalentSelect(value: Assignment) {
    props.history.push({
      pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
      state: {
        id: value.talent_id,
        name: value.talent_name,
      },
    });
    return;
  }
};

const TableBody = ({
  state,
  getFilteredList,
  sortedField,
  handleSortChange,
  onRetry,
  data,
  accessJob,
  handleUserSelect,
  handleCompanySelect,
  handleDepartmentSelect,
  handleJobSelect,
  handleTalentSelect,
  sortDirection,
  handleMarkAllItem,
  handleMarkItem,
  selectedItems,
}) => {
  const { t } = useTranslation();
  const SelectAllCheckBox = () => {
    return (
      <CustomCheckBox
        name={""}
        title={""}
        checked={selectedItems.size === getFilteredList().length}
        onClick={handleMarkAllItem}
      />
    );
  };
  const headers = [
    { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
    { title: "access_assignment", code: "access_assignment" },
    { title: "talent", code: "talent_name" },
    { title: "company", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "job_title", code: "job_title" },
    { title: "rating", code: "performance_rating" },
    { title: "status", code: "status" },
    { title: "pay_rate", code: "regular_pay_rate" },
    { title: "bill_rate", code: "regular_bill_rate" },
  ];
  function getTableData(doc, key) {
    if (key === "performance_rating")
      return (
        <span>
          <Rating name="read-only" value={doc[key]} readOnly />
        </span>
      );
    else if (key === "regular_bill_rate" || key === "regular_pay_rate")
      return <span>{currencyConversion(doc[key])}</span>;
    else if (key === "status") {
      if (doc[key] === TALENT_STATUS.active)
        return <span style={{ color: "#00BE4B" }}>{t("active")}</span>;
      return <span style={{ color: "#b1b1b1" }}>{t("inactive")}</span>;
    }
    return <span>{doc[key]}</span>;
  }

  if (state.loading === LoadingType.failed) {
    return <TableErrorHandler error={state.error} onRefresh={onRetry} />;
  } else if (data && data.length === 0) {
    return <TableEmpty title={"No data found"} onClick={onRetry} />;
  }
  return (
    <SortableTable
      headerList={headers}
      sortedField={sortedField}
      onSortChange={handleSortChange}
      flexNumber={getFlexNumber}
      isAsc={sortDirection}
    >
      {getFilteredList().map((doc, index) => {
        return (
          <tr key={"cmp_job_order_row" + index}>
            <TableData customStyle={{ flex: getFlexNumber(0) }}>
              <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.has(doc.id)}
                onClick={() => handleMarkItem(doc)}
              />
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(1) }}>
              {getTableAccessIcon(() => handleUserSelect(doc))}
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(2) }}
              isButton={true}
              onClick={() => handleTalentSelect(doc)}
            >
              {getTableData(doc, "talent_name")}
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(3) }}
              isButton={true}
              onClick={() => handleCompanySelect(doc)}
            >
              {getTableData(doc, "company_name")}
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(4) }}
              isButton={true}
              onClick={() => handleDepartmentSelect(doc)}
            >
              {doc.department_name
                ? getTableData(doc, "department_name")
                : t("corporate")}
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(5) }}
              isButton={true}
              onClick={() => handleJobSelect(doc)}
            >
              {getTableData(doc, "job_title")}
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(6) }}>
              {getTableData(doc, "performance_rating")}
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(7) }}>
              {getTableData(doc, "status")}
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(8) }}>
              {getTableData(doc, "regular_pay_rate")}
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(9) }}>
              {getTableData(doc, "regular_bill_rate")}
            </TableData>
          </tr>
        );
      })}
    </SortableTable>
  );
  function getFlexNumber(value: number) {
    if (value === 0) return 0.3;
    if (value === 1) return 1.3;
    if (value === 2) return 1;
    if (value === 3) return 1;
    if (value === 4) return 1;
    if (value === 5) return 1;
    if (value === 6) return 1;
    if (value === 7) return 1;
    if (value === 8) return 1;
    if (value === 9) return 1;
    if (value === 10) return 1;
    return 1;
  }
};

export default ContactsAssignmentPage;

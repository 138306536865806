import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import * as thunk from "./employee_list_thunk";
import { IEmplyeesDropdownList } from "../../../interfaces/report_center_payroll_tax";

export interface EmployeesDropdownListState extends EntityState<IEmplyeesDropdownList> {
    error?: AppError | null,
    loading: LoadingType,
}

const employeesDropdownListAdapter: EntityAdapter<IEmplyeesDropdownList> = createEntityAdapter<IEmplyeesDropdownList>({
    selectId: (doc) => doc.id,
})

const initialState: EmployeesDropdownListState = employeesDropdownListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const employeesDropdownListSlice = createSlice({
    name: 'employeesDropdown',
    initialState: initialState,
    reducers: {
        clearEmployeesDropdownListError(state){
            return {...state, error: null};
        },
        clearEmployeesDropdownListState(state){
            return employeesDropdownListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getEmployeeDropdownList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getEmployeeDropdownList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getEmployeeDropdownList.fulfilled, (state, action) => employeesDropdownListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const talentsDropdownListActions = employeesDropdownListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = employeesDropdownListAdapter.getSelectors();
export const selectState = (state: EmployeesDropdownListState) => state;
export const selectLoading = (state: EmployeesDropdownListState) => state.loading;
export const selectError = (state: EmployeesDropdownListState) => state.error;



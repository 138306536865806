import React, { useState, useEffect } from 'react';
import { MenuItem, InputLabel, Select, FormControl, Switch, Input } from '@material-ui/core';
import { postSnackbarMessage, useAppDispatch, useAppSelector } from '../../../redux/store';
import { getAdminCenterSkills, getAdminCenterBusinessSector, selectSkillListState, selectBusinessSectorState, getAdminCenterCertifications, getAdminCenterCredentials, getAdminCenterEducationRequirements, getAdminCenterEquipment, selectDelete_State, deleteSkill, deleteCertification, deleteCredentials, deleteEducationRequirement, deleteEquipment } from '../../../redux/admin_center';
import '../user_management/user_management_page.scss';
import './system_configuration_professional_skills_page.scss';
import TabOptionsHeader from '../../../components/tab_options_header/tab_options_header';
import { CustomButton, SpinnerScreen, getPlusBtnIcon } from '../../../utils';
import { LoadingType, THEME } from '../../../enums';
import { useTranslation } from 'react-i18next';
import { AddCircleOutline, ArrowUpward, Category } from '@material-ui/icons';
import SortableTable, { TableData } from '../../../components/sortable_table/sortable_table';
import { RouteComponentProps } from 'react-router-dom';
import { DeleteIcon, EditIcon } from '../../../icons';
import SystemCategoryDetails from './system_configuration_add_category';
import { Portal } from 'react-portal';
import { manageSkillListActions } from '../../../redux/admin_center/system_configuration/skill_list/skill_list_reducer';
import { DeleteConfirmationDialog } from '../../components/delete_confirmation_dialog';
import CustomFormSelect from '../../../components/form_selector/form_select';

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
  { title: 'Value', code: '' },
  { title: 'Actions', code: '' },
];

const TableLoading = (props) => {
  return (
    <div className="table-loading">
      <SpinnerScreen />
    </div>
  );
}

const SystemConfigurationProfessionalSkillsPage: React.FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { loading, error, skillSet, certifications, credentials, equipments, educationRequirements } = useAppSelector((state) => selectSkillListState(state));
  const skillSetDeleteState = useAppSelector((state) => selectDelete_State(state));
  const businessSector = useAppSelector(state => selectBusinessSectorState(state));
  const [businessSectors, setBusinessSectors]: any = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [selectedSector, setSelectedSector]: any = useState(null);
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [search, setSearch] = useState('');
  const [buttonLabel, setButtonLabel] = useState("Professional Skills");
  const [gridData, setGridData]: any = useState([]);
  const [showCategoryDialog, setShowCategoryDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedData, setSelectedData]: any = useState({});
  const { selectedId, setSelectedId }: any = useState("");
  const categories = [
    { id: 1, label: "Professional Skills" },
    { id: 2, label: "Certifications" },
    { id: 3, label: "Credentials" },
    { id: 4, label: "Education Requirements" },
    { id: 5, label: "Equipment" },
  ];

  useEffect(() => {
    dispatch(getAdminCenterSkills());
    dispatch(getAdminCenterBusinessSector());
  }, []);

  useEffect(() => {
    if (selectedCategory === 1 && skillSet)
      setGridData(skillSet);
    if (selectedCategory === 2 && certifications)
      setGridData(certifications);
    if (selectedCategory === 3 && credentials)
      setGridData(credentials);
    if (selectedCategory === 4 && educationRequirements)
      setGridData(educationRequirements);
    if (selectedCategory === 5 && equipments)
      setGridData(equipments);

  }, [skillSet, certifications, credentials, equipments, educationRequirements])

  useEffect(() => {
    if (businessSector && businessSector.dataList?.length > 0) {
      setBusinessSectors(businessSector.dataList);
      setSelectedSector(businessSector.dataList[0].id);
    }
  }, [businessSector]);

  useEffect(() => {
    if (skillSetDeleteState?.loading === LoadingType.succeeded) {
      dispatch(postSnackbarMessage(skillSetDeleteState?.response ?? null));
      setShowDeleteDialog(false);
      dispatch(manageSkillListActions.clearRemoveState());
    }
    return () => { }
  }, [skillSetDeleteState?.loading])

  const addEditClick = (type: number, data: any) => {
    setSelectedData({
      id: type === 1 ? "" : data.id,
      value: type === 1 ? "" : data.value,
      sector_id: selectedSector,
      agency_id: "",
    })
    setShowCategoryDialog(true)
  }

  function handleClearError() {
    dispatch(manageSkillListActions.clearRemoveState());
  }

  const handleSkillsDropdownChange = (value) => {
    setSelectedCategory(value);
    setButtonLabel((categories.filter((category: any) => category.id === value))[0].label);
    if (value === 1)
      dispatch(getAdminCenterSkills())
    if (value === 2)
      dispatch(getAdminCenterCertifications())
    if (value === 3)
      dispatch(getAdminCenterCredentials());
    if (value === 4)
      dispatch(getAdminCenterEducationRequirements())
    if (value === 5)
      dispatch(getAdminCenterEquipment())
  }

  const handleBusinessSectorDropdownChange = (value) => {
    setSelectedSector(value)
  }


  function handleDeleteCategoryData() {
    if (selectedData.id != "") {
      switch (selectedCategory) {
        case 1:
          dispatch(deleteSkill(selectedData.id));
          break;
        case 2:
          dispatch(deleteCertification(selectedData.id));
          break;
        case 3:
          dispatch(deleteCredentials(selectedData.id));
          break;
        case 4:
          dispatch(deleteEducationRequirement(selectedData.id));
          break;
        case 5:
          dispatch(deleteEquipment(selectedData.id));
          break;
        default:
          break;
      }
    }

  }

  return (
    <div className="ac-sytm-cns-container">
      <div className="ac-sytm-cns-header">
        <TabOptionsHeader onClose={() => props.history.goBack()} />
      </div>
      <div className="ac-sc-top-header">
        <div className="ac-sc-fields">
          <CustomFormSelect
            name={t("skills_dropdown")}
            list={categories.map((doc) => ({
              label: doc.label,
              value: doc.id,
            }))}
            onChange={(value) => handleSkillsDropdownChange(value)}
            value={selectedCategory}
            placeholder={t('professional_skills')}
            label={t('please_select_skill_category')}
            customStyle={{ maxWidth: "20vw" }}
            isClearable={false}
          />
        </div>
        <div className="ac-sc-fields">
          <CustomFormSelect
            name={t("business_sector_dropdown")}
            list={businessSectors.map((doc) => ({
              label: doc.business_sector,
              value: doc.id,
            }))}
            onChange={(value) => handleBusinessSectorDropdownChange(value)}
            value={selectedSector}
            placeholder={t('General')}
            label={t('please_select_business_sector')}
            customStyle={{ maxWidth: "20vw" }}
            isClearable={false}
          />
        </div>
        <div style={{ width: "50%", float: "right", marginRight: 20 }}>
          <div className="ac-sc-actions">
            <CustomButton
              leftIcon={getPlusBtnIcon()}
              loading={false}
              name={t('add_label', { value: buttonLabel })}
              enable={businessSectors?.length > 0 && selectedCategory > 0}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => addEditClick(1, {})}
            />
          </div>
        </div>
      </div>
      <div className="ac-sytm-cns-content">
        <div className="ac-sytm-cns-table">
          {loading == LoadingType.pending ? <TableLoading /> :
            <SortableTable
              headerList={tableHeader}
              headerChildren={getTableHeaders()}
              sortedField={sortedField}
              onSortChange={handleSortFieldChange}
              isAsc={sortDirection}
            >
              {
                gridData && gridData.filter((h: any) => h.sector_id === selectedSector).length > 0 ?
                  gridData.filter((h: any) => h.sector_id === selectedSector).map((doc: any, index) => {
                    return (
                      <tr key={doc.id}>
                        {selectedCategory === 1 && <TableData customStyle={{ flex: 1 }}>{doc.skill_set}</TableData>}
                        {selectedCategory === 2 && <TableData customStyle={{ flex: 1 }}>{doc.certification}</TableData>}
                        {selectedCategory === 3 && <TableData customStyle={{ flex: 1 }}>{doc.credential}</TableData>}
                        {selectedCategory === 4 && <TableData customStyle={{ flex: 1 }}>{doc.education_requirement}</TableData>}
                        {selectedCategory === 5 && <TableData customStyle={{ flex: 1 }}>{doc.required_equipment}</TableData>}
                        <TableData customStyle={{ borderRight: 'none' }}>
                          <button className="edit-icon" >
                            <EditIcon width={"100%"} height={"100%"} onClick={() => addEditClick(2, {
                              id: doc.id,
                              value: selectedCategory === 1 ? doc.skill_set :
                                selectedCategory === 2 ? doc.certification :
                                  selectedCategory === 3 ? doc.credential :
                                    selectedCategory === 4 ? doc.education_requirement :
                                      doc.required_equipment
                            })} />
                          </button>
                          <button className="delete-icon" >
                            <DeleteIcon width={"100%"} height={"100%"}
                              onClick={() => {
                                setSelectedData(
                                  {
                                    id: doc.id,
                                    value: selectedCategory === 1 ? doc.skill_set :
                                      selectedCategory === 2 ? doc.certification :
                                        selectedCategory === 3 ? doc.credential :
                                          selectedCategory === 4 ? doc.education_requirement :
                                            doc.required_equipment
                                  }
                                );
                                setShowDeleteDialog(true);
                              }}
                            />
                          </button>
                        </TableData>
                      </tr>
                    );
                  })
                  :
                  <tr>
                    <td colSpan={2}><span style={{ textAlign: "center" }}>No records found</span></td>
                  </tr>
              }

            </SortableTable>}
        </div>
        {showCategoryDialog &&
          <Portal>
            <SystemCategoryDetails
              showCategoryDialog={showCategoryDialog}
              setShowCategoryDialog={(value) => {
                setShowCategoryDialog(value);
                dispatch(manageSkillListActions.clearCreateState());
              }}
              categoryId={selectedCategory}
              selectedData={selectedData}
            />
          </Portal>
        }
        {showDeleteDialog && (
          <Portal>
            <DeleteConfirmationDialog
              message={t('are_you_sure_you_want_to_delete_this_skill_set', { value: selectedData?.value })}
              onCancel={() => setShowDeleteDialog(false)}
              onConfirm={handleDeleteCategoryData}
              deleting={skillSetDeleteState?.loading === LoadingType.pending}
              deleteType={t("skill_set")}
              state={skillSetDeleteState}
              onClearError={handleClearError}
            />
          </Portal>
        )}
      </div>
    </div>
  )

  function handleSortFieldChange(value: string) {
    if (sortedField && sortedField === value) {
      // setSortedField(null);
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedField(value);
      setSortDirection('asc');
    }
  }

  function getTableHeaders() {
    return tableHeader.map((doc, index) => {
      return (
        <th
          key={index}
          className='tb-header-cc'
          style={{
            // flex: index == 1 ? 0 : 1,
            ...(index == tableHeader.length - 1) && {
              borderRight: 'none'
            },
          }}
          onClick={() => handleSortFieldChange(doc.code)}
        >
          <span>{t(doc.title)}</span>
          {sortedField ? <div
            className={`table-header-sort-icon ${(doc.code == sortedField) ? 'table-header-sort-icon-rotate' : ''}`}
          >
            <ArrowUpward fontSize={'inherit'} color={"inherit"} />
          </div> : <div></div>}
        </th>
      );
    });
  }
}

export default SystemConfigurationProfessionalSkillsPage;
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper from '../../../../components/dialog_wrapper/dialog_wrapper';
import { LoadingType, ROLE } from '../../../../enums';
import { ICreateTalentClientCompanyInterview, ITalentClientCompanyInterview } from '../../../../interfaces';
import {
    addNewTalentClientCompanyInterview,
    getRoleUsersList,
    getTalentInterviewStatusList,
    postSnackbarMessage,
    selectSingleInterviewsToClientCompaniesStateById,
    selectTalentInterviewStatusState,
    updateTalentClientCompanyInterview,
    useAppDispatch,
    useAppSelector,
    selectTalentInterviewStatusList,
    selectCompaniesListState,
    selectCompaniesList,
    getCompaniesList,
    selectJobsListState,
    selectJobsList,
    getJobsListThunk,
    getContactsAllList,
} from '../../../../redux/store';
import { talentClientCompanyInterviewActions } from '../../../../redux/talent/details/interviews/interviews_to_client_companies/interviews_to_client_companies_reducer';
import { SpinnerScreen } from '../../../../utils';
import ClientCompanyInterviewForm from '../../forms/client_company_interview_form/client_company_interview_form';
import './client_company_interview.scss';

interface Props {
    visible: boolean,
    title: string,
    talentId: string,
    jobdetails?: {job_id: string, company_id: string, department_id: string, name: string} | null,
    talentName: string,
    currentTalentName: string,
    interview?: ITalentClientCompanyInterview | null,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}

const ClientCompanyInterviewPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onSuccessClose,
        talentId,
        interview,
        jobdetails
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const actionRef = useRef<ActionDialogHolderType>(null);
    /// [Companies & Department]
    const companiesListState = useAppSelector((state) => selectCompaniesListState(state));
    const companiesList = useAppSelector((state) => selectCompaniesList(state));

    /// [Job order]
    // const jobListState = useAppSelector((state) => selectJobsListState(state));
    // const jobList = useAppSelector((state) => selectJobsList(state));

    ///[Interviews]
    const interviewStatusState = useAppSelector(state => selectTalentInterviewStatusState(state));
    const interviewStatusList = useAppSelector(state => selectTalentInterviewStatusList(state));
    const clientCompanyInterviewsState = useAppSelector((state) => selectSingleInterviewsToClientCompaniesStateById(talentId)(state));
    const createState = clientCompanyInterviewsState.add;
    const updateState = clientCompanyInterviewsState.update;


    useEffect(() => {
        getTalentInterviewStatus();
        getCompanies();
        // getJobOrders();
        getContacts();
        dispatch(getRoleUsersList({ branch_id: '', role: ROLE.recruiter }));
        return () => { }
    }, [])

    useEffect(() => {
        if (createState.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(createState.response ?? null));
            closePopup(onSuccessClose);
        }
        if (updateState.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(updateState.response ?? null));
            closePopup(onSuccessClose);
        }
        return () => { }
    }, [createState.loading, updateState.loading])

    

    function getTalentInterviewStatus() {
        dispatch(getTalentInterviewStatusList());
    }
    function getCompanies() {
        dispatch(getCompaniesList());
    }

    // function getJobOrders() {
    //     dispatch(getJobsListThunk());
    // }

    function getContacts() {
        dispatch(getContactsAllList());
    }

    function getLoading() {
        return interviewStatusState.loading === LoadingType.pending ||
            companiesListState.loading === LoadingType.pending 
            // || jobListState.loading === LoadingType.pending;
    }
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
            customStyle={{ maxWidth: '60%' }}
        >
            <DialogWrapper onClose={() => { closePopup(onClose); handleClearError(); }}>
                {interviewForm()}
            </DialogWrapper>
        </ActionDialogHolder>
    );
    function interviewForm() {
        if (getLoading()) {
            return (
                <div className="loading-spinner"><SpinnerScreen /></div>
            )
        }
        return (
            <ClientCompanyInterviewForm
                title={props.title}
                talentId={props.talentId}
                talentName={props.talentName}
                currentTalentName={props.currentTalentName}
                loading={(createState.loading === LoadingType.pending) || (updateState.loading === LoadingType.pending)}
                error={createState.error || updateState.error}
                interview={interview}
                jobdetails={jobdetails}
                interviewStatusList={interviewStatusList}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                onClearError={handleClearError}
            />
        )
    }

    ///Handle clear error
    function handleClearError() {
        dispatch(talentClientCompanyInterviewActions.clearSingleTalentInterviewsToClientCompaniesStateError(props.talentId));
    }

    function handleSubmit(value: ICreateTalentClientCompanyInterview, send_invite?: boolean) {
        if (interview && interview.id) {
            dispatch(updateTalentClientCompanyInterview({ talentId: talentId, data: { ...interview, ...value }, send_invite: send_invite }));
        } else {
            dispatch(addNewTalentClientCompanyInterview({ talentId: talentId, data: value, send_invite: send_invite }));
        }
    }

    function handleCancel() {
        handleClearError();
        closePopup(onClose);
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
}

export default ClientCompanyInterviewPopup;
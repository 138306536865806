import { useRef } from "react";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { TickBlueIcon, TickGreyIcon } from "../../../../icons";
import { t } from "i18next";
import { LoadingType, THEME } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import { CustomButton } from "../../../../utils";
import { useTranslation } from "react-i18next";
import ApiError from "../../../../components/api_error";
import "./approve_resolve_error.scss";


interface Props {
    visible: boolean,
    title: string,
    message: string,
    btnName: string,
    onClose: () => void,
    onDissmiss?: () => void,
    handleSubmit?: () => void;
    state?: {
        error?: AppError | null | undefined;
        loading: LoadingType;
        response: string | null;
    };
    handleClearError: () => void;
    handleOnSuccessClose: () => void;
}
const ApproveResolvePopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        onClose,
        onDissmiss,
        handleSubmit,
        state,
        message,
        btnName,
        handleClearError,
        handleOnSuccessClose

    } = props;
    const actionRef = useRef<ActionDialogHolderType>(null);
    const { t } = useTranslation();
    const closePopup = (action?: () => void) => {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
      }

      const actionClosePopup = ()=>{
        onClose(); 
        handleClearError()
      }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"35%"}
            maxWrapperWidth={"35%"}
            onClose={onClose}
            onDissmiss={onClose}>
            <DialogWrapper onClose={() => { closePopup(actionClosePopup) }}>
                <div className="ar-container">
                    <div className="ar-header">
                        <FormDialogTilteHeader
                            title={title}
                            titleIcon={<TickGreyIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="ar-content">
                        <div>
                            {state?.loading === LoadingType.succeeded ?
                                <span className="">
                                    {state?.response}
                                </span>
                                :
                                <span className="">
                                    {message}
                                </span>
                            }

                        </div>
                    </div>
                    {state?.error && <div className={"error-section"}>
                        <ApiError message={state?.error.message} onClose={handleClearError} />
                    </div>}
                    <div className="ar-actions">
                        {state?.loading === LoadingType.succeeded ?
                            (<>
                                <div className="btn-save">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={t("ok")}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={()=>{closePopup(handleOnSuccessClose)}}
                                    />
                                </div>
                            </>)
                            :
                            (<div className="ar-actions-row">
                                <div className="btn-cancel" style={{ marginRight: "1em" }}>
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={t("cancel")}
                                        enable={true}
                                        backgroundColor={THEME.toggleDisableColor}
                                        onClick={() => { closePopup(actionClosePopup) }}
                                    />
                                </div>
                                <div className="btn-save">
                                    <CustomButton
                                        loading={state?.loading === LoadingType.pending}
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={btnName}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={()=>{
                                            if(handleSubmit){
                                                handleSubmit()
                                            }
                                        }}
                                    />
                                </div>
                            </div>)}
                    </div>
                </div>
            </DialogWrapper >
        </ActionDialogHolder>
    )
}

export default ApproveResolvePopup;
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { NavLink, RouteComponentProps } from "react-router-dom";
import CustomFormSelect, {
  CustomMultiFormSelect,
} from "../../../../components/form_selector/form_select";
import SearchBar from "../../../../components/search_bar";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableFilterEmpty,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { LoadingType, ROLE, THEME } from "../../../../enums";
import { IContact, IContactsByPage } from "../../../../interfaces";
import { manageContactActions } from "../../../../redux/contacts/universal_directory/manage_contact/manage_contact_reducer";
import {
  getContactsByPage,
  getRoleUsersList,
  postSnackbarMessage,
  selectAccountManagerRoleUsersList,
  selectContactsListState,
  selectProfileState,
  selectRoleUsersListState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  getContactIcon,
  getPlusBtnIcon,
  AccessPicture,
  sortByOrders,
} from "../../../../utils";
import OpenOrderRow from "../../../../utils/open_orders";
import {
  getDaysAgoWithDateAMPM,
} from "../../../../variables";
import CreateContactPopup from "../../popups/create_contact/create_contact";
import "./universal_directory.scss";
import { getContactRoles, getContactStatus, selectContactRoleList, selectContactStatusList } from "../../../../redux/admin_center";
import { CircleDownloadIcon, DownloadIcon, UploadIcon } from "../../../../icons";
import UploadRecordPopup from "../../../components/import_data_file/import_data_file";
import UrlBuilder from "../../../../apis/url_builder";
import { selectImportDataFileUrlState, selectUpdateImportDataFileUrlState, updateImpDataFileUploadUrl } from "../../../../redux/upload_files";
import { importUrlActions } from "../../../../redux/upload_files/import_data_file_reducer";
import Pagination from "../../../components/pagination/pagination";

interface Props extends RouteComponentProps<any> { }
const tableHeader = [
  { title: "access", code: "access_contact" },
  { title: "orders", code: "open_orders" },
  { title: "name", code: "name" },
  { title: "status", code: "status" },
  { title: "contact_role", code: "role" },
  { title: "company", code: "company_name" },
  { title: "job_title", code: "job_title" },
  { title: "department", code: "department_names" },
  { title: "last_contact", code: "last_contact" },
  { title: "contact", code: "preferred_contact_method" },
];

interface State {
  from?: {
    state?: {
      id: string;
      status: string;
    };
  };
}

const UniversalDirectoryPage: React.FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch();
  const contactsListState = useAppSelector((state) =>
    selectContactsListState(state)
  );
  const [contactsList, setContactList] = useState<IContactsByPage | null>(null);
  const {profile} = useAppSelector((state)=>selectProfileState(state))
  const [profileAdded, setProfileAdded] = useState(false);
  const roleUsersListState = useAppSelector((state)=>selectRoleUsersListState(state))

  const accountManagersList = useAppSelector((state) =>
    selectAccountManagerRoleUsersList(state)
  );
  const [search, setSearch] = useState("");
  const [sortedField, setSortedField] = useState<string | null>(null);

  const [sortDirection, setSortDirection] = useState("asc");

  const [addContact, setAddContact] = useState<boolean>(false);


  const { history, location } = props;
  const { t } = useTranslation();
  const [includeDepartment, setIncludeDepartment] = useState(false);
  const [accountManagers, setAccountManagers] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [contactRole, setContactRole] = useState<string[]>([]);

  const [activeAssignment, setActiveAssignment] = useState<boolean>(false);

  ///Contact Status
  const contactStatusList = useAppSelector((state) => selectContactStatusList(state));

  const importDataFileUrlState = useAppSelector((state) => selectImportDataFileUrlState(state));
  const updateImportDataFileUrlState = useAppSelector((state) => selectUpdateImportDataFileUrlState(state));

  const contactRolesList = useAppSelector((state)=> selectContactRoleList(state))

  const [importContacts, setImportContacts] = useState<boolean>(false)
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(100)

  useEffect(() => {
    dispatch(getContactRoles())
    checkAddContact();
    dispatch(getRoleUsersList());
    dispatch(getContactStatus());
    return () => { };
  }, []);

  useEffect(() => {
    const state = history.location.state as State;
    if (state?.from?.state) {
      status.push(state.from.state.status)
    }
  }, [history]);

  useEffect(() => {
    // if (
    //   roleUsersListState.loading===LoadingType.succeeded && !profileAdded) {
    //   let selectedAccountManagers = accountManagers;
    //   const isUserInAccountManagersList = accountManagersList.some((manager) => manager.id === profile?.id);
  
    //   if (isUserInAccountManagersList) {
    //     selectedAccountManagers = [profile?.id ?? ""]; // Automatically add only once
    //     setAccountManagers(selectedAccountManagers);
    //     setProfileAdded(true);
    //   }
    //   getContacts({pageNum:1,account_managers:selectedAccountManagers})
    // }
    // else{
      // Timer ID to keep track of the setTimeout
      const timeoutId = setTimeout(() => {
        if (search?.length === 0 || search?.length >= 2) {
            getContacts({ pageNum: 1});
        }
      }, 500);  // 500ms debounce delay, adjust as needed

      // Cleanup function to clear the timeout if the search value changes before the timeout is reached
      return () => clearTimeout(timeoutId);
    // }
    
  }, [search]);  // Trigger the effect whenever the search value changes

  useEffect(() => {
    if (contactsListState?.contactsByPage?.loading === LoadingType.succeeded && contactsListState?.contactsByPage?.response) {
      setContactList(contactsListState?.contactsByPage?.response)
      return () => { };
    }
  }, [contactsListState?.contactsByPage?.loading, contactsListState?.contactsByPage?.response]);

  useEffect(() => {
    if (contactsListState?.contactsByPage?.error != null && contactsList?.items?.length != 0) {
      dispatch(postSnackbarMessage(contactsListState?.contactsByPage?.error?.message));
    }
    return () => { };
  }, [contactsListState?.contactsByPage?.error]);

  useEffect(() => {
    if (updateImportDataFileUrlState?.loading === LoadingType.succeeded && updateImportDataFileUrlState?.response) {
      if (snackBarMessage !== "") {
        dispatch(postSnackbarMessage(snackBarMessage ?? null));
        dispatch(importUrlActions.clearUpdateImportUrlStateError())
      }
      setImportContacts(false)
    }
    return () => { };
  }, [updateImportDataFileUrlState?.loading]);

  useEffect(() => {
    if (importDataFileUrlState?.loading === LoadingType.succeeded && importDataFileUrlState?.response) {
      dispatch(updateImpDataFileUploadUrl({
        baseUrl: UrlBuilder.updateImportContacts,
        import_id: importDataFileUrlState?.response?.id ?? ""
      }))
    }

  }, [importDataFileUrlState?.loading, importDataFileUrlState?.response]);

  useEffect(() => {
    const state = history.location.state as any;
    if (state && state.activeAssignment) {
      setActiveAssignment(true);
    }
  }, [history]);

  function getContacts(doc?: { pageNum?: number, pageSize?: number, account_managers?: Array<string>, status_ids?: Array<string>, role_ids?: Array<string> }) {
    dispatch(getContactsByPage({ page: doc?.pageNum ?? currentPage, size: doc?.pageSize ?? size, filter_ids: { acc_manager_ids: doc?.account_managers ?? accountManagers, status_ids: doc?.status_ids ?? status,  role_ids: doc?.role_ids ?? contactRole}, search_str: search }));
    setCurrentPage(doc?.pageNum ?? currentPage)
  }

  function checkAddContact() {
    setTimeout(() => {
      if (
        location &&
        location.state != null &&
        (location.state as any).newContact === true
      ) {
        setAddContact(true);
      }
    }, 1000);
  }

  function getFilteredList(): IContact[] {
    const list = [...contactsList?.items! ?? []];
    let sortedList: IContact[] | undefined;
    let resultList: IContact[] | undefined;

    if (activeAssignment) {
      resultList = list.filter((doc) => {
        const onActiveAssignmentFilter =
          doc.active_assignment > 0 ? doc.active_assignment : false;
        return onActiveAssignmentFilter;
      });
    }


    if (sortedField != null) {
      if (sortedField === "open_orders") {
        sortedList = sortByOrders([...(resultList ?? list)], "order_status", sortDirection);
      } else {
        sortedList = [...(resultList ?? list)].sort((a, b) => {
          const valueA =
            a[sortedField] != null && a[sortedField] != undefined
              ? typeof a[sortedField] == typeof "1"
                ? a[sortedField].trim().toLowerCase()
                : a[sortedField]
              : "";
          const valueB =
            b[sortedField] != null
              ? typeof b[sortedField] == typeof "1"
                ? b[sortedField].trim().toLowerCase()
                : b[sortedField]
              : "";
          if (sortDirection === "asc") {
            return valueA > valueB ? 1 : -1;
          } else {
            return valueA < valueB ? 1 : -1;
          }
        });
      }
    }

    return sortedList ?? resultList ?? list;
  }

  function handleSortFieldChange(value: string) {
    if (value === "open_orders") {
      if (sortedField && sortedField === value) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSortedField(value);
      }
    }
    else {
      if (sortedField && sortedField === value) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSortedField(value);
        setSortDirection('asc');
      }
    }
  }

  function handleContactSelect(value: IContact) {
    props.history.push({
      pathname: `${AppRoutes.contactsDetailsPage}/${value.id}`,
      state: {
        id: value.id,
        name:
          value.name ?? `${value.first_name ?? ""} ${value.last_name ?? ""}`,
      },
    });
    return;
  }

  const handleClearError = () => {
    dispatch(importUrlActions.clearImportUrlStateError())
  }

  const handleSnackBar = () => {
    setSnackBarMessage(t("contacts_uploaded_successfully"))
  }

  const handlePageChange = (pageNum: number, size: number) => {
    getContacts({ pageNum: pageNum, pageSize: size })
    setCurrentPage(pageNum);
    setSize(size ? size : contactsList?.size!)
  };


  const handleAccountmanagers = (value) => {
    setAccountManagers(value)
    getContacts({ pageNum: 1, account_managers: value });
  }

  const handleStatus = (value) => {
    setStatus(value)
    getContacts({ pageNum: 1, status_ids: value });
  }
  const handleContactRole = (value) => {
    setContactRole(value)
    getContacts({ pageNum: 1, role_ids: value });
  }

  return (
    <div className="cnt-ud-container">
      <div className="cnt-ud-table-header">
        <TableHeaderOptions
          roleUsers={accountManagersList.map((doc) => ({
            label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
            value: doc.id,
          }))}
          totalCount={contactsList?.total!}
          departmentActive={includeDepartment}
          onChangeDepartment={() => setIncludeDepartment(!includeDepartment)}
          onChangeRoleUser={handleAccountmanagers}
          selectedRoleUsers={accountManagers}
          search={search}
          onSearchChange={setSearch}
          selectedStatus={status}
          selectedContactRole={contactRole}
          onStatusChange={handleStatus}
          onContactRoleChange={handleContactRole}
          companyStatusList={contactStatusList.map((doc) => ({
            label: doc.contact_status,
            value: doc.id,
          }))}
          contactRoleList={contactRolesList.map((doc) => ({
            label: doc.contact_role,
            value: doc.id,
          }))}
        />

        <div className="cnt-ud-actions">
          <div className="upl-files">
            <CustomButton
              leftIcon={<UploadIcon width={"1vw"} height={"1vw"} />}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("upload_contacts")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => setImportContacts(true)}
            />
          </div>
          <div style={{ marginRight: '0.5em' }}>
            <NavLink to={`${AppRoutes.apolloSearchImport}/contacts`} >
              <CustomButton
                leftIcon={<div className="btn-icon circle-download-svg">
                  <CircleDownloadIcon width={"100%"} height={"100%"} style={{ fill: "#fff" }} />
                </div>}
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("kiniso_scout_ai")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => { }}
              />
            </NavLink>
          </div>
          <div className="cnt-ud-add-user-btn">
            <CustomButton
              leftIcon={getPlusBtnIcon()}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("add_contact")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => setAddContact(true)}
            />
          </div>
        </div>
      </div>
      <div className="cnt-ud-table">{getContactsTableList()}</div>
      <div className="as-table-footer">
        <Pagination
          totalPages={contactsList?.pages!}
          onPageChange={handlePageChange}
          curPageNum={currentPage}
          totalCount={contactsList?.total!}
          size={contactsList?.size!}
          loadingState={contactsListState?.contactsByPage?.loading}
        />
      </div>
      {addContact && (
        <Portal>
          <CreateContactPopup
            visible={addContact}
            title={t("add_contact")}
            successTitle={t("new_contact")}
            onClose={() => setAddContact(false)}
            onSuccessClose={handleAddContactSuccess}
            onGotoAgencyDashboard={handleGotoAgencyDashboard}
            onGotoCreated={handleOnGotoContact}
          />
        </Portal>
      )}
      {importContacts && (
        <Portal>
          <UploadRecordPopup
            visible={importContacts}
            title={t("upload_contacts")}
            baseUrl={UrlBuilder.importContacts}
            onClose={() => setImportContacts(false)}
            loading={importDataFileUrlState?.loading}
            error={importDataFileUrlState?.error}
            onClearError={handleClearError}
            handleSnackBar={handleSnackBar}
            importType={"Contact"}
          />
        </Portal>
      )}

    </div>
  );

  ///Add user action
  function handleAddContactSuccess() {
    setAddContact(false);
    getContacts();
    dispatch(manageContactActions.clearManageContactState(["photo", "resume"]));
  }

  function handleGotoAgencyDashboard() {
    handleAddContactSuccess();
    props.history.push(AppRoutes.dashboardPage);
  }

  function handleOnGotoContact(id: string, name: string) {
    handleAddContactSuccess();
    props.history.push({
      pathname: `${AppRoutes.contactsDetailsPage}/${id}`,
      state: {
        id: id,
        name: name,
      },
    });
  }

  /// Users table list
  function getContactsTableList() {
    if (contactsListState?.contactsByPage?.loading === LoadingType.pending) {
      return <TableLoading />;
    }
    if (contactsListState?.contactsByPage?.error != null && contactsList?.items?.length === 0) {
      return (
        <TableErrorHandler
          error={contactsListState?.contactsByPage?.error}
          onRefresh={getContacts}
        />
      );
    }
    if (contactsList?.items?.length === 0) {
      return (
        <TableEmpty title={t("no_contacts_matches_found")} onClick={() => getContacts()} />
      );
    }
    if (getFilteredList().length === 0) {
      return <TableFilterEmpty title={t("no_contacts_found")} />;
    }
    return (
      <SortableTable
        headerList={tableHeader}
        sortedField={sortedField}
        onSortChange={handleSortFieldChange}
        flexNumber={getFlexNumber}
        isAsc={sortDirection}
      >
        {getFilteredList().map((doc) => {
          return (
            <tr key={doc.id}>
              <TableData customStyle={{ flex: getFlexNumber(0) }}>
                <AccessPicture picture={doc.photo_url ?? ""} onClick={() => handleContactSelect(doc)} altText="Contact Picture"/>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(1) }}>
                {OpenOrderRow(doc, "order_status", t)}
              </TableData>
              <TableData
                customStyle={{ flex: getFlexNumber(2) }}
                isButton={true}
                onClick={() => handleContactSelect(doc)}
              >
                <span>{doc.name}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(3) }}>
                <span>
                  {doc.status ?? ""}
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(4) }}>
                <span>
                  {doc.role ?? ""}
                </span>
              </TableData>
              <TableData
                customStyle={{ flex: getFlexNumber(5) }}
                isButton={true}
                onClick={() => handleCompanySelect(doc)}
              >
                <span>{doc.company_name ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(6) }}>
                <span>{doc.job_title ?? ""}</span>
              </TableData>
              <td
                style={{
                  flex: getFlexNumber(7),
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="table-data-container"
              >
                {doc.department_names &&
                  doc.department_names.length !== 0 ?
                  doc.department_names.map((item, index, arr) => (
                    <>
                      <a
                        onClick={() => handleDepartmentSelect(doc, index)}
                        className="cursor-p table-data-btn"
                      >
                        {item} {index !== arr.length - 1 ? ", " : ""}
                      </a>
                    </>
                  )) :
                  (<>
                    {doc.company_id !== null ? <div
                      onClick={() => handleCompanySelect(doc)}
                      className="cursor-p"
                    >
                      <span>{t('corporate')}</span>
                    </div> :
                      <span></span>}</>

                  )
                }
              </td>
              <TableData customStyle={{ flex: getFlexNumber(8) }}>
                <span>
                  {doc.last_contact
                    ? getDaysAgoWithDateAMPM(t, doc.last_contact)
                    : ""}
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(9) }}>
                {getContactIcon(doc.preferred_contact_method)}
              </TableData>
            </tr>
          );
        })}
      </SortableTable>
    );
  }

  function getFlexNumber(value: number) {
    if (value === 0) return 0.6;
    if (value === 1) return 1.5;
    if (value === 2) return 2;
    if (value === 3) return 2;
    if (value === 4) return 2;
    if (value === 5) return 2;
    if (value === 6) return 2;
    if (value === 7) return 2;
    if (value === 8) return 1.8;
    if (value === 8) return 0.5;
    return 1;
  }

  function handleCompanySelect(value: IContact) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
      },
    });
    return;
  }

  function handleDepartmentSelect(value: IContact, index) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_ids[index]}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.department_ids[index],
        departmentName: value.department_names[index],
      },
    });
    return;
  }
};

interface TableHeaderOptionsProps {
  /// [Role users]
  roleUsers: Array<{ label: string; value: string }>;
  selectedRoleUsers: string[];
  onChangeRoleUser: (value: string[]) => void;
  /// [Department]
  departmentActive: boolean;
  onChangeDepartment: () => void;
  totalCount: number;
  /// [Search]
  search: string;
  onSearchChange: (value: string) => void;
  /// [Status]
  selectedStatus: string[];
  selectedContactRole: string[];
  onStatusChange: (value: string[]) => void;
  onContactRoleChange: (value: string[]) => void;
  companyStatusList: Array<{ label: string; value: string }>;
  contactRoleList: Array<{ label: string; value: string }>;
}

const TableHeaderOptions = (props: TableHeaderOptionsProps) => {
  const { t } = useTranslation();
  return (
    <div className="cnt-ud-table-header-options">
      <div className="account-manager-selector">
        <CustomMultiFormSelect
          label={t("account_manager")}
          name={"account_manager"}
          list={props.roleUsers}
          onChange={props.onChangeRoleUser}
          required={false}
          placeholder={t("select")}
          value={props.selectedRoleUsers}
          customStyle={{ width: "-webkit-fill-available" }}
        />
      </div>
      <div className="company-status-selector">
        <CustomMultiFormSelect
          label={t("status")}
          name={"company_status"}
          list={props.companyStatusList}
          onChange={props.onStatusChange}
          required={false}
          placeholder={t("all")}
          value={props.selectedStatus}
          customStyle={{ width: "-webkit-fill-available" }}
        />
      </div>
      <div className="company-status-selector">
        <CustomMultiFormSelect
          label={t("contact_role")}
          name={"role_id"}
          list={props.contactRoleList}
          onChange={props.onContactRoleChange}
          required={false}
          placeholder={t("all")}
          value={props.selectedContactRole}
          customStyle={{ width: "-webkit-fill-available" }}
        />
      </div>
      <div className="cnt-ud-table-search-bar">
        <SearchBar
          value={props.search}
          onChange={props.onSearchChange}
          onSearch={()=>{}}
        />
      </div>
      <div className="total-count">
        <span>
          {`${t("total_count")}: `}
          <span className="total-count-number">{props.totalCount}</span>
        </span>
      </div>
    </div>
  );
};

export default UniversalDirectoryPage;

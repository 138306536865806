import { useEffect, useState } from 'react';
import EditIcon from '../../../../assets/icons/edit_button.svg';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg';
import { LoadingType, THEME } from "../../../enums";
import { CustomButton, getPlusBtnIcon } from "../../../utils";
import { useTranslation } from "react-i18next";
import TabOptionsHeader from '../../../components/tab_options_header/tab_options_header';
import SortableTable, { TableData } from '../../../components/sortable_table/sortable_table';
import {
    createTalentInterviewStatus, updateTalentInterviewStatus, getTalentInterviewStatus, deleteTalentInterviewStatus,
    selectTalentInterviewStatusState, selectTalentInterviewStatusList,
    createTalentInterviewQuestion, updateTalentInterviewQuestion, getTalentInterviewQuestions, deleteTalentInterviewQuestion,
    selectTalentInterviewQuestionState, selectTalentInterviewQuestionList, createOtherDocs, updateOtherDocs, deleteOtherDocs, getOtherDocs, selectOtherDocsState, selectOtherDocsList,
    createTalentSource,
    updateTalentSource,
    deleteTalentSource,
    getTalentSource,
    selectTalentSourceState,
    selectTalentSourceList
} from "../../../redux/admin_center";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../redux/store";
import './system_wide_page.scss';
import { Portal } from "react-portal";
import '../../../custom.scss';
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../components/table_empty/table_empty";
import CustomFormSelect from '../../../components/form_selector/form_select';
import { talentInterviewStatusActions } from '../../../redux/admin_center/system_configuration/talent_interview_status/talent_interview_status_reducer';
import { talentInterviewQuestionActions } from '../../../redux/admin_center/system_configuration/talent_interview_questions/talent_interview_questions_reducer';
import { otherDocsActions } from '../../../redux/admin_center/system_configuration/other_docs/other_docs_reducer';
import { DeleteConfirmationDialog } from '../../components/delete_confirmation_dialog';
import SCTalentPopup from './popups/talent_popup/talent_popup';
import { talentSourceActions } from '../../../redux/admin_center/system_configuration/talent_source/talent_source_reducer';


const tableHeader = {
    talentInterviewStatus: [
        { title: 'status', code: 'actions' },
        { title: 'description', code: 'actions' },
        { title: 'actions', code: 'actions' },
    ],
    talentInterviewQuestions: [
        { title: 'question', code: 'actions' },
        { title: 'actions', code: 'actions' },
    ],
    otherDocs: [
        { title: 'title', code: 'actions' },
        { title: 'actions', code: 'actions' },
    ],
}

const categories = {
    1: {
        label: "talent_interview_status",
        value: ["title", "description"],
        buttonLabel: "add_talent_interview_status",
        inputLabel: "status",
        deleteLabel: "delete_talent_interview_status",
        deleteMessage: "delete_talent_interview_status_message",
        updateLabel: "update_talent_interview_status",
        inputLabel2: "description",
        sequenceNumber: "sequence_number",
        methods: {
            create: createTalentInterviewStatus,
            update: updateTalentInterviewStatus,
            remove: deleteTalentInterviewStatus,
            get: getTalentInterviewStatus
        },
        headers: tableHeader.talentInterviewStatus,
        clearCreateState: talentInterviewStatusActions.clearTalentInterviewStatusCreateState,
        clearUpdateState: talentInterviewStatusActions.clearTalentInterviewStatusUpdateState,
        clearDeleteState: talentInterviewStatusActions.clearTalentInterviewStatusDeleteState,
        clearDeleteStateError: talentInterviewStatusActions.clearTalentInterviewStatusDeleteStateError,
    },
    2: {
        label: "talent_interview_questions",
        value: ["question"],
        buttonLabel: "add_talent_interview_question",
        inputLabel: "question",
        deleteLabel: "delete_talent_interview_question",
        deleteMessage: "delete_talent_interview_question_message",
        updateLabel: "update_talent_interview_question",
        inputLabel2: "description",
        sequenceNumber: "question_number",
        methods: {
            create: createTalentInterviewQuestion,
            update: updateTalentInterviewQuestion,
            remove: deleteTalentInterviewQuestion,
            get: getTalentInterviewQuestions
        },
        headers: tableHeader.talentInterviewQuestions,
        clearCreateState: talentInterviewQuestionActions.clearTalentInterviewQuestionCreateState,
        clearUpdateState: talentInterviewQuestionActions.clearTalentInterviewQuestionUpdateState,
        clearDeleteState: talentInterviewQuestionActions.clearTalentInterviewQuestionDeleteState,
        clearDeleteStateError: talentInterviewQuestionActions.clearTalentInterviewQuestionDeleteStateError,
    },
    3: {
        label: "other_docs",
        value: ["other_docs"],
        buttonLabel: "add_other_docs",
        inputLabel: "title",
        deleteLabel: "delete_other_docs",
        deleteMessage: "delete_other_docs_msg",
        updateLabel: "update_other_docs",
        inputLabel2: "description",
        sequenceNumber: "question_number",
        methods: {
            create: createOtherDocs,
            update: updateOtherDocs,
            remove: deleteOtherDocs,
            get: getOtherDocs
        },
        headers: tableHeader.otherDocs,
        clearCreateState: otherDocsActions.clearOtherDocsCreateState,
        clearUpdateState: otherDocsActions.clearOtherDocsUpdateState,
        clearDeleteState: otherDocsActions.clearOtherDocsDeleteState,
        clearDeleteStateError: otherDocsActions.clearOtherDocsDeleteStateError,
    },
    4: {
        label: "talent_source",
        value: ["value"],
        buttonLabel: "add_talent_source",
        inputLabel: "title",
        deleteLabel: "delete_talent_source",
        deleteMessage: "delete_talent_source_msg",
        updateLabel: "update_talent_source",
        methods: {
            create: createTalentSource,
            update: updateTalentSource,
            remove: deleteTalentSource,
            get: getTalentSource
        },
        headers: tableHeader.otherDocs,
        clearCreateState: talentSourceActions.cleartalentSourceCreateState,
        clearUpdateState: talentSourceActions.cleartalentSourceUpdateState,
        clearDeleteState: talentSourceActions.cleartalentSourceDeleteState,
        clearDeleteStateError: talentSourceActions.cleartalentSourceDeleteStateError,
    }
}

const TalentPage = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<{visible: boolean, title: string} | null>(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [methodValue, setMethodValue] = useState({
        'id': '', 'value': '', 'description': '',
        'sequence_number': 0, 'question_number': 0,
        'agency_id': '', 'is_deleted': false
    });
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [dropdownChanged, setDropDownChanged] = useState(true);
    const talentInterviewStatusStateState = useAppSelector((state) => selectTalentInterviewStatusState(state));
    const talentInterviewStatusStateList = useAppSelector((state) => selectTalentInterviewStatusList(state));
    const talentInterviewQuestionState = useAppSelector((state) => selectTalentInterviewQuestionState(state));
    const talentInterviewQuestionList = useAppSelector((state) => selectTalentInterviewQuestionList(state));
    const otherDocsState = useAppSelector((state) => selectOtherDocsState(state));
    const otherDocsList = useAppSelector((state) => selectOtherDocsList(state));
    const talentStatusState = useAppSelector((state) => selectTalentSourceState(state));
    const talentStatusList = useAppSelector((state) => selectTalentSourceList(state));

    const reduxMapping = {
        1: { state: talentInterviewStatusStateState, list: talentInterviewStatusStateList },
        2: { state: talentInterviewQuestionState, list: talentInterviewQuestionList },
        3: { state: otherDocsState, list: otherDocsList },
        4: { state: talentStatusState, list: talentStatusList },
    }

    useEffect(() => {
        if (getMapping()?.state?.create?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.create?.response));
            dispatch(getCategory()?.clearCreateState())
        }
        else if (getMapping()?.state?.update?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.update?.response));
            dispatch(getCategory()?.clearUpdateState())
        }
        else if (getMapping()?.state?.remove?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.remove?.response));
            dispatch(getCategory()?.clearDeleteState())
        }
    }, [
        getMapping()?.state?.create?.loading, 
        getMapping()?.state?.update?.loading, 
        getMapping()?.state?.remove?.loading
    ]);

    function getCategory(id = null) {
        if (id)
            return categories[id];
        return categories[selectedCategory];
    }

    function getMapping(id = null) {
        if (id)
            return reduxMapping[id];
        return reduxMapping[selectedCategory];
    }

    function handleCreateCloseDialog() {
        setShowCreateDialog(false);
    }

    function handleCloseDeleteDialog() {
        setShowDeleteDialog(null);
        setDeleteItemId(null);
    }

    function handleShowDeleteDialog(id, title) {
        setShowDeleteDialog({visible: true, title: title});
        setDeleteItemId(id);
    }

    function handleDeleteApprove() {
        if (deleteItemId) {
            dispatch(getCategory().methods.remove(deleteItemId));
        }
        handleCloseDeleteDialog();
    }

    function handleShowEditDialog(method) {
        setShowEditDialog(true);
        if (getCategory()?.label === "talent_source") {
            setMethodValue({
                ...methodValue,
                'id': method.id,
                'value': method[getCategory().value[0]],
                'agency_id': method.agency_id,
                'is_deleted': method.is_deleted
            })
        } else {
            setMethodValue({
                ...methodValue, 'id': method.id, 'value': method[getCategory().value[0]],
                'description': method[getCategory().value[1]],
                'sequence_number': method[getCategory().sequenceNumber],
                'question_number': method[getCategory().question_number]
            });
        }
    }

    function handleCloseEditDialog() {
        setShowEditDialog(false);
    }

    function handleDropdownChange(value) {
        if (!value)
            value = '1';
        setDropDownChanged(true);
        setSelectedCategory(Number(value));
        dispatch(getCategory(value).methods.get());
    }

    function retryFetchingData() {
        setDropDownChanged(true);
        dispatch(getCategory().methods.get());
    }

    useEffect(() => {
        if (getMapping().state.loading == LoadingType.succeeded) {
            setDropDownChanged(false);
        }
    }, [getMapping().state.loading])

    useEffect(() => {
        if (getMapping().state.remove.loading == LoadingType.succeeded)
            dispatch(getCategory().methods.get());
    }, [getMapping().state.remove.loading])

    useEffect(() => {
        dispatch(getCategory().methods.get());
        return () => {

        }
    }, [])

    return (
        <div className="ac-sc-container">
            <div className="ac-sc-header">
                <div className="ac-sytm-cns-header">
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="ac-sc-dropdown">
                <div className="ac-sc-dropdown-button">
                    <CustomFormSelect
                        name={t("talent_dropdown")}
                        list={Object.keys(categories).map(id => ({ value: id, label: categories[id].label }))}
                        onChange={(val) => handleDropdownChange(val)}
                        value={selectedCategory}
                        placeholder={t('talent_interview_status')}
                        required={false}
                        label={t('please_select_talent_category')}
                        customStyle={{ marginTop: "0 !important", maxWidth: "20vw" }}
                    />
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            enable={true}
                            leftIcon={getPlusBtnIcon()}
                            name={t(categories[selectedCategory].buttonLabel)}
                            backgroundColor={THEME.buttonColor1}
                            onClick={() => (setShowCreateDialog(true))}
                            customStyle={{ width: "max-content" }}
                        />
                    </div>
                </div>
            </div>
            <div className="ac-sc-table">
                {getMapping().state.loading == LoadingType.pending && dropdownChanged ? <TableLoading /> :
                    <TableBody
                        state={getMapping().state}
                        onRetry={retryFetchingData}
                        data={getMapping().list}
                        category={getCategory()}
                        handleShowEdit={handleShowEditDialog}
                        handleShowDelete={handleShowDeleteDialog}
                    />
                }
            </div>
            {showCreateDialog && <Portal>
                <SCTalentPopup
                    visible={showCreateDialog}
                    onClose={handleCreateCloseDialog}
                    onDismiss={handleCreateCloseDialog}
                    category={getCategory()}
                    category_id={selectedCategory}
                    reduxMapping={reduxMapping}
                    errorState={reduxMapping[selectedCategory]?.update?.error}
                />
            </Portal>}
            {showDeleteDialog && (
                <Portal>
                    <DeleteConfirmationDialog
                        message={t(getCategory()?.deleteMessage, { value: showDeleteDialog?.title })}
                        onCancel={() => setShowDeleteDialog(null)}
                        onConfirm={handleDeleteApprove}
                        deleting={getMapping()?.state?.remove === LoadingType.pending}
                        deleteType={""}
                        customDeleteHeaderTitle={t(getCategory()?.deleteLabel)}
                        state={getMapping()?.state}
                        onClearError={getCategory()?.clearDeleteStateError()}
                    />
                </Portal>
            )}
            {showEditDialog && <Portal>
                <SCTalentPopup
                    visible={showEditDialog}
                    onClose={handleCloseEditDialog}
                    onDismiss={handleCloseEditDialog}
                    methodValue={methodValue}
                    category={getCategory()}
                    category_id={selectedCategory}
                    reduxMapping={reduxMapping}
                    errorState={reduxMapping[selectedCategory]?.update?.error}
                />
            </Portal>}
        </div>

    )
}

const TableBody = ({ state, onRetry, data, category, handleShowEdit, handleShowDelete }) => {
    if (state.loading == LoadingType.failed) {
        return (<TableErrorHandler error={state.error} onRefresh={onRetry} />)
    }
    else if (data.length == 0) {
        return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    }
    else {
        return (
            <SortableTable headerList={category.headers}>
                {data.map((doc, index) => {
                    return (
                        <tr key={doc.id}>
                            {category.value.map((value, index) => {
                                return (
                                    <TableData key={doc.id + index} customStyle={{ wordWrap: "break-word" }}>
                                        <span>{doc[value]}</span>
                                    </TableData>
                                )
                            })}
                            <TableData>
                                <img src={EditIcon} alt='edit_button' style={{ marginRight: '20px' }}
                                    onClick={() => handleShowEdit(doc)} />
                                <img src={DeleteIcon} alt='delete_button'
                                    onClick={() => handleShowDelete(doc.id, doc[category.value[0]])} />
                            </TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }
}

export default TalentPage;
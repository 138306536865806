
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import CustomFormSelect, { CustomMultiFormSelect } from "../../../../../components/form_selector/form_select";
import SearchBar from "../../../../../components/search_bar";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { TalentPipelineDirectory, THEME } from "../../../../../enums";
import { MarkTalentIcon, PaperPlaneIcon, DeleteIcon } from "../../../../../icons";
import { IMultipleMove, ITPState, IPipelineStatus } from "../../../../../interfaces/talent_pipeline";
import { useAppDispatch } from "../../../../../redux/store";
import { AppRoutes } from "../../../../../routes";
import { CustomCheckBox, CustomButton, SpinnerScreen, currencyConversion, getRatingBackgrounColor } from "../../../../../utils";
import { ConfirmationDialog } from "../../../../jobs/popups/confirm_popup/confirm_popup";
import { JobOrderPipeline } from "../../../../../interfaces/job_order_pipeline";
import { pipelineSliceActions } from "../../../../../redux/job_order/details/talent_pipeline/talent_pipeline_details/talent_pipeline_reducer";
import { NotificationCircle } from "../../../../../components/notification_circle/notification_circle";

interface Props {
    loading: boolean;
    jobOrderPipelineList: JobOrderPipeline[] | null;
    history: RouteComponentProps["history"],
    handleMoveToTrash: (doc: IMultipleMove[]) => void,
    handleMoveToQueue: (doc: IMultipleMove[]) => void,
    talentId: string,
    talentName: string,
    jobOrderTrashState: ITPState,
    jobOrderQueState: ITPState,
    joborderPipelineStatusChangeState: ITPState,
    pipelineStatusList: IPipelineStatus[],
    currentLoginUserName: string,
    directory: string,
    index: number,
    handleJobOrderPipelineStatusChange: (job_order_id, status_id) => void,
    onSuccessClose: () => void,
}

const JobOrderMatchesSection: React.FunctionComponent<Props> = (props) => {
    const { loading, jobOrderPipelineList,
        handleMoveToQueue, handleMoveToTrash,
        talentId, talentName, jobOrderTrashState,
        jobOrderQueState, pipelineStatusList, currentLoginUserName,
        directory, index, handleJobOrderPipelineStatusChange, onSuccessClose } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();


    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [search, setSearch] = useState('');
    const [pipelineStatus, setPipelineStatus] = useState<string[]>([]);
    const [selectedItems, setSelectedItems] = useState<
        Map<string, { job_id: string; job_title: string, talent_id: string }>
    >(new Map());

    const [showConfirm, setShowConfirm] = useState<{ visible: boolean, type: string | null, job_order_id: string | null, job_order_name: string | null }>({ visible: false, type: null, job_order_id: null, job_order_name: null })
    const [formState, setFormState] = useState<{ id: string, pipeline_status: string, pipeline_status_id: string }[]>([]);
    const [isHovering, setIsHovering] = useState<{ visible: boolean, id: string } | null>(null)

    useEffect(() => {
        if (jobOrderPipelineList) {
            setFormState(jobOrderPipelineList.map(value => ({ id: value.id, pipeline_status: value.pipeline_status, pipeline_status_id: value.pipeline_status_id })))

        }
    }, [jobOrderPipelineList]);

    const SelectAllCheckBox = () => {
        return (
            <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.size === jobOrderPipelineList?.length}
                onClick={handleMarkAllItem}
            />
        );
    };
    const selectedJobOrdersList = Array.from(selectedItems.values()).map(({ job_id, talent_id }) => ({ job_id, talent_id }));
    const handleMarkAllItem = () => {
        const newSet = new Map<string, { job_id: string; job_title: string, talent_id: string }>();
        if (selectedItems.size === jobOrderPipelineList?.length) {
            setSelectedItems(newSet);
        } else {
            jobOrderPipelineList?.forEach((doc) =>
                newSet.set(doc.id, { job_id: doc.id, job_title: doc.job_title, talent_id: talentId })
            );
            setSelectedItems(newSet);
        }
    };
    const handleMarkItem = (value) => {
        const newSet = new Map(selectedItems);
        if (selectedItems.has(value.id)) {
            newSet.delete(value.id);
        } else {
            newSet.set(value.id, { job_id: value.id, job_title: value.job_title, talent_id: talentId });
        }
        setSelectedItems(newSet);
    };


    const headerList = [
        { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
        { title: "job_title", code: "job_title" },
        { title: "company_name", code: "company_name" },
        { title: "pipeline_status", code: "pipeline_status" },
        { title: "skills_and_certifications", code: "matched_skills" },
        { title: "requested", code: "requested" },
        { title: "placed", code: "placed" },
        { title: "over_or_under", code: "over_under_placed" },
        { title: "match_score", code: "match_score" },
        { title: "pay_rate", code: "pay_rate" },
        { title: "engagement_rating", code: "engagement_rating" },
        { title: directory === TalentPipelineDirectory.Queued ? "move_to_interview" : "move_to_que", code: "" },
        { title: "not_a_fit", code: "" }
    ];
    const tableheaderList = directory === TalentPipelineDirectory.Trashed ? headerList.slice(1, -2) : headerList

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    const getFlexNumber = (value: number) => {
        if (value === 0 && directory !== TalentPipelineDirectory.Trashed) return 0.3;
        if (value === 3 && directory !== TalentPipelineDirectory.Trashed) return 2;
        if (value === 4 && directory !== TalentPipelineDirectory.Trashed) return 1.5;
        if (value === 5 && directory !== TalentPipelineDirectory.Trashed) return 0.6;
        if (value === 6 && directory !== TalentPipelineDirectory.Trashed) return 0.6;
        if (value === 7 && directory !== TalentPipelineDirectory.Trashed) return 0.6;
        if (value === 8 && directory !== TalentPipelineDirectory.Trashed) return 0.6;
        if (value === 10 && directory !== TalentPipelineDirectory.Trashed) return 0.6;
        if (value === 11 && directory !== TalentPipelineDirectory.Trashed) return 0.6;
        if (value === 12 && directory !== TalentPipelineDirectory.Trashed) return 0.6;
        return 1;
    }

    const getFilteredList = (): JobOrderPipeline[] | null => {
        if (!jobOrderPipelineList) {
            return null; // Explicitly return null if the list is not available
        }

        let list: JobOrderPipeline[] = [...jobOrderPipelineList];

        // Sorting
        if (sortedField != null) {
            list = list.sort((a, b) => {
                const valueA =
                    a[sortedField] != null
                        ? typeof a[sortedField] === "string"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] === "string"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";

                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        // Filter by Pipeline status
        if (pipelineStatus.length !== 0) {
            list = list.filter((joborder) => pipelineStatus.includes(joborder.pipeline_status_id));
        }
        // Search Filter
        if (search.trim()) {
            list = list.filter((doc) => {
                const str = search.trim().toLowerCase();
                return Object.values(doc).some((val) =>
                    val && val.toString().trim().toLowerCase().includes(str)
                );
            });
        }

        return list;
    };

    function handleUserSelect(value: JobOrderPipeline) {
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${value.id}`,
            state: {
                id: value.id,
                name: value.job_title,
            },
        });
        return;
    }

    function handleCompanySelect(value: JobOrderPipeline) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            },
        });
        return;
    }

    const handleInterviewRedirect = (value: JobOrderPipeline) => {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${talentId}/interviews`,
            state: {
                id: talentId,
                name: talentName,
                job_order_id: value.id,
                job_order_name: value.job_title,
                company_id: value.company_id,
                department_id: value.department_id,
                interview: true
            },
        });
        return;
    }

    function getRatings(rating: number, level: string) {
        return (
            <div style={{ textAlign: "center" }}>
                <NotificationCircle
                    value={rating}
                    onClick={() => { }}
                    customStyle={{
                        backgroundColor: getRatingBackgrounColor(level),
                        width: '1.756vw',
                        height: '1.756vw',
                        cursor: "auto"
                    }}
                    textStyle={{ color: THEME.white, fontSize: '0.878vw' }}
                />
            </div>
        )
    }

    const handlePipelineStatus = (value) => {
        setPipelineStatus(value)
    }

    const handleClose = () => {
        showConfirm.type === "move" ? dispatch(pipelineSliceActions.clearMoveQueState()) : dispatch(pipelineSliceActions.clearMoveTrashState());
        setShowConfirm({ visible: false, type: "", job_order_id: null, job_order_name: null });
        setSelectedItems(new Map());
    }

    const handleClearError = () => {
        showConfirm.type === "move" ? dispatch(pipelineSliceActions.clearMoveQueError()) : dispatch(pipelineSliceActions.clearMoveTrashError())
    }
    const handleFormState = (val, job_order_id) => {
        const updatedFormState = formState.map((item) => {
            if (item.id === job_order_id) {
                return {
                    ...item,
                    pipeline_status_id: val
                };
            }
            return item;
        });
        setFormState(updatedFormState);
        handleJobOrderPipelineStatusChange(job_order_id, val)

    }

    const handleSubmit = () => {
        const payload = (selectedItems.size === 0 && showConfirm.job_order_id) ? [{ job_id: showConfirm.job_order_id ?? "", talent_id: talentId }] : selectedJobOrdersList
        if (showConfirm.type === "move") {
            handleMoveToQueue(payload)
        }
        else {
            handleMoveToTrash(payload)
        }
    }
    const handleOpenConfirm = (type: string, joborderId?: string, joborderName?: string | null) => {
        if (selectedItems.size === 1 && selectedJobOrdersList.length !== 0) {
            const firstItem = selectedItems.values().next().value;
            if (firstItem) {
                const { job_id, job_title } = firstItem;
                setShowConfirm({ visible: true, type: type, job_order_id: job_id, job_order_name: job_title })
            }
        } else if (selectedItems.size > 1) {
            setShowConfirm({ visible: true, type: type, job_order_id: null, job_order_name: null })

        } else {
            setShowConfirm({ visible: true, type: type, job_order_id: joborderId ?? "", job_order_name: joborderName ?? "" })
        }

    }

    function handleMouseOver(id) {
        setIsHovering({ id: id, visible: true });
    }
    function handleMouseOut() {
        setIsHovering(null);
    }

    return (
        <div className="talent-matches-container">
            <div className="talent-pipeline-header">
                <div className="header-left-section">
                    {directory === TalentPipelineDirectory.Match &&
                        <div className=" right-alignment multiple-btns">
                            <CustomButton
                                key={"que" + index}
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("move_to_qualified_jobs")}
                                enable={selectedItems.size !== 0}
                                onClick={() => handleOpenConfirm("move")}
                                customClassName={'mul-que'}
                                backgroundColor={THEME.defaultHighLightColor}
                            />
                            <CustomButton
                                key={"trash" + index}
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("move_selected_to_trash")}
                                enable={selectedItems.size !== 0}
                                onClick={() => handleOpenConfirm("trash")}
                                customClassName={"mul-trash"}
                                backgroundColor={THEME.defaultHighLightColor}
                            /></div>
                    }

                    {directory === TalentPipelineDirectory.Queued &&
                        <div className="right-alignment">
                            <CustomMultiFormSelect
                                label={t("pipeline_status")}
                                name={"company_status"}
                                list={pipelineStatusList.map((doc) => ({
                                    label: doc.value,
                                    value: doc.id,
                                }))}
                                onChange={handlePipelineStatus}
                                required={false}
                                placeholder={t("all")}
                                value={pipelineStatus}
                                customStyle={{ width: "13vw" }}
                            />
                        </div>
                    }

                </div>
                <div className="header-right-section">
                    <div className="total-count">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number"><b>{getFilteredList()?.length}</b></span>
                        </span>
                    </div>
                    <div className="right-alignment">
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                        />
                    </div>
                </div>
            </div>
            <div className="talent-matches-table">
                <SortableTable
                    headerList={tableheaderList}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {loading ?
                        <SpinnerScreen />
                        :
                        <>
                            {getFilteredList() && getFilteredList()?.length! > 0 ? (
                                <>
                                    {getFilteredList()?.map((doc, docIndex) => {
                                        return (
                                            <tr key={directory + doc.id + docIndex}>
                                                {directory !== TalentPipelineDirectory.Trashed && <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                    <CustomCheckBox
                                                        name={""}
                                                        title={""}
                                                        checked={selectedItems!.has(doc.id)}
                                                        onClick={() => handleMarkItem(doc)}
                                                    />
                                                </TableData>}
                                                <TableData customStyle={{ flex: getFlexNumber(1) }} isButton={true} onClick={() => handleUserSelect(doc)}>
                                                    <span>{doc.job_title ?? ""}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(2) }} isButton={true} onClick={() => handleCompanySelect(doc)}>
                                                    <span>{doc.company_name ?? ""}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                    {directory === TalentPipelineDirectory.Queued ?
                                                        <CustomFormSelect
                                                            name={'pipeline_status'}
                                                            value={formState.find((pipeline) => pipeline.id === doc.id)?.pipeline_status_id}
                                                            placeholder={t('select')}
                                                            list={pipelineStatusList.map((doc) => ({
                                                                label: doc.value,
                                                                value: doc.id,
                                                            }))}
                                                            label={t("")}
                                                            onChange={(val) => (handleFormState(val, doc.id))}
                                                            required={false}
                                                        />
                                                        :
                                                        <span>{doc.pipeline_status ?? ""}</span>
                                                    }

                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                                    <div
                                                        className="col-hover-container"
                                                        style={{ cursor: "pointer" }}
                                                        onMouseOut={() => handleMouseOut()}
                                                        onMouseOver={() => handleMouseOver(doc.id)}
                                                    >
                                                        <span>
                                                            {doc.matched_skills && doc.matched_skills.length > 5
                                                                ? <>
                                                                    {doc.matched_skills.slice(0, 5).map((item, matchIndex, arr) => (
                                                                        <span key={matchIndex}>
                                                                            {item} {matchIndex !== arr.length - 1 ? ", " : ""}
                                                                        </span>
                                                                    ))}
                                                                    <span>...</span>
                                                                </>
                                                                : doc.matched_skills.map((item, matchIndex, arr) => (
                                                                    <span key={matchIndex}>
                                                                        {item} {matchIndex !== arr.length - 1 ? ", " : ""}
                                                                    </span>
                                                                ))
                                                            }
                                                        </span>
                                                        {isHovering && (doc.id === isHovering.id) && (
                                                            <div className="parent-position">
                                                                <div className="child-position">
                                                                    <div className="action-list-title">
                                                                        {doc.matched_skills.map((item, matchIndex, arr) => (
                                                                            <span key={matchIndex}>
                                                                                {item} {matchIndex !== arr.length - 1 ? ", " : ""}&nbsp;&nbsp;
                                                                            </span>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(5), color: THEME.statusInActiveColor }}>
                                                    <span>{doc.requested}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(6), color: THEME.buttonColor17 }}>
                                                    <span>{doc.placed}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(7), color: THEME.statusInActiveColor }}>
                                                    <span>{doc.over_under_placed}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                                    <span>{doc.match_score ? (doc.match_score + "%") : 0}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(9) }}>
                                                    <span>{doc.pay_rate ? currencyConversion(doc.pay_rate) : "$0.00"}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(10) }}>
                                                    <span>{getRatings(doc.engagement_rating, doc.engagement_level)}</span>
                                                </TableData>
                                                {directory !== TalentPipelineDirectory.Trashed &&
                                                    <>
                                                        <TableData customStyle={{ flex: getFlexNumber(11) }}>

                                                            <div className="icons-row">
                                                                {directory === TalentPipelineDirectory.Match
                                                                    ?
                                                                    <span className="chaticon" onClick={() => handleOpenConfirm("move", doc.id, doc.job_title)}>{<MarkTalentIcon height={"1.5vw"} width={"1.5vw"} />}</span>
                                                                    :
                                                                    <span className="chaticon" onClick={() => handleInterviewRedirect(doc)}>{<PaperPlaneIcon height={"1.5vw"} width={"1.5vw"} />}</span>
                                                                }

                                                            </div>

                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(12) }}>
                                                            <div className="icons-row">
                                                                <span className="delicon" onClick={() => handleOpenConfirm("trash", doc.id, doc.job_title)}>{<DeleteIcon height={"1vw"} width={"1vw"} />}</span>
                                                            </div>
                                                        </TableData>
                                                    </>
                                                }
                                            </tr>
                                        )
                                    })}
                                </>
                            )
                                :
                                <>
                                    <tr className="talent-pipeline-nqd">
                                        <TableData customStyle={{ flex: getFlexNumber(20) }} align="center" >
                                            <div className="nqd_div">
                                                {directory === TalentPipelineDirectory.Match ? t("no_matched_jobs") :
                                                    (directory === TalentPipelineDirectory.Trashed ? t("no_trashed_jobs") : t("no_que_jobs"))}
                                            </div>
                                        </TableData>
                                    </tr>
                                </>
                            }

                        </>
                    }
                </SortableTable>
            </div>
            {
                showConfirm.visible && (
                    <Portal>
                        <ConfirmationDialog
                            title={t(showConfirm.type === "move" ? "move_to_que" : "move_to_trash")}
                            onClose={handleClose}
                            type={showConfirm.type ?? ""}
                            message={t(showConfirm.type === "move" ? "are_u_sure_to_move_talent" : "are_u_sure_to_trash_talent", { name: selectedItems.size > 1 ? "the selected jobs" : showConfirm.job_order_name })}
                            state={showConfirm.type === "move" ? jobOrderQueState : jobOrderTrashState}
                            onClearError={handleClearError}
                            onConfirm={handleSubmit}
                            talent_name={showConfirm.job_order_name ?? ""}
                            currentLoginUserName={currentLoginUserName}
                            onSuccessClose={() => { handleClose(); onSuccessClose() }}
                        />
                    </Portal>
                )
            }

        </div >

    )
}

export default JobOrderMatchesSection;
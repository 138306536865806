import { IconButton, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SalesMarketingAPI } from "../../../../apis/sales_marketing_api";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import CustomFormSelect, {
  CustomMultiFormSelect,
} from "../../../../components/form_selector/form_select";
import { LoadingType, THEME } from "../../../../enums";
import { LinkIcon, SalesmarketIcon } from "../../../../icons";
import { ICompetitor } from "../../../../interfaces";
import {
  getBusinessSectors,
  selectBusinessSectorList,
} from "../../../../redux/admin_center";
import {
  getCompaniesList,
  getDepartmentsList,
  selectBusinessSectorListState,
  selectCompaniesList,
  selectCompaniesListState,
} from "../../../../redux/companies";
import { getCompetitorDetails } from "../../../../redux/sales_marketing/competitor_sheet/competitor_details/competitor_details_thunk";
import { manageCompetitorActions } from "../../../../redux/sales_marketing/competitor_sheet/manage_competitors/manage_competitors_reducer";
import {
  selectManageCompetitorCreateState,
  selectManageCompetitorUpdateState,
} from "../../../../redux/sales_marketing/competitor_sheet/manage_competitors/manage_competitors_selector";
import {
  createCompetitor,
  updateCompetitor,
} from "../../../../redux/sales_marketing/competitor_sheet/manage_competitors/manage_competitors_thunk";
import {
  getCompaniesDropdown,
  getCompetitorsList,
  postSnackbarMessage,
  selectCompaniesDropdownList,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  enforceFormat,
  formatToPhone,
  FormInput,
  mapOptions,
  RegxPattern,
  SpinnerScreen,
} from "../../../../utils";
import { US_states } from "../../../../utils/States_US";
import { DeleteConfirmationDialog } from "../../../components/delete_confirmation_dialog";
import "./add_competitor.scss";
import { statusOptions } from "../../../../variables";

export interface AddCompetitorProps {
  showModal: boolean;
  callbackHandler: Function;
  competitorInfo?: ICompetitor;
  callAPI: () => void;
}

export const AddCompetitor: FC<AddCompetitorProps> = (
  props: AddCompetitorProps
) => {
  const [competitor, setCompetitor] = useState<any>({ ...props.competitorInfo, status: "ACTIVE" });
  const { callbackHandler, showModal, callAPI } = props;
  const actionRef = useRef<ActionDialogHolderType>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
  const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean>(false);
  const history = useHistory();
  const editMode = !!props.competitorInfo && props.competitorInfo.id;
  const businessSectorListState = useAppSelector((state) =>
    selectBusinessSectorListState(state)
  );
  const businessSectors = useAppSelector((state) =>
    selectBusinessSectorList(state)
  );
  const companiesListState = useAppSelector((state) =>
    selectCompaniesListState(state)
  );
  // const companies = useAppSelector((state) =>
  //   selectCompaniesList(state)
  // ).filter((t) => t.type === "COMPANY");

  const companiesDropdown = useAppSelector((state) =>
    selectCompaniesDropdownList(state)
  );

  const createState = useAppSelector((state) =>
    selectManageCompetitorCreateState(state)
  );

  const updateState = useAppSelector((state) =>
    selectManageCompetitorUpdateState(state)
  );

  useEffect(() => {
    // dispatch(getCompaniesList());
    dispatch(getCompaniesDropdown());
    // dispatch(getDepartmentsList());
    dispatch(getBusinessSectors());
  }, []);

  useEffect(() => {
    if (createState.loading == LoadingType.succeeded) {
      dispatch(postSnackbarMessage(createState.response ?? null));
      dispatch(getCompetitorsList());
      dispatch(manageCompetitorActions.clearCreateState());
      callbackHandler();
      callAPI()
    }
  }, [createState.loading]);

  useEffect(() => {
    if (updateState.loading == LoadingType.succeeded) {
      dispatch(postSnackbarMessage(updateState.response ?? null));
      dispatch(getCompetitorDetails(competitor.id));
      dispatch(manageCompetitorActions.clearUpdateState());
      callbackHandler();
    }
  }, [updateState.loading]);

  const toggle = () => {
    callbackHandler();
  };

  const closePopup = (action?: () => void) => {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  };

  const showSpinner = (): boolean =>
    businessSectorListState.loading === LoadingType.pending ||
    companiesListState.loading === LoadingType.pending;

  const updateCompetitorProp = (key: string, value: any) => {
    const newCompetitor = { ...competitor };
    newCompetitor[key] = value;
    if (newCompetitor) {
      setCompetitor(newCompetitor);
    }
  };

  const validations = (value: { text: string; field: string }) => {
    switch (value.field) {
      case "competitor_name": {
        if (value.text === "" || value.text === undefined || value.text === null) return t("validators.required");
        if (RegxPattern.username.test(value.text) === false)
          return t("validators.enterValidName");
        return null;
      }
      case "website": {
        if (!value.text) return null;
        if (RegxPattern.url.test(value.text) === false)
          return t("validators.enterValidUrl");
        return null;
      }
      case "linkedin_url":
      case "facebook_url":
      case "youtube_url":
      case "instagram_url": 
      case "twitter_url": {
        if (!value.text) return null;
        if (RegxPattern.url.test(value.text) === false)
          return t("validators.enterValidUrl");
        return null;
      }
      case "contact": {
        if (!value.text) return null;
        if (RegxPattern.usPhone.test(value.text) === false)
          return t("validators.enterValidPhoneNumber");
        return null;
      }
      default:
        return null;
    }
  };

  const handleSave = () => {
    setCheckValidationOnSubmit(true);
    if (!_isFormValid()) {
      if (editMode) {
        dispatch(updateCompetitor(competitor));
      } else {
        dispatch(createCompetitor(competitor));
      }
      setCheckValidationOnSubmit(false);
    }
  };

  const updateSocialMediaProp = (key, value) => {
    setCompetitor({
      ...competitor,
      social_media_links: { ...competitor.social_media_links, [key]: value },
    });
  };

  const getLinkIcon = () => {
    return (
      <div className="cmpny-f-link-icon">
        <LinkIcon width={"100%"} height={"100%"} />
      </div>
    );
  };

  const _isFormValid = () => {
    if (competitor.competitor_name === "") return false;
    return;
  }

  const handleDelete = async () => {
    setDeleting(true);
    try {
      const res = await SalesMarketingAPI.deleteCompetitor(competitor.id);
      if (Array.isArray(res.data)) {
        const message = res.data.join(", ");
        setSnackBarMessage(message);
      } else if (res.data) {
        setShowDeleteDialog(false);
        dispatch(postSnackbarMessage(res.data));
        history.push(AppRoutes.salesAndMarketingCompetitorSheet);
      }
    } catch (e: any) {
      const message = e.response?.data?.detail?.error?.join("\n");
      setSnackBarMessage(message);
    } finally {
      setDeleting(false);
      setShowDeleteDialog(false);
    }
  };

  const snackbarAction = () => {
    return (
      <>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setSnackBarMessage(null)}
        >
          <Close fontSize="small" />
        </IconButton>
      </>
    );
  };

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={showModal}
      wrapperWidth={"50%"}
      onClose={toggle}
      onDissmiss={toggle}
    >
      <DialogWrapper onClose={() => closePopup(toggle)}>
        {showSpinner() ? (
          <div className="loading-spinner">
            <SpinnerScreen />
          </div>
        ) : (
          <div className="uf-container competitor-form">
            <div className="uf-header">
              <FormDialogTilteHeader
                title={editMode ? "Edit Competitor" : "Add Competitor"}
                titleIcon={<SalesmarketIcon width={"100%"} height={"100%"} />}
              />
            </div>
            <div className="uf-content">
              <div className="row my-1">
                <div className="col-md-6">
                  <FormInput
                    id={"competitor_name"}
                    onChange={updateCompetitorProp}
                    type={"text"}
                    value={competitor?.competitor_name}
                    label={t("competitor name")}
                    placeholder=""
                    validations={validations}
                    checkValidationOnSubmit={checkValidationOnSubmit}
                    required
                  />
                </div>
                <div className="col-md-2">
                  <CustomFormSelect
                    customStyle={{ width: "-webkit-fill-available" }}
                    name={"status"}
                    value={competitor?.status}
                    required={false}
                    label={t("status")}
                    list={statusOptions}
                    onChange={(value) => updateCompetitorProp("status", value)}
                  />
                </div>
              </div>
              <div className="row my-1">
                <div className="col-md-3">
                  <FormInput
                    id={"street_address"}
                    onChange={updateCompetitorProp}
                    type={"text"}
                    value={competitor?.street_address}
                    label={t("street_address")}
                    placeholder=""
                  />
                </div>
                <div className="col-md-3">
                  <FormInput
                    id={"street_address2"}
                    onChange={updateCompetitorProp}
                    type={"text"}
                    value={competitor?.street_address2}
                    label={t("street_address2")}
                    placeholder=""
                  />
                </div>
                <div className="col-md-3">
                  <FormInput
                    id={"city"}
                    onChange={updateCompetitorProp}
                    type={"text"}
                    value={competitor?.city}
                    label={t("city")}
                    placeholder=""
                  />
                </div>
                <div className="col-md-3 selector-dropdown">
                  <CustomFormSelect
                    customStyle={{ width: "-webkit-fill-available" }}
                    value={competitor?.state}
                    name="state"
                    label={t("state")}
                    placeholder={t("select_placeholder")}
                    list={mapOptions(US_states, "name", "code")}
                    onChange={(value) => updateCompetitorProp("state", value)}
                  />
                </div>
                <div className="col-md-3">
                  <FormInput
                    id={"zip_code"}
                    onChange={updateCompetitorProp}
                    type={"text"}
                    value={competitor?.zip_code}
                    label={t("zip_code")}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row my-1">
                <div className="col-md-3">
                  <FormInput
                    id={"website"}
                    onChange={updateCompetitorProp}
                    type={"text"}
                    value={competitor?.website}
                    label={t("website")}
                    placeholder=""
                    validations={validations}
                  />
                </div>
                <div className="col-md-3">
                  <FormInput
                    validations={validations}
                    id={"contact"}
                    onChange={updateCompetitorProp}
                    type={"text"}
                    value={competitor.contact}
                    label={t("contact")}
                    placeholder=""
                    maxLength={16}
                    onKeyDown={enforceFormat}
                    onKeyUp={formatToPhone}
                  />
                </div>
                <div className="col-md-3  selector-dropdown">
                  <CustomMultiFormSelect
                    name="competitor_customers"
                    list={businessSectors.map((doc) => ({
                      label: doc.business_sector,
                      value: doc.id,
                    }))}
                    onChange={(value) =>
                      updateCompetitorProp("domain_expertise_ids", value)
                    }
                    placeholder={t("select")}
                    value={competitor.domain_expertise_ids ?? []}
                    label={t("industry_expertise")}
                  />
                </div>
                <div className="col-md-3  selector-dropdown">
                  <CustomMultiFormSelect
                    name="competitor_customers"
                    list={companiesDropdown.map((doc) => ({
                      label: doc.name,
                      value: doc.id,
                    }))}
                    onChange={(value) =>
                      updateCompetitorProp("competitor_customers_ids", value)
                    }
                    placeholder={t("select")}
                    value={competitor.competitor_customers_ids ?? []}
                    label={t("competitor_customers")}
                  />
                </div>
              </div>
              <div className="row my-2">
              <div className="col">
                  <FormInput
                    validations={validations}
                    id={"linkedin_url"}
                    onChange={updateSocialMediaProp}
                    type={"text"}
                    value={competitor.social_media_links?.linkedin_url}
                    label={t("linked_in")}
                    pattern={RegxPattern.url}
                    placeholder=""
                    labelIcon={getLinkIcon()}
                  />
                </div>
                <div className="col">
                  <FormInput
                    validations={validations}
                    id={"facebook_url"}
                    onChange={updateSocialMediaProp}
                    type={"text"}
                    value={competitor.social_media_links?.facebook_url}
                    label={t("facebook")}
                    pattern={RegxPattern.url}
                    placeholder=""
                    labelIcon={getLinkIcon()}
                  />
                </div>
                
                <div className="col">
                  <FormInput
                    validations={validations}
                    id={"youtube_url"}
                    onChange={updateSocialMediaProp}
                    type={"text"}
                    value={competitor.social_media_links?.youtube_url}
                    label={t("youtube")}
                    pattern={RegxPattern.url}
                    placeholder=""
                    labelIcon={getLinkIcon()}
                  />
                </div>
                <div className="col">
                  <FormInput
                    validations={validations}
                    id={"instagram_url"}
                    onChange={updateSocialMediaProp}
                    type={"text"}
                    value={competitor.social_media_links?.instagram_url}
                    label={t("instagram")}
                    pattern={RegxPattern.url}
                    placeholder=""
                    labelIcon={getLinkIcon()}
                  />
                </div>
                <div className="col">
                  <FormInput
                    validations={validations}
                    id={"twitter_url"}
                    onChange={updateSocialMediaProp}
                    type={"text"}
                    value={competitor.social_media_links?.twitter_url}
                    label={t("x")}
                    pattern={RegxPattern.url}
                    placeholder=""
                    labelIcon={getLinkIcon()}
                  />
                </div>
              </div>
              <div className="row my-2 mb-4">
                <FormInput
                  id={"overview"}
                  onChange={updateCompetitorProp}
                  required={false}
                  placeholder={""}
                  type={"text"}
                  multiline={true}
                  value={competitor.overview}
                  numberOfLines={3}
                  fieldStyles={{ width: "25vw" }}
                  label={t("Overview")}
                  customStyle={{ width: "-webkit-fill-available" }}
                />
              </div>
            </div>
            <div className="uf-actions">
              {editMode && (
                <div style={{ marginRight: "auto" }}>
                  <CustomButton
                    loading={false}
                    textStyle={{ textTransform: "capitalize" }}
                    name={t("delete_field", { name: t("competitor") })}
                    enable={true}
                    backgroundColor={THEME.statusInActiveColor}
                    onClick={() => setShowDeleteDialog(true)}
                  />
                </div>
              )}
              <div className="btn-cancel">
                <CustomButton
                  loading={false}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("cancel")}
                  enable
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={() => closePopup(toggle)}
                />
              </div>
              <div className="btn-save">
                <CustomButton
                  loading={createState.loading === LoadingType.pending}
                  textStyle={{ textTransform: "capitalize" }}
                  name={editMode ? "Save" : "Add Competitor"}
                  // enable={!!competitor.competitor_name}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={handleSave}
                />
              </div>
            </div>
            {showDeleteDialog && editMode && (
              <DeleteConfirmationDialog
                message={t("delete_competitor_message", {
                  competitor: competitor.competitor_name,
                })}
                onCancel={() => setShowDeleteDialog(false)}
                onConfirm={handleDelete}
                deleting={deleting}
                deleteType={t("contact")}
                onClearError={()=>{}}
              />
            )}
            <Snackbar
              open={snackBarMessage !== null}
              autoHideDuration={5000}
              onClose={() => setSnackBarMessage(null)}
              message={snackBarMessage}
              action={snackbarAction()}
            />
          </div>
        )}
      </DialogWrapper>
    </ActionDialogHolder>
  );
};

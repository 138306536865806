import {
  IUpdateReport
} from "../../../../interfaces/work_comp_audit_and_compliance_reporting";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { CustomButton, RegxPattern, SpinnerScreen } from "../../../../utils";
import { AppError, FormInputOption } from "../../../../interfaces";
import RenderInput from "../../../../components/render_input/render_input";
import { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType, THEME } from "../../../../enums";
import { CalenderIcon, ClaimStatusIcon } from "../../../../icons";
import "./report_form.scss";
import DragAndDrop from "../../../../components/drag_and_drop/drag_and_drop";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { convertDateToTimeStamp, getDateFromTimeStamp } from "../../../../variables";
import { RiskMgmtInsuranceCompany } from "../../../../interfaces/risk_mgmt_insurance_company";
import { workCompAuditAndComplianceReportingListActions } from "../../../../redux/risk_management/universal_directory/work_comp_audit_and_compliance_reporting/work_comp_audit_and_compliance_reporting_reducer";
import { RiskMgmtInsuranceCompanyState } from "../../../../redux/risk_management/universal_directory/insurance_company/insurance_company_reducer";
import { useAppDispatch } from "../../../../redux/store";
import { getAllInsuranceCompanies, workCompAuditAndComplianceReportDelete } from "../../../../redux/risk_management";
import { DeleteConfirmationDialog } from "../../../components/delete_confirmation_dialog";

interface IProps {
  title: string;
  onClose: () => void;
  onSubmit: (
    data: IUpdateReport,
    attachedDocs: Array<File> | null
  ) => void;
  reportInfo?: IUpdateReport;
  state: {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
  };
  history?: RouteComponentProps["history"];
  insuranceCompanyState: RiskMgmtInsuranceCompanyState;
  insuranceCompanies: RiskMgmtInsuranceCompany[];
  wccrDeleteDocState: {
    error?: AppError | null;
    loading: LoadingType;
    response: string;
}
}

const ReportForm: React.FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation();
  const [showDeleteDialog, setShowDeleteDialog] = useState<{visible: boolean, name: string} | null>(null);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  const initialForm: IUpdateReport = {
    insurance_company_id: "",
    report_name: "",
    report_number: "",
    report_status: "",
    report_or_audit_period_start: 0,
    report_or_audit_period_end: 0,
    date_due: 0,
    date_completed: 0,
    next_steps: "",
    date_submitted: 0,
    report_docs: [],
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllInsuranceCompanies());
  }, []);

  const getInsuranceCompaniesForSelection = () => {
    return props.insuranceCompanies ? props.insuranceCompanies.map(comp => {
      return { label: comp.insurance_company_name, value: comp.id }
    }) : [];

  }

  const [formState, setFormState] = useState<IUpdateReport>(
    props.reportInfo ? props.reportInfo : initialForm
  );
  const [checkValidationOnSubmit, setCheckValidationOnSubmit] =
    useState<boolean>(false);
  const history = useHistory();
  const [reportDateRange, setReportDateRange] = useState(
    props.reportInfo
      ? [
        props.reportInfo.report_or_audit_period_start !== 0 ? getDateFromTimeStamp(props.reportInfo.report_or_audit_period_start) : 0,
        props.reportInfo.report_or_audit_period_end !== 0 ? getDateFromTimeStamp(props.reportInfo.report_or_audit_period_end) : 0,
      ]
      : [null, null]
  );
  const [reportStartDate, reportEndDate] = reportDateRange;
  const [reportDocs, setReportDocs] = useState<Array<File> | null>(null);

  useEffect(() => {
    if ((formState.report_or_audit_period_start !== 0 && formState.report_or_audit_period_end !== 0)) {
      setDatePickerOpen(false)
    }
  }, [formState.report_or_audit_period_start, formState.report_or_audit_period_end])

  const handleSubmit = () => {
    setCheckValidationOnSubmit(true);
    const requiredFieldArray = [
      "insurance_company_id",
      "report_name",
      "report_number",
      "report_status",
      "report_or_audit_period",
      "date_due",
      "date_completed",
      "next_steps",
      "date_submitted",
    ];
    requiredFieldArray.map((field) => {
      handleFieldChange(field, formState[field]);
    });
    if (_isFormValid()) {
      props.onSubmit({ ...formState }, reportDocs);
      setCheckValidationOnSubmit(false);
    }
  };

  const _isFormValid = () => {
    const textValues = [
      formState.insurance_company_id,
      formState.report_name,
      // formState.report_number,
      formState.report_status,
    ];
    for (const value of textValues) {
      if (value === "") return false;
      if (RegxPattern.username.test(value) === false) return false;
    }

    return true;
  };

  const reportStatusList = [
    { label: "Due", value: "Due" },
    { label: "Complete & Submitted", value: "Complete & Submitted" },
  ];

  const formBasicInformation: (FormInputOption | FormInputOption[])[] = [
    /// 1st row
    {
      field: "insurance_company_id",
      type: "selector",
      label: "insurance_company",
      secure: false,
      required: true,
      placeholder: "select",
      keyboardtype: "",
      selectorList: getInsuranceCompaniesForSelection()
    },
    /// 2nd row
    [
      {
        field: "report_name",
        type: "text",
        label: "report_name",
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "report_number",
        type: "text",
        label: "report_number",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "report_status",
        type: "selector",
        label: "report_status",
        secure: false,
        required: true,
        placeholder: "select",
        keyboardtype: "",
        selectorList: reportStatusList,
        multi: false,
      },
    ],
    /// 3rd row
    [
      {
        field: "report_or_audit_period",
        type: "date",
        label: "report_or_audit_period",
        secure: false,
        required: false,
        placeholder: "MM/DD/YYYY",
        keyboardtype: "",
        setHoursToZero: true,
      },
      {
        field: "date_due",
        type: "date",
        label: "date_due",
        secure: false,
        required: false,
        placeholder: "MM/DD/YYYY",
        keyboardtype: "",
        setHoursToZero: true,
      },
      {
        field: "date_completed",
        type: "date",
        label: "date_completed",
        secure: false,
        required: false,
        placeholder: "MM/DD/YYYY",
        keyboardtype: "",
        setHoursToZero: true,
      },
    ],
    /// 4th row
    [
      {
        field: "next_steps",
        type: "text",
        label: "next_steps",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
        multiline: true,
        numberOfLines: 10,
        containerStyle: {
          flex: 1,
        }
      },
    ],
  ];

  const handleFieldChange = (fieldId: string, value: any, parent?: string) => {
    if (parent) {
      setFormState({
        ...formState,
        [parent]: { ...formState[parent], [fieldId]: value },
      });
    } else {
      setFormState({ ...formState, [fieldId]: value });
    }
  };

  function reportFormValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case "insurance_company_id":
      case "report_name":
      case "report_status": {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.username.test(value.text) === false)
          return t("validators.enterValidName");
        return null;
      }
      default: {
        return null;
      }
    }
  }

  const date_submitted_doc = {
    field: "date_submitted",
    type: "date",
    label: "date_submitted",
    secure: false,
    required: false,
    placeholder: "MM/DD/YYYY",
    keyboardtype: "",
    setHoursToZero: true,
  };

  function handleReportDateRangeChange(value) {
    setReportDateRange(value);
    setFormState({
      ...formState,
      report_or_audit_period_start:
        value[0] !== null ? convertDateToTimeStamp(value[0]) : 0,
      report_or_audit_period_end:
        value[1] !== null ? convertDateToTimeStamp(value[1]) : 0,
    });
  }

  const openDatePicker = () => {
    setDatePickerOpen(true);
  };

  /// Section one
  function getSectionOne() {
    return (
      <div className="report-f-section-divider">
        {formBasicInformation.map((doc, index) => {
          if (Array.isArray(doc)) {
            return (
              <div key={index + "row"} className="report-f-row">
                {doc.map((subDoc, subIndex) => {
                  if (subDoc.field == "report_or_audit_period") {
                    return (
                      <div key={subIndex + "subrow"} className="dateRange report-f-input-holder">
                        <label>{t("report_or_audit_period")}</label>
                        <div className="date-input-icon">
                          <div className="calendar-icon-div">
                            <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={openDatePicker} />
                          </div>
                          <DatePicker
                            selectsRange={true}
                            startDate={reportStartDate}
                            endDate={reportEndDate}
                            onChange={handleReportDateRangeChange}
                            isClearable={true}
                            placeholderText={'Start Date -  End Date'}
                            dateFormat="MM/dd/yy"
                            popperProps={{
                              positionFixed: true,
                              strategy: "fixed"// use this to make the popper position: fixed
                            }}
                            onClickOutside={() => setDatePickerOpen(false)}
                            open={isDatePickerOpen}
                            onFocus={() => setDatePickerOpen(true)}
                            showMonthDropdown={true}
                            showYearDropdown={true}
                            dropdownMode="select"
                          />
                        </div>
                      </div>
                    );
                  }
                  return (
                    <RenderInput
                      key={index + subIndex}
                      doc={subDoc}
                      index={subIndex}
                      formState={formState}
                      handleFieldChange={handleFieldChange}
                      formValidators={reportFormValidators}
                      className={"report-f-input-holder"}
                      checkValidationOnSubmit={checkValidationOnSubmit}
                    />
                  );
                })}
              </div>
            );
          }

          return (
            <RenderInput
              key={index}
              doc={doc}
              index={index}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={reportFormValidators}
              className={"report-f-input-holder"}
              checkValidationOnSubmit={checkValidationOnSubmit}
            />
          );
        })}
        <div key={"3row"} className="report-f-row">
          <RenderInput
            key={3}
            doc={date_submitted_doc}
            index={3}
            formState={formState}
            handleFieldChange={handleFieldChange}
            formValidators={reportFormValidators}
            className={"report-f-input-holder"}
            checkValidationOnSubmit={checkValidationOnSubmit}
          />
          <div style={{ width: "65%" }} className="drag-drop-div">
            <DragAndDrop
              label={t("report_docs")}
              selectedFiles={reportDocs}
              accept={
                ".pdf,image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              }
              onMultiChange={onDocumentChange}
              onMultiRemove={onRemoveDocument}
              formValidators={validateDocumentType}
              multiple={true}
              type="small"
            />
          </div>
        </div>
      </div>
    );
  }

  const validateDocumentType = () => {
    return null;
  };

  function onDocumentChange(value: Array<File>) {
    if (reportDocs && reportDocs?.length > 0) {
      setReportDocs([...reportDocs, ...value]);
      //setReportDocsInFormData([...reportDocs, ...value]);
    } else {
      setReportDocs(value);
      //setReportDocsInFormData(value);
    }
  }

  function setReportDocsInFormData(reportDocs: Array<File>) {
    let report_docs: string[] = [];
    reportDocs &&
      reportDocs.map((doc, i) => {
        report_docs.push(doc.name);
      });
    setFormState({ ...formState, report_docs: report_docs });
  }

  function onRemoveDocument(index: number) {
    if (reportDocs && reportDocs?.length > 0) {
      const newArr = [...reportDocs];
      newArr.splice(index, 1);
      setReportDocs(newArr);
      setReportDocsInFormData(newArr);
    } else {
      setReportDocs(null);
      setReportDocsInFormData([]);
    }
  }

  const onClose = () => {
    dispatch(workCompAuditAndComplianceReportingListActions.clearWCCRDeleteState())
    setShowDeleteDialog(null)
  }

  const hadnleDelete = () => {
    dispatch(workCompAuditAndComplianceReportDelete(props.reportInfo?.id!))
  }

  return (
    <>
      {props.insuranceCompanyState.loading === LoadingType.pending ? <div className="loading-spinner"><SpinnerScreen /></div> :
        (
          <div className="report-f-container">
            <div className="report-f-header">
              <FormDialogTilteHeader
                title={props.title}
                titleIcon={<ClaimStatusIcon width={"100%"} height={"100%"} />}
              />
            </div>
            <div className="report-f-content">{getSectionOne()}</div>
            <div className="report-f-actions">
              {props.reportInfo && <div style={{ marginRight: "auto" }}>
                <CustomButton
                  loading={false}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("delete_field", { name: t("") })}
                  enable={true}
                  backgroundColor={THEME.statusInActiveColor}
                  onClick={() => setShowDeleteDialog({visible: true, name: props.reportInfo?.report_name!})}
                />
              </div>}
              <div className="btn-cancel">
                <CustomButton
                  loading={false}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("cancel")}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={props.onClose}
                />
              </div>

              <div className="btn-save">
                <CustomButton
                  loading={props.state.loading === LoadingType.pending}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("save")}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={handleSubmit}
                />
              </div>
            </div>
            {showDeleteDialog && (
              <DeleteConfirmationDialog
                message={t("delete_work_comp_audits_and_compliance_reporting_message", {
                  report_name: showDeleteDialog?.name,
                })}
                onCancel={() => setShowDeleteDialog(null)}
                onConfirm={hadnleDelete}
                deleting={props.wccrDeleteDocState.loading === LoadingType.pending}
                deleteType={t("report_name")}
                state={props.wccrDeleteDocState}
                onClearError={onClose}
              />
            )}
          </div>
        )}
    </>
  )

};

export default ReportForm;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {
  NotificationCircle,
  NumberBadge,
} from "../../../../components/notification_circle/notification_circle";
import { LoadingType, THEME } from "../../../../enums";
import {
  CallIcon,
  CalenderIcon,
  CompaniesIcon,
  ContactsIcon,
  MarkTalentIcon,
  PaperPlaneIcon,
  PlusIcon,
  RecruitingBoardIcon,
  SalesBoardIcon,
  ServiceBoardIcon,
  TalentIcon,
  TrafficLightIcon,
  TextIcon,

} from "../../../../icons";
import {
  IProfileDropdown,
  ISalesCall,
  IServiceCall,
  ITrafficEngagement,
} from "../../../../interfaces";
import { getOnlineApplicationsReceivedList } from "../../../../redux/dashboard/metrics/online_applications_received_list/online_applications_received_list_thunk";
import { getOnlineApplicationsSentList } from "../../../../redux/dashboard/metrics/online_applications_sent_list/online_applications_sent_list_thunk";
import {
  getDashboardSalesCallsList,
  getDashboardTotalOpenOrdersCount,
} from "../../../../redux/dashboard/metrics/sales_calls/sales_calls_thunk";
import { sendOnBoardingLinksStateActions } from "../../../../redux/dashboard/metrics/send_onboarding_link/send_onboarding_link_reducer";
import { sendOnlineApplicationsStateActions } from "../../../../redux/dashboard/metrics/send_online_application/send_online_application_reducer";
import { getDashboardServiceCallsList } from "../../../../redux/dashboard/metrics/service_calls/service_calls_thunk";
import { selectTalentAvailableCandidatesCount } from "../../../../redux/dashboard/metrics/talent_available_candidates/talent_available_candidates_selector";
import {
  getCompletedInterviewInviteCount,
  getDashboardCompanyTrafficEngagement,
  getDashboardTalentTrafficEngagement,
  getDashboardTrafficEngagement,
  getRemainingInterviewInviteCount,
  getTalentAvailableCandidatesCount,
  selectDashboardComapnyTrafficEngagementListState,
  selectDashboardOpenOrdersState,
  selectDashboardOpenServiceCallsLength,
  selectDashboardSalesCallList,
  selectDashboardSalesCallListState,
  selectDashboardServiceCallListState,
  selectDashboardTalentTrafficEngagementListState,
  selectDashboardTrafficEngagementCompanyList,
  selectDashboardTrafficEngagementTalentList,
  useAppDispatch,
  useAppSelector,
  getOnlineApplicationsSentCount,
  getOnlineApplicationsReceivedCount,
  selectOnlineApplicationsReceivedCountState,
  selectOnlineApplicationsSentCountState,
  getOnboardingCountThunk,
  selectDashboardTrafficEngagementContactList,
  getRoleUsersList,
  selectRoleUsersList,
  selectProfileState,
  selectInBoundLeadsListState,
  getInBoundLeadsListCount,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  getRatingBackgrounColor,
} from "../../../../utils";
import { convertDateToTimeStamp, getDatesBetween } from "../../../../variables";
import Board from "../../components/board/board";
import BoardHeader from "../../components/board_header/board_header";
import EnagementCard from "../../components/engagement_card/engagement_card";
import InterviewsList from "../../components/interviews_list/interviews_list";
import SalesCallsList from "../../components/sales_calls_list/sales_calls_list";
import ServiceCallsList from "../../components/service_calls_list/service_calls_list";
import TitleSectionHeader from "../../components/title_section_header/title_section_header";
import MarkTalentAvailablePopup from "../../popups/mark_talent_available/mark_talent_available";
import SendOnBoardingLinkPopup from "../../popups/send_onboarding_link/send_onboarding_link";
import SendOnlineApplicationPopup from "../../popups/send_online_application/send_online_application";
import "./metrics_page.scss";
import { selectDashboardContactTrafficEngagementListState } from "../../../../redux/dashboard/metrics/contact_taffic_engagement/contact_taffic_engagement_selector";
import { getDashboardContactTrafficEngagement } from "../../../../redux/dashboard/metrics/contact_taffic_engagement/contact_taffic_engagement_thunk";
import { DateInput } from "../../../../components/date_input/date_input";
import { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import "react-datepicker/dist/react-datepicker.css";
interface Props extends RouteComponentProps<any> { }
interface HoveredState {
  planASalesCall: boolean,
  addCompanyContact: boolean,
  sendOnboardingLink: boolean,
  addJobOrder: boolean,
  markTalentAvailable: boolean,
  sendOnlineApplication: boolean,
  addTalent: boolean,
  leads: boolean
}
const MetricsPage: React.FunctionComponent<Props> = (props) => {
  const { history, location, match } = props;
  const { params } = match;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const salesCallsState = useAppSelector((state) =>
    selectDashboardSalesCallListState(state)
  );
  const totalOpenOrdersState = useAppSelector((state) =>
    selectDashboardOpenOrdersState(state)
  );

  const salesCallsList = useAppSelector((state) =>
    selectDashboardSalesCallList(state)
  );
  const serviceCallsState = useAppSelector((state) =>
    selectDashboardServiceCallListState(state)
  );
  const serviceCallsOpenListLength = useAppSelector((state) =>
    selectDashboardOpenServiceCallsLength(state)
  );

  const companyTrafficEngagement = useAppSelector((state) =>
    selectDashboardTrafficEngagementCompanyList(state)
  );
  const contactTrafficEngagement = useAppSelector((state) =>
    selectDashboardTrafficEngagementContactList(state)
  );
  const talentTrafficEngagement = useAppSelector((state) =>
    selectDashboardTrafficEngagementTalentList(state)
  );

  const companyTrafficLightEngagement = useAppSelector((state) =>
    selectDashboardComapnyTrafficEngagementListState(state)
  );
  const contactTrafficLightEngagement = useAppSelector((state) =>
    selectDashboardContactTrafficEngagementListState(state)
  );
  const talentTrafficLightEngagement = useAppSelector((state) =>
    selectDashboardTalentTrafficEngagementListState(state)
  );

  const availableCandidatesList = useAppSelector((state) =>
    selectTalentAvailableCandidatesCount(state)
  );
  const [showMarkTalentAvailable, setShowMarkTalentAvailable] =
    useState<boolean>(false);
  const [showSendOnlineApplication, setShowSendOnlineApplication] =
    useState<boolean>(false);

  const [showSendOnBoardingLink, setShowSendOnBoardingLink] =
    useState<boolean>(false);
  const applicationRecievedCount = useAppSelector((state) =>
    selectOnlineApplicationsReceivedCountState(state)
  );
  const applicationSentCount = useAppSelector((state) =>
    selectOnlineApplicationsSentCountState(state)
  );

  const inBoundLeadsListState = useAppSelector((state) =>
    selectInBoundLeadsListState(state)
  );

  const profileState = useAppSelector((state) => selectProfileState(state));
  const currentLoginUserId = profileState.profile?.id;
  const usersList = useAppSelector((state) => selectRoleUsersList(state));

  const [usersListData, setUsersListData] = useState<IProfileDropdown[]>();

  useEffect(() => {
    dispatch(getInBoundLeadsListCount(true))
  }, [])

  useEffect(() => {
    if (usersList.length !== 0) {
      const indexToMove = usersList.findIndex(item => item.id === currentLoginUserId);
      if (indexToMove !== -1) {
        const objectToMove = usersList[indexToMove];
        const objectToMoveCopy = { ...objectToMove }
        objectToMoveCopy.first_name = "Show My Calls";
        objectToMoveCopy.last_name = "";
        const updatedData = usersList.filter(item => item.id !== currentLoginUserId);
        updatedData.unshift(objectToMoveCopy);
        setUsersListData(updatedData);
      }
      else {
        const newObj = { id: currentLoginUserId !== undefined ? currentLoginUserId : "", first_name: "Show My Calls", last_name: "", roles: [], status: 'ACTIVE' }
        const updatedData = usersList.filter(item => item.id !== currentLoginUserId);
        updatedData.unshift(newObj);
        setUsersListData(updatedData);
      }
    }
  }, [currentLoginUserId, usersList])

  const [salesUsers, setSalesUsers] = useState<string[]>([]);
  const [serviceUsers, setServiceUsers] = useState<string[]>([]);
  const [isHovering, setIsHovering] = useState<HoveredState>(
    {
      planASalesCall: false,
      addCompanyContact: false,
      sendOnboardingLink: false,
      addJobOrder: false,
      markTalentAvailable: false,
      sendOnlineApplication: false,
      addTalent: false,
      leads: false
    }
  );
  const [salesFormState, setSalesFormState] = useState<{ available_from: number, available_to: number }>({
    available_from: 0,
    available_to: 0,
  })
  const [serviceFormState, setServiceFormState] = useState<{ available_from: number, available_to: number }>({
    available_from: 0,
    available_to: 0,
  })


  useEffect(() => {
    dispatch(getRoleUsersList());
    getSalesCalls();
    getServiceCalls();
    getTrafficEngagement();
    getAvailableCandidatesCount();
    dispatch(getOnlineApplicationsReceivedList());
    dispatch(getOnlineApplicationsSentList());
    dispatch(getOnlineApplicationsSentCount());
    dispatch(getOnlineApplicationsReceivedCount());
    dispatch(
      getRemainingInterviewInviteCount({
        this_week: true,
        get_count: true,
        completed: false,
      })
    );
    dispatch(
      getCompletedInterviewInviteCount({
        this_week: true,
        get_count: true,
        completed: true,
      })
    );
    dispatch(getOnboardingCountThunk());
    return () => { };
  }, []);

  function getSalesCalls() {
    dispatch(getDashboardSalesCallsList({ my_upcoming_calls: true }));
    dispatch(getDashboardTotalOpenOrdersCount());
  }

  function getServiceCalls() {
    dispatch(getDashboardServiceCallsList({ call_completed: false }));
  }

  function handleMouseOver(value) {
    setIsHovering((prevHovering) => ({
      ...prevHovering,
      [value]: true,
    }));
  }
  function handleMouseOut(value) {
    setIsHovering((prevHovering) => ({
      ...prevHovering,
      [value]: false,
    }));
  }


  function getTrafficEngagement() {
    dispatch(getDashboardTrafficEngagement());
    dispatch(getDashboardCompanyTrafficEngagement());
    dispatch(getDashboardContactTrafficEngagement());
    dispatch(getDashboardTalentTrafficEngagement());
  }

  function getAvailableCandidatesCount() {
    dispatch(
      getTalentAvailableCandidatesCount({
        from_date: convertDateToTimeStamp(new Date(), true),
        to_date: convertDateToTimeStamp(new Date(), true),
      })
    );
  }

  /// [Sales board]

  function handleSalesBoardOpenOrders() {
    history.push({
      pathname: AppRoutes.jobsUniversalDirectory,
      state: {
        openOrder: true,
      },
    });
  }

  function handlePlanMySalesCalls() {
    history.push({
      pathname: AppRoutes.salesAndMarketingSalesCallActivity,
      state: {
        planCall: true,
        source: "dashboard"
      },
    });
  }

  function handleGoToLeads() {
    history.push({
      pathname: AppRoutes.dashboardLeadsPage,
      state: {
        source: "leads"
      },
    });
  }

  function handleAddCompany() {
    history.push({
      pathname: AppRoutes.companiesUniversalDirectory,
      state: {
        newCompany: true,
      },
    });
  }
  function handleAddContact() {
    history.push({
      pathname: AppRoutes.contactsUniversalDirectory,
      state: {
        newContact: true,
      },
    });
  }
  function handleAddJobOrder() {
    history.push({
      pathname: AppRoutes.jobsUniversalDirectory,
      state: {
        newJoborder: true,
      },
    });
  }

  function handleSalesCallClick(value: ISalesCall) {
    history.push({
      pathname: AppRoutes.salesAndMarketingSalesCallActivity,
      state: {
        completeCall: true,
        id: value.id ?? "",
        source: "dashboard"
      },
    });
  }

  /// [Service board]
  function handleServiceBoardOpenOrders() {
    history.push(AppRoutes.salesAndMarketingServiceCallActivity);
  }

  function handleServiceCallClick(value: IServiceCall) {
    history.push({
      pathname: AppRoutes.salesAndMarketingServiceCallActivity,
      state: {
        completeCall: true,
        id: value.id ?? "",
      },
    });
  }

  ///Recruiting board
  function handleRecruitingAvailableCandidates() {
    history.push(`${AppRoutes.dashboardPage}/available-candidates`);
  }
  function handleAddTalent() {
    history.push({
      pathname: AppRoutes.talentUniversalDirectory,
      state: {
        newTalent: true,
      },
    });
  }

  /// Company engagement
  function handleCompanyEngagement(type: string) {
    history.push({
      pathname: `${AppRoutes.dashboardPage}/${type}/company-engagement`,
      state: {
        type: type,
      },
    });
  }
  /// Contact engagement
  function handleContactEngagement(type: string) {
    history.push({
      pathname: `${AppRoutes.dashboardPage}/${type}/contact-engagement`,
      state: {
        type: type,
      },
    });
  }
  /// Talent engagement
  function handleTalentEngagement(type: string) {
    history.push({
      pathname: `${AppRoutes.dashboardPage}/${type}/talent-engagement`,
      state: {
        type: type,
      },
    });
  }
  function handleOpenActiveAssignmentTalent() {
    history.push({
      pathname: AppRoutes.talentUniversalDirectory,
      state: {
        activeAssignment: true,
      },
    });
  }
  function handleOpenActiveAssignmentCompany() {
    history.push({
      pathname: AppRoutes.companiesUniversalDirectory,
      state: {
        activeAssignment: true,
      },
    });
  }
  function handleOpenActiveAssignmentContact() {
    history.push({
      pathname: AppRoutes.contactsUniversalDirectory,
      state: {
        activeAssignment: true,
        includeDepartments: true,
      },
    });
  }
  function getTotalOpenOrdersCount() {
    if (totalOpenOrdersState.loading === LoadingType.pending) {
      return "-";
    }
    if (totalOpenOrdersState.loading === LoadingType.failed) {
      return "NA";
    }
    if (totalOpenOrdersState.loading === LoadingType.succeeded) {
      return totalOpenOrdersState.response ?? 0;
    }
    return "-";
  }
  const [salesDateRange, setSalesDateRange] = useState([null, null]);
  const [salesStartDate, salesEndDate] = salesDateRange;
  const [serviceDateRange, setServiceDateRange] = useState([null, null]);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [serviceStartDate, serviceEndDate] = serviceDateRange;
  function handleSalesFieldChange(value) {
    setSalesFormState({
      ...salesFormState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0]) : 0,
      available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1].getTime() + (1 * 24 * 60 * 60 * 1000))) : 0,
    });
    setSalesDateRange(value);

  }

  function handleServicesFieldChange(value) {
    setServiceFormState({
      ...serviceFormState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0]) : 0,
      available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1].getTime() + (1 * 24 * 60 * 60 * 1000))) : 0,
    });
    setServiceDateRange(value);
  }
  const [salesCallsListLength, setSalesCallsListLength] = useState(0);
  const [serviceCallsListLength, setServiceCallsListLength] = useState(0);


  useEffect(() => {
    if (salesCallsList !== undefined || salesCallsList !== null) {
      setSalesCallsListLength(salesCallsList.length)
    }
    if (serviceCallsState !== undefined || serviceCallsState !== null) {
      setServiceCallsListLength(serviceCallsState.arrival_calls.length +
        serviceCallsState.end_of_assignment_call.length +
        serviceCallsState.end_of_first_day_calls.length +
        serviceCallsState.end_of_second_day_calls.length +
        serviceCallsState.four_hour_guarantee.length +
        serviceCallsState.friday_calls.length +
        serviceCallsState.job_confirmation_calls.length +
        serviceCallsState.thirty_day_calls.length +
        serviceCallsState.sixty_day_calls.length +
        serviceCallsState.ninety_day_calls.length)
    }
  }, [salesCallsList, serviceCallsState])

  useEffect(() => {
    if ((salesFormState.available_from !== 0 && salesFormState.available_to !== 0)) {
      setDatePickerOpen(false)
    }
  }, [salesFormState.available_from, salesFormState.available_to])

  const openDatePicker = () => {
    setDatePickerOpen(true);
  };

  return (
    <div className="dsh-metric-container">
      <div className="dsh-metric-boards">
        <div className="dsh-board dsh-sales-board">
          <Board
            header={
              <BoardHeader
                title={t("sales_board").toUpperCase()}
                titleIcon={
                  <SalesBoardIcon
                    width={"100%"}
                    height={"100%"}
                    style={{ color: "#0fa2b4" }}
                  />
                }
                badgeName={t("value_open_orders", {
                  value: getTotalOpenOrdersCount(),
                  text: t("orders"),
                })}
                onBadgeClick={handleSalesBoardOpenOrders}
                badgeColor={"#1198AB"}
                backgroundColor={"#E8F5F7"}
                borderColor={"#1198AB"}
              />
            }
          >
            <div className="dsb-sb-bg-color">
              <div className="dsh-board-cmfs">
                <div>
                  <CustomMultiFormSelect
                    label={t("user")}
                    name={"user"}
                    list={usersListData !== undefined ? usersListData.map((doc) => ({
                      label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                      value: doc.id,
                    })) : []}
                    onChange={setSalesUsers}
                    required={false}
                    placeholder={t("select")}
                    value={salesUsers}
                    customStyle={{ width: "7vw" }}
                    customClassName="sales-user-role-select-class"
                    customClassPrefixName="sales-user-role-select-class"
                  />
                </div>
                <div>
                  <div className="dateRange salesDate">
                    <label>{t("date_range")}</label>
                    <div className="date-input-icon">
                      <div className="calendar-icon-div">
                        <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={openDatePicker} />
                      </div>
                      <DatePicker
                        selectsRange={true}
                        startDate={salesStartDate}
                        endDate={salesEndDate}
                        onChange={handleSalesFieldChange}
                        isClearable={true}
                        placeholderText={'Start Date -  End Date'}
                        dateFormat="MM/dd/yy"
                        popperProps={{
                          positionFixed: true,
                          strategy: "fixed"// use this to make the popper position: fixed
                        }}
                        onClickOutside={() => setDatePickerOpen(false)}
                        open={isDatePickerOpen}
                        onFocus={() => setDatePickerOpen(true)}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        dropdownMode="select"
                      />
                    </div>
                  </div>
                </div>
               
                <div className="actions-list-count">
                  <div className="actions-list">
                    <div className="action-btn">
                      <div onMouseOut={() => handleMouseOut('leads')}
                        onMouseOver={() => handleMouseOver('leads')}>

                        <div className="action-icon" >
                          <HandleLeadIcon
                            handleGoToLeads={handleGoToLeads}
                            totalLeads={inBoundLeadsListState?.getCount?.response ?? 0}
                          />
                        </div>
                        {isHovering.leads && (
                          <div className="parent-position">
                            <div className="child-position">
                              <div className="action-list-title" onClick={handleGoToLeads}>{t("inbound_leads")}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="action-btn">
                      <div onMouseOut={() => handleMouseOut('planASalesCall')}
                        onMouseOver={() => handleMouseOver('planASalesCall')}>

                        <div className="action-icon" >
                          <CallIcon
                            width={"100%"}
                            height={"100%"}
                            style={{ color: "#1198AB" }}
                            onClick={handlePlanMySalesCalls}
                          />
                        </div>
                        {isHovering.planASalesCall && (
                          <div className="parent-position">
                            <div className="child-position">
                              <div className="action-list-title" onClick={handlePlanMySalesCalls}>{t("plan_a_sales_call")}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="action-btn">
                      <div
                        onMouseOut={() => { handleMouseOut('addCompanyContact') }}
                        onMouseOver={() => { handleMouseOver('addCompanyContact') }}>
                        <div className="action-icon">
                          <PlusIcon
                            width={"100%"}
                            height={"100%"}
                            style={{ color: "#1198AB" }}
                          />
                        </div>
                        {isHovering.addCompanyContact && (
                          <div className="parent-position">
                            <div className="child-position">
                              <div className="action-list-title">
                                <div style={{ cursor: "pointer" }} onClick={handleAddContact}>{t("add_contact")}</div>
                                <div style={{ cursor: "pointer" }} onClick={handleAddCompany}>{t("add_company")}</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                    </div>
                  </div>
                  <div className="tl-count">
                  <span>{`${t('total_count')}: `} <span>{salesCallsListLength}</span> </span>
                </div>
                </div>

              </div>
            </div>
            <TitleSectionHeader title={t("scheduled_Sales_Calls").toUpperCase()}>
              <></>
            </TitleSectionHeader>
            <SalesCallsList
              list={salesCallsList}
              loading={salesCallsState.loading === LoadingType.pending}
              error={salesCallsState.error}
              onClick={handleSalesCallClick}
              onRefresh={getSalesCalls}
              salesUsers={salesUsers ?? []}
              salesFormState={salesFormState}
              salesCallLength={setSalesCallsListLength}
            />
          </Board>
        </div>
        <div className="dsh-board dsh-service-board">
          <Board
            header={
              <BoardHeader
                title={t("service").toUpperCase()}
                titleIcon={
                  <ServiceBoardIcon
                    width={"100%"}
                    height={"100%"}
                    style={{ color: "#79649E" }}
                  />
                }
                badgeName={t("value_open_service_calls", {
                  value: serviceCallsOpenListLength,
                })}
                onBadgeClick={handleServiceBoardOpenOrders}
                badgeColor={"#79649E"}
                backgroundColor={"#EBE8F1"}
                borderColor={"#8777A6"}
              />
            }
            customContentStyle={{
              overflow: "auto",
            }}
          >
            <div className="dsb-srv-bg-color">
              <div className="dsh-board-cmfs">
                <div>
                  <CustomMultiFormSelect
                    label={t("user")}
                    name={"user"}
                    list={usersListData !== undefined ? usersListData.map((doc) => ({
                      label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                      value: doc.id,
                    })) : []}
                    onChange={setServiceUsers}
                    required={false}
                    placeholder={t("select")}
                    value={serviceUsers}
                    customStyle={{ width: "4vw" }}
                    customClassName="service-user-role-select-class"
                    customClassPrefixName="service-user-role-select-class"
                  />
                </div>
               
                {/* <div style={{ visibility: 'hidden' }}>
                  <div className="dateRange serviceDate">

                  </div>
                </div> */}
                <div className="actions-list-count">
                  <div className="actions-list">
                    <div className="action-btn">
                      <div onMouseOut={() => { handleMouseOut('sendOnboardingLink') }}
                        onMouseOver={() => { handleMouseOver('sendOnboardingLink') }}>
                        <div className="action-icon">
                          <PaperPlaneIcon
                            width={"100%"}
                            height={"100%"}
                            style={{ color: "#79649E" }}
                            onClick={() => setShowSendOnBoardingLink(true)}
                          />
                        </div>
                        {isHovering.sendOnboardingLink && (
                          <div className="parent-position">
                            <div className="child-position">
                              <div className="action-list-title" onClick={() => setShowSendOnBoardingLink(true)}>{t("send_onboarding_link")}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="action-btn">
                      <div onMouseOut={() => { handleMouseOut('addJobOrder') }}
                        onMouseOver={() => { handleMouseOver('addJobOrder') }}>
                        <div className="action-icon">
                          <PlusIcon
                            width={"100%"}
                            height={"100%"}
                            style={{ color: "#79649E" }}
                            onClick={handleAddJobOrder}
                          />
                        </div>
                        {isHovering.addJobOrder && (
                          <div className="parent-position">
                            <div className="child-position">
                              <div className="action-list-title" onClick={handleAddJobOrder}>{t("add_job_order")}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="tl-count">
                  <div>
                    <span>{`${t('total_count')}: `}
                      <span>{serviceCallsListLength}</span> </span>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <ServiceCallsList
              state={serviceCallsState}
              onClick={handleServiceCallClick}
              onRefresh={getServiceCalls}
              serviceUsers={serviceUsers ?? []}
              serviceFormState={serviceFormState}
              serviceCallLength={setServiceCallsListLength}

            />
          </Board>
        </div>
        <div className="dsh-board dsh-recruiting-board">
          <Board
            header={
              <BoardHeader
                title={t("recruiting").toUpperCase()}
                titleIcon={
                  <RecruitingBoardIcon
                    width={"100%"}
                    height={"100%"}
                    style={{ color: "#008FD1" }}
                  />
                }
                badgeName={t("value_available_candidates", {
                  value: availableCandidatesList,
                })}
                onBadgeClick={handleRecruitingAvailableCandidates}
                badgeColor={"#008FD1"}
                backgroundColor={"#D9EEF8"}
                borderColor={"#008FD1"}
              />
            }
          >
            <div className="dsb-rtg-bg-color">
              <div className="dsh-board-cmfs">

                <div className="actions-list-count">
                  <div className="actions-list">
                    <div className="action-btn">
                      <div onMouseOut={() => { handleMouseOut('markTalentAvailable') }}
                        onMouseOver={() => { handleMouseOver('markTalentAvailable') }}>
                        <div className="action-icon">
                          <MarkTalentIcon
                            width={"100%"}
                            height={"100%"}
                            style={{ color: "#008FD1" }}
                            onClick={() => setShowMarkTalentAvailable(true)}
                          />
                        </div>
                        {isHovering.markTalentAvailable && (
                          <div className="parent-position">
                            <div className="child-position">
                              <div className="action-list-title" onClick={() => setShowMarkTalentAvailable(true)}>{t("mark_talent_available")}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="action-btn">
                      <div
                        onMouseOut={() => { handleMouseOut('sendOnlineApplication') }}
                        onMouseOver={() => { handleMouseOver('sendOnlineApplication') }}>
                        <div className="action-icon">
                          <PaperPlaneIcon
                            width={"100%"}
                            height={"100%"}
                            style={{ color: "#008FD1" }}
                            onClick={() => setShowSendOnlineApplication(true)}
                          />
                        </div>
                        {isHovering.sendOnlineApplication && (
                          <div className="parent-position">
                            <div className="child-position">
                              <div className="action-list-title" onClick={() => setShowSendOnlineApplication(true)}>{t("send_online_application")}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="action-btn">
                      <div onMouseOut={() => { handleMouseOut('addTalent') }}
                        onMouseOver={() => { handleMouseOver('addTalent') }}>
                        <div className="action-icon">
                          <PlusIcon
                            width={"100%"}
                            height={"100%"}
                            style={{ color: "#008FD1" }}
                            onClick={handleAddTalent}
                          />
                        </div>
                        {isHovering.addTalent && (
                          <div className="parent-position">
                            <div className="child-position">
                              <div className="action-list-title" onClick={handleAddTalent}>{t("add_talent")}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <InterviewsList
              list={[]}
              loading={false}
              error={null}
              onClick={(value) => console.log(value)}
              onRefresh={() => { }}
            />
          </Board>
        </div>
      </div>
      <div className="dsh-metric-traffic-lights">
        <div className="dsh-eng-board dsh-cmpny-eng">
          <EnagementCard
            titleInfo={{
              icon: (
                <CompaniesIcon
                  width={"100%"}
                  height={"100%"}
                  style={{ color: THEME.defaultHighLightColor }}
                />
              ),
              title: t("company_engagement").toUpperCase(),
              notifications: companyTrafficLightEngagement.data
                ? companyTrafficLightEngagement.data["average"]
                : 0,
              notificationsLevel: companyTrafficLightEngagement.data
                ? companyTrafficLightEngagement.data["average_level"]
                : "LOW",
              activeAssignments: companyTrafficLightEngagement.data
                ? companyTrafficLightEngagement.data["ACTIVE_ASSIGNMENTS"]
                : 0,
              assignmentTitle: t(
                "client_companies_with_active_assignments"
              ),
            }}
            lowValue={{
              title: t("low"),
              interactions: getInteractionsText(
                companyTrafficEngagement["LOW"]
              ),
              value: companyTrafficLightEngagement.data
                ? companyTrafficLightEngagement?.data["LOW"]
                : 0,
            }}
            mediumValue={{
              title: t("medium"),
              interactions: getInteractionsText(
                companyTrafficEngagement["MEDIUM"]
              ),
              value: companyTrafficLightEngagement.data
                ? companyTrafficLightEngagement?.data["MEDIUM"]
                : 0,
            }}
            highValue={{
              title: t("high"),
              interactions: getInteractionsText(
                companyTrafficEngagement["HIGH"]
              ),
              value: companyTrafficLightEngagement.data
                ? companyTrafficLightEngagement?.data["HIGH"]
                : 0,
            }}
            onLow={() => handleCompanyEngagement("LOW")}
            onMedium={() => handleCompanyEngagement("MEDIUM")}
            onHigh={() => handleCompanyEngagement("HIGH")}
            onActiveAssignment={handleOpenActiveAssignmentCompany}
          />
        </div>
        <div className="dsh-eng-board dsh-contact-eng">
          <EnagementCard
            titleInfo={{
              icon: (
                <ContactsIcon
                  width={"100%"}
                  height={"100%"}
                  style={{ color: THEME.defaultHighLightColor }}
                />
              ),
              title: t("contact_engagement").toUpperCase(),
              notifications: contactTrafficLightEngagement.data
                ? contactTrafficLightEngagement.data["average"]
                : 0,
              notificationsLevel: contactTrafficLightEngagement.data
                ? contactTrafficLightEngagement.data["average_level"]
                : "LOW",
              activeAssignments: contactTrafficLightEngagement.data
                ? contactTrafficLightEngagement.data["ACTIVE_ASSIGNMENTS"]
                : 0,
              assignmentTitle: t(
                "contacts_with_active_assignments"
              ),
            }}
            lowValue={{
              title: t("low"),
              interactions: getInteractionsText(
                contactTrafficEngagement["LOW"]
              ),
              value: contactTrafficLightEngagement.data
                ? contactTrafficLightEngagement?.data["LOW"]
                : 0,
            }}
            mediumValue={{
              title: t("medium"),
              interactions: getInteractionsText(
                contactTrafficEngagement["MEDIUM"]
              ),
              value: contactTrafficLightEngagement.data
                ? contactTrafficLightEngagement?.data["MEDIUM"]
                : 0,
            }}
            highValue={{
              title: t("high"),
              interactions: getInteractionsText(
                contactTrafficEngagement["HIGH"]
              ),
              value: contactTrafficLightEngagement.data
                ? contactTrafficLightEngagement?.data["HIGH"]
                : 0,
            }}
            onLow={() => handleContactEngagement("LOW")}
            onMedium={() => handleContactEngagement("MEDIUM")}
            onHigh={() => handleContactEngagement("HIGH")}
            onActiveAssignment={handleOpenActiveAssignmentContact}
          />
        </div>
        <div className="dsh-eng-board dsh-tlnt-eng">
          <EnagementCard
            titleInfo={{
              icon: (
                <TalentIcon
                  width={"100%"}
                  height={"100%"}
                  style={{ color: THEME.defaultHighLightColor }}
                />
              ),
              title: t("talent_engagement").toUpperCase(),
              notifications: talentTrafficLightEngagement.data
                ? talentTrafficLightEngagement.data["average"]
                : 0,
              notificationsLevel: talentTrafficLightEngagement.data
                ? talentTrafficLightEngagement.data["average_level"] : "LOW",
              activeAssignments: talentTrafficLightEngagement.data
                ? talentTrafficLightEngagement.data["ACTIVE_TALENT"]
                : 0,
              assignmentTitle: t("people_currently_on_active_assignments"),
            }}
            lowValue={{
              title: t("low"),
              interactions: getInteractionsText(talentTrafficEngagement["LOW"]),
              value: talentTrafficLightEngagement.data
                ? talentTrafficLightEngagement?.data["LOW"]
                : 0,
            }}
            mediumValue={{
              title: t("medium"),
              interactions: getInteractionsText(
                talentTrafficEngagement["MEDIUM"]
              ),
              value: talentTrafficLightEngagement.data
                ? talentTrafficLightEngagement?.data["MEDIUM"]
                : 0,
            }}
            highValue={{
              title: t("high"),
              interactions: getInteractionsText(
                talentTrafficEngagement["HIGH"]
              ),
              value: talentTrafficLightEngagement.data
                ? talentTrafficLightEngagement?.data["HIGH"]
                : 0,
            }}
            onLow={() => handleTalentEngagement("LOW")}
            onMedium={() => handleTalentEngagement("MEDIUM")}
            onHigh={() => handleTalentEngagement("HIGH")}
            onActiveAssignment={handleOpenActiveAssignmentTalent}
          />
        </div>
      </div>

      {showMarkTalentAvailable && (
        <Portal>
          <MarkTalentAvailablePopup
            visible={showMarkTalentAvailable}
            title={t("hi_who_would_you_like_to_mark_available")}
            onClose={() => setShowMarkTalentAvailable(false)}
            onDissmiss={() => setShowMarkTalentAvailable(false)}
            onSuccessClose={handleOnMarkTalentSuccess}
          />
        </Portal>
      )}

      {showSendOnlineApplication && (
        <Portal>
          <SendOnlineApplicationPopup
            visible={showSendOnlineApplication}
            onClose={() => setShowSendOnlineApplication(false)}
            onDissmiss={() => setShowSendOnlineApplication(false)}
            onSuccessClose={handleOnSendOnlineApplicationSuccess}
          />
        </Portal>
      )}

      {showSendOnBoardingLink && (
        <Portal>
          <SendOnBoardingLinkPopup
            visible={showSendOnBoardingLink}
            onClose={() => setShowSendOnBoardingLink(false)}
            onDissmiss={() => setShowSendOnBoardingLink(false)}
            onSuccessClose={handleOnSendOnBoardingSuccess}
          />
        </Portal>
      )}
    </div>
  );

  function getInteractionsText(doc?: ITrafficEngagement) {
    if (doc && doc.range_from != null && doc.range_to != null) {
      return `(${t("interactions", {
        value: `${doc.range_from}-${doc.range_to}`,
      })})`;
    }
    if (doc && doc.range_from != null && doc.range_to === null) {
      return `(${doc.range_from}+)`;
    }
    return `(NA)`;
  }

  function handleOnMarkTalentSuccess() {
    setShowMarkTalentAvailable(false);
  }

  function handleOnSendOnlineApplicationSuccess() {
    setShowSendOnlineApplication(false);
    dispatch(
      sendOnlineApplicationsStateActions.clearSendOnlineApplicationState()
    );
    dispatch(getOnlineApplicationsSentList());
    dispatch(getOnlineApplicationsSentCount());
  }
  function handleOnSendOnBoardingSuccess() {
    setShowSendOnBoardingLink(false);
    dispatch(sendOnBoardingLinksStateActions.clearSendOnBoardingLinkState());

  }
};

interface handleLeadIconProps {
  handleGoToLeads: () => void;
  totalLeads: number
}

export const HandleLeadIcon: React.FunctionComponent<handleLeadIconProps> = (props) => {
  const { handleGoToLeads, totalLeads } = props
  return (
    <div className="avatar-container" onClick={handleGoToLeads}>
      <div>
        <TextIcon
          width={"100%"}
          height={"100%"}
          style={{ color: "#1198AB" }}
          onClick={handleGoToLeads}
        />
      </div>
      {totalLeads !== 0 &&
       <button className="total-leads"
     >
       <span>{totalLeads}</span>
     </button>}
    </div>
  );
};


export default MetricsPage;

import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./employee_list_reducer";

const select = (state: IRootState) => state.selectors.employeesDropdown;
export const selectEmployeesDropdownListState = createSelector(select, selectState);
export const selectEmployeesDropdownList = createSelector(
    select,
    selectAll
);

export const selectEmployeesDropdownListEntities = createSelector(
    select,
    selectEntities
);
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import { TableFilterEmpty } from "../../../../components/table_empty/table_empty";
import { LoadingType, THEME } from "../../../../enums";
import {
  CallBlueIcon,
  CircleDownloadIcon,
  EmailIcon,
  FacebookIcon,
  LinkedInIcon,
  TwitterIcon,
  UserProfileIcon,
  WebsiteIcon,
} from "../../../../icons";
import { AppError } from "../../../../interfaces";
import {
  IApolloContactsResults,
  IApolloImportResponse,
  IContactApolloImport,
  IContactsApolloSearchResults,
} from "../../../../interfaces/apollo_search_import";
import { CustomButton, CustomCheckBox } from "../../../../utils";
import ImportPeoplePopup from "../../popups/import_popup/import_people_popup";
import ApolloPagination from "../apollo_table_pagination/apollo_table_pagination";
import "./contacts_talent_result_table.scss";

interface Props {
  selectedContact?: IContactApolloImport | null;
  contactsResult: IApolloContactsResults | null;
  onChange: (value: IContactApolloImport | null) => void;
  title: string;
  importContact: (
    doc: IContactApolloImport[],
    directDial: boolean,
    forceImport: boolean
  ) => void;
  importLoading: LoadingType;
  successTitle: string;
  currentLoginUserName?: string;
  onClose: () => void;
  onGotoCreated: (id: string, name: string | undefined) => void;
  importResponse: IApolloImportResponse | undefined;
  source: string;
  error: AppError | null | undefined;
  onClearError: () => void;
  onClearImportState: () => void;
  handlePageChange: (pageNum: number) => void;
  currentPage: number;
  availCredits?: number;
  availMobileCredits?: number;
  onAddCredit: Dispatch<SetStateAction<boolean>>;
  handleSelection: (itemMap: Map<any, IContactsApolloSearchResults>) => void;
  selectedItems: Map<any, IContactsApolloSearchResults>;
}

const ApolloContactsResults: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const {
    contactsResult,
    importContact,
    importLoading,
    successTitle,
    currentLoginUserName,
    error,
    onClearError,
    availCredits,
    availMobileCredits,
    onAddCredit,
    onGotoCreated,
    importResponse,
    source,
    onClearImportState,
    currentPage,
    handlePageChange,
    selectedItems,
    handleSelection
  } = props;
  const [sortedField, setSortedField] = useState<string | null>(null);

  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState("");
  const [importPopup, setImportPopup] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const contactsResultList = contactsResult?.results;

  const SelectAllCheckBox = () => {
    return (
      <CustomCheckBox
        name={""}
        title={""}
        checked={selectedItems.size === contactsResultList?.length}
        onClick={handleMarkAllItem}
      />
    );
  };

  const tableHeader = [
    { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
    { title: "", code: "" },
    { title: "name", code: "first_name" },
    { title: "title", code: "title" },
    { title: "company_name", code: "organization" },
    { title: "departments", code: "departments" },
    { title: "address", code: "" },
    { title: "email", code: "email" },
    { title: "phone", code: "phone" },
  ];
  const getEmailInfo = (email: string | null | undefined) => {
    if (email !== undefined && email !== null && email !== "") {
      return (
        <div
          onMouseOut={() => {
            setIsHovering(false);
          }}
          onMouseOver={() => {
            setIsHovering(true);
          }}
          className="icon-hover-div"
        >
          <div>
            <a
              href={`mailto:${email}`}
              className="mail-icon"
              target="_blank"
              rel="noreferrer"
            >
              <EmailIcon width={"100%"} height={"100%"} />
            </a>
          </div>
          {isHovering && (
            <div>
              <div className="parent-position">
                <div className="child-position">
                  <div className="mail-details">{email}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
    return <span>{""}</span>;
  };
  const getPhoneInfo = (phone_numbers: any | undefined) => {
    if (
      phone_numbers !== undefined &&
      phone_numbers !== null &&
      phone_numbers.length !== 0
    ) {
      return (
        <div
          onMouseOut={() => {
            setIsHovering(false);
          }}
          onMouseOver={() => {
            setIsHovering(true);
          }}
          className="icon-hover-div"
        >
          <div>
            <a
              href={`tel:${phone_numbers[0]}`}
              className="phone-icon"
              target="_blank"
              rel="noreferrer"
            >
              <CallBlueIcon width={"100%"} height={"100%"} />
            </a>
          </div>
          {isHovering && (
            <div>
              <div className="parent-position">
                <div className="child-position">
                  <div className="phone-details">
                    {phone_numbers.map((phone) => {
                      return (
                        <>
                          <div className="phone">{phone.raw_number}</div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
    return <span>{""}</span>;
  };

  const getSocialMediaInfo = (
    website: string | null | undefined,
    linkedin: string | null | undefined,
    facebook: string | null | undefined,
    twitter: string | null | undefined
  ) => {
    const socialMediaIcons: JSX.Element[] = [];
    if (website !== null && website !== undefined && website !== "") {
      socialMediaIcons.push(
        <a
          href={website}
          className="website-icon"
          target="_blank"
          rel="noreferrer"
        >
          <WebsiteIcon width={"100%"} height={"100%"} />
        </a>
      );
    }
    if (linkedin !== null && linkedin !== undefined && linkedin !== "") {
      socialMediaIcons.push(
        <a
          href={linkedin}
          className="website-icon"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInIcon width={"100%"} height={"100%"} />
        </a>
      );
    }
    if (facebook !== null && facebook !== undefined && facebook !== "") {
      socialMediaIcons.push(
        <a
          href={facebook}
          className="website-icon"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon width={"100%"} height={"100%"} />
        </a>
      );
    }
    if (twitter !== null && twitter !== undefined && twitter !== "") {
      socialMediaIcons.push(
        <a
          href={twitter}
          className="website-icon"
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon width={"100%"} height={"100%"} />
        </a>
      );
    }
    if (socialMediaIcons.length > 0) {
      return <div style={{ display: "flex" }}>{socialMediaIcons}</div>;
    }
    return <span>{""}</span>;
  };
  function getFilteredList(): IContactsApolloSearchResults[] | undefined {
    let sortedList: IContactsApolloSearchResults[] | undefined;
    if (sortedField != null && contactsResultList !== undefined) {
      sortedList = [...contactsResultList].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] !== undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }
    return (sortedList ?? contactsResultList)?.filter((doc) => {
      const str = search.trim().toLowerCase();
      const nameFilter = `${doc.first_name ?? ""} ${doc.last_name}`
        .trim()
        .toLowerCase()
        .includes(str);
      return nameFilter;
    });
  }
  function handleSortFieldChange(value: string) {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  }

  const handleMarkContact = (value: IContactsApolloSearchResults) => {
      const newMap = new Map(selectedItems);
      if (selectedItems.has(value.id)) {
        newMap.delete(value.id);
      } else {
        newMap.set(value.id, value);
      }
      handleSelection(newMap);
  };
  const handleMarkAllItem = () => {
    const newMap = new Map();
    if (selectedItems.size === contactsResultList?.length) {
      handleSelection(newMap);
    } else {
      contactsResultList?.forEach((contact) => {
        newMap.set(contact.id, contact);
      });
      handleSelection(newMap);
    }
  };

  const onSubmit = (isDirectDial: boolean, forceImport: boolean) => {
    importContact(
      Array.from(selectedItems.values()),
      isDirectDial,
      forceImport
    );
  };

  function formatNumber(n: number | undefined) {
    return n
      ?.toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="as-cnt-imp-f-container">
      <div className="as-cnt-imp-f-content">
        <div className="as-cnt-btn-row">
          <span className="total-count">
            {t("total_results")} :
            <b>{formatNumber(contactsResult?.total_results)}</b>
          </span>
          <div className="creds-row">
            <div className="creds-div-row">
              {availCredits && availMobileCredits && (
                <>
                  <div className="creds-div">
                    <div className="credits-row">
                      <span>{t("available_email_export_credits")} : </span>
                      <span
                        className="cred-num"
                        style={{
                          color: availCredits! >= 2 ? "#00c655" : "#FF7600",
                          marginLeft: "0.1em",
                        }}
                      >
                        {availCredits! <= 2 ? 2 : availCredits!}
                      </span>
                    </div>
                  </div>

                  <div className="creds-div">
                    <div className="credits-row">
                      <span>{t("available_mobile_credits")} : </span>
                      <span
                        className="cred-num"
                        style={{
                          color:
                            availMobileCredits! >= 2 ? "#00c655" : "#FF7600",
                          marginLeft: "0.1em",
                        }}
                      >
                        {availMobileCredits! <= 2 ? 2 : availMobileCredits!}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div>
              {(availCredits! <= 2 || availMobileCredits! <= 2) && (
                <div className="no-creds-div">
                  <div className="no-creds">{t("insufficient_credits")}</div>
                  <div>
                    <div
                      onClick={() => onAddCredit(true)}
                      className="add-creds-div"
                    >
                      {t("add_credits")}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="selectd-count-div">
              {selectedItems.size > 0 && (
                <span>
                  {t("selected_items", { name: source })} :&nbsp;
                  <b>{selectedItems.size}</b>
                </span>
              )}
            </div>
            <div>
              <CustomButton
                leftIcon={
                  <div className="btn-icon circle-download-svg">
                    <CircleDownloadIcon
                      width={"100%"}
                      height={"100%"}
                      style={{ fill: "#fff" }}
                    />
                  </div>
                }
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("import")}
                enable={
                  availCredits === undefined
                    ? selectedItems.size > 0
                      ? true
                      : false
                    : selectedItems.size > 0 && availCredits! > 2
                    ? true
                    : false
                }
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => setImportPopup(true)}
              />
            </div>
          </div>
        </div>
        <div className="as-srch-table-holder">
          {getFilteredList()?.length !== 0 ? (
            <SortableTable
              headerList={tableHeader}
              sortedField={sortedField}
              onSortChange={handleSortFieldChange}
              flexNumber={getFlexNumber}
              isAsc={sortDirection}
            >
              {getFilteredList()?.map((doc, index) => {
                return (
                  <tr key={doc.id} onClick={() => handleRowClick(doc)}>
                    <TableData customStyle={{ flex: getFlexNumber(0) }}>
                      <CustomCheckBox
                        name={""}
                        title={""}
                        checked={selectedItems.has(doc.id)}
                        onClick={() => handleMarkContact(doc)}
                      />
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(1) }}>
                      <div className="contact-image">
                        {doc.photo_url !== null ? (
                          <img
                            src={doc.photo_url}
                            width={"100%"}
                            height={"100%"}
                            alt="No Profile"
                          />
                        ) : (
                          <UserProfileIcon width={"100%"} height={"100%"} />
                        )}
                      </div>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(2) }}>
                      <div className="company-row">
                        <div>
                          {`${doc.first_name ?? ""} ${doc.last_name ?? ""}`}
                        </div>
                        <div className="icons-row">
                          {getSocialMediaInfo(
                            "",
                            doc.linkedin_url,
                            doc.facebook_url,
                            doc.twitter_url
                          )}
                        </div>
                      </div>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                      {doc.title}
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(4) }}>
                      <div className="company-div-row">
                        <div className="comp-logo">
                          {doc?.organization?.logo_url !== null ? (
                            <img
                              src={doc?.organization?.logo_url}
                              width={"100%"}
                              height={"100%"}
                              alt="No Profile"
                            />
                          ) : (
                            <UserProfileIcon width={"100%"} height={"100%"} />
                          )}
                        </div>
                        <div className="company-row">
                          <div>{doc.organization?.name}</div>
                          <div className="icons-row">
                            {getSocialMediaInfo(
                              doc?.organization?.website_url,
                              doc?.organization?.linkedin_url,
                              doc?.organization?.facebook_url,
                              doc.organization?.twitter_url
                            )}
                          </div>
                        </div>
                      </div>
                    </TableData>
                    <td
                      style={{
                        flex: getFlexNumber(5),
                        flexDirection: "column",
                      }}
                    >
                      {doc.departments &&
                        doc.departments.length !== 0 &&
                        doc.departments.map((item, index, arr) => (
                          <>
                            {item.toString().replaceAll("_", " ")}
                            {index !== arr.length - 1 ? ", " : ""}
                          </>
                        ))}
                    </td>
                    <TableData customStyle={{ flex: getFlexNumber(6) }}>{`${
                      doc.city ? doc.city + "," : ""
                    } ${doc.state ? doc.state + "," : ""} ${
                      doc.country ?? ""
                    }`}</TableData>
                    <TableData customStyle={{ flex: getFlexNumber(7) }}>
                      {getEmailInfo(doc.email)}
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(8) }}>
                      {doc.phone_numbers !== null
                        ? getPhoneInfo(doc.phone_numbers)
                        : ""}
                    </TableData>
                  </tr>
                );
              })}
            </SortableTable>
          ) : (
            <div className="srch-talent-holder">
              <TableFilterEmpty title={t("no_talent_found")} />
            </div>
          )}
        </div>

        <div className="as-table-footer">
          <ApolloPagination
            totalPages={contactsResult?.page_count}
            onPageChange={handlePageChange}
            curPageNum={currentPage}
            totalCount={contactsResult?.total_results}
          />
        </div>
      </div>
      {importPopup && (
        <Portal>
          <ImportPeoplePopup
            visible={true}
            title={""}
            successTitle={successTitle}
            currentLoginUserName={currentLoginUserName}
            selectedNames={[]}
            onClose={() => {
              onClearImportState();
              setImportPopup(false);
              handleSelection(new Map());
              props.onChange(null);
            }}
            onGotoCreated={onGotoCreated}
            importResponse={importResponse}
            importLoading={importLoading}
            source={source}
            handleOnImport={onSubmit}
            onDissmiss={() => setImportPopup(false)}
            error={error}
            onClearError={onClearError}
            selectedCount={selectedItems.size}
          />
        </Portal>
      )}
    </div>
  );

  function handleRowClick(doc: IContactsApolloSearchResults) {
    if (props.selectedContact && props.selectedContact.id === doc.id) {
      props.onChange(null);
    } else {
      props.onChange({
        id: doc.id,
        name: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
      });
    }
  }
  function getFlexNumber(value: number) {
    if (value === 0) return 0.2;
    if (value === 1) return 0.3;
    if (value === 2) return 1;
    if (value === 3) return 1;
    if (value === 4) return 1.5;
    if (value === 5) return 1.5;
    if (value === 6) return 1;
    if (value === 7) return 0.5;
    if (value === 8) return 0.5;
    if (value === 9) return 1;

    return 1;
  }
};

export default ApolloContactsResults;

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../components/api_error";
import DialogWrapper, { FormDialogTilteHeader } from "../../../components/dialog_wrapper/dialog_wrapper";
import DropZone from "../../../components/file_upload/file_upload_page";
import { LoadingType, THEME } from "../../../enums";
import { UploadIcon } from "../../../icons";
import { AppError } from "../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { CustomButton, CustomCheckBox, SpinnerScreen } from "../../../utils";
import { downloadSampleFileUploadUrl, getImpDataFileUploadUrl, getTalentResumeUploadUrls, selectSampleFileDownloadUrlState, selectTalentResumeUploadState, updateTalentImportThunk, uploadTalentResumeFileThunk } from "../../../redux/upload_files";
import "./import_data_file.scss";
import { importUrlActions } from "../../../redux/upload_files/import_data_file_reducer";
import DragAndDrop from "../../../components/drag_and_drop/drag_and_drop";

interface Props {
    visible: boolean;
    title: string;
    baseUrl: string;
    importType: string;
    loading: LoadingType;
    error: AppError | null | undefined;
    onClose: () => void;
    onClearError: () => void;
    handleSnackBar: () => void;
}

const UploadRecordPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        baseUrl,
        importType,
        onClose,
        onClearError,
        handleSnackBar,
        error,
        loading
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const sampleFileDownloadState = useAppSelector((state) => selectSampleFileDownloadUrlState(state));
    const talentResumeUploadState = useAppSelector((state) => selectTalentResumeUploadState(state));

    const actionRef = useRef<ActionDialogHolderType>(null);
    const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);

    const [allowDuplicates, setAllowDuplicates] = useState<boolean>(false);
    useEffect(() => {
        if (sampleFileDownloadState?.loading === LoadingType.succeeded) {
            let aTag = document.createElement("a");
            aTag.setAttribute("href", sampleFileDownloadState.response?.url!);
            aTag.setAttribute("target", "_blank");
            aTag.click();
            dispatch(importUrlActions.clearDownloadUrlState());
        }
        return () => { }
    }, [sampleFileDownloadState?.loading]);

    useEffect(() => {
        if (talentResumeUploadState.getUploadUrls.loading === LoadingType.succeeded) {
            const response = talentResumeUploadState.getUploadUrls.response;
            if (!response) return; // Exit if response is null or undefined

            const uploadFiles: { url: string; file: File | null }[] = selectedFiles
                ? selectedFiles
                    .map((file) => {
                        const matchingFile = response.find((item) => item.file_name === file.name);
                        return matchingFile ? { url: matchingFile.url, file } : null;
                    })
                    .filter(Boolean) as { url: string; file: File | null }[] // Type assertion
                : [];

            if (uploadFiles.length === 0) return; // No files to upload, exit early

            dispatch(uploadTalentResumeFileThunk({ uploadFiles }))
                .unwrap() // Ensures we get the actual resolved value from the thunk
                .then((uploadResults) => {
                    // Check if all uploads were successful
                    const allSuccessful = uploadResults.every((res) => res.status === 200);

                    if (allSuccessful) {
                        const fileIds = response.map((data) => data.id);
                        dispatch(updateTalentImportThunk(fileIds)); // Dispatch after successful uploads
                    } else {
                        // console.error("Some files failed to upload:", uploadResults);
                    }
                })
                .catch((error) => console.error("Upload failed:", error));
        }
    }, [talentResumeUploadState.getUploadUrls.loading, talentResumeUploadState.getUploadUrls.response]);

    const handleUploadUrlSubmit = () => {
        const fileNames = selectedFiles?.map((file) => file.name);
        if (importType !== "Talent" && selectedFiles) {
            dispatch(getImpDataFileUploadUrl({
                baseUrl: baseUrl,
                file: selectedFiles && selectedFiles[0]
            }))
        }
        if (importType === "Talent" && selectedFiles) {
            dispatch(getTalentResumeUploadUrls({ files: selectedFiles, file_names: fileNames ?? [], allow_duplicates: allowDuplicates }))
        }
        handleSnackBar()
    }

    const handleDownload = () => {
        dispatch(downloadSampleFileUploadUrl(importType))
    }


    const acceptedFileFormats = {
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
        "text/csv": [".csv"],
        "application/pdf": [".pdf"],
        "application/msword": [".doc"], // For older Word files
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    };

    const onRemoveFiles = (index: number) => {
        if (selectedFiles && selectedFiles?.length > 1) {
            const newArr = [...selectedFiles]
            newArr.splice(index, 1);
            setSelectedFiles(newArr);
        } else {
            setSelectedFiles(null);
        }
    }
    function handleFilesChange(value: Array<File>) {
        if (selectedFiles && selectedFiles?.length > 0) {
            setSelectedFiles([...value]);
        } else {
            setSelectedFiles(value);
        }
        setProgress(0);
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"fit-content"}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={() => {
                onClose();
                onClearError();
            }}>
                <div className="import-data-container">
                    <div className="import-data-header">
                        <FormDialogTilteHeader
                            title={t(title)}
                            titleIcon={<UploadIcon width={"1.25vw"} height={"1.25vw"} />}
                            isDownloadSampleBtn={true}
                            downloadBtnTitle={t("download_sample")}
                            onDownload={handleDownload}
                        />
                    </div>
                    <div>
                        <div className="import-data-content">
                            <div>
                                {importType === "Talent" ?
                                    <DragAndDrop
                                        type={"large"}
                                        selectedFiles={selectedFiles}
                                        onMultiChange={handleFilesChange}
                                        onMultiRemove={onRemoveFiles}
                                        accept={".pdf,image/*,.doc,.docx,.xls,.xlsx,.csv,.html,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                                        multiple={true}
                                        placeholder={t("drag_and_drop_message")}

                                    />
                                    :
                                    <DropZone maxFiles={1} handleFiles={setSelectedFiles} maxSize={26214400} accept={acceptedFileFormats} />
                                }
                            </div>
                            {(talentResumeUploadState.getUploadUrls.loading === LoadingType.pending || talentResumeUploadState.upload.loading === LoadingType.pending) &&
                                <div className="upload-loader">
                                    <SpinnerScreen status={"Uploading Files...."} />

                                </div>
                            }
                        </div>
                        {error &&
                            <div className="error-holder">
                                <ApiError message={error.message} onClose={onClearError} />
                            </div>
                        }
                    </div>
                </div>
                <div className="import-data-actions">
                    {importType === "Talent" &&
                        <div>
                            <CustomCheckBox
                                name={"department"}
                                title={t("allow_duplicates")}
                                checked={allowDuplicates}
                                onClick={() => setAllowDuplicates(!allowDuplicates)}
                            />
                        </div>
                    }
                    <div className="btn-cancel" style={{ marginRight: "1em" }}>
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("cancel")}
                            enable={true}
                            backgroundColor={THEME.toggleDisableColor}
                            onClick={() => {
                                onClose();
                                onClearError();
                            }}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            loading={(importType === "Talent" ? talentResumeUploadState.upload.loading : loading) === LoadingType.pending}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("upload")}
                            enable={selectedFiles! && true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => handleUploadUrlSubmit()}
                        />
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder >
    );
}

export default UploadRecordPopup;
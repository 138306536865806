import { ICreateSalesCall, IOnboardingLink, IOnboardingLinkQueryParams } from "../interfaces";
import { IInBoundLeads, IInBoundLeadsSendInvite } from "../interfaces/inbound_leads";
import { ICreateInterviewCalendarInvite } from "../interfaces/interview_calendar_invite";
import { ISendOnBoardingLink } from "../interfaces/onboarding_link";
import { ISendOnlineApplication } from "../interfaces/online_application";
import { OnlineApplicationReceived } from "../interfaces/online_application_received";
import { OnlineApplicationSent } from "../interfaces/online_application_sent";
import { stringifyParams } from "../utils";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const DashboardAPI = {
  /// [Dashboard]

  /// [Salesboard]
  getSalesCalls(query: { branch_id?: string; my_upcoming_calls: boolean }) {
    if (query.branch_id) {
      return interceptor.get(
        `${UrlBuilder.salesCalls}?branch_id=${query.branch_id}&my_upcoming_calls=${query.my_upcoming_calls}`
      );
    }
    return interceptor.get(
      `${UrlBuilder.salesCalls}?&my_upcoming_calls=${query.my_upcoming_calls}`
    );
  },

  postSalesCalls(data: ICreateSalesCall) {
    return interceptor.post(`${UrlBuilder.salesCalls}`, data);
  },

  patchSalesCalls(data: ICreateSalesCall) {
    return interceptor.patch(`${UrlBuilder.salesCalls}`, data);
  },

  deleteSalesCalls(id: string) {
    return interceptor.delete(`${UrlBuilder.salesCalls}?sales_call_id=${id}`);
  },
  getTotalOpenOrdersCount() {
    return interceptor.get(UrlBuilder.dashboardTotalOpenOrdersCount);
  },

  /// [Service]
  getDashboardServiceCall(query: { call_completed: boolean }) {
    return interceptor.get(
      `${UrlBuilder.dashboardServiceCall}?&call_completed=${query.call_completed}`
    );
  },

  getDashboardServiceCallById(id: string) {
    return interceptor.get(
      `${UrlBuilder.dashboardServiceCallById}?&dashboard_service_call_id=${id}`
    );
  },

  /// [Recruiting]

  getRecruiting(id: string) {
    return interceptor.get(
      `${UrlBuilder.talentClientInterviewsGetAll}?&talent_id=${id}`
    );
  },

  postOnlineApplications(credentials: ISendOnlineApplication) {
    return interceptor.post(UrlBuilder.onlineApplicationsSent, credentials);
  },
  getOnlineApplicationsSent(getCount?: boolean) {
    return interceptor.get(
      UrlBuilder.onlineApplicationsSent +
      `?this_week=false&get_count=${getCount ?? false}`
    );
  },
  updateOnlineApplicationsSent(data: OnlineApplicationSent) {
    return interceptor.patch(UrlBuilder.onlineApplicationsSent, data);
  },
  getOnlineApplicationsReceived(getCount?: boolean) {
    return interceptor.get(
      UrlBuilder.talentOnlineApplication +
      `?this_week=false&get_count=${getCount ?? false}`
    );
  },
  getOnlineApplicationsReceivedById(id) {
    return interceptor.get(
      UrlBuilder.talentOnlineApplication +
      `?application_id=${id}&this_week=false&get_count=false`
    );
  },
  updateOnlineApplicationReceived(app: OnlineApplicationReceived) {
    return interceptor.patch(UrlBuilder.talentOnlineApplication, app);
  },
  importApplication(doc : {merge:boolean, appIds : string[]}){
    if(doc.merge) return interceptor.post(`${UrlBuilder.talentImport}?merge=${doc.merge}`,doc.appIds)
    return interceptor.post(UrlBuilder.talentImport, doc.appIds);
  },
  archiveApplications(appIds: string[]) {
    return interceptor.post(UrlBuilder.talentOnlineApplicationArchive, appIds);
  },
  /// Trafic light engagement metrics
  getTrafficLightEngagementMetrics() {
    return interceptor.get(UrlBuilder.trafficLightEngagementMetric);
  },
  getCompanyTrafficLightEngagementMetrics() {
    return interceptor.get(UrlBuilder.dashboardEngagementDetailsCustomer);
  },
  getContactTrafficLightEngagementMetrics() {
    return interceptor.get(UrlBuilder.dashboardEngagementDetailsContact);
  },
  getTalentTrafficLightEngagementMetrics() {
    return interceptor.get(UrlBuilder.dashboardEngagementDetailsCandidate);
  },
  getDashboardEngagementDetailsGetCustomers(type: string) {
    return interceptor.get(
      `${UrlBuilder.dashboardEngagementDetailsGetCustomers}?metric_type=${type}`
    );
  },
  getDashboardEngagementDetailsGetContacts(type: string) {
    return interceptor.get(
      `${UrlBuilder.dashboardEngagementDetailsGetContacts}?metric_type=${type}`
    );
  },
  getDashboardEngagementDetailsGetCandidates(type: string) {
    return interceptor.get(
      `${UrlBuilder.dashboardEngagementDetailsGetCandidate}?metric_type=${type}`
    );
  },
  getAvailableCandidates(
    from_date: number,
    to_date: number,
    get_count: boolean
  ) {
    return interceptor.get(
      `${UrlBuilder.getTalentAvailableToday}?from_date=${from_date}&to_date=${to_date}&get_count=${get_count}`
    );
  },
  getSendInterviewCalendarInvite(
    this_week: boolean,
    get_count: boolean,
    completed: boolean,
    startDate?: number,
    endDate?: number
  ) {
    if (startDate && endDate) {
      return interceptor.get(
        `${UrlBuilder.sendInterviewCalendarInvite}?this_week=false&get_count=${get_count}&completed=${completed}&start_date=${startDate}&end_date=${endDate}`
      );
    }
    return interceptor.get(
      `${UrlBuilder.sendInterviewCalendarInvite}?this_week=${this_week}&get_count=${get_count}&completed=${completed}`
    );
  },
  saveSendInterviewCalendarInvite(payload: ICreateInterviewCalendarInvite) {
    return interceptor.post(UrlBuilder.sendInterviewCalendarInvite, payload);
  },
  updateSendInterviewCalendarInvite(payload: ICreateInterviewCalendarInvite) {
    return interceptor.patch(UrlBuilder.sendInterviewCalendarInvite, payload);
  },
  archiveInterview(payload: any) {
    return interceptor.post(`${UrlBuilder.archiveInterview}`, payload);
  },
  postOnBoardingLinks(doc: { forceResend: boolean, value: ISendOnBoardingLink }) {
    if (doc.forceResend) return interceptor.post(`${UrlBuilder.onBoardingLinkSent}?force_resend=${doc.forceResend}`, doc.value);
    return interceptor.post(UrlBuilder.onBoardingLinkSent, doc.value);
    // return interceptor.post(UrlBuilder.onBoardingLinkSent, value);
  },


  getAllSendOnboardingLinkList(data: IOnboardingLinkQueryParams) {
    return interceptor.get(`${UrlBuilder.sendOnboardingLinkGetAll}?${stringifyParams(data)}`);
  },

  getSendOnboardingCount(data: IOnboardingLinkQueryParams) {
    return interceptor.get(`${UrlBuilder.sendOnboardingLinkGetAll}?${stringifyParams(data)}`);
  },

  updateSendOnboardingLink(data: IOnboardingLink) {
    return interceptor.patch(UrlBuilder.sendOnboardingLink, data);
  },

  reseSendOnboardingLink(data: IOnboardingLink) {
    return interceptor.post(UrlBuilder.sendOnboardingLink, data);
  },

  archiveOnboardingApplicationLink(data: string[]) {
    return interceptor.post(UrlBuilder.sendOnboardingLinkArchiveOnboardingApplications, data);
  },

  resendOnboardingApplicationLink(data: string[]) {
    return interceptor.post(UrlBuilder.resendOnboardingApplicationsLink, data);
  },


  /// Inbound Leads
  getInboundLeads(getCount?: boolean, archived?: boolean) {
    if(archived) return interceptor.get(`${UrlBuilder.dashboardInBoundLeads}?archived=${archived}`) 
    if(getCount) return interceptor.get(`${UrlBuilder.dashboardInBoundLeads}?get_count=${getCount}`);
    return interceptor.get(UrlBuilder.dashboardInBoundLeads);
  },
  getInboundLeadGetById(inbound_id: string) {
    return interceptor.get(
      `${UrlBuilder.dashboardInBoundLeads}?inbound_id=${inbound_id}`
    );
  },
  postInboundLeadSendInvite(data: IInBoundLeadsSendInvite) {
    return interceptor.post(UrlBuilder.dashboardInBoundLeadsSendInvite, data);
  },
  postInboundLeadImport(data: { lead_ids: Array<string>, import_type: string }) {
    return interceptor.post(UrlBuilder.dashboardInBoundLeadsImport, data);
  },
  postInboundLeadArchive(lead_ids: Array<string>) {
    return interceptor.post(UrlBuilder.dashboardInBoundLeadsArchive, lead_ids);
  },
  updateInboundLeadData(data: IInBoundLeads) {
    return interceptor.patch(UrlBuilder.dashboardInBoundLeads, data);
  },
};

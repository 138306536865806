import { useTranslation } from "react-i18next";
import { THEME } from "../../enums";
import {
  LocationIcon,
  CallIcon,
  GlobeIcon,
  ActiveAssignmentsIcon,
  BriefcaseIcon,
  CallWhiteIcon,
  CompaniesIcon,
  ReloadIcon,
} from "../../icons";
import { ICompany, IDepartment } from "../../interfaces";
import {
  addressParser,
  CustomButton,
  getArrayStrings,
  getCompanyStatusText,
  getHttpsString,
} from "../../utils";
import SocialMediaProfile from "../social_media_profile/social_media_profile";
import "./company_details_section.scss";
import { LoadingType, ProfilePictureType } from "../../enums";
import UploadProfile from "../../components/image_crop/image_crop_popup";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
  getPreSignedURLForDownload,
  selectSingleProfilePictureStateById,
} from "../../redux/store";
import { Portal } from "react-portal";
import UserAvatar from "../user_avatar/user_avatar";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../routes";

interface Props {
  company: ICompany | IDepartment | null;
  branchId: string | null;
  isDepartment: boolean;
  onActionClick: (value: string) => void;
}

const CompanyDetailsSection: React.FunctionComponent<Props> = (props) => {
  const { company, isDepartment, onActionClick } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const companyProfileSections = [
    {
      sectionTitle: "department",
      sectionIcon: CompaniesIcon,
      sectionContent: [
        { title: "department", valueField: "name" },
        {title: "category", valueField: "categories_names"}
      ],
    },
    {
      sectionTitle: "address",
      sectionIcon: LocationIcon,
      sectionContent: [
        {
          title: "",
          valueField: addressParser({
            street_address: company ? company["street_address"] : null,
            street_address2: company ? company["street_address2"] : null,
            city: company ? company["city"] : null,
            state: company ? company["state"] : null,
            zip_code: company ? company["zip_code"] : null,
          }),
        },
      ],
    },

    {
      sectionTitle: "contact",
      sectionIcon: CallIcon,
      sectionContent: [{ title: "", valueField: "phone" }],
    },

    {
      sectionTitle: "website",
      sectionIcon: GlobeIcon,
      sectionContent: [{ title: "website", valueField: "website" }],
    },
  ];

  function handleStatusClick() {
    history.push({
      pathname: `${AppRoutes.companiesUniversalDirectory}/${company?.id}`,
      state: {
        id: company?.id,
        status: company?.status_id,
      },
    });
    return;
  }

  return (
    <div className="cds-container">
      <CompanyInfo
        name={company?.name}
        uri={company?.logo}
        objectId={company?.id}
        rating={company?.engagement_rating}
        level={company?.engagement_level}
      />
      <div className="c-ds-branch-selector">
        <div className="cds-branch-left">
          <div className="cds-status">
            <span>
              {`${t("STATUS")}`}
            </span>
            <span
                className="status-txt"
                onClick={
                  company?.status !== "" && company?.status !== null
                    ? handleStatusClick
                    : () => { }
            }>
              {company?.status ?? ""}
            </span>
          </div>
        </div>
        <div className="cds-branch-right">
          <div className="cds-status">
            <span>
              {`${t("company_id")}`}
              </span>
              <span
                className="cds-id-txt">
                {!isDepartment && company?.id_number ? company.id_number : ""}
                {isDepartment && company?.company_id_number ? company.company_id_number : ""}
              </span>
          </div>
        </div>
      </div>
      {companyProfileSections.map((doc, index) => {
        return (
          <div
            key={index}
            className={
              doc.sectionTitle === "contact"
                ? "cds-info-address-container"
                : "cds-info-container"
            }>
            <div className="cds-info-icon">
              <doc.sectionIcon width={"100%"} height={"100%"} />
            </div>
            <div className="cds-info-content">
              {doc.sectionContent.map((data, index) => {
                const arrayValue = getValues(data.valueField);
                return (
                  <div key={index} className="cds-info-holder">
                    <div className="cds-into-title">
                      {doc.sectionTitle === "address" ||
                      doc.sectionTitle === "contact" ? (
                        <span style={{ display: "grid" }}>
                          {t(data.title).toUpperCase()}
                        </span>
                      ) : (
                        <span>{t(data.title).toUpperCase()}</span>
                      )}
                    </div>
                    <div
                      className="cds-info-value"
                      style={{ marginBottom: data.lineTwo ? "0px" : "0.5em" }}>
                      {arrayValue}
                    </div>
                    {data.lineTwo && (
                      <div className="cds-info-value">
                        {getValues(data.lineTwo)}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <SocialMediaProfile
        title={t("social_media").toUpperCase()}
        profile={company?.social_media_links!}
        showXurl={true}
      />
      {props.isDepartment === false && (
        <CompanyActions onClick={onActionClick} />
      )}
    </div>
  );

  function getValues(obj: any) {
    if (company == null) return <span>{""}</span>;

    if (Array.isArray(obj)) {
      return getArrayStrings(obj, company);
    }
    if (obj === "categories_names") {
      return company.categories_names?.length
        ? company.categories_names.map((name, index) => (
            <div key={index}>
              {name}{index !== company.categories_names!.length - 1 && ","}
            </div>
          ))
        : <span className="na-span">NA</span>;
    }

    if (obj === "website") {
      return company.website !== "" && company.website !== null ? (
        <span style={{ textTransform: "none" }}>
          <a
            href={getHttpsString(company?.website)}
            target="_blank"
            rel="noreferrer">
            {company?.website}
          </a>
        </span>
      ) : (
        <span className="na-span">NA</span>
      );
    }

    if (obj === "name" && isDepartment === false) {
      return <span>{t("corporate")}</span>;
    }
    if (obj === "phone") {
      return company.phone !== null && company.phone !== "" ? (
        <span>{company.phone}</span>
      ) : (
        <span className="na-span">NA</span>
      );
    }
    if (typeof obj === "object") {
      return obj;
    }
    const value = obj !== "" && company![obj] !== null ? company![obj] : "";
    return <span>{value}</span>;
  }
};

const CompanyInfo = (props) => {
  const { name, rating, level } = props;
  const dispatch = useAppDispatch();
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [sentRequest, setSentRequest] = useState(false);
  const [imageURL, setImageURL] = useState("");

  const profilePictureState = useAppSelector((state) =>
    selectSingleProfilePictureStateById(
      ProfilePictureType.companies + "-" + props.objectId
    )(state)
  );

  useEffect(() => {
    setSentRequest(true);
    dispatch(
      getPreSignedURLForDownload({
        object_id: props.objectId,
        object_type: ProfilePictureType.companies,
      })
    );
  }, []);

  function updateProfile() {
    dispatch(
      getPreSignedURLForDownload({
        object_id: props.objectId,
        object_type: ProfilePictureType.companies,
      })
    );
    setSentRequest(true);
  }

  useEffect(() => {
    if (
      sentRequest &&
      profilePictureState.download.loading === LoadingType.succeeded
    ) {
      setSentRequest(false);
      setImageURL(profilePictureState.download.response ?? "");
    }
  }, [profilePictureState.download.loading]);

  return (
    <div className="cds-user-info">
      <UserAvatar
        onClick={() => setShowImageUpload(true)}
        url={imageURL}
        rating={{ value: rating, level: level }}
      />
      <div className="cds-user-name">
        <span>{name}</span>
      </div>
      {showImageUpload && (
        <Portal>
          <UploadProfile
            onClose={() => setShowImageUpload(false)}
            onDismiss={() => setShowImageUpload(false)}
            visible={showImageUpload}
            onApprove={updateProfile}
            objectId={props.objectId}
            objectType={ProfilePictureType.companies}
          />
        </Portal>
      )}
    </div>
  );
};

const buttonsList = [
  {
    title: "company_assignments",
    icon: ActiveAssignmentsIcon,
    type: "company_assignments",
    color: THEME.statusActiveColor,
  },
  {
    title: "company_job_orders",
    icon: BriefcaseIcon,
    type: "company_job_orders",
    color: THEME.defaultHighLightColor,
  },
  {
    title: "schedule_a_sales_call",
    icon: CallWhiteIcon,
    type: "schedule_a_sales_call",
    color: THEME.buttonColor15,
  },
];

interface CompanyActionsProps {
  onClick: (type: string) => void;
}

const CompanyActions: React.FunctionComponent<CompanyActionsProps> = (
  props
) => {
  const { t } = useTranslation();
  return (
    <div className="cds-action-container">
      {buttonsList.map((doc, index) => {
        return (
          <div key={index} className="cds-action">
            <CustomButton
              customStyle={{ width: "-webkit-fill-available" }}
              leftIcon={
                <div className="cds-action-icon">
                  <doc.icon
                    width={"100%"}
                    height={"100%"}
                    style={{ color: "#fff" }}
                  />
                </div>
              }
              loading={false}
              textStyle={{ textTransform: "none" }}
              name={t(doc.title)}
              enable={true}
              backgroundColor={doc.color}
              onClick={() => props.onClick(doc.type)}
            />
          </div>
        );
      })}
    </div>
  );
};
export default CompanyDetailsSection;

import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper from '../../../../components/dialog_wrapper/dialog_wrapper';
import { LoadingType, ROLE } from '../../../../enums';
import { ICreateTalentInHouseInterview, ITalentInHouseInterview } from '../../../../interfaces';
import { addNewTalentInHouseInterview, getRoleUsersList, getTalentInHouseInterviewQuestions, postSnackbarMessage, selectRoleUsersList, selectRoleUsersListState, selectSingleInHouseInterviewsQuestionsStateById, selectSingleInHouseInterviewsStateById, updateTalentInHouseInterview, useAppDispatch, useAppSelector } from '../../../../redux/store';
import { talentInHouseInterviewActions } from '../../../../redux/talent/details/interviews/in_house_interviews/in_house_interviews_reducer';
import { SpinnerScreen } from '../../../../utils';
import InHouseInterviewForm from '../../forms/in_house_interview_form/in_house_interview_form';
import './in_house_interview.scss';

interface Props {
    visible: boolean,
    title: string,
    talentId: string,
    interview?: ITalentInHouseInterview | null,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
    onlineAppDate: number | undefined,
}

const InHouseInterviewPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onSuccessClose,
        talentId,
        interview,
        onlineAppDate
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const actionRef = useRef<ActionDialogHolderType>(null);
    const interviewQuestionsState = useAppSelector(state => selectSingleInHouseInterviewsQuestionsStateById(talentId)(state));
    const inHouseInterviewsState = useAppSelector((state) => selectSingleInHouseInterviewsStateById(talentId)(state));
    const createState = inHouseInterviewsState.add;
    const updateState = inHouseInterviewsState.update;

    const roleUsersListState = useAppSelector((state) => selectRoleUsersListState(state));
    const roleUsersList = useAppSelector((state) => selectRoleUsersList(state));
    useEffect(() => {
        getInterviewQuestions();
        dispatch(getRoleUsersList({ branch_id: '', role: ROLE.recruiter }));
        return () => { }
    }, [])

    useEffect(() => {
        if (createState.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(createState.response ?? null));
            closePopup(onSuccessClose);
        }
        if (updateState.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(updateState.response ?? null));
            closePopup(onSuccessClose);
        }
        return () => { }
    }, [createState.loading, updateState.loading])

    function getInterviewQuestions() {
        dispatch(getTalentInHouseInterviewQuestions(talentId));
    }

    function getLoading() {
        return interviewQuestionsState.loading === LoadingType.pending || roleUsersListState.loading === LoadingType.pending;
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
            customStyle={{ maxWidth: '50%' }}
        >
            <DialogWrapper onClose={() => {closePopup(onClose); handleClearError();}}>
                {interviewForm()}
            </DialogWrapper>
        </ActionDialogHolder>
    );

    function interviewForm() {
        if (getLoading()) {
            return (
                <div className="loading-spinner"><SpinnerScreen /></div>
            )
        }
        return (
            <InHouseInterviewForm
                title={props.title}
                talentId={props.talentId}
                loading={(createState.loading === LoadingType.pending) || (updateState.loading === LoadingType.pending)}
                error={createState.error || updateState.error}
                interview={interview}
                recruiters={roleUsersList}
                questions={interviewQuestionsState.data ?? []}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                onClearError={handleClearError}
                onlineAppDate={onlineAppDate}
            />
        )
    }

    ///Handle clear error
    function handleClearError() {
        dispatch(talentInHouseInterviewActions.clearSingleTalentInHouseInterviewsStateError(props.talentId));
    }

    function handleSubmit(value: ICreateTalentInHouseInterview, send_invite?: boolean) {
        if (interview && interview.id) {
            dispatch(updateTalentInHouseInterview({ talentId: talentId, data: { ...value, id: interview.id, interview_by: interview.interview_by }, send_invite: send_invite }));
        } else {
            dispatch(addNewTalentInHouseInterview({ talentId: talentId, data: value, send_invite: send_invite }));
        }
    }

    function handleCancel() {
        handleClearError();
        closePopup(onClose);
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
}

export default InHouseInterviewPopup;
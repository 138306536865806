import { useTranslation } from "react-i18next";
import { THEME } from "../../enums";
import {
  SectorIcon,
  LocationIcon,
  CallIcon,
  SSNIcon,
  MarkTalentIcon,
  PayrateIcon,
  EmailIcon,
  AssignIcon,
  TextEmAllIcon,
  CloseIcon,
} from "../../icons";
import { ITalent } from "../../interfaces";
import {
  addressParser,
  currencyConversion,
  CustomButton,
  getArrayStrings,
} from "../../utils";
import SocialMediaProfile from "../social_media_profile/social_media_profile";
import StatusBanner from "../status_banner/status_banner";
import { LoadingType, ProfilePictureType } from "../../enums";
import UploadProfile from "../../components/image_crop/image_crop_popup";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
  getPreSignedURLForDownload,
  selectSingleProfilePictureStateById,
} from "../../redux/store";
import { Portal } from "react-portal";
import { getDateString, getDaysAgoWithAMPM } from "../../variables";
import SSNHolder from "../ssn_holder/ssn_holder";
import ResumeHolder from "../resume_holder/resume_holder";
import UserAvatar from "../user_avatar/user_avatar";
import "./employee_portal_details.scss";
import { sendEmailActions } from "../../redux/send_email/send_email_reducer";
import { AppRoutes } from "../../routes";
import { useHistory } from "react-router-dom";
import SendEmailPopup from "../../main/components/send_email/send_email_popup";
interface Props {
  talent: ITalent | null;
  onBranchChange: (value: string) => void;
  onActionClick: (value: string) => void;
  onSSNClick: () => void;
  onResumeClick: (path: string) => void;
  isTalent?: boolean;
}

const EmployeePortalDetailsSection: React.FunctionComponent<Props> = (
  props
) => {
  const { talent, onBranchChange, onActionClick } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [showSendEmail, setShowSendEmail] = useState(false);

  const employeePortalProfileSections = [
    {
      sectionTitle: "sector",
      sectionIcon: SectorIcon,
      direction: "row",
      sectionContent: [
        { title: "sector", valueField: "business_sector_value" },
        { title: "job_title", valueField: "title" },
      ],
    },
    {
      sectionTitle: "payrate",
      sectionIcon: PayrateIcon,
      direction: "row",
      sectionContent: [
        { title: "payrate", valueField: "pay_rate" },
        { title: "resume", valueField: getResumeIcon() }, ///resume_path
      ],
    },
    {
      sectionTitle: "ssn",
      sectionIcon: SSNIcon,
      direction: "column",
      sectionContent: [
        { title: "ss_number", valueField: getSSNLockIcon() },
      ],
    },
    {
      sectionTitle: "address",
      sectionIcon: LocationIcon,
      direction: "column",
      sectionContent: [
        {
          title: "",
          valueField: addressParser({
            street_address: talent ? talent["street_address"] : null,
            street_address2: talent ? talent["street_address2"] : null,
            city: talent ? talent["city"] : null,
            state: talent ? talent["state"] : null,
            zip_code: talent ? talent["zip_code"] : null,
          }),
        },
      ],
    },

    {
      sectionTitle: "contact",
      sectionIcon: CallIcon,
      direction: "column",
      sectionContent: [{ title: "", valueField: "phone" }],
    },
    {
      sectionTitle: "work_email",
      sectionIcon: EmailIcon,
      direction: "column",
      sectionContent: [{ title: "work_email", valueField: "email_2" }],
    },
    {
      sectionTitle: "personal_email",
      sectionIcon: EmailIcon,
      direction: "column",
      sectionContent: [{ title: "personal_email", valueField: "email" }],
    },
  ];

  function getSSNLockIcon() {
    if (
      talent?.last_4_digits_of_ssn == null ||
      talent?.last_4_digits_of_ssn == ""
    )
      return <span className="na-span">NA</span>;
    return (
      <SSNHolder
        value={talent?.last_4_digits_of_ssn}
        iconColor={THEME.defaultHighLightColor}
        onClick={props.onSSNClick}
        showFormat={true}
      />
    );
  }

  function getResumeIcon() {
    if (talent?.resume_file_name == null || talent?.resume_file_name == "")
      return <span className="na-span">NA</span>;
    if (talent && talent.resume_file_name) {
      return (
        <ResumeHolder
          path={talent?.resume_file_name}
          onClick={() =>
            talent.resume_path != null && talent.resume_path != ""
              ? props.onResumeClick(talent.resume_path)
              : {}
          }
        />
      );
    }
    return "";
  }


  const handleClearError = () => {
    dispatch(sendEmailActions.clearSendEmailStateError());
  };

  return (
    <div className="tds-container">
      <EmployeePortalInfo
        name={`${talent?.first_name ?? ""} ${talent?.middle_name ?? ""} ${
          talent?.last_name ?? ""
        }`}
        uri={talent?.contact_photo}
        objectId={talent?.id}
        rating={talent?.engagement_rating}
        level={talent?.engagement_level}
      />
      {talentActivity()}
      <div className="tds-branch-selector">
         <div className="tds-branch-left">
          <div className="tds-status">
            <span>
              {`${t("STATUS")}`}
            </span>
            <span className="tds-status-txt"
                  style={{
                    color:
                      talent?.status === "Active"
                        ? THEME.buttonColor17
                        : talent?.status === "Inactive"
                        ? "#b1b1b1"
                        : "#ED4857",
                  }}>
                  {talent?.status}
                </span>
          </div>
        </div>
        <div className="tds-branch-right">
          <div className="tds-status">
            <span>
              {`${t("employee_id")}`}
              </span>
              <span
                className="tds-id-txt">
                {talent?.id_number ? talent.id_number : ""}
              </span>
          </div>
        </div>
      </div>

      {employeePortalProfileSections.map((doc, index) => {
        return (
          <div
            key={index}
            className={
              doc.sectionTitle === "contact" || doc.sectionTitle === "email"
                ? "tds-info-address-container"
                : "tds-info-container"
            }
            style={
              doc.sectionTitle === "personal_email"
                ? { border: "none", paddingTop: "0", marginBottom: "0" }
                : doc.sectionTitle === "work_email"
                ? { marginBottom: "0" }
                : {}
            }>
            <div className="tds-info-icon">
              <doc.sectionIcon width={"100%"} height={"100%"} />
            </div>
            <div
              className="tds-info-content"
              style={{
                ...(doc.direction === "row" && {
                  flexDirection: "row",
                  justifyContent: "space-between",
                }),
              }}>
              {doc.sectionContent.map((data, index) => {
                const arrayValue = getValues(data.valueField);

                return (
                  <div
                    key={index}
                    className="tds-info-holder"
                    style={{
                      ...(doc.direction === "row" && { flex: 1, width: "50%" }),
                      // ...(index === 0 && { marginRight: "1em" }),
                    }}>
                    <div className="tds-into-title">
                      {doc.sectionTitle === "address" ||
                      doc.sectionTitle === "contact" ||
                      doc.sectionTitle === "email" ? (
                        <span style={{ display: "grid" }}>
                          {t(data.title).toUpperCase()}
                        </span>
                      ) : (
                        <span>{t(data.title).toUpperCase()}</span>
                      )}
                    </div>
                    <div
                      className="tds-info-value"
                      style={{ marginBottom: "0.5em" }}>
                      {doc.sectionTitle === "work_email" ||
                      doc.sectionTitle === "personal_email" ? (
                        doc.sectionTitle === "work_email" ? (
                          <div
                            className="email-info-value"
                            style={{ marginBottom: "0.5em" }}
                            onClick={
                              talent?.email_2 !== "" && talent?.email_2 !== null
                                ? () => setShowSendEmail(true)
                                : () => {}
                            }>
                            <span>
                              {talent?.email_2 !== null &&
                              talent?.email_2 !== "" ? (
                                arrayValue
                              ) : (
                                <span className="na-span">NA</span>
                              )}
                            </span>
                          </div>
                        ) : (
                          <div
                            className="email-info-value"
                            style={{ marginBottom: "0.5em" }}
                            onClick={
                              talent?.email !== "" && talent?.email !== null
                                ? () => setShowSendEmail(true)
                                : () => {}
                            }>
                            <span>
                              {talent?.email !== null &&
                              talent?.email !== "" ? (
                                arrayValue
                              ) : (
                                <span className="na-span">NA</span>
                              )}
                            </span>
                          </div>
                        )
                      ) : (
                        arrayValue
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      {showSendEmail && (
        <Portal>
          <SendEmailPopup
            visible={showSendEmail}
            data={{
              obj_id: talent?.id!,
              email: talent?.email!,
              email_2: talent?.email_2!,
            }}
            objectType={ProfilePictureType.talent}
            onClose={() => setShowSendEmail(false)}
            onClearError={handleClearError}
          />
        </Portal>
      )}

      <SocialMediaProfile
        title={t("social_media").toUpperCase()}
        profile={talent?.social_media_links!}
      />
      <EmployeePortalActions
        onClick={onActionClick}
        disabled={talent?.status}
      />
      {talent?.is_available && (
        <div className="available-status">
          <StatusBanner title={t("available")} />
        </div>
      )}
    </div>
  );

  function getValues(obj: any) {
    if (talent == null) return <span>{""}</span>;
    if (Array.isArray(obj)) {
      return getArrayStrings(obj, talent);
    }
    const value = obj !== "" && talent![obj] !== null ? talent![obj] : "";
    if (obj === "pay_rate") {
      return <span>{currencyConversion(value)}</span>;
    }
    if (obj === "business_sector_value") {
      return talent.business_sector_value !== null &&
        talent.business_sector_value !== "" ? (
        <span>{talent.business_sector_value}</span>
      ) : (
        <span className="na-span">NA</span>
      );
    }
    if (obj === "title") {
      return talent.title !== null && talent.title !== "" ? (
        <span>{talent.title}</span>
      ) : (
        <span className="na-span">NA</span>
      );
    }
    if (obj === "phone") {
      return talent.phone !== null && talent.phone !== "" ? (
        <span>{talent.phone}</span>
      ) : (
        <span className="na-span">NA</span>
      );
    }
    if (obj === "email") {
      return talent.email !== null && talent.email !== "" ? (
        <span>{talent.email}</span>
      ) : (
        <span className="na-span">NA</span>
      );
    }
    if (typeof obj === "object") {
      return obj;
    }
    return <span>{value}</span>;
  }

  function talentActivity() {
    const lastEngagementDate = talent?.last_engagement_date
      ? getDaysAgoWithAMPM(t, talent?.last_engagement_date)
      : null;
    return (
      <div className="tds-activity">
        {lastEngagementDate && (
          <div className="last-engagement-txt">
            <span>{t("last_engagement", { value: lastEngagementDate })}</span>
          </div>
        )}
        {talent?.currently_on_assignment && (
          <div className="assignment-status">
            <div className="icon">
              <AssignIcon
                width={"100%"}
                height={"100%"}
                style={{ color: THEME.statusActiveColor }}
              />
            </div>
            <div className="status">
              <span style={{ color: THEME.statusActiveColor }}>
                {t("currently_on_assignment")}
              </span>
            </div>
          </div>
        )}
        {talent?.status === "Terminated" && (
              <div>
                <span style={{color: "#ED4857", fontWeight: "600"}} className="terminated-date">
                  {`${t("termination_date")}: `}
                  <span>{getDateString(talent?.termination_date)}</span>
                </span>
              </div>
            )}
      </div>
    );
  }
};

const EmployeePortalInfo = (props) => {
  const { name, uri, rating, level } = props;
  const dispatch = useAppDispatch();
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [sentRequest, setSentRequest] = useState(false);
  const [imageURL, setImageURL] = useState("");

  const profilePictureState = useAppSelector((state) =>
    selectSingleProfilePictureStateById(
      ProfilePictureType.talent + "-" + props.objectId
    )(state)
  );

  useEffect(() => {
    setSentRequest(true);
    dispatch(
      getPreSignedURLForDownload({
        object_id: props.objectId,
        object_type: ProfilePictureType.talent,
      })
    );
  }, []);

  function updateProfile() {
    dispatch(
      getPreSignedURLForDownload({
        object_id: props.objectId,
        object_type: ProfilePictureType.talent,
      })
    );
    setSentRequest(true);
  }

  useEffect(() => {
    if (
      sentRequest &&
      profilePictureState.download.loading === LoadingType.succeeded
    ) {
      setSentRequest(false);
      setImageURL(profilePictureState.download.response ?? "");
    }
  }, [profilePictureState.download.loading]);

  return (
    <div className="tds-user-info">
      <UserAvatar
        onClick={() => setShowImageUpload(true)}
        url={imageURL}
        rating={{ value: rating, level: level }}
      />
      <div className="tds-user-name">
        <span>{name}</span>
      </div>
      {showImageUpload && (
        <Portal>
          <UploadProfile
            onClose={() => setShowImageUpload(false)}
            onDismiss={() => setShowImageUpload(false)}
            visible={showImageUpload}
            onApprove={updateProfile}
            objectId={props.objectId}
            objectType={ProfilePictureType.talent}
          />
        </Portal>
      )}
    </div>
  );
};

const buttonsList = [
  {
    title: "mark_talent_available",
    icon: MarkTalentIcon,
    type: "mark_talent_available",
    color: THEME.buttonColor16,
  },
  {
    title: "send_message_to_emp",
    icon: TextEmAllIcon,
    type: "send_message_to_emp",
    color: "#ff4d00",
  },
  {
    title: "terminate_employee",
    icon: CloseIcon,
    type: "terminate_employee",
    color: "#ED4857",
  },
];

interface EmployeePortalActionsProps {
  onClick: (type: string) => void;
  talent?: ITalent | null;
  adminName?: string;
  isTalent?: boolean;
  disabled: string | undefined;
}

const EmployeePortalActions: React.FunctionComponent<
  EmployeePortalActionsProps
> = (props) => {
  const { t } = useTranslation();
  return (
    <div className="tds-action-container">
      {buttonsList.map((doc, index) => {
        return (
          <div key={index} className="tds-action">
            <CustomButton
              customStyle={{ width: "-webkit-fill-available" }}
              leftIcon={
                <div className="tds-action-icon">
                  <span style={{ color: "#fff" }}>
                    <doc.icon width={"100%"} height={"100%"} />
                  </span>
                </div>
              }
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t(doc.title)}
              enable={
                props.disabled === "Terminated" &&
                doc.type === "terminate_employee"
                  ? false
                  : true
              }
              backgroundColor={doc.color}
              onClick={() => props.onClick(doc.type)}
              customClassName={doc.title}
            />
          </div>
        );
      })}
    </div>
  );
};

export default EmployeePortalDetailsSection;

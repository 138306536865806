import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./sixty_day_calls_report_thunk";
import { IReportCenterService } from "../../../../interfaces/report_center_service";

export interface SixtyDayCallsReportsListState extends EntityState<IReportCenterService> {
    error?: AppError | null,
    loading: LoadingType,  
}

const sixtyDayCallsReportsListAdapter: EntityAdapter<IReportCenterService> = createEntityAdapter<IReportCenterService>({
    selectId: (doc) => doc.id,
})

const initialState: SixtyDayCallsReportsListState = sixtyDayCallsReportsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const sixtyDayCallsReportsListSlice = createSlice({
    name: 'sixtyDayCallsReportsList',
    initialState: initialState,
    reducers: {
        clearSixtyDayCallsReportsListError(state) {
            return { ...state, error: null };
        },
        clearSixtyDayCallsReportsListState(state) {
            return sixtyDayCallsReportsListAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getSixtyDayCallsReportsList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getSixtyDayCallsReportsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getSixtyDayCallsReportsList.fulfilled, (state, action) => sixtyDayCallsReportsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearSixtyDayCallsReportsListError, clearSixtyDayCallsReportsListState } = sixtyDayCallsReportsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = sixtyDayCallsReportsListAdapter.getSelectors();
export const selectState = (state: SixtyDayCallsReportsListState) => state;



import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getPayrollJournalList, getReportStartEndDate, selectPayrollJournalListState, selectReportsDownloadState, selectReportStartEndDateState, useAppDispatch, useAppSelector, getReportDownloadURL, getPaycheckPDFURLThunk, selectePaycheckInvoiceState } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { convertDateToTimeStamp, dateType, getDateFromTimeStamp, getDateString } from "../../../../../variables";
import { LoadingType, THEME } from "../../../../../enums";
import { IRCPayrollJournalPayChecks, IRCPayrollJournal } from "../../../../../interfaces/report_center_payroll_tax";
import { AppRoutes } from "../../../../../routes";
import DatePicker from "react-datepicker";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../../components/table_empty/table_empty";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { CalenderIcon, DownloadIcon } from "../../../../../icons";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import SearchBar from "../../../../../components/search_bar";
import { currencyConversion, CustomButton } from "../../../../../utils";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import './payroll_journal.scss'
import UrlBuilder from "../../../../../apis/url_builder";
import { reportDownloadSliceActions } from "../../../../../redux/report_center/download_report/download_report_reducer";
import { paycheckInvoiceSliceActions } from "../../../../../redux/back_office/view_paycheck_invoice/view_paycheck_invoice_reducer";
import ApiError from "../../../../../components/api_error";
import { clearPayrollJournalListError } from "../../../../../redux/report_center/payroll_tax/payroll_journal/payroll_journal_reducer";

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
    { title: "employee", code: "employee_name" },
    { title: "check_date", code: "check_date" },
    { title: "check_number", code: "check_number" },
    { title: "payment_type", code: "check_delivery" },
    { title: "gross_amount", code: "gross_amount" },
    { title: "taxes", code: "total_tax_amount" },
    { title: "deductions", code: "total_deduction" },
    { title: "net_deposit", code: "net_amount" },
];

const PayrollJournal: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const payrollJournalListState = useAppSelector((state) => selectPayrollJournalListState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));
    const reportStartEndDate = useAppSelector((state) => selectReportStartEndDateState(state));

    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");
    const [isCheckDate, setIsCheckDate] = useState<boolean>(false);
    const [payrollJournalList, setPayrollJournalList] = useState<IRCPayrollJournal | null>(null);

    const [formState, setFormstate] = useState<{ available_from: number, available_to: number }>()
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const paycheckPDFURLState = useAppSelector((state) => selectePaycheckInvoiceState(state));
    const loadingState = payrollJournalListState.loading === LoadingType.pending

    useEffect(() => {
        dispatch(getReportStartEndDate({}));
    }, []);

    useEffect(() => {
        if (reportStartEndDate.loading === LoadingType.succeeded && reportStartEndDate.response.length !== 0) {
            const startDate = reportStartEndDate.response[0];
            const EndDate = reportStartEndDate.response[1];
            setFormstate({ available_from: startDate, available_to: EndDate });
            setDateRange([getDateFromTimeStamp(startDate), getDateFromTimeStamp(EndDate)]);
        }
    }, [reportStartEndDate.loading, reportStartEndDate.response]);

    useEffect(() => {
        if ((formState?.available_from !== 0 && formState?.available_to !== 0)
            || ((formState?.available_from !== 0 && formState?.available_to !== 0) || (formState?.available_from === 0 && formState?.available_to === 0) && isCheckDate)) {
            getPJournalList();
            setDatePickerOpen(false)
        }
        else if ((formState?.available_from === 0 && formState?.available_to === 0)) {
            getPJournalList();
            setDatePickerOpen(true);
        }
    }, [formState?.available_from, formState?.available_to, isCheckDate]);


    useEffect(() => {
        if (payrollJournalListState && payrollJournalListState?.loading === LoadingType.succeeded) {
            setPayrollJournalList(payrollJournalListState?.response);
        }
        return () => {
            setPayrollJournalList(null) 
        }
    }, [payrollJournalListState?.loading]);

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    useEffect(() => {
        if (paycheckPDFURLState.paycheck.loading === LoadingType.succeeded && paycheckPDFURLState.paycheck.response !== "") {
            window.open(paycheckPDFURLState.paycheck.response, "_blank");
            dispatch(paycheckInvoiceSliceActions.clearPaycheckState());
        }
    }, [paycheckPDFURLState.paycheck.loading, paycheckPDFURLState.paycheck.response]);

    const getPJournalList = () => {
        if (formState) {
            dispatch(getPayrollJournalList({ start_date: formState.available_from, end_date: formState.available_to, is_check_date: isCheckDate! }))
        }
    }

    function handleChange(value) {
        setFormstate({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setDateRange(value);
    }

    function handleDownload() {
        if (formState) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.payrollJournal,
                    start_date: formState.available_from,
                    end_date: formState.available_to,
                    is_check_date: isCheckDate ? isCheckDate : false,
                    download: true
                }));
        }
    }

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function handleTalentSelect(value: IRCPayrollJournalPayChecks) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
            state: {
                id: value.talent_id,
                name: value.employee_name,
            },
        });
        return;
    }

    const handleViewCheck = (pay_check_id: string) => {
        dispatch(getPaycheckPDFURLThunk(pay_check_id));
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        return 1;
    }

    const showSpinner = () => payrollJournalListState?.loading === LoadingType.pending;

    const getFilteredList = () => {
        let list: IRCPayrollJournalPayChecks[] | undefined = payrollJournalList?.paychecks;

        if (payrollJournalList && Array.isArray(payrollJournalList?.paychecks) && sortedField != null) {
            list = [...payrollJournalList?.paychecks].sort((a, b) => {
                const valueA =
                    a[sortedField] !== null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] !== null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }

    const getTable = () => {
        if (showSpinner()) return <TableLoading />;

        if (payrollJournalListState?.error != null && payrollJournalList?.paychecks?.length === 0) {
            return <TableErrorHandler error={payrollJournalListState?.error} onRefresh={getPJournalList} />;
        }

        if (payrollJournalList?.paychecks?.length === 0 || getFilteredList()?.length === 0) {
            return <TableEmpty title={"No Data found"} onClick={getPJournalList} />;
        }

        if (getFilteredList()?.length !== 0) {
            return (
                <div className="pj-table">
                    <SortableTable
                        headerList={tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {getFilteredList()?.map((doc) => {
                            return (
                                <tr key={doc.id}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}
                                        isButton={true}
                                        onClick={() => handleTalentSelect(doc)}
                                    >
                                        <span>{doc.employee_name}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                        <span>{getDateString(doc.check_date)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2) }}
                                        isButton={true}
                                        onClick={() => handleViewCheck(doc.id)}
                                    >
                                        <span>{doc.check_number}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                        <span>{doc.check_delivery}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                        <span>{currencyConversion(doc.gross_amount)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                        <span>{currencyConversion(doc.total_tax_amount)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                        <span>{currencyConversion(doc.total_deduction)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                        <span>{currencyConversion(doc.net_amount)}</span>
                                    </TableData>
                                </tr>
                            )
                        })}
                    </SortableTable>
                </div>
            );
        }
    };

    const handleClearError = () => {
        dispatch(clearPayrollJournalListError())
    }

    return (
        <div className="payroll-journal-container">
            <div>
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>
            <div className="payroll-journal-header">
                <div className="pj-r-left">
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={() => setDatePickerOpen(true)} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleChange}
                                isClearable={loadingState ? false : true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dropdownMode="select"
                                disabled={loadingState}
                            />
                        </div>
                    </div>
                    <div className="fs-btn">
                        <CustomFormSelect
                            name={t("date_type")}
                            list={dateType.map((doc) => ({
                                label: doc.label,
                                value: doc.value,
                            }))}
                            onChange={setIsCheckDate}
                            value={isCheckDate}
                            placeholder={t('select')}
                            required={false}
                            label={t('date_type')}
                            disabled={loadingState}
                        />
                    </div>
                    {payrollJournalListState?.error && <div className="error-holder">
                        <ApiError message={payrollJournalListState?.error?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="pj-r-right">
                    <div className="total-count-action">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                    <div className="download-btn">
                        <CustomButton
                            leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                            loading={false}
                            name={t("download")}
                            enable={getFilteredList()?.length !== 0 && (loadingState ? false : true)}
                            backgroundColor={THEME.buttonColor16}
                            onClick={() => handleDownload()}
                        />
                    </div>
                    <div>
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                        />
                    </div>
                </div>
            </div>

            <div className="table-container">
                <div className="header-container">
                    <div className="table-header">
                        <div className="table-header-title">
                            <span>{t("payroll_journal")}</span>
                        </div>
                    </div>
                </div>
                {getTable()}
            </div>
            {payrollJournalListState.loading === LoadingType.succeeded &&
                <div className="total-counts-card">
                    <div className="total-title">
                        Totals
                    </div>
                    <div className="total-count-main">
                        <div className="total-count">
                            {currencyConversion(payrollJournalList?.paychecks && payrollJournalList?.paychecks.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.gross_amount
                            }, 0) : 0)}
                        </div>
                        <div className="total-count">
                            {currencyConversion(payrollJournalList?.paychecks && payrollJournalList?.paychecks.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.total_tax_amount
                            }, 0) : 0)}
                        </div>
                        <div className="total-count">
                            {currencyConversion(payrollJournalList?.paychecks && payrollJournalList?.paychecks.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.total_deduction
                            }, 0) : 0)}
                        </div>
                        <div className="total-count">
                            {currencyConversion(payrollJournalList?.paychecks && payrollJournalList?.paychecks.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.net_amount
                            }, 0) : 0)}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default PayrollJournal;
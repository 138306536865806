import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getArrivalCheckCallsReportList, getReportDownloadURL, selectArrivalCheckCallsReportList, selectArrivalCheckCallsReportListState, selectReportsDownloadState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import ServiceReportSection from "../../../components/service_section_page/service_section_page";
import { IReportCenterDownloadUrlParams, IReportCenterDownloadUrlRequestBody } from "../../../../../interfaces/report_center";
import './arrival_check_calls_report.scss'
import { useEffect, useState } from "react";
import { LoadingType } from "../../../../../enums";
import UrlBuilder from "../../../../../apis/url_builder";
import { clearArrivalCheckCallsReportListError } from "../../../../../redux/report_center/service/arrival_check_calls_report/arrival_check_calls_report_reducer";
import { IReportCenterService } from "../../../../../interfaces/report_center_service";


const tableHeader = [
    { title: "customer", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "employee", code: "talent_name" },
    { title: "manager", code: "manager" },
    { title: "job_title", code: "job_title" },
    { title: "arrival_call_date", code: "date_of_scheduled_call" },
    { title: "dm_reached", code: "dm_reached" },
    { title: "call_results", code: "call_result" },
];

interface Props extends RouteComponentProps<any> { 
    isDirectServiceCalls: boolean
}
const ArrivalCheckCallsReport: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { location, history, isDirectServiceCalls } = props;

    const arrivaCheckCallsReportListState = useAppSelector((state) => selectArrivalCheckCallsReportListState(state));
    const arrivaCheckCallsReportListData = useAppSelector((state) => selectArrivalCheckCallsReportList(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));

    const filteredArrivaCheckCalsReptList = isDirectServiceCalls
        ? arrivaCheckCallsReportListData.filter(doc => {
            const isDirect = doc.direct_hire === true;
            return isDirect;
        })
        : arrivaCheckCallsReportListData.filter(doc => {
            const isDirect = doc.direct_hire === false;
            return isDirect;
        });

    const [companiesList, setCompaniesList] = useState<{label: string, value: string}[]>([])
    const [arivaCheckCalsReptList, setArrivaCheckCalsReptList] = useState<IReportCenterService[] | null>(null);

    useEffect(() => {
        if (arrivaCheckCallsReportListState.loading === LoadingType.succeeded) {
            setArrivaCheckCalsReptList(filteredArrivaCheckCalsReptList);
        }
        return () => {
            setArrivaCheckCalsReptList(null) 
        }
    }, [arrivaCheckCallsReportListState.loading]);

    useEffect(() => {
        if (arrivaCheckCallsReportListState && arrivaCheckCallsReportListState?.loading === LoadingType.succeeded) {
            const updatedCompaniesList = filteredArrivaCheckCalsReptList?.reduce<{ label: string; value: string }[]>((acc, company) => {
                if (!acc.some(item => item.value === company.company_id)) {
                    acc.push({ label: company.company_name, value: company.company_id });
                }
                return acc;
            }, []);
            setCompaniesList(updatedCompaniesList);
        }
    }, [arrivaCheckCallsReportListState?.loading]);

    const getArrivalCheckCallReportList = ({ start_date, end_date }: { start_date: number, end_date: number }) => {
        dispatch(getArrivalCheckCallsReportList({ start_date, end_date }))
    }
    function handleDownload({ params, requestBody }: { params: IReportCenterDownloadUrlParams, requestBody?: IReportCenterDownloadUrlRequestBody }) {
        dispatch(getReportDownloadURL(
            {
                baseUrl: UrlBuilder.serviceCallsRCArrivalCheckCallsReport,
                start_date: params.start_date,
                end_date: params.end_date,
                company_id: params.company_id,
                department_id: params.department_id,
                download: params.download,
                downloadAR: { account_managers: requestBody?.account_manager_ids, recruiters: requestBody?.recruiter_ids },
            }));
    }

    const handleClearError = () => {
        dispatch(clearArrivalCheckCallsReportListError())
    }

    return (
        <div className="arrival-check-calls-reports-container">
            <ServiceReportSection
                tableHeader={tableHeader}
                reportListState={arrivaCheckCallsReportListState}
                reportList={arivaCheckCalsReptList!} 
                history={history}
                loading={arrivaCheckCallsReportListState.loading === LoadingType.pending}
                companiesList={companiesList}
                downloadUrlState={reportDownloadUrlState}
                tableHeaderTitle={"arrival_check_calls_report"}
                getReportList={getArrivalCheckCallReportList}
                handleDownload={handleDownload}
                onClearError={handleClearError}
            />
        </div>
    )

}
export default ArrivalCheckCallsReport;
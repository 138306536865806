import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getPRListThunk, getReportDownloadURL, getReportStartEndDate, selectPaycheckRegisterListState, selectReportsDownloadState, selectReportStartEndDateState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import './paycheck_register.scss';
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { CalenderIcon, DownloadIcon } from "../../../../../icons";
import { convertDateToTimeStamp, dateType, getDateFromTimeStamp, getDateString } from "../../../../../variables";
import { IRCPaycheckRegister } from "../../../../../interfaces/report_center";
import { LoadingType, THEME } from "../../../../../enums";
import PaycheckTalentTablePage from "../../../components/paycheck_talent_table/paycheck_talent_table";
import { CustomButton, SpinnerScreen } from "../../../../../utils";
import PaycheckSummaryTable from "../../../components/paycheck_summary_table/paycheck_summary_table";
import { paycheckRegisterSliceActions } from "../../../../../redux/report_center/payroll_tax/paycheck_register/paycheck_register_reducer";
import SearchBar from "../../../../../components/search_bar";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import UrlBuilder from "../../../../../apis/url_builder";
import { reportDownloadSliceActions } from "../../../../../redux/report_center/download_report/download_report_reducer";
import ApiError from "../../../../../components/api_error";

interface Props extends RouteComponentProps<any> { }

const PayCheckRegister: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { location, history } = props;
    const paycheckRegisterState = useAppSelector((state) => selectPaycheckRegisterListState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));
    const reportStartEndDate = useAppSelector((state) => selectReportStartEndDateState(state));

    const [prList, setPRList] = useState<IRCPaycheckRegister | null>(null)
    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>()
    const [search, setSearch] = useState('');
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isCheckDate, setIsCheckDate] = useState<boolean>(false);
    const loadingState = paycheckRegisterState.loading === LoadingType.pending;

    useEffect(() => {
        dispatch(getReportStartEndDate({}));
    }, []);

    useEffect(() => {
        if (reportStartEndDate.loading === LoadingType.succeeded && reportStartEndDate.response.length !== 0) {
            const startDate = reportStartEndDate.response[0];
            const EndDate = reportStartEndDate.response[1];
            setFormState({ available_from: startDate, available_to: EndDate });
            setDateRange([getDateFromTimeStamp(startDate), getDateFromTimeStamp(EndDate)]);
        }
    }, [reportStartEndDate.loading, reportStartEndDate.response]);

    useEffect(() => {
        if ((formState?.available_from !== 0 && formState?.available_to !== 0) || ((formState?.available_from !== 0 && formState?.available_to !== 0) || (formState?.available_from === 0 && formState?.available_to === 0) && isCheckDate)) {
            getPaycheckRegisterList();
            setDatePickerOpen(false);
        }
        else if ((formState?.available_from === 0 && formState?.available_to === 0)) {
            getPaycheckRegisterList();
            setDatePickerOpen(true);
        }
    }, [formState?.available_from, formState?.available_to, isCheckDate]);

    useEffect(() => {
        if (paycheckRegisterState.loading === LoadingType.succeeded && paycheckRegisterState.response) {
            setPRList(paycheckRegisterState.response);
            setTotalCount(paycheckRegisterState.response.talents.length);
        }
        return () => {
            setPRList(null) 
        }
    }, [paycheckRegisterState.loading, paycheckRegisterState.response, paycheckRegisterState.response?.talents.length]);

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    const getPaycheckRegisterList = () => {
        if (formState) {
            dispatch(getPRListThunk({ start_date: formState.available_from, end_date: formState.available_to, is_check_date: isCheckDate }));
            setTotalCount(prList ? prList.talents.length : 0);
        }
    }

    function handleChange(value) {
        setFormState({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setDateRange(value);
        dispatch(paycheckRegisterSliceActions.clearPRState());
    }

    function handleDownload() {
        if (formState) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.paycheckRegister,
                    start_date: formState.available_from,
                    end_date: formState.available_to,
                    is_check_date: isCheckDate ? isCheckDate : false,
                    download: true
                }));
        }
    }

    const handleClearError = () => {
        dispatch(paycheckRegisterSliceActions.clearPRErrorState())
    }

    return (
        <div className="pcr-container">
            <div className="header-container">
                <div className="table-header">
                    <span>{t("paycheck_register")}</span>
                </div>
                <div>
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="pcr-header">
                <div className="pcr-h-left">
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={() => setDatePickerOpen(true)} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleChange}
                                isClearable={loadingState ? false : true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dropdownMode="select"
                                disabled={loadingState}
                            />
                        </div>
                    </div>
                    <div className="fs-btn">
                        <CustomFormSelect
                            name={t("date_type")}
                            list={dateType.map((doc) => ({
                                label: doc.label,
                                value: doc.value,
                            }))}
                            onChange={setIsCheckDate}
                            value={isCheckDate}
                            placeholder={t('select')}
                            required={false}
                            label={t('date_type')}
                            disabled={loadingState}
                        />
                    </div>
                    {paycheckRegisterState?.error && <div className="error-holder">
                        <ApiError message={paycheckRegisterState?.error?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="pcr-h-right">
                    <div className="total-count">
                        <span>{`${t('total_count')}: `} <span className="total-count-number">{totalCount}</span> </span>
                    </div>
                    <div className="download-btn">
                        <CustomButton
                            leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                            loading={false}
                            name={t("download")}
                            enable={((paycheckRegisterState?.response?.summary.all_transactions.transactions ?? []).length > 0)
                                || ((paycheckRegisterState?.response?.summary.all_other_deductions.other_deductions ?? []).length > 0) && (loadingState ? false : true)}
                            backgroundColor={THEME.buttonColor16}
                            onClick={() => handleDownload()}
                        />
                    </div>
                    <div className="search-bar">
                        <SearchBar value={search} onChange={(value) => setSearch(value)} onSearch={() => { }} placeholder="Search for Employee,Check No,Check date,Net Check Amount,Payment Type" />
                    </div>
                </div>


            </div>
            <div className="pcr-content">
                {paycheckRegisterState.loading === LoadingType.pending ?
                    <SpinnerScreen />
                    :
                    (
                        <div>
                            {prList && prList.summary &&
                                (
                                    <PaycheckSummaryTable
                                        loading={paycheckRegisterState.loading}
                                        summaryList={prList.summary}
                                        error={paycheckRegisterState.error}
                                    />
                                )}
                            {prList && prList?.talents.length > 0 &&
                                (
                                    <PaycheckTalentTablePage
                                        loading={paycheckRegisterState.loading}
                                        prList={prList ? prList.talents : []}
                                        error={paycheckRegisterState.error}
                                        search={search}
                                        setTotalCount={(count) => setTotalCount(count)}
                                    />
                                )
                            }
                        </div>
                    )}

            </div>
        </div >
    )
}

export default PayCheckRegister;
import CurveTabNavigation from "../../../../../components/curve_tab_navigation/curve_tab_navigation";
import "./deduction_entity_dashboard.scss";
import { DeductionDetailsSection } from "./deduction_details_sections";
import { LoadingType, THEME } from "../../../../../enums";
import { EditIcon } from "../../../../../icons";
import { FC, useEffect, useState } from "react";
import { Portal } from "react-portal";
import CreateDeductionPopup from "../../../popups/create_deduction/create_deduction";
import { getDeductionById, postSnackbarMessage, selectDeductionDetailsByIdState, selectManageDeductionUpdateState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { updateDeduction } from "../../../../../redux/back_office/third_party_deductions/manage_deductions/manage_deduction_type_thunk";
import { RouteComponentProps } from "react-router-dom";
import { ThirdPartyDeductionType } from "../../../../../interfaces/third_party_deduction_type";
import { useTranslation } from "react-i18next";
import { manageDeductionActions } from "../../../../../redux/back_office/third_party_deductions/manage_deductions/manage_deduction_type_reducer";

export const DeductionEntityDetails: FC<RouteComponentProps<any>> = (
  props: RouteComponentProps<any>
) => {
  const { t } = useTranslation();
  const { location } = props;
  const { params } = props.match;
  const updateDeductionState = useAppSelector((state) => selectManageDeductionUpdateState(state));

  const [editDeduction, setEditDeduction] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (params && params.id) {
      dispatch(getDeductionById(params.id))
    }
  }, []);
  const deductionState = useAppSelector((state) =>
    selectDeductionDetailsByIdState(params.id)(state)
  );
  const [deductionInfo, setDeductionInfo] = useState<ThirdPartyDeductionType | null>();

  useEffect(() => {
    if (deductionState.loading === LoadingType.succeeded && deductionState.data) {
      setDeductionInfo(deductionState.data)
    }
  }, [deductionState.loading, deductionState.data]);

  useEffect(() => {
    if (updateDeductionState && updateDeductionState.loading === LoadingType.succeeded) {
      setEditDeduction(false);
      dispatch(postSnackbarMessage(updateDeductionState.response ?? null));
      dispatch(manageDeductionActions.clearManageDeductionState());
      dispatch(getDeductionById(params.id))
    }
  }, [updateDeductionState, updateDeductionState.loading])

  const getTabContent = () => {
    return (
      <div className="gnrl-info-container">
        <div className="gnrl-info-header">
            <span>{t("overview")}</span>
        </div>
        <div className="gnrl-info-content">
        <span>
             {deductionInfo?.description ? deductionInfo?.description : ""}
         </span>
        </div>
      </div>
    );
  };

  const getTabList = () => {
    return [
      {
        title: t("general_info").toUpperCase(),
        content: getTabContent(),
      },
    ];
  };

  function handleEdit() {
    setEditDeduction(true);
  }

  const getEditAction = () => {
    return (
      <button className="icon-btn cdp-edit-icon" onClick={handleEdit}>
        <EditIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
      </button>
    );
  };

  const handleEditDeductionSuccess = (data) => {
    setDeductionInfo(data);
    dispatch(updateDeduction(data));
  };

  return (
    <>
      <div className="deduct-dsh-container">
        <div className="deduct-dsh-left-panel">
          <DeductionDetailsSection deductionInfo={deductionInfo} />
        </div>
        <div className="deduct-dsh-right-panel">
          <CurveTabNavigation
            tabs={getTabList()}
            selected={0}
            onChange={(value) => { }}
            actions={getEditAction()}
          />
        </div>
      </div>
      {editDeduction && (
        <Portal>
          <CreateDeductionPopup
            visible={editDeduction}
            title={"Edit " + deductionInfo?.value}
            onClose={() => {
              setEditDeduction(false);
            }}
            onSubmit={handleEditDeductionSuccess}
            deductionInfo={deductionInfo}
            state={updateDeductionState}
            onClearError={()=>{
              dispatch(manageDeductionActions.clearManageDeductionStateError())
            }}
          />
        </Portal>
      )}
    </>
  );
};

import './authentication_page_holder.scss';
import kinisoIcon from '../../assets/icons/kiniso.svg';
import { LoadingType } from '../enums';
import { SpinnerScreen } from '../utils';
interface Props {
    history?: any,
    location?: any,
    children: any,
    onChange?: (id: string, value: any) => void,
    agencyLogoURL: string,
    agencyLogoLoading: LoadingType,
    searchParams: any;
};
const AuthenticationPageHolder = (props: Props) => {

    const getBackgroundURL = () => {
        if (!props.searchParams || props.searchParams.toString().length === 0) {
            // If searchParams is null, return kinisoIcon
            return kinisoIcon;
        }

        // If searchParams exists, check agencyLogoURL
        return props.agencyLogoURL?.trim() ? props.agencyLogoURL : kinisoIcon;
    };
    return (
        <div className="aut-page-container">
            <div className="aut-page-container">
                <div className="aut-lotty-container">
                    <div className="aut-lotty-wrapper">
                        {/* <div className="aut-lotty-extra-space"></div> */}
                        <div className="aut-users-lotty">
                            {(!props.searchParams || props.agencyLogoLoading === LoadingType.failed )? (
                                <div
                                    className="background-img aut-users-svg"
                                    style={{
                                        backgroundImage: `url(${kinisoIcon})`,
                                    }}
                                ></div>
                            ) : props.agencyLogoLoading === LoadingType.pending ? (
                                <SpinnerScreen />
                            ) : props.agencyLogoLoading === LoadingType.succeeded ? (
                                <div
                                    className="background-img aut-users-svg"
                                    style={{
                                        backgroundImage: `url(${getBackgroundURL()})`,
                                    }}
                                ></div>
                            ) : null }
                        </div>
                    </div>

                    <div className="aut-wrapper">
                        <div className="aut-shape-wrapper">
                            {/* <div className="aut-shape-pyramid"></div> */}
                            <div className="aut-holder">
                                {props.children}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default AuthenticationPageHolder;
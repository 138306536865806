import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError } from "../../../../../interfaces";
import { TalentPipeline } from "../../../../../interfaces/talent_pipeline";
import * as thunk from "./talent_pipeline_thunk";

export interface TalentPipelineState {
    error?: AppError | null,
    loading: LoadingType,
    response: TalentPipeline[] | null,
    matchRating: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null,
    },
    pipelineStatus: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null,
    },
    moveQue: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null,
    },
    moveTrash: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null,
    }
}

const initialState: TalentPipelineState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    matchRating: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    pipelineStatus: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    moveQue: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    moveTrash: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}


export const talentPipelineSlice = createSlice({
    name: 'talentPipelineDetails',
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...state, ...initialState };
        },
        clearMoveQueState(state) {
            return { ...state, moveQue: { ...initialState.moveQue } };
        },
        clearMoveTrashState(state) {
            return { ...state, moveTrash: { ...initialState.moveTrash } };
        },
        clearMoveQueError(state) {
            return { ...state, moveQue: { ...state.moveQue, error: null } };
        },
        clearMoveTrashError(state) {
            return { ...state, moveTrash: { ...state.moveTrash, error: null } };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTalentPipelineDataThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getTalentPipelineDataThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.error, }))
            .addCase(thunk.getTalentPipelineDataThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addCase(thunk.updateTalentMatchRatingThunk.pending, (state, action) => ({ ...state, matchRating: { ...state.matchRating, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateTalentMatchRatingThunk.rejected, (state, action) => ({ ...state, matchRating: { ...state.matchRating, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.updateTalentMatchRatingThunk.fulfilled, (state, action) => ({ ...state, matchRating: { ...state.matchRating, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updatePipelineStatus.pending, (state, action) => ({ ...state, pipelineStatus: { ...state.pipelineStatus, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updatePipelineStatus.rejected, (state, action) => ({ ...state, pipelineStatus: { ...state.pipelineStatus, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.updatePipelineStatus.fulfilled, (state, action) => ({ ...state, pipelineStatus: { ...state.pipelineStatus, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.multipleTalentsQueueThunk.pending, (state, action) => ({ ...state, moveQue: { ...state.moveQue, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.multipleTalentsQueueThunk.rejected, (state, action) => ({ ...state, moveQue: { ...state.moveQue, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.multipleTalentsQueueThunk.fulfilled, (state, action) => ({ ...state, moveQue: { ...state.moveQue, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.multipleTalentsTrashThunk.pending, (state, action) => ({ ...state, moveTrash: { ...state.moveTrash, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.multipleTalentsTrashThunk.rejected, (state, action) => ({ ...state, moveTrash: { ...state.moveTrash, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.multipleTalentsTrashThunk.fulfilled, (state, action) => ({ ...state, moveTrash: { ...state.moveTrash, loading: LoadingType.succeeded, error: null, response: action.payload } }))



            .addDefaultCase(state => ({ ...state }));
    }
});

export const pipelineSliceActions = talentPipelineSlice.actions;
export const selectState = (state: TalentPipelineState) => state;



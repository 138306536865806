import interceptor from "./interceptor";
import axios from "axios";
import UrlBuilder from "./url_builder";
import { IProfilePictureRequestQuery, IProfilePictureDownloadRequestQuery, IResumeFileDownloadRequestQuery, IAgencyProfilePictureRequestQuery } from "../interfaces";

export const S3API = {
    uploadFile(url: string, file, onProgress?: (event: any) => void) {
        return axios.put(url, file, { onUploadProgress: onProgress })
    },

    getFileExtention(fileName: string | undefined) {
        if (!fileName) return "mp4";
        const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
        return extension !== fileName ? extension : "mp4";
    },

    uploadWithPreSignedURL(url, data) {
        return axios.put(url, data)
            .then((res) => {
                return res; // Ensure this returns the response
            })
            .catch((error) => {
                throw error;
            });
    },

    getPreSignedURLForUpload(query: IProfilePictureRequestQuery) {
        return interceptor.get(`${UrlBuilder.preSignedURLForUpload}?object_id=${query.object_id}&object_type=${query.object_type}&file_name=${query.file_name}`)
    },

    getPreSignedURLForDownload(query: IProfilePictureDownloadRequestQuery) {
        return interceptor.get(`${UrlBuilder.preSignedURLForDownload}?object_id=${query.object_id}&object_type=${query.object_type}`)
    },
    getFileUrlForDownload(query: IResumeFileDownloadRequestQuery) {
        return interceptor.get(`${UrlBuilder.fileDownLoad}?file_path=${query.file_path}`)
    },

    uploadAgencyOnboardingHandbookFile(url: string, data: any) {
        return axios.put(url, data);
    },
    getAgencyOnboardingHandbookDownloadURL() {
        return interceptor.get(`${UrlBuilder.adminCenterAgencyOnBoardingHandbookDownloadURL}`);
    },

    getAgencyProfilePictureUrlForUpload(query: IAgencyProfilePictureRequestQuery) {
        return interceptor.get(`${UrlBuilder.agencyLogoUploadUrl}?logo_file_name=${query.file_name}`)
    },
    
    downloadFile(file_path: string) {
        return interceptor.get(`${UrlBuilder.fileDownLoad}?file_path=${file_path}`)
    },

    viewFileURL(file_path: string){
        return interceptor.get(`${UrlBuilder.fileView}?file_path=${file_path}`)
    }
}

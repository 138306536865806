import { Rating } from "@material-ui/lab";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { JobOrderAPI } from "../../../../apis";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../components/api_error";
import { DateInput } from "../../../../components/date_input/date_input";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import SearchBar from "../../../../components/search_bar";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableFilterEmpty,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { TimeInput } from "../../../../components/time_input/time_input";
import { TALENT_STATUS, THEME } from "../../../../enums";
import { LoadingType } from "../../../../enums/loading";
import { AssignIcon, TalentActiveStatusIcon } from "../../../../icons";
import {
  Assignment,
  IJobOrder,
  IJobOrderDetail,
  IJobServiceProfile,
  ITalent,
} from "../../../../interfaces";
import {
  getWorkCompCodes,
  getWorkCompConfigList,
  selectWorkCompCodeList,
  selectWorkCompCodeState,
  selectWorkCompConfigList,
  selectWorkCompConfigListState,
} from "../../../../redux/admin_center";
import { getAssignmentsList } from "../../../../redux/assignments/universal_directory/assignments_list/assignments_list_thunk";
import {
  manageAssignmentActions,
  manageAssignmentSlice,
  selectCreateState,
} from "../../../../redux/assignments/universal_directory/manage_assignment/manage_assignment_reducer";
import {
  createAssignment,
  updateAssignment,
} from "../../../../redux/assignments/universal_directory/manage_assignment/manage_assignment_thunk";
import {
  getAssignmentById,
  getBusinessSectors,
  getCompaniesList,
  getContactsByCompany,
  getDepartmentsList,
  getJobsListThunk,
  getProfile,
  getTalentList,
  postSnackbarMessage,
  selectCompaniesList,
  selectCompaniesListState,
  selectCompanyContactsList,
  selectContactsListState,
  selectJobsList,
  selectJobsListState,
  selectManageAssignmentCreateState,
  selectManageAssignmentState,
  selectProfileState,
  selectTalentList,
  selectTalentListEntities,
  selectTalentListState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  CustomCheckBox,
  FormInput,
  convertNumberToMoney,
  formatToMoney,
  allowNumbers
} from "../../../../utils";
import {
  getStatusFromICreateState,
  mapOptions,
} from "../../../../utils/helpers";
import { SpinnerScreen } from "../../../../utils/spinner";
import "../../../admin_center/forms/user_form/user_form.scss";
import "./create_assignment_popup.scss";
import TimePickerComponent from "../../../../components/time_picker/time_picker";
import { Timer } from "@material-ui/icons";
import {
  convertStringTimeToTimestamp,
  convertTimestampToStringTime,
  timestampToStringDate,
  payFeePlacementType
} from "../../../../variables";

import { FormControl,FormControlLabel,RadioGroup,Radio } from "@material-ui/core";



export interface CreateAssignmentPopupProps {
  showModal: boolean;
  assignmentInfo: Assignment;
  callbackHandler: Function;
  isFromJobs: boolean;
  selectedJobTalent: ITalent | IJobOrderDetail | null;
  selectedTalent?: ITalent;
}

const CreateAssignmentPopup: FC<CreateAssignmentPopupProps> = ({
  showModal,
  assignmentInfo,
  callbackHandler,
  isFromJobs,
  selectedJobTalent,
  selectedTalent,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [assignment, setAssignment] = useState<Assignment>(assignmentInfo);
  const actionRef = useRef<ActionDialogHolderType>(null);
  const [search, setSearch] = useState("");
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [list, setList] = useState<(ITalent | IJobOrder)[]>([]);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [showNextPanel, setShowNextPanel] = useState<boolean>(
    selectedTalent ? true : false
  );
  const [showCreationPanel, setShowCreationPanel] = useState<boolean>(false);
  const [selectedListItem, setSelectedListItem] = useState<
    ITalent | IJobOrder
  >();
  const [listError, setListError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [endDateValid, setEndDateValid] = useState(true);
  const [startDateValid, setStartDateValid] = useState(true);

  const companiesListState = useAppSelector((state) =>
    selectCompaniesListState(state)
  );
  const companies = useAppSelector((state) => selectCompaniesList(state));

  const talentListState = useAppSelector((state) =>
    selectTalentListState(state)
  );
  const talentList = useAppSelector((state) => selectTalentList(state));
  const talentEntitites = useAppSelector((state) =>
    selectTalentListEntities(state)
  );
  const jobListState = useAppSelector((state) => selectJobsListState(state));
  const jobList = useAppSelector((state) => selectJobsList(state));

  const assignmentManageState = useAppSelector((state) =>
    selectManageAssignmentState(state)
  );
  const createState = assignmentManageState.create;
  const updateState = assignmentManageState.update;
  const contactsList = useAppSelector((state) =>
    selectCompanyContactsList(state)
  );
  const contactsListState = useAppSelector((state) =>
    selectContactsListState(state)
  );
  // const workCompCodeState = useAppSelector((state) =>
  //   selectWorkCompCodeState(state)
  // );
  // const workCompCodeList = useAppSelector((state) =>
  //   selectWorkCompCodeList(state)
  // );
  const workCompCodeState = useAppSelector((state) =>
    selectWorkCompConfigListState(state)
  );
  const workCompCodeList = useAppSelector((state) =>
    selectWorkCompConfigList(state)
  );
  const { profile } = useAppSelector((state) => selectProfileState(state));
  const history = useHistory();

  const talentTableHeader = [
    { title: "", code: "" },
    { title: "name", code: "first_name" },
    { title: "job_title", code: "title" },
    { title: "on_assignment", code: "on_assignment_company" },
    { title: "status", code: "status" },
    { title: "address", code: "street_address" },
    { title: "city", code: "city" },
    { title: "state", code: "state" },
    { title: "zip_code", code: "zip_code" },
    { title: "ssn_hash", code: "last_4_digits_of_ssn" },
  ];

  const jobtableHeader = [
    { title: "", code: "" },
    { title: "Company", code: "company_name" },
    { title: "Department", code: "department_name" },
    { title: "Job Title", code: "job_title" },
    { title: "Rating", code: "rating" },
    { title: "Status", code: "job_status" },
    { title: "Pay Rate", code: "regular_pay_rate" },
    { title: "Bill Rate", code: "regular_bill_rate" },
    { title: "WC Code", code: "wc_code" },
    { title: "Requested", code: "requested" },
    { title: "Placed", code: "placed" },
    { title: "Still Needed", code: "over_under_placed" },
    { title: "", code: "access_job" },
  ];

  

  useEffect(() => {
    if (createState.loading == LoadingType.succeeded) {
      dispatch(
        postSnackbarMessage(getStatusFromICreateState(createState.response))
      );
      dispatch(getAssignmentsList());
      dispatch(manageAssignmentActions.clearManageAssignmentState([]));
      setShowCreationPanel(true);
      callbackHandler();
    }
    if (updateState.loading == LoadingType.succeeded) {
      dispatch(
        postSnackbarMessage(getStatusFromICreateState(updateState.response))
      );
      dispatch(getAssignmentById({ assignment_id: assignmentInfo.id }));
      dispatch(manageAssignmentActions.clearManageAssignmentState([]));
      callbackHandler();
      setShowCreationPanel(true);
    }
  }, [createState.loading, updateState.loading]);

  useEffect(() => {
    if (isFromJobs) {
      if (talentListState.loading === LoadingType.succeeded) {
        setList(talentList);
        setListLoading(false);
      } else if (talentListState.loading === LoadingType.pending) {
        setListLoading(true);
      }
    } else {
      if (jobListState.loading === LoadingType.succeeded) {
        setList(jobList);
        setListLoading(false);
      } else if (talentListState.loading === LoadingType.pending) {
        setListLoading(true);
      }
    }
  }, [talentListState.loading, jobListState.loading]);

  useEffect(() => {
    dispatch(getCompaniesList());
    dispatch(getDepartmentsList());
    dispatch(getBusinessSectors());
    // dispatch(getWorkCompCodes());
    dispatch(getWorkCompConfigList());
    dispatch(getProfile());
    getList();
  }, []);

  useEffect(() => {
    if (selectedTalent) {
      setSelectedListItem(selectedTalent);
      handleAssignment();
    }
  }, [selectedTalent]);

  useEffect(() => {
    if (talentListState.error != null || jobListState.error != null) {
      setListError(true);
    }
  }, [talentListState.error, jobListState.error]);

  const updateAssignmentProp = (key: string, value: any) => {
    const newAssignment = { ...assignment };
    newAssignment[key] = value;
    setAssignment(newAssignment);
  };

  const handlePayRateChange = (id, value) => {
    const payRate = value.replace(/,/g, ""); // Remove commas from input value
    if (payRate.trim() === "") {
      setAssignment({
        ...assignment,
        regular_pay_rate: "",
        double_time_pay_rate: "0.00", // Set bill rate to '0.00' if pay rate is empty
        overtime_pay_rate: "0.00", // Set bill rate to '0.00' if pay rate is empty
      });
    } else if (!isNaN(payRate)) {
      const doubleTimePayRate = parseFloat(payRate) * 2;
      const overTimePayRate = parseFloat(payRate) * 1.5;
      setAssignment({
        ...assignment,
        regular_pay_rate: value,
        double_time_pay_rate: isNaN(doubleTimePayRate)
          ? ""
          : (convertNumberToMoney(doubleTimePayRate, "string") as string),
        overtime_pay_rate: isNaN(overTimePayRate)
          ? ""
          : (convertNumberToMoney(overTimePayRate, "string") as string),
      });
    }
  };

  const handleBillRateChange = (id, value) => {
    const regularbillRate = value.replace(/,/g, ""); // Remove commas from input value
    if (regularbillRate.trim() === "") {
      setAssignment({
        ...assignment,
        regular_bill_rate: "",
        double_time_bill_rate: "0.00", // Set bill rate to '0.00' if pay rate is empty
        overtime_bill_rate: "0.00", // Set bill rate to '0.00' if pay rate is empty
      });
    } else if (!isNaN(regularbillRate)) {
      const doubleTimeBillRate = parseFloat(regularbillRate) * 2;
      const overTimeTimeBillRate = parseFloat(regularbillRate) * 1.5;
      setAssignment({
        ...assignment,
        regular_bill_rate: value,
        double_time_bill_rate: isNaN(doubleTimeBillRate)
          ? ""
          : (convertNumberToMoney(doubleTimeBillRate, "string") as string),
        overtime_bill_rate: isNaN(overTimeTimeBillRate)
          ? ""
          : (convertNumberToMoney(overTimeTimeBillRate, "string") as string),
      });
    }
  };

  const toggle = () => {
    callbackHandler();
    handleClearError();
  };

  const handleSave = () => {
    const startTimeTimeStampValue = convertStringTimeToTimestamp(
      assignment?.shift_start! as string
    );
    const endTimeTimeStampValue = convertStringTimeToTimestamp(
      assignment?.shift_end! as string
    );
    const payload = {
      ...assignment,
      regular_bill_rate: convertNumberToMoney(
        assignment.regular_bill_rate,
        "number"
      ) as number,
      regular_pay_rate: convertNumberToMoney(
        assignment.regular_pay_rate,
        "number"
      ) as number,
      double_time_bill_rate: convertNumberToMoney(
        assignment.double_time_bill_rate,
        "number"
      ) as number,
      double_time_pay_rate: convertNumberToMoney(
        assignment.double_time_pay_rate,
        "number"
      ) as number,
      overtime_bill_rate: convertNumberToMoney(
        assignment.overtime_bill_rate,
        "number"
      ) as number,
      overtime_pay_rate: convertNumberToMoney(
        assignment.overtime_pay_rate,
        "number"
      ) as number,
      shift_start: startTimeTimeStampValue! / 1000,
      shift_end: endTimeTimeStampValue! / 1000,
    };
    if (assignment && assignment.id) {
      dispatch(updateAssignment(payload));
    } else {
      dispatch(createAssignment({ forceAdd: false, value: payload }));
    }
  };

  const showSpinner = (): boolean =>
    talentListState.loading === LoadingType.pending ||
    jobListState.loading === LoadingType.pending ||
    contactsListState.loading === LoadingType.pending ||
    workCompCodeState.loading === LoadingType.pending ||
    listLoading ||
    loading;

  const closePopup = (action?: () => void) => {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  };

  const getError = (): any =>
    !isFromJobs ? jobListState.error : talentListState.error;

  const getList = () => {
    dispatch(getTalentList());
    dispatch(getJobsListThunk());
  };

  const getEmptyMessage = () =>
    !isFromJobs ? t("no_jobs_found") : t("no_talent_found");

  const getFilteredList = () => {
    let sortedList = list;
    if (sortedField != null) {
      sortedList = [...list].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] != undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }
    return sortedList.filter(!isFromJobs ? getJobfilter : getTalentFilter);
  };

  const getJobfilter = (doc) => {
    const str = search.trim().toLowerCase();
    const companyFilter = doc.company_name?.trim().toLowerCase().includes(str);
    const departmentFilter = doc.department_name
      ? doc.department_name?.trim().toLowerCase().includes(str)
      : false;
    const jobFilter = doc.job_title
      ? doc.job_title?.trim().toLowerCase().includes(str)
      : false;
    return companyFilter || departmentFilter || jobFilter;
  };

  const getTalentFilter = (doc) => {
    const str = search.trim().toLowerCase();
    const nameFilter = `${doc.first_name ?? ""} ${doc.middle_name ?? ""} ${
      doc.last_name ?? ""
    }`
      .trim()
      .toLowerCase()
      .includes(str);
    const cityFilter = doc.city
      ? doc.city?.trim().toLowerCase().includes(str)
      : false;
    const stateFilter = doc.state
      ? doc.state?.trim().toLowerCase().includes(str)
      : false;
    const zipcodeFilter = doc.zip_code
      ? doc.zip_code?.trim().toLowerCase().includes(str)
      : false;
    return nameFilter || cityFilter || stateFilter || zipcodeFilter;
  };

  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      // setSortedField(null);
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  const getTableHeader = () =>
    !isFromJobs ? jobtableHeader : talentTableHeader;

  const getRatingCell = (value: number) => {
    return <Rating name="read-only" value={value} readOnly />;
  };

  const getNameField = (doc: ITalent) => {
    return (
      <div className="tlnt-ud-table-name">
        <div className="status-icon">
          <TalentActiveStatusIcon
            width={"20px"}
            height={"20px"}
            style={{
              color: !!doc.on_assignment_company
                ? THEME.buttonColor13
                : THEME.statusDisabledColor,
            }}
          />
        </div>
        <div className="name-txt">
          <span>{`${doc.first_name ?? ""} ${doc.middle_name ?? ""} ${
            doc.last_name ?? ""
          }`}</span>
        </div>
      </div>
    );
  };

  const talentStatus = (status: string) => {
    if (status === TALENT_STATUS.active)
      return <span style={{ color: THEME.buttonColor17 }}>{t("active")}</span>;
    else if (status === TALENT_STATUS.inactive)
      return <span style={{ color: "#b1b1b1" }}>{t("in_active")}</span>;
    else return <span>{""}</span>;
  };

  const getCurrentPanel = () => {
    if (!showNextPanel) return getFirstPanel();
    return getSecondPanel();
  };

  const mapAssignmentDetails = async () => {
    const details = isFromJobs ? selectedJobTalent : selectedListItem;
    let job = details as IJobOrderDetail;
    const tDetails = !isFromJobs ? selectedJobTalent : selectedListItem;
    let talent = tDetails as ITalent;
    if (!!selectedTalent) {
      talent = talentEntitites[selectedTalent["talent_id"]] as ITalent;
    }
    const results = await getCompleteJobDetails(job.id);
    job = results[0].data as any as IJobOrderDetail;
    const jobServiceProfile = results[1].data as any as IJobServiceProfile;
    const newAssignment: Assignment = {
      id_number: job.id_number ?? "",
      job_order_id_number: job.job_order_id_number,
      agency_id: job.agency_id ?? "",
      assignment_end_reason_id: "",
      branch_id: job.branch_id ?? "",
      company_id: job.company_id ?? "",
      company_name: job.company_name,
      contact_id: job.report_to_contact_id,
      contact_name: job.report_to_contact_name,
      department_id: job.department_id ?? "",
      department_name: job.department_name,
      double_time_bill_rate: job.double_time_bill_rate,
      double_time_pay_rate: job.double_time_pay_rate,
      end_date: job.end_date,
      id: "",
      is_deleted: false,
      job_order_id: job.id ?? "",
      job_title: job.job_title,
      mark_up_factor: 0,
      overtime_bill_rate: job.overtime_bill_rate,
      overtime_pay_rate: job.overtime_pay_rate,
      performance_rating: 0,
      regular_bill_rate: job.regular_bill_rate,
      regular_pay_rate: job.regular_pay_rate,
      service_calls: [],
      shift_break: jobServiceProfile.break_in_minutes?.toString() ?? "",
      shift_end:
        convertTimestampToStringTime(jobServiceProfile?.end_time! * 1000) ??
        null,
      shift_hours: jobServiceProfile.estimated_shift_hours,
      shift_name: jobServiceProfile.shift_name ?? "",
      shift_start:
        convertTimestampToStringTime(jobServiceProfile?.start_time! * 1000) ??
        null,
      start_date: job.start_date,
      status: TALENT_STATUS.active,
      talent_id: talent.id ?? "",
      talent_name: talent.first_name + " " + talent.last_name,
      triple_time_bill_rate: job.triple_time_bill_rate,
      triple_time_pay_rate: job.triple_time_pay_rate,
      wc_code: job.wc_code,
      wc_code_id:
        workCompCodeList.find((i) => i.work_comp_code === job.wc_code)?.id ??
        null,
      work_schedule: {
        MONDAY: true,
        TUESDAY: true,
        WEDNESDAY: true,
        THURSDAY: true,
        FRIDAY: true,
        SATURDAY: true,
        SUNDAY: true,
      },
      account_managers: [],
      recruiters: [],
      fee_amount: job.fee_amount ?? 0,
      fee_type: job.fee_type ?? "flat_rate",
      pay_type : job.pay_type ?? "hourly",
      fee_type_flat_rate: job.fee_type_flat_rate ?? 0,
      fee_type_percentage: job.fee_type_percentage ?? 0,
      hourly_pay_type: job.hourly_pay_type ?? 0,
      salary_pay_type : job.salary_pay_type ?? 0,
      placement_type_value: job.placement_type_value
    };
    dispatch(
      getContactsByCompany({
        company_id: newAssignment.company_id,
        department_id: newAssignment.department_id ?? null,
      })
    );
    setAssignment(newAssignment);
    setLoading(false);
    setShowNextPanel(true);
  };

  const getCompleteJobDetails = (id) => {
    return Promise.all([
      JobOrderAPI.getJobOrder(id),
      JobOrderAPI.getJobOrderServiceProfile(id),
    ]);
  };

  function handleClearError() {
    dispatch(manageAssignmentActions.clearCreateAssignmentState());
  }

  function getStatus(status: string) {
    if (status === "OPEN")
      return (
        <span style={{ color: THEME.statusInActiveColor }}>{t("open")}</span>
      );
    else if (status === "FILLED")
      return <span style={{ color: THEME.buttonColor17 }}>{t("filled")}</span>;
    else if (status === "CANCELLED_BY_CLIENT")
      return <span style={{ color: "#B1B1B1" }}>{t("cancel_by_client")}</span>;
    else if (status === "MISTAKEN_ORDER")
      return <span style={{ color: "#B1B1B1" }}>{t("mistaken_order")}</span>;
    else if (status === "CLOSED")
      return <span style={{ color: "#B1B1B1" }}>{t("closed")}</span>;
    else return <span>{""}</span>;
  }

  const getDateTime = (timestamp) => {
    if (!timestamp) return timestamp;
    let date = new Date(timestamp * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const getProp = (propertyName: string) => {
    if (isFromJobs && selectedJobTalent) {
      return selectedJobTalent[propertyName];
    } else {
      return selectedListItem ? selectedListItem[propertyName] : "";
    }
  };

  const handleAssignment = () => {
    setLoading(true);
    mapAssignmentDetails();
  };

  const handleClearTime = (id: string) => {
    if (id === "shift_start") {
      setAssignment({ ...assignment, shift_start: "" });
    }
    if (id === "end_time") {
      setAssignment({ ...assignment, shift_end: "" });
    }
  };

  const accessJob = (value: IJobOrder) => {
    history.push({
      pathname: `${AppRoutes.jobDetailsPage}/${value.id}`,
      state: {
        id: value.id,
        name: value.job_title,
      },
    });
  };

  const getJobTableCells = (item: IJobOrder) => {
    return (
      <>
        <TableData customStyle={{ flex: getFlexNumber(1) }}>
          <span>{item.company_name}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(2) }}>
          <span>{item.department_name ?? t("corporate")}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(3) }}>
          <span>{item.job_title}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(4) }}>
          <span>{getRatingCell(item.rating)}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(5) }}>
          <span>{item.job_status ?? ""}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(6) }}>
          <span>{item.regular_pay_rate}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(7) }}>
          <span>{item.regular_bill_rate}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(8) }}>
          <span>{item.wc_code ? item.wc_code : "NA"}</span>
        </TableData>
        <TableData
          customStyle={{
            flex: getFlexNumber(9),
            color: THEME.statusInActiveColor,
            paddingLeft: "1em",
          }}>
          <span>{item.requested}</span>
        </TableData>
        <TableData
          customStyle={{ flex: getFlexNumber(10), color: THEME.buttonColor17 }}>
          <span>{item.placed}</span>
        </TableData>
        <TableData
          customStyle={{
            flex: getFlexNumber(11),
            color: THEME.statusInActiveColor,
          }}>
          <span>{item.over_under_placed}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(12) }}>
          <CustomButton
            textStyle={{
              textTransform: "capitalize",
              marginLeft: 0,
              marginRight: 0,
              textAlign: "center",
            }}
            enable={true}
            name={t("access_job")}
            onClick={() => accessJob(item)}
            fontSize={12}
            backgroundColor={THEME.buttonColor4}
          />
        </TableData>
      </>
    );
  };

  const getTalentTableCells = (item: ITalent) => {
    return (
      <>
        <TableData customStyle={{ flex: getFlexNumber(1) }}>
          <span>{getNameField(item)}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(2) }}>
          <span>{item.title}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(3) }}>
          <span>{item.on_assignment_company}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(4) }}>
          <span>{item.status ?? ""}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(5) }}>
          <span>{item.street_address}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(6) }}>
          <span>{item.city ?? ""}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(7) }}>
          <span>{item.state ?? ""}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(8) }}>
          <span>{item.zip_code ?? ""}</span>
        </TableData>
        <TableData customStyle={{ flex: getFlexNumber(9) }}>
          <span>{item.last_4_digits_of_ssn ?? ""}</span>
        </TableData>
      </>
    );
  };

  function getFlexNumber(value: number) {
    if (value === 0) return 0.5;
    if (value === 4) return 0.6;
    //if (value === 5) return 0.7;
    if (value === 6) return 0.7;
    if (value === 7) return 0.5;
    if (value === 8) return 0.5;
    if (value === 9) return 0.5;
    if (value === 10) return 0.5;
    if (value === 11) return 0.5;
    if (value === 12) return 1.5;
    return 1;
  }

  const getTable = () => {
    if (showSpinner()) {
      return <TableLoading />;
    }
    if (listError && list.length === 0) {
      return <TableErrorHandler error={getError()} onRefresh={getList} />;
    }
    if (list.length === 0) {
      return <TableEmpty title={getEmptyMessage()} onClick={() => getList()} />;
    }
    if (getFilteredList().length === 0) {
      return <TableFilterEmpty title={getEmptyMessage()} />;
    }
    return (
      <SortableTable
        headerList={getTableHeader()}
        sortedField={sortedField}
        onSortChange={handleSortFieldChange}
        flexNumber={getFlexNumber}
        isAsc={sortDirection}>
        {getFilteredList().map((doc) => {
          return (
            <tr
              className="list-grid-row"
              key={doc.id}
              onClick={() => setSelectedListItem(doc)}>
              <TableData customStyle={{ flex: getFlexNumber(0) }}>
                <CustomCheckBox
                  name={""}
                  title={""}
                  checked={doc.id === selectedListItem?.id}
                  onClick={() => setSelectedListItem(doc)}
                />
              </TableData>
              {isFromJobs
                ? getTalentTableCells(doc as ITalent)
                : getJobTableCells(doc as IJobOrder)}
            </tr>
          );
        })}
      </SortableTable>
    );
  };

  const getFirstPanel = () => {
    return (
      <div className="create-assignment-panel-1">
        {/* <div>
          <AssignIcon
            width={"45px"}
            height={"45px"}
            style={{ color: THEME.assignmentsOptionTextColor }}
          />
        </div> */}
        <div className="assignment_popup_container">
          {/* <h2>{t("assignment_popup_header", { name: profile?.first_name })}</h2>
          <h4>{t("assignment_popup_subheader")}</h4> */}
          <FormDialogTilteHeader
            title={t("assignment_popup_header", { name: profile?.first_name })}
            subTitle={t("assignment_popup_subheader")}
            titleIcon={<AssignIcon width={"100%"} height={"100%"} />}
          />
          <div className="assignment_popup_table">
            <div className="search-header">
              {isFromJobs ? t("search_talent") : t("search_jobs")}
            </div>
            <SearchBar
              value={search}
              onChange={(value) => setSearch(value)}
              onSearch={() => null}
            />
            {getTable()}
          </div>
          <div className="uf-actions">
            <div className="btn-save">
              <CustomButton
                loading={
                  createState.loading === LoadingType.pending ||
                  updateState.loading === LoadingType.pending
                }
                textStyle={{ textTransform: "none" }}
                name={t("assign_this_person_to_job_order")}
                enable={!!selectedListItem}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={handleAssignment}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleFeeStruChange = (fieldId: string, value: any) => {
    if (fieldId === "pay_type") {
      if (value === "hourly") {
        setAssignment({
          ...assignment, [fieldId]: value, salary_pay_type: 0,
          fee_type: assignment.fee_type === "percentage" ? "" : assignment.fee_type,
          fee_type_percentage: assignment.fee_type === "percentage" ? 0 : assignment.fee_type_percentage,
          fee_amount: assignment.fee_type === "percentage" ? 0 : assignment.fee_amount,

        });
      }
      else {
        setAssignment({ ...assignment, [fieldId]: value, hourly_pay_type: 0 });
      }
    }
    else if (fieldId === "fee_type") {

      if (value === "percentage") {
        setAssignment({ ...assignment, [fieldId]: value, fee_type_flat_rate: 0, fee_amount: 0 });
      }
      else {
        setAssignment({ ...assignment, [fieldId]: value, fee_type_percentage: 0, fee_amount: 0 });
      }
    }
    else if (fieldId === "salary_pay_type") {
      const sanitizedValue = value.replace(/,/g, '');
      const fee_amount_val = parseFloat(sanitizedValue) * (assignment.fee_type_percentage! / 100);
      setAssignment({ ...assignment, [fieldId]: sanitizedValue, fee_amount: isNaN(fee_amount_val) ? 0 : fee_amount_val });

    }
    else if (fieldId === "fee_type_percentage") {
      if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
        const salaryPayType = assignment.salary_pay_type !== undefined
          ? parseFloat(assignment.salary_pay_type.toString().replace(/,/g, ''))
          : 0;

        if (!isNaN(salaryPayType)) {
          const fee_amount_val = salaryPayType * (parseFloat(value) / 100);
          setAssignment({ ...assignment, [fieldId]: value, fee_amount: isNaN(fee_amount_val) ? 0 : fee_amount_val });
        }
      }
    }
    else if (fieldId === "fee_type_flat_rate") {
      setAssignment({ ...assignment, [fieldId]: value, fee_amount: value });
    }
    else {
      setAssignment({ ...assignment, [fieldId]: value });
    }
  }

  const getSecondPanel = () => {
    return (
      <div className="create-assignment-panel-1">
        {/* <div>
          <AssignIcon
            width={"45px"}
            height={"45px"}
            style={{ color: THEME.assignmentsOptionTextColor }}
          />
        </div> */}
        <div className="assignment_popup_container">
          {/* <h2>{t("keep_on")}</h2>
          <h4>{t("getting_close")}</h4> */}
          <FormDialogTilteHeader
            title={t("keep_on", { name: profile?.first_name })}
            subTitle={t("getting_close")}
            titleIcon={<AssignIcon width={"100%"} height={"100%"} />}
          />
          {getForm()}
          {/* {createState.error && <div className={"error-section"}>
            <ApiError message={createState.error.message} onClose={handleClearError} />
          </div>} */}
        </div>
      </div>
    );
  };

  const getForm = () => {
    return (
      <div className="form-content">
        <div className="form-row">
          <div className="form-item">
            <FormInput
              id={"talent"}
              onChange={() => {}}
              type={"text"}
              value={assignment.talent_name}
              label={t("talent")}
              placeholder=""
              disabled
            />
          </div>
          <div className="form-item">
            <FormInput
              id={"company"}
              onChange={() => {}}
              type={"text"}
              value={assignment.company_name}
              label={t("company")}
              placeholder=""
              disabled
            />
          </div>
          <div className="form-item">
            <FormInput
              id={"department"}
              onChange={() => {}}
              type={"text"}
              value={assignment.department_name}
              label={t("department")}
              placeholder={t("Corporate")}
              disabled
            />
          </div>
          <div className="form-item">
            <FormInput
              id={"job_title"}
              onChange={() => {}}
              type={"text"}
              value={assignment.job_title}
              label={t("job_title")}
              placeholder=""
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-item" style={{ marginTop: "auto" }}>
            <div className="sub-label">{t("start_date")}</div>
            <DateInput
              id={"start_date"}
              onChange={updateAssignmentProp}
              placeholder={""}
              value={assignment.start_date}
              setHoursToZero={true}
            />
          </div>
          <div className="form-item" style={{ marginTop: "auto" }}>
            <div className="sub-label">{t("end_date")}</div>
            <DateInput
              id={"end_date"}
              onChange={updateAssignmentProp}
              placeholder={""}
              value={assignment.end_date}
              setHoursToZero={true}
            />
          </div>
          <div className="form-item">
            <CustomFormSelect
              name="contact_id"
              value={assignment?.contact_id}
              label={"report_to"}
              placeholder={t("select_placeholder")}
              list={mapOptions(contactsList.data ?? [], "contact_name", "id")}
              onChange={(value) => updateAssignmentProp("contact_id", value)}
            />
          </div>
          <div className="form-item">
            <CustomFormSelect
              name="wc_code_id"
              value={assignment?.wc_code_id}
              label={"wc_code"}
              placeholder={t("select_placeholder")}
              list={mapOptions(workCompCodeList ?? [], "work_comp_code", "id")}
              onChange={(value) => updateAssignmentProp("wc_code_id", value)}
            />
          </div>
        </div>
        <div className="form-row">
          {payFeePlacementType.includes(assignment.placement_type_value)?(
            <>
            <div className="job-order-column col1">
            <div className="ac-sc-payroll-fields">
                  <p className="ac-service-text">FEE STRUCTURE</p>
                  <SectionHolder title={t("rate")} assignmentDetails = {assignment} >
                    <div className="fee_structure">
                      <FormControl component="fieldset">
                        <RadioGroup aria-required aria-label="fee_type" name="fee_type" value={assignment.fee_type}
                          onChange={(event) => {
                            handleFeeStruChange("fee_type", event.target.value)
                          }}>
                          <div className="radio-btn fc">
                            <FormControlLabel value="flat_rate" control={<Radio />} label={t("flat_rate")} />
                            <div className="radio-i">
                              <FormInput
                                id={"fee_type_flat_rate"}
                                onChange={handleFeeStruChange}
                                type={"text"}
                                value={convertNumberToMoney(assignment.fee_type_flat_rate, "string") as string}
                                label={t("")}
                                placeholder=""
                                onBlur={formatToMoney}
                                onKeyDown={formatToMoney}
                                onKeyUp={formatToMoney}
                                prefix={<span>$</span>}
                                disabled={assignment.fee_type !== "flat_rate"}
                                
                              />
                            </div>
                          </div>
                          <div className="radio-btn">
                            <FormControlLabel value="percentage" control={<Radio />} label={t("percentage")} disabled={assignment.pay_type === "hourly"} />
                            <div className="radio-i">
                              <FormInput
                                id={"fee_type_percentage"}
                                onChange={handleFeeStruChange}
                                type={"text"}
                                value={assignment.fee_type_percentage}
                                label={t("")}
                                placeholder=""
                                suffix={<span>%</span>}
                                onKeyUp={(e) => { allowNumbers(e); }}
                                onKeyDown={(e) => { allowNumbers(e); }}
                                disabled={assignment.fee_type !== "percentage" || assignment.pay_type === "hourly"}
                              />
                            </div>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </SectionHolder>
                  <SectionHolder title={t("pay_type")}  assignmentDetails = {assignment} >
                    <div className="fee_structure">
                      <FormControl component="fieldset">
                        <RadioGroup aria-required aria-label="pay_type" name="pay_type" value={assignment.pay_type}
                          onChange={(event) => {
                            handleFeeStruChange("pay_type", event.target.value)
                          }}>
                          <div className="radio-btn fc">
                            <FormControlLabel value="hourly" control={<Radio />} label={t("hourly")} />
                            <div className="radio-i">
                              <FormInput
                                id={"hourly_pay_type"}
                                onChange={handleFeeStruChange}
                                type={"text"}
                                value={convertNumberToMoney(assignment.hourly_pay_type, "string") as string}
                                label={t("")}
                                placeholder=""
                                onBlur={formatToMoney}
                                onKeyDown={formatToMoney}
                                onKeyUp={formatToMoney}
                                prefix={<span>$</span>}
                                disabled={assignment.pay_type !== "hourly"}
                                
                              />
                            </div>
                          </div>
                          <div className="radio-btn">
                            <FormControlLabel value="salary" control={<Radio />} label={t("salary")} />
                            <div className="radio-i">
                              <FormInput
                                id={"salary_pay_type"}
                                onChange={handleFeeStruChange}
                                type={"text"}
                                value={convertNumberToMoney(assignment.salary_pay_type, "string") as string}
                                label={t("")}
                                placeholder=""
                                onBlur={formatToMoney}
                                onKeyDown={formatToMoney}
                                onKeyUp={formatToMoney}
                                prefix={<span>$</span>}
                                disabled={assignment.pay_type !== "salary"}
                                
                              />
                            </div>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </SectionHolder>
                  <SectionHolder title={t("fee_amount")}  assignmentDetails = {assignment} >
                    <div className="fee_structure" style={{width:"35%"}}>
                      <div className="fee_amnt">
                        <FormInput
                          id={"fee_amount"}
                          onChange={handleFeeStruChange}
                          type={"text"}
                          value={convertNumberToMoney(assignment.fee_amount, "string") as string}
                          label={t("")}
                          placeholder=""
                          prefix={<span>$</span>}
                          disabled={true}
                          // customStyle={{minWidth:"5vw"}}
                        />
                      </div>
                    </div>
                  </SectionHolder>
            </div>
          </div>
          </>
          ):
          <div className="form-table-item">
            <table>
              <thead>
                <tr>
                  <th>{t("pay_type")}</th>
                  <th>{t("pay_rate")}</th>
                  <th>{t("bill_rate")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TableData>{t("regular")}</TableData>
                  <TableData>
                    <PayRate
                      rate={assignment.regular_pay_rate}
                      // handleChange={(e) =>
                      //   updateAssignmentProp("regular_pay_rate", e)
                      // }
                      handleChange={(id, value) =>
                        handlePayRateChange(id, value)
                      }
                    />
                  </TableData>
                  <TableData>
                    <PayRate
                      rate={assignment.regular_bill_rate}
                      // handleChange={(e) =>
                      //   updateAssignmentProp("regular_bill_rate", e)
                      // }
                      handleChange={(id, value) =>
                        handleBillRateChange(id, value)
                      }
                    />
                  </TableData>
                </tr>
                <tr>
                  <TableData>{t("over_time")}</TableData>
                  <TableData>
                    <PayRate
                      rate={assignment.overtime_pay_rate}
                      handleChange={(e) =>
                        updateAssignmentProp("overtime_pay_rate", e)
                      }
                      disabled={true}
                    />
                  </TableData>
                  <TableData>
                    <PayRate
                      rate={assignment.overtime_bill_rate}
                      handleChange={(e) =>
                        updateAssignmentProp("overtime_bill_rate", e)
                      }
                    />
                  </TableData>
                </tr>
                <tr>
                  <TableData>{t("double_time")}</TableData>
                  <TableData>
                    <PayRate
                      rate={assignment.double_time_pay_rate}
                      handleChange={(e) =>
                        updateAssignmentProp("double_time_pay_rate", e)
                      }
                      disabled={true}
                    />
                  </TableData>
                  <TableData>
                    <PayRate
                      rate={assignment.double_time_bill_rate}
                      handleChange={(e) =>
                        updateAssignmentProp("double_time_bill_rate", e)
                      }
                    />
                  </TableData>
                </tr>
              </tbody>
            </table>
          </div>
          }

            <div className="job-order-column col2">
                  <div className="ac-sc-payroll-fields">
                  <p className="ac-service-text">SHIFT DETAILS</p>
                  <SectionHolder title="rate"  assignmentDetails = {assignment} >
                    <div className="fee_structure">
                      <FormControl component="fieldset">
                        <RadioGroup >
                          <div className="radio-btn fc">
                            <FormControlLabel control={<Radio />} label={t("shift_name")} />
                            <div className="radio-i">
                              <FormInput
                                id={"shift_name"}
                                onChange={updateAssignmentProp}
                                type={"text"}
                                value={assignment?.shift_name}
                                placeholder=""
                              />
                            </div>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </SectionHolder>
                  <SectionHolder title={t("shift_time")} assignmentDetails = {assignment} >
                    <div className="fee_structure">
                      <FormControl component="fieldset">
                        <RadioGroup>
                          <div className="radio-btn fc">
                            <FormControlLabel control={<Radio />} label={t("start")} />
                            <div className="radio-i">
                            <TimePickerComponent
                              id={"shift_start"}
                              onChange={updateAssignmentProp}
                              placeholder={""}
                              value={assignment.shift_start}
                              onClearTime={() => handleClearTime("shift_start")}
                              icon={<Timer />}
                            />
                            </div>
                          </div>
                          <div className="radio-btn">
                            <FormControlLabel  control={<Radio />} label={t("end")} />
                            <div className="radio-i">
                            <TimePickerComponent
                              id={"shift_end"}
                              onChange={updateAssignmentProp}
                              placeholder={""}
                              value={assignment.shift_end}
                              onClearTime={() => handleClearTime("shift_end")}
                              icon={<Timer />}
                            />
                            </div>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </SectionHolder>
                  <SectionHolder title="" assignmentDetails = {assignment} >
                    <div className="fee_structure">
                      <FormControl component="fieldset">
                        <RadioGroup>
                          <div className="radio-btn fc">
                            <FormControlLabel control={<Radio />} label={t("break")} />
                            <div className="radio-i">
                            <FormInput
                              id={"shift_break"}
                              onChange={(_field: any, value: any) => {
                                if (value !== null) {
                                  updateAssignmentProp("shift_break", Number(value));
                                }
                              }}
                              required={false}
                              placeholder={""}
                              type={"number"}
                              value={assignment.shift_break}
                            />
                            </div>
                          </div>
                          <div className="radio-btn">
                            <FormControlLabel  control={<Radio />} label={t("estimated_shift_hours")} />
                            <div className="radio-i">
                            <FormInput
                              id={"shift_hours"}
                              onChange={(_field: any, value: any) => {
                                if (value !== null) {
                                  updateAssignmentProp("shift_hours", Number(value));
                                }
                              }}
                              required={false}
                              placeholder={""}
                              type={"number"}
                              value={assignment.shift_hours}
                            />
                            </div>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </SectionHolder>
            </div>
          </div>
          
          
        </div>
        
        
        <div className="form-actions">
          <div className={"error-section"}>
            {createState.error && (
              <ApiError
                message={createState.error.message}
                onClose={handleClearError}
              />
            )}
          </div>
          <div className="act-btns">
            <div className="btn-back">
              <CustomButton
                textStyle={{ textTransform: "capitalize" }}
                name={t("back")}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => {
                  handleClearError();
                  setShowNextPanel(false);
                }}
                enable
              />
            </div>
            <div className="btn-save">
              <CustomButton
                textStyle={{ textTransform: "none" }}
                name={t("finalize_creating_assignment")}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={handleSave}
                enable
                loading={
                  createState.loading == LoadingType.pending ||
                  updateState.loading === LoadingType.pending
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={showModal}
      wrapperWidth={"60%"}
      maxWrapperWidth={"60%"}
      onClose={toggle}
      onDissmiss={toggle}>
      <DialogWrapper onClose={() => closePopup(toggle)}>
        {showSpinner() ? (
          <div className="loading-spinner">
            <SpinnerScreen />
          </div>
        ) : (
          getCurrentPanel()
        )}
      </DialogWrapper>
    </ActionDialogHolder>
  );
};

export const PayRate = ({ rate, handleChange, disabled = false }) => {
  return (
    <div className="pay-rate">
      <FormInput
        id={""}
        onChange={(id, value) => handleChange(id, value)}
        type={"text"}
        value={convertNumberToMoney(rate, "string") as string}
        placeholder=""
        prefix={<span>$</span>}
        step="0.01"
        min={"0.00"}
        customInputStyle={{ minWidth: "unset" }}
        onBlur={formatToMoney}
        onKeyDown={formatToMoney}
        onKeyUp={formatToMoney}
        disabled={disabled}
      />
    </div>
  );
};
interface SectionHolderProps {
  title: string;
  children: React.ReactNode;
  assignmentDetails : Assignment
}

const SectionHolder: React.FunctionComponent<SectionHolderProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div className="jod-section-holder">
      <div className="jod-section-title" style={!payFeePlacementType.includes(props.assignmentDetails.placement_type_value) && props.title!==t("shift_time")?{display:"none"}:{}}>
        <span>{props.title}</span>
      </div>
      <div className="jod-section-content"  style={!payFeePlacementType.includes(props.assignmentDetails.placement_type_value) && props.title===t("shift_time")?{paddingTop:"0.2em"}:{}}>{props.children}</div>
    </div>
  );
};


export default CreateAssignmentPopup;

import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./marketing_summary_report_thunk";
import { MarketingCampaign } from "../../../../interfaces/marketing_campaign";
import { IReportCenterUploadUrlResponse } from "../../../../interfaces/report_center";


export interface MarketingSummaryReportListState {
    error?: AppError | null,
    loading: LoadingType,
    response: MarketingCampaign[] | null,
    downloadURL: {
        error?: AppError | null;
        loading: LoadingType;
        response: IReportCenterUploadUrlResponse | null;
    };
}

const initialState: MarketingSummaryReportListState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    downloadURL: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
  
}


export const marketingSummaryReportListSlice = createSlice({
    name: 'marketingSummaryReportList',
    initialState: initialState,
    reducers: {
        clearMarketingSummaryReportListError(state) {
            return { ...state, error: null };
        },
        clearMarketingSummaryReportListState(state) {
            return { ...state, loading: LoadingType.idle, error: null };
        },
        clearMSRDownloadState(state) {
            return {
                ...state, downloadURL: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getMarketingSummaryReportList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getMarketingSummaryReportList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getMarketingSummaryReportList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload }))

            .addCase(thunk.getMarketingSummaryReportDownloadUrl.pending, (state, action) => ({ ...state, downloadURL: { ...state.downloadURL, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getMarketingSummaryReportDownloadUrl.rejected, (state, action) => ({ ...state, downloadURL: { ...state.downloadURL, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.getMarketingSummaryReportDownloadUrl.fulfilled, (state, action) => ({ ...state, downloadURL: { ...state.downloadURL, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearMarketingSummaryReportListError, clearMarketingSummaryReportListState, clearMSRDownloadState } = marketingSummaryReportListSlice.actions;
export const selectState = (state: MarketingSummaryReportListState) => state;
export const selectMarketingSummaryReportDownloadUrl = (state: MarketingSummaryReportListState) => state.downloadURL;



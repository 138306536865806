import { useEffect, useState } from "react";
import { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import CustomFormSelect, { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import { TalentDarkBlueIcon } from "../../../../icons";
import {
  AppError,
  IEmploymentHistory,
  ICreateEmploymentHistory,
  ICompetitor,
  ICompanySkillSet,
  ICompanyCredential,
  ICompanyCertification,
  ICompanyEducationRequirement,
  ICompanyRequiredEquipment,
  IProfileDropdown,
} from "../../../../interfaces";
import {
  convertNumberToMoney,
  CustomButton,
  enforceFormat,
  formatToMoney,
  formatToPhone,
  FormInput,
  RegxPattern,
} from "../../../../utils";
import { useTranslation } from "react-i18next";
import { THEME, } from "../../../../enums";
import ApiError from "../../../../components/api_error";

import "react-datepicker/dist/react-datepicker.css";
import "./talent_employment_form.scss";
import {
  getCompaniesDropdown,
  selectCompaniesDropdownList,
  getCompaniesList,
  selectCompaniesList,
  selectCompaniesListState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { DateInput } from "../../../../components/date_input/date_input";
import { getDateFromTimeStamp } from "../../../../variables";

import CreatableSelect from "react-select/creatable";
import RenderInput from "../../../../components/render_input/render_input";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";

const formOptions = {
  /// 1st row
  company_name: {
    field: "company_name",
    type: "text",
    label: "company",
    secure: false,
    required: true,
    placeholder: "",
    keyboardtype: "",
  },
  department_name: {
    field: "department_name",
    type: "text",
    label: "department",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
  },
  supervisor_name: {
    field: "supervisor_name",
    type: "text",
    label: "supervisor",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
  },
  supervisor_contact: {
    field: "supervisor_contact",
    label: "supervisor_contact",
    type: "tel",
    secure: false,
    required: false,
    placeholder: "(555) 555 - 5555",
    keyboardtype: "",
    maxLength: 16,
    onKeyUp: formatToPhone,
    onKeyDown: enforceFormat,
  },
  // 2nd row

  job_title: {
    field: "job_title",
    type: "text",
    label: "job_title",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
  },

  is_placed_by_staffing_agency: {
    // field: "placed_by_staffing_agency",
    field: "is_placed_by_staffing_agency",
    type: "text",
    label: "placed_by_staffing_agency",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
  },

  placed_by_staffing_agency_id: {
    field: "placed_by_staffing_agency_id",
    type: "text",
    label: "if_placed_by_another_staffing_agency_which_one",
    secure: false,
    required: false,
    placeholder: "select",
    keyboardtype: "",
  },
  pay_rate: {
    field: "pay_rate",
    type: "text",
    label: "pay_rate",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
  },
  start_date: {
    field: "start_date",
    type: "date",
    label: "start_date",
    secure: false,
    required: true,
    placeholder: "",
    keyboardtype: "",
  },
  end_date: {
    field: "end_date",
    type: "date",
    label: "end_date",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
  },

  /// 2nd row
  professional_skills: {
    field: "professional_skills",
    type: "text",
    label: "professional_skills",
    secure: false,
    required: false,
    placeholder: "select",
    keyboardtype: "",
  },
  certifications: {
    field: "certifications",
    type: "text",
    label: "certifications",
    secure: false,
    required: false,
    placeholder: "select",
    keyboardtype: "",
    multiline: true,
  },
  education: {
    field: "education",
    type: "text",
    label: "education",
    pattern: "",
    secure: false,
    required: false,
    placeholder: "select",
    keyboardtype: "",
  },
  credentials: {
    field: "credentials",
    type: "text",
    label: "credentials",
    pattern: "",
    secure: false,
    required: false,
    placeholder: "select",
    keyboardtype: "",
  },

  safety_equipment: {
    field: "safety_equipment",
    type: "text",
    label: "safety_equipment",
    pattern: "",
    secure: false,
    required: false,
    placeholder: "select",
    keyboardtype: "",
  },
  /// 3rd row
  job_description_duties: {
    field: "job_description_duties",
    type: "text",
    label: "job_description_duties",
    pattern: "",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
    multiline: true,
  },
  reason_for_leaving: {
    field: "reason_for_leaving",
    type: "text",
    label: "reason_for_leaving",
    pattern: "",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
    multiline: true,
  },
  //4th row
  reference_check_notes: {
    field: "reference_check_notes",
    type: "text",
    label: "reference_check_notes",
    pattern: "",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
    multiline: true,
  },
  reference_checked_with_id: {
    field: "reference_checked_with_id",
    type: "text",
    label: "reference_checked_with",
    pattern: "",
    secure: false,
    required: false,
    placeholder: "select",
    keyboardtype: "",
  },
  reference_check_by: {
    field: "reference_check_by",
    type: "text",
    label: "reference_check_by",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
  },
  reference_check_date: {
    field: "reference_check_date",
    type: "date",
    label: "reference_check_date",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
  },
};

interface Props {
  title: string;
  loading: boolean;
  error: AppError | null | undefined;
  talent?: IEmploymentHistory;
  skillSetList: Array<ICompanySkillSet>;
  credentialsList: Array<ICompanyCredential>;
  certificationsList: Array<ICompanyCertification>;
  educationList: Array<ICompanyEducationRequirement>;
  requiredEquipmentList: Array<ICompanyRequiredEquipment>;
  competitors: Array<ICompetitor>;
  usersList: IProfileDropdown[],
  onClose: () => void;
  onSubmit: (doc: {
    forceAdd: boolean;
    value: ICreateEmploymentHistory;
  }) => void;
  onClearError: () => void;
}

const getTalentEmploymentFormData = (
  value: IEmploymentHistory
): ICreateEmploymentHistory => {
  return {
    talent_id: value.talent_id ?? "",
    company_id: value.company_id ?? "",
    company_name: value.company_name ?? "",
    department_name: value.department_name ?? "",
    supervisor_name: value.supervisor_name ?? "",
    supervisor_contact: value.supervisor_contact ?? "",
    job_title: value.job_title ?? "",
    pay_rate: value.pay_rate ?? 0,
    // placed_by_staffing_agency: value.placed_by_staffing_agency ?? "",
    start_date: value.start_date ?? 0,
    end_date: value.end_date ?? 0,
    professional_skills: value.professional_skills ?? [],
    certifications: value.certifications ?? [],
    education: value.education ?? [],
    credentials: value.credentials ?? [],
    safety_equipment: value.safety_equipment ?? [],
    job_description_duties: value.job_description_duties ?? "",
    reason_for_leaving: value.reason_for_leaving ?? "",
    reference_check_notes: value.reference_check_notes ?? "",
    reference_check_by: value.reference_check_by ?? "",
    reference_check_date: value.reference_check_date ?? 0,
    is_new_company: value.is_new_company ?? false,
    ok_to_contact_supervisor: value.ok_to_contact_supervisor ?? false,
    is_placed_by_staffing_agency: value.is_placed_by_staffing_agency ?? false,
    reference_checked_with: value.reference_checked_with ?? "",
    placed_by_staffing_agency_name: value.placed_by_staffing_agency_name ?? "",
    reference_checked_with_id: value.reference_checked_with_id ?? "",
    placed_by_staffing_agency_id: value.placed_by_staffing_agency_id ?? ""
  };
};

const TalentEmploymentForm: React.FunctionComponent<Props> = (props) => {
  const [formState, setFormState] = useState<ICreateEmploymentHistory>(
    props.talent
      ? getTalentEmploymentFormData(props.talent)
      : {
        talent_id: "",
        company_id: "",
        company_name: "",
        department_name: "",
        supervisor_name: "",
        supervisor_contact: "",
        job_title: "",
        pay_rate: 0,
        // placed_by_staffing_agency: "",
        start_date: 0,
        end_date: 0,
        professional_skills: [],
        certifications: [],
        education: [],
        credentials: [],
        safety_equipment: [],
        job_description_duties: "",
        reason_for_leaving: "",
        reference_check_notes: "",
        reference_check_by: "",
        is_new_company: false,
        ok_to_contact_supervisor: false,
        reference_check_date: 0,
        is_placed_by_staffing_agency: false,
        reference_checked_with: "",
        placed_by_staffing_agency_name: "",
        reference_checked_with_id: "",
        placed_by_staffing_agency_id: ""
      }
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>();
  const [error, setError] = useState('');
  const companiesListState = useAppSelector((state) =>
    selectCompaniesListState(state)
  );
  const companies = useAppSelector((state) => selectCompaniesList(state));
  useEffect(() => {
    dispatch(getCompaniesList());
    dispatch(getCompaniesDropdown());
  }, []);
  const companiesDropdown = useAppSelector((state) =>
    selectCompaniesDropdownList(state)
  );
  function handleFieldChange(fieldId, value) {
    if (fieldId === "company_name" && value != null) {
      const isNew = value?.label === value?.value ? true : false;
      setFormState({
        ...formState,
        [fieldId]: value.value,
        "is_new_company": isNew,
      });
    } else {
      setFormState({ ...formState, [fieldId]: value });
    }
  }
  const mapOptions = (options: any[], labelProp: string, valueProp: string) => {
    let mappedList: Array<{ label: string; value: string }> = [];
    if (options && options.length > 0) {
      mappedList = options.map((option) => ({
        label: option[labelProp],
        value: option[valueProp],
      }));
    }
    return mappedList;
  };

  const isCompanySelected = () => {
    if(formState.company_name === '' || formState.company_name === null) {
      return setError(t("validators.required"))
    }
    return null;
  }

  return (
    <div className="create-talent-emp-container">
      <div className="create-talent-emp-header">
        <FormDialogTilteHeader
          title={props.title}
          titleIcon={<TalentDarkBlueIcon width={"100%"} height={"100%"} />}
        />
      </div>
      <div className="create-talent-emp-content">
        {getSectionOne()}
        {getSectionTwo()}
        {getSectionThree()}
        {getSectionFour()}

        {props.error && (
          <div className={"error-section"}>
            <ApiError
              message={props.error.message}
              onClose={props.onClearError}
            />
          </div>
        )}
      </div>
      <div className="create-talent-emp-actions">
        <div className="fl-right">
          <div className="btn-cancel">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("cancel")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={props.onClose}
            />
          </div>
          <div className="btn-save">
            <CustomButton
              loading={props.loading}
              textStyle={{ textTransform: "capitalize" }}
              name={
                props.error != null && props.error?.code === 409
                  ? t("force_add")
                  : props.talent
                    ? t("update")
                    : t("save")
              }
              // enable={_isFormValid()}
              enable={true}
              backgroundColor={
                props.error != null && props.error?.code === 409
                  ? THEME.secondaryColor6
                  : THEME.defaultHighLightColor
              }
              onClick={() => {
                const updatedFormState = {
                  ...formState,
                  [formOptions.pay_rate.field]: convertNumberToMoney(
                    formState[formOptions.pay_rate.field], 
                    "number"
                  ) as number,
                };
                setCheckValidationOnSubmit(true);
                isCompanySelected()
                if (!_isFormValid()) {
                  return;
                }
                props.onSubmit({
                  forceAdd:
                    props.error != null && props.error?.code === 409
                      ? true
                      : false,
                  value: updatedFormState,
                });
                setCheckValidationOnSubmit(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  /// Section one
  function getSectionOne() {
    const borderColor = error && (formState.company_name === '' || formState.company_name === null) ? "red" : "";
    const customStyles = {
      control: (base) => ({
        ...base,
        height: "1.9898983vw",
        minHeight: "1.9898983vw",
        borderColor: borderColor,
      })
    };
    return (
      <div className="create-talent-emp-section-divider">
        <div className="create-talent-emp-row sec-1-1">
          <div
            key={formOptions.company_name.field}
            className={"create-talent-emp-input-holder"}
            style={{ marginTop: "0.5em" }}>
            <span className="company_label">
              {t(formOptions.company_name.label)}&nbsp;*
            </span>

            <CreatableSelect
              isClearable
              name={formOptions.company_name.field}
              formatCreateLabel={(userInput) => `Use "${userInput}"`}
              options={mapOptions(companiesDropdown, "name", "id")}
              placeholder={t('select_placeholder')}
              className="creatable_select"
              value={
                mapOptions(companiesDropdown, "name", "id").find(
                  (doc) =>
                    formState[formOptions.company_name.field] === doc.value
                ) ??
                (formState[formOptions.company_name.field]
                  ? {
                    label: formState[formOptions.company_name.field],
                    value: formState[formOptions.company_name.field],
                  }
                  : "")
              }
              styles={customStyles}
              onChange={(option, action) => {
                handleFieldChange(formOptions.company_name.field, option);
              }}
            />
            {error && (formState.company_name === '' || formState.company_name === null) && <div className="error">{error}</div>}
          </div>
          <div
            key={formOptions.department_name.field}
            className={"create-talent-emp-input-holder"}>
            <FormInput
              validations={talentEmploymentFormValidators}
              id={formOptions.department_name.field}
              onChange={handleFieldChange}
              secure={formOptions.department_name.secure}
              required={formOptions.department_name.required}
              placeholder={formOptions.department_name.placeholder}
              type={formOptions.department_name.type}
              value={formState[formOptions.department_name.field]}
              label={t(formOptions.department_name.label)}
              keyboardtype={formOptions.department_name?.keyboardtype}
            />
          </div>
          <div
            key={formOptions.supervisor_name.field}
            className={"create-talent-emp-input-holder"}>
            <FormInput
              validations={talentEmploymentFormValidators}
              id={formOptions.supervisor_name.field}
              onChange={handleFieldChange}
              secure={formOptions.supervisor_name.secure}
              required={formOptions.supervisor_name.required}
              placeholder={formOptions.supervisor_name.placeholder}
              type={formOptions.supervisor_name.type}
              value={formState[formOptions.supervisor_name.field]}
              label={t(formOptions.supervisor_name.label)}
              keyboardtype={formOptions.supervisor_name?.keyboardtype}
            />
          </div>
          <div
            key={formOptions.job_title.field}
            className={"create-talent-emp-input-holder"}>
            <FormInput
              validations={talentEmploymentFormValidators}
              id={formOptions.job_title.field}
              onChange={handleFieldChange}
              secure={formOptions.job_title.secure}
              required={formOptions.job_title.required}
              placeholder={formOptions.job_title.placeholder}
              type={formOptions.job_title.type}
              value={formState[formOptions.job_title.field]}
              label={t(formOptions.job_title.label)}
              keyboardtype={formOptions.job_title?.keyboardtype}
            />
          </div>
        </div>
        <div className="create-talent-emp-row sec-1-2">
          <div
            key={formOptions.is_placed_by_staffing_agency.field}
            className={"create-talent-emp-input-holder"}>
            <ToggleSwitch
              label={t(formOptions.is_placed_by_staffing_agency.label)}
              labelPosition={"top"}
              checked={formState[formOptions.is_placed_by_staffing_agency.field]}
              onChange={(value) => setFormState({ ...formState, is_placed_by_staffing_agency: value })}
            />
          </div>
          <div
            key={formOptions.placed_by_staffing_agency_id.field}
          >
            <CustomFormSelect
              name={formOptions.placed_by_staffing_agency_id.field}
              list={props.competitors.map((doc) => ({
                label: doc.competitor_name,
                value: doc.id,
              }))}
              onChange={(value) =>
                handleFieldChange(formOptions.placed_by_staffing_agency_id.field, value)
              }
              required={formOptions.placed_by_staffing_agency_id.required}
              placeholder={formOptions.placed_by_staffing_agency_id.placeholder}
              value={formState[formOptions.placed_by_staffing_agency_id.field]}
              label={t(formOptions.placed_by_staffing_agency_id.label)}
              customStyle={{ width: "21.5vw", marginRight: "2.5em" }}
            />
          </div>
          <div
            key={formOptions.pay_rate.field}
            className={"create-talent-emp-input-holder"}>
            <FormInput
              id={formOptions.pay_rate.field}
              onChange={handleFieldChange}
              secure={formOptions.pay_rate.secure}
              prefix={<span>$</span>}
              step="0.01"
              min={"0.00"}
              onBlur={formatToMoney}
              onKeyDown={formatToMoney}
              onKeyUp={formatToMoney}
              required={formOptions.pay_rate.required}
              placeholder={formOptions.pay_rate.placeholder}
              type={formOptions.pay_rate.type}
              value={convertNumberToMoney(formState[formOptions.pay_rate.field]) as number}
              label={t(formOptions.pay_rate.label)}
              keyboardtype={formOptions.pay_rate?.keyboardtype}
              customInputStyle={{ width: "9.8vw", minWidth: "8vw" }}
            />
          </div>
        </div>
        <div className="create-talent-emp-row sec-1-3">
          <div
            key={formOptions.start_date.field}>
            <DateInput
              id={formOptions.start_date.field}
              onChange={handleFieldChange}
              secure={formOptions.start_date.secure}
              required={formOptions.start_date.required}
              placeholder={formOptions.start_date.placeholder}
              value={formState[formOptions.start_date.field]}
              label={t(formOptions.start_date.label)}
              disablePast={false}
              disableFuture={true}
              // minDate={new Date()}
              setHoursToZero={true}
              customInputStyle={{ width: "9.8vw", marginRight: "2.5em" }}
              checkValidationOnSubmit={checkValidationOnSubmit}
              validations={talentEmploymentFormValidators}
            />
          </div>
          <div
            key={formOptions.end_date.field}            >
            <DateInput
              id={formOptions.end_date.field}
              onChange={handleFieldChange}
              secure={formOptions.end_date.secure}
              required={formOptions.end_date.required}
              placeholder={formOptions.end_date.placeholder}
              value={formState[formOptions.end_date.field]}
              label={t(formOptions.end_date.label)}
              disablePast={false}
              minDate={
                formState[formOptions.start_date.field]
                  ? getDateFromTimeStamp(
                    formState[formOptions.start_date.field]
                  )
                  : undefined
              }
              setHoursToZero={true}
              customInputStyle={{ width: "9.8vw" }}
              checkValidationOnSubmit={checkValidationOnSubmit}
              validations={talentEmploymentFormValidators}
            />
          </div>
        </div>
      </div>
    );
  }

  function getSectionTwo() {
    return (
      <div className="create-talent-emp-section-divider">
        <div className="create-talent-emp-row sec-2-1">
          <div
            key={formOptions.professional_skills.field}
            className={"create-talent-emp-input-holder"}
            style={{ marginTop: "0.5em" }}>
            <CustomMultiFormSelect
              name={formOptions.professional_skills.field}
              list={props.skillSetList.map((doc) => ({
                label: doc.skill_set,
                value: doc.id,
              }))}
              onChange={(value) =>
                handleFieldChange(formOptions.professional_skills.field, value)
              }
              required={formOptions.professional_skills.required}
              placeholder={formOptions.professional_skills.placeholder}
              value={formState[formOptions.professional_skills.field]}
              label={t(formOptions.professional_skills.label)}
            />
          </div>
          <div
            key={formOptions.certifications.field}
            className={"create-talent-emp-input-holder"}
            style={{ marginTop: "0.5em" }}>
            <CustomMultiFormSelect
              name={formOptions.certifications.field}
              list={props.certificationsList.map((doc) => ({
                label: doc.certification,
                value: doc.id,
              }))}
              onChange={(value) =>
                handleFieldChange(formOptions.certifications.field, value)
              }
              required={formOptions.certifications.required}
              placeholder={formOptions.certifications.placeholder}
              value={formState[formOptions.certifications.field]}
              label={t(formOptions.certifications.label)}
            />
          </div>
          <div
            key={formOptions.education.field}
            className={"create-talent-emp-input-holder"}
            style={{ marginTop: "0.5em" }}>
            <CustomMultiFormSelect
              name={formOptions.education.field}
              list={props.educationList.map((doc) => ({
                label: doc.education_requirement,
                value: doc.id,
              }))}
              onChange={(value) =>
                handleFieldChange(formOptions.education.field, value)
              }
              required={formOptions.education.required}
              placeholder={formOptions.education.placeholder}
              value={formState[formOptions.education.field]}
              label={t(formOptions.education.label)}
            />
          </div>
          <div
            key={formOptions.credentials.field}
            className={"create-talent-emp-input-holder"}
            style={{ marginTop: "0.5em" }}>
            <CustomMultiFormSelect
              name={formOptions.credentials.field}
              list={props.credentialsList.map((doc) => ({
                label: doc.credential,
                value: doc.id,
              }))}
              onChange={(value) =>
                handleFieldChange(formOptions.credentials.field, value)
              }
              required={formOptions.credentials.required}
              placeholder={formOptions.credentials.placeholder}
              value={formState[formOptions.credentials.field]}
              label={t(formOptions.credentials.label)}
            />
          </div>
        </div>
        {/* <div className="create-talent-emp-row sec-2-2">
          <div
            key={formOptions.safety_equipment.field}
            className={"create-talent-emp-input-holder"}
            style={{ marginTop: "0.5em" }}>
            <CustomMultiFormSelect
              name={formOptions.safety_equipment.field}
              list={props.requiredEquipmentList.map((doc) => ({
                label: doc.required_equipment,
                value: doc.id,
              }))}
              onChange={(value) =>
                handleFieldChange(formOptions.safety_equipment.field, value)
              }
              required={formOptions.safety_equipment.required}
              placeholder={formOptions.safety_equipment.placeholder}
              value={formState[formOptions.safety_equipment.field]}
              label={t(formOptions.safety_equipment.label)}
            />
          </div>
        </div> */}
      </div>
    );
  }
  function getSectionThree() {
    return (
      <div className="create-talent-emp-section-divider">
        <div className="create-talent-emp-row sec-3">
          <div
            key={formOptions.job_description_duties.field}
            className={"create-talent-emp-input-holder"}
            style={{ width: "-webkit-fill-available" }}>
            <FormInput
              validations={talentEmploymentFormValidators}
              id={formOptions.job_description_duties.field}
              onChange={handleFieldChange}
              secure={formOptions.job_description_duties.secure}
              required={formOptions.job_description_duties.required}
              placeholder={formOptions.job_description_duties.placeholder}
              type={formOptions.job_description_duties.type}
              value={formState[formOptions.job_description_duties.field]}
              label={t(formOptions.job_description_duties.label)}
              keyboardtype={formOptions.job_description_duties?.keyboardtype}
              multiline={formOptions.job_description_duties.multiline}
            />
          </div>
          <div
            key={formOptions.reason_for_leaving.field}
            className={"create-talent-emp-input-holder"}
            style={{ width: "-webkit-fill-available" }}>
            <FormInput
              validations={talentEmploymentFormValidators}
              id={formOptions.reason_for_leaving.field}
              onChange={handleFieldChange}
              secure={formOptions.reason_for_leaving.secure}
              required={formOptions.reason_for_leaving.required}
              placeholder={formOptions.reason_for_leaving.placeholder}
              type={formOptions.reason_for_leaving.type}
              value={formState[formOptions.reason_for_leaving.field]}
              label={t(formOptions.reason_for_leaving.label)}
              keyboardtype={formOptions.reason_for_leaving?.keyboardtype}
              multiline={formOptions.reason_for_leaving.multiline}
            />
          </div>
        </div>
        <div className="create-talent-emp-row sec-4">
          <div
            key={formOptions.supervisor_contact.field}
            className={"create-talent-emp-input-holder"}>
            <RenderInput
              doc={{
                field: "ok_to_contact_supervisor",
                type: "switch",
                label: "ok_to_contact_supervisor",
                secure: false,
                required: false,
                placeholder: "",
                keyboardtype: "",
                labelPosition: "right",
              }}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={talentEmploymentFormValidators}
              className={"cmpny-f-input-holder"}
              customStyle={{ flex: 1 }}
            />
          </div>
          {formState.ok_to_contact_supervisor && (
            <div
              key={formOptions.supervisor_contact.field}
              className={"create-talent-emp-input-holder"}>
              <FormInput
                validations={talentEmploymentFormValidators}
                id={formOptions.supervisor_contact.field}
                onChange={handleFieldChange}
                secure={formOptions.supervisor_contact.secure}
                required={formOptions.supervisor_contact.required}
                placeholder={formOptions.supervisor_contact.placeholder}
                type={formOptions.supervisor_contact.type}
                value={formState[formOptions.supervisor_contact.field]}
                label={t(formOptions.supervisor_contact.label)}
                keyboardtype={formOptions.supervisor_contact?.keyboardtype}
                onKeyDown={enforceFormat}
                onKeyUp={formatToPhone}
                maxLength={formOptions.supervisor_contact.maxLength}

              />

            </div>
          )}
        </div>
      </div>
    );
  }
  function getSectionFour() {
    return (
      <div className="create-talent-emp-section-divider">
        <div className="create-talent-emp-row sec-6">
          <div
            key={formOptions.reference_check_notes.field}
            className={"create-talent-emp-input-holder"}
            style={{ width: "-webkit-fill-available" }}>
            <FormInput
              validations={talentEmploymentFormValidators}
              id={formOptions.reference_check_notes.field}
              onChange={handleFieldChange}
              secure={formOptions.reference_check_notes.secure}
              required={formOptions.reference_check_notes.required}
              placeholder={formOptions.reference_check_notes.placeholder}
              type={formOptions.reference_check_notes.type}
              value={formState[formOptions.reference_check_notes.field]}
              label={t(formOptions.reference_check_notes.label)}
              keyboardtype={formOptions.reference_check_notes?.keyboardtype}
              multiline={formOptions.reference_check_notes.multiline}
            />
          </div>
          <div
            key={formOptions.reference_checked_with_id.field}
            className={"create-talent-emp-input-holder"}
            style={{ width: "-webkit-fill-available" }}>
            <CustomFormSelect
              name={formOptions.reference_checked_with_id.field}
              list={props.usersList?.map((doc) => ({
                label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                value: doc.id,
              }))}
              onChange={(value) =>
                handleFieldChange(formOptions.reference_checked_with_id.field, value)
              }
              required={formOptions.reference_checked_with_id.required}
              placeholder={formOptions.reference_checked_with_id.placeholder}
              value={formState[formOptions.reference_checked_with_id.field]}
              label={t(formOptions.reference_checked_with_id.label)}
              customStyle={{ width: "19vw" }}
            />
          </div>
          <div style={{ width: "-webkit-fill-available" }}>
            <div
              key={formOptions.reference_check_date.field}
              className={"create-talent-emp-input-holder"}>
              <DateInput
                id={formOptions.reference_check_date.field}
                onChange={handleFieldChange}
                secure={formOptions.reference_check_date.secure}
                required={formOptions.reference_check_date.required}
                placeholder={formOptions.reference_check_date.placeholder}
                value={formState[formOptions.reference_check_date.field]}
                label={t(formOptions.reference_check_date.label)}
                disablePast={false}
                setHoursToZero={true}
              />
            </div>
            <div
              key={formOptions.reference_check_by.field}
              className={"create-talent-emp-input-holder"}>
              <FormInput
                validations={talentEmploymentFormValidators}
                id={formOptions.reference_check_by.field}
                onChange={handleFieldChange}
                secure={formOptions.reference_check_by.secure}
                required={formOptions.reference_check_by.required}
                placeholder={formOptions.reference_check_by.placeholder}
                type={formOptions.reference_check_by.type}
                value={formState[formOptions.reference_check_by.field]}
                label={t(formOptions.reference_check_by.label)}
                keyboardtype={formOptions.reference_check_by?.keyboardtype}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function _isFormValid() {
    const { company_name, start_date, end_date, supervisor_contact } =
      formState;
    // if (supervisor_contact != '' && RegxPattern.phone.test(supervisor_contact) === false) return false;

    if (
      company_name === "" ||
      company_name === null ||
      !start_date
    )
      return false;

    return true;
  }

  function talentEmploymentFormValidators(value: {
    text: string;
    field: string;
  }) {
    switch (value.field) {
      case "supervisor_contact": {
        if (!value.text) return null;
        if (RegxPattern.usPhone.test(value.text) === false)
          return t("validators.enterValidPhoneNumber");
        return null;
      }
      case formOptions.company_name.field:
      case formOptions.supervisor_name.field:
      case formOptions.reference_checked_with_id.field:
      case formOptions.department_name.field:
      case formOptions.job_title.field:
      case formOptions.start_date.field:  {
        if (!value.text) return t("validators.required");
        return null;
      }

      default: {
        return null;
      }
    }
  }
};

export default TalentEmploymentForm;

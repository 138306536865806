import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ApiError from "../../../../components/api_error";
import { DateInput } from "../../../../components/date_input/date_input";
import { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { TimeInput } from "../../../../components/time_input/time_input";
import { THEME } from "../../../../enums";
import { ChatIcon } from "../../../../icons";
import {
  AppError,
  ICreateTalentClientCompanyInterview,
  IJobOrderDetail,
  ITalentClientCompanyInterview,
  ITalentInterviewStatus,
} from "../../../../interfaces";
import {
  getCompaniesDropdown,
  getContactsAllList,
  getContactsByCompany,
  getDepartmentsDropdown,
  getTalentDropdown,
  getjobsDropdown,
  selectCompaniesDropdownList,
  selectCompanyContactsList,
  selectContactsListAll,
  selectDepartmentsDropdownList,
  selectJobsDropdownList,
  selectTalentsDropdownList,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { CustomButton, CustomCheckBox, FormInput, mapOptions } from "../../../../utils";
import "./client_company_interview_form.scss";
import { dateTimeToTimestamp, timestampToStringDate } from "../../../../variables";
import TimePickerComponent from "../../../../components/time_picker/time_picker";
import { Timer } from "@material-ui/icons";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";

const formOptions = {
  company: {
    field: "company_id",
    type: "text",
    label: "company",
    secure: false,
    required: true,
    placeholder: "select",
    keyboardtype: "",
    disabled: true,
  },
  department: {
    field: "department_id",
    type: "text",
    label: "department",
    secure: false,
    required: false,
    placeholder: "Corporate",
    keyboardtype: "",
    disabled: true,

  },
  job_order: {
    field: "job_order_id",
    type: "text",
    label: "job_order",
    secure: false,
    required: false,
    placeholder: "select",
    keyboardtype: "",
    disabled: true,

  },
  talent: {
    field: "talent_id",
    type: "text",
    label: "talent",
    secure: false,
    required: true,
    placeholder: "select",
    keyboardtype: "",

  },
  /// 2nd row
  talent_interview_status_id: {
    field: "talent_interview_status_id",
    type: "text",
    label: "interview_status",
    secure: false,
    required: false,
    placeholder: "select",
    keyboardtype: "",
  },
  interview_with: {
    field: "contact_id",
    type: "text",
    label: "interview_with",
    secure: false,
    required: true,
    placeholder: "select",
    keyboardtype: "",
  },
  interview_date: {
    // field: "interview_date",
    field: "interview_schedule_date",
    type: "date",
    label: "interview_date",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
  },
  date_submitted: {
    field: "date_submitted",
    type: "date",
    label: "date_submitted",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
  },
  interview_time: {
    // field: "interview_date",
    field: "interview_schedule_time",
    type: "date",
    label: "interview_time",
    secure: false,
    required: false,
    placeholder: "",
  },

  talent_interview_feedback: {
    field: "talent_interview_feedback",
    type: "text",
    label: "talent_feedback",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
    multiline: true,
  },
  contact_interview_feedback: {
    field: "contact_interview_feedback",
    type: "text",
    label: "client_company_contact_feedback",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
    multiline: true,
  },
  recruiter_notes: {
    field: "recruiter_notes",
    type: "text",
    label: "recruiter_notes",
    secure: false,
    required: false,
    placeholder: "",
    keyboardtype: "",
    multiline: true,
  },
};
interface Props {
  title: string;
  jobId: string;
  talent_id?: string;
  loading: boolean;
  error?: AppError | null;
  interview?: ITalentClientCompanyInterview | null;
  interviewStatusList: ITalentInterviewStatus[];
  onSubmit: (value: ICreateTalentClientCompanyInterview, send_invite?: boolean) => void;
  onCancel: () => void;
  onClearError: () => void;
  jobState: IJobOrderDetail | undefined;
  visible: boolean;

}

const getInterviewFormData = (
  value: ITalentClientCompanyInterview
): ICreateTalentClientCompanyInterview => {
  return {
    id: value.id ?? "",
    contact_id: value.contact_id ?? "",
    talent_id: value.talent_id ?? "",
    talent_interview_status_id: value.talent_interview_status_id ?? "",
    job_order_id: value.job_order_id ?? "",
    interview_with: value.interview_with ?? "",
    interview_date: value?.interview_date! ?? "",
    interview_schedule_date: value?.interview_schedule_date! ?? "",
    interview_schedule_time: value?.interview_schedule_time! ?? "",
    talent_name: value.talent_name ?? "",
    interview_status: value.interview_status ?? "",
    talent_interview_feedback: value.talent_interview_feedback ?? "",
    contact_interview_feedback: value.contact_interview_feedback ?? "",
    company_name: value.company_name ?? "",
    department_name: value.department_name ?? "",
    job_title: value.job_title ?? "",
    company_id: value.company_id,
    department_id: value.department_id,
    candidate_submitted: value.candidate_submitted ?? false,
    date_submitted: value.date_submitted ?? 0,
    recruiter_notes: value.recruiter_notes ?? ""
  };
};

const ClientCompanyInterviewForm: React.FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch();
  const companiesDropdown = useAppSelector((state) =>
    selectCompaniesDropdownList(state)
  );
  const departmentsDropdown = useAppSelector((state) =>
    selectDepartmentsDropdownList(state)
  );
  const jobsDropdown = useAppSelector((state) => selectJobsDropdownList(state));
  const contactsList = useAppSelector((state) => selectCompanyContactsList(state));

  const [formState, setFormState] =
    useState<ICreateTalentClientCompanyInterview>(
      props.interview
        ? getInterviewFormData(props.interview)
        : {
          talent_id: props.talent_id ?? "",
          contact_id: "",
          talent_interview_status_id: getFirstStatus(),
          job_order_id: props?.jobId,
          interview_date: 0,
          interview_schedule_date: 0,
          interview_schedule_time: "12:00 AM",
          talent_interview_feedback: "",
          contact_interview_feedback: "",
          candidate_submitted: false,
          date_submitted: 0,
          recruiter_notes: ""
        }
    );

  const isValidScheduleDate = formState.interview_schedule_date !== 0 && formState.interview_schedule_date !== -19800 && formState.interview_schedule_date != null;
  const dateTimestampValue = isValidScheduleDate ? timestampToStringDate(formState.interview_schedule_date) : null;
  const timeStringValue = formState.interview_schedule_time !== "" ? formState.interview_schedule_time : "12:00 AM";
  const dateAndTimeTimeStampValue = isValidScheduleDate
    ? dateTimeToTimestamp(dateTimestampValue, timeStringValue)! / 1000
    : null;

  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getTalentDropdown({}));
    return () => { };
  }, []);
  const talentList = useAppSelector((state) =>
    selectTalentsDropdownList(state)
  );
  const [company, setCompany] = useState<string | null | undefined>(
    props.interview?.company_id ?? props?.jobState?.company_id
  );
  const [department, setDepartment] = useState<string | null | undefined>(
    props.interview?.department_id ?? props?.jobState?.department_id
  );

  const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>();
  const [sendCalendarInvite, setSendCalendarInvite] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getCompaniesDropdown());
    if (company) {
      dispatch(
        getContactsByCompany({
          company_id: company,
          department_id: department ?? null,
        }),
      );
      dispatch(
        getjobsDropdown({ company_id: company, department_id: department ?? undefined, open_jobs: true })
      );
      dispatch(getDepartmentsDropdown(company));
    }
    return () => { };
  }, [company, department]);

  function handleFieldChange(fieldId, value) {
    setFormState({ ...formState, [fieldId]: value });
  }

  function getFirstStatus() {
    if (props.interviewStatusList.length != 0) {
      return props.interviewStatusList[0].id;
    }
    return "";
  }

  function handleCompanyChange(value: string) {
    if (value && value !== "") {
      setCompany(value);
      setFormState({
        ...formState,
        company_id: value,
        job_order_id: "",
        contact_id: "",
        department_id: "",
      });
    } else {
      dispatch(getDepartmentsDropdown(value));
      setCompany(null);
      setDepartment(null);
      setFormState({
        ...formState,
        job_order_id: "",
        contact_id: "",
        company_id: "",
        department_id: "",
      });
    }
  }
  function handleDepartmentChange(value: string) {
    if (value && value != "") {
      setDepartment(value);
      setFormState({
        ...formState,
        job_order_id: "",
        contact_id: "",
        department_id: value,
      });
    } else {
      setDepartment(null);
      setFormState({
        ...formState,
        job_order_id: "",
        contact_id: "",
        department_id: "",
      });
    }
  }

  function handleOnChange(value) {
    setSendCalendarInvite(value);
  }

  return (
    <div className="jobs-clnt-in-f-container">
      <div className="tlnt-clnt-in-f-header">
        <FormDialogTilteHeader
          title={props.title}
          titleIcon={
            <ChatIcon
              width={"100%"}
              height={"100%"}
              style={{ color: THEME.talentOptionTextColor }}
            />
          }
        />
      </div>
      <div className="tlnt-clnt-in-f-content">
        {formUI()}
        {props.error && (
          <div className={"error-section"}>
            <ApiError
              message={props.error.message}
              onClose={props.onClearError}
            />
          </div>
        )}
      </div>
      <div className="tlnt-clnt-in-f-actions">
        <div>
          <CustomCheckBox
            name={"send_invite"}
            title={formState.id ? t("send_updated_calendar_invite") : t("send_calendar_invite")}
            checked={sendCalendarInvite}
            onClick={() => handleOnChange(!sendCalendarInvite)}
          />
        </div>
        <div className="btn-cancel">
          <CustomButton
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("cancel")}
            enable={true}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={props.onCancel}
          />
        </div>

        <div className="btn-save">
          <CustomButton
            loading={props.loading}
            textStyle={{ textTransform: "capitalize" }}
            name={formState.id ? t('update') : t('save')}
            // enable={_isFormValid()}
            enable={true}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={() => {
              setCheckValidationOnSubmit(true)
              if (_isFormValid()) {
                props.onSubmit({
                  ...formState,
                  // interview_date: formState.interview_date === 0 ? null : formState.interview_date
                  interview_date: dateAndTimeTimeStampValue
                }, sendCalendarInvite)
                setCheckValidationOnSubmit(false)
              }
            }}
          />
        </div>
      </div>
    </div>
  );

  function _isFormValid() {
    const {
      contact_id,
      talent_id,
      //job_order_id,
      interview_date,
      // department_id,
      company_id,
      interview_schedule_date,
      interview_schedule_time,
    } = formState;

    if (
      talent_id === "" ||
      contact_id === "" ||
      // job_order_id === "" ||
      company_id === "" 
      // interview_schedule_date === 0 ||
      // interview_schedule_time === "" ||
      // formState[formOptions.interview_date.field] === null ||
      // formState[formOptions.interview_time.field] === ""|| 
      // formState[formOptions.date_submitted.field]===null ||
      // department_id === "" ||
      // !interview_date
    )
      return false;

    return true;
  }

  function talentFormValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case formOptions.talent.field:
      case formOptions.interview_with.field: {
        if (value.text === "" || value.text === null || value.text === undefined) return t("validators.required");
        return null;
      }
      // case formOptions.interview_date.field:
      // case formOptions.interview_time.field: {
      //   if (!value.text) return t("validators.required");
      //   return null;
      // }

      default: {
        return null;
      }
    }
  }

  function formUI() {
    return (
      <div className="tlnt-clnt-in-f-section-divider">
        <div className="tlnt-clnt-in-f-row" style={{ marginBottom: "1em" }}>
          <div
            key={formOptions.company.field}
            className={"tlnt-clnt-in-f-input-holder-first-row"}
          >
            <CustomFormSelect
              customStyle={{ width: "-webkit-fill-available" }}
              name={formOptions.company.field}
              value={formState["company_id"] ?? company}
              required={formOptions.company.required}
              label={t(formOptions.company.label)}
              placeholder={t(formOptions.company.placeholder)}
              list={companiesDropdown.map((doc) => ({
                label: doc.name,
                value: doc.id,
              }))}
              onChange={(value) => {
                handleCompanyChange(value);
              }}
              disabled={true}
            />
          </div>
          <div
            key={formOptions.department.field}
            className={"tlnt-clnt-in-f-input-holder-first-row"}
          >
            <CustomFormSelect
              customStyle={{ width: "-webkit-fill-available" }}
              name={formOptions.department.field}
              value={formState["department_id"] ?? department}
              required={formOptions.department.required}
              label={t(formOptions.department.label)}
              placeholder={t(formOptions.department.placeholder)}
              list={departmentsDropdown.map((doc) => ({
                label: doc.name,
                value: doc.id,
              }))}
              onChange={(value) => {
                handleDepartmentChange(value);
              }}
              disabled={true}

            />
          </div>
          <div
            key={formOptions.job_order.field}
            className={"tlnt-clnt-in-f-input-holder-first-row"}
          >
            <CustomFormSelect
              customStyle={{ width: "-webkit-fill-available" }}
              name={formOptions.job_order.field}
              value={formState[formOptions.job_order.field]}
              required={formOptions.job_order.required}
              label={t(formOptions.job_order.label)}
              placeholder={t(formOptions.job_order.placeholder)}
              list={jobsDropdown.map((doc) => ({
                label: doc.name,
                value: doc.id,
              }))}
              onChange={(value) =>
                handleFieldChange(formOptions.job_order.field, value)
              }
              disabled={true}
            />
          </div>
        </div>
        <div className="tlnt-clnt-in-f-row" style={{ marginBottom: "1em" }}>
          <div className="tlnt-clnt-in-f-input-holder-first-row">
            <ToggleSwitch
              label={t("candidate_submitted")}
              checked={formState.candidate_submitted!}
              onChange={(value) => handleFieldChange("candidate_submitted", value)}
              labelPosition="top"
            />
          </div>
          <div
            key={formOptions.talent.field}
            className={"tlnt-clnt-in-f-input-holder-first-row"}
          >
            <CustomFormSelect
              list={talentList.map((doc) => ({
                label: doc.name,
                value: doc.id,
              }))}
              customStyle={{ width: "-webkit-fill-available" }}
              name={formOptions.talent.field}
              value={formState[formOptions.talent.field]}
              required={formOptions.talent.required}
              label={t(formOptions.talent.label)}
              placeholder={t(formOptions.talent.placeholder)}
              onChange={(value) =>
                handleFieldChange(formOptions.talent.field, value)
              }
              disabled={(props.interview || props.talent_id) ? true : false}
              checkValidationOnSubmit={checkValidationOnSubmit}
              validations={talentFormValidators}

            />
          </div>
          <div
            key={formOptions.date_submitted.field}
            className={"tlnt-clnt-in-f-input-holder-first-row"}
          >
            <DateInput

              checkValidationOnSubmit={checkValidationOnSubmit}
              validations={talentFormValidators}
              id={formOptions.date_submitted.field}
              onChange={handleFieldChange}
              secure={formOptions.date_submitted.secure}
              required={formOptions.date_submitted.required}
              placeholder={formOptions.date_submitted.placeholder}
              value={formState[formOptions.date_submitted.field]}
              label={t(formOptions.date_submitted.label)}
              setHoursToZero={true}
            />
          </div>
        </div>
        <div className="tlnt-clnt-in-f-row" style={{ marginBottom: "1em" }}>
          <div
            key={formOptions.talent_interview_status_id.field}
            className={"tlnt-clnt-in-f-input-holder"}
          >
            <CustomFormSelect
              customStyle={{ width: "-webkit-fill-available" }}
              name={formOptions.talent_interview_status_id.field}
              value={formState[formOptions.talent_interview_status_id.field]}
              required={formOptions.talent_interview_status_id.required}
              label={t(formOptions.talent_interview_status_id.label)}
              placeholder={t(
                formOptions.talent_interview_status_id.placeholder
              )}
              list={props.interviewStatusList.map((doc) => ({
                label: doc.title,
                value: doc.id,
              }))}
              onChange={(value) =>
                handleFieldChange(
                  formOptions.talent_interview_status_id.field,
                  value
                )
              }
              doNotTranslate={true}
            />
          </div>
          <div
            key={formOptions.interview_with.field}
            className={"tlnt-clnt-in-f-input-holder"}
          >
            <CustomFormSelect
              customStyle={{ width: "-webkit-fill-available" }}
              name={formOptions.interview_with.field}
              value={formState[formOptions.interview_with.field]}
              required={formOptions.interview_with.required}
              label={t(formOptions.interview_with.label)}
              placeholder={t(formOptions.interview_with.placeholder)}
              list={mapOptions(
                contactsList.data ?? [],
                "contact_name", 
                "id"
              )}
              onChange={(value) =>
                handleFieldChange(formOptions.interview_with.field, value)
              }
              checkValidationOnSubmit={checkValidationOnSubmit}
              validations={talentFormValidators}
            />
          </div>
          <div
            key={formOptions.interview_date.field}
            className={"tlnt-clnt-in-f-input-holder"}
          >
            <DateInput
              checkValidationOnSubmit={checkValidationOnSubmit}
              validations={talentFormValidators}
              id={formOptions.interview_date.field}
              onChange={handleFieldChange}
              secure={formOptions.interview_date.secure}
              required={formOptions.interview_date.required}
              placeholder={formOptions.interview_date.placeholder}
              value={formState[formOptions.interview_date.field]}
              label={t(formOptions.interview_date.label)}
              setHoursToZero={true}
            />
          </div>
          <div
            key={`${formOptions.interview_time.field}_self_time`}
            className={"tlnt-clnt-in-f-input-holder"}
          >
            <TimePickerComponent
              id={formOptions.interview_time.field}
              onChange={handleFieldChange}
              placeholder={formOptions.interview_time.placeholder}
              value={formState[formOptions.interview_time.field]}
              label={t(formOptions.interview_time.label)}
              icon={<Timer />}
              required={formOptions.interview_time.required}
              checkValidationOnSubmit={checkValidationOnSubmit}
              validations={talentFormValidators}
            />
          </div>
        </div>
        <div className="tint-ihi-questions-section">
          <div className="tint-ihi-question-container">
            <div className="tint-ihi-question-title">
              <span>{t("interview_feedback")}</span>
            </div>
            <div className="tint-ihi-input-form">
              <div className="tlnt-f-row">
                <div
                  key={formOptions.talent_interview_feedback.field}
                  className={"tlnt-f-input-holder"}
                >
                  <FormInput
                    id={formOptions.talent_interview_feedback.field}
                    onChange={handleFieldChange}
                    secure={formOptions.talent_interview_feedback.secure}
                    required={formOptions.talent_interview_feedback.required}
                    placeholder={
                      formOptions.talent_interview_feedback.placeholder
                    }
                    type={formOptions.talent_interview_feedback.type}
                    value={
                      formState[formOptions.talent_interview_feedback.field]
                    }
                    label={`${t(
                      formOptions.talent_interview_feedback.label
                    ).toUpperCase()}:`}
                    keyboardtype={
                      formOptions.talent_interview_feedback?.keyboardtype
                    }
                    multiline={formOptions.talent_interview_feedback.multiline}
                    labelStyles={{ fontWeight: "normal" }}
                  />
                </div>
                <div
                  key={formOptions.contact_interview_feedback.field}
                  className={"tlnt-f-input-holder"}
                >
                  <FormInput
                    id={formOptions.contact_interview_feedback.field}
                    onChange={handleFieldChange}
                    secure={formOptions.contact_interview_feedback.secure}
                    required={formOptions.contact_interview_feedback.required}
                    placeholder={
                      formOptions.contact_interview_feedback.placeholder
                    }
                    type={formOptions.contact_interview_feedback.type}
                    value={
                      formState[formOptions.contact_interview_feedback.field]
                    }
                    label={`${t(
                      formOptions.contact_interview_feedback.label
                    ).toUpperCase()}:`}
                    keyboardtype={
                      formOptions.contact_interview_feedback?.keyboardtype
                    }
                    multiline={formOptions.contact_interview_feedback.multiline}
                    labelStyles={{ fontWeight: "normal" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tint-ihi-questions-section">
          <div className="tlnt-f-row">
            <div
              key={formOptions.recruiter_notes.field}
              className={"tlnt-f-input-holder"}
            >
              <FormInput
                id={formOptions.recruiter_notes.field}
                onChange={handleFieldChange}
                secure={formOptions.recruiter_notes.secure}
                required={formOptions.recruiter_notes.required}
                placeholder={
                  formOptions.recruiter_notes.placeholder
                }
                type={formOptions.recruiter_notes.type}
                value={
                  formState[formOptions.recruiter_notes.field]
                }
                label={`${t(
                  formOptions.recruiter_notes.label
                ).toUpperCase()}:`}
                keyboardtype={
                  formOptions.recruiter_notes?.keyboardtype
                }
                multiline={formOptions.recruiter_notes.multiline}
                labelStyles={{ fontWeight: "normal" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ClientCompanyInterviewForm;

import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./friday_calls_reports_thunk";
import { IReportCenterService } from "../../../../interfaces/report_center_service";

export interface FridayCallsReportsListState extends EntityState<IReportCenterService> {
    error?: AppError | null,
    loading: LoadingType,  
}

const fridayCallsReportsListAdapter: EntityAdapter<IReportCenterService> = createEntityAdapter<IReportCenterService>({
    selectId: (doc) => doc.id,
})

const initialState: FridayCallsReportsListState = fridayCallsReportsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const fridayCallsReportsListSlice = createSlice({
    name: 'fridayCallsReportsList',
    initialState: initialState,
    reducers: {
        clearFridayCallsReportsListError(state) {
            return { ...state, error: null };
        },
        clearFridayCallsReportsListState(state) {
            return fridayCallsReportsListAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getFridayCallsReportsList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getFridayCallsReportsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getFridayCallsReportsList.fulfilled, (state, action) => fridayCallsReportsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearFridayCallsReportsListError, clearFridayCallsReportsListState } = fridayCallsReportsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = fridayCallsReportsListAdapter.getSelectors();
export const selectState = (state: FridayCallsReportsListState) => state;



import { RouteComponentProps } from "react-router-dom"
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../../components/table_empty/table_empty";
import { useTranslation } from "react-i18next";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import SearchBar from "../../../../../components/search_bar";
import { useEffect, useState } from "react";
import { AppRoutes } from "../../../../../routes";
import { currencyConversion, CustomButton } from "../../../../../utils";
import { getARAgingReportList, getInvoiceList, getReportDownloadURL, getRoleUsersList, selectAccountManagerRoleUsersList, selectARAgingReportListState, selectReportsDownloadState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { IARAgingReport, IARAgingReportRecords, IInvoiceList } from "../../../../../interfaces/report_center_finance_accounting";
import { LoadingType, THEME } from "../../../../../enums";
import { convertDateToTimeStamp, getDateString } from "../../../../../variables";
import CustomFormSelect, { CustomMultiFormSelect } from "../../../../../components/form_selector/form_select";
import InvoiceListPopup from "../popups/invoice_list/invoice_list";
import { Portal } from "react-portal";
import { DateInput } from "../../../../../components/date_input/date_input";
import { DownloadIcon } from "../../../../../icons";
import { reportDownloadSliceActions } from "../../../../../redux/report_center/download_report/download_report_reducer";
import UrlBuilder from "../../../../../apis/url_builder";
import './ar_aging_report.scss'
import ApiError from "../../../../../components/api_error";
import { clearARAgingReportListError } from "../../../../../redux/report_center/finance_accounting/ar_aging_report/ar_aging_report_reducer";

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
    { title: "customer", code: "company_name" },
    { title: "1-30", code: "ONE_TO_THIRTY" },
    { title: "31-60", code: "THIRTY_ONE_TO_SIXTY" },
    { title: "61-90", code: "SIXTY_ONE_TO_NINETY" },
    { title: "90 plus", code: "NINETY_PLUS" },
    { title: "total", code: "total" },
    { title: "", code: "" },
];

const tableInvoiceListHeader = [
    { title: "customer", code: "customer" },
    { title: "department", code: "department" },
    { title: "invoice_date", code: "invoice_date" },
    { title: "invoice_number", code: "invoice_number" },
    { title: "date_due", code: "due_date" },
    { title: "days_past_due", code: "days_past_due" },
    { title: "original_amount", code: "amount" },
    { title: "open_balance", code: "balance_amount" },
];

const ARAgingReport: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const accountManagersList = useAppSelector((state) => selectAccountManagerRoleUsersList(state));
    const aRAgingReportListState = useAppSelector((state) => selectARAgingReportListState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));

    const [aRAgingReportList, setaRAgingReportList] = useState<IARAgingReport | null>(null);
    const [invoiceList, setInvoiceList] = useState<IInvoiceList | null>(null);
    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");
    const [company, setCompany] = useState<string>("");
    const [accountManagers, setAccountManagers] = useState<string[]>([]);
    const [openInvoiceList, setOpenInvoiceList] = useState<{ visible: boolean, company_id: string, aging_type: string } | null>(null)
    const [companiesList, setCompaniesList] = useState<{ label: string, value: string }[]>([])

    const [asOfDate, setAsOfDate] = useState<number>(convertDateToTimeStamp(new Date()));
    const loadingState = aRAgingReportListState.loading === LoadingType.pending;

    useEffect(() => {
        dispatch(getRoleUsersList());
    }, [])

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    useEffect(() => {
        if (asOfDate || asOfDate === null) {
            getARAReport();
        }
    }, [asOfDate]);

    useEffect(() => {
        if (aRAgingReportListState && aRAgingReportListState?.loading === LoadingType.succeeded) {
            setaRAgingReportList(aRAgingReportListState?.response);
            const updatedCompaniesList = aRAgingReportListState?.response?.records?.reduce<{ label: string; value: string }[]>((acc, record) => {
                if (!acc.some(item => item.value === record.company_id)) {
                    acc.push({ label: record.company_name, value: record.company_id });
                }
                return acc;
            }, [])!;
            setCompaniesList(updatedCompaniesList);
        }
        return () => {
            setaRAgingReportList(null) 
        }
    }, [aRAgingReportListState?.loading]);

    useEffect(() => {
        if (aRAgingReportListState?.invoiceList?.loading && aRAgingReportListState?.invoiceList?.loading === LoadingType.succeeded) {
            setInvoiceList(aRAgingReportListState?.invoiceList?.response);
        }
    }, [aRAgingReportListState?.invoiceList?.loading]);

    const getARAReport = () => {
        dispatch(getARAgingReportList(asOfDate));
    };

    const getInvoices = () => {
        if (openInvoiceList && asOfDate) {
            dispatch(getInvoiceList({ company_id: openInvoiceList?.company_id, end_date: asOfDate, aging_type: openInvoiceList?.aging_type.toString() }))
        }
    }

    const handleDateChange = (id: string, value: any) => {
        setAsOfDate(value);
    };

    function handleDownload() {
        if (asOfDate) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.aRAgingReport,
                    as_of_date: asOfDate,
                    company_id: company,
                    download: true,
                    downloadAR: { account_managers: accountManagers },
                }));
        }
    }

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function handleCompanySelect(value: IARAgingReportRecords) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            },
        });
        return;
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 2;
        return 1;
    }

    const showSpinner = () => aRAgingReportListState?.loading === LoadingType.pending;

    const getFilteredList = () => {
        let list: IARAgingReportRecords[] | undefined = aRAgingReportList?.records;
        let resultList: IARAgingReportRecords[] | undefined;

        resultList = list?.filter(doc => {
            const hasCompany = company && company !== "";
            const hasAccountManagers = accountManagers.length > 0;

            // Check if the document matches the company condition
            const companyMatch = !hasCompany || doc.company_id === company;

            // Check if the document matches the account managers condition
            const accountManagersMatch = !hasAccountManagers || (
                doc.account_managers &&
                doc.account_managers.length > 0 &&
                doc.account_managers.some(manager => accountManagers.includes(manager))
            );

            // Return true if both conditions are met
            return companyMatch && accountManagersMatch;
        });


        if (aRAgingReportList && sortedField != null) {
            list = [...aRAgingReportList.records].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return (resultList ?? list)?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }

    function getFilteredListSumAmount(type) {
        switch (type) {
            case "ONE_TO_THIRTY": {
                const TotalOneToThirty = aRAgingReportList?.records && aRAgingReportList?.records?.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.ONE_TO_THIRTY
                }, 0) : 0;
                return TotalOneToThirty;
            }
            case "THIRTY_ONE_TO_SIXTY": {
                const TotalThirtyOneToSixty = aRAgingReportList?.records && aRAgingReportList?.records?.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.THIRTY_ONE_TO_SIXTY
                }, 0) : 0;
                return TotalThirtyOneToSixty;
            }
            case "SIXTY_ONE_TO_NINETY": {
                const TotalSixtyOneToNinety = aRAgingReportList?.records && aRAgingReportList?.records?.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.SIXTY_ONE_TO_NINETY
                }, 0) : 0;
                return TotalSixtyOneToNinety;
            }
            case "NINETY_PLUS": {
                const TotalNintyPlus = aRAgingReportList?.records && aRAgingReportList?.records?.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.NINETY_PLUS
                }, 0) : 0;
                return TotalNintyPlus;
            }
            case "total": {
                const Total = aRAgingReportList?.records && aRAgingReportList?.records?.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.total
                }, 0) : 0;
                return Total;
            }
        }
    }

    const getTable = () => {
        if (showSpinner()) return <TableLoading />;

        if (aRAgingReportListState?.error != null && aRAgingReportList?.records?.length === 0) {
            return <TableErrorHandler error={aRAgingReportListState?.error} onRefresh={getARAReport} />;
        }

        if (aRAgingReportList?.records?.length === 0 || getFilteredList()?.length === 0) {
            return <TableEmpty title={"No Data found"} onClick={getARAReport} />;
        }

        if (getFilteredList()?.length !== 0) {
            return (
                <div className="ar-ar-table">
                    <SortableTable
                        headerList={tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {getFilteredList()?.map((doc, index) => {
                            return (
                                <tr key={doc.company_id}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}
                                        isButton={true}
                                        onClick={() => handleCompanySelect(doc)}
                                    >
                                        <span>{doc.company_name}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1) }}
                                        isButton={true}
                                        onClick={() => setOpenInvoiceList({ visible: true, company_id: doc.company_id, aging_type: "ONE_TO_THIRTY" })}
                                    >
                                        <span>{currencyConversion(doc.ONE_TO_THIRTY)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2) }}
                                        isButton={true}
                                        onClick={() => setOpenInvoiceList({ visible: true, company_id: doc.company_id, aging_type: "THIRTY_ONE_TO_SIXTY" })}
                                    >
                                        <span>{currencyConversion(doc.THIRTY_ONE_TO_SIXTY)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}
                                        isButton={true}
                                        onClick={() => setOpenInvoiceList({ visible: true, company_id: doc.company_id, aging_type: "SIXTY_ONE_TO_NINETY" })}
                                    >
                                        <span>{currencyConversion(doc.SIXTY_ONE_TO_NINETY)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(4) }}
                                        isButton={true}
                                        onClick={() => setOpenInvoiceList({ visible: true, company_id: doc.company_id, aging_type: "NINETY_PLUS" })}
                                    >
                                        <span>{currencyConversion(doc.NINETY_PLUS)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                        <span>{currencyConversion(doc.total)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                        <span></span>
                                    </TableData>
                                </tr>
                            )
                        })}
                    </SortableTable>
                </div>
            );
        }
    };

    const handleClearError = () => {
        dispatch(clearARAgingReportListError())
    }

    return (
        <div className="ar-aging-report-container">
            <div>
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>
            <div className="ar-aging-report-header">
                <div className="arar-h-left">
                    <div className="dateRange">
                        <DateInput
                            id={'as_of_date'}
                            onChange={handleDateChange}
                            placeholder={t("mm/dd/yyyy")}
                            value={asOfDate}
                            label={t('as_of')}
                            disabled={loadingState}
                        />
                    </div>
                    <div className="select-type">
                        <CustomMultiFormSelect
                            label={t("account_manager")}
                            name={"account_manager"}
                            value={accountManagers}
                            placeholder={"All"}
                            list={accountManagersList?.map((doc) => ({
                                label: `${doc.first_name ?? ''} ${doc.last_name ?? ''}`,
                                value: doc.id,
                            }))}
                            onChange={(value) => {
                                setAccountManagers(value);
                            }}
                            disabled={loadingState}
                        />
                    </div>
                    <div className="select-type">
                        <CustomFormSelect
                            label={t("customer_name")}
                            name={"company_id"}
                            value={company}
                            placeholder={"All"}
                            list={companiesList?.map((doc) => ({
                                label: doc.label,
                                value: doc.value,
                            }))}
                            onChange={(value) => {
                                setCompany(value);
                            }}
                            disabled={loadingState}
                        />
                    </div>
                    {aRAgingReportListState?.error && <div className="error-holder">
                        <ApiError message={aRAgingReportListState?.error?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="arar-h-right">
                    <div className="total-count-action">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                    <div className="select-type">
                        <CustomButton
                            leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                            loading={false}
                            name={t("download")}
                            enable={getFilteredList()?.length !== 0 && (loadingState ? false : true)}
                            backgroundColor={THEME.buttonColor16}
                            onClick={() => handleDownload()}
                        />
                    </div>
                    <div className="select-type">
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                        />
                    </div>
                </div>
            </div>

            <div className="table-container">
                <div className="header-container">
                    <div className="table-header">
                        <div className="table-header-title">
                            <span>{t("aging_report")}</span>
                        </div>
                        <div className="total-count-action">
                            <span>
                                {`${t("as_of")}: `}
                                <span className="total-count-number">
                                    {getDateString(asOfDate)}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                {getTable()}
            </div>
            {aRAgingReportListState.loading === LoadingType.succeeded && <div>
                <div className="total-counts-card">
                    <div className="total-count">
                        Totals
                    </div>
                    <div className="total-count">
                        {currencyConversion(getFilteredListSumAmount("ONE_TO_THIRTY"))}
                    </div>
                    <div className="total-count">
                        {currencyConversion(getFilteredListSumAmount("THIRTY_ONE_TO_SIXTY"))}
                    </div>
                    <div className="total-count">
                        {currencyConversion(getFilteredListSumAmount("SIXTY_ONE_TO_NINETY"))}
                    </div>
                    <div className="total-count">
                        {currencyConversion(getFilteredListSumAmount("NINETY_PLUS"))}
                    </div>
                    <div className="total-count">
                        {currencyConversion(getFilteredListSumAmount("total"))}
                    </div>
                </div>
            </div>}
            {openInvoiceList?.visible &&
                <Portal>
                    <InvoiceListPopup
                        asOfDate={asOfDate}
                        visible={openInvoiceList.visible}
                        openInvoiceList={openInvoiceList}
                        tableHeader={tableInvoiceListHeader}
                        invoiceListState={aRAgingReportListState?.invoiceList}
                        invoiceList={invoiceList}
                        history={props.history}
                        onClose={() => setOpenInvoiceList(null)}
                        onRetry={getInvoices}
                    />
                </Portal>}
        </div >
    );
}

export default ARAgingReport;
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import CurveTabNavigation from '../../../../components/curve_tab_navigation/curve_tab_navigation';
import { CompaniesIcon, InHouseInterviewIcon } from '../../../../icons';
import { ITalentClientCompanyInterview, ITalentInHouseInterview } from '../../../../interfaces';
import { getInterviewsToClientCompanies, getTalentInHouseInterviews, selectInHouseInterviewsState, selectSingleInHouseInterviewsList, selectSingleInHouseInterviewsStateById, selectSingleInterviewsToClientCompaniesStateById, selectTalentDetailsByIdState, updateTalentInHouseInterview, useAppDispatch, useAppSelector } from '../../../../redux/store';
import { talentClientCompanyInterviewActions } from '../../../../redux/talent/details/interviews/interviews_to_client_companies/interviews_to_client_companies_reducer';
import { talentInHouseInterviewActions } from '../../../../redux/talent/details/interviews/in_house_interviews/in_house_interviews_reducer';
import ClientCompanyInterviewPopup from '../../popups/client_company_interview/client_company_interview';
import InHouseInterviewPopup from '../../popups/in_house_interview/in_house_interview';
import './interviews.scss';
import InterviewSendOutsToClientCompanies from './interview_send_outs_to_client_companies/interview_send_outs_to_client_companies';
import InHouseInterviews from './in_house_interviews/in_house_interviews';
import { AppRoutes } from '../../../../routes';
import { dateToTimestamp, extractDateFromTimestamp, extractTimeFromTimestamp } from '../../../../variables';

interface Props extends RouteComponentProps<any> { }
interface State {
    id: string;
    name: string;
}
const Interviews: React.FunctionComponent<Props> = (props) => {
    const { history, location, match } = props;
    const { params } = match;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState<number>(0);
    const talentState = useAppSelector((state) => selectTalentDetailsByIdState(params.id)(state));

    /// [InHouse Interviews]
    const inHouseInterviewsState = useAppSelector((state) => selectSingleInHouseInterviewsStateById(params.id)(state));
    const interviewsList = useAppSelector((state) => selectSingleInHouseInterviewsList(params.id)(state));
    const inHouseUpdateState = inHouseInterviewsState.add;

    const [editInHouseInterview, setEditInHouseInterview] = useState<{ visible: boolean, value: ITalentInHouseInterview | null }>({ visible: false, value: null });
    const [selectedInHouseInterview, setSelectedInHouseInterview] = useState<ITalentInHouseInterview | null>(null);

    /// [Client Company Interview]
    const clientCompanyInterviewsState = useAppSelector((state) => selectSingleInterviewsToClientCompaniesStateById(params.id)(state));
    const clientCompanyUpdateState = clientCompanyInterviewsState.add;

    const [editClientCompanyInterview, setEditClientCompanyInterview] = useState<{ visible: boolean, value: ITalentClientCompanyInterview | null }>({ visible: false, value: null });
    const [selectedClientCompanyInterview, setSelectedClientCompanyInterview] = useState<ITalentClientCompanyInterview | null>(null);

    const getFirstInterview = (data) => data && typeof data === 'object' ? data[Object.keys(data)[0]] : {};

    const firstInHouseInterview = getFirstInterview(inHouseInterviewsState?.data);
    const firstClientComapnyInterview = getFirstInterview(clientCompanyInterviewsState?.data);
    const talentName = history.location.state as State;
    const [jobdetails, setJobDetails] = useState<{job_id: string, company_id: string, department_id: string, name: string} | null>(null);
    const [currentTalentName, setCurrentTalentName]= useState<string>("")

    useEffect(() => {
        getInHouseInterviews();
        getClientCompanyInterviews();
        return () => { }
    }, [])

    useEffect(() => {
        if (selectedInHouseInterview != null) {
            const findSelected = inHouseInterviewsState.data[selectedInHouseInterview.id];
            if (findSelected) handleOnInHouseInterviewChange(findSelected);
        } else {
            setSelectedInHouseInterview(firstInHouseInterview)
        }
        return () => { }
    }, [inHouseInterviewsState.loading])

    useEffect(() => {
        if (selectedClientCompanyInterview != null) {
            const findSelected = clientCompanyInterviewsState.data[selectedClientCompanyInterview.id];
            if (findSelected) handleOnClientComapnyInterviewChange(findSelected);
        } else {
            setSelectedClientCompanyInterview(firstClientComapnyInterview)
        }
        return () => { }
    }, [clientCompanyInterviewsState.loading])

    useEffect(() => {
        const state = location.state as any;
        if (state) {
            if (state["interview"]) {
                setJobDetails({job_id: state["job_order_id"], company_id: state["company_id"] , department_id: state["department_id"], name: state["name"]});
                setCurrentTalentName(state["name"])
                handleAddNewClientCompanyInterview();
                const updatedState = { ...(history.location.state || {}), interview: false };
                history.replace({ ...history.location, state: updatedState });
                window.history.replaceState({}, document.title);
            }
        }
    }, [location]);

    function getInHouseInterviews() {
        dispatch(getTalentInHouseInterviews(params.id));
    }

    function getClientCompanyInterviews() {
        dispatch(getInterviewsToClientCompanies(params.id));
    }

    /// Interviews


    function handleOnInHouseInterviewChange(value: ITalentInHouseInterview) {
        setSelectedInHouseInterview(value);
    }
    function handleOnInHouseInterviewClose() {
        setSelectedInHouseInterview(null);
    }

    function handleInHouseInterviewClearError() {
        dispatch(talentInHouseInterviewActions.clearSingleTalentInHouseInterviewsStateError(params.id));
    }

    function handleInHouseInterviewSaveChanges(value: ITalentInHouseInterview) {
        dispatch(updateTalentInHouseInterview({ talentId: params.id, data: value }));
    }


    /// [Client company Interviews]
    function handleOnClientComapnyInterviewChange(value: ITalentClientCompanyInterview) {
        setSelectedClientCompanyInterview(value);
    }
    function handleOnClientComapnyInterviewClose() {
        setSelectedClientCompanyInterview(null);
    }

    const handleCompanySelect = () => {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${selectedClientCompanyInterview?.company_id}`,
            state: {
                id: selectedClientCompanyInterview?.company_id,
                name: selectedClientCompanyInterview?.company_name,
            },
        });

    }
    const handleDeptSelect = () => {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${selectedClientCompanyInterview?.company_id}/dashboard/${selectedClientCompanyInterview?.department_id}`,
            state: {
                id: selectedClientCompanyInterview?.company_id,
                name: selectedClientCompanyInterview?.company_name,
                departmentId: selectedClientCompanyInterview?.department_id,
                departmentName: selectedClientCompanyInterview?.department_name,
            },
        });

    }
    const handleContactSelect = () => {
        props.history.push({
            pathname: `${AppRoutes.contactsDetailsPage}/${selectedClientCompanyInterview?.contact_id}`,
            state: {
                id: selectedClientCompanyInterview?.contact_id,
                name: selectedClientCompanyInterview?.interview_with,
            },
        });
    }


    return (
        <div className="tlnt-interviews">
            <CurveTabNavigation
                tabs={getTabList()}
                selected={tabIndex}
                onChange={(value) => setTabIndex(value)}
            // actions={getEditAction()}
            />
            {editInHouseInterview.visible && <InHouseInterviewPopup
                visible={editInHouseInterview.visible}
                title={editInHouseInterview.value ? t('edit_interview') : t('new_interview')}
                talentId={params.id}
                interview={editInHouseInterview.value}
                onClose={() => setEditInHouseInterview({ visible: false, value: null })}
                onDissmiss={() => setEditInHouseInterview({ visible: false, value: null })}
                onSuccessClose={handleOnEditInhouseInterviewSuccess}
                onlineAppDate={talentState?.data?.online_application_date}
            />}
            {editClientCompanyInterview.visible && <ClientCompanyInterviewPopup
                visible={editClientCompanyInterview.visible}
                title={editClientCompanyInterview.value ? t('edit_client_company_interview') : t('new_client_company_interview')}
                talentId={params.id}
                jobdetails={jobdetails}
                talentName={talentName?.name!}
                currentTalentName={currentTalentName}
                interview={editClientCompanyInterview.value}
                onClose={() => {
                    setEditClientCompanyInterview({ visible: false, value: null });
                    setJobDetails(null);
                }}
                onDissmiss={() => {
                    setEditClientCompanyInterview({ visible: false, value: null });
                    setJobDetails(null);
                }}
                onSuccessClose={handleOnEditClientCompanyInterviewSuccess}
            />}
        </div>

    )

    function getTabList() {
        return [
            {
                title: t('in_house_interviews').toUpperCase(),
                icon: InHouseInterviewIcon,
                content: <InHouseInterviews
                    onInterviewClick={handleOnInHouseInterviewChange}
                    onAddNew={handleAddNewInHouseInterview}
                    state={inHouseInterviewsState}
                    onRefresh={getInHouseInterviews}
                    selectedInterview={selectedInHouseInterview}
                    onEditInterview={handleEditInterview}
                    onCloseInterview={handleOnInHouseInterviewClose}
                />,
            },
            {
                title: t('interview_send_outs_to_client_companies').toUpperCase(),
                icon: CompaniesIcon,
                content: <InterviewSendOutsToClientCompanies
                    onInterviewClick={handleOnClientComapnyInterviewChange}
                    onAddNew={handleAddNewClientCompanyInterview}
                    state={clientCompanyInterviewsState}
                    onRefresh={getClientCompanyInterviews}
                    selectedInterview={selectedClientCompanyInterview}
                    onEditInterview={handleEditClientCompanyInterview}
                    onCloseInterview={handleOnClientComapnyInterviewClose}
                    handleCompanySelect={handleCompanySelect}
                    handleDeptSelect={handleDeptSelect}
                    handleContactSelect={handleContactSelect}
                />,
            },
        ];
    }

    /// [InHouseInterview]

    function handleAddNewInHouseInterview() {
        setEditInHouseInterview({ visible: true, value: null });
    }

    function handleOnEditInhouseInterviewSuccess() {
        setEditInHouseInterview({ visible: false, value: null });
        dispatch(talentInHouseInterviewActions.clearSingleTalentInHouseInterviewsManageState(params.id));
        getInHouseInterviews();

    }

    function handleEditInterview(value: ITalentInHouseInterview) {
        const dateSting = extractDateFromTimestamp(value?.interview_date!)
        const dateTimeStamp = dateToTimestamp(dateSting)
        const time = extractTimeFromTimestamp(value?.interview_date!)
        const updatedInhouseInterview = {
            ...value,
            interview_schedule_date: (value?.interview_date! !== 0 && value?.interview_date! !== -19800) ? dateTimeStamp : 0,
            interview_schedule_time: value?.interview_date! !== 0 ? time : "12:00 AM"
        }
        setEditInHouseInterview({ visible: true, value: updatedInhouseInterview });
    }

    /// [Client Company Interviews]

    function handleAddNewClientCompanyInterview() {
        setEditClientCompanyInterview({ visible: true, value: null });
    }

    function handleOnEditClientCompanyInterviewSuccess() {
        setEditClientCompanyInterview({ visible: false, value: null });
        dispatch(talentClientCompanyInterviewActions.clearSingleTalentInterviewsToClientCompaniesStateError(params.id));
        getClientCompanyInterviews();
        setJobDetails(null);
    }

    function handleEditClientCompanyInterview(value: ITalentClientCompanyInterview) {
        const dateSting = extractDateFromTimestamp(value?.interview_date!)
        const dateTimeStamp = dateToTimestamp(dateSting)
        const time = extractTimeFromTimestamp(value?.interview_date!)
        const updatedClientCompanyInterview = {
            ...value,
            interview_schedule_date: (value?.interview_date! !== 0 && value?.interview_date! !== -19800) ? dateTimeStamp : 0,
            interview_schedule_time: value?.interview_date! !== 0 ? time : "12:00 AM"
        }
        setEditClientCompanyInterview({ visible: true, value: updatedClientCompanyInterview });
        // setEditClientCompanyInterview({ visible: true, value: value });
    }

}

export default Interviews;
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { approveTCErrorThunk, getTCERListThunk, getTimeCardsErrorReportDownloadUrl, getWeekEndingDatesDropdownThunk, resolveTCErrorThunk, selectTCERDownloadUrlState, selectTimecardsErrorsReportListState, selectTimecardsReportListState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import ErrorsReportsPage from "../../errors_reports_page/errors_reports_page";
import { useEffect, useState } from "react";
import { LoadingType } from "../../../../../enums";
import { timecardsErrorReportListSliceActions } from "../../../../../redux/back_office/timecards/time_cards/errors_reports/errors_reports_reducer";
import { CustomCheckBox } from "../../../../../utils";


interface Props extends RouteComponentProps<any> { }

const TimeCardsErrorsReportPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { history } = props;
    const tcErrorsReportListState = useAppSelector((state) => selectTimecardsErrorsReportListState(state));
    const timecardsERDownloadUrlState = useAppSelector((state) => selectTCERDownloadUrlState(state));

    const [errorReportsList, setErrorReportsList] = useState<any[] | null>(null);
    const [weekendId, setWeekendId] = useState<string>("")
    const [selectedItems, setSelectedItems] = useState<
        Map<string, { id: string; name: string }>
    >(new Map());

    const isCheckboxDisabled  = errorReportsList?.every(
        item => item.status === "APPROVED" || item.status === "RESOLVED" || item.type === "Error"
      );

    const filteredSelectedItems= errorReportsList?.filter((item)=>item.status === "OPEN" && item.type === "Warning")

    useEffect(() => {
        dispatch(getWeekEndingDatesDropdownThunk());
    }, []);
    useEffect(() => {
        if (tcErrorsReportListState && tcErrorsReportListState.loading === LoadingType.succeeded) {
            setErrorReportsList(tcErrorsReportListState.response);
        }
    }, [tcErrorsReportListState, tcErrorsReportListState.loading]);

    useEffect(() => {
        if (timecardsERDownloadUrlState.loading === LoadingType.succeeded && timecardsERDownloadUrlState.response &&
            timecardsERDownloadUrlState.response.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', timecardsERDownloadUrlState?.response?.url!);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(timecardsErrorReportListSliceActions.clearDownloadState());
        }
    }, [timecardsERDownloadUrlState.loading]);

    const SelectAllCheckBox = () => {
        return (
            <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.size === filteredSelectedItems?.length}
                onClick={handleMarkAllItem}
                disabled={isCheckboxDisabled}
            />
        );
    };

    const tableHeader = [
        { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
        { title: 'batch', code: 'batch_number' },
        { title: 'status', code: 'status' },
        { title: 'category', code: 'type' },
        { title: 'detailed_error_msg', code: 'error_msg' },
        { title: 'talent_payee', code: 'talent_name' },
        { title: 'company_bill_to', code: 'company_name' },
        { title: 'job_order', code: 'job_title' },
        { title: 'transaction_number', code: 'transaction_number' },
        { title: 'resolve_error', code: 'resolve_error' },
    ];

    const handleMarkAllItem = () => {
        const newSet = new Map<string, { id: string; name: string }>();
        if (selectedItems.size === filteredSelectedItems?.length) {
            setSelectedItems(newSet);
        } else {
            filteredSelectedItems?.forEach((doc) =>
                newSet.set(doc.id, { id: doc.id, name: "" })
            );
            setSelectedItems(newSet);
        }
    };

    const handleMarkItem = (value) => {
        const newSet = new Map(selectedItems);
        if (selectedItems.has(value.id)) {
            newSet.delete(value.id);
        } else {
            newSet.set(value.id, { id: value.id, name: "" });
        }
        setSelectedItems(newSet);
    };

    const handleWeekEndChange = (week_id: string) => {
        dispatch(getTCERListThunk(week_id));
    }

    const handleApproveError = (timeCardids: string[]) => {
        dispatch(approveTCErrorThunk(timeCardids));
    }
    const handleResolveError = (timeCardid: string) => {
        dispatch(resolveTCErrorThunk(timeCardid))
    }
    const handleClearApproveError = () => {
        dispatch(timecardsErrorReportListSliceActions.clearAprroveErrorState());
        dispatch(timecardsErrorReportListSliceActions.clearResolveErrorState());
    }
    const handleOnSuccessClose = () => {
        dispatch(timecardsErrorReportListSliceActions.clearAprroveState());
        dispatch(timecardsErrorReportListSliceActions.clearResolveState());
        dispatch(getTCERListThunk(weekendId));
    }

    const handleSetWeekendId = (week_id: string) => {
        setWeekendId(week_id)
    }
    function handleDownload() {
        dispatch(getTimeCardsErrorReportDownloadUrl(weekendId));
    }
    const resetSelectedItems = () => {
        setSelectedItems(new Map());
      };
    return (<>

        <ErrorsReportsPage
            tableheaderList={tableHeader}
            reportsList={errorReportsList}
            source="timecards"
            weekendChange={handleWeekEndChange}
            WeekendId={(week_id) => handleSetWeekendId(week_id)}
            loading={tcErrorsReportListState.loading === LoadingType.pending}
            onApprove={handleApproveError}
            onResolve={handleResolveError}
            state={tcErrorsReportListState.approve}
            resolveState={tcErrorsReportListState.resolve}
            handleClearError={handleClearApproveError}
            handleOnSuccessClose={handleOnSuccessClose}
            handleDownload={handleDownload}
            // isApproveError={true}
            history={history}
            selectedItems={selectedItems}
            handleMarkItem={handleMarkItem}
            handleClearSelectedItems={resetSelectedItems}
            onClose = {()=>{dispatch(timecardsErrorReportListSliceActions.clearAprroveState());
                dispatch(timecardsErrorReportListSliceActions.clearResolveState())}}
        />
    </>);
}

export default TimeCardsErrorsReportPage;
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import DropZone from "../../../../components/file_upload/file_upload_page";
import { DeleteIcon, DocumentIcon, DownloadIcon, UploadIcon } from "../../../../icons"
import { LoadingType, THEME } from "../../../../enums";
import { CustomButton } from "../../../../utils";
import './upload_doc.scss';
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import ApiError from "../../../../components/api_error";
import { AppError, ITimeCards, ITimeCardsData } from "../../../../interfaces";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import { getFileDownloadURL, selectFileDownloadResponseState, selectWorkCompClaimsListState } from "../../../../redux/risk_management";
import { workCompClaimsListActions } from "../../../../redux/risk_management/universal_directory/work_comp_claims_list/work_comp_claims_list_reducer";
import { Portal } from "react-portal";
import { timecardsListSliceActions } from "../../../../redux/back_office/timecards/time_cards/time_cards_list/time_cards_list_reducer";
import { deleteTimeCardsUploadDocument, uploadTimeCardsDocsUploadUrl } from "../../../../redux/back_office/timecards";
import { DeleteConfirmationDialog } from "../../../components/delete_confirmation_dialog";
interface Props {
    visible: boolean;
    onClose: () => void;
    onDissmiss?: () => void;
    onClearError: () => void;
    pay_type_id: string | undefined;
    timecard_id: string | undefined;
    error: AppError | null | undefined;
    timeCardsList: ITimeCards | null;
    isNew: boolean | undefined;
    loading: LoadingType;
    deleteDocumentState: any; 
    handleSnackBar: () => void;
    isClosedBatch?: boolean;
}

const tableHeader = [
    { title: 'file_name', code: 'file_name' },
    { title: '', code: '' },
    { title: '', code: '' },
];

const UploadDocPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onClearError,
        handleSnackBar,
        timeCardsList,
        pay_type_id,
        timecard_id,
        error,
        loading,
        isNew,
        isClosedBatch,
        deleteDocumentState
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [selectedFiles, setSelectedFiles] = useState<Array<File> | null>(null);
    const [checkNew, setCheckNew] = useState<boolean>(false);
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const fileDownloadState = useAppSelector((state) =>
        selectFileDownloadResponseState(state)
    );
    const [showDeleteDialog, setShowDeleteDialog] = useState<{ visible: boolean, file_name: string } | null>(null);
    useEffect(() => {
        if (isNew && isNew !== undefined) {
            setCheckNew(isNew)
        }
    })

    useEffect(() => {
        if (deleteDocumentState?.loading === LoadingType.succeeded) {
            dispatch(timecardsListSliceActions.clearDeleteState());
            setShowDeleteDialog(null)
        }
    }, [deleteDocumentState?.loading])

    useEffect(() => {
        if (fileDownloadState.loading === LoadingType.succeeded) {
            let aTag = document.createElement("a");
            aTag.setAttribute("href", fileDownloadState.data.url);
            aTag.setAttribute("target", "_blank");
            aTag.click();
            dispatch(workCompClaimsListActions.clearFileDownloadState());
        }
    }, [fileDownloadState.loading]);

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }

    function handleFiles(files) {
        setSelectedFiles(files);
    }
    function handleFilesChange(value: Array<File>) {
        if (selectedFiles && selectedFiles?.length > 0) {
            setSelectedFiles([...value]);
        } else {
            setSelectedFiles(value);
        }
    }

    function handleSelectedFileRemove(index: number) {
        if (selectedFiles && selectedFiles?.length > 1) {
            const newArr = [...selectedFiles]
            newArr.splice(index, 1);
            setSelectedFiles(newArr);
        } else {
            setSelectedFiles(null);
        }

    }

    function _isFormValid() {
        if (!selectedFiles) return false;
        return true;
    }

    const handleUploadUrlSubmit = () => {
        const file_name = selectedFiles ? selectedFiles.map(file => ({ file_name: file.name })) : null
        if (timecard_id && pay_type_id) {
            dispatch(uploadTimeCardsDocsUploadUrl({ time_card_id: timecard_id, pay_type_id: pay_type_id, files: selectedFiles }))
        }
        handleSnackBar()
    }

    const getFilteredList = () => {
        let list: ITimeCardsData[] | undefined = timeCardsList?.time_cards;
        return list;
    }

    function handleDownload(filePath) {
        dispatch(getFileDownloadURL(filePath))
    }


    function handleOnDelete(timeCardId: string, payTypeId: string, fileName: string) {
        if (timeCardId && payTypeId && fileName) {
            dispatch(deleteTimeCardsUploadDocument({ time_card_id: timeCardId, pay_type_id: payTypeId, file_name: fileName }))
        }
    }

    function handleDeleteSuccessClose() {
        setShowDeleteDialog(null);
    }

    const onDeleteClose = () => {
        setShowDeleteDialog(null);
    }

    function handleClearError() {
        dispatch(timecardsListSliceActions.clearDeleteState());
    }

    const acceptedFileFormats = {
        "application/pdf": [".pdf"],
        "image/jpeg": [".jpg", ".jpeg"],
        "image/png": [".png"],
        "image/gif": [".gif"],
        "image/bmp": [".bmp"],
        "image/webp": [".webp"],
        "image/svg+xml": [".svg"]
    };
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"fit-content"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => {
                onClose();
                onClearError();
            }}>
                <div className="upload-doc-container">
                    <div className="upload-doc-header">
                        <FormDialogTilteHeader
                            title={!checkNew ? t("uploaded_documents") : t("upload_documents")}
                            titleIcon={!checkNew ? <DocumentIcon width={"100%"} height={"100%"} /> : <UploadIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    {!checkNew ?
                        <div className="upload-doc-table-content">
                            <SortableTable
                                headerList={tableHeader}
                                sortedField={sortedField}
                                onSortChange={handleSortFieldChange}
                                flexNumber={getFlexNumber}
                                isAsc={sortDirection}
                            >
                                {getFilteredList() &&
                                    getFilteredList()?.map((doc, index) => {
                                        return (
                                            <>
                                                {doc.payments.map((subDoc, subIndex) => {
                                                    return (
                                                        <>
                                                            {subDoc.docs.map((document, cSubIndex) => {
                                                                return (
                                                                    <>
                                                                        {timecard_id === doc.id && pay_type_id === subDoc.pay_type_id &&
                                                                            <tr key={doc.id + subIndex}>
                                                                                <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{document.file_name}</span></TableData>
                                                                                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                                                                    <CustomButton
                                                                                        textStyle={{ textTransform: 'capitalize' }}
                                                                                        enable={true}
                                                                                        leftIcon={<div><DownloadIcon style={{ height: "1vw", width: "1vw" }} /></div>}
                                                                                        name={t("download")}
                                                                                        backgroundColor={THEME.buttonColor1}
                                                                                        onClick={() => handleDownload(document.file_path)}
                                                                                    />
                                                                                </TableData>
                                                                                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                                                    <div className="icon-btn tdp-edit-icon">
                                                                                        {!isClosedBatch &&
                                                                                            <button
                                                                                                onClick={() => setShowDeleteDialog({ visible: true, file_name: document.file_name })}
                                                                                            >
                                                                                                <div className="icon-btn tdp-edit-icon">
                                                                                                    <DeleteIcon width={"100%"} height={"100%"} />
                                                                                                </div>
                                                                                            </button>}

                                                                                    </div>
                                                                                </TableData>
                                                                            </tr >
                                                                        }
                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                    )
                                                })}
                                            </>
                                        );
                                    })
                                }

                                <div>

                                </div>
                            </SortableTable>
                        </div> :
                        <div>
                            <div className="upload-doc-content">
                                <div>
                                    <DropZone maxFiles={10} handleFiles={handleFilesChange} maxSize={26214400} accept={acceptedFileFormats} />
                                </div>
                            </div>
                            {error &&
                                <div className="error-holder">
                                    <ApiError message={error.message} onClose={onClearError} />
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className="bo-tct-upload-doc-actions">
                    <div className="btn-cancel" style={{ marginRight: "1em" }}>
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("cancel")}
                            enable={true}
                            backgroundColor={THEME.toggleDisableColor}
                            onClick={() => {
                                onClose();
                                onClearError();
                            }}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            loading={loading === LoadingType.pending}
                            textStyle={{ textTransform: "capitalize" }}
                            name={checkNew ? t("upload") : t("upload_more")}
                            enable={isClosedBatch ? false : (checkNew ? _isFormValid() : true)}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => checkNew ? handleUploadUrlSubmit() : setCheckNew(!checkNew)}
                        />
                    </div>
                </div>
                 {showDeleteDialog && showDeleteDialog.visible && (
                    <Portal>
                        <DeleteConfirmationDialog
                            message={t("are_you_sure_you_want_to_delete_this_document", { documentName: showDeleteDialog?.file_name })}
                            onCancel={()=>setShowDeleteDialog(null)}
                            onConfirm={() => handleOnDelete(timecard_id!, pay_type_id!, showDeleteDialog.file_name)}
                            deleting={deleteDocumentState?.loading === LoadingType.pending}
                            deleteType={t("document")}
                            state={deleteDocumentState}
                            onClearError={handleClearError}
                        />
                    </Portal>
                )}
            </DialogWrapper>
        </ActionDialogHolder >
    );
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;

        return 1;
    }
}

export default UploadDocPopup;
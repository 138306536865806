import { RouteComponentProps } from "react-router-dom"
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../../components/table_empty/table_empty";
import DatePicker from "react-datepicker";
import { CalenderIcon, DownloadIcon } from "../../../../../icons";
import { useTranslation } from "react-i18next";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import SearchBar from "../../../../../components/search_bar";
import { useEffect, useState } from "react";
import { AppRoutes } from "../../../../../routes";
import { CashReceiptsPaymentType, InvoicePaymentMethod, LoadingType, THEME } from "../../../../../enums";
import { convertDateToTimeStamp, getDateFromTimeStamp, getDateString } from "../../../../../variables";
import { IRCCashReceipts, IRCCRRInvoicePayments, IRCInvoiceRegister, IRCSingleInvoiceRegister } from "../../../../../interfaces/report_center_finance_accounting";
import { getCashReceiptsList, getInvoicePDFURLThunk, getReportDownloadURL, getReportStartEndDate, selectCashReceiptsListState, selectePaycheckInvoiceState, selectReportsDownloadState, selectReportStartEndDateState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { currencyConversion, CustomButton } from "../../../../../utils";
import { CustomMultiFormSelect } from "../../../../../components/form_selector/form_select";
import UrlBuilder from "../../../../../apis/url_builder";
import { reportDownloadSliceActions } from "../../../../../redux/report_center/download_report/download_report_reducer";
import './cash_receipts.scss';
import { paycheckInvoiceSliceActions } from "../../../../../redux/back_office/view_paycheck_invoice/view_paycheck_invoice_reducer";
import ApiError from "../../../../../components/api_error";
import { clearCashReceiptsListError } from "../../../../../redux/report_center/finance_accounting/cash_receipts/cash_receipts_reducer";

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
    { title: "customer", code: "company_name" },
    { title: "invoice_number", code: "invoice_number" },
    { title: "invoice_date", code: "invoice_date" },
    { title: "date_received", code: "date_received" },
    { title: "invoice_amount", code: "invoice_amount" },
    { title: "payment_amount", code: "payment_amount" },
    { title: "remaining_balance", code: "remaining_balance" },
    { title: "payment_method", code: "payment_method" },
    { title: "reference_number", code: "reference_number" },
    { title: "payment_type", code: "payment_type" },
    { title: "days_outstanding", code: "days_outstanding" },
];

const CashReceipts: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const cashReceiptsListState = useAppSelector((state) => selectCashReceiptsListState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));
    const reportStartEndDate = useAppSelector((state) => selectReportStartEndDateState(state));

    const [cashReceiptsList, setCashReceiptsList] = useState<IRCCashReceipts | null>(null);
    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");
    const [paymentMethodFilter, setPaymentMethodFilter] = useState<string[]>([])
    const [paymentTypeFilter, setPaymentTypeFilter] = useState<string[]>([]);

    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>()
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const invoicePDFURLState = useAppSelector((state) => selectePaycheckInvoiceState(state));
    const loadingState = cashReceiptsListState.loading === LoadingType.pending;

    useEffect(() => {
        dispatch(getReportStartEndDate({}));
    }, []);

    useEffect(() => {
        if (reportStartEndDate.loading === LoadingType.succeeded && reportStartEndDate.response.length !== 0) {
            const startDate = reportStartEndDate.response[0];
            const EndDate = reportStartEndDate.response[1];
            setFormState({ available_from: startDate, available_to: EndDate });
            setDateRange([getDateFromTimeStamp(startDate), getDateFromTimeStamp(EndDate)]);
        }
    }, [reportStartEndDate.loading, reportStartEndDate.response]);

    useEffect(() => {
        if ((formState?.available_from !== 0 && formState?.available_to !== 0)) {
            getCashReceipts();
            setDatePickerOpen(false);
        }
        else if ((formState?.available_from === 0 && formState?.available_to === 0)) {
            getCashReceipts();
            setDatePickerOpen(true);
        }
    }, [formState?.available_from, formState?.available_to]);

    useEffect(() => {
        if (cashReceiptsListState && cashReceiptsListState?.loading === LoadingType.succeeded) {
            setCashReceiptsList(cashReceiptsListState?.response);
        }
        return () => {
            setCashReceiptsList(null) 
        }
    }, [cashReceiptsListState?.loading]);


    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    useEffect(() => {
        if (invoicePDFURLState.invoice.loading === LoadingType.succeeded && invoicePDFURLState.invoice.response !== "") {
            window.open(invoicePDFURLState.invoice.response, "_blank");
            dispatch(paycheckInvoiceSliceActions.clearInoviceState());
        }
    }, [invoicePDFURLState.invoice.loading, invoicePDFURLState.invoice.response]);

    const getCashReceipts = () => {
        if (formState) {
            dispatch(getCashReceiptsList({ start_date: formState.available_from, end_date: formState.available_to }))
        }
    }

    const handleViewInvoice = (invoice_id: string) => {
        dispatch(getInvoicePDFURLThunk(invoice_id));
    }

    function handleDownload() {
        if (formState) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.cashReceipts,
                    start_date: formState.available_from,
                    end_date: formState.available_to,
                    download: true
                }));
        }
    }

    function handleChange(value) {
        setFormState({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setDateRange(value);
    }

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function handleCompanySelect(value: IRCCRRInvoicePayments) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            },
        });
        return;
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 1;
        return 1;
    }

    const showSpinner = () => cashReceiptsListState?.loading === LoadingType.pending;

    const getFilteredList = () => {
        let list: IRCCRRInvoicePayments[] | undefined = cashReceiptsList?.cash_receipts_report?.invoice_payments;
        let resultList: IRCCRRInvoicePayments[] | undefined;

        if (paymentTypeFilter.length !== 0 || paymentMethodFilter.length !== 0 || (paymentTypeFilter.length !== 0 && paymentMethodFilter.length !== 0)) {

            resultList = list?.filter((doc) => {

                const paymentTypeMatches = paymentTypeFilter.length === 0 || (doc.payment_type && paymentTypeFilter.includes(doc.payment_type));

                const paymentMethodMatches = paymentMethodFilter.length === 0 || (doc.payment_method && paymentMethodFilter.includes(doc.payment_method));

                return paymentTypeMatches && paymentMethodMatches;

            });

        }
        if (sortedField != null && list) {
            list = [...list].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return (resultList ?? list)?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }

    function getFilteredListSumAmount(type) {
        switch (type) {
            case "invoice_amount": {
                const TotalInvoiceAmount = cashReceiptsList?.cash_receipts_report && cashReceiptsList?.cash_receipts_report?.invoice_payments?.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.invoice_amount
                }, 0) : 0;
                return TotalInvoiceAmount;
            }
            case "payment_amount": {
                const TotalPaymentAmount = cashReceiptsList?.cash_receipts_report && cashReceiptsList?.cash_receipts_report?.invoice_payments?.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.payment_amount
                }, 0) : 0;
                return TotalPaymentAmount;
            }
            case "remaining_balance": {
                const TotalRemainingBalance = cashReceiptsList?.cash_receipts_report && cashReceiptsList?.cash_receipts_report?.invoice_payments?.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.remaining_balance
                }, 0) : 0;
                return TotalRemainingBalance;
            }
            case "company_name": {
                const uniqueCompanies = new Set(getFilteredList()?.map(payment => payment.company_name));
                return uniqueCompanies.size;
            }
            case "days_outstanding": {
                const TotalRemainingBalance = cashReceiptsList?.cash_receipts_report && cashReceiptsList?.cash_receipts_report?.invoice_payments?.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.days_outstanding
                }, 0) : 0;
                return TotalRemainingBalance;
            }
            case "paid_in_full_amount": {
                const TotalPaidInFullAmount = cashReceiptsList?.cash_receipts_report && cashReceiptsList?.cash_receipts_report?.invoice_payments?.length > 0 ? getFilteredList()?.reduce((total, payment) => {
                    if (payment.payment_amount >= payment.invoice_amount) {
                        return total + (+payment.payment_amount || 0);
                    }
                    return total;
                }, 0) : 0;
                return TotalPaidInFullAmount;
            }
            case "partial_amount": {
                const TotalPartialAmount = cashReceiptsList?.cash_receipts_report && cashReceiptsList?.cash_receipts_report?.invoice_payments?.length > 0 ? getFilteredList()?.reduce((total, payment) => {
                    if (payment.payment_amount < payment.invoice_amount) {
                        return total + (+payment.payment_amount || 0);
                    }
                    return total;
                }, 0) : 0;
                return TotalPartialAmount;
            }
            default:
                return 0;
        }
    }

    const getTable = () => {
        if (showSpinner()) return <TableLoading />;

        if (cashReceiptsListState?.error != null && cashReceiptsList?.cash_receipts_report?.invoice_payments?.length === 0) {
            return <TableErrorHandler error={cashReceiptsListState?.error} onRefresh={getCashReceipts} />;
        }

        if (cashReceiptsList?.cash_receipts_report?.invoice_payments?.length === 0 || getFilteredList()?.length === 0) {
            return <TableEmpty title={"No Data found"} onClick={getCashReceipts} />;
        }

        if (getFilteredList()?.length !== 0) {
            return (
                <div className="cr-table">
                    <SortableTable
                        headerList={tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {getFilteredList()?.map((doc, index) => {
                            return (
                                <tr key={doc.id}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}
                                        isButton={true}
                                        onClick={() => handleCompanySelect(doc)}
                                    >
                                        <span>{doc.company_name}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1) }}
                                        isButton={true}
                                        onClick={() => handleViewInvoice(doc.id)}
                                    >
                                        <span>{doc.invoice_number}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                        <span>{getDateString(doc.invoice_date)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                        <span>{getDateString(doc.date_received)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                        <span>{currencyConversion(doc.invoice_amount)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                        <span>{currencyConversion(doc.payment_amount)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                        <span>{currencyConversion(doc.remaining_balance)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                        <span>{doc.payment_method}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                        <span>{doc.reference_number}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(9) }}>
                                        <span>{doc.payment_type}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(10) }}>
                                        <span>{doc.days_outstanding}</span>
                                    </TableData>
                                </tr>
                            )
                        })}
                    </SortableTable>
                </div>
            );
        }
    };

    const handleClearError = () => {
        dispatch(clearCashReceiptsListError())
    }

    return (
        <div className="invoice-report-container cash-receipts-container">
            <div>
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>
            <div className="invoice-report-header">
                <div className="cr-h-left">
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={() => setDatePickerOpen(true)} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleChange}
                                isClearable={loadingState ? false : true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dropdownMode="select"
                                disabled={loadingState}
                            />
                        </div>
                    </div>
                    <div className="select-type">
                        <CustomMultiFormSelect
                            list={Object.entries(InvoicePaymentMethod).map(([key, value]) => ({
                                label: value,
                                value: value,
                            }))}
                            name={"payment_method"}
                            value={paymentMethodFilter}
                            label={t("payment_method")}
                            placeholder={"All"}
                            onChange={setPaymentMethodFilter}
                            disabled={loadingState}
                        />
                    </div>
                    <div className="select-type">
                        <CustomMultiFormSelect
                            name={t("payment_type")}
                            list={Object.entries(CashReceiptsPaymentType).map(([key, value]) => ({
                                label: value,
                                value: value,
                            }))}
                            value={paymentTypeFilter}
                            placeholder={t('all')}
                            onChange={setPaymentTypeFilter}
                            label={t('payment_type')}
                            disabled={loadingState}
                        />
                    </div>
                    {cashReceiptsListState?.error && <div className="error-holder">
                        <ApiError message={cashReceiptsListState?.error?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="cr-h-right">
                    <div className="total-count-action">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                    <div className="select-type">
                        <CustomButton
                            leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                            loading={false}
                            name={t("download")}
                            enable={getFilteredList()?.length !== 0 && (loadingState ? false : true)}
                            backgroundColor={THEME.buttonColor16}
                            onClick={() => handleDownload()}
                        />
                    </div>
                    <div className="select-type">
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                        />
                    </div>
                </div>
            </div>

            <div className="table-container">
                <div className="header-container">
                    <div className="table-header">
                        <div className="table-header-title">
                            <span>{t("cash_receipts")}</span>
                        </div>
                    </div>
                </div>
                {getTable()}
            </div>
            {cashReceiptsListState.loading === LoadingType.succeeded && <div>
                <div className="total-counts-first-card">
                    <div className="total-title">
                        Totals
                    </div>
                    <div className="total-count-main">
                        <div className="total-count">
                            {currencyConversion(getFilteredListSumAmount("invoice_amount"))}
                        </div>
                        <div className="total-count">
                            {currencyConversion(getFilteredListSumAmount("payment_amount"))}
                        </div>
                        <div className="total-count">
                            {currencyConversion(getFilteredListSumAmount("remaining_balance"))}
                        </div>
                    </div>
                </div>
                <div className="total-counts-second-card">
                    <div className="total-title">
                        Cash Receipts Summary
                    </div>
                    <div className="total-count">
                        <span>Paid in Full:</span>
                        {currencyConversion(getFilteredListSumAmount("paid_in_full_amount"))}
                    </div>
                    <div className="total-count pb">
                        <span>Partial Payment:</span>
                        {currencyConversion(getFilteredListSumAmount("partial_amount"))}
                    </div>
                    {/* <div className="total-count pb">
                        <span>Write Off:</span>
                        <span>{currencyConversion(cashReceiptsList?.cash_receipts_summary?.write_off!)}</span>
                    </div> */}
                    <div className="total-count mt">
                        <span>Totals:</span>
                        {currencyConversion(getFilteredListSumAmount("paid_in_full_amount") + getFilteredListSumAmount("partial_amount"))}
                    </div>
                    <div className="total-count mt-b">
                        <span>Total Customers</span>
                        {getFilteredListSumAmount("company_name")}
                    </div>
                    <div className="total-count">
                        <span>Total Invoices</span>
                        {getFilteredList()?.length}
                    </div>
                    <div className="total-count">
                        <span>Total Payments Received</span>
                        {currencyConversion(getFilteredListSumAmount("payment_amount"))}
                    </div>
                    <div className="total-count">
                        <span>Total No of Payments Received</span>
                        {getFilteredList()?.length}
                    </div>
                    <div className="total-count">
                        <span>Average Days Outstanding</span>
                        {getFilteredList()?.length! > 0 ? getFilteredListSumAmount("days_outstanding") / (getFilteredList()?.length || 0) : 0}
                    </div>
                </div>
            </div>}
        </div >
    );
}

export default CashReceipts;
import { useTranslation } from "react-i18next";
import { THEME } from "../../../../../enums";
import { ITalent } from "../../../../../interfaces";
import SectionCard from "../../../../../components/section_card/section_card";
import { ExclaimOrangeIcon, TickGreenIcon } from "../../../../../icons";
import { useEffect, useState } from "react";
import './profile.scss';

interface Props {
    employeePortalAccess: boolean;
    onCreateLogin: () => void;
    onResetPassword: () => void;
    talentData: ITalent | null;
}

const ProfileSection: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { employeePortalAccess, onCreateLogin, onResetPassword, talentData, } = props;
    const bgColor = employeePortalAccess ? THEME.statusDisabledColor : THEME.defaultHighLightColor;
    const [options, setOptions] = useState<{ [key: string]: any }>({});
      
    useEffect(() => {
        if (talentData) {
            setOptions({
                years_of_experience: {
                    value: talentData.years_of_experience,
                    title: t("years_of_experience"),
                },
                wc_code: {
                    value: talentData.wc_code,
                    title: t("work_comp"),
                },
                talent_source: {
                    value: talentData.talent_source,
                    title: t("recruiting_source"),
                },
                // transportation: {
                //     value: talentData.transportation,
                //     title: "transportation",
                // },
                // i_am_available_to_work: {
                //     value: talentData.availability_type,
                //     title: t("i_am_available_to_work"),
                // },
            });
        }
    }, [talentData]);

    function DocumentStatusCard({ title, value }: { title: string, value: string | number }) {
        return (
            <button className="doc-card-container" style={{ cursor: "auto" }}>
                <div className="stat-icon">
                    {value ? (
                        <TickGreenIcon width={"100%"} height={"100%"} />
                    ) : (
                        <ExclaimOrangeIcon width={"100%"} height={"100%"} />
                    )}
                </div>
                <div className="doc-name">
                    <span>{title} {value ? ':' : ''} &nbsp;</span><span>{value}</span>
                </div>
                {!value && <div
                    className="stat-banner"
                    style={{
                        backgroundColor: value ? THEME.statusActiveColor : "#FF7600",
                    }}
                >
                    <span>{t("missing")}</span>
                </div>}
            </button>
        );
    }
    return (
        <SectionCard
            backgroundColor="#EBF1F8"
            activeColor="#0B3E78"
            title={t('profile')}
            buttonTitle={employeePortalAccess ? t("reset_password") : t("create_login_for_this_talent")}
            onButtonTitleClick={employeePortalAccess ? onResetPassword : onCreateLogin}
            buttonBackgroundColor={bgColor}
            employeePortalAccess={employeePortalAccess}
            isBtnVisible= {true}
        >
            <div className="profile-section-container">
                <div className="obd-status-options">
                    <div className="option-column">
                        {Object.keys(options)
                            .slice(0, 3)
                            .map((key, index) => {
                                return (
                                    <DocumentStatusCard
                                        title={t(options[key].title)}
                                        value={t(options[key].value)}
                                    />
                                );
                            })}
                    </div>
                    <div className="option-column">
                        {Object.keys(options)
                            .slice(3, 5)
                            .map((key, index) => {
                                return (
                                    <DocumentStatusCard
                                        title={t(options[key].title)}
                                        value={t(options[key].value)}
                                    />
                                );
                            })}
                    </div>
                </div>
            </div>
        </SectionCard>
    )
}

export default ProfileSection;
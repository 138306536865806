import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../components/api_error";
import { CircleDownloadIcon, DeleteIcon } from "../../../../icons";
import { CustomButton } from "../../../../utils";
import { LoadingType } from "../../../../enums";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { AppError } from "../../../../interfaces";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import "./confirm_popup.scss";

export interface DeleteConfirmationDialogProps {
    message: string,
    onClose: () => void;
    onConfirm: () => void;
    onClearError: () => void;
    type: string,
    title: string,
    talent_name: string,
    state: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    },
    currentLoginUserName: string,
    onSuccessClose: () => void;
}

export const ConfirmationDialog: FC<DeleteConfirmationDialogProps> = (
    props: DeleteConfirmationDialogProps
) => {
    const { onClose, onConfirm, message, type, title, state, onClearError, currentLoginUserName, talent_name, onSuccessClose } = props;
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible
            wrapperWidth={"30%"}
            maxWrapperWidth={"40%"}
            onClose={() => onClose()}
            onDissmiss={() => closePopup(state.loading === LoadingType.succeeded ? onSuccessClose : onClose)}
        >
            <DialogWrapper onClose={() => closePopup(state.loading === LoadingType.succeeded ? onSuccessClose : onClose)}>
                <div className="jt-cp-container">
                    <div className="jt-cp-header">
                        <FormDialogTilteHeader
                            title={title}
                            titleIcon={type === "trash" ?
                                <DeleteIcon width={"100%"} height={"100%"} color="red" />
                                :
                                <CircleDownloadIcon width={"100%"} height={"100%"} />
                            }
                        />
                    </div>
                    <div className="jt-cp-content">
                        {state.loading === LoadingType.succeeded ?
                            <div className="saved-doc-holder">
                                <DocSavedText>
                                    <div>
                                        <span className="aknw-name">
                                            {t("hey_name", { name: currentLoginUserName })}<br />
                                            {t(type === "trash" ? "move_trash_success" : "move_que_success", { name: talent_name ? talent_name : "Selected Talents" })}
                                        </span></div>
                                </DocSavedText>
                            </div>
                            :
                            <span className="confirm-text">{message}</span>
                        }

                    </div>
                    <div className="jt-cp-actions-row">
                        <div className="jt-error-holder">
                            {state?.error && <ApiError message={state.error.message} onClose={onClearError} />}
                        </div>
                        <div className="jt-cp-actions">
                            {state.loading === LoadingType.succeeded ?
                                <div className="btn-save">
                                    <CustomButton
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={t("ok")}
                                        enable={true}
                                        backgroundColor={""}
                                        onClick={() => { closePopup(onSuccessClose) }}
                                    />
                                </div>
                                :
                                <>
                                    <div className="btn-cancel">
                                        <CustomButton
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("no")}
                                            enable={true}
                                            onClick={() => { closePopup(onClose) }}
                                        />
                                    </div>
                                    <div className="btn-save">
                                        <CustomButton
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("yes")}
                                            enable={true}
                                            backgroundColor={""}
                                            onClick={onConfirm}
                                            loading={state.loading === LoadingType.pending}
                                        />
                                    </div>
                                </>
                            }

                        </div>
                    </div>
                </div>

            </DialogWrapper>

        </ActionDialogHolder>
    );
};

import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getEmployeeDropdownList, getReportDownloadURL, getReportStartEndDate, getTalentDropdown, getTaxByEmployeeList, selectEmployeesDropdownList, selectReportsDownloadState, selectReportStartEndDateState, selectTalentsDropdownList, selectTaxByEmployeeListState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useEffect, useRef, useState } from "react";
import { convertDateToTimeStamp, dateType, getDateFromTimeStamp } from "../../../../../variables";
import { LoadingType, THEME } from "../../../../../enums";
import { IRCTaxByEmplyee, IRCTBEEmplyeeTaxes } from "../../../../../interfaces/report_center_payroll_tax";
import { AppRoutes } from "../../../../../routes";
import DatePicker from "react-datepicker";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../../components/table_empty/table_empty";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { CalenderIcon, DownloadIcon } from "../../../../../icons";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import SearchBar from "../../../../../components/search_bar";
import { currencyConversion, CustomButton } from "../../../../../utils";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import UrlBuilder from "../../../../../apis/url_builder";
import { reportDownloadSliceActions } from "../../../../../redux/report_center/download_report/download_report_reducer";
import './tax_by_employee.scss';
import ApiError from "../../../../../components/api_error";
import { clearTaxByEmployeeListError } from "../../../../../redux/report_center/payroll_tax/tax_by_employee/tax_by_employee_reducer";

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
    { title: "ssn", code: "talent_ssn" },
    { title: "talent", code: "talent_name" },
    { title: "tax_type", code: "tax_type" },
    { title: "taxable_wages", code: "taxable_wages" },
    { title: "total_tax", code: "total_tax" },
    { title: "", code: "" },
];

const TaxByEmployee: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const taxByEmployeeListState = useAppSelector((state) => selectTaxByEmployeeListState(state));
    const employeeDropdownList = useAppSelector((state) => selectEmployeesDropdownList(state))
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));
    const reportStartEndDate = useAppSelector((state) => selectReportStartEndDateState(state));

    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");

    const [search, setSearch] = useState("");
    const [taxByEmployeeList, setTaxByEmployeeList] = useState<IRCTaxByEmplyee | null>(null);
    const [employee, setEmployee] = useState<string>('');

    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>()
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [isCheckDate, setIsCheckDate] = useState<boolean>(false);
    const prevFormState = useRef(formState);
    const loadingState = taxByEmployeeListState.loading === LoadingType.pending;

    useEffect(() => {
        dispatch(getReportStartEndDate({}));
    }, []);

    useEffect(() => {
        if (reportStartEndDate.loading === LoadingType.succeeded && reportStartEndDate.response.length !== 0) {
            const startDate = reportStartEndDate.response[0];
            const EndDate = reportStartEndDate.response[1];
            setFormState({ available_from: startDate, available_to: EndDate });
            setDateRange([getDateFromTimeStamp(startDate), getDateFromTimeStamp(EndDate)]);
        }
    }, [reportStartEndDate.loading, reportStartEndDate.response]);

    useEffect(() => {
        if (
          formState?.available_from !== 0 &&
          formState?.available_to !== 0 &&
          (formState?.available_from !== prevFormState.current?.available_from ||
            formState?.available_to !== prevFormState.current?.available_to)
        ) {
          dispatch(
            getEmployeeDropdownList({
              start_date: formState?.available_from!,
              end_date: formState?.available_to!,
              is_check_date: isCheckDate,
            })
          );
          prevFormState.current = formState;
        }
        if (employeeDropdownList.length > 0) {
            setEmployee(employeeDropdownList[0].id);
        }
      }, [formState?.available_from, formState?.available_to, employeeDropdownList]);

    useEffect(() => {
        if ((formState?.available_from !== 0 && formState?.available_to !== 0)
            || (formState?.available_from !== 0 && formState?.available_to !== 0 && employee && isCheckDate)
        ) {
            getTaxBEmployeeList();
            setDatePickerOpen(false)
        }
        else if (formState?.available_from === 0 && formState?.available_to === 0) {
            getTaxBEmployeeList();
            setDatePickerOpen(true);
        }
    }, [formState?.available_from, formState?.available_to, employee, isCheckDate]);

    useEffect(() => {
        if (taxByEmployeeListState && taxByEmployeeListState?.loading === LoadingType.succeeded) {
            setTaxByEmployeeList(taxByEmployeeListState?.response);
        }
        return () => {
            setTaxByEmployeeList(null) 
        }
    }, [taxByEmployeeListState?.loading]);

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    const getTaxBEmployeeList = () => {
        if (formState && employee) {
            dispatch(getTaxByEmployeeList({ start_date: formState.available_from, end_date: formState.available_to, talent_id: employee, is_check_date: isCheckDate }))
        }
    }

    function handleDownload() {
        if (formState) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.taxByEmployee,
                    start_date: formState.available_from,
                    end_date: formState.available_to,
                    is_check_date: isCheckDate ? isCheckDate : false,
                    talent_id: employee,
                    download: true
                }));
        }
    }

    function handleChange(value) {
        setFormState({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setDateRange(value);
    }

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function handleTalentSelect(value: IRCTBEEmplyeeTaxes) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
            state: {
                id: value.talent_id,
                name: value.talent_name,
            },
        });
        return;
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 2;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        return 1;
    }

    const showSpinner = () => taxByEmployeeListState?.loading === LoadingType.pending;

    const getFilteredList = () => {
        let list: IRCTBEEmplyeeTaxes[] | undefined = taxByEmployeeList?.employee_taxes!;

        if (taxByEmployeeList && Array.isArray(taxByEmployeeList.employee_taxes) && sortedField != null) {
            list = [...taxByEmployeeList.employee_taxes].sort((a, b) => {
                const valueA =
                    a[sortedField] !== null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] !== null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }

    const getTable = () => {
        if (showSpinner()) return <TableLoading />;

        if (taxByEmployeeListState?.error != null && taxByEmployeeList?.employee_taxes?.length === 0) {
            return <TableErrorHandler error={taxByEmployeeListState?.error} onRefresh={getTaxBEmployeeList} />;
        }

        if (taxByEmployeeList?.employee_taxes?.length === 0 || getFilteredList()?.length === 0 || getFilteredList()?.length === undefined || getFilteredList()?.length === null) {
            return <TableEmpty title={"No Data found"} onClick={getTaxBEmployeeList} />;
        } else {
            return (
                <div className="tbe-table">
                    <SortableTable
                        headerList={tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {getFilteredList()?.map((doc, index) => {
                            return (
                                <tr key={doc.talent_id + index}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                        <span>{doc.talent_ssn}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1) }}
                                        isButton={true}
                                        onClick={() => handleTalentSelect(doc)}
                                    >
                                        <span>{doc.talent_name}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                        <span>{doc.tax_type}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                        <span>{currencyConversion(doc.taxable_wages)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                        <span>{currencyConversion(doc.total_tax)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                        <span></span>
                                    </TableData>
                                </tr>
                            )
                        })}
                    </SortableTable>
                </div>
            );
        }
    };

    const handleClearError = () => {
        dispatch(clearTaxByEmployeeListError())
    }

    return (
        <div className="payroll-journal-container tax-by-employee-container">
            <div>
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>
            <div className="payroll-journal-header">
                <div className="tbe-h-left">
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={() => setDatePickerOpen(true)} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleChange}
                                isClearable={loadingState ? false : true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dropdownMode="select"
                                disabled={loadingState}
                            />
                        </div>
                    </div>
                    <div className="dwn-btn">
                        <CustomFormSelect
                            name={t("date_type")}
                            list={dateType.map((doc) => ({
                                label: doc.label,
                                value: doc.value,
                            }))}
                            onChange={setIsCheckDate}
                            value={isCheckDate}
                            placeholder={t('select')}
                            required={false}
                            label={t('date_type')}
                            disabled={loadingState}
                        />
                    </div>
                    <div className="dwn-btn">
                        <CustomFormSelect
                            label={t("employee")}
                            name={"talent_id"}
                            value={employee}
                            placeholder={"All"}
                            list={employeeDropdownList ?
                                [{ label: "All", value: "all" }, ...employeeDropdownList?.map((doc) => ({
                                    label: doc.name,
                                    value: doc.id,
                                }))] : []}
                            onChange={(value) => {
                                setEmployee(value);
                            }}
                            isClearable={false}
                            disabled={loadingState}
                        />
                    </div>
                    {taxByEmployeeListState?.error && <div className="error-holder">
                        <ApiError message={taxByEmployeeListState?.error?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="tbe-h-right">
                    <div className="total-count-action">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                    <div className="dwn-btn">
                        <CustomButton
                            leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                            loading={false}
                            name={t("download")}
                            enable={getFilteredList()?.length !== 0 && (loadingState ? false : true)}
                            backgroundColor={THEME.buttonColor16}
                            onClick={() => handleDownload()}
                        />
                    </div>
                    <div className="dwn-btn">
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                        />
                    </div>
                </div>
            </div>

            <div className="table-container">
                <div className="header-container">
                    <div className="table-header">
                        <div className="table-header-title">
                            <span>{t("tax_by_employee")}</span>
                        </div>
                    </div>
                </div>
                {getTable()}
            </div>
            {taxByEmployeeListState.loading === LoadingType.succeeded &&
                <div className="total-counts-card">
                    <div className="total-title">
                        Totals
                    </div>
                    <div className="total-count-main" style={{ width: "41%" }}>
                        <div className="total-count">
                            {currencyConversion(taxByEmployeeList?.employee_taxes && taxByEmployeeList?.employee_taxes.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.total_tax
                            }, 0) : 0)}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default TaxByEmployee;
import { useEffect, useState } from 'react';
import EditIcon from '../../../../assets/icons/edit_button.svg';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg';
import { LoadingType, THEME } from "../../../enums";
import { CustomButton, getPlusBtnIcon } from "../../../utils";
import { useTranslation } from "react-i18next";
import TabOptionsHeader from '../../../components/tab_options_header/tab_options_header';
import SortableTable, { TableData } from '../../../components/sortable_table/sortable_table';
import {
    createEndAssignmentReason, updateEndAssignmentReason, getEndAssignmentReasons, deleteEndAssignmentReason,
    selectEndAssignmentReasonState, selectEndAssignmentReasonList,
    selectAssignmentTypeState, selectAssignmentTypeList
} from "../../../redux/admin_center";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../redux/store";
import './system_wide_page.scss';
import { Portal } from "react-portal";
import '../../../custom.scss';
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../components/table_empty/table_empty";
import CustomFormSelect from '../../../components/form_selector/form_select';
import { DeleteConfirmationDialog } from '../../components/delete_confirmation_dialog';
import { endAssignmentReasonActions } from '../../../redux/admin_center/system_configuration/end_assignment_reasons/end_assignment_reasons_reducer';

import SCAssignmentsPopup from './popups/assignments_popup/assignments_popup';

const tableHeader = [
    { title: 'title', code: 'actions' },
    { title: 'description', code: 'actions' },
    { title: 'actions', code: 'actions' },
];

const categories = {
    1: {
        label: "end_assignment_reasons",
        value: ["title", "description"],
        buttonLabel: "add_end_assignment_reason",
        inputLabel: "title",
        deleteLabel: "delete_end_assignment_reason",
        deleteMessage: "delete_end_assignment_reason_message",
        updateLabel: "update_end_assignment_reason",
        inputLabel2: "description",
        methods: {
            create: createEndAssignmentReason,
            update: updateEndAssignmentReason,
            remove: deleteEndAssignmentReason,
            get: getEndAssignmentReasons
        },
        headers: tableHeader,
        clearCreateState: endAssignmentReasonActions.clearEndAssignmentReasonCreateState,
        clearUpdateState: endAssignmentReasonActions.clearEndAssignmentReasonUpdateState,
        clearDeleteState: endAssignmentReasonActions.clearEndAssignmentReasonDeleteState,
        clearDeleteStateError: endAssignmentReasonActions.clearEndAssignmentReasonDeleteStateError,
    },
    /*2: {
        label: "assignment_types",
        value: ["title", "description"],
        buttonLabel: "add_assignment_type",
        inputLabel: "title",
        deleteLabel: "delete_assignment_type",
        deleteMessage: "delete_assignment_type_message",
        updateLabel: "update_assignment_type",
        inputLabel2: "description",
        methods: {
            create: createAssignmentType,
            update: updateAssignmentType,
            remove: deleteAssignmentType,
            get: getAssignmentTypes
        },
        headers: tableHeader,
        clearCreateState: assignmentTypeActions.clearAssignmentTypeCreateState,
        clearUpdateState: assignmentTypeActions.clearAssignmentTypeUpdateState,
        clearDeleteState: assignmentTypeActions.clearAssignmentTypeDeleteState,
        clearDeleteStateError: assignmentTypeActions.clearAssignmentTypeDeleteStateError,
    }*/
}

const AssignmentsPage = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<{visible: boolean, title: string} | null>(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [methodValue, setMethodValue] = useState({
        'id': '',
        'title': '',
        'value': '',
        'description': '',
        'sequence_number': 0
    });
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [dropdownChanged, setDropDownChanged] = useState(true);

    const endAssignmentReasonState = useAppSelector((state) => selectEndAssignmentReasonState(state));
    const endAssignmentReasonList = useAppSelector((state) => selectEndAssignmentReasonList(state));
    const assignmentTypeState = useAppSelector((state) => selectAssignmentTypeState(state));
    const assignmentTypeList = useAppSelector((state) => selectAssignmentTypeList(state));

    const reduxMapping = {
        1: { state: endAssignmentReasonState, list: endAssignmentReasonList },
        2: { state: assignmentTypeState, list: assignmentTypeList }
    }

    useEffect(() => {
        if (getMapping()?.state?.create?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.create?.response));
            dispatch(getCategory()?.clearCreateState())
        }
        else if (getMapping()?.state?.update?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.update?.response));
            dispatch(getCategory()?.clearUpdateState())
        }
        else if (getMapping()?.state?.remove?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.remove?.response));
            dispatch(getCategory()?.clearDeleteState())
        }
    }, [
        getMapping()?.state?.create?.loading, 
        getMapping()?.state?.update?.loading, 
        getMapping()?.state?.remove?.loading
    ]);

    function getCategory(id = null) {
        if (id)
            return categories[id];
        return categories[selectedCategory];
    }

    function getMapping(id = null) {
        if (id)
            return reduxMapping[id];
        return reduxMapping[selectedCategory];
    }

    function handleCreateCloseDialog() {
        setShowCreateDialog(false);
    }

    function handleCloseDeleteDialog() {
        setShowDeleteDialog(null);
        setDeleteItemId(null);
    }

    function handleShowDeleteDialog(id, title) {
        setShowDeleteDialog({visible: true, title: title});
        setDeleteItemId(id);
    }

    function handleDeleteApprove() {
        if (deleteItemId) {
            dispatch(getCategory().methods.remove(deleteItemId));
        }
        handleCloseDeleteDialog();
    }

    function handleShowEditDialog(method) {
        setShowEditDialog(true);
        setMethodValue({
            ...methodValue, 'id': method.id, 'title': method[getCategory().value[0]],
            'description': method[getCategory().value[1]], 'sequence_number': method['sequence_number']
        });
    }

    function handleCloseEditDialog() {
        setShowEditDialog(false);
    }

    function handleDropdownChange(value) {
        if (!value)
            value = '1';
        setDropDownChanged(true);
        setSelectedCategory(Number(value));
        dispatch(getCategory(value).methods.get());
    }

    function retryFetchingData() {
        setDropDownChanged(true);
        dispatch(getCategory().methods.get());
    }

    useEffect(() => {
        if (getMapping().state.loading == LoadingType.succeeded) {
            setDropDownChanged(false);
        }
    }, [getMapping().state.loading])

    useEffect(() => {
        if (getMapping().state.remove.loading == LoadingType.succeeded)
            dispatch(getCategory().methods.get());
    }, [getMapping().state.remove.loading])

    useEffect(() => {
        dispatch(getCategory().methods.get());
        return () => {

        }
    }, [])

    return (
        <div className="ac-sc-container">
            <div className="ac-sc-header">
                <div className="ac-sytm-cns-header">
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="ac-sc-dropdown">
                <div className="ac-sc-dropdown-button">
                    <CustomFormSelect
                        name={t("assignment_dropdown")}
                        list={Object.keys(categories).map(id => ({ value: id, label: categories[id].label }))}
                        onChange={(val) => handleDropdownChange(val)}
                        value={selectedCategory}
                        placeholder={t('end_assignment_reasons')}
                        required={false}
                        label={t('please_select_assignment_category')}
                        customStyle={{ marginTop: "0 !important", maxWidth: "20vw" }}
                    />
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            enable={true}
                            leftIcon={getPlusBtnIcon()}
                            name={t(categories[selectedCategory].buttonLabel)}
                            backgroundColor={THEME.buttonColor1}
                            onClick={() => (setShowCreateDialog(true))}
                            customStyle={{ width: "max-content" }}
                        />
                    </div>
                </div>
            </div>
            <div className="ac-sc-table">
                {getMapping().state.loading == LoadingType.pending && dropdownChanged ? <TableLoading /> :
                    <TableBody
                        state={getMapping().state}
                        onRetry={retryFetchingData}
                        data={getMapping().list}
                        category={getCategory()}
                        handleShowEdit={handleShowEditDialog}
                        handleShowDelete={handleShowDeleteDialog}
                    />
                }
            </div>
            {showCreateDialog && <Portal>
                <SCAssignmentsPopup
                    visible={showCreateDialog}
                    onClose={handleCreateCloseDialog}
                    onDismiss={handleCreateCloseDialog}
                    category={getCategory()}
                    category_id={selectedCategory}
                    reduxMapping={reduxMapping}
                    errorState={reduxMapping[selectedCategory]?.update?.error}
                />
            </Portal>}
             {showDeleteDialog && (
                <Portal>
                    <DeleteConfirmationDialog
                        message={t(getCategory()?.deleteMessage, { value: showDeleteDialog?.title })}
                        onCancel={() => setShowDeleteDialog(null)}
                        onConfirm={handleDeleteApprove}
                        deleting={getMapping()?.state?.remove === LoadingType.pending}
                        deleteType={""}
                        customDeleteHeaderTitle={t(getCategory()?.deleteLabel)}
                        state={getMapping()?.state}
                        onClearError={getCategory()?.clearDeleteStateError()}
                    />
                </Portal>
            )}
            {showEditDialog && <Portal>
                <SCAssignmentsPopup
                    visible={showEditDialog}
                    onClose={handleCloseEditDialog}
                    onDismiss={handleCloseEditDialog}
                    methodValue={methodValue}
                    category={getCategory()}
                    category_id={selectedCategory}
                    reduxMapping={reduxMapping}
                    errorState={reduxMapping[selectedCategory]?.update?.error}
                />
            </Portal>}
        </div>

    )
}

const TableBody = ({ state, onRetry, data, category, handleShowEdit, handleShowDelete }) => {
    if (state.loading == LoadingType.failed) {
        return (<TableErrorHandler error={state.error} onRefresh={onRetry} />)
    }
    else if (data.length == 0) {
        return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    }
    else {
        return (
            <SortableTable headerList={category.headers}>
                {data.map((doc, index) => {
                    return (
                        <tr key={"astr" + index}>
                            {category.value.map((value, index) => {
                                return (
                                    <TableData key={"astd" + index} customStyle={{ wordWrap: "break-word" }}>
                                        <span>{doc[value]}</span>
                                    </TableData>
                                )
                            })}
                            <TableData>
                                <img src={EditIcon} alt='edit_button' style={{ marginRight: '20px' }}
                                    onClick={() => handleShowEdit(doc)} />
                                <img src={DeleteIcon} alt='delete_button'
                                    onClick={() => handleShowDelete(doc.id, doc.title)} />
                            </TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }
}

export default AssignmentsPage;
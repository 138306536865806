import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getMarketingSummaryReportDownloadUrl, getMarketingSummaryReportList, getRoleUsersList, selectMarketingSummaryReportDownloadUrlState, selectMarketingSummaryReportListState, selectRoleUsersList, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { convertDateToTimeStamp, getDateString } from "../../../../../variables";
import { LoadingType, THEME } from "../../../../../enums";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { CalenderIcon, CompletedCallIcon, CompletedCallWhiteIcon, DownloadIcon, ScheduledCallIcon, ScheduledCallWhiteIcon, TickBlueIcon, TickGreenIcon, TickWhiteIcon } from "../../../../../icons";
import { CustomMultiFormSelect } from "../../../../../components/form_selector/form_select";
import DatePicker from "react-datepicker";
import { CustomButton, convertNumberToMoney, currencyConversion } from "../../../../../utils";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../../components/table_empty/table_empty";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import { MarketingCampaign } from "../../../../../interfaces/marketing_campaign";
import './marketing_summary_report.scss';
import { clearMarketingSummaryReportListError, clearMSRDownloadState } from "../../../../../redux/report_center/sales_and_marketing/marketing_summary_report/marketing_summary_report_reducer";
import SearchBar from "../../../../../components/search_bar";
import ApiError from "../../../../../components/api_error";

interface Props extends RouteComponentProps<any> { }
const MarkettingSummaryReport: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { location, history } = props;

    const tableHeader = [
        { title: "campaign_name", code: "marketing_campaign_name" },
        { title: "start_date", code: "start_date_time" },
        { title: "end_date", code: "end_date_time" },
        { title: "target_audience", code: "target_audience" },
        { title: "campaign_budget", code: "budget" },
        { title: "gross_profit_potential_for_campaign", code: "gross_profit_potential" },
    ];

    const usersList = useAppSelector((state) => selectRoleUsersList(state));
    const { loading, error, response } = useAppSelector((state) => selectMarketingSummaryReportListState(state));
    const salesMSRDownloadUrlState = useAppSelector((state) => selectMarketingSummaryReportDownloadUrlState(state));

    const [showCompleted, setShowCompleted] = useState<boolean>(false);
    const [managerFilter, setManagerFilter]: any = useState("");
    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const [marketingFormState, setMarketingFormState] = useState<{ available_from: number, available_to: number }>({ available_from: convertDateToTimeStamp(thirtyDaysAgo, true), available_to: convertDateToTimeStamp(new Date(), true) })
    const [marketingDateRange, setMarketingDateRange] = useState([thirtyDaysAgo, new Date()]);
    const [marketingStartDate, marketingEndDate] = marketingDateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [marketingSummaryList, setMarketingSummaryList] = useState<MarketingCampaign[] | null>(null);

    useEffect(() => {
        dispatch(getRoleUsersList());
    }, []);

    useEffect(() => {
        if (loading === LoadingType.succeeded && response) {
            setMarketingSummaryList(response);
        }
        return () => {
            setMarketingSummaryList(null) 
        }
    }, [loading, response]);

    useEffect(() => {
        if ((marketingStartDate === null && marketingEndDate === null) || (marketingFormState.available_from === 0 && marketingFormState.available_to === 0)) {
            setMarketingDateRange([thirtyDaysAgo, new Date()]);
            setMarketingFormState({ available_from: convertDateToTimeStamp(thirtyDaysAgo, true), available_to: convertDateToTimeStamp(new Date(), true) })
        }
    }, [showCompleted]);

    useEffect(() => {
        if ((marketingFormState.available_from !== 0 && marketingFormState.available_to !== 0) || ((marketingFormState.available_from === 0 && marketingFormState.available_to === 0))) {
            getMarketingCallSummarysList();
        }
    }, [showCompleted, marketingFormState.available_from, marketingFormState.available_to]);

    useEffect(() => {
        if ((marketingFormState.available_from !== 0 && marketingFormState.available_to !== 0)) {
            setDatePickerOpen(false)
        }
        if ((marketingFormState.available_from === 0 && marketingFormState.available_to === 0)) {
            openDatePicker();
        }
    }, [marketingFormState.available_from, marketingFormState.available_to])


    useEffect(() => {
        if (salesMSRDownloadUrlState.loading === LoadingType.succeeded && salesMSRDownloadUrlState.response?.url &&
            salesMSRDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', salesMSRDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(clearMSRDownloadState());
        }
    }, [salesMSRDownloadUrlState.loading]);

    function handleDownload() {
        if (managerFilter?.length !== 0) {
            dispatch(getMarketingSummaryReportDownloadUrl({ is_completed: showCompleted, start_date: marketingFormState.available_from, end_date: marketingFormState.available_to, download: true, users: managerFilter }));
        }
        else {
            dispatch(getMarketingSummaryReportDownloadUrl({ is_completed: showCompleted, start_date: marketingFormState.available_from, end_date: marketingFormState.available_to, download: true }));
        }
    }

    const openDatePicker = () => {
        setDatePickerOpen(true);
    };
    const getMarketingCallSummarysList = () => {
        dispatch(getMarketingSummaryReportList({ is_completed: showCompleted, start_date: marketingFormState.available_from, end_date: marketingFormState.available_to }))
    }

    function handleChange(value) {
        setMarketingFormState({
            ...marketingFormState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setMarketingDateRange(value);

    }

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function getFlexNumber(value: number) {
        if (value === 0) return 1.2;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 2;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 2;
        if (value === 11) return 1;
        return 1;
    }

    const showSpinner = () => loading === LoadingType.pending;

    const getFilteredList = () => {
        let list: MarketingCampaign[] = [...marketingSummaryList || []];
        let sortedList: MarketingCampaign[] | undefined;
        // if (showCompleted) {
        //     sortedList = list.filter((doc) => doc.is_completed === true);
        // } else {
        //     sortedList = list.filter((doc) => doc.is_completed === false);
        // }
        // if (managerFilter?.length !== 0) {
        //     if (showCompleted) {
        //         sortedList = sortedList?.filter((doc) => {
        //             const userIncluded = doc.is_completed === showCompleted && doc.modified_by !== null ? managerFilter.includes(doc?.modified_by!) : managerFilter.includes(doc?.created_by!);
        //             return userIncluded;
        //         });
        //     }
        //     else {
        //         sortedList = sortedList?.filter((doc) => {
        //             const userIncluded = doc.is_completed === !showCompleted && doc.modified_by !== null ? managerFilter.includes(doc?.modified_by!) : managerFilter.includes(doc?.created_by!);
        //             return userIncluded;
        //         });
        //     }
        // }
        if (managerFilter?.length !== 0) {
            sortedList = list.filter((doc) => {
                const userIncluded = doc.modified_by !== null ? managerFilter.includes(doc?.modified_by!) : managerFilter.includes(doc?.created_by!);
                return userIncluded;
            });
        }
        if (sortedField !== null) {
            sortedList = [...(sortedList ?? list)].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return (sortedList ?? list)?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }
    const getTable = () => {
        if (showSpinner()) return <TableLoading />;

        if (error != null && marketingSummaryList?.length === 0) {
            return <TableErrorHandler error={error} onRefresh={getMarketingCallSummarysList} />;
        }

        if (marketingSummaryList?.length === 0 || getFilteredList()?.length === 0) {
            return <TableEmpty title={"No Reports found"} onClick={getMarketingCallSummarysList} />;
        }

        if (getFilteredList()?.length !== 0) {
            return (
                <SortableTable
                    headerList={tableHeader}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {getFilteredList()?.map((doc) => {
                        return (
                            <tr key={doc.id}>
                                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                    <span>{doc.marketing_campaign_name}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                    <span>{getDateString(doc.start_date_time)}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                    <span>{getDateString(doc.end_date_time)}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                    <span>
                                        {doc.target_audience}
                                    </span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                    {currencyConversion(doc.budget)}
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                    {currencyConversion(doc.gross_profit_potential)}
                                </TableData>
                            </tr>
                        )
                    })}
                </SortableTable>
            );
        }
    };

    const handleClearError = () => {
        dispatch(clearMarketingSummaryReportListError())
    }

    return (
        <div className="sales-call-summary-report rc-sm-ms-container">
            <div className="sales-call-summary-report-header">
                <div className="main-table-header">
                    <div>
                        <CustomMultiFormSelect
                            label={t("select_user")}
                            name={"select_user"}
                            value={managerFilter}
                            placeholder={"All"}
                            list={usersList?.map((doc) => ({
                                label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                                value: doc.id,
                            }))}
                            onChange={(value) => {
                                setManagerFilter(value);
                            }}
                            disabled={loading === LoadingType.pending}
                        />
                    </div>
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={openDatePicker} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={marketingStartDate}
                                endDate={marketingEndDate}
                                onChange={handleChange}
                                isClearable={loading === LoadingType.pending ? false: true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dropdownMode="select"
                                disabled={loading === LoadingType.pending}
                            />
                        </div>
                    </div>
                    {error && <div className="error-holder">
                        <ApiError message={error?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>
            <div className="flex-row main-header">
                <div
                    className={`flex-grow main-header-item ${!showCompleted ? "selected" : ""
                        }`}
                    onClick={() => setShowCompleted(false)}
                >
                    {!showCompleted ? (
                        <ScheduledCallWhiteIcon
                            height={"20px"}
                            width={"20px"}
                            style={{ marginRight: "15px" }}
                        />
                    ) : (
                        <ScheduledCallIcon
                            height={"20px"}
                            width={"20px"}
                            style={{ marginRight: "15px" }}
                        />
                    )}
                    Active Marketing Campaign
                </div>
                <div
                    className={`flex-grow main-header-item ${showCompleted ? "selected" : ""
                        }`}
                    onClick={() => setShowCompleted(true)}
                >
                    {showCompleted ? (
                        <CompletedCallWhiteIcon
                            height={"20px"}
                            width={"20px"}
                            style={{ marginRight: "15px" }}
                        />
                    ) : (
                        <CompletedCallIcon
                            height={"20px"}
                            width={"20px"}
                            style={{ marginRight: "15px" }}
                        />
                    )}
                    Completed Marketing Campaign
                </div>
            </div>

            <div className="table-container ">
                <div className="header-container">
                    <div className="table-header">
                        <div className="table-header-title">
                            <span>{t("marketing_summary_report")}</span>
                        </div>
                    </div>
                    <div className="rc-scsr-btns">
                        <div className="total-count-action">
                            <span>
                                {`${t("total_count")}: `}
                                <span className="total-count-number">
                                    {getFilteredList()?.length}
                                </span>
                            </span>
                        </div>
                        <div className="rc-scsr-btn">
                            <CustomButton
                                leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                                loading={false}
                                name={t("download")}
                                enable={getFilteredList()?.length !== 0 && (loading === LoadingType.pending ? false : true)}
                                backgroundColor={THEME.buttonColor16}
                                onClick={() => handleDownload()}
                            />
                        </div>
                        <div>
                            <SearchBar
                                value={search}
                                onChange={setSearch}
                                onSearch={() => { }}
                            />
                        </div>
                        {/* <div>
                            <CustomButton
                                leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                                loading={false}
                                name={t("print")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => { }}
                            />
                        </div> */}
                    </div>
                </div>
                {getTable()}
            </div>
            {/* <div className="call-counts">
                    <div className="total-count">
                        Total Campaigns Impressions:
                    </div>
                    <div className="total-count">
                        
                    </div>
            </div> */}
        </div>
    );


}

export default MarkettingSummaryReport;
import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../../../apis";
import { AppError, ICreateTalentInHouseInterview, ITalentInHouseInterview } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";


export const getTalentInHouseInterviews = createAsyncThunk<
    Array<ITalentInHouseInterview>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/interviews/talent-in-house-interview/get',
    async (talentId, thunkAPI) => {
        try {
            const res = await TalentAPI.getAllTalentInHouseInterviews(talentId);
            if (typeof res.data === 'string') return [];
            return res.data as ITalentInHouseInterview;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const addNewTalentInHouseInterview = createAsyncThunk<
    string,
    { talentId: string, data: ICreateTalentInHouseInterview, send_invite?: boolean },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/interviews/talent-in-house-interview/post',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.postTalentInHouseInterview(payload.data, payload.send_invite);
            // thunkAPI.dispatch(getTalentInHouseInterviews(payload.talentId));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const updateTalentInHouseInterview = createAsyncThunk<
    string,
    { talentId: string, data: ITalentInHouseInterview, send_invite?: boolean },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/interviews/talent-in-house-interview/patch',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.patchTalentInHouseInterview(payload.data, payload.send_invite);
            // thunkAPI.dispatch(getTalentInHouseInterviews(payload.talentId));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { TalentPipelineDirectory, LoadingType, THEME } from '../../../../enums';
import { ExpandMoreIcon, Jobsicon } from '../../../../icons';
import { IPipelineStatus, IMultipleMove, TalentPipeline } from '../../../../interfaces/talent_pipeline';
import { selectPipelineStatusState, getPipelineStatusThunk } from '../../../../redux/admin_center';
import { useAppDispatch, useAppSelector, selectProfileState, getJobOrderPipelineDataThunk, selectJobsPipelineState, selectTalentPipelineState, multipleTalentsTrashThunk, multipleTalentsQueueThunk, updatePipelineStatus, getTalentPipelineDataThunk } from '../../../../redux/store';
import JobOrderMatchesSection from './job_order_matches_section/job_order_matches_section';
import { JobOrderPipeline } from "../../../../interfaces/job_order_pipeline";
import { jobOrderPipelineSliceActions } from "../../../../redux/talent/details/job_order_pipeline/job_order_pipeline_details/job_order_pipeline_reducer";

interface Props extends RouteComponentProps<any> { }

interface State {
    id: string;
    name: string;
}

const JobOrderPipelinePage: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { history, match } = props;
    const { params } = match
    const dispatch = useAppDispatch();
    const [expanded, setExpanded] = useState<boolean | string>('false');
    const jobsPipelineState = useAppSelector((state) =>
        selectJobsPipelineState(state)
    );

    const talentPipelineState = useAppSelector((state) => selectTalentPipelineState(state));

    const jobsTrashState = talentPipelineState.moveTrash;
    const jobsQueState = talentPipelineState.moveQue;
    const jobsPipelineStatusChangeState = talentPipelineState.pipelineStatus;

    const pipelineStatusState = useAppSelector((state) => selectPipelineStatusState(state));

    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""}`;

    const [matchesJobsList, setMatchesTalentList] = useState<JobOrderPipeline[] | null>(null);
    const [queJobstList, setQueTalentList] = useState<JobOrderPipeline[] | null>(null);
    const [trashedJobsList, setTrashedTalentList] = useState<JobOrderPipeline[] | null>(null);
    const [piplineStatus, setPipelineStatus] = useState<IPipelineStatus[] | null>(null);
    const talentName = history.location.state as State;

    const accordions = [
        { id: "panel1", title: "qualified_jobs", subTitle: "qualified_jobs_subtitle", directory: TalentPipelineDirectory.Queued },
        { id: "panel2", title: "smart_matches", subTitle: "smart_matches_subtitle", directory: TalentPipelineDirectory.Match },
        { id: "panel3", title: "not_a_fit", directory: TalentPipelineDirectory.Trashed }];
    useEffect(() => {
        dispatch(jobOrderPipelineSliceActions.clearState());
        dispatch(getJobOrderPipelineDataThunk(params.id))
        dispatch(getPipelineStatusThunk());
        handleExpand('panel2');
    }, [])
    useEffect(() => {
        if (jobsPipelineState.loading === LoadingType.succeeded && jobsPipelineState.response) {
            setMatchesTalentList(jobsPipelineState.response.filter((talent) => talent.directory === TalentPipelineDirectory.Match));
            setQueTalentList(jobsPipelineState.response.filter((talent) => talent.directory === TalentPipelineDirectory.Queued));
            setTrashedTalentList(jobsPipelineState.response.filter((talent) => talent.directory === TalentPipelineDirectory.Trashed));
        }
        if (jobsPipelineState.loading === LoadingType.failed) {
            setMatchesTalentList(null);
            setQueTalentList(null);
            setTrashedTalentList(null);
        }
    }, [jobsPipelineState.loading, jobsPipelineState.response]);

    useEffect(() => {
        if (pipelineStatusState.loading === LoadingType.succeeded && pipelineStatusState.response) {
            setPipelineStatus(pipelineStatusState.response);
        }
    }, [pipelineStatusState.loading, pipelineStatusState.response]);

    useEffect(() => {
        if (jobsPipelineStatusChangeState.loading === LoadingType.succeeded) {
            dispatch(getJobOrderPipelineDataThunk(params.id));
        }
    }, [jobsPipelineStatusChangeState.loading]);

    const handleExpand = (panel: string) => {
        setExpanded((expanded === panel ? false : panel));
    };

    const handleMoveToTrash = (doc: IMultipleMove[]) => {
        dispatch(multipleTalentsTrashThunk(doc))
    }
    const handleMoveToQueue = (doc: IMultipleMove[]) => {
        dispatch(multipleTalentsQueueThunk(doc))
    }
    const handleJobPipelineStatusChange = (job_id, status_id) => {
        dispatch(updatePipelineStatus({ talent_id: params.id, job_id: job_id, status_id: status_id }));
    }
    const handleSuccessClose = () => {
        dispatch(getJobOrderPipelineDataThunk(params.id));
    }

    return (
        <div className="jobs-talent-pipeline-container">
            {accordions.map((accordion, index) => {
                return (
                    <Accordion className="accordion-container"
                        expanded={expanded === accordion.id} onChange={() => handleExpand(accordion.id)} key={accordion.id + index}
                        style={{
                            marginTop: 0, // Adjust spacing
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="accordion-title"
                            aria-controls="talent-matches"

                        >
                            <div className="title-holder">
                                <div className="ac-title-icon">
                                    <Jobsicon
                                        width={"1.5vw"}
                                        height={"1.5vw"}
                                        style={{ color: THEME.defaultHighLightColor }}
                                    />
                                </div>
                                <div className="title-name">
                                    <span>{t(accordion.title)}</span>
                                    <span className="accordion-subtitle">{t(accordion.subTitle ?? "")}</span>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className="accordion-details">
                            <JobOrderMatchesSection
                                directory={accordion.directory}
                                index={index}
                                loading={jobsPipelineState.loading === LoadingType.pending}
                                jobOrderPipelineList={accordion.directory === TalentPipelineDirectory.Queued ? queJobstList : accordion.directory === TalentPipelineDirectory.Match ? matchesJobsList : trashedJobsList}
                                history={history}
                                handleMoveToTrash={(doc: IMultipleMove[]) => handleMoveToTrash(doc)}
                                handleMoveToQueue={(doc: IMultipleMove[]) => handleMoveToQueue(doc)}
                                talentId={params.id}
                                talentName={talentName?.name!}
                                jobOrderQueState={jobsQueState}
                                jobOrderTrashState={jobsTrashState}
                                joborderPipelineStatusChangeState={jobsPipelineStatusChangeState}
                                pipelineStatusList={piplineStatus ?? []}
                                currentLoginUserName={currentLoginUserName}
                                handleJobOrderPipelineStatusChange={(talent_id, status_id) => handleJobPipelineStatusChange(talent_id, status_id)}
                                onSuccessClose={handleSuccessClose}
                            />
                        </AccordionDetails>
                    </Accordion>)
            })}
        </div>
    )
}

export default JobOrderPipelinePage;
import { t } from "i18next";
import SectionCard from "../../../../../components/section_card/section_card";
import './hiring_and_onboarding_data.scss';
import { CustomButton, FormInput, SpinnerScreen, getPlusBtnIcon } from "../../../../../utils";
import { CRIMINAL_RECORD, LoadingType, THEME } from "../../../../../enums";
import { useEffect, useState } from "react";
import { Portal } from "react-portal";
import AddCrimeHistoryPopup from "../../../popups/add_criminal_history/add_crime_history";
import { ICreateTalentCrimeHistory, ITalentCrimeHistory } from "../../../../../interfaces";
import { useTranslation } from "react-i18next";
import { addNewTalentCrimeHistoryThunk, deleteTalentCrimeHistoryThunk, getTalentCrimeHistoryThunk, postSnackbarMessage, selectSingleTalentCrimeHistStateById, updateTalentCrimeHistoryThunk, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { talentCrimeHistoryActions } from "../../../../../redux/talent/details/onboarding_and_docs/crime_history/crime_history_reducer";
import { getDateString } from "../../../../../variables";
import { DeleteIcon, DownloadIcon, EditIcon, TickGreenIcon, TickGreyIcon } from "../../../../../icons";
import { S3API } from "../../../../../apis/s3_api";
import { FILE_ICONS } from "../../../../../file_icons";
import { DeleteConfirmationDialog } from "../../../../components/delete_confirmation_dialog";
interface Props {
    talent_id: string;
}
const HiringAndOnboardingData: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { talent_id } = props;
    const talentCrimeHistoryState = useAppSelector((state) => selectSingleTalentCrimeHistStateById(talent_id)(state));
    const addCrimeHistoryState = talentCrimeHistoryState.add;
    const updateCrimeHistoryState = talentCrimeHistoryState.update;
    const removeCrimeHistoryState = talentCrimeHistoryState.delete;

    const [addCrimeHistory, setAddCrimeHistory] = useState<boolean>(false);
    const [crimeHistoryData, setAddCrimeHistoryData] = useState<ITalentCrimeHistory[] | null>();

    const [selectedFiles, setSelectedFiles] = useState<Array<File> | null>(null);
    const [addStateLoading, setAddStateLoading] = useState(LoadingType.idle);
    const [delAction, setDelAction] = useState<boolean>(false);
    const [showDeleteAction, setShowDeleteAction] = useState<{
        id: string | undefined;
        offence_type: string;
        visible: boolean;
    } | null>(null);
    const [showFileDeleteAction, setShowFileDeleteAction] = useState<{
        visible: boolean;
        fileName: string;
        index: number;
    } | null>(null);
    useEffect(() => {
        dispatch(getTalentCrimeHistoryThunk(talent_id));
    }, []);

    useEffect(() => {
        if (talentCrimeHistoryState.loading === LoadingType.succeeded && talentCrimeHistoryState.data) {
            setAddCrimeHistoryData(talentCrimeHistoryState.data)
        }
    }, [talentCrimeHistoryState.data, talentCrimeHistoryState.loading])

    useEffect(() => {
        if (addCrimeHistoryState && addCrimeHistoryState.loading === LoadingType.succeeded && addCrimeHistoryState.response) {
            dispatch(postSnackbarMessage(t("criminal_history_added")));
            dispatch(getTalentCrimeHistoryThunk(talent_id));
            dispatch(talentCrimeHistoryActions.clearTalentAddCrimeHistoryError(talent_id));
            setSelectedFiles(null);
        }
        if (!delAction && updateCrimeHistoryState?.loading === LoadingType.succeeded && updateCrimeHistoryState?.response) {
            if (updateCrimeHistoryState?.response && selectedFiles) {
                const fileUrlMap = {};
                updateCrimeHistoryState.response.map((item, index) => {
                    fileUrlMap[index] = item.url;
                });
                selectedFiles.map((file, index) => {
                    const url = fileUrlMap[index];

                    if (url) {
                        S3API.uploadWithPreSignedURL(url, file)
                    }
                });
            }
            dispatch(postSnackbarMessage(t("criminal_history_updated")));
            dispatch(getTalentCrimeHistoryThunk(talent_id));
            dispatch(talentCrimeHistoryActions.clearTalentUpdateCrimeHistoryError(talent_id));
            setSelectedFiles(null)
            setDelAction(false);
        }
        if (delAction && updateCrimeHistoryState && updateCrimeHistoryState.loading === LoadingType.succeeded && updateCrimeHistoryState.response) {
            dispatch(postSnackbarMessage(t("criminal_history_updated")));
            dispatch(getTalentCrimeHistoryThunk(talent_id));
            dispatch(talentCrimeHistoryActions.clearTalentUpdateCrimeHistoryError(talent_id));
            setShowFileDeleteAction(null)
            setDelAction(false);
        }
        return () => { }
    }, [delAction, dispatch, addCrimeHistoryState, addCrimeHistoryState?.loading, addCrimeHistoryState?.response, talent_id, updateCrimeHistoryState, updateCrimeHistoryState?.loading, updateCrimeHistoryState?.response])

    useEffect(() => {
        if ((removeCrimeHistoryState && removeCrimeHistoryState.loading === LoadingType.succeeded && removeCrimeHistoryState.response)) {
            dispatch(postSnackbarMessage(removeCrimeHistoryState.response));
            dispatch(getTalentCrimeHistoryThunk(talent_id));
            dispatch(talentCrimeHistoryActions.clearTalentRemoveCrimeHistoryError(talent_id));
        }
        return () => { }
    }, [dispatch, removeCrimeHistoryState, removeCrimeHistoryState?.loading, removeCrimeHistoryState?.response, talent_id])

    function handleSubmit(value: ICreateTalentCrimeHistory) {
        if (crimeDataById) {
            dispatch(updateTalentCrimeHistoryThunk({ talent_id: talent_id, data: { ...crimeDataById, ...value }, files: selectedFiles }));
        }
        else {
            dispatch(addNewTalentCrimeHistoryThunk({ talent_id: talent_id, data: value, files: selectedFiles }));
        }
        setAddCrimeHistory(false);
        // setSelectedFiles(null);
        setCrimeDataById(undefined);
    }

    function handleFilesChange(value: Array<File>) {
        if (selectedFiles && selectedFiles?.length > 0) {
            setSelectedFiles([...selectedFiles, ...value]);
        } else {
            setSelectedFiles(value);
        }
    }

    function handleSelectedFileRemove(index: number) {
        if (selectedFiles && selectedFiles?.length > 1) {
            const newArr = [...selectedFiles]
            newArr.splice(index, 1);
            setSelectedFiles(newArr);
        } else {
            setSelectedFiles(null);
        }

    }
    function handleDownload(filePath) {
        let aTag = document.createElement('a')
        aTag.setAttribute('href', filePath);
        aTag.setAttribute('target', '_blank');
        aTag.click();
    }
    function handleDeleteRecord(id: string | undefined, offence_type: string) {
        setShowDeleteAction({ id: id, offence_type: offence_type, visible: true });
    }
    /// close delete  dialog
    function handleCloseDeleteDialog() {
        setShowDeleteAction(null);
    }
    ///Delete record
    function handleOnSuccessDeleteRecordDialog(id: string) {
        dispatch(deleteTalentCrimeHistoryThunk({ talent_id: talent_id, object_id: id }));
        handleCloseDeleteDialog();
    }
    const [crimeDataById, setCrimeDataById] = useState<ITalentCrimeHistory | undefined>()

    const handleEditRecord = (id: string | undefined) => {
        const updatedData = crimeHistoryData?.find((crime) => crime.id === id);
        setCrimeDataById(updatedData)
        setAddCrimeHistory(true);
    }
    function handleClearError() {
        dispatch(talentCrimeHistoryActions.clearTalentRemoveCrimeHistoryError(talent_id));
        dispatch(talentCrimeHistoryActions.clearTalentUpdateCrimeHistoryError(talent_id));

    }
    const handleFileRemove = (filename: string, docindex: number) => {
        // const filteredCrimeHistData = crimeHistoryData?.find((item, index) => index === docindex);
        // // Remove 'filename' from the 'file_names' array
        // const updatedFileNames = filteredCrimeHistData?.file_names.filter((fileName) => fileName !== filename);

        // // Remove 'filename' from the 'file_paths' object
        // const updatedFilePaths = { ...filteredCrimeHistData?.file_paths };
        // delete updatedFilePaths[filename];

        // // Update the 'filteredCrimeHistData' object with the modified file_names and file_paths
        // const updatedObject = {
        //     ...filteredCrimeHistData,
        //     file_names: updatedFileNames,
        //     file_paths: updatedFilePaths,
        // };


        // const updatedCrimeHistData = {...crimeHistoryData, [docindex]:updatedObject};
        setDelAction(true)
        const updatedCrimeHistData: ITalentCrimeHistory[] | undefined = crimeHistoryData?.map((item, index) => {
            if (index === docindex) {
                // Remove 'filename' from the 'file_names' array
                const updatedFileNames = item.file_names.filter((fileName) => fileName !== filename);

                // Remove 'filename' from the 'file_paths' object
                const updatedFilePaths = { ...item.file_paths };
                delete updatedFilePaths[filename];

                // Update the 'item' object with the modified file_names and file_paths
                const updatedItem = {
                    ...item,
                    file_names: updatedFileNames,
                    file_paths: updatedFilePaths,
                };
                dispatch(updateTalentCrimeHistoryThunk({ talent_id: talent_id, data: updatedItem, files: selectedFiles }));
                return {
                    ...item,
                    file_names: updatedFileNames,
                    file_paths: updatedFilePaths,
                };
            }

            return item;
        });
    }
    return (
        <SectionCard
            backgroundColor="#EBF1F8"
            activeColor="#0B3E78"
            title={t('hiring_and_onboarding_data')}
            buttonTitle={t("add_criminal_hist")}
            onButtonTitleClick={() => setAddCrimeHistory(true)}
            isBtnVisible= {true}
        >
            <div className="crime-docs-container">
                {talentCrimeHistoryState && talentCrimeHistoryState.loading === LoadingType.pending && (
                    <SpinnerScreen></SpinnerScreen>
                )}
                {talentCrimeHistoryState &&
                    talentCrimeHistoryState.loading === LoadingType.succeeded &&
                    talentCrimeHistoryState.error === null && (
                        <div className="crime-hist-data-scroll" >
                            {crimeHistoryData?.map((doc, docindex) => (
                                <>
                                    <div className="crime-rec-row-actions">
                                        <div className="crime-rec-row">
                                            <div>
                                                <div className="stat-icon">
                                                    {doc.offence_type === CRIMINAL_RECORD.FELONY ?
                                                        (<TickGreenIcon width={"100%"} height={"100%"} />) :
                                                        (<TickGreyIcon width={"100%"} height={"100%"} />)}

                                                </div>
                                                <div className="doc-name" >
                                                    <span style={{
                                                        color: doc.offence_type === CRIMINAL_RECORD.FELONY ? THEME.statusActiveColor : '#bdc2cc',
                                                    }}>{CRIMINAL_RECORD.FELONY}</span>
                                                </div>
                                            </div>
                                            <div style={{ marginLeft: "2em" }}>
                                                <div className="stat-icon">
                                                    {doc.offence_type === CRIMINAL_RECORD.MISDEMEANOR ?
                                                        (<TickGreenIcon width={"100%"} height={"100%"} />) :
                                                        (<TickGreyIcon width={"100%"} height={"100%"} />)}

                                                </div>
                                                <div className="doc-name" >
                                                    <span style={{
                                                        color: doc.offence_type === CRIMINAL_RECORD.MISDEMEANOR ? THEME.statusActiveColor : '#bdc2cc',
                                                    }}>{CRIMINAL_RECORD.MISDEMEANOR}</span>
                                                </div>
                                            </div>
                                            <div style={{ marginLeft: "2em" }}>
                                                <div className="stat-icon">
                                                    {CRIMINAL_RECORD[doc.offence_type] === CRIMINAL_RECORD.NO_RECORD ?
                                                        (<TickGreenIcon width={"100%"} height={"100%"} />) :
                                                        (<TickGreyIcon width={"100%"} height={"100%"} />)}

                                                </div>
                                                <div className="doc-name" >
                                                    <span style={{
                                                        color: CRIMINAL_RECORD[doc.offence_type] === CRIMINAL_RECORD.NO_RECORD ? THEME.statusActiveColor : '#bdc2cc',
                                                    }}>{CRIMINAL_RECORD.NO_RECORD}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="actions-icons">
                                            <EditIcon width={"100%"} height={"100%"} onClick={() =>
                                                handleEditRecord(doc.id)
                                            } />
                                            <DeleteIcon width={"100%"} height={"100%"} onClick={() =>
                                                handleDeleteRecord(doc.id, doc.offence_type)
                                            } />
                                        </div>
                                    </div>
                                    <div key={docindex} className="crime-hist-data-container">

                                        <div className="data-card">
                                            <label>{t("entered_by")}</label>
                                            <div>
                                                <FormInput
                                                    value={doc.entered_by_name}
                                                    placeholder={""}
                                                    type={"text"}
                                                    id={"entered_by_name"}
                                                    onChange={() => { }}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="data-card">
                                            <label>{t("interviewed_by")}</label>
                                            <div>
                                                <FormInput
                                                    value={doc.interviewed_by_name}
                                                    placeholder={""}
                                                    type={"text"}
                                                    id={"interviewed_by_name"}
                                                    onChange={() => { }}
                                                    disabled
                                                /></div>
                                        </div>
                                        <div className="data-card">
                                            <label>{t("recruiter")}</label>
                                            <div>
                                                <FormInput
                                                    value={doc.recruiter_name}
                                                    placeholder={""}
                                                    type={"text"}
                                                    id={"recruiter_name"}
                                                    onChange={() => { }}
                                                    disabled
                                                /></div>
                                        </div>
                                        <div className="data-card">
                                            <label>{t("date_entered")}</label>
                                            <div>
                                                <FormInput
                                                    value={getDateString(doc.created_date)}
                                                    placeholder={""}
                                                    type={"text"}
                                                    id={"created_date"}
                                                    onChange={() => { }}
                                                    disabled
                                                /></div>
                                        </div>
                                        <div className="data-card">
                                            <label>{t("hire_date")}</label>
                                            <div>
                                                <FormInput
                                                    value={getDateString(doc.hire_date)}
                                                    placeholder={""}
                                                    type={"text"}
                                                    id={"hire_date"}
                                                    onChange={() => { }}
                                                    disabled
                                                /></div>
                                        </div>
                                        <div className="data-card">
                                            <label>{t("interview_date")}</label>
                                            <div><FormInput
                                                value={getDateString(doc.interview_date)}
                                                placeholder={""}
                                                type={"text"}
                                                id={"interview_date"}
                                                onChange={() => { }}
                                                disabled
                                            /></div>
                                        </div>
                                        <div className="pill-card-container" style={{ width: '100%' }}>
                                            {doc.file_download_urls && Object.entries(doc.file_download_urls).map(([file, url], index) => {
                                                const nameList = file.split('.');
                                                const type = nameList[nameList?.length - 1];
                                                return (
                                                    <div className="pill-card" key={index}>
                                                        <div className="doc-pill-card-container">
                                                            <div style={{ display: "flex", flexDirection: "row", marginBottom: '0.5em' }}>
                                                                <div className="stat-icon">
                                                                    {/* <TickGreenIcon width={"100%"} height={"100%"} /> */}
                                                                    <img src={FILE_ICONS[type.toLowerCase()]} width={"100%"} height={"100%"} style={{ objectFit: 'cover' }} alt="respective file type icon" />

                                                                </div>
                                                                <div className="doc-name" >
                                                                    <span>{file}</span>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                {file !== null && (
                                                                    <div className="doc-icon action-btn">
                                                                        <div>
                                                                            <CustomButton
                                                                                customClassName={"doc-down-btn"}
                                                                                enable={true}
                                                                                leftIcon={<div><DownloadIcon /></div>}
                                                                                name={''}
                                                                                backgroundColor={THEME.buttonColor16}
                                                                                onClick={() => handleDownload(url)}
                                                                            />
                                                                        </div>
                                                                        <div className="del-icon">
                                                                            <CustomButton
                                                                                customClassName={"doc-down-btn"}
                                                                                enable={true}
                                                                                leftIcon={<div><DeleteIcon /></div>}
                                                                                name={''}
                                                                                backgroundColor={THEME.buttonColor2A}
                                                                                onClick={() => setShowFileDeleteAction({visible: true, fileName: file, index: docindex})}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    )}

                {addCrimeHistory && (
                    <Portal>
                        <AddCrimeHistoryPopup
                            visible={addCrimeHistory}
                            title={crimeDataById ? t("update_criminal_hist") : t("add_criminal_hist")}
                            successTitle={t("new_talent")}
                            onClose={() => { setAddCrimeHistory(false); setSelectedFiles(null); setCrimeDataById(undefined); }}
                            onSuccessClose={() => { setAddCrimeHistory(false) }}
                            onSubmit={handleSubmit}
                            talent_id={talent_id}
                            selectedFiles={selectedFiles}
                            onDocumentChange={handleFilesChange}
                            onRemoveDocument={handleSelectedFileRemove}
                            loading={addStateLoading}
                            crimeDataById={crimeDataById}
                        />
                    </Portal>
                )}
                {showDeleteAction && showDeleteAction.visible && (
                    <Portal>
                        <DeleteConfirmationDialog
                            message={t("are_you_sure_you_want_to_delete_record", { name: showDeleteAction?.offence_type})}
                            onCancel={() => setShowDeleteAction(null)}
                            onConfirm={() => handleOnSuccessDeleteRecordDialog(showDeleteAction?.id!)}
                            deleting={removeCrimeHistoryState?.loading === LoadingType.pending}
                            deleteType={t("document")}
                            state={removeCrimeHistoryState}
                            onClearError={handleClearError}
                        />
                    </Portal>
                )}
                {showFileDeleteAction && showFileDeleteAction.visible && (
                    <Portal>
                        <DeleteConfirmationDialog
                            message={t("are_you_sure_you_want_to_delete_this_document", {
                                documentName: showFileDeleteAction?.fileName,
                            })}
                            onCancel={() => setShowFileDeleteAction(null)}
                            onConfirm={() => handleFileRemove(showFileDeleteAction.fileName, showFileDeleteAction.index)}
                            deleting={updateCrimeHistoryState?.loading === LoadingType.pending}
                            deleteType={t("document")}
                            state={updateCrimeHistoryState}
                            onClearError={handleClearError}
                        />
                    </Portal>
                )}
            </div>
        </SectionCard>
    )
}

export default HiringAndOnboardingData;
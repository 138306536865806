import { Portal, Step, StepContent, StepLabel, Stepper, Typography } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { LoadingType, THEME } from '../../enums';
import { IEngagement } from '../../interfaces';
import { selectEngagementDeleteState, selectEngagementListState, selectEngagementSavestate } from '../../redux/engagement/engagement_selector';
import { deleteEngagement, getDownloadFileURLThunk, getEngagementDataById, postSnackbarMessage, selectFileViewDownloadState, updateEngagement, useAppDispatch, useAppSelector } from '../../redux/store';
import { CustomButton, getPlusBtnIcon } from '../../utils';
import AddEnagement from './engagementsPopup';
import './engagementDetails.scss';
import { DeleteIcon, DownloadIcon, EditIcon, TickBlueIcon } from '../../icons';
import moment from 'moment';
import { dateToTimestamp, extractDateFromTimestamp, extractTimeFromTimestamp, getDateString } from '../../variables';
import { ActionDialogHolder, ActionDialogHolderType } from '../../components/action_dialog_holder/action_dialog_holder';
import ApiError from '../../components/api_error';
import { engagementListActions } from '../../redux/engagement/engagement_reducer';
import { DeleteConfirmationDialog } from './delete_confirmation_dialog';
import { fileViewDownloadSliceActions } from '../../redux/files/file_view_download/file_view_download_reducer';

interface IEngamentDetails {
    id: string;
    departmentId?: string,
    source: string,
    customStyle?: React.CSSProperties,
    onSuccessCreate?: () => void
}

const EngagementDetails = ({ id, departmentId, source, customStyle, onSuccessCreate }: IEngamentDetails) => {
    const dispatch = useAppDispatch();
    const engagementListState = useAppSelector((state) => selectEngagementListState(state));
    const fileDownloadState = useAppSelector((state) => selectFileViewDownloadState(state));

    const { t } = useTranslation();
    const [showPopup, setShowPopup] = useState(false);
    const [engagementData, setEngagementData]: any = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [downLoadingFile, setDownLoadingFile]: any = useState("");
    const [showUploadFileDeleteDialog, setShowUploadFileDeleteDialog] = useState<{ visible: boolean, filePath: string, selectedIndex: number, engagement: IEngagement } | null>(null);

    const actionRef = useRef<ActionDialogHolderType>(null);
    const { loading, error, response } = useAppSelector((state) => selectEngagementDeleteState(state));
    const engagementState = useAppSelector((state) => selectEngagementSavestate(state));
    useEffect(() => {
        if (engagementState.loading === LoadingType.succeeded) {
            handleSuccessClose()
            setShowUploadFileDeleteDialog(null);
            const message = typeof engagementState.response === "string"
                ? engagementState.response
                : engagementState.response?.["message"];

            dispatch(postSnackbarMessage(message || "Something went wrong"));
        }
    }, [engagementState.loading])
    useEffect(() => {
        dispatch(getEngagementDataById(id));
    }, [id]);

    useEffect(() => {
        if (loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(response ?? null));
            setShowDelete(false);
            setEngagementData(null);
            dispatch(engagementListActions.clearDeleteState());
            if (onSuccessCreate) onSuccessCreate();
        }
        return () => { }
    }, [loading]);
    useEffect(() => {
        if (fileDownloadState.loading === LoadingType.succeeded && fileDownloadState.response) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', fileDownloadState.response);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(fileViewDownloadSliceActions.clearState());
            setDownLoadingFile("");
        }

    }, [fileDownloadState.loading])

    const isLoading = () => {
        return engagementListState.loading === LoadingType.pending;
    }


    const handleClose = () => {
        setShowPopup(false);
        dispatch(engagementListActions.clearSaveState())
    }

    function handleSuccessClose() {
        if (onSuccessCreate) onSuccessCreate();
        handleClose();
    }

    function getStepIcon() {
        return (
            <div className="ac-um-add-icon">
                <TickBlueIcon fontSize='small' fill='#0B3E78' />
            </div>

        )
    }
    function getDateTime(timestamp) {
        let date = new Date(timestamp * 1000);
        return date.toLocaleString().replace(',', '');
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
    function handleClearError() {
        dispatch(engagementListActions.clearDeleteState());
    }
    const onClose = () => {
        setShowDelete(false);
    }
    const onDissmiss = () => {
        setShowDelete(false);
    }

    const handleEdit = (val: IEngagement) => {
        const dateSting = extractDateFromTimestamp(val.date_time!)
        const dateTimeStamp = dateToTimestamp(dateSting)
        const time = extractTimeFromTimestamp(val.date_time!)
        const updatedEngagementData = {
            ...val,
            date: dateTimeStamp,
            time: time
        }
        // setEngagementData(h);
        setEngagementData(updatedEngagementData);
        setShowPopup(true)
    };

    const handleDelete = () => {
        dispatch(deleteEngagement(engagementData))
    }
    function handleDownload(filePath) {
        setDownLoadingFile(filePath);
        dispatch(getDownloadFileURLThunk(filePath));
    }

    function handleUpdateClearError() {
        dispatch(engagementListActions.clearSaveState());
    }

    function handleDeleteUploadedFile() {
        const EngagementDetails = showUploadFileDeleteDialog?.engagement
           if (EngagementDetails && typeof showUploadFileDeleteDialog?.selectedIndex === 'number' && showUploadFileDeleteDialog?.selectedIndex >= 0 && showUploadFileDeleteDialog?.selectedIndex < EngagementDetails?.file_paths?.length) {
               const indexnum = showUploadFileDeleteDialog?.selectedIndex
               const docsFileNameArray = EngagementDetails?.file_paths ?? [];
               const docsFilePathArray = EngagementDetails?.file_names ?? [];
               const updatedFileNames = [...docsFileNameArray?.slice(0, indexnum), ...docsFileNameArray?.slice(indexnum + 1)]
               const updatedFilePaths = [...docsFilePathArray?.slice(0, indexnum), ...docsFilePathArray?.slice(indexnum + 1)]
               dispatch(updateEngagement({
                data: {
                  ...EngagementDetails!,
                  file_names: updatedFileNames,
                  file_paths: updatedFilePaths,
                },
              }));
           }
       }
    
    return (
        <>
            <div className="eng-ud-container" style={customStyle}>
                <div className="eng-ud-table-header">
                    <div className="eng-ud-table-headerText">
                        {t('engagement_details')}
                    </div>
                    <div className="eng-ud-actions">
                        <div className="tlnt-ud-add-user-btn">
                            <CustomButton
                                leftIcon={getPlusBtnIcon()}
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('add_event')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => {
                                    setEngagementData(null)
                                    setShowPopup(true)

                                }}
                            />
                        </div>
                    </div>

                </div>
                <div className="eng-ud-table">
                    {isLoading() ? <div className="spinner-holder"><Spinner /></div> :
                        <Stepper orientation="vertical" >
                            {engagementListState.data.map((doc: IEngagement, index) => {
                                return (
                                    <Step key={index} active>
                                        <StepLabel StepIconComponent={getStepIcon}>
                                            <div className="action-btn">
                                                <Typography variant='h6' style={{ color: THEME.defaultHighLightColor }}>
                                                    {doc.event_title}
                                                </Typography>
                                                {
                                                    doc.can_delete &&
                                                    <button style={{ width: "15px", height: "20px", color: "#0b3e78", margin: 5 }}
                                                        // onClick={() => {
                                                        //     setEngagementData(h);
                                                        //     setShowPopup(true)
                                                        // }}
                                                        onClick={() => handleEdit(doc)}
                                                    >
                                                        <EditIcon width={"100%"} height={"100%"} style={{ color: "#0b3e78" }} />
                                                    </button>
                                                }
                                                {
                                                    doc.can_delete &&
                                                    <button style={{ width: "15px", height: "20px", color: "#0b3e78", margin: 5 }} onClick={() => {
                                                        setEngagementData(doc);
                                                        setShowDelete(true)
                                                    }}>
                                                        <DeleteIcon width={"100%"} height={"100%"} style={{ color: "#0b3e78" }} />
                                                    </button>
                                                }

                                            </div>
                                        </StepLabel>
                                        <StepContent>
                                            <div>
                                                <span style={{ color: THEME.defaultHighLightColor }}>{doc.date_time ? getDateString(doc.date_time) : '--'}</span>
                                            </div>
                                            <div className='desc'>
                                                <span>{doc.description}</span>
                                            </div>
                                            <div className="en-file-row">
                                                {doc.file_paths && doc.file_paths.length > 0 &&
                                                    doc.file_paths.map((file: string, index) => {
                                                        const fileName = file.split('/').pop();
    
                                                        return (
                                                            <div className="en-file-doc">
                                                                <div className="icons-row">
                                                                    <CustomButton
                                                                        customClassName={"en-file-dwn-btn"}
                                                                        enable={true}
                                                                        leftIcon={<div><DownloadIcon /></div>}
                                                                        name={''}
                                                                        onClick={() => (handleDownload(file))}
                                                                        loading={doc === downLoadingFile && fileDownloadState.loading === LoadingType.pending}
                                                                    />
                                                                </div>
                                                                <div className="icons-row">
                                                                    <CustomButton
                                                                        customClassName="en-file-dwn-btn"
                                                                        enable={true}
                                                                        leftIcon={<div><DeleteIcon /></div>}
                                                                        name={''}
                                                                        backgroundColor={THEME.buttonColor2A}
                                                                        onClick={() => setShowUploadFileDeleteDialog({ visible: true, filePath: fileName ?? "", selectedIndex: index, engagement: doc })}
                                                                    />
                                                                </div>
                                                                <div className='filename'>{fileName}</div>
                                                            </div>
                                                        )
                                                    })}
                                            </div>

                                        </StepContent>
                                    </Step>
                                )
                            }
                            )}

                        </Stepper>
                    }
                </div>

                {showPopup && <Portal>
                    <AddEnagement
                        showPopup={showPopup}
                        source={source}
                        object_id={(departmentId && departmentId !== null && departmentId !== "") ? departmentId : id}
                        onClose={handleClose}
                        onDissmiss={handleClose}
                        onSuccessClose={handleSuccessClose}
                        engagementData={engagementData}
                        engagementState={engagementState}
                    />
                </Portal>}
            </div>
            {showDelete && (
                <DeleteConfirmationDialog
                    message={t("are_you_sure_you_want_to_delete", {
                        value: engagementData.event_title
                    })}
                    onCancel={() => setShowDelete(false)}
                    onConfirm={handleDelete}
                    deleting={engagementListState?.deleteResponse?.loading === LoadingType.pending}
                    deleteType={t("event_engagement_details")}
                    state={engagementListState?.deleteResponse}
                    onClearError={handleClearError}
                />
            )}
             {showUploadFileDeleteDialog?.visible && (
                <DeleteConfirmationDialog
                    message={t("are_you_sure_you_want_to_delete", {
                        value: showUploadFileDeleteDialog?.filePath,
                    })}
                    onCancel={() => setShowUploadFileDeleteDialog(null)}
                    onConfirm={handleDeleteUploadedFile}
                    deleting={engagementState.loading === LoadingType.pending}
                    deleteType={t("document")}
                    state={engagementState}
                    onClearError={handleUpdateClearError}
                />
            )}
        </>
    )
}
export default EngagementDetails;
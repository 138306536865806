import { createAsyncThunk } from "@reduxjs/toolkit";
import {  EngagementAPI } from "../../apis";
import { AppError, IEngagement } from "../../interfaces";
import { catchRequestError } from "../../utils";
import { S3API } from "../../apis/s3_api";

export const getEngagementDataById = createAsyncThunk<
    Array<IEngagement>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@engagement-details/get',
    async (id, thunkAPI) => {
        try {
            const res = await EngagementAPI.getEngagementDataById(id);
            if (typeof res.data === 'string') return [];
            return res.data as IEngagement[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const saveEngagement = createAsyncThunk<
    string,
    { data: IEngagement, files: Array<File> | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@engagement-details/save',
    async (payload, thunkAPI) => {
        try {
            const res = await EngagementAPI.addEngagement(payload.data);
            const { upload_urls } = res.data;
            // If there are any upload URLs, handle the file uploads
            if (upload_urls && Object.keys(upload_urls).length > 0) {
                // Iterate over the upload URLs and handle the files
                const uploadPromises = Object.entries(upload_urls).map(async ([fileName, fileUrl]) => {
                    const url = fileUrl as string;
                    // Find the corresponding file from the payload
                    const fileToUpload = payload?.files?.find((file) => file.name === fileName);
                    if (fileToUpload) {
                        const uploadFile = await S3API.uploadFile(url, fileToUpload);
                        return uploadFile.data;  // Return uploaded file data
                    }
                });
                const uploadResults = await Promise.all(uploadPromises);
                return {
                    ...res.data,
                    uploadResults,
                };
            }
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const updateEngagement = createAsyncThunk<
    string,
    { data: IEngagement, files?: Array<File> | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@engagement-details/update',
    async (payload, thunkAPI) => {
        try {
            const res = await EngagementAPI.updateEngagement(payload.data);
            const { upload_urls } = res.data;
            // If there are any upload URLs, handle the file uploads
            if (upload_urls && Object.keys(upload_urls).length > 0) {
                // Iterate over the upload URLs and handle the files
                const uploadPromises = Object.entries(upload_urls).map(async ([fileName, fileUrl]) => {
                    const url = fileUrl as string;
                    // Find the corresponding file from the payload
                    const fileToUpload = payload?.files?.find((file) => file.name === fileName);
                    if (fileToUpload) {
                        const uploadFile = await S3API.uploadFile(url, fileToUpload);
                        return uploadFile.data;  // Return uploaded file data
                    }
                });
                const uploadResults = await Promise.all(uploadPromises);
                return {
                    ...res.data,
                    uploadResults,
                };
            }
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteEngagement = createAsyncThunk<
    string,
    IEngagement,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@engagement-details/delete',
    async (data, thunkAPI) => {
        try {
            const res = await EngagementAPI.deleteEngagement(data.id);
           thunkAPI.dispatch(getEngagementDataById(data.object_id));
           return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
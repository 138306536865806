import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./end_of_1st_days_calls_report_thunk";
import { IReportCenterService } from "../../../../interfaces/report_center_service";

export interface EndOFDCReportListState extends EntityState<IReportCenterService> {
    error?: AppError | null,
    loading: LoadingType,  
}

const endOFDCReportListAdapter: EntityAdapter<IReportCenterService> = createEntityAdapter<IReportCenterService>({
    selectId: (doc) => doc.id,
})

const initialState: EndOFDCReportListState = endOFDCReportListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle, 
})


export const endOFDCReportListSlice = createSlice({
    name: 'endOFDCReportList',
    initialState: initialState,
    reducers: {
        clearEndOFDCReportListError(state) {
            return { ...state, error: null };
        },
        clearEndOFDCReportListState(state) {
            return endOFDCReportListAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getEndOFDCReportList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getEndOFDCReportList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getEndOFDCReportList.fulfilled, (state, action) => endOFDCReportListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearEndOFDCReportListError, clearEndOFDCReportListState } = endOFDCReportListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = endOFDCReportListAdapter.getSelectors();
export const selectState = (state: EndOFDCReportListState) => state;



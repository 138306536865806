import { useTranslation } from "react-i18next";
import { selectWeekEndingDatesDDList, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { CustomButton, CustomCheckBox, SpinnerScreen } from "../../../../utils";
import { useEffect, useState } from "react";
import { DownloadIcon } from "../../../../icons";
import SearchBar from "../../../../components/search_bar";
import { LoadingType, THEME } from "../../../../enums";
import './errors_reports_page.scss';
import SortableTable, { IHeaderListProps, TableData } from "../../../../components/sortable_table/sortable_table";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { getBOERStatus, getDateString } from "../../../../variables";
import ApproveResolvePopup from "../../popups/approve_resolve_error/approve_resolve_error";
import { Portal } from "react-portal";
import { AppError } from "../../../../interfaces";
import { AppRoutes } from "../../../../routes";
import { RouteComponentProps } from "react-router-dom";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";

interface Props {
    tableheaderList: Array<IHeaderListProps>;
    reportsList: any;
    source: string;
    weekendChange: (week_id: string) => void;
    WeekendId: (week_id: string) => void;
    loading: boolean;
    onApprove?: (timeCardids: string[]) => void;
    onResolve: (timeCardid: string) => void;
    state?: {
        error?: AppError | null | undefined;
        loading: LoadingType;
        response: string | null;
    };
    resolveState: {
        error?: AppError | null | undefined;
        loading: LoadingType;
        response: string | null;
    };
    handleClearError: () => void;
    handleOnSuccessClose: () => void;
    handleDownload: () => void;
    // isApproveError?: boolean;
    isWeeklyAccountingErrorReport?: boolean;
    history: RouteComponentProps["history"],
    selectedItems: Map<string, {
        id: string;
        name: string;
    }>
    handleMarkItem: (value: any) => void
    handleClearSelectedItems: () => void;
    onClose?:()=>void

}
const ErrorsReportsPage: React.FunctionComponent<Props> = (props) => {
    const {
        tableheaderList,
        reportsList,
        // isApproveError,
        weekendChange,
        loading,
        onApprove,
        onResolve,
        state,
        resolveState,
        WeekendId,
        handleClearError,
        handleOnSuccessClose,
        handleDownload,
        isWeeklyAccountingErrorReport,
        selectedItems,
        handleMarkItem,
        handleClearSelectedItems,
        onClose
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const weekEndingDatesList = useAppSelector((state) => selectWeekEndingDatesDDList(state));

    const [selectedWeekDate, setSelectedWeekDate] = useState<{ id: string, date: string }>({ id: "", date: "" });
    // const [selectedItem, setSelectedItem] = useState<Set<string>>(new Set());
    const [approve, setApprove] = useState<boolean>(false);
    const [resolve, setResolve] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<{id: string, type: string} | null>(null);
    const [showResolvedErrors, setShowResolvedErrors] = useState<boolean>(false);
    const arrayOfSelectedItems = selectedItems && Array.from(selectedItems.values()).map(item => item.id);

    useEffect(() => {
        if (weekEndingDatesList && weekEndingDatesList.length > 0) {
            setSelectedWeekDate({ id: weekEndingDatesList[0].id, date: getDateString(weekEndingDatesList[0].week_ending_date, "mm/dd/yyyy") });
            weekendChange(weekEndingDatesList[0].id);
            WeekendId(weekEndingDatesList[0].id);
        }
    }, [weekEndingDatesList.length]);

    const [search, setSearch] = useState('');

    const handleSearch = (value: string) => {
        setSearch(value);
    }
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    const getFlexNumber = (value: number) => {
        if (value === 0) return 0.2;
        if (value === 1) return 1.5;
        if (value === 2) return 1.5;
        if (value === 3) return 1.5;
        if (value === 4) return 3;
        if (value === 5) return 2;
        if (value === 6) return 2;
        if (value === 7) return 1;
        if (value === 8) return 1.7;
        if (value === 9) return 2;

        if (value === 20) return 1;
        return 1;
    }
    const getSecondFlexNumber = (value: number) => {
        if (value === 0) return 0.2;
        if (value === 1) return 1.5;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        return 1;
    }
    const getFilteredList = () => {
        let list = Array.isArray(reportsList) ? [...reportsList] : [];

        if (reportsList && sortedField != null) {
            list = [...reportsList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        // if (!showResolvedErrors) {
        //     const filteredData = list?.filter((doc) => doc.status === "OPEN");
        //     return filteredData
        // }
        if (!showResolvedErrors) {
            list = list.filter(doc => doc.status === "OPEN");
        }
        
        return list?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }

    const handleWeekEndChange = (val) => {
        const selectedWeekObject = weekEndingDatesList.find(obj => obj.id === val);
        setSelectedWeekDate({ id: val, date: getDateString(selectedWeekObject?.week_ending_date, "mm/dd/yyyy") });
        weekendChange(val);
        WeekendId(val);
    }
    // const handleMarkItem = (doc: any) => {
    //     const newSet = new Set(selectedItem);
    //     if (selectedItem.has(doc.id)) {
    //         newSet.delete(doc.id);
    //     } else {
    //         newSet.add(doc.id);
    //     }
    //     setSelectedItem(newSet);
    // }

    function handleTalentSelect(value) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
            state: {
                id: value.talent_id,
                name: value.talent_name,
            },
        });
        return;
    }
    function handleCompanySelect(value) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            },
        });
        return;
    }
    function handleJobSelect(value) {
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${value.job_order_id}`,
            state: {
                id: value.job_order_id,
                name: value.job_title,
            }
        })
        return;
    }

    // const handleMarkAllItem = () => {
    //     const newSet = new Map<string, { id: string, name: string }>();
    //     if (selectedItems.size === activeDirectoryList.length) {
    //         setSelectedItems(newSet);
    //     } else {
    //         activeDirectoryList.forEach(doc => newSet.set(doc.id, { id: doc.id, name: doc.name }));
    //         setSelectedItems(newSet);
    //     }

    // }

    const handleOnSucccessResolve = ()=>{
        handleOnSuccessClose(); 
        setResolve(false);
    }

    function toggle () {
        if(state?.loading===LoadingType.succeeded) {
            handleOnSuccessClose();
            handleClearSelectedItems!()
        }
        else{
            if(props?.onClose) {
                props.onClose()
            }
        }
        setApprove(false)
    }
    return (
        <div className="errors-report-container">
            <div className="er-header">
                <div className="left-col">
                    <div>
                        <CustomFormSelect
                            list={
                                weekEndingDatesList.map((doc) => ({
                                    label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
                                    value: doc.id,
                                }))
                            }
                            customStyle={{ width: "100%" }}
                            name={"week_ending_date"}
                            value={selectedWeekDate.id}
                            label={t("week_ending_date")}
                            placeholder={"Select"}
                            onChange={handleWeekEndChange}
                            isClearable={false}
                        />
                    </div>
                    <div className="toggle-switch">
                        <ToggleSwitch
                            onChange={(value) => setShowResolvedErrors(value)}
                            label={t("show_resolved_errors")}
                            checked={showResolvedErrors}
                        />
                    </div>
                </div>
                <div className="right-col">
                    {/* {isApproveError && */}
                    <div>
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={selectedItems!.size > 1 ? t("approve_warnings") : t("approve_warning")}
                            onClick={() => setApprove(true)}
                            backgroundColor={THEME.defaultHighLightColor}
                            enable={selectedItems!.size > 0}
                        />
                    </div>
                    {/* } */}
                    {/* <div className="ml">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("resolve_error")}
                            onClick={() => onResolve("")}
                            backgroundColor={THEME.defaultHighLightColor}
                            enable={selectedItems.size > 0}
                        />
                    </div> */}
                    <div className="ml dwn-btn">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            leftIcon={<DownloadIcon height={"1vw"} width={"1vw"} />}
                            name={t("download")}
                            backgroundColor={THEME.buttonColor16}
                            onClick={handleDownload}
                            enable={getFilteredList()?.length !== 0}
                        />
                    </div>
                    <div className="ml search-bar">
                        <SearchBar value={search} onChange={handleSearch} onSearch={() => { }} placeholder="Search..." />
                    </div>
                </div>
            </div>
            <div className="er-content">
                <div className="er-table">
                    <SortableTable
                        headerList={tableheaderList}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={isWeeklyAccountingErrorReport ? getSecondFlexNumber : getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {loading ?
                            <SpinnerScreen />
                            :
                            <>
                                {getFilteredList() && getFilteredList()?.length! > 0 ? (
                                    <>
                                        {getFilteredList()?.map((doc, index) => {
                                            return (
                                                <tr key={doc.id + index}>
                                                    {isWeeklyAccountingErrorReport ?
                                                        <>
                                                            <TableData customStyle={{ flex: getSecondFlexNumber(0) }}>
                                                                {doc.type !== "Error" &&
                                                                    <CustomCheckBox
                                                                        name={""}
                                                                        title={""}
                                                                        checked={selectedItems!.has(doc.id)}
                                                                        onClick={() => handleMarkItem!(doc)}
                                                                        disabled={(doc.status === "APPROVED" || doc.status === "RESOLVED") && true}
                                                                    />
                                                                }
                                                            </TableData>
                                                            <TableData customStyle={{ flex: getSecondFlexNumber(1) }}><span>{doc.error_msg}</span></TableData>
                                                            <TableData customStyle={{ flex: getSecondFlexNumber(2) }}><span>{doc.error_type}</span></TableData>
                                                            <TableData customStyle={{ flex: getSecondFlexNumber(3) }}><span style={{ color: doc.type === "Error" ? THEME.statusInActiveColor :  THEME.statusWarningColor}}>{doc.type}</span></TableData>
                                                            <TableData customStyle={{ flex: getSecondFlexNumber(4) }}><span>{getBOERStatus(doc.status)}</span></TableData>
                                                            <TableData customStyle={{ flex: getSecondFlexNumber(5) }}><span>{getDateString(doc.week_ending_date, "mm/dd/yyyy")}</span></TableData>
                                                            <TableData customStyle={{ flex: getSecondFlexNumber(6) }}>
                                                                <div className="er-resolve-btn">
                                                                    <CustomButton
                                                                        loading={false}
                                                                        textStyle={{ textTransform: "capitalize" }}
                                                                        name={t(doc.type === "Error" ? "resolve_error" : "resolve_warning")}
                                                                        onClick={() => { setResolve(true); setSelectedItem({id: doc.id, type: doc.type}) }}
                                                                        backgroundColor={THEME.defaultHighLightColor}
                                                                        enable={doc.status === "OPEN" && true}
                                                                    />
                                                                </div>
                                                            </TableData>
                                                        </>
                                                        :
                                                        <>
                                                            <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                                {doc.type !== "Error" &&
                                                                    <CustomCheckBox
                                                                        name={""}
                                                                        title={""}
                                                                        checked={selectedItems!.has(doc.id)}
                                                                        onClick={() => handleMarkItem!(doc)}
                                                                        disabled={(doc.status === "APPROVED" || doc.status === "RESOLVED") && true}
                                                                    />
                                                                }
                                                                </TableData>
                                                            <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{doc.batch_number}</span></TableData>
                                                            <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{getBOERStatus(doc.status)}</span></TableData>
                                                            <TableData customStyle={{ flex: getFlexNumber(3) }}><span style={{ color: doc.type === "Error" ? THEME.statusInActiveColor :  THEME.statusWarningColor}}>{(doc.type)}</span></TableData>
                                                            <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{doc.error_msg}</span></TableData>
                                                            <TableData customStyle={{ flex: getFlexNumber(5) }} isButton={true} onClick={() => handleTalentSelect(doc)}><span>{doc.talent_name}</span></TableData>
                                                            <TableData customStyle={{ flex: getFlexNumber(6) }} isButton={true} onClick={() => handleCompanySelect(doc)}><span>{doc.company_name}</span></TableData>
                                                            <TableData customStyle={{ flex: getFlexNumber(7) }} isButton={true} onClick={() => handleJobSelect(doc)}><span>{doc.job_title}</span></TableData>
                                                            <TableData customStyle={{ flex: getFlexNumber(8) }}><span>{doc.transaction_number}</span></TableData>
                                                            <TableData customStyle={{ flex: getFlexNumber(9) }}>
                                                                <div className="er-resolve-btn">
                                                                    <CustomButton
                                                                        loading={false}
                                                                        textStyle={{ textTransform: "capitalize" }}
                                                                        name={t(doc.type === "Error" ? "resolve_error" : "resolve_warning")}
                                                                        onClick={() => { setResolve(true); setSelectedItem({id: doc.id, type: doc.type}) }}
                                                                        backgroundColor={THEME.defaultHighLightColor}
                                                                        enable={doc.status === "OPEN" && true}
                                                                    />
                                                                </div>
                                                            </TableData>
                                                        </>
                                                    }
                                                </tr>
                                            )
                                        })}
                                    </>
                                )
                                    :
                                    <>
                                        <tr>
                                            <TableData customStyle={{ flex: getFlexNumber(20) }} align="center" >
                                                <div className="na_div">
                                                    {t("no_reports_available")}
                                                </div>
                                            </TableData>
                                        </tr>
                                    </>
                                }

                            </>
                        }
                    </SortableTable>
                </div>
            </div>
            {approve &&
                (
                    <Portal>
                        <ApproveResolvePopup
                            visible={approve}
                            title={selectedItems!.size > 1 ? t("approve_warnings") : t("approve_warning")}
                            onClose={() => {
                                toggle()
                            }}
                            handleSubmit={() => {
                                if (onApprove) {
                                    onApprove(arrayOfSelectedItems!)
                                }
                            }}
                            state={state}
                            message={selectedItems!.size > 1 ? t("are_you_sure_to_approve_warnings") : t("are_you_sure_to_approve_warning")}
                            btnName={t("approve")}
                            handleClearError={handleClearError}
                            handleOnSuccessClose={() => { handleOnSuccessClose(); setApprove(false); handleClearSelectedItems!() }}
                        />

                    </Portal>
                )
            }
            {resolve &&
                (
                    <Portal>
                        <ApproveResolvePopup
                            visible={resolve}
                            title={selectedItem?.type === "Error" ? t("resolve_error") : t("resolve_warning")}
                            onClose={() => () => resolveState.loading===LoadingType.succeeded? handleOnSucccessResolve():setResolve(false)}
                            handleSubmit={() => onResolve(selectedItem?.id!)}
                            state={resolveState}
                            message={selectedItem?.type === "Error" ? t("are_you_sure_to_resolve_error") : t("are_you_sure_to_resolve_warning")}
                            btnName={t("resolve")}
                            handleClearError={handleClearError}
                            handleOnSuccessClose={handleOnSucccessResolve }
                        />

                    </Portal>
                )
            }
        </div>


    );
}

export default ErrorsReportsPage;
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import SearchBar from '../../../../../components/search_bar';
import SortableTable, { TableData } from '../../../../../components/sortable_table/sortable_table';
import TableEmpty, { TableErrorHandler, TableFilterEmpty, TableLoading } from '../../../../../components/table_empty/table_empty';
import { COMPANY_TYPE, LoadingType, ROLE } from '../../../../../enums';
import './universal_directory.scss';
import {
    useAppDispatch, useAppSelector,
    selectDashboardEngagementListState, selectDashboardEngagementCustomers,
    postSnackbarMessage, getDashboardEngagementCustomerDetails, getRoleUsersList, selectAccountManagerRoleUsersList,
    selectProfileState
} from '../../../../../redux/store';
import { getCompanyStatusText, getTableAccessIcon, getWebsiteInfo } from '../../../../../utils/helpers';
import { AppRoutes } from '../../../../../routes';
import { ICompanyEngagement } from '../../../../../interfaces';
import CustomFormSelect, { CustomMultiFormSelect } from '../../../../../components/form_selector/form_select';
import { CustomCheckBox } from '../../../../../utils';
import { getCompanyStatus, selectCompanyStatusList } from '../../../../../redux/admin_center';


interface Props extends RouteComponentProps<any> { }
const tableHeader = [
    // { title: 'access_company', code: 'access_company' },
    { title: 'engagement_rating', code: 'engagement_rating' },
    { title: 'company', code: 'company_name' },
    { title: 'status', code: 'status' },
    { title: 'department', code: 'department_name' },
    { title: 'website', code: 'website' },
    { title: 'address', code: 'address' },
    { title: 'city', code: 'city' },
    { title: 'state', code: 'state' },
    { title: 'zip_code', code: 'zip_code' },
];

const CompanyEngagementUniversalDirectoryPage: React.FunctionComponent<Props> = (props) => {
    const dispatch = useAppDispatch();
    const dashboardEngagementsState = useAppSelector((state) => selectDashboardEngagementListState(state));
    const customerList = useAppSelector((state) => selectDashboardEngagementCustomers(state));
    const {profile} = useAppSelector((state)=>selectProfileState(state))
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [addCompany, setAddCompany] = useState<boolean>(false);
    const { history, match, location } = props;
    const { params } = match;
    const { t } = useTranslation();

    const [includeDepartment, setIncludeDepartment] = useState(false);
    const [accountManagers, setAccountManagers] = useState<string[]>([]);
    const [status, setStatus] = useState<string | null>(null);
    const accountManagersList = useAppSelector((state) =>
        selectAccountManagerRoleUsersList(state)
    );
    const companyStatusList = useAppSelector((state) => selectCompanyStatusList(state));

    useEffect(() => {
        if (params && params.type !== "")
            getcustomerList();
        return () => {
        }
    }, [params.type])

    useEffect(() => {
        dispatch(getRoleUsersList());
        dispatch(getCompanyStatus());
        return () => { };
    }, []);

    useEffect(() => {
        if (dashboardEngagementsState.error != null && customerList && customerList.length != 0) {
            dispatch(postSnackbarMessage(dashboardEngagementsState.error.message));
        }
        return () => {
        }
    }, [dashboardEngagementsState.error])


    function getcustomerList() {
        dispatch(getDashboardEngagementCustomerDetails(params.type));
    }

    function getOnlyCompanies() {
        return customerList.filter((doc) => doc.type === COMPANY_TYPE.company);
    }

    function getFilteredList(): ICompanyEngagement[] {
        // const list = customerList;
        // let sortedList: ICompanyEngagement[] | undefined;
        const list = includeDepartment ? customerList : getOnlyCompanies();
        let sortedList: ICompanyEngagement[] | undefined;
        let resultList: ICompanyEngagement[] | undefined;

        if (accountManagers.length != 0) {
            resultList = (resultList ?? list).filter((doc) => {
                const filter =
                    doc.account_managers && doc.account_managers.length != 0
                        ? doc.account_managers.some((s) => accountManagers.indexOf(s) >= 0)
                        : false;
                return filter;
            });
        }

        if (status != null && status != "") {
            resultList = (resultList ?? list).filter((doc) => {
                const statusFilter =
                    doc.status_id && status
                        ? doc.status_id === status
                        : false;
                return statusFilter;
            });
        }

        if (sortedField != null) {
            sortedList = [...list].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] != undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }


        return (sortedList ?? resultList ?? list).filter(doc => {
            const str = search.trim().toLowerCase();
            const deptnameFilter = doc.department_name ? doc.department_name.trim().toLowerCase().includes(str) : false;
            const companyName = doc.company_name ? doc.company_name.trim().toLowerCase().includes(str) : false;
            const cityFilter = doc.city ? doc.city.trim().toLowerCase().includes(str) : false;
            const stateFilter = doc.state ? doc.state.trim().toLowerCase().includes(str) : false;
            const zipcodeFilter = doc.zip_code ? doc.zip_code.trim().toLowerCase().includes(str) : false;
            const statusFilter = doc.status ? doc.status.trim().toLowerCase().includes(str) : false;
            const addressFilter = doc.address ? doc.address.trim().toLowerCase().includes(str) : false;
            const departmentFilter = doc.department_name ? doc.department_name.trim().toLowerCase().includes(str) : false;
            return deptnameFilter || companyName || cityFilter || stateFilter || zipcodeFilter || statusFilter || addressFilter || departmentFilter;
        });
    }

    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            // setSortedField(null);
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    }

    return (
        <div className="cps-ud-container">
            <div className="cps-ud-table-header">
                <TableHeaderOptions
                    roleUsers={accountManagersList.map((doc) => ({
                        label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                        value: doc.id,
                    }))}
                    totalCount={getFilteredList().length}
                    departmentActive={includeDepartment}
                    onChangeDepartment={() => setIncludeDepartment(!includeDepartment)}
                    onChangeRoleUser={setAccountManagers}
                    selectedRoleUsers={accountManagers}
                    search={search}
                    onSearchChange={setSearch}
                    selectedStatus={status}
                    onStatusChange={setStatus}
                    companyStatusList={companyStatusList.map((doc) => ({
                        label: doc.company_status,
                        value: doc.id,
                    }))}
                />
            </div>
            <div className="cps-ud-table">
                {getCustomersTableList()}
            </div>
        </div>
    );

    function getRatings(value: any) {
        return (
            <div className={params.type === 'LOW' ? 'cmp-eng-rating-low' : (params.type === 'MEDIUM' ? 'cmp-eng-rating-medium' : 'cmp-eng-rating-high')}>
                <span className='cmp-eng-rating-text'>
                    {value}
                </span>
            </div>
        )
    }
    // function toTitles(s){ 
    //     return s.replace(/\w\S*/g, function(t) { return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase(); }); 
    // }

    /// table list
    function getCustomersTableList() {
        if (dashboardEngagementsState.loading === LoadingType.pending) {
            return (<TableLoading />);
        }
        if (dashboardEngagementsState.error != null && customerList && customerList.length === 0) {
            return (
                <TableErrorHandler error={dashboardEngagementsState.error} onRefresh={getcustomerList} />
            );
        }
        if (customerList && customerList.length === 0) {
            return (
                <TableEmpty title={t('no_data_found')} onClick={() => getcustomerList()} />
            );
        }
        if (getFilteredList().length === 0) {
            return (
                <TableFilterEmpty title={t('no_data_found')} />
            );


        }
        return (
            <SortableTable
                headerList={tableHeader}
                sortedField={sortedField}
                onSortChange={handleSortFieldChange}
                flexNumber={getFlexNumber}
                isAsc={sortDirection}
            >
                {getFilteredList().map((doc, index) => {
                    return (
                        <tr key={doc.id}>
                            {/* <TableData customStyle={{ flex: getFlexNumber(0) }}>{getTableAccessIcon(() => handleCompanySelect(doc))}</TableData> */}
                            <TableData customStyle={{ flex: getFlexNumber(0) }}>{getRatings(doc.engagement_rating)}</TableData>
                            <TableData customStyle={{ flex: getFlexNumber(1) }} isButton={doc.company_name ? true : false} onClick={() => handleCompanySelect(doc)}><span>{doc.company_name}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{doc.status ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(3) }} isButton={doc.department_name ? true : false} onClick={() => handleDepartmentSelect(doc)}><span>{doc.department_name ?? t('corporate')}</span></TableData>
                            {/* <TableData customStyle={{ flex: getFlexNumber(5) }}>{doc.contact ?? ''}</TableData> */}
                            <TableData customStyle={{ flex: getFlexNumber(4) }}>{getWebsiteInfo(doc.website)}</TableData>
                            <TableData customStyle={{ flex: getFlexNumber(5) }}><span>{doc.address ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(6) }}><span>{doc.city ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(7) }}><span>{doc.state ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(8) }}><span>{doc.zip_code ?? ''}</span></TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1.2;
        if (value === 1) return 1.5;
        if (value === 2) return 2;
        if (value === 3) return 2;
        if (value === 4) return 1;
        if (value === 5) return 2;
        if (value === 6) return 1.2;
        if (value === 7) return 1;
        if (value === 8) return 1;
        // if (value === 9) return 1;
        // if (value === 10) return 1;
        return 1;
    }

    function handleCompanySelect(value: ICompanyEngagement) {
        var param_id;
        if (value.type === "DEPARTMENT") {
            param_id = value.company_id;
        }
        else {
            param_id = value.id;
        }
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${param_id}`,
            state: {
                id: param_id,
                name: value.company_name,
            }
        })
        return;
    }

    function handleDepartmentSelect(value: ICompanyEngagement) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
                departmentId: value.id,
                departmentName: value.department_name,
            }
        })
        return;
    }
}

interface TableHeaderOptionsProps {
    /// [Role users]
    roleUsers: Array<{ label: string; value: string }>;
    selectedRoleUsers: string[];
    onChangeRoleUser: (value: string[]) => void;
    /// [Department]
    departmentActive: boolean;
    onChangeDepartment: () => void;
    totalCount: number;
    /// [Search]
    search: string;
    onSearchChange: (value: string) => void;
    /// [Status]
    selectedStatus: string | null;
    onStatusChange: (value: string) => void;
    companyStatusList: Array<{ label: string; value: string }>;
}

const TableHeaderOptions = (props: TableHeaderOptionsProps) => {
    const { t } = useTranslation();
    return (
        <div className="cps-ud-table-header-options">
            <div className="account-manager-selector">
                <CustomMultiFormSelect
                    label={t("account_manager")}
                    name={"account_manager"}
                    list={props.roleUsers}
                    onChange={props.onChangeRoleUser}
                    required={false}
                    placeholder={t("select")}
                    value={props.selectedRoleUsers}
                    customStyle={{ width: "-webkit-fill-available" }}
                />
            </div>
            <div className="company-status-selector">
                <CustomFormSelect
                    customStyle={{ width: "-webkit-fill-available" }}
                    name={"company_status"}
                    value={props.selectedStatus}
                    required={false}
                    label={t("status")}
                    placeholder={t("all")}
                    list={props.companyStatusList}
                    onChange={props.onStatusChange}
                />
            </div>
            <div className="cps-ud-table-search-bar">
                <SearchBar value={props.search} onChange={props.onSearchChange} onSearch={() => { }} />
            </div>
            <div className="deparment-toggle">
                <CustomCheckBox
                    name={"department"}
                    title={t("include_departments")}
                    checked={props.departmentActive}
                    onClick={props.onChangeDepartment}
                />
            </div>
            <div className="total-count">
                <span>{`${t('total_count')}: `} <span className="total-count-number">{props.totalCount}</span> </span>
            </div>
        </div>
    );
}


export default CompanyEngagementUniversalDirectoryPage;
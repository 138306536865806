import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { TalentSource } from "../../../../interfaces/talent_source";

export const getTalentSource = createAsyncThunk<
    Array<TalentSource>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/talent_source/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getTalentSource();
            return res.data as TalentSource[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createTalentSource = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/talent_source/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createTalentSource(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateTalentSource = createAsyncThunk<
    string,
    {'id': string, 'value': string, 'agency_id': string, 'is_deleted': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/talent_source/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateTalentSource(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteTalentSource = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/talent_source/delete',
    async (status_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteTalentSource(status_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
